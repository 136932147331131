import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, Col, Row } from 'reactstrap';
import { Enterprise, TenantConfig, getDate } from '../../Components/Common/Util';
import googlePlay from '../../assets/images/common/svg/common/Google_Play.svg';
import androidEnterpriseImg from '../../assets/images/common/png/common/androidEnterprise.png';
import calendar from '../../assets/images/common/png/common/calendar.png';
import enterprisename from '../../assets/images/common/png/common/enterprisename.png';
import id from '../../assets/images/common/png/common/id.png';
import status from '../../assets/images/common/png/common/status.png';
import tick from '../../assets/images/common/svg/common/tickmark.svg';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import { enterpriseAccount } from '../../store/actions';
import Enrollment from './EnrollmentModal';
import NoEnterprise from './NoEnterprise';

const AndroidEnterprise = (props) => {
    const urlconf = useEnv();
    let dispatch = useDispatch();
    let api = new APIClient();
    let enterPriseObj = Enterprise();
    const enterprises = enterPriseObj ? JSON.parse(enterPriseObj) : {};
    const [enrollment, setEnrollment] = useState({});
    const tenantConfigData = TenantConfig();
    const [addEnterpriseModal, setAddEnterpriseModal] = useState(false);
    const enterpriseDetails = [
        { label: 'Enterprise ID', key: 'id', img: id, alt: 'id', configKey: 'COMPANY_NAME' },
        { label: 'Enterprise Name', key: 'name', img: enterprisename, alt: 'name', configKey: 'COMPANY_NAME' }
    ];

    const enterpriseDetails2 = [
        {
            label: 'Activated On',
            value: enterprises?.lastmodifiedTime ? getDate(enterprises?.lastmodifiedTime) : '—',
            img: calendar,
            alt: 'calendar'
        },
        { label: 'Status', value: !enterprises?.id ? 'In-progress' : 'Activated', img: status, alt: 'status' }
    ];

    useEffect(() => {
        setEnrollment(enterprises);
        let enterpriseToken = window.location.search?.split('=')?.[1];
        if (enterpriseToken !== '' && enterpriseToken !== undefined && enterpriseToken !== null) {
            let enrollObj = localStorage.getItem('enrollData');
            let enrollData = enrollObj ? JSON.parse(enrollObj) : {};
            let params = { signupUrlName: enrollData?.name, enterpriseToken: enterpriseToken };
            completeRegistration(params);
        }
    }, []);

    const completeRegistration = (params) => {
        props.setLoading(true);
        api.create(url.ENTERPRISES, params)
            .then(async (resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    setEnrollment(resp.data);
                    dispatch(enterpriseAccount(JSON.stringify(resp.data)));
                    props.setLoading(false);
                    window.location.href = window.location.origin + '/' + window.location.hash;
                }
            })
            .catch(function (error) {
                toast.error(error);
                props.setLoading(false);
            });
    };

    const toggle = useCallback(async () => {
        if (addEnterpriseModal) setAddEnterpriseModal(false);
        else setAddEnterpriseModal(true);
    }, [addEnterpriseModal]);

    const handleEnrollment = () => {
        props.setLoading(true);
        const URL = url.ENTERPRISES + url.REGISTRATION_URL;
        api.get(URL)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    localStorage.setItem('enrollData', JSON.stringify(resp.data));
                    window.location.href = resp.data.url;
                    props.setLoading(false);
                }
            })
            .catch((err) => {
                props.setLoading(false);
            });
    };

    return (
        <React.Fragment>
            {Object.keys(enrollment)?.length > 0 ? (
                <Card className="heightVh-75 p-4 d-grid place-items-center overflow-auto enterprise-back-img ms-2">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <Row>
                            <div className="d-grid place-items-center">
                                <img src={androidEnterpriseImg} alt="verification" height={65} />
                                <div className="d-flex align-items-center margin-top-20">
                                    {enterprises?.id && <img src={tick} alt="id" width={30} height={30} className="me-2" />}
                                    <span className="d-flex align-items-center">
                                        <p className={`mb-0 fs-18 ${enterprises?.id ? 'text-success' : 'text-info'} fw-semibold`}>
                                            {enterprises?.id
                                                ? 'Enterprise registration completed'
                                                : 'No enterprise account found. Please enroll'}
                                        </p>
                                    </span>
                                </div>
                                {enterprises?.id && (
                                    <span className="d-flex align-items-center margin-top-30">
                                        <p className="mb-1 fs-13 text-muted">
                                            You have successfully completed enterprise registration. Please check the details below.
                                        </p>
                                    </span>
                                )}
                            </div>
                        </Row>
                        <Row className="margin-top-60 gap-20 w-100">
                            {enterpriseDetails?.length > 0 &&
                                enterpriseDetails.map((obj, ind) => {
                                    return (
                                        <Col key={ind}>
                                            <div className="d-flex align-items-center">
                                                <img src={obj.img} alt={obj.alt} width={46} height={46} />
                                                <span className="d-flex align-items-center ms-2">
                                                    <div className="d-block">
                                                        <p className="mb-1 text-muted fw-medium">{obj.label}</p>
                                                        <p className="mb-1 fw-semibold">
                                                            {enterprises?.[obj.key] || tenantConfigData?.[obj.configKey]}
                                                        </p>
                                                    </div>
                                                </span>
                                            </div>
                                        </Col>
                                    );
                                })}
                        </Row>
                        <Row className="margin-top-60 gap-20 w-100">
                            {enterpriseDetails2?.length > 0 &&
                                enterpriseDetails2.map((obj, ind) => {
                                    return (
                                        <Col key={ind}>
                                            <div className="d-flex align-items-center">
                                                <img src={obj.img} alt={obj.alt} width={46} height={46} />
                                                <span className="d-flex align-items-center ms-2">
                                                    <div className="d-block">
                                                        <p className="mb-1 text-muted fw-medium">{obj.label}</p>
                                                        <p className="mb-1 fw-semibold">{obj.value}</p>
                                                    </div>
                                                </span>
                                            </div>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </div>
                </Card>
            ) : (
                <NoEnterprise
                    setAddEnterpriseModal={setAddEnterpriseModal}
                    enterPrise={enrollment}
                    image={googlePlay}
                    message="No managed google play store account found"
                />
            )}

            {addEnterpriseModal && (
                <Enrollment
                    open={addEnterpriseModal}
                    toggle={toggle}
                    setModal={setAddEnterpriseModal}
                    handleEnrollment={handleEnrollment}
                />
            )}
        </React.Fragment>
    );
};

export default AndroidEnterprise;
