import React from 'react';
import { Card, Spinner } from 'reactstrap';

const Loader = (props) => {
    return (
        <React.Fragment>
            <Card className={props.customLoad ? 'custom-loader-main' : 'loader-main'}>
                <div className={props.customLoad ? 'custom-loader-box' : 'loader-box'}>
                    <Spinner color="primary" className="spinner-style" />
                    <strong className="loading-style">{props.loaderMsg ? props.loaderMsg : 'Loading...'}</strong>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default Loader;
