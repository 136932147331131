/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Row } from 'reactstrap';
import Loader from '../../../Components/Common/Loader';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import {
    configEnableOnCondition,
    ConfigNotEnabledComponent,
    EnrollConfigComponents,
    EnrollmentConfigCard,
    getWindowsConfigInitialValues,
    windowsEnrollConfigSchema
} from './EnrollmentConfigConstants';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';
import DeleteModal from '../../../Components/Common/DeleteModal';

const EnrollmentConfig = () => {
    document.title = 'Enrollment Config';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [configModal, setConfigModal] = useState(false);
    const [assignModal, setAssignModal] = useState(false);
    const [allConfigs, setAllConfigs] = useState([]);
    const [mode, setMode] = useState('add');
    const [selectedConfig, setSelectedConfig] = useState({});
    const [assignTo, setAssignTo] = useState('USER');
    const [usersList, setUsersList] = useState([]);
    const [user, setUser] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const policyCode = window.location.hash?.split('/')?.reverse()[0];

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: getWindowsConfigInitialValues()
    });

    useEffect(() => {
        if (policyCode) handlePromise();
    }, []);

    const handlePromise = () => {
        setLoading(true);
        const usersPromise = new Promise((resolve, reject) => {
            api.get(url.USERS, { page: 1, size: 100 }, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) {
                        resolve(resp.data);
                    }
                    reject('Applications Failed.');
                })
                .catch((err) => {
                    reject('Applications Failed.');
                });
        });
        const configsPromise = new Promise((resolve, reject) => {
            api.get(url.WINDOWS_ENROLL_CONFIG, { policy: policyCode, page: 1, size: 100 }, domains.WINDOWS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success' && resp.data) {
                        resolve(resp.data);
                    }
                    reject('Applications Failed.');
                })
                .catch((err) => {
                    reject('Applications Failed.');
                });
        });
        Promise.allSettled([usersPromise, configsPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    setUsersList(result[0].value);
                }
                if (result[1].status === 'fulfilled') {
                    setAllConfigs(result[1].value);
                }
            })
            .finally((err) => setLoading(false));
    };

    const getConfigs = () => {
        setLoading(true);
        let params = { policy: policyCode, page: 1, size: 100 };
        api.get(url.WINDOWS_ENROLL_CONFIG, params, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    if (resp.data) setAllConfigs(resp.data);
                    else setAllConfigs([]);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleAddNew = () => {
        setMode('add');
        setConfigModal(true);
    };

    const toggleConfigCanvas = () => {
        setConfigModal(false);
        validation.setValues(getWindowsConfigInitialValues());
    };

    const handleEnrollConfigBody = () => {
        return (
            <>
                {windowsEnrollConfigSchema.map((field, index) => {
                    return (
                        <>
                            {configEnableOnCondition(field, validation) ? (
                                <Row className={'m-0 p-0 gap-1'} key={index}>
                                    <EnrollConfigComponents field={field} index={index} formType={mode} validation={validation} />
                                </Row>
                            ) : (
                                <ConfigNotEnabledComponent field={field} validation={validation} />
                            )}
                        </>
                    );
                })}
            </>
        );
    };

    const onConfigEdit = (item) => {
        setMode('edit');
        setConfigModal(true);
        let obj = { authtype: item.authtype };
        if (item.authtype === 'STATIC_PIN') {
            obj.pin = item?.authconfig?.pin || '';
        }
        validation.setValues(obj);
        setSelectedConfig(item);
    };

    const onConfigDelete = (item) => {
        setSelectedConfig(item);
        setDeleteModal(true);
    };

    const onMakeDefault = (item) => {
        setLoading(true);
        api.patch(url.WINDOWS_ENROLL_CONFIG + '/' + item?._id + '/makedefault', {}, false, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.enrollmentConfigUpdated);
                    getConfigs();
                }
            })
            .finally(() => setLoading(false));
    };

    const checkDisabled = () => {
        let flag = true;
        if (validation.values?.authtype !== 'STATIC_PIN') flag = false;
        else if (validation.values?.pin?.length === 4) flag = false;
        return flag;
    };

    const onConfigSave = () => {
        setLoading(true);
        let payloadObj = {
            authtype: validation.values.authtype,
            authconfig: {}
        };
        if (mode === 'add') {
            payloadObj.policy = policyCode;
            payloadObj.platformconfig = {};
            payloadObj.expiration = new Date().toISOString();
        }
        if (validation.values.authtype === 'STATIC_PIN') {
            payloadObj.authconfig = {
                pin: validation.values.pin
            };
        }

        const endpoint = mode === 'add' ? url.WINDOWS_ENROLL_CONFIG : `${url.WINDOWS_ENROLL_CONFIG}/${selectedConfig?._id}`;
        const method = mode === 'add' ? 'create' : 'patch';

        api[method](endpoint, payloadObj, false, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(mode === 'add' ? toastMessages.enrollmentConfigCreated : toastMessages.enrollmentConfigUpdated);
                    getConfigs();
                }
            })
            .finally(() => {
                setLoading(false);
                toggleConfigCanvas();
            });
    };

    const toggleAssignCanvas = () => {
        setAssignModal(false);
        setUser('');
    };

    const onAssignClick = (item) => {
        setSelectedConfig(item);
        setAssignModal(true);
    };

    const assigningToOptions = [{ label: 'User', value: 'USER' }];

    const handleAssignBody = () => {
        return (
            <>
                <Row className="m-0 p-0 gap-1">
                    <Col xs={12} md={12} sm={12} lg={8} xl={7} className="m-0 p-0 mb-2">
                        <Label className="mb-0 fw-medium d-flex align-items-center mb-1">
                            Assign To <span className="red-color ps-1">*</span>
                        </Label>
                        <Select
                            options={assigningToOptions}
                            onChange={(e) => setAssignTo(e.value)}
                            value={assigningToOptions.find((obj) => obj.value === assignTo)}
                            isSearchable={true}
                            placeholder={'Select'}
                            noOptionsMessage={() => 'No data found'}
                        />
                    </Col>
                </Row>
                <Row className="m-0 mt-1 p-0 gap-1">
                    <Col xs={12} md={12} sm={12} lg={8} xl={7} className="m-0 p-0 mb-2">
                        <Select
                            getOptionValue={(option) => option.email}
                            getOptionLabel={(option) => option.email}
                            options={usersList}
                            onChange={(e) => setUser(e.email)}
                            value={usersList.find((obj) => obj.email === assignTo)}
                            isSearchable={true}
                            placeholder={'Select User'}
                            noOptionsMessage={() => 'No data found'}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    const checkDisabledForAssign = () => {
        let flag = true;
        if (assignTo.length > 0 && user.length > 0) flag = false;
        return flag;
    };

    const onAssignSave = () => {
        setLoading(true);
        let payloadObj = {
            assignmenttype: 'POLICY',
            assignmentref: policyCode,
            assignedtotype: assignTo,
            assignedtoref: user,
            enrollconfigcode: selectedConfig?.enrollmentcode
        };
        api.create(url.WINDOWS_ASSIGNMENT, payloadObj, false, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.assignmentCreated);
                }
            })
            .finally(() => {
                setLoading(false);
                toggleAssignCanvas();
            });
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to Delete?</div>
                <div>
                    Enrollment Code : <span className="fw-semibold">{selectedConfig.enrollmentcode}</span>
                </div>
            </div>
        );
    };

    const handleDeleteConfirmation = () => {
        setDeleteModal(false);
        setLoading(true);
        api.delete(url.WINDOWS_ENROLL_CONFIG + '/' + selectedConfig?._id, '', domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.enrollmentConfigDeleted);
                    getConfigs();
                }
            })
            .finally(() => {
                setSelectedConfig({});
                setLoading(false);
            });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={'Windows Enrollment Configuration'}
                        history={history}
                        showBack={true}
                        backLink="windowspolicies"
                    />
                    <Card className="card-height-100">
                        <CardHeader>
                            <div className="d-flex justify-content-between align-items-center fs-14 fw-semibold">
                                Enrollment Configuration History
                            </div>
                            <div className="padding-top-10 font-size-12 fw-medium text-muted">
                                Dispalys detailed record of all the created enrollment tokens for a policy used for device registration to
                                track configured settings. A default card has been pre-configured for convinent use.
                            </div>
                        </CardHeader>
                        <div className="p-4">
                            <div className="d-flex w-100 align-items-center justify-content-between">
                                <div className="ps-2 d-flex align-items-center">
                                    <Label className="p-0 m-0 fw-semibold fs-16">Policy Code :</Label>
                                    <p className="p-0 m-0 ms-2 fw-normal fs-14">{policyCode}</p>
                                </div>
                                <Button type="button" color="primary" onClick={handleAddNew}>
                                    + Create New Config
                                </Button>
                            </div>
                        </div>
                        <CardBody className="pt-0">
                            <Row>
                                {allConfigs?.length > 0 ? (
                                    allConfigs.map((item, index) => {
                                        return (
                                            <EnrollmentConfigCard
                                                key={index}
                                                index={index}
                                                item={item}
                                                onMakeDefault={onMakeDefault}
                                                onAssignClick={onAssignClick}
                                                onConfigEdit={onConfigEdit}
                                                onConfigDelete={onConfigDelete}
                                            />
                                        );
                                    })
                                ) : (
                                    <NoRecordsFound text="Configs" />
                                )}
                            </Row>
                        </CardBody>
                        <DeleteModal
                            hideIcon={true}
                            show={deleteModal}
                            hideDeleteMessage={true}
                            message={message()}
                            onDeleteClick={handleDeleteConfirmation}
                            onCloseClick={() => setDeleteModal(false)}
                        />
                        <OffcanvasModal
                            direction="end"
                            toggle={toggleConfigCanvas}
                            open={configModal}
                            handleCloseClick={toggleConfigCanvas}
                            saveText={mode === 'add' ? 'Create' : 'Update'}
                            saveDisabled={checkDisabled()}
                            loading={loading}
                            OffcanvasModalID="enrollConfig"
                            handleOffcanvasBody={() => handleEnrollConfigBody()}
                            offcanvasHeader={mode === 'add' ? 'Create Enrollment Config' : 'Update Enrollment Config'}
                            modalClassName="w-40"
                            handleSaveClick={() => onConfigSave()}
                        />
                        <OffcanvasModal
                            direction="end"
                            toggle={toggleAssignCanvas}
                            open={assignModal}
                            handleCloseClick={toggleAssignCanvas}
                            saveText={'Assign'}
                            saveDisabled={checkDisabledForAssign()}
                            loading={loading}
                            OffcanvasModalID="Assignment"
                            handleOffcanvasBody={() => handleAssignBody()}
                            offcanvasHeader={'Assignment'}
                            modalClassName="w-40"
                            handleSaveClick={() => onAssignSave()}
                        />
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EnrollmentConfig;
