import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import Loader from '../../../Components/Common/Loader';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { FilePond } from 'react-filepond';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { useEnv } from '../../../envContext';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';

const AddWindowsScript = () => {
    const api = new APIClient();
    const history = useHistory();
    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    const [scriptName, setScriptName] = useState('');
    const [uploadedFile, setUploadedFile] = useState('');
    const [receiverType, setReceiverType] = useState('');
    const [receivers, setReceivers] = useState([]);
    const [devicesType, setDevicesType] = useState('');
    const [devicesList, setDevicesList] = useState([]);
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash, true);
    let formType = formTypeAndId['formType'];
    let scripType = formTypeAndId['queryType'];
    let recordID = formTypeAndId['recordID'];

    const assigningOptions = [
        { label: 'Device', value: 'DEVICE' }
        //  {label: 'Device Group', value: 'GROUP'}
    ];

    useEffect(() => {
        handlePromise();
    }, []);

    const handlePromise = () => {
        const devicePromise = new Promise((resolve, reject) => {
            api.get(url.DEVICES, { page: 1, size: 100, status: 'ACTIVE' }, domains.WINDOWS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp);
                    reject('Devices Failed.');
                })
                .catch((err) => {
                    reject('Devices Failed.');
                });
        });

        Promise.allSettled([devicePromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    result[0].value.data?.forEach((data) => {
                        data.label = data.extra?.serial || '—';
                        data.value = data.extra?.serial || '—';
                    });
                }
                setDevicesList(result[0].value.data || []);
            })
            .finally(() => setLoading(false));
    };

    const handleFileUpload = (files) => {
        if (files.filename?.endsWith('.ps1')) {
            setUploadedFile(files?.file);
        } else toast.error(toastMessages.invalidFileFormat);
    };

    const onSaveClick = () => {
        setLoading(true);
        let formData = new FormData();
        formData.append('file', uploadedFile);
        formData.append('name', scriptName);
        formData.append('receivertype', receiverType?.value);
        formData.append('receivers', receivers.join());
        api.create(url.SCRIPTS, formData, false, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.scriptCreated);
                    history.push('/windowsscripts');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle="Upload Script"
                        history={history}
                        showBack={true}
                        backLink="windowsscripts"
                        homeLink="Dashboard"
                    />
                    <Card>
                        <CardHeader>
                            <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                                <div className="fs-14 ">Upload Script</div>
                            </div>
                            <div className="font-size-12 fw-medium text-muted">
                                You can execute custom scripts on Windows machines, create script files with custom configurations and
                                upload the file. Then assign the script file to the target devices.
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row className="p-0">
                                <Col xs={12} md={6} sm={6} lg={5} xl={5} className="m-0 mb-2">
                                    <Label className={'mb-0 fw-medium d-flex align-items-center mb-1'}>
                                        Script Name <span className="red-color ps-1">*</span>
                                    </Label>
                                    <div className="input-group position-relative">
                                        <Input
                                            name="scriptName"
                                            id="scriptName"
                                            className="form-control"
                                            placeholder="Enter"
                                            onChange={(e) => setScriptName(e.target.value)}
                                            value={scriptName}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3 p-0">
                                <Col xs={12} md={6} sm={6} lg={5} xl={5} className="m-0 mb-2">
                                    <Label className="mb-0 fw-medium d-flex align-items-center mb-1">
                                        Upload
                                        <span className="red-color ps-1">*</span>
                                    </Label>
                                    <div className="text-muted font-size-12 mb-2 word-wrap-break">upload .ps1 file</div>
                                    <FilePond
                                        name="uploadFile"
                                        onaddfile={(_, fileItems) => handleFileUpload(fileItems)}
                                        onremovefile={() => setUploadedFile('')}
                                        className="filepond filepond-input-multiple"
                                    />
                                </Col>
                            </Row>

                            {uploadedFile !== '' && (
                                <Row className={`mt-3 p-0 ${uploadedFile === '' ? 'postion-relative mask' : ''}`}>
                                    <Col xs={12} md={6} sm={6} lg={5} xl={5} className="m-0 mb-2">
                                        <Label className="mb-0 fw-medium d-flex align-items-center mb-1">
                                            Assign To <span className="red-color ps-1">*</span>
                                        </Label>
                                        <Select
                                            options={assigningOptions}
                                            onChange={(e) => setReceiverType(e)}
                                            value={receiverType}
                                            isSearchable={true}
                                            placeholder={'Select'}
                                            noOptionsMessage={() => 'No data found'}
                                        />
                                        {receiverType && receiverType?.value === 'DEVICE' && (
                                            <div className="mt-3 mb-3">
                                                {/* <div className="form-check form-check-inline mb-0">
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value={devicesType}
                                                        onChange={() => {
                                                            setDevicesType('All');
                                                            setReceivers(['*']);
                                                        }}
                                                        checked={devicesType === 'All'}
                                                    />
                                                    <Label
                                                        className="mb-0"
                                                        onClick={() => {
                                                            setDevicesType('All');
                                                            setReceivers(['*']);
                                                        }}
                                                    >
                                                        All Devices
                                                    </Label>
                                                </div> */}
                                                <div className="form-check form-check-inline mb-0">
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value={devicesType}
                                                        onChange={() => {
                                                            setDevicesType('Specific');
                                                            setReceivers([]);
                                                        }}
                                                        checked={devicesType === 'Specific'}
                                                    />
                                                    <Label
                                                        className="mb-0"
                                                        onClick={() => {
                                                            setDevicesType('Specific');
                                                            setReceivers([]);
                                                        }}
                                                    >
                                                        Specific Devices
                                                    </Label>
                                                </div>
                                            </div>
                                        )}
                                        {devicesType === 'Specific' && (
                                            <>
                                                <Select
                                                    isMulti={true}
                                                    isClearable={true}
                                                    closeMenuOnSelect={false}
                                                    options={devicesList}
                                                    placeholder={'Select Devices'}
                                                    onChange={(selectedOption) =>
                                                        setReceivers(selectedOption.map((option) => option.value))
                                                    }
                                                    value={devicesList?.filter((option) => receivers?.includes(option?.value))}
                                                    isSearchable={true}
                                                    noOptionsMessage={() => 'No data found'}
                                                />
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </CardBody>
                        <CardFooter className="d-flex justify-content-end ">
                            <Button
                                className="bg-light border-primary text-primary mx-3 px-5 fs-12 fw-semibold"
                                onClick={() => history.push('/windowsscripts')}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="bg-primary border-none px-8 fs-12 fw-semibold"
                                disabled={scriptName === '' || uploadedFile === '' || receiverType === '' || receivers.length === 0}
                                onClick={() => onSaveClick()}
                            >
                                Save
                            </Button>
                        </CardFooter>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddWindowsScript;
