import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import timefence from '../../../assets/images/common/png/common/timefence.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { TIMEFENCE_SCHEMA } from '../TableSchema';

const TimeFence = () => {
    document.title = 'Time-Fence';
    const urlconf = useEnv();
    let history = useHistory();
    let api = new APIClient();
    const [loading, setLoading] = useState(false);
    const [fences, setFences] = useState([]);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [selectedRow, setSelectedRow] = useState('');
    const [actionModal, setActionModal] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [backupFences, setBackupFences] = useState();

    useEffect(() => {
        getFences(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFences = (params) => {
        setLoading(true);
        setSearchParams(params);
        api.get(url.DEVICE_AUTOMATION, { ...params, type: 'TIMEFENCE' })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    resp.data.forEach((data) => {
                        data.groups = data.assign || '—';
                        data.modifiedBy = data.lastmodifiedBy || '—';
                        data.modifiedDate = data.lastmodifiedTime ? getDate(data.lastmodifiedTime) : '—';
                    });
                    setBackupFences(resp?.data);
                    setFences(resp.data);
                    setTotalRecords(resp.totalRecords);
                }
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
    };

    const handleAddNew = () => {
        history.push('/timefence/add');
    };

    const handleDeleteFence = () => {
        setLoading(true);
        api.delete(url.TIME_FENCE + '/' + selectedRow.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.policyFenceDeleted);
                    getFences({ page: 1, size: 10 });
                }
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
                setSelectedRow('');
                setActionModal(false);
            });
    };

    const handleDelete = (row) => {
        setSelectedRow(row.original);
        setActionModal(true);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete Time-Fence?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={timefence} alt="deletefence" width={60} height={60} />
                </div>
                <div>
                    Time-Fence Name: <span className="fw-semibold">{selectedRow?.name}</span>
                </div>
            </div>
        );
    };
    const handleGlobalSearch = (val) => {
        let filteredPolicies = [...backupFences];
        if (val?.length > 0) filteredPolicies = backupFences.filter((policy) => policy?.name?.toLowerCase()?.includes(val?.toLowerCase()));
        setTotalRecords(filteredPolicies.length);
        setFences(filteredPolicies);
    };
    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Time Fence" history={''} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        columns={TIMEFENCE_SCHEMA}
                        data={fences}
                        handleDelete={handleDelete}
                        tableHeader={'All Time Fences'}
                        isGlobalFilter={true}
                        searchFilter2={true}
                        handleGlobalSearch={handleGlobalSearch}
                        searchPlaceHolder={'Name'}
                        addButtonText="Add Time-Fence"
                        addButton={true}
                        customPageSize={10}
                        pageNum={searchParams.page}
                        totalRecords={totalRecords}
                        handleAddNew={handleAddNew}
                        handleEdit={(row) => history.push('/timefence/edit/' + row.original.id)}
                        reader={priviliges.CONFIGURATION_READER}
                        editor={priviliges.CONFIGURATION_EDITOR}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
                <DeleteModal
                    hideIcon={true}
                    hideDeleteMessage={true}
                    message={message()}
                    show={actionModal}
                    onDeleteClick={handleDeleteFence}
                    onCloseClick={() => {
                        setActionModal(false);
                        setSelectedRow('');
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default TimeFence;
