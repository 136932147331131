import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Card, Col, Container, Row } from 'reactstrap';
import DEVICE_ALLOCATION from '../../assets/images/common/png/common/device_allocation.png';
import Loader from '../../Components/Common/Loader';
import { APIClient } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import * as domains from '../../helpers/domain_helper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const EnrollmentSettings = () => {
    const api = new APIClient();
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState('add');

    const history = useHistory();

    const enrollmentSchema = [
        {
            icon: DEVICE_ALLOCATION,
            title: 'Device Allocation',
            info: 'Assign devices and corresponding platforms to users in accordance with organizational policies'
        }
    ];

    useEffect(() => {
        geteRestriction();
    }, []);

    const geteRestriction = () => {
        setLoading(true);
        api.get(url.DEVICE_RESTRICTIONS, '', domains.MDM_COMMONS)
            .then((resp) => {
                if (resp.status === 'success' && resp.data.length > 0) {
                    setMode('edit');
                }
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content h-100">
                <Container fluid>
                    <BreadCrumb pageTitle={'Device Allocation'} homeLink="Dashboard" />
                    <Row>
                        {enrollmentSchema?.map((ele, ind) => (
                            <Col xs={12} sm={6} md={4} lg={3} xl={3} key={ind}>
                                <Card className="py-2 px-1 text-center shadow-card card-height-100 rounded-2" key={ind}>
                                    <div className="cursor-pointer" onClick={() => history.push('/enrollmentsettings/' + mode)}>
                                        <div className="p-2">
                                            <img src={ele.icon} alt="img" height={60} />
                                        </div>
                                        <div className="p-1 fw-medium fs-">{ele.title}</div>
                                        <div className="d-flex justify-content-center">
                                            {mode === 'edit' ? (
                                                <div className="d-flex align-items-center">
                                                    <i className="ri-checkbox-circle-fill text-success fs-15 pe-1" />
                                                    <span>Configured</span>
                                                </div>
                                            ) : (
                                                <div className="d-flex align-items-center">
                                                    <i className="ri-error-warning-line text-danger fs-15 pe-1" />
                                                    <span>Not Configured</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="p-2 text-muted fs-12">{ele.info}</div>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EnrollmentSettings;
