import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import delGeo from '../../../assets/images/common/png/delete/geoDel.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { geoFenceSchema } from '../TableSchema';

const GeoFence = () => {
    document.title = 'Geo Fence';
    const urlconf = useEnv();
    let history = useHistory();
    let api = new APIClient();

    const [geoFences, setGeoFences] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 2000, configType: 'GEOFENCE' });
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedGeoFence, setSelectedGeoFence] = useState('');
    const [geoFencesBackup, setGeoFencesBackup] = useState([]);
    const [tableSchema, setTableSchema] = useState([]);
    const [showCloneModal, setShowCloneModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    useEffect(() => {
        setLoading(true);
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.CONFIGURATION_EDITOR);
        let schema = [...geoFenceSchema];
        if (findPriv) schema = geoFenceSchema.filter((item, index) => index < geoFenceSchema.length - 1);
        setTableSchema([...schema]);
        getGeoFences({ ...searchParams });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getGeoFences = (params) => {
        api.get(url.DEVICE_AUTOMATION, { page: 1, size: 10, type: 'GEOFENCE' })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((data) => {
                        data.assingGroups = data.assign ? data.assign : '—';
                        data.policiesApplied = data.policies?.length > 0 ? data.policies?.join(',') : '—';
                        data.doNotDelete = data.policies?.length > 0;
                        data.description = data.config?.description ? data.config?.description : '—';
                        data.latitude = data.config?.latitude ? data.config?.latitude : '—';
                        data.longitude = data.config?.longitude ? data.config?.longitude : '—';
                        data.modifiedBy = data.lastmodifiedBy ? data.lastmodifiedBy : 'Tectoro';
                        data.modifiedDate = data.lastmodifiedTime ? getDate(data.lastmodifiedTime) : '—';
                    });
                }
                setTotalRecords(resp.totalRecords);
                setGeoFences(resp.data);
                setGeoFencesBackup(resp.data);
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleRemoveAddress = (row) => {
        setSelectedGeoFence(row.original);
        setDeleteModal(true);
    };

    const handleEditGeofence = (row) => {
        history.push('/geofence/edit/' + row.original.id);
    };

    const handleAddNew = () => {
        history.push('/geofence/add');
    };

    const handleSearch = (val) => {
        setSearchVal(val);
        let filteredGeoFences = [...geoFencesBackup];
        if (val?.length > 0)
            filteredGeoFences = geoFencesBackup.filter((fence) => fence?.name?.toLowerCase()?.includes(val?.toLowerCase()));
        setTotalRecords(filteredGeoFences.length);
        setGeoFences(filteredGeoFences);
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        setDeleteModal(false);
        api.delete(url.DEVICE_AUTOMATION + '/geofence/' + selectedGeoFence?.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.geofenceDeleted);
                    getGeoFences({ ...searchParams });
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to delete Geo-Fence?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={delGeo} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>
                    Geo-Fence Name: <span className="fw-semibold">{selectedGeoFence?.name}</span>
                </div>
            </div>
        );
    };

    /*
     * const handleClickView = (row) => {
     *     history.push('/geofence/view/' + row.original.id);
     * };
     */

    const handleDownloadFirewall = (row) => {};

    const handleClone = (row) => {
        validation.resetForm();
        setSelectedRow(row);
        setShowCloneModal(true);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { name: '' },
        onSubmit: (values) => {
            setLoading(true);
            let obj = { name: values?.name, configType: 'GEOFENCE', config: selectedRow?.original?.config };
            api.create(url.CONFIG, obj)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        toast.success(toastMessages.geofenceClone);
                        getGeoFences({ ...searchParams });
                        closeModal();
                    }
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        }
    });

    const closeModal = () => {
        setShowCloneModal(false);
        setDeleteModal(false);
        validation.setValues({ ...validation.values, name: '' });
    };

    const handleCloneModalBody = () => {
        return (
            <Form
                className="h-100"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col xs={12} sm={12} md={12} xl={6} lg={6}>
                        <Label className="form-label d-flex align-items-center fw-medium">
                            Geofence Name
                            <span className="red-color ps-1">*</span>
                        </Label>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={6} lg={6}>
                        <div className="input-group">
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter Geofence Name"
                                validate={{ required: { value: true } }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ''}
                                invalid={validation.touched.name && validation.errors.name ? true : false}
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Geo Fence" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Geo Fences'}
                        isGlobalFilter={true}
                        searchFilter2={true}
                        searchPlaceHolder={'Name'}
                        handleGlobalSearch={handleSearch}
                        addButton={true}
                        columns={tableSchema}
                        customPageSize={10}
                        handleDownloadFirewall={handleDownloadFirewall}
                        handleDelete={handleRemoveAddress}
                        handleEdit={handleEditGeofence}
                        handleClone={handleClone}
                        // handleClickView={handleClickView}
                        handleAddNew={handleAddNew}
                        reader={priviliges.CONFIGURATION_READER}
                        editor={priviliges.CONFIGURATION_EDITOR}
                        data={geoFences}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={message()}
                        show={deleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        // deleteMessage={`Are you sure you want to remove this device group code -${selectedRow?.name} ?`}
                    />
                    <CommonModal
                        size={'md'}
                        show={showCloneModal}
                        disabled={!validation.values.name || loading}
                        modalheader={'Clone Geofence'}
                        onCloseClick={() => {
                            setShowCloneModal(false);
                            validation.resetForm();
                        }}
                        handleClick={() => validation.handleSubmit()}
                        handleModalBody={() => handleCloneModalBody()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default GeoFence;
