/* eslint-disable max-nested-callbacks */
import React from 'react';
import CountUp from 'react-countup';
import TotalDevices from '../../assets/images/android/svg/TotalDevices.svg';
import ActiveDevices from '../../assets/images/android/svg/ActiveDevices.svg';
import ProvisioningDevices from '../../assets/images/android/svg/ProvisioningDevices.svg';
import notEnrolled from '../../assets/images/android/svg/NotEnrolled.svg';
import on from '../../assets/images/android/svg/ON.svg';
import off from '../../assets/images/android/svg/OFF.svg';
import { Card, CardBody, Col } from 'reactstrap';
import { Tooltip } from '../../Components/Common/Util';
import TotalTvDevices from '../../assets/images/tv/svg/totalTvs.svg';
import ActiveTvDevices from '../../assets/images/tv/svg/activeTvs.svg';
import tvOn from '../../assets/images/tv/svg/tvOn.svg';
import tvOff from '../../assets/images/tv/svg/tvOff.svg';
import notEnrolledTv from '../../assets/images/tv/svg/tvUnenrolled.svg';
import AndroidImage from '../../assets/images/android/png/android_image.png';
import TvImage from '../../assets/images/tv/png/Tv_image.png';
import iosImage from '../../assets/images/apple/png/common/ios_image.png';
import windowsImage from '../../assets/images/windows/png/windows_image.png';
import windowsTotalDevices from '../../assets/images/windows/svg/common/windowsTotalDevices.svg';
import windowsActiveDevices from '../../assets/images/windows/svg/common/windowsActiveDevices.svg';
import windowsNotEnrolledDevices from '../../assets/images/windows/svg/common/windowsNotEnrolled.svg';

const DashboardWidgets = (props) => {
    const allWidgetsData = [
        {
            id: 1,
            label: 'Total',
            color: '155BB3',
            counter: props?.widgetCount?.totalCount,
            icon: TotalDevices,
            helpText:
                'The total device count includes New Devices, Active Devices, Provisioning Devices, Disabled Devices, and Deleted Devices.',
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 2,
            label: 'Provisioning',
            color: 'E88331',
            helpText:
                '"Provisioning" status indicates devices that are currently undergoing setup (enrollment) but have not yet finished the process.',
            counter: props?.widgetCount?.deviceStatusCount?.PROVISIONING,
            icon: ProvisioningDevices,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 3,
            label: 'Enrolled',
            color: '75C16F',
            helpText: 'Devices that are enrolled and ready for use are classified as "Enrolled Devices".',
            counter:
                (props?.widgetCount?.deviceStatusCount?.ACTIVE ? Number(props?.widgetCount?.deviceStatusCount?.ACTIVE) : 0) +
                (props?.widgetCount?.deviceStatusCount?.DISABLED ? Number(props?.widgetCount?.deviceStatusCount?.DISABLED) : 0),
            icon: ActiveDevices,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 4,
            label: 'Not Enrolled',
            color: 'CD4B36',
            helpText:
                'Devices previously enrolled in EMM (Enterprise Mobility Management) that undergo a factory reset and are not re-enrolled will appear as "Not enrolled devices."',
            counter:
                (props?.widgetCount?.deviceStatusCount?.NEW ? Number(props?.widgetCount?.deviceStatusCount?.NEW) : 0) +
                (props?.widgetCount?.deviceStatusCount?.DELETED ? Number(props?.widgetCount?.deviceStatusCount?.DELETED) : 0) +
                (props?.widgetCount?.deviceStatusCount?.DELETING ? Number(props?.widgetCount?.deviceStatusCount?.DELETING) : 0),
            icon: notEnrolled,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 5,
            label: 'Online',
            color: '538F47',
            helpText: 'The devices which are connected to Internet in the past 24 hours are displayed in "Online"',
            counter: props?.widgetCount?.onlineCount?.ONLINE,
            icon: on,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 6,
            label: 'Offline',
            color: 'E14224',
            helpText: 'The devices which are not connected to Internet in the past 24 hours are displayed in OFF  ',
            counter:
                (props?.widgetCount?.onlineCount?.[''] ? Number(props?.widgetCount?.onlineCount?.['']) : 0) +
                (props?.widgetCount?.onlineCount?.OFFLINE ? Number(props?.widgetCount?.onlineCount?.OFFLINE) : 0) +
                (props?.widgetCount?.onlineCount?.AWAY ? Number(props?.widgetCount?.onlineCount?.AWAY) : 0),
            icon: off,
            decimals: 0,
            suffix: '',
            prefix: ''
        }
    ];

    const tvAllWidgetsData = [
        {
            id: 1,
            label: 'Total',
            color: '155BB3',
            counter: props?.widgetCount?.totalCount,
            icon: TotalTvDevices,
            helpText: 'Number of devices enrolled in the portal.',
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 2,
            label: 'Enrolled',
            color: '75C16F',
            counter: props?.widgetCount?.enrolledCount,
            icon: ActiveTvDevices,
            helpText: 'Device currently registered in the EMM system for remote management and policy control.',
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 3,
            label: 'Not Enrolled',
            color: 'CD4B36',
            counter: props?.widgetCount?.notEnrolledCount,
            icon: notEnrolledTv,
            helpText: 'Previously enrolled but no longer in the portal.',
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 4,
            label: 'Online',
            color: '538F47',
            counter: props?.widgetCount?.onlineCount,
            helpText: 'Devices currently connected to the internet.',
            icon: tvOn,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 5,
            label: 'Offline',
            color: 'E14224',
            counter: props?.widgetCount?.offlineCount,
            helpText: 'Devices not connected to the internet.',
            icon: tvOff,
            decimals: 0,
            suffix: '',
            prefix: ''
        }
    ];

    const dashboardWidgetsData = [
        {
            id: 1,
            label: 'Total',
            color: '155BB3',
            counter: 100,
            icon: AndroidImage,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 2,
            label: 'Android',
            color: '75C16F',
            counter: 40,
            icon: AndroidImage,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 3,
            label: 'Android TV',
            color: 'CD4B36',
            counter: 40,
            icon: TvImage,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 4,
            label: 'Apple iOS',
            color: '538F47',
            counter: 20,
            icon: iosImage,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 5,
            label: 'Windows',
            color: 'E14224',
            counter: 0,
            icon: windowsImage,
            decimals: 0,
            suffix: '',
            prefix: ''
        }
    ];
    const iosWidgetData = [
        {
            id: 1,
            label: 'Total',
            color: '155BB3',
            counter: props.devices?.total || 0,
            icon: TotalDevices,
            helpText:
                'The total device count includes New Devices, Active Devices, Provisioning Devices, Disabled Devices, and Deleted Devices.',
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 2,
            label: 'Provisioning',
            color: 'E88331',
            helpText:
                '"Provisioning" status indicates devices that are currently undergoing setup (enrollment) but have not yet finished the process.',
            counter: props.devices?.provisioning || 0,
            icon: ProvisioningDevices,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 3,
            label: 'Enrolled',
            color: '75C16F',
            helpText: 'Devices that are enrolled and ready for use are classified as "Enrolled Devices".',
            counter: props.devices?.enrolled || 0,
            icon: ActiveDevices,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 4,
            label: 'Not Enrolled',
            color: 'CD4B36',
            helpText:
                'Devices previously enrolled in EMM (Enterprise Mobility Management) that undergo a factory reset and are not re-enrolled will appear as "Not enrolled devices."',
            counter: props.devices?.notEnrolled || 0,
            icon: notEnrolled,
            decimals: 0,
            suffix: '',
            prefix: ''
        }
    ];
    const windowsWidgetData = [
        {
            id: 1,
            label: 'Total',
            color: '155BB3',
            counter: props.devices?.total || 0,
            icon: windowsTotalDevices,
            helpText: 'The total device count includes Active Devices and Deleted Devices.',
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 2,
            label: 'Enrolled',
            color: '75C16F',
            helpText: 'Devices that are enrolled and ready for use are classified as "Enrolled Devices".',
            counter: props.devices?.active || 0,
            icon: windowsActiveDevices,
            decimals: 0,
            suffix: '',
            prefix: ''
        },
        {
            id: 3,
            label: 'Not Enrolled',
            color: 'CD4B36',
            helpText:
                'Devices previously enrolled in EMM (Enterprise Mobility Management) that undergo a factory reset and are not re-enrolled will appear as "Not enrolled devices."',
            counter: props.devices?.deleted || 0,
            icon: windowsNotEnrolledDevices,
            decimals: 0,
            suffix: '',
            prefix: ''
        }
    ];
    return (
        <React.Fragment>
            {(props.tv
                ? tvAllWidgetsData
                : props.dashboard
                ? dashboardWidgetsData
                : props.ios
                ? iosWidgetData
                : props.windows
                ? windowsWidgetData
                : allWidgetsData || []
            ).map((item, key) => (
                <Col key={key}>
                    <Card>
                        <CardBody className={`border-start border-2 border-${item.color}`}>
                            <div className="d-flex align-items-center">
                                <img height={40} src={item.icon} alt="" />
                                <div className="flex-grow-1 overflow-hidden ms-3">
                                    <p
                                        className="text-uppercase fw-semibold text-muted text-truncate mb-3 d-flex align-items-center gap-1"
                                        id={`widget-${key}`}
                                    >
                                        {item.label}
                                        {item.label?.length > 15 && Tooltip(`widget-${key}`, item.label)}
                                        {item.helpText && (
                                            <>
                                                <i className="ri-information-line link-info" id={`info-${key}`} />
                                                {Tooltip(`info-${key}`, item.helpText)}
                                            </>
                                        )}
                                    </p>
                                    <div className="d-flex align-items-center mb-3">
                                        <h4 className="fs-16 flex-grow-1 text-truncate text-info mb-0">
                                            <span className="counter-value me-1" data-target="825" id={`count-${key}`}>
                                                <CountUp
                                                    start={0}
                                                    prefix={item.prefix}
                                                    suffix={item.suffix}
                                                    separator={item.separator}
                                                    end={item.counter}
                                                    decimals={item.decimals}
                                                    duration={4}
                                                />
                                                {item.counter?.length > 6 && Tooltip(`count-${key}`, item.counter)}
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </React.Fragment>
    );
};

export default DashboardWidgets;
