/* eslint-disable max-nested-callbacks */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { Enterprise, baseURL, getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import delConfig from '../../../assets/images/common/png/delete/deleteManagedConfig.png';
import noConfig from '../../../assets/images/common/png/common/noConfig.png';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { configsSchema } from '../TableSchema';

const ManagedConfigurations = () => {
    document.title = 'Managed Configurations';
    const urlconf = useEnv();
    let history = useHistory();
    let api = new APIClient();
    let enterprise = Enterprise();
    let enterpriseObj = enterprise ? JSON.parse(enterprise) : {};
    let hashArr = window.location.hash.split('/');

    const [embededToken, setEmbededToken] = useState('');
    const recordID = hashArr[hashArr.length - 1];
    const [offCanvasModal, setOffCanvasModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [app, setApp] = useState({});
    const [mode, setMode] = useState('add');
    const [selectedRow, setSelectedRow] = useState({});
    const [configurations, setConfigurations] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [selectedConfigurations, setSelectedConfigurations] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);
    const [canvasLoader, setCanvasLoader] = useState(false);

    useEffect(() => {
        setLoading(true);
        getEmbededToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEmbededToken = () => {
        let EMBEDEDURL = url.EMBEDED_URL;
        let params = { enterpriseId: enterpriseObj.id, parentUrl: baseURL() };
        api.get(EMBEDEDURL, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    setEmbededToken(resp.data);
                    getApps();
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const getApps = () => {
        let params = { enterpriseId: enterpriseObj.id, page: 1, size: 2000 };
        api.get(url.APPLICATIONS, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data.length > 0) {
                    let appObj = resp.data.find((obj) => obj.packageName === recordID);
                    setApp(appObj);
                    getConfigs(appObj.packageName);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const getConfigs = (packageName) => {
        api.get(url.MANAGED_CONFIGURATIONS, { page: 1, size: 100, packageName: packageName })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((data, ind) => {
                        data.appliedPolicies = data.appliedPolicies ? data.appliedPolicies : 0;
                        data.appliedPolicies = 27 - ind > 0 ? 27 - ind : -1 * (27 - ind);
                        data.createdDate = getDate(data.createdTime);
                    });
                    setConfigurations(resp.data);
                    setTotalRecords(resp.totalRecords);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleOffCanvasBody = () => {
        if (embededToken && app.packageName) {
            const mcmId = selectedRow?.managedConfigId;
            var URL =
                mcmId && mode === 'edit'
                    ? `https://play.google.com/managed/mcm?token=${embededToken}&packageName=${app.packageName}&mcmId=${mcmId}`
                    : `https://play.google.com/managed/mcm?token=${embededToken}&packageName=${app.packageName}`;

            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/api.js';

            script.onload = () => {
                window.gapi.load('gapi.iframes', () => {
                    var options = {
                        url: URL,
                        where: document.getElementById('container'),
                        attributes: { style: 'width: 100%; height:1000px', scrolling: 'yes', showsearchbox: true, search: true }
                    };
                    var iframe = window.gapi.iframes.getContext().openChild(options);
                    iframe.register(
                        'onconfigupdated',
                        function (event) {
                            setCanvasLoader(true);
                            let obj = { managedConfigId: event.mcmId, title: event.name, packageName: app.packageName };
                            let apiService = '';
                            if (mode === 'edit') apiService = api.update(url.MANAGED_CONFIGURATIONS + '/' + selectedRow.id, obj);
                            else apiService = api.create(url.MANAGED_CONFIGURATIONS, obj);

                            apiService.then((resp) => {
                                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                                setCanvasLoader(false);
                                setOffCanvasModal(false);
                                if (resp.status === 'success') {
                                    setLoading(true);
                                    setSelectedRow({});
                                    getConfigs(app.packageName);
                                    setLoading(false);
                                }
                            });
                        },
                        window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
                    );
                });
            };
            document.body.appendChild(script);
        }
        return (
            <div className="col-md-12">
                <div id="container"></div>
            </div>
        );
    };

    const handleCanvasModalClose = useCallback(async () => {
        setSelectedRow({});
        setMode('add');
        if (offCanvasModal) setOffCanvasModal(false);
        else setOffCanvasModal(true);
    }, [offCanvasModal]);

    const handleDeleteOneConfirmation = () => {
        setLoading(false);
        api.delete(url.MANAGED_CONFIGURATIONS + '/' + selectedRow?.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    setLoading(true);
                    getConfigs(app.packageName);
                }
                setDeleteModal(false);
                setSelectedRow({});
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleDeleteOne = (row) => {
        setSelectedRow(row.original);
        setDeleteModal(true);
    };

    const handleEdit = (row) => {
        setMode('edit');
        setSelectedRow(row.original);
        setOffCanvasModal(true);
    };

    const handleAdd = () => {
        setMode('add');
        setOffCanvasModal(true);
    };

    const handleCheckboxChange = (e, ind, row) => {
        let rows = [...configurations];
        rows[ind].checked = e.target.checked;
        if (e.target.checked) setSelectedConfigurations([...selectedConfigurations, row.original]);
        else {
            let selDevices = [...selectedConfigurations];
            selDevices = selDevices.filter((device) => device.managedConfigId !== row.original.managedConfigId);
            setSelectedConfigurations(selDevices);
        }
        setConfigurations(rows);
        let unselectedRows = rows.filter((rowObj) => !rowObj.checked);
        if (unselectedRows?.length > 0) setCheckAll(false);
        else setCheckAll(true);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete Managed Configuration?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={delConfig} alt="deletepolicy" width={60} height={60} />
                </div>
                <div>
                    Configuration Name: <span className="fw-semibold">{selectedRow?.title}</span>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        showInNormalCase={true}
                        pageTitle={app.title}
                        showIcon={true}
                        showBack={true}
                        backLink="aeapps"
                        icon={app.smallIconUrl}
                        history={history}
                        homeLink="Dashboard"
                    />
                    {app.configuration === 'YES' || app.appType === 'Launcher' ? (
                        <React.Fragment>
                            <TableContainer
                                loading={loading}
                                data={configurations}
                                isGlobalFilter={true}
                                tableHeader={'All Configurations'}
                                addButton={true}
                                handleAddNew={handleAdd}
                                handleEdit={handleEdit}
                                handleDelete={handleDeleteOne}
                                handleCheckboxChange={handleCheckboxChange}
                                checkAll={checkAll}
                                // totalRecords={totalRecords}
                                columns={configsSchema}
                                reader={priviliges.POLICY_READER}
                                editor={priviliges.POLICY_EDITOR}
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-nowrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                            />
                        </React.Fragment>
                    ) : (
                        !loading && (
                            <React.Fragment>
                                <div className="w-100 height-300 d-flex gap-3 flex-column align-items-center justify-content-center">
                                    <img src={noConfig} alt="noConfig" height={150} width={150} />
                                    <span className="text-danger">No Configuration Available On This Application.</span>
                                    <Button size="sm" id="export" onClick={() => history.push('/applications')}>
                                        <span className="d-flex align-items-center">Go Back</span>
                                    </Button>
                                </div>
                            </React.Fragment>
                        )
                    )}
                    {offCanvasModal && (
                        <OffcanvasModal
                            direction="end"
                            modalClassName="w-50"
                            open={offCanvasModal}
                            hideSaveButton={true}
                            toggle={handleCanvasModalClose}
                            handleCloseClick={handleCanvasModalClose}
                            OffcanvasModalID="managedConfigModal"
                            handleOffcanvasBody={handleOffCanvasBody}
                            offcanvasHeader="Managed Configuration"
                            loading={canvasLoader}
                        />
                    )}
                    <DeleteModal
                        hideIcon={true}
                        show={deleteModal}
                        hideDeleteMessage={true}
                        message={message()}
                        confirmText={'Delete'}
                        onDeleteClick={handleDeleteOneConfirmation}
                        onCloseClick={() => {
                            setDeleteModal(false);
                            setSelectedRow({});
                        }}
                        deleteMessage={'Are you sure you want to delete this managed configuration ?'}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManagedConfigurations;
