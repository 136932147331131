// import images
import logoLg from '../../assets/images/common/svg/companies/logo-lg.svg';
import tectoroAuthBg from '../../assets/images/common/png/companies/tectoro-auth-bg2.png';
import chaitanyaAuthBg from '../../assets/images/common/png/companies/chaitanya_slider.png';
import chaitanyaLg from '../../assets/images/common/svg/companies/chaitanya_logo.svg';
import apseLg from '../../assets/images/common/png/companies/apse-logo.png';
import TectoroFavicon from '../../assets/images/favicon/favicon.ico';
import ChaitanyaFavicon from '../../assets/images/favicon/chaitanya-favicon.ico';
import ApseFavicon from '../../assets/images/favicon/apse-favicon.ico';

const tenantConfig = {
    TECTORO: {
        COMPANY_LOGO: logoLg,
        COMPANY_SLIDE_BG1: tectoroAuthBg,
        COMPANY_ID: 'TECTORO',
        COMPANY_NAME: 'Tectoro Consulting Pvt Ltd.',
        PRIMARY_HEADER: 'Enterprise Mobility Solution',
        SECONDARY_HEADER: 'Tectoro Consulting Pvt Ltd.',
        COMPANY_DEVELOPED_BY: 'Enterprise Mobility Solution',
        COMAPNY_PRIMARY_COLOR: '#405189',
        COMAPNY_SECONDARY_COLOR: '#3577f1',
        COMAPNY_SUCCESS_COLOR: '#0ab39c',
        COMPANY_FOOTER: 'All rights reserved',
        COMPANY_FAVICON: TectoroFavicon,
        COMPANY_POLICY_ACCESS: ['FM', 'WP', 'DD'],
        ONLINE: 1,
        OFFLINE: 3,
        ONLINE_UNIT: 'days',
        OFFLINE_UNIT: 'days',
        CREATED_DATE: '2023-01-24 11:30:11.758',
        DEVICE_DEFAULT_PASSWORD: 'Tectoro@123',
        MAX_FILE_SIZE_QUANTITY: '1',
        MAX_FILE_SIZE_UNIT: 'TB'
    },
    CHAITANYA: {
        COMPANY_LOGO: chaitanyaLg,
        COMPANY_SLIDE_BG1: chaitanyaAuthBg,
        COMPANY_ID: 'CHAITANYA',
        COMPANY_NAME: 'Sri Chaitanya Educational Institutions',
        PRIMARY_HEADER: 'Enterprise Mobility Solution',
        SECONDARY_HEADER: 'Sri Chaitanya Educational Institutions',
        COMPANY_DEVELOPED_BY: 'Enterprise Mobility Solution',
        COMAPNY_PRIMARY_COLOR: '#405189',
        COMAPNY_SECONDARY_COLOR: '#3577f1',
        COMAPNY_SUCCESS_COLOR: '#0ab39c',
        COMPANY_FOOTER: 'All rights reserved',
        COMPANY_FAVICON: ChaitanyaFavicon,
        COMPANY_POLICY_ACCESS: ['FM'],
        ONLINE: 1,
        OFFLINE: 3,
        ONLINE_UNIT: 'days',
        OFFLINE_UNIT: 'days',
        DEVICE_DEFAULT_PASSWORD: 'Chaitanya@123',
        MAX_FILE_SIZE_QUANTITY: '1',
        MAX_FILE_SIZE_UNIT: 'TB'
    },
    APSE: {
        COMPANY_LOGO: apseLg,
        COMPANY_SLIDE_BG1: tectoroAuthBg,
        COMPANY_ID: 'APSE',
        COMPANY_NAME: 'Department of School Education',
        PRIMARY_HEADER: 'Enterprise Mobility Solution',
        SECONDARY_HEADER: 'Department of School Education',
        COMPANY_DEVELOPED_BY: 'Enterprise Mobility Solution',
        COMAPNY_PRIMARY_COLOR: '#405189',
        COMAPNY_SECONDARY_COLOR: '#3577f1',
        COMAPNY_SUCCESS_COLOR: '#0ab39c',
        COMPANY_FOOTER: 'All rights reserved',
        COMPANY_FAVICON: ApseFavicon,
        COMPANY_POLICY_ACCESS: ['FM'],
        ONLINE: 1,
        OFFLINE: 3,
        ONLINE_UNIT: 'days',
        OFFLINE_UNIT: 'days',
        CREATED_DATE: '2023-01-24 11:30:11.758',
        DEVICE_DEFAULT_PASSWORD: 'Apse@123',
        MAX_FILE_SIZE_QUANTITY: '1',
        MAX_FILE_SIZE_UNIT: 'TB'
    },
    AROG: {
        COMPANY_LOGO: apseLg,
        COMPANY_SLIDE_BG1: tectoroAuthBg,
        COMPANY_ID: 'AROG',
        COMPANY_NAME: 'Dr YSR Aarogyasri Health Care Trust.',
        PRIMARY_HEADER: 'Dr YSR Aarogyasri Health Care Trust',
        SECONDARY_HEADER: 'Government of Andhra Pradesh',
        COMPANY_DEVELOPED_BY: 'Enterprise Mobility Solution',
        COMAPNY_PRIMARY_COLOR: '#405189',
        COMAPNY_SECONDARY_COLOR: '#3577f1',
        COMAPNY_SUCCESS_COLOR: '#0ab39c',
        COMPANY_FOOTER: 'All rights reserved',
        COMPANY_FAVICON: ApseFavicon,
        COMPANY_POLICY_ACCESS: ['FM', 'WP', 'DD'],
        ONLINE: 1,
        OFFLINE: 3,
        ONLINE_UNIT: 'days',
        OFFLINE_UNIT: 'days',
        CREATED_DATE: '2023-01-24 11:30:11.758',
        DEVICE_DEFAULT_PASSWORD: 'Tectoro@123',
        MAX_FILE_SIZE_QUANTITY: '1',
        MAX_FILE_SIZE_UNIT: 'TB'
    }
};

export { tenantConfig };
