import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import toastMessages from '../../common/messages/toastMessages';
import { Dynamicomponents } from './EnrollmentDynamicComponents';
import { deviceSchema } from '../../Components/constants/constants';
import { getFormTypeAndRecordId, TenantConfig } from '../../Components/Common/Util';
import Loader from '../../Components/Common/Loader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { APIClient } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import * as domains from '../../helpers/domain_helper';
import { useEnv } from '../../envContext';

const AddEnrollmentSettings = () => {
    const api = new APIClient();
    const config = useEnv();
    const tenantData = TenantConfig();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        platform: 'any'
    });
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    const formType = formTypeAndId['formType'];

    document.title = `${formType.charAt(0).toUpperCase() + formType.slice(1)} Device Allocation`;

    useEffect(() => {
        getRestriction();
    }, []);

    const getRestriction = () => {
        setLoading(true);
        api.get(url.DEVICE_RESTRICTIONS, '', domains.MDM_COMMONS)
            .then((resp) => {
                if (resp.status === 'success' && resp.data.length > 0) {
                    const response = resp.data[0];

                    const obj = {
                        noOfDevices: response.UserLimit,
                        platform: response.IsSpecific === true ? 'specific' : 'any',
                        android: response.Android,
                        windows: response.Windows,
                        ios: response.IOS,
                        ID: response.ID
                    };
                    setFormData(obj);
                }
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formData },
        onSubmit: (values) => {
            const obj = {
                userLimit: Number(values?.noOfDevices),
                isSpecific: values?.platform === 'specific' ? true : false,
                Android: values?.platform === 'specific' ? Number(values?.android) : undefined,
                IOS: values?.platform === 'specific' ? Number(values?.ios) : undefined,
                Windows: values?.platform === 'specific' ? Number(values?.windows) : undefined
            };
            setLoading(true);
            const serviceCall =
                formType === 'add'
                    ? api.create(url.DEVICE_RESTRICTIONS, obj, false, domains.MDM_COMMONS)
                    : api.patch(url.DEVICE_RESTRICTIONS + '/' + formData.ID, obj, false, domains.MDM_COMMONS);

            serviceCall
                .then((resp) => {
                    resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status.toLowerCase() === 'success') {
                        toast.success(formType === 'add' ? toastMessages.deviceAllocationCreated : toastMessages.deviceAllocationUpdated);
                    }
                    setLoading(false);
                })
                .catch((error) => setLoading(false));
            validation.resetForm();
            history.push('/enrollmentsettings');
        }
    });

    const handleDisableSave = () => {
        let flag = [];
        flag.push(!Number(validation?.values?.noOfDevices));
        flag.push(validation?.values?.platform === '');
        if (validation?.values?.platform === 'specific') {
            flag.push(!validation?.values?.windows && !validation?.values?.android && !validation?.values?.ios);
        }

        const totalDevices =
            (validation?.values?.windows ? Number(validation?.values?.windows) : 0) +
            (validation?.values?.android ? Number(validation?.values?.android) : 0) +
            (validation?.values?.ios ? Number(validation?.values?.ios) : 0);

        if (validation?.values?.platform === 'specific' && Number(validation?.values?.noOfDevices) !== totalDevices) flag.push(true);

        if (flag.includes(true)) return true;
        else return false;
    };

    const handleShowOn = (field) => {
        let flag = true;
        flag = validation?.values[field?.showOn] === field?.showVal ? true : false;
        if (flag) flag = flag && field?.enableOn ? tenantData?.[field?.enableOn] : true;
        return flag;
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={`${formType.charAt(0).toUpperCase() + formType.slice(1)} Device Allocation`}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink={'enrollmentsettings'}
                    />
                    <Card>
                        <CardHeader>
                            <div className="fs-14 fw-semibold">Device Allocation</div>
                            <div className="mt-1">
                                Restrict the enrolment process to authenticated user by configuring user restrictions and platform
                                restrictions
                            </div>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    {deviceSchema?.map((field, index) => (
                                        <div className="mt-2" key={index}>
                                            {(field.showOn ? handleShowOn(field) : true) &&
                                                Dynamicomponents({
                                                    field: field,
                                                    validation: validation,
                                                    formType: formType,
                                                    index: index
                                                })}
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <div className="d-flex justify-content-end ">
                                <Button
                                    className="bg-white border-primary text-primary"
                                    onClick={() => {
                                        validation.resetForm();
                                        history.push('/enrollmentsettings');
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={validation.handleSubmit}
                                    disabled={handleDisableSave()}
                                    className="mx-2 border-primary"
                                >
                                    {formType === 'add' ? 'Save' : 'Update'}
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddEnrollmentSettings;
