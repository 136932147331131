import toastMessages from '../../common/messages/toastMessages';

export const androidTVSchema = [
    /*
     * {
     *     Header: 'checkbox',
     *     customHeader: true,
     *     headerType: 'checkbox',
     *     customCell: true,
     *     fieldType: 'checkbox',
     *     accessor: 'checked',
     *     thClass: 'width-50 max-width-50 min-width-50 d-flex align-items-center',
     *     className: 'width-50 max-width-50',
     *     width: 50,
     *     maxWidth: 50,
     *     minWidth: 50
     * },
     */
    /*
     * {
     *     Header: 'Last Contact',
     *     accessor: 'customField',
     *     customCell: true,
     *     fieldType: 'statusDots',
     *     sortable: false,
     *     thClass: ' width-100 max-width-100',
     *     className: 'width-100 max-width-100',
     *     width: 200,
     *     maxWidth: 120,
     *     minWidth: 120,
     *     onlineStatusTooltip: 'onlineStatusTooltip'
     * },
     */
    {
        Header: 'Device Name',
        accessor: 'serial',
        sortable: true,
        customCell: true,
        width: 200,
        maxWidth: 200,
        minWidth: 200,
        fieldKey: 'serial',
        fieldType: 'fieldClickable',
        view: true,
        copy: true,
        copyToastMsg: toastMessages.devicenameCopied
    },
    {
        Header: 'Brand',
        accessor: 'brand',
        sortable: true,
        width: 10,
        maxWidth: 150,
        minWidth: 150
    },
    {
        Header: 'Model',
        accessor: 'model',
        sortable: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    /*
     * {
     *     Header: 'Phone Number',
     *     accessor: 'phone',
     *     sortable: true,
     *     width: 100,
     *     minWidth: 100,
     *     maxWidth: 100
     * },
     */
    {
        Header: 'Policy',
        accessor: 'policy',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'Group',
        accessor: 'group',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    /*
     * {
     *     Header: 'User',
     *     accessor: 'user',
     *     sortable: true,
     *     width: 150,
     *     maxWidth: 150,
     *     minWidth: 150
     * },
     * {
     *     Header: 'Device Type',
     *     accessor: 'source',
     *     sortable: true,
     *     width: 150,
     *     maxWidth: 150,
     *     minWidth: 150
     * },
     */
    {
        Header: 'OS Version',
        accessor: 'osVersion',
        sortable: true,
        window: 50,
        minWidth: 50,
        maxWidth: 50
    },
    /*
     * {
     *     Header: 'Security Patch',
     *     accessor: 'lastSecurityPatch',
     *     sortable: false,
     *     width: 150,
     *     maxWidth: 150,
     *     minWidth: 150,
     *     customCell: true,
     *     fieldType: 'dateFormat',
     *     key: 'lastSecurityPatch',
     *     dateOnly: true
     * },
     */
    {
        Header: 'Device Status',
        accessor: 'deviceStatus',
        sortable: true,
        customCell: true,
        fieldType: 'badge',
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    { Header: 'Modified By', accessor: 'modifiedby', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        lock: true,
        factoryreset: true,
        power: true,
        reboot: true
        /*
         * notes: true,
         * map: true
         */
    }
];

export const androidTVAppSchema = [
    /*
     * {
     *     Header: 'Name',
     *     accessor: 'title',
     *     width: 250,
     *     maxWidth: 250,
     *     minWidth: 250,
     *     sortable: true
     * },
     */
    {
        Header: 'Package Name',
        accessor: 'packagename',
        width: 250,
        maxWidth: 250,
        minWidth: 250,
        sortable: true
    },
    {
        Header: 'Version Code',
        accessor: 'code',
        width: 250,
        maxWidth: 250,
        minWidth: 250,
        sortable: true
    }
];

export const androidTvAppsSchema = [
    {
        Header: 'App Name',
        accessor: 'name',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true,
        customCell: true,
        fieldType: 'clickable',
        view: true
    },
    { Header: 'Package', accessor: 'packageName', width: 200, maxWidth: 200, minWidth: 200, sortable: true },
    { Header: 'Version', accessor: 'appversion', width: 150, maxWidth: 150, minWidth: 150, sortable: true },
    { Header: 'Modified By', accessor: 'modifiedby', width: 150, minWidth: 150, maxWidth: 150, sortable: true },
    { Header: 'Modified Date', accessor: 'modifiedDate', width: 150, minWidth: 150, maxWidth: 150, sortable: false },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        edit: true,
        delete: true,
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];

export const tvPoliciesSchema = [
    {
        Header: 'Policy Name',
        accessor: 'name',
        sortable: true,
        customCell: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        fieldType: 'clickable',
        view: true
    },
    {
        Header: 'Policy Code',
        accessor: 'code',
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        sortable: true
    },
    {
        Header: 'Policy Type',
        accessor: 'polType',
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        sortable: true
    },
    {
        Header: 'Policy Version',
        accessor: 'version',
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        sortable: true
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: false
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        edit: true,
        // qrCode: true,
        clone: true,
        deleteFadable: true,
        enrollmentToken: true
        /*
         * deleteFadable: true,
         * fadeTooltip: 'You cannot delete this policy, as it is already applied to a device.',
         * fade: 'doNotDelete',
         * clone: true,
         */
    }
];

export const TVLauncherSetupSchema = [
    {
        Header: 'Name',
        accessor: 'name',
        customCell: true,
        fieldType: 'clickable',
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150
    },
    /*
     * {
     *     Header: 'Package Name',
     *     accessor: 'packageName',
     *     sortable: true,
     *     width: 150,
     *     maxWidth: 150,
     *     minWidth: 150
     * },
     */
    { Header: 'Policies', accessor: 'policies', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified By', accessor: 'modifiedBy', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        edit: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this configuration, as it is already being used in a policy',
        fade: 'doNotDelete',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];
