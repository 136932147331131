const TVSettingFieldsArray = [
    {
        heading: 'Network Settings',
        bgClass: 'bg-e0ebf8 text-primary fw-semibold ',
        hideAll: true
    },
    {
        heading: 'Wi-Fi Settings',
        image: 'wifi',
        value: 'show_wifi',
        password_value: 'req_admin_password_wifi',
        password_type: 'wifi',
        password: 'wifi',
        req_timer: 'req_timer_wifi',
        timer: 'timer_wifi'
    },
    {
        heading: 'Bluetooth',
        image: 'bluetooth',
        value: 'show_bluetooth',
        password_value: 'req_admin_password_bluetooth',
        password_type: 'bluetooth',
        password: 'bluetooth',
        req_timer: 'req_timer_bluetooth',
        timer: 'timer_bluetooth'
    },
    { heading: 'Display Settings', bgClass: 'bg-e0ebf8 text-primary fw-semibold ', hideAll: true },
    {
        heading: 'Screen Orientation',
        image: 'screenOrientation',
        value: 'show_screen_orientation',
        password_value: 'req_admin_password_screen_orientation',
        password_type: 'screen_orientation',
        password: 'screen_orientation',
        req_timer: 'req_timer_screen_orientation',
        timer: 'timer_screen_orientation'
    },
    {
        heading: 'Brightness',
        image: 'brightness',
        value: 'show_brigntness',
        password_value: 'req_admin_password_brightness',
        password_type: 'brightness',
        password: 'brightness',
        req_timer: 'req_timer_brightness',
        timer: 'timer_brightness'
    },
    { heading: 'Device Details', bgClass: 'bg-e0ebf8 text-primary fw-semibold ', hideAll: true },
    {
        heading: 'Device details',
        image: 'details',
        value: 'show_device_details',
        password_value: 'req_admin_password_device_details',
        password_type: 'details',
        password: 'details',
        req_timer: 'req_timer_device_details',
        timer: 'timer_device_details'
    },
    { heading: 'Control Settings', bgClass: 'bg-e0ebf8 text-primary fw-semibold ', hideAll: true },
    {
        heading: 'System Settings',
        image: 'systemSettings',
        value: 'show_system_settings',
        password_value: 'req_admin_password_system_settings',
        password_type: 'system_settings',
        password: 'system_settings',
        req_timer: 'req_timer_system_settings',
        timer: 'timer_system_settings'
    },
    {
        heading: 'Exit QR',
        image: 'exitqr',
        value: 'show_kiosk_exit',
        hideOnTenant: true,
        tenantkey: 'KIOSK_EXIT',
        password_value: 'req_admin_password_kiosk_exit',
        password_type: 'kiosk_exit',
        password: 'kiosk_exit',
        req_timer: 'req_timer_kiosk_exit',
        timer: 'timer_kiosk_exit'
    }
];

export default TVSettingFieldsArray;
