import React from 'react';
import Slider from 'react-rangeslider';
import Select from 'react-select';
import { Alert, Button, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import factoryReset from '../../../assets/images/common/png/common/factoryReset.png';
import lockDevice from '../../../assets/images/common/png/common/lockDevice.png';
import rebootDevice from '../../../assets/images/common/png/common/rebootDevice.png';
import screenshot from '../../../assets/images/common/png/common/screenshot.png';
import deprovision from '../../../assets/images/common/png/common/unlink.png';
import userDelete from '../../../assets/images/common/png/common/userDelete.png';
import Loader from '../../../Components/Common/Loader';
import { IsAuthorized } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import {
    togglePassword,
    togglePasswordChange,
    togglePasswordChangeVal,
    tooglePasswordStrengthIn,
    tooglePasswordStrengthOut
} from '../../../Components/Hooks/UserHooks';

export const handleModalBody = (hideStyles, obj) => {
    return (
        <Row className={'p-4 justify-content-center ' + (obj.resetLoader ? 'mask' : '')}>
            {obj.resetLoader && <Loader />}
            {!hideStyles && (
                <>
                    <div className="text-center mt-2">
                        <h5 className="text-primary">Reset Password?</h5>
                        <p className="text-muted mb-1">
                            Reset your password with <b>{obj.tenantConfig?.COMPANY_DEVELOPED_BY}</b>
                        </p>
                        <i className="ri-lock-password-line font-50 text-success" color="primary"></i>
                    </div>
                    <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                        Please set as new password for the device - {obj.selectedRow?.original?.serial}
                    </Alert>
                </>
            )}
            <Col className="d-flex align-items-center justify-content-center mt-2">
                <div className="form-check form-check-inline">
                    <Input
                        type="radio"
                        value={'true'}
                        id={'default_password'}
                        name={'default_password'}
                        className="form-check-input"
                        onBlur={obj.validation.handleBlur}
                        onChange={obj.validation.handleChange}
                        checked={obj.validation.values['default_password'] === 'true'}
                    />
                    <Label className="form-check-label">{'Default Password'}</Label>
                </div>
                <div className="form-check form-check-inline">
                    <Input
                        type="radio"
                        value={'false'}
                        id={'default_password'}
                        name={'default_password'}
                        className="form-check-input"
                        onBlur={obj.validation.handleBlur}
                        onChange={obj.validation.handleChange}
                        checked={obj.validation.values['default_password'] === 'false'}
                    />
                    <Label className="form-check-label">{'Custom Password'}</Label>
                </div>
            </Col>
            <div className="p-2">
                <Form>
                    {obj.validation.values['default_password'] === 'true' && (
                        <>
                            <div className="d-flex align-items-center justify-content-center mt-4">
                                <Label className="form-label">Default Password :</Label>
                                <Alert className="alert-borderless alert-info text-center mb-2 mx-2" role="alert">
                                    {obj.tenantConfig?.DEVICE_DEFAULT_PASSWORD}
                                </Alert>
                            </div>
                            {!hideStyles && (
                                <div className="text-center mt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        onClick={(e) => obj.handleSubmit({ newPassword: obj.tenantConfig?.DEVICE_DEFAULT_PASSWORD })}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    {obj.validation.values['default_password'] === 'false' && (
                        <>
                            <div className="mb-4">
                                <Label className="form-label">New Password</Label>
                                <div className="position-relative auth-pass-inputgroup">
                                    <Input
                                        onFocus={() => tooglePasswordStrengthIn('password-contain')}
                                        onBlur={(e) => {
                                            obj.validation.handleBlur(e);
                                            togglePasswordChangeVal(obj.validation.values.newPassword) &&
                                                tooglePasswordStrengthOut('password-contain');
                                        }}
                                        name="newPassword"
                                        type="password"
                                        autoComplete="off"
                                        className="form-control pe-5 password-input"
                                        onPaste={() => false}
                                        placeholder="Enter new password"
                                        id="password-input"
                                        aria-describedby="passwordInput"
                                        maxLength={15}
                                        required
                                        onChange={(e) => {
                                            obj.validation.handleChange(e);
                                            togglePasswordChange(e.target.value);
                                        }}
                                        value={obj.validation.values.newPassword || ''}
                                        invalid={obj.validation.touched.newPassword && obj.validation.errors.newPassword ? true : false}
                                    />
                                    {obj.validation.touched.newPassword && obj.validation.errors.newPassword ? (
                                        <FormFeedback type="invalid">
                                            <div>{obj.validation.errors.newPassword}</div>
                                        </FormFeedback>
                                    ) : null}
                                    {obj.validation.values.newPassword && obj.validation.values.newPassword !== '' && (
                                        <Button
                                            color="link"
                                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                            type="button"
                                            onClick={() => togglePassword('password-input', 'password-icon1')}
                                        >
                                            <i id="password-icon1" className="ri-eye-off-fill align-middle"></i>
                                        </Button>
                                    )}
                                </div>
                                <div id="passwordInput" className="form-text">
                                    Must be at least 8 characters.
                                </div>
                            </div>
                            <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                <h5 className="fs-13">Password must contain:</h5>
                                <p id="pass-length" className="invalid fs-12 mb-2">
                                    Minimum <b>8 characters</b>
                                </p>
                                <p id="pass-max-length" className="invalid fs-12 mb-2">
                                    Maximum <b>12 characters</b>
                                </p>
                                <p id="pass-lower" className="invalid fs-12 mb-2">
                                    At least 1 <b>lowercase</b> letter (a-z)
                                </p>
                                <p id="pass-upper" className="invalid fs-12 mb-2">
                                    At least 1 <b>uppercase</b> letter (A-Z)
                                </p>
                                <p id="pass-number" className="invalid fs-12 mb-2">
                                    At least 1 <b>number</b> (0-9)
                                </p>
                                <p id="pass-special" className="invalid fs-12 mb-0">
                                    At least 1 <b>Special</b> Symbol
                                </p>
                            </div>
                            {!hideStyles && (
                                <div className="text-center mt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        onClick={(e) => obj.validation.handleSubmit()}
                                        disabled={!togglePasswordChangeVal(obj.validation.values.newPassword)}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </Form>
            </div>
        </Row>
    );
};
export const handleDeleteBody = (selectedRow) => {
    return (
        <React.Fragment>
            <div className="mt-3 text-center">
                <div className="mb-4">Are you sure you want to reset Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={factoryReset} alt="device" width={60} height={60} />
                </div>
                <div>
                    Serial: <span className="fw-semibold">{selectedRow.original?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    );
};

export const handleNotesModalBody = (validation1) => {
    return (
        <Col>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation1.handleSubmit();
                    return false;
                }}
            >
                <div className="input-group">
                    <IsAuthorized
                        privRequired={priviliges.TVDEVICE_EDITOR}
                        yes={() => (
                            <div className="w-100">
                                <Input
                                    type="textarea"
                                    id="notes"
                                    name="notes"
                                    maxLength={255}
                                    className={
                                        'form-control ' +
                                        (validation1?.values?.notes && validation1?.values?.notes?.length === 255
                                            ? 'border border-danger border-1'
                                            : '')
                                    }
                                    placeholder="Enter Notes"
                                    validate={{ required: { value: true } }}
                                    onChange={validation1.handleChange}
                                    onBlur={validation1.handleBlur}
                                    value={validation1?.values['notes'] || ''}
                                    // invalid={validation1?.touched['notes'] && validation1?.errors['notes'] ? true : false}
                                />
                                <div className="d-flex align-items-center justify-content-end text-muted mt-1">
                                    {validation1?.values?.notes ? validation1?.values?.notes?.length : 0}/255
                                </div>
                            </div>
                        )}
                        no={() => {
                            <span>{validation1?.values['notes'] ? validation1?.values['notes'] : '–'}</span>;
                        }}
                    />
                </div>
                <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                    It may take some time for it to reflect the updated notes.
                </Alert>
            </Form>
        </Col>
    );
};
export const handlePGChangeBody = (options, key, label, bulkAction) => {
    return (
        <Row>
            <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                <Label className="form-label d-flex align-items-center fw-medium">
                    Change To <span className="red-color ps-1">*</span>
                </Label>
            </Col>
            <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                <Select
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option[label]}
                    id={label}
                    name="value"
                    classNamePrefix={'custom-select w-100'}
                    options={options}
                    placeholder={`Select ${key}`}
                    onBlur={() => bulkAction.handleBlur({ target: { name: key?.toLowerCase() } })}
                    onChange={(selectedOption) => bulkAction.handleChange({ target: { name: key?.toLowerCase(), value: selectedOption } })}
                    value={bulkAction.values?.[key?.toLowerCase()] || ''}
                    isSearchable={true}
                    noOptionsMessage={() => 'No data found'}
                />
            </Col>
        </Row>
    );
};

const formatLabel = (value) => {
    return value;
};

export const messageFunc = (type, selectedDevice, volume, setVolume, message, setMessage) => {
    return type === 'lock' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to lock Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={lockDevice} alt="device" width={60} height={60} />
                </div>
                <div className="mb-4">
                    Serial: <span className="fw-semibold">{selectedDevice.original?.serial}</span>
                </div>
                <div>
                    <Row>
                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className={'d-flex align-items-center justify-content-between'}>
                            <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                                Message
                                <span className="ps-1 text-danger">*</span>
                            </Label>
                        </Col>
                        <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                            <div className="input-group">
                                <Input
                                    name={'message'}
                                    id={'message'}
                                    className="form-control"
                                    placeholder={'Enter Message'}
                                    type={'textarea'}
                                    onChange={(e) => setMessage(e.target.value)}
                                    value={message}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'reboot' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to reboot Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={rebootDevice} alt="device" width={60} height={60} />
                </div>
                <div>
                    Device Name: <span className="fw-semibold">{selectedDevice.original?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'deprovision' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to deprovision this Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={deprovision} alt="device" width={60} height={60} />
                </div>
                <div>
                    Device Name: <span className="fw-semibold">{selectedDevice.original?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'wipeUser' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to wipe users on this Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={userDelete} alt="device" width={60} height={60} />
                </div>
                <div>
                    Device Name: <span className="fw-semibold">{selectedDevice.original?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'screenshot' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to take screenshot on this Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={screenshot} alt="device" width={60} height={60} />
                </div>
                <div>
                    Device Name: <span className="fw-semibold">{selectedDevice.original?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'wipe' ? (
        <React.Fragment>
            <div className="mt-3 text-center">
                <div className="mb-4">Are you sure you want to reset Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={factoryReset} alt="device" width={60} height={60} />
                </div>
                <div>
                    Device Name: <span className="fw-semibold">{selectedDevice.original?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'shutdown' ? (
        <React.Fragment>
            <div className="mt-3 text-center">
                <div className="mb-4">Are you sure you want to turn off this Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={factoryReset} alt="device" width={60} height={60} />
                </div>
                <div>
                    Device Name: <span className="fw-semibold">{selectedDevice.original?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div>
                <div>
                    Device Name: <span className="fw-semibold">{selectedDevice.original?.serial}</span>
                </div>
                <Slider
                    className="my-3"
                    min={0}
                    max={100}
                    step={1}
                    format={(value) => formatLabel(value)}
                    onChange={(e) => setVolume(e)}
                    value={volume}
                />
            </div>
        </React.Fragment>
    );
};
