import { Circle, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useState } from 'react';
import greenMarker from '../../../assets/images/common/svg/common/greenmarker.svg';
import redMarker from '../../../assets/images/common/svg/common/redmarker.svg';
import { useEnv } from '../../../envContext';

const MapView = (props) => {
    const config = useEnv();
    const mapStyles = { width: '98%', height: '95%' };
    const [mapObj, setMapObj] = useState(null);

    const onLoad = useCallback(async function callback(map) {
        setMapObj(map);
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMapObj(null);
    }, []);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: config.REACT_APP_MAP_TOKEN
    });

    return (
        <>
            {' '}
            {isLoaded && (
                <GoogleMap mapContainerStyle={mapStyles} center={props.deviceData.center} zoom={16} onLoad={onLoad} onUnmount={onUnmount}>
                    <Marker
                        position={props.deviceData.center}
                        icon={{
                            url: greenMarker,
                            scaledSize: new window.google.maps.Size(64, 64)
                        }}
                    />
                    <Circle
                        center={props.deviceData.center}
                        radius={props.deviceData.geoRadius}
                        options={{
                            fillColor: '#59AF5067',
                            color: '#0D807B',
                            strokeColor: '#0D807B',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillOpacity: 0.35
                        }}
                    />
                    {props.deviceData.showCircle && (
                        <Marker
                            position={props.policy.center}
                            icon={{
                                url: redMarker,
                                scaledSize: new window.google.maps.Size(64, 64)
                            }}
                        />
                    )}
                    {props.deviceData.showCircle && (
                        <Circle
                            center={props.policy.center}
                            radius={props.policy.geoRadius}
                            options={{
                                fillColor: '#D8202067',
                                color: '#D82020',
                                strokeColor: '#D82020',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillOpacity: 0.35
                            }}
                        />
                    )}
                </GoogleMap>
            )}
        </>
    );
};

export default MapView;
