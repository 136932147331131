/* eslint-disable max-nested-callbacks */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import CommonModal from '../../Components/Common/CommonModal';
import DeleteModal from '../../Components/Common/DeleteModal';
import Loader from '../../Components/Common/Loader';
import Pagination from '../../Components/Common/Pagination/Pagination';
import { TenantConfig, Tooltip, convertUTCtoIST, getDate } from '../../Components/Common/Util';
import hardwareIcon from '../../assets/images/common/svg/devicesViewIcons/hardware.svg';
import deviceSync from '../../assets/images/common/png/devicesViewIcons/device-sync.png';
import shutdown from '../../assets/images/common/png/devicesViewIcons/devicePower.png';
import deviceSecurity from '../../assets/images/common/svg/devicesViewIcons/deviceSecurity.svg';
import lockDevice from '../../assets/images/common/png/devicesViewIcons/lockDevice.png';
import rebootDevice from '../../assets/images/common/png/devicesViewIcons/rebootDevice.png';
import softwareIcon from '../../assets/images/common/svg/devicesViewIcons/software.svg';
import syncDevice from '../../assets/images/common/png/devicesViewIcons/sync.png';
import toastMessages from '../../common/messages/toastMessages';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';

const ViewIOSDevices = (props) => {
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const tenantConfig = TenantConfig();
    const [iosDetails, setIosDetails] = useState('');
    const [commandHistory, setCommandHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('');
    const [actionModal, setActionModal] = useState(false);
    const [actionText, setActionText] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [policy, setPolicy] = useState('');
    const [policyOptions, setPolicyOptions] = useState([]);
    const [policyBackup, setPolicyBackup] = useState('');
    const [defaultGroup, setDefaultGroup] = useState('');
    const [deviceGroupOptions, setDeviceGroupsOptions] = useState([]);
    const [deviceGroupOptionsBackup, setDeviceGroupsOptionsBackup] = useState([]);
    const [modalData, setModalData] = useState({ message: '', image: '' });
    const [lostMode, setLostMode] = useState({ phone: '', message: '', footNote: '', networkTether: false, enabled: false });
    const [commonModal, setCommonModal] = useState({ show: false, header: '', type: '' });
    const [appsList, setAppsList] = useState([]);
    const [commandPage, setCommandPage] = useState({ page: 1, size: 20, length: 0, device: props.recordID });
    const commands = {
        RestartDevice: { label: 'Restart Device', icon: 'ri-creative-commons-sa-line' },
        DeviceLock: { label: 'Device Lock', icon: 'ri-phone-lock-line' },
        ShutDownDevice: { label: 'Shutdown', icon: 'ri-shut-down-line' },
        EraseDevice: { label: 'Erase Device', icon: 'ri-android-line' },
        DeviceInformation: { label: 'Device Information', icon: 'ri-information-line' },
        ProfileList: { label: 'Profile List', icon: 'ri-profile-line' },
        RemoveProfile: { label: 'Remove Profile', icon: 'ri-delete-bin-line' },
        InstallApplication: { label: 'Install Application', icon: 'ri-tablet-line' },
        ManagedApplicationList: { label: 'Managed Application List', icon: 'ri-apps-2-line' },
        DeclarativeManagement: { label: 'Declarative Management', icon: 'ri-tablet-line' },
        InstallProfile: { label: 'Install Profile', icon: 'ri-install-line' },
        RemoveApplication: { label: 'Remove Application', icon: 'ri-delete-bin-line' },
        DisableLostMode: { label: 'Disabled Lost Mode', icon: 'ri-phone-find-line' },
        EnableLostMode: { label: 'Enabled Lost Mode', icon: 'ri-phone-find-line' },
        PlayLostModeSound: { label: 'Lost Mode Sound', icon: 'ri-volume-up-line' },
        Wallpaper: { label: 'Wallpaper', icon: 'ri-image-2-line' },
        ActivationLockBypassCode: { label: 'Activation Lock Bypass Code', icon: 'ri-lock-unlock-line' },
        InstallEnterpriseApplication: { label: 'Install Enterprise Application', icon: 'ri-tablet-line' },
        DeviceName: { label: 'Device Name', icon: 'ri-smartphone-line' },
        ActivationLock: { label: 'Activation Lock', icon: 'ri-door-lock-box-line' },
        '': { label: 'NA' }
    };

    const actionItems = [
        {
            id: 'reboot',
            label: 'Reboot',
            show: !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('REBOOT'),
            disabled: !iosDetails?.enroll?.issupervised,
            disabledTooltip: 'This device is not supervised.',
            icon: 'ri-creative-commons-sa-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to reboot Device?',
                    image: rebootDevice,
                    actionBody: { command: 'RestartDevice', params: { RequestType: 'RestartDevice' } },
                    toastMessage: toastMessages.rebootDevice
                });
                setType('reboot');
                setActionModal(true);
                setActionText('Yes, Reboot it!');
            },
            condition: iosDetails.showActions
        },
        {
            id: 'lock',
            label: 'Lock',
            show: !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('LOCK'),
            icon: 'ri-phone-lock-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to lock Device?',
                    image: lockDevice,
                    actionBody: {
                        command: 'DeviceLock',
                        params: { RequestType: 'DeviceLock', Message: 'Locked by your TT Admin' }
                    },
                    toastMessage: toastMessages.lockDevice
                });
                setType('lock');
                setActionModal(true);
                setActionText('Yes, Lock it!');
            },
            condition: iosDetails.showActions
        },
        {
            id: 'shutdown',
            label: 'Shutdown',
            disabled: !iosDetails?.enroll?.issupervised,
            disabledTooltip: 'This device is not supervised.',
            show: !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('SHUTDOWN'),
            icon: 'ri-shut-down-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to shutdown Device?',
                    image: shutdown,
                    actionBody: { command: 'ShutDownDevice', params: { RequestType: 'ShutDownDevice' } },
                    toastMessage: toastMessages.shutdownDevice
                });
                setType('shutdonw');
                setActionModal(true);
                setActionText('Yes, Shut it down!');
            },
            condition: iosDetails.showActions
        },
        {
            id: 'lost_mode_sound',
            label: 'Lost Mode Sound',
            icon: 'ri-volume-up-line',
            clickFunction: () => handleLostModeClick('lost_mode_sound', 'Lost Mode Sound'),
            condition: iosDetails.showActions,
            show: !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('LOST_MODE_SOUND')
        },
        {
            id: 'lost_mode',
            label: 'Lost Mode',
            icon: 'ri-phone-find-line',
            clickFunction: () => handleLostModeClick('lost_mode', 'Lost Mode'),
            condition: iosDetails.showActions,
            show: !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('LOST_MODE')
        },
        {
            id: 'sync',
            icon: 'ri-device-recover-line',
            label: 'Sync Policy',
            show: !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('SYNC_POLICY'),
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to sync policy on this Device?',
                    image: deviceSync,
                    actionBody: '',
                    toastMessage: toastMessages.deviceSync
                });
                setType('sync_policy');
                setActionModal(true);
                setActionText('Yes, Sync it!');
            },
            condition: iosDetails.showActions
        },
        {
            id: 'refresh',
            label: 'Refresh',
            show: !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('REFRESH'),
            icon: 'ri-refresh-line',
            clickFunction: () => {
                setModalData({
                    message: 'Are you sure you want to Refresh Device?',
                    image: syncDevice,
                    actionBody: '',
                    toastMessage: toastMessages.refreshDevice
                });
                setType('refresh');
                setActionModal(true);
                setActionText('Yes, Refresh it!');
            },
            condition: iosDetails.showActions
        }
    ];

    useEffect(() => {
        setLoading(true);
        handlePromise();
    }, []);

    const handlePromise = () => {
        const infoPromise = new Promise((resolve, reject) => {
            api.get(url.DEVICES + '/' + props.recordID + '/info', '', domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp.data);
                    else reject('Info failed');
                })
                .catch((_err) => reject('Info failed'));
        });
        const devicePromise = new Promise((resolve, reject) => {
            api.get(url.DEVICES + '/' + props.recordID, '', domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp.data);
                    else reject('Device failed');
                })
                .catch((_err) => reject('Device failed'));
        });
        const policyPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 1000 };
            api.get(url.POLICIES, params, domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Policy failed.');
                })
                .catch((err) => reject('Policy failed.'));
        });

        const getDeviceGroups = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100 };
            api.get(url.DEVICE_GROUPS, params, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Group failed.');
                })
                .catch((_err) => reject('Group failed.'));
        });

        const actionHistyPromise = new Promise((resolve, reject) => {
            api.get(url.IOS_COMMANDS, { device: props.recordID, page: 1, size: 20 }, domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp);
                    else reject('History failed.');
                })
                .catch((_err) => reject('History failed'));
        });

        const getiOSApps = new Promise((resolve, reject) => {
            api.get(url.IOSAPPS, { page: 1, size: 2000 }, domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp);
                    else reject('Apps failed.');
                })
                .catch((_err) => reject('Apps failed'));
        });

        Promise.allSettled([devicePromise, infoPromise, policyPromise, getDeviceGroups, actionHistyPromise, getiOSApps])
            .then((result) => {
                let groupArr = [];
                let deviceData = {};
                if (result[0].status === 'fulfilled') {
                    deviceData = { ...result[0].value };
                    deviceData.serial = deviceData?.refs?.find((ref) => ref.type === 'SERIAL')?.value;
                    deviceData.showActions = deviceData.enroll?.status === 'ACTIVE';
                }
                if (result[1].status === 'fulfilled') {
                    setDeviceName(result?.[1]?.value?.report.QueryResponses.DeviceName);
                    deviceData.deviceInfo = { ...result[1].value };
                }
                if (result[2].status === 'fulfilled') {
                    let polObj = result?.[2]?.value?.find((pol) => pol.code === result[0]?.value?.assignment?.policy);
                    if (polObj) result[0].value.assignment.policyName = polObj?.name + ' - ' + polObj?.code;
                    setPolicy(polObj);
                    setPolicyBackup(polObj);
                    setPolicyOptions(result[2].value);
                }
                if (result[3].status === 'fulfilled') {
                    if (result[3].value?.length > 0) {
                        result[3].value.forEach((group) => {
                            groupArr.push(group);
                            group.label = group.name;
                            group.key = group.code;
                            group.value = group.code;
                            group.nameCode = group.name + ' - ' + group.code;
                            if (group.children) {
                                group.children = setChildGroups(group.children);
                                groupArr = setGroups(group.children, groupArr);
                            }
                            group.childsCount = group.children.length ? group.children.length : 0;
                        });
                        let defGroup = handleDeviceGroup(groupArr, result?.[0]?.value?.assignment?.group);
                        result[0].value.assignment.groupName = defGroup ? defGroup?.name + ' - ' + defGroup?.code : undefined;
                        setDefaultGroup(defGroup);
                        setDeviceGroupsOptions(result[3].value);
                        setDeviceGroupsOptionsBackup(result[3].value);
                    }
                }
                if (result[4].status === 'fulfilled') {
                    result[4].value?.data?.forEach((value) => {
                        value.command =
                            value?.params?.Settings?.[0]?.Item === 'Wallpaper' || value?.params?.Item === 'Wallpaper'
                                ? 'Wallpaper'
                                : value?.params?.Settings?.[0]?.Item === 'DeviceName'
                                ? 'DeviceName'
                                : value?.params?.Settings?.[0]?.MDMOptions?.ActivationLockAllowedWhileSupervised === true ||
                                  value?.params?.Settings?.[0]?.MDMOptions?.ActivationLockAllowedWhileSupervised === false
                                ? 'ActivationLock'
                                : value.command;
                        value.class = value.status === 'NEW' ? 'primary' : value.status === 'DONE' ? 'success' : 'danger';
                    });
                    setCommandPage({ ...commandPage, length: result[4]?.value?.totalRecords });
                    setCommandHistory(result[4].value?.data);
                }
                if (result[5].status === 'fulfilled') {
                    setAppsList(result[5]?.value?.data);
                }
                setIosDetails(deviceData);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleDeviceGroup = (groupArr, groupCode) => {
        let deviceGroupObj = groupArr.find((group) => group.code === groupCode);
        return deviceGroupObj;
    };

    const setChildGroups = (groupArr, groupStatus) => {
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.value = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            group.deviceCount = groupStatus?.[group.code] ? groupStatus?.[group.code] : 0;
            if (group.children) group.children = setChildGroups(group.children, groupStatus);
            group.childsCount = group.children.length ? group.children.length : 0;
        });
        return groupArr;
    };

    const setGroups = (groups, arr) => {
        groups.forEach((group) => {
            arr.push(group);
            if (group.children) arr = setGroups(group.children, arr);
        });
        return arr;
    };

    const handleLostModeClick = (key, heading) => {
        setLostMode({ phone: '', message: '', footNote: '', networkTether: false, enabled: false });
        setCommonModal({ show: true, header: heading, type: key });
        setActionText('Ok');
    };

    const getCommandHistory = (params) => {
        api.get(url.IOS_COMMANDS, params, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp?.data?.forEach((value) => {
                        value.command =
                            value?.params?.Settings?.[0]?.Item === 'Wallpaper' || value?.params?.Item === 'Wallpaper'
                                ? 'Wallpaper'
                                : value?.params?.Settings?.[0]?.Item === 'DeviceName'
                                ? 'DeviceName'
                                : value?.params?.Settings?.[0]?.MDMOptions?.ActivationLockAllowedWhileSupervised === true ||
                                  value?.params?.Settings?.[0]?.MDMOptions?.ActivationLockAllowedWhileSupervised === false
                                ? 'ActivationLock'
                                : value.command;
                        value.class = value.status === 'NEW' ? 'primary' : value.status === 'DONE' ? 'success' : 'danger';
                    });
                    params = { ...params, length: resp?.totalRecords };
                    setCommandHistory(resp?.data);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false))
            .finally(() => {
                setCommandPage(params);
            });
    };

    const returnData = (key, val, edit) => {
        return (
            <tr>
                <td className="fw-medium w-50 py-2">
                    {key === 'Total internal storage' ? <div className="d-flex align-items-center h-100">{key + ' '}</div> : key}
                </td>
                <td className="text-grey-text fw-medium w-50 py-2 word-wrap-break">
                    <div className="d-flex align-items-center">{val ? val : '—'}</div>
                </td>
            </tr>
        );
    };

    const commandReturnData = (command, ind) => {
        return (
            <tr className="w-100" key={ind}>
                <td className="fw-medium w-70">
                    <div className="d-flex align-items-center text-truncate">
                        <i
                            title={commands[command.command]?.label}
                            className={`${commands[command.command]?.icon} me-2 fs-18 cursor-pointer link-secondary`}
                        />
                        {commands[command.command]?.label}
                    </div>
                </td>
                <td className="w-30 word-wrap-break m-0">
                    <span className={'lh-sm fw-semibold text-truncate text-' + command?.class}>
                        {command.status?.substr(0, 1) + command.status?.substr(1)?.toLowerCase()}
                    </span>
                </td>
                <td className="text-info w-30 text-truncate word-wrap-break m-0" id={`reqdate-${ind}`}>
                    {command.reqdate ? getDate(command.reqdate) : '—'}
                </td>
            </tr>
        );
    };

    const handleCommandPageChange = (page) => {
        setLoading(true);
        getCommandHistory({ ...commandPage, page: page });
    };

    const actionMessage = () => {
        return (
            <React.Fragment>
                <div className="mt-3">
                    <div className="mb-4">{modalData.message}</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={modalData.image} alt="device" width={60} height={60} />
                    </div>
                    <div>
                        Serial: <span className="fw-semibold">{iosDetails.serial}</span>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const actionService = () => {
        setLoading(true);
        setActionModal(false);
        const body = { ...modalData?.actionBody, device: props.recordID };
        let apiService;
        if (type === 'refresh') {
            apiService = api.patch(url.DEVICES + '/' + props.recordID + '/syncinfo', '', false, domains.IOS_V1);
        } else if (type === 'sync_policy') {
            apiService = api.patch(url.DEVICES + '/' + props.recordID + '/syncpolicy', '', false, domains.IOS_V1);
        } else {
            apiService = api.create(url.IOS_COMMANDS, body, false, domains.IOS_V1);
        }
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(modalData?.toastMessage);
                    handlePromise();
                } else setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const toggleCommonModal = useCallback(() => {
        setCommonModal({ ...commonModal, show: !commonModal.show });
        setLostMode({ phone: '', message: '', footNote: '', networkTether: false, enabled: false });
    }, [commonModal]);

    const handleLostModeDetails = (e, key) => {
        if (key === 'phone') {
            let regex = new RegExp(/[0-9]/g);
            let test = regex.test(e.target.value);
            if (!test) return;
        }
        setLostMode({ ...lostMode, [key]: e.target.value });
    };

    const handleCommonModalBody = () => {
        if (commonModal.type === 'lost_mode') {
            return (
                <React.Fragment>
                    <div className="mt-3 d-flex align-items-center justify-content-center flex-column">
                        {/* <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={factoryModeImage} alt="factory_mode" width={60} height={60} />
                    </div> */}
                        <div className="mb-4">Are you sure you want to {lostMode.enabled ? 'enable' : 'disable'} lost mode?</div>
                        <div className="mb-4 d-flex align-items-center justify-content-center">
                            <div className="p-1 d-flex align-items-center border border-1 border-info rounded-3">
                                <div
                                    className={`${
                                        !lostMode.enabled ? 'bg-info text-light' : 'bg-light text-dark'
                                    } w-50 px-2 py-1 cursor-pointer`}
                                    onClick={() => {
                                        setLostMode({ ...lostMode, enabled: false });
                                        setActionText('Yes, Disable it!');
                                    }}
                                >
                                    Disable
                                </div>
                                <div
                                    className={`${
                                        lostMode.enabled ? 'bg-info text-light' : 'bg-light text-dark'
                                    } w-50 px-2 py-1 cursor-pointer`}
                                    onClick={() => {
                                        setLostMode({ ...lostMode, enabled: true });
                                        setActionText('Yes, Enable it!');
                                    }}
                                >
                                    Enable
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col sm={12} md={lostMode.enabled ? 6 : 5}>
                            <Label className={'mb-0 fw-medium'}>Require Network Tether :</Label>
                            {/* <div className="text-muted wrap-text-word"></div> */}
                        </Col>
                        <Col sm={12} md={lostMode.enabled ? 6 : 2}>
                            <div className="form-check form-switch form-switch-md">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={lostMode.networkTether || false}
                                    onChange={(e) => setLostMode({ ...lostMode, networkTether: e.target.checked })}
                                />
                            </div>
                        </Col>
                    </Row>
                    {lostMode.enabled && (
                        <>
                            <Row className="mt-2">
                                <Col sm={12} md={6} className="d-flex align-items-center">
                                    <Label className={'mb-0 fw-medium'}>Phone Number :</Label>
                                    {/* <div className="text-muted wrap-text-word"></div> */}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="input-group">
                                        <Input
                                            className="form-control"
                                            placeholder={'Enter Phone Number'}
                                            validate={{ required: { value: true } }}
                                            maxLength={10}
                                            onChange={(e) => handleLostModeDetails(e, 'phone')}
                                            value={lostMode.phone || ''}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col sm={12} md={6} className="d-flex align-items-center">
                                    <Label className={'mb-0 fw-medium'}>Message :</Label>
                                    {/* <div className="text-muted wrap-text-word"></div> */}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="input-group">
                                        <Input
                                            className="form-control"
                                            placeholder={'Enter Message'}
                                            validate={{ required: { value: true } }}
                                            type="textarea"
                                            onChange={(e) => handleLostModeDetails(e, 'message')}
                                            value={lostMode.message || ''}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col sm={12} md={6} className="d-flex align-items-center">
                                    <Label className={'mb-0 fw-medium'}>FootNote :</Label>
                                    {/* <div className="text-muted wrap-text-word"></div> */}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="input-group">
                                        <Input
                                            className="form-control"
                                            placeholder={'Enter Foot Note'}
                                            validate={{ required: { value: true } }}
                                            type="textarea"
                                            onChange={(e) => handleLostModeDetails(e, 'footNote')}
                                            value={lostMode.footNote || ''}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </React.Fragment>
            );
        }
        if (commonModal.type === 'lost_mode_sound') {
            return (
                <React.Fragment>
                    <Col>
                        <div className="mb-4">Are you sure you want to play lost mode on this device?</div>
                        <Row className="mt-2">
                            <Col sm={12} md={6}>
                                <Label className={'mb-0 fw-medium'}>Require Network Tether :</Label>
                                {/* <div className="text-muted wrap-text-word"></div> */}
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="form-check form-switch form-switch-md">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={lostMode.networkTether || false}
                                        onChange={(e) => setLostMode({ ...lostMode, networkTether: e.target.checked })}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </React.Fragment>
            );
        }
    };

    const handleCommonModalConfirm = () => {
        setCommonModal({ show: false, header: '', type: '' });
        setLoading(true);
        let apiService;
        let params = { device: props.recordID };
        if (commonModal.type === 'lost_mode') {
            params = {
                ...params,
                command: lostMode.enabled ? 'EnableLostMode' : 'DisableLostMode',
                params: {
                    RequestType: lostMode.enabled ? 'EnableLostMode' : 'DisableLostMode',
                    RequestRequiresNetworkTether: !!lostMode.networkTether
                }
            };
            if (lostMode.enabled)
                params.params = { ...params.params, PhoneNumber: lostMode.phone, Message: lostMode.message, Footnote: lostMode.message };
            apiService = api.create(url.IOS_COMMANDS, params, false, domains.IOS_V1);
        } else if (commonModal.type === 'lost_mode_sound') {
            params = {
                ...params,
                command: 'PlayLostModeSound',
                params: { RequestType: 'PlayLostModeSound', RequestRequiresNetworkTether: lostMode.networkTether }
            };
            apiService = api.create(url.IOS_COMMANDS, params, false, domains.IOS_V1);
        }

        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(successToastMsg());
                    setLostMode({ phone: '', message: '', footNote: '', networkTether: false, enabled: false });
                    handlePromise();
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const successToastMsg = () => {
        let message;
        switch (commonModal.type) {
            case 'lost_mode':
                if (lostMode.enabled) {
                    message = toastMessages.lostModeEnabled;
                } else {
                    message = toastMessages.lostModeDisabled;
                }
                break;
            case 'lost_mode_sound':
                message = toastMessages.lostModeSoundInitiated;
                break;
            default:
                message = toastMessages.actionSuccessMsg;
        }
        return message;
    };

    return (
        <React.Fragment>
            {loading && <Loader />}

            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="View Device" history={history} homeLink="Dashboard" showBack={true} backLink="mydevices" />
                    <Row>
                        <Col className="col-12">
                            <Card className="pt-3 pb-3 ps-2 pe-2 w-100 d-flex align-items-center mb-0">
                                <div className="w-100 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-15 px-1 fw-medium">Device ID :</div>
                                        <div className="fs-16 ps-2 text-info">{iosDetails?.serial}</div>
                                    </div>
                                    <div className="d-flex align-items-center gap-4">
                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            {actionItems?.filter((action) => action.show)?.length > 0 && (
                                                <div className="fs-15 d-flex justify-content-center align-items-center fw-medium">
                                                    Actions :
                                                </div>
                                            )}
                                            {actionItems.map((action, ind) => {
                                                return (
                                                    action.show && (
                                                        <div
                                                            key={ind}
                                                            className={`width-35 height-35 d-flex align-items-center justify-content-center ${
                                                                action.condition
                                                                    ? action.disabled
                                                                        ? 'bg-muted'
                                                                        : 'bg-447BD107 cursor-pointer'
                                                                    : ''
                                                            }`}
                                                            onClick={
                                                                action.condition && !action.disabled ? action.clickFunction : undefined
                                                            }
                                                        >
                                                            <i
                                                                id={action.id}
                                                                className={`${action.icon} fs-20 ${
                                                                    action.condition ? 'action-hover link-info' : 'text-muted'
                                                                }`}
                                                            />
                                                            {Tooltip(action.id, action.disabled ? action.disabledTooltip : action.label)}
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="card-height-100 mb-0 padding-bottom-50">
                                <CardHeader className="d-flex align-items-center flex-column">
                                    <>
                                        <div className="w-100 mt-3 d-flex justify-content-center">
                                            <span className="rounded height-50 width-50 border border-2 d-flex align-items-center justify-content-center fs-2">
                                                <i className="ri-tablet-line"></i>
                                            </span>
                                        </div>
                                        <div className="w-100 mt-3 margin-bottom-30 d-flex justify-content-center">
                                            <p className="ps-2 fs-18 fw-semibold mb-0">{iosDetails?.hardware?.model}</p>
                                        </div>
                                    </>
                                    <div className="w-100 d-flex justify-content-start">
                                        <h4 className="card-title mb-0 flex-grow-1 text-success">Device Summary</h4>
                                        <div className="flex-shrink-0"></div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData('Device Group', iosDetails?.assignment?.groupName)}
                                                {returnData('Policy', iosDetails?.assignment?.policyName)}
                                                {returnData('User Name', iosDetails?.assignment?.user)}
                                                {/* {returnData('Mobile Number', iosDetails?.deviceSummary?.phone, false)} */}
                                                {returnData('Enrollment Time', convertUTCtoIST(iosDetails?.enroll?.enrolldate))}
                                                {returnData('Last Status Report Time', convertUTCtoIST(iosDetails?.modifieddate))}
                                                {returnData(
                                                    'Battery',
                                                    Math.floor(
                                                        (iosDetails?.deviceInfo?.report?.QueryResponses?.BatteryLevel * 100)?.toFixed(2)
                                                    ) + (iosDetails?.deviceInfo?.report?.QueryResponses?.BatteryLevel > 0 ? '%' : '')
                                                )}
                                                {returnData('Last Modified Date', convertUTCtoIST(iosDetails?.modifieddate))}
                                                {returnData('Policy Compliance', 'NON-COMPLIANT')}
                                                {returnData('Management Mode', 'DEVICE_OWNER')}
                                                {returnData('Ownership', 'COMPANY_OWNER')}
                                            </tbody>
                                        </table>
                                        <div className="w-100 d-flex justify-content-start ps-4 margin-top-30">
                                            <h4 className="card-title mb-0 flex-grow-1 text-success">Device Identity Details</h4>
                                            <div className="flex-shrink-0"></div>
                                        </div>
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData('Serial', iosDetails?.serial)}
                                                {returnData('IMEI', iosDetails?.deviceInfo?.report?.QueryResponses?.IMEI)}
                                                {returnData('Device Name', iosDetails?.deviceInfo?.report?.QueryResponses?.DeviceName)}
                                                {returnData('Bluetooth MAC', iosDetails?.deviceInfo?.report?.QueryResponses?.BluetoothMAC)}
                                                {returnData('Wi-Fi MAC', iosDetails?.deviceInfo?.report?.QueryResponses?.WiFiMAC)}
                                            </tbody>
                                        </table>
                                        <div className="w-100 d-flex justify-content-start ps-4 margin-top-30">
                                            <h4 className="card-title mb-0 flex-grow-1 text-success">Device Security</h4>
                                            <div className="flex-shrink-0"></div>
                                        </div>
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData(
                                                    'Cloud Backup Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.IsCloudBackupEnabled ? 'Yes' : 'No'
                                                )}
                                                {returnData(
                                                    'Device Locator Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.IsDeviceLocatorServiceEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'MDM Lost Mode Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.IsMDMLostModeEnabled ? 'Yes' : 'No'
                                                )}
                                                {returnData(
                                                    'Supervised Device',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.IsSupervised ? 'Yes' : 'No'
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="card-height-20 mb-3 padding-bottom-50">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={hardwareIcon} alt="Hardware Icon" height={20}></img>
                                    <h4 className="ps-2 card-title mb-0 flex-grow-1 text-success">Hardware Information</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData('Manufacturer', 'Apple')}
                                                {returnData('Model', iosDetails?.deviceInfo?.report?.QueryResponses?.ProductName)}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-height-30 mb-3 padding-bottom-50">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={softwareIcon} alt="Software Icon" height={20}></img>
                                    <h4 className="card-title ps-2 mb-0 flex-grow-1 text-success">Software Information</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData('iOS Version', iosDetails?.deviceInfo?.report?.QueryResponses?.OSVersion)}
                                                {returnData(
                                                    'Software Update',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.SoftwareUpdateDeviceID
                                                )}
                                                {returnData(
                                                    'Modem Firmware Version',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.ModemFirmwareVersion
                                                )}
                                                {returnData(
                                                    'Supplemental Build Version',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.SupplementalBuildVersion
                                                )}
                                                {returnData('Time Zone', iosDetails?.deviceInfo?.report?.QueryResponses?.TimeZone)}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-height-50 mb-0 padding-bottom-50">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={softwareIcon} alt="Software Icon" height={20}></img>
                                    <h4 className="card-title ps-2 mb-0 flex-grow-1 text-success">Accessibility</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                            <tbody>
                                                {returnData(
                                                    'Bold Text Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings?.BoldTextEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'Increase Contrast Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings
                                                        ?.IncreaseContrastEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'Reduce Motion Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings
                                                        ?.ReduceMotionEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'Reduce Transparency Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings
                                                        ?.ReduceTransparencyEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'Font Size',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings?.TextSize
                                                )}
                                                {returnData(
                                                    'Touch Accommodations Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings
                                                        ?.TouchAccommodationsEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'VoiceOver Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings?.VoiceOverEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                                {returnData(
                                                    'Zoom Enabled',
                                                    iosDetails?.deviceInfo?.report?.QueryResponses?.AccessibilitySettings?.ZoomEnabled
                                                        ? 'Yes'
                                                        : 'No'
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex flex-column g-3">
                            <Card className="card-height-100 mb-0 padding-bottom-50">
                                <CardHeader className="align-items-center d-flex">
                                    <img src={deviceSecurity} alt="Non Compliance Icon"></img>
                                    <h4 className="card-title ps-2 mb-0 flex-grow-1 text-success">Action History</h4>
                                    <div className="flex-shrink-0"></div>
                                </CardHeader>
                                <CardBody>
                                    <div className="max-height-850">
                                        <div className="table-responsive table-card">
                                            <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                                <tbody>
                                                    {commandHistory?.length > 0 ? (
                                                        commandHistory?.map((command, ind) => (
                                                            <React.Fragment key={ind}>{commandReturnData(command, ind)}</React.Fragment>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className="pb-6 fs-13">{'No Records Found.'}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {commandPage?.length > 15 && (
                                        <Pagination
                                            activePage={commandPage.page}
                                            itemsCountPerPage={20}
                                            totalItemsCount={commandPage.length}
                                            pageRangeDisplayed={3}
                                            onChange={handleCommandPageChange}
                                            hideFirstLastPages={false}
                                            isColumn={true}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <DeleteModal
                    hideIcon={true}
                    show={actionModal}
                    hideDeleteIcon={true}
                    hideDeleteMessage={true}
                    message={actionMessage()}
                    confirmText={actionText}
                    onDeleteClick={actionService}
                    onCloseClick={() => setActionModal(false)}
                />
                <CommonModal
                    size={'md'}
                    show={commonModal.show}
                    modalheader={commonModal.header}
                    onCloseClick={toggleCommonModal}
                    saveText={actionText}
                    hideSaveButton={commonModal.type === 'activation_lock' ? true : false}
                    handleClick={handleCommonModalConfirm}
                    handleModalBody={() => handleCommonModalBody()}
                />
            </div>
        </React.Fragment>
    );
};

export default ViewIOSDevices;
