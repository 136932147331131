/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import AnimatedTreeComponent from '../../../Components/Common/AnimatedTreeComponent';
import Loader from '../../../Components/Common/Loader';
import { Tooltip, noCaseSensitiveSortBy } from '../../../Components/Common/Util';
import { COUNTRIES } from '../../../Components/constants/Countries';
import { userFormData } from '../../../common/data';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';

const AddUsers = (props) => {
    document.title = 'Users';
    const urlconf = useEnv();
    let history = useHistory();
    const api = new APIClient();

    const [mode, setMode] = useState(props.mode);
    const [formValues, setFormValues] = useState({ ...props.record });
    /*
     * const [roles, setRoles] = useState([]);
     * let emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
     */
    const [roleType, setRoleType] = useState('');
    const [roleTypes, setRoleTypes] = useState([]);
    const [groups, setGroups] = useState([]);
    const [flatGroups, setFlatGroups] = useState([]);
    const [allGroupAccess, setAllGroupAccess] = useState(true);

    useEffect(() => {
        setAllGroupAccess(props?.record?.groups ? !!props?.record?.groups?.includes('*') : true);
    }, [props?.record?.groups]);

    useEffect(() => {
        setMode(props.mode);
        props.setCanvasLoader(true);
        promiseHandler();
    }, []);

    const promiseHandler = () => {
        const rolesPromise = new Promise((resolve, reject) => {
            api.get(url.ROLES, { page: 1, size: 100 }, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Roles failed.');
                })
                .catch((err) => reject('Roles failed.'));
        });

        const groupsPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100 };
            api.get(url.DEVICE_GROUPS, params, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Groups failed.');
                })
                .catch((err) => reject('Groups failed.'));
        });

        Promise.allSettled([rolesPromise, groupsPromise]).then(async (result) => {
            let userTypeArr = [];
            if (result[0]?.status === 'fulfilled') {
                result[0]?.value?.forEach((data) => {
                    let obj = { label: data.name, value: data._id };
                    if (props.record.role === obj.value) {
                        setRoleType(obj);
                        props.handleRoleTypeChange(obj);
                    }
                    if (data.lastmodifiedBy !== 'SYSTEM') userTypeArr.push(obj);
                });
            }
            setRoleTypes(userTypeArr);
            if (result[1]?.status === 'fulfilled') {
                let superParents = [];
                let flatGroupArr = [];
                if (result[1]?.value) {
                    let groupsData = [];
                    let deviceGroups = props.record?.groups ? props.record?.groups : [];
                    if (props.record?.deviceGroups) props.handleGroupSelection(deviceGroups);
                    await result[1]?.value.forEach(async (data) => {
                        data.label = data.name;
                        data.key = data.code;
                        data.checked = deviceGroups.includes(data.code);
                        groupsData.push(data);
                        if (data.children) {
                            let obj = await setGroupsData(data.children, data, groupsData, deviceGroups);
                            data.children = obj.deviceGroups;
                            groupsData = [...obj.groupsData];
                        }
                    });
                    flatGroupArr = groupsData;
                }
                setFlatGroups(flatGroupArr);
                superParents = noCaseSensitiveSortBy(result[1]?.value, 'label');
                setGroups(superParents);
            }
            props.setCanvasLoader(false);
        });
    };

    useEffect(() => {
        if (props.record) setFormValues(props.record);
    }, [props.record]);

    const setChild = (parents, totalData) => {
        parents.forEach((parent) => {
            parent.children = totalData.filter((data) => data.parent === parent.id);
            if (parent.children?.length > 0) parent.children = setChild(parent.children, totalData);
        });
        return parents;
    };

    const setGroupsData = async (deviceGroups, dataObj, groupsData, deviceGroupCodes) => {
        await deviceGroups.forEach(async (data) => {
            data.label = data.name;
            data.key = data.code;
            data.checked = deviceGroupCodes.includes(data.code) || dataObj.checked;
            groupsData.push(data);
            if (data.children) {
                let obj = await setGroupsData(data.children, data, groupsData, deviceGroupCodes);
                data.children = obj.deviceGroups;
                groupsData = [...obj.groupsData];
            }
        });
        return { deviceGroups: deviceGroups, groupsData: groupsData };
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: formValues.firstname ? formValues.firstname : '',
            lastname: formValues.lastname ? formValues.lastname : '',
            email: formValues.email ? formValues.email : '',
            username: formValues.username ? formValues.username : '',
            phone: formValues.phone ? formValues.phone : '',
            countryCode: formValues?.countryCode
                ? formValues?.countryCode
                : {
                      name: 'India',
                      code: 'IN',
                      emoji: '🇮🇳',
                      unicode: 'U+1F1EE U+1F1F3',
                      image: 'IN.svg',
                      dial_code: '91',
                      disaplay_dial_code: '+91'
                  }
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required('Please Enter Your First Name'),
            lastname: Yup.string().required('Please Enter Your Last Name'),
            email: Yup.string().required('Please Enter Your Email ID')
        })
    });

    useEffect(() => {
        props.formErrors(validation.errors);
    }, [validation.errors]);

    useEffect(() => {
        props.saveFormData(validation.values, allGroupAccess);
    }, [validation.values, allGroupAccess]);

    const copyToClipboard = async (val) => {
        navigator.clipboard.writeText(val).then(() => {
            toast.success(toastMessages.emailCopied);
        });
    };

    const handlePhoneChange = (e) => {
        let regex = new RegExp(/^[1-9][0-9]{0,9}$/);
        let valid = regex.test(e.target.value);
        if ((valid && e.target.value?.length <= 10) || e.target.value === '') {
            validation.setValues({ ...validation.values, phone: e.target.value });
        }
    };

    const inputComponent = (field) => {
        return (
            <div className="mb-3 mb-lg-0">
                <Row>
                    <Col xs={12} sm={12} md={12} xl={3} lg={3} className="d-flex align-items-center">
                        <Label className="form-label mb-0 d-flex align-items-center">
                            {field.label}
                            {field.notMandatory ? (
                                <span className="ps-1"> :</span>
                            ) : (mode !== 'view' && field.value !== 'email') || mode === 'add' ? (
                                <span className="red-color ps-1"> *</span>
                            ) : (
                                <span className="ps-1"> :</span>
                            )}
                        </Label>
                    </Col>
                    <Col>
                        <Row>
                            {field.enableCountry && mode !== 'view' && (
                                <Col
                                    sm={mode === 'view' ? 1 : 2}
                                    md={mode === 'view' ? 1 : 4}
                                    lg={mode === 'view' ? 1 : 4}
                                    xl={mode === 'view' ? 1 : 4}
                                    xxl={mode === 'view' ? 1 : 3}
                                >
                                    <Select
                                        getOptionValue={(option) => option.disaplay_dial_code}
                                        getOptionLabel={(option) => (
                                            <div className="d-flex align-items-center gap-1">
                                                <span>{`(${option.disaplay_dial_code})`}</span>
                                                {option.code}
                                            </div>
                                        )}
                                        isMulti={field.isMulti ? true : false}
                                        isClearable={field.isMulti ? true : false}
                                        id={field.label}
                                        name={field.mobileValue}
                                        options={COUNTRIES}
                                        placeholder={'Select'}
                                        onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                                        onChange={(selectedOption) => {
                                            validation.setValues({ ...validation.values, [field.mobileValue]: selectedOption });
                                        }}
                                        value={validation?.values[field.mobileValue] || ''}
                                        isSearchable={true}
                                        isDisabled={field?.disable ? field?.disable : false}
                                        noOptionsMessage={() => 'No data found'}
                                    />
                                </Col>
                            )}
                            <Col>
                                <div className="input-group">
                                    {(mode === 'edit' ? field.value !== 'username' && field.value !== 'email' : mode !== 'view') ? (
                                        <Input
                                            name={field.value}
                                            id={field.label}
                                            className="form-control"
                                            placeholder={`Enter ${field.label}`}
                                            type={field.type}
                                            validate={{ required: { value: true } }}
                                            onChange={field.value === 'phone' ? (e) => handlePhoneChange(e) : validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values[field.value]?.trimStart() || ''}
                                            invalid={validation.touched[field.value] && validation.errors[field.value] ? true : false}
                                        />
                                    ) : validation.values[field.value] ? (
                                        <span className="d-flex align-items-center">
                                            {field.mobileValue
                                                ? validation?.values[field.mobileValue]?.disaplay_dial_code +
                                                  '-' +
                                                  validation.values[field.value]
                                                : validation.values[field.value]}
                                            {field.value === 'email' && (
                                                <span>
                                                    <i
                                                        id={'copy'}
                                                        onClick={() => copyToClipboard(validation.values[field.value])}
                                                        className="ri-file-copy-line ms-1 fs-13 cursor-pointer link-primary"
                                                    ></i>
                                                    {Tooltip('copy', 'Copy')}
                                                </span>
                                            )}
                                        </span>
                                    ) : (
                                        '–'
                                    )}
                                    {validation.touched[field.value] && validation.errors[field.value] ? (
                                        <FormFeedback type="invalid">{validation.errors[field.value]}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    };

    const setCheckedGroup = (groupsObj, group) => {
        groupsObj.children?.forEach((groupVal) => {
            if (groupVal.code === group.code) groupVal.checked = !!groupVal.checked;
            if (groupsObj.checked) groupVal.checked = true;
            if (groupVal.children) groupVal = setCheckedGroup(groupVal, group);
        });
        return groupsObj;
    };

    const checkGroupsChecked = (groupsObj, groupsChecked) => {
        groupsObj.children?.forEach((groupVal) => {
            if (groupVal.checked) groupsChecked.push(groupVal.code);
            else if (groupVal.children) groupsChecked = checkGroupsChecked(groupVal, groupsChecked);
        });
        return groupsChecked;
    };

    const setUncheckGroup = (groupsObj, group) => {
        groupsObj.children?.forEach((groupVal) => {
            groupVal.checked = false;
            if (groupVal.children) groupVal = setUncheckGroup(groupVal, group);
        });
        return groupsObj;
    };

    const changeGroups = (groupsArr, _checked, group) => {
        groupsArr.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
            if (groupObj.children) groupObj = setCheckedGroup(groupObj, group);
            if (groupObj.code === group.code && !groupObj.checked) groupObj = setUncheckGroup(groupObj, group);
        });
        let groupsChecked = [];
        groupsArr.forEach((groupObj) => {
            if (groupObj.checked) groupsChecked.push(groupObj.code);
            else if (groupObj.children) groupsChecked = checkGroupsChecked(groupObj, groupsChecked);
        });
        props.handleGroupSelection(groupsChecked);
        setGroups(groupsArr);
    };

    const handleCreateGroup = () => {
        localStorage.setItem('createGroup', true);
        history.push('/devicegroups');
    };

    const handleRoleTypeChange = (option) => {
        setRoleType(option);
        props.handleRoleTypeChange(option);
    };

    return (
        <React.Fragment>
            <Form className="h-100">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
                    <div className="h-100 ">
                        <div className="mb-3 mb-lg-0 d-flex flex-column gap-4">
                            {userFormData?.length > 0 &&
                                userFormData.map((field, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {field.inputType === 'input' && inputComponent(field, index)}
                                        </React.Fragment>
                                    );
                                })}
                            <div className="mb-3 mb-lg-0">
                                <Row>
                                    <Col xs={12} sm={12} md={12} xl={3} lg={3} className="d-flex align-items-center">
                                        <Label className="form-label mb-0 d-flex align-items-center">
                                            Role Type
                                            {mode !== 'view' ? (
                                                <span className="red-color ps-1"> *</span>
                                            ) : (
                                                <span className="ps-1"> :</span>
                                            )}
                                        </Label>
                                    </Col>
                                    <Col>
                                        <div className="input-group">
                                            {mode !== 'view' ? (
                                                <Select
                                                    getOptionValue={(option) => option.value}
                                                    getOptionLabel={(option) => option.label}
                                                    id="roleType"
                                                    name="roleType"
                                                    className="w-100"
                                                    options={roleTypes}
                                                    placeholder="Select Role Type"
                                                    onChange={(selectedOption) => handleRoleTypeChange(selectedOption)}
                                                    value={roleType || ''}
                                                    isSearchable={true}
                                                    noOptionsMessage={() => 'No data found'}
                                                />
                                            ) : roleType?.length > 0 ? (
                                                roleType
                                                    ?.map((v) => v.label)
                                                    ?.toString()
                                                    ?.split(',')
                                                    ?.join(', ')
                                            ) : roleType?.label ? (
                                                roleType?.label
                                            ) : roleType ? (
                                                roleType
                                            ) : (
                                                '–'
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-3 mb-lg-0 w-100">
                                <Row>
                                    <Col>
                                        <div className="input-group">
                                            <input
                                                type="checkbox"
                                                checked={allGroupAccess}
                                                disabled={props.mode === 'view'}
                                                className={'form-check-input me-2 border border-primary '}
                                                onChange={(e) => setAllGroupAccess(e.target.checked)}
                                            />
                                            <span>Access All Groups</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {!allGroupAccess && (
                                <div className="mb-3 mb-lg-0 w-100 shadow-card">
                                    <div className="bg-soft-success padding-8 fs-14 text-success border-radius-top-left-4 border-radius-top-right-4">
                                        <div className="d-flex align-items-center">
                                            <i className="ri-database-2-fill me-2" />
                                            <span>Select Groups</span>({groups.length})
                                        </div>
                                    </div>
                                    <div className="height-400 border-radius-bottom-left-4 border-radius-bottom-right-4 p-3 overflow-auto">
                                        <AnimatedTreeComponent
                                            groups={groups}
                                            flatGroups={flatGroups}
                                            changeGroups={changeGroups}
                                            disableCheckbox={mode === 'view'}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Form>
        </React.Fragment>
    );
};

export default AddUsers;
