import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import DashboardCharts from '../../Dashboard/DashboardCharts';
import DashboardWidgets from '../../Dashboard/DashboardWidgets';
import TabsComponent from '../../../Components/Common/TabsComponent';
import { TenantConfig } from '../../../Components/Common/Util';

const IOSDashboard = () => {
    document.title = 'iOS Dashboard';

    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const api = new APIClient();
    const [devices, setDevices] = useState({ total: 0, provisioning: 0, enrolled: 0, notEnrolled: 0 });
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [supervision, setSupervision] = useState([]);
    const [osVersion, setOSVersion] = useState([]);
    const [macDevices, setMacDevices] = useState({ total: 0, provisioning: 0, enrolled: 0, notEnrolled: 0 });
    const [macDeviceTypes, setMacDeviceTypes] = useState([]);
    const [macSupervision, setMacSupervision] = useState([]);
    const [macosVersion, setMacosVersion] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [types, setType] = useState('');
    const tenant = TenantConfig();

    const [selectedTab, setSelectedTab] = useState(tenant.IOS_POLICY_TYPES?.includes('ios') ? 'ios' : 'macos');

    const iosDashboardsData = [
        {
            id: 'device_type',
            label: 'Device Type',
            helpText: 'This chart displays the distribution of devices by type',
            dataColors:
                '["--EMM-supplier-count-1","--EMM-supplier-count-2","--EMM-supplier-count-3","--EMM-supplier-count-4","--EMM-supplier-count-5"]',
            dataColorsArr: ['supplier-count-1', 'supplier-count-2', 'supplier-count-3', 'supplier-count-4', 'supplier-count-5'],
            details: deviceTypes
        },
        {
            id: 'super_unSuper_devices',
            label: 'Supervised Vs Unsupervised Devices',
            helpText: 'This chart compares the number of supervised and unsupervised devices after enrolment.',
            dataColors: '["--EMM-green2", "--EMM-green4","--EMM-green3"]',
            dataColorsArr: ['green2', 'green4', 'green3'],
            details: supervision
        },
        {
            id: 'dashboard_os_distribution',
            label: 'OS Distribution',
            helpText: 'This chart displays the distribution of OS versions across all devices',
            dataColors: '["--EMM-androidv0", "--EMM-androidv1", "--EMM-androidv2","--EMM-androidv3","--EMM-androidv4","--EMM-androidv5"]',
            dataColorsArr: ['androidv0', 'androidv1', 'androidv2', 'androidv3', 'androidv4', 'androidv5'],
            details: osVersion
        }
    ];

    const macDashboardsData = [
        {
            id: 'mac_device_type',
            label: 'Device Type',
            helpText: 'This chart displays the distribution of devices by type',
            dataColors:
                '["--EMM-supplier-count-1","--EMM-supplier-count-2","--EMM-supplier-count-3","--EMM-supplier-count-4","--EMM-supplier-count-5"]',
            dataColorsArr: ['supplier-count-1', 'supplier-count-2', 'supplier-count-3', 'supplier-count-4', 'supplier-count-5'],
            details: macDeviceTypes
        },
        {
            id: 'super_unSuper_devices',
            label: 'Supervised Vs Unsupervised Devices',
            helpText: 'This chart compares the number of supervised and unsupervised devices after enrolment.',
            dataColors: '["--EMM-green2", "--EMM-green4","--EMM-green3"]',
            dataColorsArr: ['green2', 'green4', 'green3'],
            details: macSupervision
        },
        {
            id: 'mac_dashboard_os_distribution',
            label: 'OS Distribution',
            helpText: 'This chart displays the distribution of OS versions across all devices',
            dataColors: '["--EMM-androidv0", "--EMM-androidv1", "--EMM-androidv2","--EMM-androidv3","--EMM-androidv4","--EMM-androidv5"]',
            dataColorsArr: ['androidv0', 'androidv1', 'androidv2', 'androidv3', 'androidv4', 'androidv5'],
            details: macosVersion
        }
    ];

    useEffect(() => {
        handlePromise();
    }, []);

    const toggle = () => {
        setShowDetailsModal(!showDetailsModal);
    };

    const handlePromise = () => {
        setLoading(true);
        let dashboardPromise = new Promise((resolve, reject) => {
            api.get(url.IOS_DASHBOARD, '', domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') resolve(resp.data);
                    else reject('Dashboard failed');
                })
                .catch((err) => reject('Dashboard Failed'));
        });

        Promise.allSettled([dashboardPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    if ('ios' in result[0].value) {
                        let devicesObj = { ...devices };
                        let obj = {};
                        result[0].value?.ios?.status?.forEach((device) => (obj[device?.status?.toLowerCase()] = device.count));
                        devicesObj.provisioning = obj.provisioning || 0;
                        devicesObj.enrolled = obj.active || 0;
                        devicesObj.notEnrolled = (obj.deleted || 0) + (obj.new || 0);
                        devicesObj.total = devicesObj.provisioning + devicesObj.enrolled + devicesObj.notEnrolled || 0;

                        result[0].value?.ios?.issupervised?.forEach((superObj) => {
                            superObj.label = superObj.issupervised ? 'Supervised Devices' : 'Unsupervised Devices';
                        });
                        result[0].value?.ios?.osversion?.forEach((os) => {
                            os.label = os.osversion;
                        });
                        result[0]?.value?.ios?.model?.forEach((deviceType) => {
                            deviceType.label = deviceType.devicemodel;
                        });
                        setDeviceTypes(result[0].value?.ios?.model || []);
                        setOSVersion(result[0]?.value?.ios?.osversion || []);
                        setSupervision(result[0].value?.ios?.issupervised || []);
                        setDevices(devicesObj);
                    }
                    if ('mac' in result[0].value) {
                        let devicesObj = { ...macDevices };
                        let obj = {};
                        result[0].value?.mac?.status?.forEach((device) => (obj[device?.status?.toLowerCase()] = device.count));
                        devicesObj.provisioning = obj.provisioning || 0;
                        devicesObj.enrolled = obj.active || 0;
                        devicesObj.notEnrolled = (obj.deleted || 0) + (obj.new || 0);
                        devicesObj.total = devicesObj.provisioning + devicesObj.enrolled + devicesObj.notEnrolled || 0;

                        result[0].value?.mac?.issupervised?.forEach((superObj) => {
                            superObj.label = superObj.issupervised ? 'Supervised Devices' : 'Unsupervised Devices';
                        });
                        result[0].value?.mac?.osversion?.forEach((os) => {
                            os.label = os.osversion;
                        });
                        result[0]?.value?.mac?.model?.forEach((deviceType) => {
                            deviceType.label = deviceType.devicemodel;
                        });
                        setMacDeviceTypes(result[0].value?.mac?.model || []);
                        setMacosVersion(result[0]?.value?.mac?.osversion || []);
                        setMacSupervision(result[0].value?.mac?.issupervised || []);
                        setMacDevices(devicesObj);
                    }
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleExport = () => {
        const file = [];
        setLoading(true);
        if (selectedTab === 'ios') {
            if (!!tenant.IOS_WIDGETS.includes('dashboard_devices')) {
                file.push(
                    ['Total', 'Provisioning', 'Enrolled', 'Not Enrolled'],
                    [devices.total, devices.provisioning, devices.enrolled, devices.notEnrolled]
                );
            }

            if (!!tenant.IOS_WIDGETS.includes('device_type') && deviceTypes?.length > 0) {
                file.push([], ['Device Type'], ['Type', 'Count']);
                deviceTypes?.forEach((type) => file.push([type.label, type.count]));
            }
            if (!!tenant.IOS_WIDGETS.includes('super_unSuper_devices') && supervision?.length > 0) {
                file.push([], ['Supervised Vs Unsupervised Devices'], ['Type', 'Count']);
                supervision?.forEach((type) => file.push([type.label, type.count]));
            }
            if (!!tenant.IOS_WIDGETS.includes('dashboard_os_distribution') && osVersion?.length > 0) {
                file.push([], ['OS Distribution'], ['Version', 'Count']);
                osVersion?.forEach((type) => file.push([type.label, type.count]));
            }
        } else if (selectedTab === 'macos') {
            if (!!tenant.IOS_WIDGETS.includes('dashboard_devices')) {
                file.push(
                    ['Total', 'Provisioning', 'Enrolled', 'Not Enrolled'],
                    [macDevices.total, macDevices.provisioning, macDevices.enrolled, macDevices.notEnrolled]
                );
            }

            if (!!tenant.IOS_WIDGETS.includes('mac_device_type') && macDeviceTypes?.length > 0) {
                file.push([], ['Device Type'], ['Type', 'Count']);
                macDeviceTypes?.forEach((type) => file.push([type.label, type.count]));
            }
            if (!!tenant.IOS_WIDGETS.includes('super_unSuper_devices') && macSupervision?.length > 0) {
                file.push([], ['Supervised Vs Unsupervised Devices'], ['Type', 'Count']);
                macSupervision?.forEach((type) => file.push([type.label, type.count]));
            }
            if (!!tenant.IOS_WIDGETS.includes('mac_dashboard_os_distribution') && macosVersion?.length > 0) {
                file.push([], ['OS Distribution'], ['Version', 'Count']);
                macosVersion?.forEach((type) => file.push([type.label, type.count]));
            }
        }

        var csv = '';
        file.forEach(function (row) {
            csv += row.join(',');
            csv += '\n';
        });
        // document.write(csv);
        var a = document.createElement('a');
        a.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = `${selectedTab} Dashboard Report.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    };
    const showModal = (modalId) => {
        setType(modalId);
        setShowDetailsModal(true);
    };

    const handleOffcanvasBody = () => {
        return (
            <div className="pt-2 ps-4 pe-4 bg-white">
                <ul className="list-group list-group-flush border border-2 border-dashed mb-0 mt-2">
                    {types === 'device_type' &&
                        deviceTypes?.length > 0 &&
                        deviceTypes.map((ele, index) => {
                            return (
                                <li key={index} className="list-group-item px-0">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 ms-2">
                                            <p className="fs-14">{ele.label}</p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <p className="fs-14 mb-0">{ele.count}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    {types === 'mac_device_type' &&
                        macDeviceTypes?.length > 0 &&
                        macDeviceTypes.map((ele, index) => {
                            return (
                                <li key={index} className="list-group-item px-0">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 ms-2">
                                            <p className="fs-14">{ele.label}</p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <p className="fs-14 mb-0">{ele.count}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    {types === 'dashboard_os_distribution' &&
                        osVersion?.length > 0 &&
                        osVersion.map((ele, index) => {
                            return (
                                <li key={index} className="list-group-item px-0">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 ms-2">
                                            <p className="fs-14">{ele.label}</p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <p className="fs-14 mb-0">{ele.count}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    {types === 'mac_dashboard_os_distribution' &&
                        macosVersion?.length > 0 &&
                        macosVersion.map((ele, index) => {
                            return (
                                <li key={index} className="list-group-item px-0">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 ms-2">
                                            <p className="fs-14">{ele.label}</p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <p className="fs-14 mb-0">{ele.count}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    };

    const handleIOSDashboard = () => {
        return (
            <>
                <Row>{tenant.IOS_WIDGETS?.includes('dashboard_devices') && <DashboardWidgets ios={true} devices={devices} />}</Row>
                <Row>
                    {iosDashboardsData?.map(
                        (field, index) =>
                            tenant.IOS_WIDGETS?.includes(field?.id) && (
                                <Col xl={4} md={4} key={index}>
                                    <DashboardCharts details={field.details || []} field={field} showDetailsModal={showModal} />
                                </Col>
                            )
                    )}
                </Row>
            </>
        );
    };

    const handlelMacDashboard = () => {
        return (
            <>
                <Row>{tenant.IOS_WIDGETS?.includes('dashboard_devices') && <DashboardWidgets ios={true} devices={macDevices} />}</Row>
                <Row>
                    {macDashboardsData?.map(
                        (field, index) =>
                            tenant.IOS_WIDGETS?.includes(field?.id) && (
                                <Col xl={4} md={4} key={index}>
                                    <DashboardCharts details={field.details || []} field={field} showDetailsModal={showModal} />
                                </Col>
                            )
                    )}
                </Row>
            </>
        );
    };

    const appleOSList = [
        {
            tabID: 'ios',
            tabName: (
                <div className="d-flex align-items-center gap-2">
                    <i className="ri-apple-line" />
                    iOS
                </div>
            ),
            tabContent: handleIOSDashboard()
        },
        {
            tabID: 'macos',
            tabName: (
                <div className="d-flex align-items-center gap-2">
                    <i className="ri-macbook-line"></i>MAC
                </div>
            ),
            tabContent: handlelMacDashboard()
        }
    ];

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Dashboard" history={history} homeLink="Dashboard" hideLinks={true} handleExport={handleExport} />
                    <TabsComponent
                        tabsList={appleOSList?.filter((list) => tenant.IOS_POLICY_TYPES?.includes(list.tabID))}
                        defaultTabID={tenant.IOS_POLICY_TYPES?.includes('ios') ? 'ios' : 'macos'}
                        noPadding={true}
                        toggleTab={(tab) => setSelectedTab(tab)}
                        custombg={' p-0'}
                    />
                    <OffcanvasModal
                        direction="end"
                        toggle={toggle}
                        open={showDetailsModal}
                        handleCloseClick={toggle}
                        OffcanvasModalID={types}
                        hideSaveButton={true}
                        closeButtonBorder={true}
                        handleOffcanvasBody={handleOffcanvasBody}
                        modalClassName={'w-25'}
                        offcanvasHeader={
                            types === 'device_type' || types === 'mac_device_type'
                                ? 'Device Type'
                                : types === 'super_unSuper_devices'
                                ? 'Supervised Vs Unsupervised Devices'
                                : 'OS Distribution'
                        }
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default IOSDashboard;
