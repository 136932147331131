import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Col, Input, Label, Spinner, UncontrolledTooltip } from 'reactstrap';
import { IsAuthorized, Tooltip, convertBytesTo, convertDataToBytes, convertTextCase, getDate } from '../../../Components/Common/Util';
import {
    togglePassword,
    togglePasswordChange,
    togglePasswordChangeVal,
    tooglePasswordStrengthIn,
    tooglePasswordStrengthOut
} from '../../../Components/Hooks/UserHooks';
import { ACTIONS, ACTION_CODES, priviliges } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import * as url from '../../../helpers/url_helper';

import antivirusIcon from '../../../assets/images/common/svg/devicesViewIcons/antiirus.svg';
import hardwareIcon from '../../../assets/images/common/svg/devicesViewIcons/hardware.svg';
import ClearData from '../../../assets/images/common/png/devicesViewIcons/DataLoss.png';
import acerIcon from '../../../assets/images/common/png/devicesViewIcons/acerIcon.png';
import appsImage from '../../../assets/images/common/svg/devicesViewIcons/apps.svg';
import cameraAction from '../../../assets/images/common/png/devicesViewIcons/cameraAction.png';
import celkonIcon from '../../../assets/images/common/png/devicesViewIcons/celkonIcon.png';
import clearCacheImg from '../../../assets/images/common/png/devicesViewIcons/clearCacheDel.png';
import clearcache from '../../../assets/images/common/svg/devicesViewIcons/clearcache.svg';
import deviceSecurity from '../../../assets/images/common/svg/devicesViewIcons/deviceSecurity.svg';
import factoryModeImage from '../../../assets/images/common/png/devicesViewIcons/factorMode.png';
import hpIcon from '../../../assets/images/common/png/devicesViewIcons/hp.png';
import lockDevice from '../../../assets/images/common/png/devicesViewIcons/lockDevice.png';
import nokiaIcon from '../../../assets/images/common/png/devicesViewIcons/nokiaIcon.png';
import AIRTEL from '../../../assets/images/common/png/devicesViewIcons/airtel.png';
import BSNL from '../../../assets/images/common/png/devicesViewIcons/bsnl.png';
import JIO from '../../../assets/images/common/png/devicesViewIcons/jio.png';
import SimIcon from '../../../assets/images/common/svg/devicesViewIcons/simInfo.svg';
import VI from '../../../assets/images/common/png/devicesViewIcons/vi.png';
import phone from '../../../assets/images/common/png/devicesViewIcons/phone.png';
import rebootDevice from '../../../assets/images/common/png/devicesViewIcons/rebootDevice.png';
import samsungIcon from '../../../assets/images/common/png/devicesViewIcons/samsungIcon.png';
import Scream from '../../../assets/images/common/png/devicesViewIcons/scream.png';
import softwareIcon from '../../../assets/images/common/svg/devicesViewIcons/software.svg';
import syncDevice from '../../../assets/images/common/png/devicesViewIcons/sync.png';
import xls from '../../../assets/images/common/png/devicesViewIcons/xls.png';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';

const actionImages = { clearcache: clearcache };

const operatorImages = { jio: JIO, bsnl: BSNL, airtel: AIRTEL, vi: VI };

export const handleLauncherPasswordModalBody = ({ customLauncherPassword, setCustomLauncherPassword }) => {
    return (
        <React.Fragment>
            <div className="mb-4">
                <Label className="form-label">Current Password</Label>
                <div className="position-relative auth-pass-inputgroup">
                    <Input
                        onFocus={() => tooglePasswordStrengthIn('password-contain')}
                        onBlur={(e) => {
                            togglePasswordChangeVal(customLauncherPassword) && tooglePasswordStrengthOut('password-contain');
                        }}
                        name="customLauncherPasswordChange"
                        type="password"
                        autoComplete="off"
                        className="form-control pe-5 password-input"
                        onPaste={() => false}
                        placeholder="Enter Custom Launcher Password"
                        id="custom-password-input"
                        aria-describedby="customPasswordInput"
                        required
                        onChange={(e) => {
                            setCustomLauncherPassword(e.target.value);
                            togglePasswordChange(e.target.value);
                        }}
                        value={customLauncherPassword || ''}
                    />
                    {customLauncherPassword && customLauncherPassword !== '' && (
                        <Button
                            color="link"
                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button"
                            onClick={() => togglePassword('custom-password-input', 'password-icon1')}
                        >
                            <i id="password-icon1" className="ri-eye-off-fill align-middle"></i>
                        </Button>
                    )}
                </div>
                <div id="passwordInput" className="form-text">
                    Must be at least 8 characters.
                </div>
            </div>
            <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                <h5 className="fs-13">Password must contain:</h5>
                <p id="pass-length" className="invalid fs-12 mb-2">
                    Minimum <b>8 characters</b>
                </p>
                <p id="pass-max-length" className="invalid fs-12 mb-2">
                    Maximum <b>12 characters</b>
                </p>
                <p id="pass-lower" className="invalid fs-12 mb-2">
                    At least 1 <b>lowercase</b> letter (a-z)
                </p>
                <p id="pass-upper" className="invalid fs-12 mb-2">
                    At least 1 <b>uppercase</b> letter (A-Z)
                </p>
                <p id="pass-number" className="invalid fs-12 mb-2">
                    At least 1 <b>number</b> (0-9)
                </p>
                <p id="pass-special" className="invalid fs-12 mb-0">
                    At least 1 <b>Special</b> Symbol
                </p>
            </div>
            <div className="mt-4 fw-semibold">
                <span className="text-muted">Last Modified Password Date : </span>
                <span className="text-danger fw-bold">20-07-2023 10:00 PM</span>
            </div>
        </React.Fragment>
    );
};

export const handleKioskExitBody = (qrCodeString) => {
    return (
        <div className={`d-flex align-items-center justify-content-center flex-wrap ${qrCodeString ? '' : 'mt-3'}`}>
            {qrCodeString ? <img alt="" className="w-75" src={`${qrCodeString}`} /> : <Spinner color="primary" className="spinner-style" />}
        </div>
    );
};

export const actionMessage = ({
    deviceData,
    type,
    antivirusAction,
    setAntivirusAction,
    setActionText,
    factoryMode,
    setMonitoring,
    monitoring,
    setFactoryMode
}) => {
    return type === 'lock' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to lock Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={lockDevice} alt="device" width={60} height={60} />
                </div>
                <div>
                    Serial: <span className="fw-semibold">{deviceData?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'reboot' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to reboot Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={rebootDevice} alt="device" width={60} height={60} />
                </div>
                <div>
                    Serial: <span className="fw-semibold">{deviceData?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'antivirus' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={antivirusIcon} alt="device" width={60} height={60} />
                </div>
                <div className="mb-4">Are you sure you want to {antivirusAction === 'START' ? 'start' : 'stop'} scan?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <div className="p-1 d-flex align-items-center border border-1 border-info rounded-3">
                        <div
                            className={`${
                                antivirusAction === 'STOP' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-2 py-1 cursor-pointer`}
                            onClick={() => {
                                setAntivirusAction('STOP');
                                setActionText('Stop Scan');
                            }}
                        >
                            STOP
                        </div>
                        <div
                            className={`${
                                antivirusAction === 'START' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-2 py-1 cursor-pointer`}
                            onClick={() => {
                                setAntivirusAction('START');
                                setActionText('Scan Now');
                            }}
                        >
                            START
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'factory_mode' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={factoryModeImage} alt="factory_mode" width={60} height={60} />
                </div>
                <div className="mb-4">Are you sure you want to {factoryMode === 'enable' ? 'enable' : 'disable'} factory mode?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <div className="p-1 d-flex align-items-center border border-1 border-info rounded-3">
                        <div
                            className={`${
                                factoryMode === 'disable' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-2 py-1 cursor-pointer`}
                            onClick={() => {
                                setFactoryMode('disable');
                                setActionText('Yes, Disable it!');
                            }}
                        >
                            Disable
                        </div>
                        <div
                            className={`${
                                factoryMode === 'enable' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-2 py-1 cursor-pointer`}
                            onClick={() => {
                                setFactoryMode('enable');
                                setActionText('Yes, Enable it!');
                            }}
                        >
                            Enable
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'CAMERA' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={cameraAction} alt="device" width={60} height={60} />
                </div>
                <div className="mb-4">Are you sure you want to Enable / Disable the camera?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <div className="p-1 d-flex align-items-center border border-1 border-info rounded-3">
                        <div
                            className={`${
                                monitoring.action === 'DISABLE' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-2 py-1 cursor-pointer`}
                            onClick={() => setMonitoring({ ...monitoring, action: 'DISABLE' })}
                        >
                            DISABLE
                        </div>
                        <div
                            className={`${
                                monitoring.action === 'ENABLE' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-2 py-1 cursor-pointer`}
                            onClick={() => setMonitoring({ ...monitoring, action: 'ENABLE' })}
                        >
                            ENABLE
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'REFRESH' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to Sync Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={syncDevice} alt="device" width={60} height={60} />
                </div>
                <div>
                    Serial: <span className="fw-semibold">{deviceData?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    ) : type === 'RETIRE' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-3">Are you sure you want to Retire this Device?</div>
                <div className="mb-3 d-flex align-items-center justify-content-center">
                    <img src={ClearData} alt="device" width={60} height={60} />
                </div>
                <div className="mt-3 text-center">
                    Serial: <span className="fw-semibold">{deviceData?.serial}</span>
                </div>
                <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                    <div>
                        Upon confirmation, the device will be removed from the list. This action would take few minutes to remove the device
                        completely.
                    </div>
                </Alert>
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={Scream} alt="device" width={60} height={60} />
                </div>
                <div className="mb-4">Are you sure you want to {monitoring.action === 'START' ? 'activate' : 'de-activate'} scream?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <div className="p-1 d-flex align-items-center border border-1 border-info rounded-3">
                        <div
                            className={`${
                                monitoring.action === 'STOP' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-2 py-1 cursor-pointer`}
                            onClick={() => setMonitoring({ ...monitoring, action: 'STOP' })}
                        >
                            STOP
                        </div>
                        <div
                            className={`${
                                monitoring.action === 'START' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-2 py-1 cursor-pointer`}
                            onClick={() => setMonitoring({ ...monitoring, action: 'START' })}
                        >
                            START
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export const deviceIcon = (hardwareData) => {
    let icon;
    if (hardwareData?.brand?.toLowerCase() === 'samsung') icon = samsungIcon;
    else if (hardwareData?.brand?.toLowerCase() === 'acer') icon = acerIcon;
    else if (hardwareData?.brand?.toLowerCase() === 'nokia') icon = nokiaIcon;
    else if (hardwareData?.brand?.toLowerCase() === 'celkon') icon = celkonIcon;
    else if (hardwareData?.brand?.toLowerCase() === 'hp') icon = hpIcon;
    // else if (hardwareData?.brand?.toLowerCase() === 'lenovo') icon = lenovoIcon;
    return icon;
};
export const dynamicComponent = (
    key,
    value,
    options,
    { handleChangeObj, onCancelObj, onSubmitObj },
    recordID,
    setPolicyShift,
    obj,
    urlconf
) => {
    return (
        <React.Fragment>
            <Select
                id={key}
                className="max-width-250"
                name={value?.value}
                getOptionLabel={(option) => option.name}
                options={options}
                value={value}
                isSearchable={true}
                placeholder={`Select ${key}`}
                classNamePrefix={'custom-select'}
                onChange={(e) => handleChange(e, key, handleChangeObj)}
                noOptionsMessage={() => 'No data found'}
            />
            <i
                title="Close"
                className="ri-close-line ms-2 me-2 fs-18 cursor-pointer link-danger"
                onClick={() => onCancel(key, onCancelObj)}
            />
            <i
                title="Submit"
                className="ri-check-line fs-18 cursor-pointer link-primary"
                onClick={() => {
                    key === 'Policy'
                        ? setPolicyShift({
                              confirmation: true,
                              details: { key: key, onSubmitObj: onSubmitObj, recordID: recordID },
                              obj: obj
                          })
                        : onSubmit(key, onSubmitObj, recordID, setPolicyShift, '', urlconf);
                }}
            />
        </React.Fragment>
    );
};

export const handleChange = (e, key, obj) => {
    if (key === 'Policy') obj.setPolicy(e);
    else if (key === 'Device State') obj.setDeviceState(e);
    else obj.setDeviceGroup(e);
};

export const onCancel = (key, obj) => {
    if (key === 'Policy') {
        obj.setShowPolicyDropdown(false);
        obj.setPolicy({
            label: obj.deviceData?.policyAppliedName,
            name: obj.deviceData?.policyAppliedName,
            value: obj.deviceData?.policyApplied
        });
    } else if (key === 'Device State') {
        obj.setShowDeviceState(false);
        obj.setDeviceState({
            label: obj.deviceData?.rawMsg?.state,
            name: obj.deviceData?.rawMsg?.state,
            value: obj.deviceData?.rawMsg?.state
        });
    } else {
        obj.setShowDeviceGroup(false);
        obj.setDeviceGroup({ ...obj.selectedDeviceGroup });
    }
};

export const onSubmit = (key, obj, recordID, setPolicyShift, fullObj, urlconf) => {
    let param = fullObj ? fullObj?.dynamicCompObj?.onSubmitObj : obj;
    param.setLoading(true);
    let params = {};
    if (key === 'Policy') params = { updateType: 'POLICY', policyId: param.policy?.value };
    else if (key === 'Device State') params = { updateType: 'STATE', state: param.deviceState?.value };
    else params = { updateType: 'DEVICEGROUP', deviceGroupCode: param.selectedDeviceGroup?.value };
    if (setPolicyShift) setPolicyShift({ confirmation: false, details: '' });

    param.api
        .update(url.DEVICES + '/' + recordID, params)
        .then((resp) => {
            resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (resp.status?.toLowerCase() === 'success') {
                toast.success(
                    key === 'Policy'
                        ? toastMessages.devicePolicyUpdate
                        : key === 'Device State'
                        ? toastMessages.deviceStatusUpdate
                        : toastMessages.deviceGroupUpdate
                );
                if (key === 'Policy') {
                    let policyCode = resp?.data?.policyName?.split('/')?.reverse()[0];
                    param.setShowPolicyDropdown(false);
                    param.setPolicy({ label: param.policy?.label, name: param.policy?.name, value: policyCode });
                } else if (key === 'Device State') {
                    param.setShowDeviceState(false);
                    param.setDeviceState({ label: resp?.data?.state, name: resp?.data?.state, value: resp?.data?.state });
                } else {
                    param.setShowDeviceGroup(false);
                    param.setDeviceGroup({ ...param.selectedDeviceGroup });
                }
            }
            param.setLoading(false);
        })
        .catch((err) => {
            param.setLoading(false);
        });
};

export const iconReturnData = (key, ele, ind) => {
    return (
        <tr className="w-100">
            <td className="fw-medium w-70">
                <div className="d-flex align-items-center text-truncate">
                    {ACTIONS[ele.commandType]?.image ? (
                        <img src={actionImages[ACTIONS[ele.commandType]?.image]} alt="clearcache" className="me-2" width={18} height={18} />
                    ) : (
                        <i
                            title={
                                ACTIONS[ele.commandType]?.label
                                    ? ACTIONS[ele.commandType]?.label
                                    : ele.message?.action === ACTIONS[ele.commandType]?.action1
                                    ? ACTIONS[ele.commandType]?.label1
                                    : ACTIONS[ele.commandType]?.label2
                            }
                            className={`${
                                ele.message?.action === 'STOP'
                                    ? ACTIONS[ele.commandType]?.off
                                    : ACTIONS[ele.commandType]?.icon
                                    ? ACTIONS[ele.commandType]?.icon
                                    : ele.message?.action === ACTIONS[ele.commandType]?.action1
                                    ? ACTIONS[ele.commandType]?.icon1
                                    : ACTIONS[ele.commandType]?.icon2
                            } me-2 fs-18 cursor-pointer link-secondary`}
                        />
                    )}
                    {key + (ele.commandType === 'Scream' ? (ele.message?.action === 'STOP' ? ' Off' : ' On') : '')}
                    {ACTIONS[ele.commandType]?.showBadge && (
                        <span className={'ms-1 lh-sm badge badge-soft-' + ACTIONS[ele.commandType]?.badgeColor}>
                            {ele?.message?.appName}
                        </span>
                    )}
                </div>
            </td>
            <td className="text-info w-30 word-wrap-break m-0">
                <span className={'lh-sm fw-semibold text-truncate text-' + ACTION_CODES[ele.commandStatus]?.class}>
                    {ACTION_CODES[ele.commandStatus]?.name}
                </span>
            </td>
            <td className="text-info w-30 text-truncate word-wrap-break m-0" id={`date-${ind}`}>
                {ele.commandDate ? getDate(ele.commandDate) : '—'}
            </td>
        </tr>
    );
};

const onClick = (key, obj) => {
    if (key === 'Policy') obj.setShowPolicyDropdown(true);
    else if (key === 'Device State') obj.setShowDeviceState(true);
    else obj.setShowDeviceGroup(true);
};

export const returnData = (key, val, edit, obj, recordID, setPolicyShift, info, urlconf) => {
    return edit && key === 'Device Group' ? (
        <tr className="row w-100">
            <td className="fw-medium w-50 col-6">
                <div className="d-flex align-items-center">
                    {key}
                    {obj.showDeviceGroup && (
                        <>
                            <i className="ri-information-line blue-icon ps-1 mt-1" id={'UncontrolledTooltipExample'} />
                            <UncontrolledTooltip placement="bottom" target={'UncontrolledTooltipExample'}>
                                It will update once the device gets refreshed.
                            </UncontrolledTooltip>
                        </>
                    )}
                </div>
            </td>
            <td className="w-50 word-wrap-break col-6">
                <div className="d-flex align-items-center">
                    <div className="max-width-150">
                        <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>{val ? val : 'Any'}</EllipsisToolTip>
                    </div>
                    <IsAuthorized
                        privRequired={priviliges.DEVICE_EDITOR}
                        yes={() => (
                            <i
                                title="Edit"
                                className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                onClick={() => onClick(key, obj.onClickObj)}
                            />
                        )}
                    />
                </div>
            </td>
        </tr>
    ) : edit && key === 'Policy' ? (
        <tr className="row w-100">
            <td className="fw-medium w-50 col-6">
                <div className="d-flex align-items-center">
                    {key}
                    {obj.showPolicyDropdown && (
                        <>
                            <i className="ri-information-line blue-icon ps-1" id={'UncontrolledTooltipExample'} />
                            <UncontrolledTooltip placement="bottom" target={'UncontrolledTooltipExample'}>
                                It will update once the device gets refreshed.
                            </UncontrolledTooltip>
                        </>
                    )}
                </div>
            </td>
            <td className="w-50 word-wrap-break col-6">
                <div className="d-flex align-items-center">
                    {!obj.showPolicyDropdown ? (
                        <>
                            <div className="max-width-150">
                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>{val ? val : 'Any'}</EllipsisToolTip>
                            </div>
                            <IsAuthorized
                                privRequired={priviliges.DEVICE_EDITOR}
                                yes={() => (
                                    <i
                                        title="Edit"
                                        className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                        onClick={() => onClick(key, obj.onClickObj)}
                                    />
                                )}
                            />
                        </>
                    ) : (
                        dynamicComponent(key, obj.policy, obj.policyOptions, obj.dynamicCompObj, recordID, setPolicyShift, obj, urlconf)
                    )}
                </div>
            </td>
        </tr>
    ) : edit && key === 'Device State' ? (
        <tr className="row w-100">
            <td className="fw-medium w-50 col-6">
                <div className="d-flex align-items-center">
                    {key}
                    {obj.showDeviceState && (
                        <>
                            <i className="ri-information-line blue-icon ps-1 mt-1" id={'UncontrolledTooltipExample'} />
                            <UncontrolledTooltip placement="bottom" target={'UncontrolledTooltipExample'}>
                                It will update once the device gets refreshed.
                            </UncontrolledTooltip>
                        </>
                    )}
                </div>
            </td>
            <td className="w-50 word-wrap-break col-6">
                <div className="d-flex align-items-center">
                    {!obj.showDeviceState ? (
                        <>
                            <div className="max-width-250">
                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>{val ? val : 'Any'}</EllipsisToolTip>
                            </div>
                            <IsAuthorized
                                privRequired={priviliges.DEVICE_EDITOR}
                                yes={() => (
                                    <i
                                        title="Edit"
                                        className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                        onClick={() => onClick(key, obj.onClickObj)}
                                    />
                                )}
                            />
                        </>
                    ) : (
                        dynamicComponent(key, obj.deviceState, obj.deviceStateOptions, obj.dynamicCompObj, recordID, '', obj, urlconf)
                    )}
                </div>
            </td>
        </tr>
    ) : edit && (key === 'SIM 1' || key === 'SIM 2') ? (
        <tr>
            <td className="fw-medium w-50">
                <div className="d-flex align-items-center">{key}</div>
            </td>
            <td className="w-50 word-wrap-break">
                <div className="d-flex align-items-center">
                    {!obj.showEditSim?.[key?.replaceAll(' ', '')?.toLowerCase()] ? (
                        <>
                            <div className="max-width-250">
                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>{val ? val : 'Any'}</EllipsisToolTip>
                            </div>
                            <IsAuthorized
                                privRequired={priviliges.DEVICE_EDITOR}
                                yes={() => (
                                    <i
                                        title="Edit"
                                        className="ri-edit-box-line ps-4 fs-18 cursor-pointer link-secondary"
                                        onClick={() => obj.onClickEditSim(key?.replaceAll(' ', '')?.toLowerCase())}
                                    />
                                )}
                            />
                        </>
                    ) : (
                        obj.inputComponent(key?.replaceAll(' ', '')?.toLowerCase())
                    )}
                </div>
            </td>
        </tr>
    ) : (
        <tr className="row w-100">
            <td className="fw-medium w-50 col-6">
                <div className="d-flex align-items-center">
                    {key}
                    {info && (
                        <>
                            <i className="ms-1 cursor-pointer ri-information-line link-info" id={`info-${key?.replaceAll(' ', '')}`} />
                            {Tooltip(`info-${key?.replaceAll(' ', '')}`, info)}
                        </>
                    )}
                </div>
            </td>
            <td className="text-grey-text fw-medium w-50 word-wrap-break">
                <div className="max-width-300">
                    <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                        {val || val === 0 ? val : key === 'SIM 1' || key === 'SIM 2' ? 'Any' : '—'}
                    </EllipsisToolTip>
                </div>
            </td>
        </tr>
    );
};
export const viewDeviceHeader = ({ hardwareData, deviceData, disableActions, tenantConfig }) => {
    return (
        <>
            <div className="w-100 mt-3 d-flex justify-content-center">
                <img src={deviceIcon(hardwareData)} alt="" />
            </div>
            <div className="w-100 mt-3 d-flex justify-content-center">
                <p className="fs-18 fw-bold mb-0">{hardwareData?.brand?.toUpperCase()}</p>
            </div>
            <div className="w-100 d-flex justify-content-center">
                <p className="fs-16 mb-2">Model :</p>
                <p className="fs-16 ps-2 text-info mb-2">{hardwareData?.model}</p>
            </div>
            {tenantConfig.ENABLE_DATA_TRACKING &&
                !!tenantConfig?.DEVICE_ACTIONS?.includes('APP_USAGE') &&
                disableActions?.['appUsageReport'] && (
                    <>
                        <div
                            className={`my-4 d-flex justify-content-center align-items-center border rounded p-2 ${
                                deviceData.allAppsDailyDataUsage >
                                convertDataToBytes(tenantConfig.MAX_DATA_USAGE + '' + tenantConfig.MAX_DATA_USAGE_UNIT?.toUpperCase())
                                    ? 'border-danger bg-soft-danger '
                                    : 'border-success bg-soft-success'
                            } `}
                        >
                            <div>
                                <div className="text-center px-4 border-end border-white">
                                    <p className=" fw-medium mb-2 fs-14 ">Data Usage Limit</p>
                                    <p className="fs-13 fw-medium mb-0 text-muted">
                                        {tenantConfig.MAX_DATA_USAGE + ' ' + tenantConfig.MAX_DATA_USAGE_UNIT?.toUpperCase()}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="text-center px-4">
                                    <p className=" fw-medium mb-2 fs-14 "> Consumed Data</p>
                                    <p className="d-flex align-items-center justify-content-center mb-0 ">
                                        <span
                                            className={`fs-12 fw-medium px-1 text-center border rounded-pill  ${
                                                deviceData.allAppsDailyDataUsage >
                                                convertDataToBytes(
                                                    tenantConfig.MAX_DATA_USAGE + '' + tenantConfig.MAX_DATA_USAGE_UNIT?.toUpperCase()
                                                )
                                                    ? 'border-danger text-danger'
                                                    : 'border-success text-success'
                                            } `}
                                        >
                                            {deviceData.dataUsed}
                                        </span>
                                        <i
                                            className={`${
                                                deviceData.allAppsDailyDataUsage >
                                                convertDataToBytes(
                                                    tenantConfig.MAX_DATA_USAGE + '' + tenantConfig.MAX_DATA_USAGE_UNIT?.toUpperCase()
                                                )
                                                    ? 'ri-information-line fs-16 ps-1 text-black-50'
                                                    : ''
                                            }`}
                                            id="data-usage-limit"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <UncontrolledTooltip placement="bottom" target="data-usage-limit">
                            Reached the data usage limit
                        </UncontrolledTooltip>
                    </>
                )}
        </>
    );
};
export const message = (clearCacheApp) => {
    return (
        <div className="mt-3">
            <div className="mb-2">Do you want to clear Cache?</div>
            <div className="mb-4">
                <span className="text-primary">{clearCacheApp.title}</span>
            </div>
            <div className="mb-4 d-flex align-items-center justify-content-center">
                <img src={clearCacheImg} alt="deletepolicy" width={60} height={60} />
            </div>
            <div className="mt-2 d-flex align-items-center font-size-13 text-danger font-style-italic justify-content-center fs-14">
                <i className="ri-information-line me-1" />
                Both Cache & Data will be cleared on Android 13 & Above.
            </div>
        </div>
    );
};
export const violationCard = (ele, ind, violationDate) => {
    return (
        <Card className="card-height-100 border shadow" key={ind}>
            <div className="card-body">
                <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                        <span
                            className={`avatar-title bg-soft-${
                                Object.values(ele)?.[0]?.level?.toLowerCase() === 'low'
                                    ? 'warning'
                                    : Object.values(ele)?.[0]?.level?.toLowerCase() === 'medium'
                                    ? 'orange-1'
                                    : 'danger'
                            } text-${
                                Object.values(ele)?.[0]?.level?.toLowerCase() === 'low'
                                    ? 'warning'
                                    : Object.values(ele)?.[0]?.level?.toLowerCase() === 'medium'
                                    ? 'orange-1'
                                    : 'danger'
                            } rounded-2 fs-2`}
                        >
                            <i
                                className={`${Object.values(ele)?.[0]?.icon} text-${
                                    Object.values(ele)?.[0]?.level?.toLowerCase() === 'low'
                                        ? 'warning'
                                        : Object.values(ele)?.[0]?.level?.toLowerCase() === 'medium'
                                        ? 'orange-1'
                                        : 'danger'
                                }`}
                            ></i>
                        </span>
                    </div>
                    <div className="flex-grow-1 overflow-hidden ms-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <Col sm={6} className="text-truncate" id={`violationtitle-${ind}`}>
                                <div className="d-flex align-items-center">
                                    <p className=" fw-medium text-truncate m-0">
                                        {(Object.values(ele)?.[0]?.key === 'SIM1_OPERATOR' ||
                                            Object.values(ele)?.[0]?.key === 'SIM2_OPERATOR') &&
                                            'SIM'}
                                        {Object.values(ele)?.[0]?.key === 'MEMORYCARD_USED' && 'SD Card'}
                                        {Object.values(ele)?.[0]?.key === 'GEO_FENCE' && 'Geo-fence'}
                                        {Object.values(ele)?.[0]?.key === 'UNAUTHORIZED_APPS' && 'Un-authorized Apps'}
                                    </p>
                                </div>
                            </Col>
                            {Tooltip(
                                `violationtitle-${ind}`,
                                Object.values(ele)?.[0]?.key === 'SIM1_OPERATOR' || Object.values(ele)?.[0]?.key === 'SIM2_OPERATOR'
                                    ? 'SIM'
                                    : Object.values(ele)?.[0]?.key === 'MEMORYCARD_USED'
                                    ? 'SD Card'
                                    : Object.values(ele)?.[0]?.key === 'GEO_FENCE'
                                    ? 'Geo-fence'
                                    : Object.values(ele)?.[0]?.key === 'UNAUTHORIZED_APPS'
                                    ? 'Un-authorized Apps'
                                    : ''
                            )}
                            <Col sm={6} className="text-truncate" id={`violationDate-${ind}`}>
                                <div className="d-flex align-items-center">
                                    <span className="fs-12 text-muted text-truncate">{getDate(violationDate)}</span>
                                </div>
                            </Col>
                            {Tooltip(`violationDate-${ind}`, getDate(violationDate))}
                        </div>

                        <p className="text-truncate mb-0 fs-13">{Object.values(ele)?.[0]?.details}</p>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export const handleAppsInPolicy = (applicationsInPolicy, handleClearCache) => {
    return (
        <Card className="card-height-100 mb-3">
            <CardHeader className="align-items-center d-flex">
                <img src={appsImage} alt="Non Compliance Icon"></img>
                <h4 className="ps-2 card-title mb-0 flex-grow-1 text-success">Apps</h4>
                <div className="flex-shrink-0"></div>
            </CardHeader>
            <CardBody className="p-0">
                {applicationsInPolicy?.length > 0 ? (
                    applicationsInPolicy.map((app, ind) => {
                        return (
                            <div className="d-flex align-items-center justify-content-between border-bottom pt-2 pb-2 ps-4 pe-4" key={ind}>
                                <div className="d-flex align-items-center">
                                    <img src={app?.smallIconUrl} alt={app?.title} height={25} width={25} className="me-1" />
                                    <span className="fw-medium fs-12">{app?.title}</span>
                                </div>
                                <IsAuthorized
                                    privRequired={priviliges.DEVICE_EDITOR}
                                    yes={() => (
                                        <div className="d-flex align-items-center gap-7">
                                            <img
                                                src={clearcache}
                                                id={`clear_cache-${ind}`}
                                                alt="clear_cache"
                                                width={15}
                                                height={15}
                                                className="cursor-pointer me-2"
                                                onClick={() => handleClearCache(app)}
                                            />
                                            {Tooltip(`clear_cache-${ind}`, 'Clear Cache')}
                                        </div>
                                    )}
                                />
                            </div>
                        );
                    })
                ) : (
                    <div className="mt-3 ms-2">No Apps Found.</div>
                )}
            </CardBody>
        </Card>
    );
};

export const handleAntiVirusFunc = ({ antivirusData, handleViewThreats, setShowAntivirusHistory, getAntiVirus }) => {
    return (
        <Card className="card-height-100 mb-3">
            <CardHeader className="align-items-center d-flex">
                <img src={antivirusIcon} alt="Anti virus Icon"></img>
                <h4 className="ps-2 card-title mb-0 flex-grow-1 text-success">Anti Virus</h4>
                <div className="flex-shrink-0"></div>
            </CardHeader>
            <CardBody>
                {antivirusData?.status ? (
                    <>
                        <div className="d-flex align-items-center flex-column justify-content-center margin-bottom-30">
                            <img src={antivirusIcon} alt="anti virus" height={55} className="margin-bottom-15" />
                        </div>
                        <div className="table-responsive table-card margin-bottom-10">
                            <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                                <tbody>
                                    {returnData('Scanned Date', antivirusData?.scannedDate)}
                                    {returnData('Files', antivirusData?.numberOfFilesScanned)}
                                    {returnData('Duration', antivirusData?.duration)}
                                    <tr className="row w-100">
                                        <td className="fw-medium w-50 col-6 fw-medium">Threats</td>
                                        <td className="text-grey-text fw-medium w-50 word-wrap-break">
                                            <div className="d-flex max-width-450">
                                                <span className="text-danger fw-semibold">
                                                    Detected (
                                                    {antivirusData?.noOfFilesDetected === 0
                                                        ? '0'
                                                        : antivirusData?.noOfFilesDetected < 10
                                                        ? '0' + antivirusData?.noOfFilesDetected
                                                        : antivirusData?.noOfFilesDetected}
                                                    )
                                                </span>
                                                {antivirusData?.noOfFilesDetected > 0 && (
                                                    <span
                                                        className="text-info fw-medium fs-14 ms-2 cursor-pointer"
                                                        onClick={handleViewThreats}
                                                    >
                                                        View
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    {returnData('Performed By', antivirusData?.performedBy)}
                                    <tr className="row w-100">
                                        <td className="fw-medium w-50 col-6 fw-medium">Status</td>
                                        <td className="text-grey-text fw-medium w-50 word-wrap-break">
                                            {antivirusData.status ? (
                                                <div className="d-flex align-items-center">
                                                    <span className={'lh-sm badge badge-soft-' + antivirusData?.statusClass}>
                                                        {antivirusData.status}
                                                    </span>
                                                </div>
                                            ) : (
                                                '—'
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    'No Scan Found.'
                )}
            </CardBody>
            {antivirusData?.status && (
                <CardFooter>
                    <div className="d-flex align-items-center justify-content-center">
                        <span
                            className="text-info cursor-pointer"
                            onClick={() => {
                                setShowAntivirusHistory(true);
                                getAntiVirus({ page: 1, size: 10 });
                            }}
                        >
                            View History
                        </span>
                    </div>
                </CardFooter>
            )}
        </Card>
    );
};

export const appsInPolicyFunc = ({ applicationsInPolicy, handleClearCache }) => {
    return (
        <Card className="card-height-100 mb-3">
            <CardHeader className="align-items-center d-flex">
                <img src={appsImage} alt="Non Compliance Icon"></img>
                <h4 className="ps-2 card-title mb-0 flex-grow-1 text-success">Apps</h4>
                <div className="flex-shrink-0"></div>
            </CardHeader>
            <CardBody className=" height-500 overflow-y-auto">
                {applicationsInPolicy?.length > 0
                    ? applicationsInPolicy.map((app, ind) => {
                          return (
                              <div
                                  className="d-flex align-items-center justify-content-between border-bottom pt-2 pb-2 ps-4 pe-4"
                                  key={ind}
                              >
                                  <div className="d-flex align-items-center">
                                      <img src={app?.smallIconUrl} alt={app?.title} height={25} width={25} className="me-1" />
                                      <span className="fw-medium fs-12">{app?.title}</span>
                                  </div>
                                  <IsAuthorized
                                      privRequired={priviliges.DEVICE_EDITOR}
                                      yes={() => (
                                          <div className="d-flex align-items-center gap-7">
                                              <img
                                                  src={clearcache}
                                                  id={`clear_cache-${ind}`}
                                                  alt="clear_cache"
                                                  width={15}
                                                  height={15}
                                                  className="cursor-pointer me-2"
                                                  onClick={() => handleClearCache(app)}
                                              />
                                              {Tooltip(`clear_cache-${ind}`, 'Clear Cache')}
                                          </div>
                                      )}
                                  />
                              </div>
                          );
                      })
                    : 'No Apps Found.'}
            </CardBody>
        </Card>
    );
};

export const actionHistoryFunc = ({ tenantConfig, commandHistory }) => {
    return (
        <Card className={`card-height-${!!tenantConfig?.DEVICE_ACTIONS?.includes('ANTIVIRUS') ? '100' : '70'} mb-3`}>
            <CardHeader className="align-items-center d-flex">
                <img src={deviceSecurity} alt="Non Compliance Icon"></img>
                <h4 className="ps-2 card-title mb-0 flex-grow-1 text-success">Action History</h4>
                <div className="flex-shrink-0"></div>
            </CardHeader>
            <CardBody className="overflow-y-auto">
                <div className="table-responsive table-card">
                    <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                        <tbody>
                            {commandHistory?.length > 0 ? (
                                commandHistory.map((ele, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {iconReturnData(
                                                ACTIONS[ele.commandType]?.label
                                                    ? ACTIONS[ele.commandType]?.label
                                                    : ele.message?.action === ACTIONS[ele.commandType]?.action1
                                                    ? ACTIONS[ele.commandType]?.label1
                                                    : ACTIONS[ele.commandType]?.label2,
                                                ele,
                                                index
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td className="pb-6 fs-13">{'No Records Found.'}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
};
export const handleHardwareInfoFunc = ({ hardwareData, deviceData }) => {
    return (
        <Card className="card-height-100 mb-3">
            <CardHeader className="align-items-center d-flex">
                <img src={hardwareIcon} alt="Hardware Icon"></img>
                <h4 className="ps-2 card-title mb-0 flex-grow-1 text-success">Hardware Information</h4>
                <div className="flex-shrink-0"></div>
            </CardHeader>
            <CardBody>
                <div className="table-responsive table-card">
                    <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                        <tbody>
                            {returnData('Brand', hardwareData?.brand)}
                            {returnData('Device Base band Version', hardwareData?.deviceBasebandVersion)}
                            {returnData('Manufacturer', hardwareData?.manufacturer)}
                            {returnData('Model', hardwareData?.model)}
                            {returnData('Hardware', hardwareData?.hardware)}
                            {returnData('Memory', convertBytesTo(deviceData?.rawMsg?.memoryInfo?.totalRam))}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
};
export const handleSimInfoFunc = (simData) => {
    return (
        <Card className="card-height-100 mb-3">
            <CardHeader className="align-items-center d-flex">
                <img src={SimIcon} alt="SIM_INFO"></img>
                <h4 className="ps-2 card-title mb-0 flex-grow-1 text-success">SIM Lock</h4>
                <span
                    className={`lh-sm badge badge-soft-${
                        simData.simStatus === 'blocked' ? 'danger' : simData.simStatus === 'NA' ? 'dark-gray' : 'success'
                    }`}
                >
                    {convertTextCase(simData.simStatus, true)}
                </span>
                <div className="flex-shrink-0"></div>
            </CardHeader>
            <CardBody>
                <div className="table-responsive table-card">
                    <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                        <tbody>
                            <tr>
                                <td>
                                    <p className="fs-16 mb-2 fw-semibold">SIM 1</p>
                                    <div className="mb-4">
                                        <p className="fs-14 fw-regular text-dark-gray mb-1">Operator</p>
                                        <div className="d-flex align-items-center gap-2 fs-12 fw-medium mb-0">
                                            {simData.operator1 && simData.operator1 !== 'Any' && simData.operator1 !== 'None' && (
                                                <img
                                                    src={operatorImages[simData.operator1]}
                                                    alt={simData.operator1}
                                                    width={16}
                                                    height={16}
                                                />
                                            )}
                                            <span>{simData.operator1 ? convertTextCase(simData.operator1) : 'Not available'}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-dark-gray mb-2 fs-14 fw-medium">Serial Number(ICCID)</p>
                                        <div className="d-flex align-items-center mb-2 gap-1">
                                            <div className="d-flex align-items-center justify-content-center rounded-5 shadow-card width-30 height-30">
                                                <img src={xls} alt="file" width={15} height={20} />
                                            </div>
                                            <span className={`fw-semibold fs-14 ${!simData.iccid1Matched ? 'text-danger' : ''}`}>
                                                {simData.iccid1Configured ? simData.iccid1Configured : 'Not configured'}
                                            </span>
                                            {!simData.iccid1Matched && (
                                                <>
                                                    <i className="ri-information-line fs-14" id="iccid1notmatched" />
                                                    {Tooltip('iccid1notmatched', 'Configured SIM and installed SIM did not match')}
                                                </>
                                            )}
                                        </div>
                                        <div className="d-flex align-items-center mb-2 gap-1">
                                            <div className="d-flex align-items-center justify-content-center rounded-5 shadow-card width-30 height-30">
                                                <img src={phone} alt="phone" width={15} height={20} />
                                            </div>
                                            <span className="fw-regular text-dark-gray fs-14">
                                                {simData.iccid1Installed ? simData.iccid1Installed : 'Not available'}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="fs-16 mb-2 fw-semibold">SIM 2</p>
                                    <div className="mb-4">
                                        <p className="fs-14 fw-regular text-dark-gray mb-1">Operator</p>
                                        <div className="d-flex align-items-center gap-2 fs-12 fw-medium mb-0">
                                            {simData.operator2 && simData.operator2 !== 'Any' && simData.operator2 !== 'None' && (
                                                <img
                                                    src={operatorImages[simData.operator2]}
                                                    alt={simData.operator2}
                                                    width={16}
                                                    height={16}
                                                />
                                            )}
                                            <span>{simData.operator2 ? convertTextCase(simData.operator2) : 'Not available'}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-dark-gray mb-2 fs-14 fw-medium">Serial Number(ICCID)</p>
                                        <div className="d-flex align-items-center mb-2 gap-1">
                                            <div className="d-flex align-items-center justify-content-center rounded-5 shadow-card width-30 height-30">
                                                <img src={xls} alt="file" width={15} height={20} />
                                            </div>
                                            <span className={`fw-semibold fs-14 ${simData.iccid2Matched ? '' : 'text-danger'}`}>
                                                {simData.iccid2Configured ? simData.iccid2Configured : 'Not configured'}
                                            </span>
                                            {!simData.iccid2Matched && (
                                                <>
                                                    <i className="ri-information-line fs-14" id="iccid1notmatched" />
                                                    {Tooltip('iccid1notmatched', 'Configured SIM and installed SIM did not match')}
                                                </>
                                            )}
                                        </div>
                                        <div className="d-flex align-items-center mb-2 gap-1">
                                            <div className="d-flex align-items-center justify-content-center rounded-5 shadow-card width-30 height-30">
                                                <img src={phone} alt="phone" width={15} height={20} />
                                            </div>
                                            <span className="fw-regular text-dark-gray fs-14">
                                                {simData.iccid2Installed ? simData.iccid2Installed : 'Not available'}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
};
export const handleSoftwareInfoFunc = ({ softwareData, deviceData }) => {
    return (
        <Card className="card-height-100 mb-3">
            <CardHeader className="align-items-center d-flex">
                <img src={softwareIcon} alt="Software Icon"></img>
                <h4 className="card-title ps-2 mb-0 flex-grow-1 text-success">Software Information</h4>
                <div className="flex-shrink-0"></div>
            </CardHeader>
            <CardBody>
                <div className="table-responsive table-card">
                    <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                        <tbody>
                            {returnData('Android Version', softwareData?.androidVersion)}
                            {returnData('Software Update', softwareData?.systemUpdateInfo?.updateStatus)}
                            {returnData('Device Policy Version Code', softwareData?.androidDevicePolicyVersionCode)}
                            {returnData('Device Policy Version Name', softwareData?.androidDevicePolicyVersionName)}
                            {returnData('Android Build Number', softwareData?.androidBuildNumber)}
                            {returnData('Device Kernel Version', softwareData?.deviceKernelVersion)}
                            {softwareData?.bootloaderVersion !== 'unknown' &&
                                returnData('Boot Loader Version', softwareData?.bootloaderVersion)}
                            {returnData('Android Build Time', softwareData?.androidBuildTime)}
                            {returnData('Security Patch Level', softwareData?.securityPatchLevel)}
                            {returnData('Device Build Signature', softwareData?.deviceBuildSignature)}
                            {returnData('API Version', deviceData?.rawMsg?.apiLevel)}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
};
export const handleDeviceIdentityDetailsFunc = ({ hardwareData, deviceData }) => {
    return (
        <Card className="card-height-100 mb-0">
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1 text-success">Device Identity Details</h4>
                <div className="flex-shrink-0"></div>
            </CardHeader>
            <CardBody>
                <div className="table-responsive table-card">
                    <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                        <tbody>
                            {returnData('Device ID', deviceData?.deviceId)}
                            {returnData('Serial Number', hardwareData?.serialNumber)}
                            {returnData(
                                'IMEI',
                                deviceData?.rawMsg?.networkInfo?.imei
                                    ? deviceData?.rawMsg?.networkInfo?.imei
                                    : deviceData?.details?.additionalInfo?.IMEI
                            )}
                            {returnData('MAC Address', deviceData?.rawMsg?.networkInfo?.wifiMacAddress)}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
};
export const handleDeviceSecurityFunc = ({ deviceData }) => {
    return (
        <Card className="card-height-100 mb-3">
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1 text-success">Device Security</h4>
                <div className="flex-shrink-0"></div>
            </CardHeader>
            <CardBody>
                <div className="table-responsive table-card">
                    <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                        <tbody>
                            {returnData(
                                'Encrypted',
                                deviceData?.rawMsg?.deviceSettings?.isEncrypted === true
                                    ? 'Yes'
                                    : deviceData?.rawMsg?.deviceSettings?.isEncrypted === false
                                    ? 'No'
                                    : '—'
                            )}
                            {returnData(
                                'Play Protect Enabled',
                                deviceData?.rawMsg?.deviceSettings?.verifyAppsEnabled === true
                                    ? 'Yes'
                                    : deviceData?.rawMsg?.deviceSettings?.verifyAppsEnabled === false
                                    ? 'No'
                                    : '—'
                            )}
                            {returnData('Policy Compliance', deviceData?.rawMsg?.policyCompliant ? 'Yes' : 'No')}
                            {returnData('Encryption Status', deviceData?.rawMsg?.deviceSettings?.encryptionStatus)}
                            {returnData('Device Posture', deviceData?.rawMsg?.securityPosture?.devicePosture)}
                            {returnData('Compliance Status', deviceData?.details?.compliance)}
                            {returnData('Password Protected', deviceData?.details?.additionalInfo?.DEVICE_PROTECTED)}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
};
export const handleDeviceSummaryFunc = ({
    hardwareData,
    deviceData,
    deviceState,
    deviceGroup,
    returnDataObj,
    policy,
    disableActions,
    tenantConfig,
    recordID,
    setPolicyShift,
    hideSwitchEdits,
    noEdit,
    urlconf
}) => {
    return (
        <Card className="card-height-100 mb-0">
            <CardHeader className="d-flex align-items-center flex-column">
                {hardwareData?.brand && viewDeviceHeader({ hardwareData, deviceData, disableActions, tenantConfig })}
                <div className="w-100 d-flex justify-content-start mt-2">
                    <h4 className="card-title mb-0 flex-grow-1 text-success">Device Summary</h4>
                    <div className="flex-shrink-0"></div>
                </div>
            </CardHeader>
            <CardBody>
                <div className="table-responsive table-card">
                    <table className="table table-wrap table-borderless table-centered align-middle mb-0">
                        <tbody>
                            {returnData(
                                'Device State',
                                deviceState?.label,
                                (deviceData.deviceStatusApplied &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'retire' &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'deleted' &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'deleting' &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'provisioning') ||
                                    (deviceData.deviceStatusConfigured &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'retire' &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'deleted' &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'deleting' &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'provisioning')
                                    ? hideSwitchEdits
                                        ? false
                                        : !noEdit
                                    : false,
                                returnDataObj,
                                recordID,
                                '',
                                '',
                                urlconf
                            )}
                            {returnData(
                                'Device Group',
                                deviceGroup?.label,
                                (deviceData.deviceStatusApplied &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'retire' &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'deleted' &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'deleting' &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'provisioning') ||
                                    (deviceData.deviceStatusConfigured &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'retire' &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'deleted' &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'deleting' &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'provisioning')
                                    ? hideSwitchEdits
                                        ? false
                                        : !noEdit
                                    : false,
                                returnDataObj,
                                recordID,
                                '',
                                '',
                                urlconf
                            )}
                            {returnData(
                                'Policy',
                                policy?.label,
                                (deviceData.deviceStatusApplied &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'retire' &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'deleted' &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'deleting' &&
                                    deviceData.deviceStatusApplied?.toLowerCase() !== 'provisioning') ||
                                    (deviceData.deviceStatusConfigured &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'retire' &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'deleted' &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'deleting' &&
                                        deviceData.deviceStatusConfigured?.toLowerCase() !== 'provisioning')
                                    ? hideSwitchEdits
                                        ? false
                                        : !noEdit
                                    : false,
                                returnDataObj,
                                recordID,
                                setPolicyShift,
                                '',
                                urlconf
                            )}
                            {returnData('EMM ID', deviceData.id)}
                            {returnData('Applied Policy Version', deviceData?.rawMsg?.appliedPolicyVersion)}
                            {returnData('Enterprise ID', deviceData?.enterprise)}
                            {returnData('Enrolled Time', getDate(deviceData?.rawMsg?.enrollmentTime))}
                            {returnData(
                                'Last Policy Sync Time',
                                deviceData?.rawMsg?.lastStatusReportTime
                                    ? getDate(deviceData?.rawMsg?.lastStatusReportTime)
                                    : getDate(deviceData?.lastmodifiedTime),
                                false,
                                '',
                                '',
                                '',
                                'The last time the device fetched its policy.'
                            )}
                            {returnData(
                                'Last Status Report Time',
                                getDate(deviceData?.rawMsg?.lastStatusReportTime),
                                false,
                                '',
                                '',
                                '',
                                'The last time the device sent a status report.'
                            )}
                            {returnData('Management Mode', deviceData?.rawMsg?.managementMode)}
                            {returnData('Ownership', deviceData?.rawMsg?.ownership)}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
};
export const violationsBody = ({ violationSummary, violationDate }) => {
    return (
        <div>
            {violationSummary?.length > 0 ? (
                // eslint-disable-next-line array-callback-return
                violationSummary.map((ele, ind) => {
                    if (Object.values(ele)?.[0]?.key === 'SIM1_OPERATOR') {
                        return violationCard(ele, ind, violationDate);
                    }
                    if (Object.keys(ele)?.[0] === 'SIM2_OPERATOR') {
                        return violationCard(ele, ind, violationDate);
                    }
                    if (Object.keys(ele)?.[0] === 'MEMORYCARD_USED') {
                        return violationCard(ele, ind, violationDate);
                    }
                    if (Object.keys(ele)?.[0] === 'GEO_FENCE') {
                        return violationCard(ele, ind, violationDate);
                    }
                    if (Object.keys(ele)?.[0] === 'UNAUTHORIZED_APPS') {
                        return violationCard(ele, ind, violationDate);
                    }
                })
            ) : (
                <div className="fs-13 d-flex align-items-center justify-content-center">{'No Records Found.'}</div>
            )}
        </div>
    );
};
