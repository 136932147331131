export const policiesSchema = [
    {
        Header: 'Policy Name',
        accessor: 'name',
        sortable: true,
        customCell: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        fieldType: 'iconClickable',
        view: true
    },
    {
        Header: 'Policy Code',
        accessor: 'code',
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        sortable: true
    },
    {
        Header: 'Policy Type',
        accessor: 'polType',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Policy Version',
        accessor: 'policyVersion',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'Devices',
        accessor: 'deviceCount',
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        sortable: true
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: false
    },
    {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        customCell: true,
        fieldType: 'switch',
        width: '30px',
        maxWidth: '30px'
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        qrCode: true,
        editFadable: true,
        fadeEdit: 'rowEdit',
        fadeTooltipKeyEdit: 'editTooltip',
        deleteFadable: true,
        fadeTooltipKeyDelete: 'deleteTooltip',
        fade: 'doNotDelete',
        clone: true,
        enrollmentToken: true
        // auditlog: true
    }
];

export const androidTVPoliciesSchema = [
    {
        Header: 'Policy Name',
        accessor: 'name',
        sortable: true,
        customCell: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        fieldType: 'clickable',
        view: true
    },
    {
        Header: 'Policy Code',
        accessor: 'code',
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        sortable: true
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: false
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        edit: true
        // qrCode: true
        /*
         * deleteFadable: true,
         * fadeTooltip: 'You cannot delete this policy, as it is already applied to a device.',
         * fade: 'doNotDelete',
         * clone: true,
         * enrollmentToken: true
         */
    }
];

export const makerSchema = [
    {
        Header: 'Policy Name',
        accessor: 'name',
        sortable: true,
        customCell: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        fieldType: 'clickable',
        view: true
    },
    {
        Header: 'Policy Code',
        accessor: 'code',
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        sortable: true
    },
    {
        Header: 'Policy Type',
        accessor: 'polType',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Policy Version',
        accessor: 'policyVersion',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'Devices',
        accessor: 'deviceCount',
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        sortable: true
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: false
    },
    {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        customCell: true,
        fieldType: 'switch',
        width: '30px',
        maxWidth: '30px'
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        qrCode: true,
        deleteFadable: true,
        editFadable: true,
        fade: 'rowDelete',
        fadeEdit: 'rowEdit',
        fadeTooltipKeyDelete: 'deleteTooltip',
        fadeTooltipKeyEdit: 'editTooltip',
        clone: true,
        enrollmentToken: true,
        onHideAction: 'hideActionMessage',
        onHideClass: 'policyBadgeClass'
    }
];

export const checkerSchema = [
    {
        Header: 'Policy Name',
        accessor: 'name',
        sortable: true,
        customCell: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        fieldType: 'clickable',
        view: true
    },
    {
        Header: 'Policy Code',
        accessor: 'code',
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        sortable: true
    },
    {
        Header: 'Policy Type',
        accessor: 'polType',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Policy Version',
        accessor: 'policyVersion',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'Created By',
        accessor: 'createdBy',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Created Date',
        accessor: 'createdDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: false
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true
    },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: false
    },
    {
        Header: 'Status',
        accessor: 'statusApplied',
        sortable: true,
        customCell: true,
        fieldType: 'badge'
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'takeAction',
        takeActionClass: 'info',
        hideTakeAction: 'hideTakeAction'
    }
];
