import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, FormFeedback, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TabsComponent from '../../../Components/Common/TabsComponent';
import { Tooltip, getFormTypeAndRecordId } from '../../../Components/Common/Util';
import AllowedDomains from '../../../assets/images/common/png/common/allowedDomains.png';
import BlockedDomains from '../../../assets/images/common/png/common/blockedDomains.png';
import bookmarkImg from '../../../assets/images/common/png/common/bookmark.png';
import TickMark from '../../../assets/images/common/svg/common/tickmark.svg';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';

const AddBrowserSettings = () => {
    const urlconf = useEnv();
    let history = useHistory();
    let api = new APIClient();

    const [loading, setLoading] = useState(false);
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];

    document.title = formType === 'add' ? 'Add Browser Settings' : formType === 'edit' ? 'Edit Browser Settings' : 'View Browser Settings';

    const [mode, setMode] = useState(formType);
    const [allowedList, setAllowedList] = useState([]);
    const [blockedList, setBlockedList] = useState([]);
    const [allowByDefaultBrowser, setAllowByDefaultBrowser] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selVal, setSelVal] = useState('');
    const [formValues, setFormValues] = useState('');
    const [selectedTab, setSelectedTab] = useState('config');
    const [browserData, setBrowserData] = useState({});

    useEffect(() => {
        setLoading(true);
        getAppType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAppType = () => {
        api.get(url.APPLICATIONS + '/BROWSER')
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) setBrowserData(resp.data?.[0]);
                setLoading(false);
                if (recordID) getOneBrowserConfig();
            })
            .catch((_err) => setLoading(false));
    };

    const getOneBrowserConfig = () => {
        setLoading(true);
        api.get(url.MANAGED_CONFIGURATIONS + '/' + recordID)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    setFormValues(resp.data);
                    setAllowByDefaultBrowser(resp.data?.config?.allowByDefault);
                    setAllowedList(resp.data?.config?.allowedDomains);
                    setBlockedList(resp.data?.config?.blockedDomains);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: formValues.title ? formValues.title : '',
            bookmarkList: formValues?.config?.bookmarks ? formValues?.config?.bookmarks : []
        },
        onSubmit: (values) => {
            setLoading(true);
            let obj = {
                title: values.name,
                packageName: browserData?.packageName,
                managedConfigId: formType === 'edit' ? formValues.managedConfigId : null,
                config: {
                    allowByDefault: !!allowByDefaultBrowser,
                    allowedDomains: allowedList?.length > 0 ? allowedList : [],
                    blockedDomains: blockedList?.length > 0 ? blockedList : [],
                    bookmarks: values.bookmarkList?.length > 0 ? values.bookmarkList : []
                }
            };
            if (formType === 'edit') obj.id = recordID;
            createNdUpdate(obj);
        }
    });

    const createNdUpdate = (dataObj) => {
        let apiService;
        if (recordID) apiService = api.update(url.MANAGED_CONFIGURATIONS + '/' + recordID, dataObj);
        else apiService = api.create(url.MANAGED_CONFIGURATIONS, dataObj);

        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.browserConfigUpdate : toastMessages.browserConfigCreate);
                    history.push('/browser');
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleCardClick = (allowed, blocked, type) => {
        if (formType === 'view') return;
        if (allowByDefaultBrowser ? type === 'allowedDomain' : type === 'blockedDomain') return;
        let arr = allowByDefaultBrowser ? allowed : blocked;
        if (arr?.length > 0) {
            setDeleteModal(true);
            setSelVal(!allowByDefaultBrowser);
        } else setAllowByDefaultBrowser(!allowByDefaultBrowser);
    };

    const handleRemoveOneItem = (item) => {
        if (allowByDefaultBrowser) {
            let arr = allowedList.filter((ele) => ele !== item);
            setAllowedList(arr);
        } else {
            let arr = blockedList.filter((ele) => ele !== item);
            setBlockedList(arr);
        }
    };

    const handleRemoveOneBookmark = (ele) => {
        let bookmarks = [...validation.values?.bookmarkList];
        bookmarks = bookmarks.filter((bookmark) => bookmark.url !== ele.url);
        validation.setValues({ ...validation.values, bookmarkList: bookmarks });
    };

    const handleAddUrls = () => {
        if (allowByDefaultBrowser) {
            if (
                !validation.values.allowedDomain?.includes('.') ||
                validation.values.allowedDomain?.endsWith('.') ||
                validation.values.allowedDomain?.startsWith('.')
            ) {
                toast.error(toastMessages.invalidDomain);
                return;
            }
            if (allowedList.includes(validation.values.allowedDomain) || blockedList.includes(validation.values.allowedDomain)) {
                toast.error(toastMessages.domainAlreadyExists);
                return;
            }
            let arr = [...allowedList];
            if (validation.values.allowedDomain) arr.push(validation.values.allowedDomain);
            setAllowedList(arr);
            validation.values.allowedDomain = '';
        } else {
            if (
                !validation.values.blockedDomain?.includes('.') ||
                validation.values.blockedDomain?.endsWith('.') ||
                validation.values.blockedDomain?.startsWith('.')
            ) {
                toast.error(toastMessages.invalidDomain);
                return;
            }
            if (allowedList.includes(validation.values.blockedDomain) || blockedList.includes(validation.values.blockedDomain)) {
                toast.error(toastMessages.domainAlreadyExists);
                return;
            }
            let arr = [...blockedList];
            if (validation.values.blockedDomain) arr.push(validation.values.blockedDomain);
            setBlockedList(arr);
            validation.values.blockedDomain = '';
        }
    };

    const handleDeleteConfirmation = () => {
        setDeleteModal(false);
        setAllowByDefaultBrowser(selVal);
        if (allowByDefaultBrowser) setAllowedList([]);
        else setBlockedList([]);
    };

    const addUrlTemplate = (field) => {
        return (
            <Card className="border mb-sm-0">
                <CardHeader className="fw-semibold" onClick={() => handleCardClick(allowedList, blockedList, field.value)}>
                    <Row className={'g-2 align-items-center ' + (!field.param ? 'pe-none opacity-50' : '')}>
                        <Col>
                            <Label
                                className={
                                    'd-flex flex-column form-check-label fw-medium fs-15' + (formType === 'view' ? ' cursor-auto' : '')
                                }
                            >
                                {field.label}
                                <span className="d-flex align-items-center text-truncate fs-13 mt-2 fw-normal">{field.desc}</span>
                            </Label>
                        </Col>
                        {field.param && (
                            <Col>
                                <div className="d-flex justify-content-end">
                                    <img src={TickMark} alt="" height="25" />
                                </div>
                            </Col>
                        )}
                    </Row>
                </CardHeader>
                <CardBody className={!field.param ? 'pe-none opacity-50' : ''}>
                    <Row>
                        <Col>
                            {formType !== 'view' && (
                                <Row className="g-2 align-items-center">
                                    <Col sm="auto">
                                        <Label className="form-label d-flex align-items-center fw-medium">
                                            Domain<span className="ps-1">:</span>
                                        </Label>
                                    </Col>
                                    <Col>
                                        <div className="input-group">
                                            <Input
                                                name={field.value}
                                                id={field.value}
                                                className="form-control"
                                                placeholder={'Ex : Facebook.com'}
                                                type={'text'}
                                                validate={{ required: { value: true } }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values[field.value] || ''}
                                                invalid={validation.touched[field.value] && validation.errors[field.value] ? true : false}
                                            />
                                            {validation.touched[field.value] && validation.errors[field.value] ? (
                                                <FormFeedback type="invalid">{validation.errors[field.value]}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col sm="auto">
                                        <Button size="sm" type="button" color={'primary'} onClick={handleAddUrls}>
                                            <span className="d-flex align-items-center">
                                                <i className="ri-add-line me-1 fs-16"></i> Add Domain
                                            </span>
                                        </Button>
                                    </Col>
                                    <Col sm="auto">
                                        <Button
                                            size="sm"
                                            outline
                                            type="button"
                                            color="primary"
                                            onClick={() => {
                                                allowByDefaultBrowser ? setAllowedList([]) : setBlockedList([]);
                                                validation.setValues({ ...validation.values, bookmarkList: [] });
                                            }}
                                        >
                                            <span className="d-flex align-items-center">
                                                <i className="ri-close-circle-line me-1 fs-16"></i> Clear All
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                            <Row className="mt-2 border rounded">
                                <Col className="pe-0">
                                    <div className="filter-choices-input height-205 overflow-auto p-2">
                                        <div className="choices" data-type="text">
                                            <div className="choices__inner min-height-1">
                                                <div className="choices__list choices__list--multiple">
                                                    {field.list?.length > 0 ? (
                                                        field.list.map((ele, ind) => {
                                                            return (
                                                                <div
                                                                    key={ind}
                                                                    className={`choices__item choices__item--selectable bg-${field.color} border-white`}
                                                                >
                                                                    {ele}
                                                                    {formType !== 'view' && (
                                                                        <button
                                                                            type="button"
                                                                            className="choices__button"
                                                                            onClick={() => handleRemoveOneItem(ele)}
                                                                        >
                                                                            Remove item
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <div className="d-flex height-180 align-items-center justify-content-center">
                                                            <div className="d-flex align-items-center justify-content-center flex-column">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <img alt="" height={40} className="opacity-50" src={field.img} />
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-center fs-14 fw-normal mt-2">
                                                                    No Domains Found
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    };

    const handleAddBookmark = () => {
        let bookmarkURL = validation.values.bookmark;
        let bookmarkURLArr = bookmarkURL.split('.');
        if (bookmarkURLArr?.length === 1 || bookmarkURLArr[bookmarkURLArr?.length - 1]?.length < 2) {
            toast.error(toastMessages.invalidURL);
            return;
        }
        let checkExist = validation.values.bookmarkList?.find(
            (bookmark) =>
                bookmark.url?.toLowerCase() === 'https://' + validation.values.bookmark?.toLowerCase() ||
                bookmark.name === validation.values.bookmarkName
        );
        if (validation.values?.bookmark?.includes(' ')) {
            toast.error(toastMessages.urlRestriction);
            return;
        }
        if (checkExist) {
            toast.error(toastMessages.existingBookmark);
            validation.setValues({ ...validation.values, bookmark: '', bookmarkName: '' });
            return;
        }
        /*
         * let found = false;
         * if (allowByDefaultBrowser) {
         *     if (allowedList?.length > 0) {
         *         let bookmarkString = allowedList.find((allow) => allow === validation.values?.bookmark);
         *         if (bookmarkString) found = true;
         *     }
         * } else {
         *     found = true;
         *     if (blockedList.length > 0) {
         *         if (blockedList?.length > 0) {
         *             let bookMarkString = blockedList.find((block) => block === validation.values?.bookmark);
         *             if (bookMarkString) found = false;
         *         }
         *     }
         * }
         * if (found)
         */
        validation.setValues({
            ...validation.values,
            bookmarkList: [
                ...validation.values?.bookmarkList,
                { name: validation.values?.bookmarkName, url: 'https://' + validation.values?.bookmark }
            ],
            bookmark: '',
            bookmarkName: ''
        });
        /*
         * else {
         *     toast.error('Bookmark should be in allowed list.');
         *     validation.setValues({ ...validation.values, bookmark: '' });
         * }
         */
    };

    const handleTabContent = (key) => {
        if (key === 'config') {
            return (
                <Row>
                    <Col>
                        {addUrlTemplate({
                            param: allowByDefaultBrowser,
                            color: 'info',
                            id: 'allow-domain',
                            list: allowedList,
                            img: AllowedDomains,
                            value: 'allowedDomain',
                            label: 'Allowed Domains',
                            desc: 'Only added domains are accessible, remaining are blocked'
                        })}
                    </Col>
                    <Col>
                        {addUrlTemplate({
                            param: !allowByDefaultBrowser,
                            color: 'danger',
                            id: 'block-domain',
                            list: blockedList,
                            img: BlockedDomains,
                            value: 'blockedDomain',
                            label: 'Blocked Domains',
                            desc: 'Only added domains are blocked remaining are accessible.'
                        })}
                    </Col>
                </Row>
            );
        } else {
            return (
                <Col>
                    <Card className="border mb-sm-0">
                        <CardBody>
                            <Row>
                                <Col>
                                    {formType !== 'view' && (
                                        <Row className="g-2 align-items-center">
                                            <Col sm="auto">
                                                <Label className="form-label d-flex align-items-center fw-medium">
                                                    Bookmark<span className="ps-1">:</span>
                                                </Label>
                                            </Col>
                                            <Col>
                                                <div className="input-group">
                                                    <Input
                                                        name={'bookmarkName'}
                                                        id={'bookmarkName'}
                                                        className="form-control"
                                                        placeholder={'Bookmark Name Ex : Facebook'}
                                                        type={'text'}
                                                        validate={{ required: { value: true } }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values['bookmarkName'] || ''}
                                                        invalid={
                                                            validation.touched['bookmarkName'] && validation.errors['bookmarkName']
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched['bookmarkName'] && validation.errors['bookmarkName'] ? (
                                                        <FormFeedback type="invalid">{validation.errors['bookmarkName']}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="input-group">
                                                    <Input
                                                        name={'bookmark'}
                                                        id={'bookmark'}
                                                        className="form-control"
                                                        placeholder={'Bookmark URL Ex : Facebook.com'}
                                                        type={'text'}
                                                        validate={{ required: { value: true } }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values['bookmark'] || ''}
                                                        invalid={
                                                            validation.touched['bookmark'] && validation.errors['bookmark'] ? true : false
                                                        }
                                                    />
                                                    {validation.touched['bookmark'] && validation.errors['bookmark'] ? (
                                                        <FormFeedback type="invalid">{validation.errors['bookmark']}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm="auto">
                                                <Button
                                                    size="sm"
                                                    type="button"
                                                    disabled={
                                                        !validation.values.bookmark ||
                                                        !validation.values.bookmarkName ||
                                                        validation.values.bookmark === '' ||
                                                        validation.values.bookmarkName === ''
                                                    }
                                                    color={'primary'}
                                                    onClick={handleAddBookmark}
                                                >
                                                    <span className="d-flex align-items-center">
                                                        <i className="ri-add-line me-1 fs-16"></i> Add Bookmark
                                                    </span>
                                                </Button>
                                            </Col>
                                            <Col sm="auto">
                                                <Button
                                                    size="sm"
                                                    outline
                                                    type="button"
                                                    color="primary"
                                                    disabled={
                                                        !validation.values?.bookmarkList || validation.values?.bookmarkList?.length === 0
                                                    }
                                                    onClick={() => {
                                                        validation.setValues({ ...validation.values, bookmarkList: [] });
                                                    }}
                                                >
                                                    <span className="d-flex align-items-center">
                                                        <i className="ri-close-circle-line me-1 fs-16"></i> Clear All
                                                    </span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row className="mt-2 border rounded">
                                        <Col className="pe-0">
                                            <div className="filter-choices-input height-205 overflow-auto p-2">
                                                <div className="choices" data-type="text">
                                                    <div className="choices__inner min-height-1">
                                                        <div className="choices__list choices__list--multiple">
                                                            {validation.values?.bookmarkList?.length > 0 ? (
                                                                validation.values?.bookmarkList?.map((ele, ind) => {
                                                                    return (
                                                                        <div
                                                                            key={ind}
                                                                            className="choices__item choices__item--selectable bg-primary border-white"
                                                                        >
                                                                            <div id={`bookmark-${ind}`}>
                                                                                {ele.name}
                                                                                {formType !== 'view' && (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="choices__button"
                                                                                        onClick={() => handleRemoveOneBookmark(ele)}
                                                                                    >
                                                                                        Remove item
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                            {Tooltip(`bookmark-${ind}`, ele.url)}
                                                                        </div>
                                                                    );
                                                                })
                                                            ) : (
                                                                <div className="d-flex height-180 align-items-center justify-content-center">
                                                                    <div className="d-flex align-items-center justify-content-center flex-column">
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <img
                                                                                alt=""
                                                                                height={50}
                                                                                className="opacity-50"
                                                                                src={bookmarkImg}
                                                                            />
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-center fs-14 fw-normal mt-2">
                                                                            No Bookmarks Found
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            );
        }
    };

    const tabsList = [
        { tabID: 'config', tabName: 'Configuration', tabContent: handleTabContent('config'), icon: 'ri-settings-5-fill' },
        { tabID: 'bookmarks', tabName: 'Bookmarks', tabContent: handleTabContent('bookmarks'), icon: 'ri-bookmark-fill' }
    ];

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={`${mode?.charAt(0)?.toUpperCase() + mode?.slice(1)} Browser Config`}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="browser"
                    />
                    <React.Fragment>
                        <Card>
                            <CardHeader className="ps-1">
                                <div className="ms-3 d-flex align-items-center">
                                    Name
                                    {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                    <div className={'input-group'}>
                                        {formType !== 'view' ? (
                                            <div className="w-25 ms-5">
                                                <Input
                                                    name={'name'}
                                                    id={'name'}
                                                    className={'form-control'}
                                                    placeholder={'Enter Name'}
                                                    type={'text'}
                                                    maxLength={'30'}
                                                    validate={{ required: { value: true } }}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values['name'] || ''}
                                                    invalid={validation.touched['name'] && validation.errors['name'] ? true : false}
                                                />
                                            </div>
                                        ) : validation?.values['name'] ? (
                                            <span className="ps-1">{validation?.values['name']}</span>
                                        ) : (
                                            '–'
                                        )}
                                        {validation.touched['name'] && validation.errors['name'] ? (
                                            <p className="m-0 mt-2 text-danger">{validation.errors['name']}</p>
                                        ) : null}
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className="p-1">
                                <TabsComponent
                                    tabsList={tabsList}
                                    defaultTabID={'config'}
                                    enableOnView={true}
                                    mode={mode}
                                    selectedTab={selectedTab}
                                    toggleTab={(tab) => setSelectedTab(tab)}
                                />
                            </CardBody>
                            <CardFooter>
                                <Row className="p-0 m-0">
                                    <Col className="p-0 m-0">
                                        <div className="gap-2 d-flex justify-content-end">
                                            <button type="reset" className="btn btn-light" onClick={() => history.push('/browser')}>
                                                Cancel
                                            </button>
                                            {mode !== 'view' && (
                                                <button
                                                    type="submit"
                                                    className="btn btn-success"
                                                    id="add-btn"
                                                    disabled={!validation.values['name'] || validation.values['name'] === '' || loading}
                                                    onClick={(e) => validation.handleSubmit()}
                                                >
                                                    {formValues?.id ? 'Update' : 'Save'}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </React.Fragment>
                </Container>
            </div>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteConfirmation}
                onCloseClick={() => setDeleteModal(false)}
                deleteMessage="If you switch all saved entries will be cleared."
            />
        </React.Fragment>
    );
};

export default AddBrowserSettings;
