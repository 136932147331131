import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import browserConf from '../../../assets/images/common/png/common/browser-config.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { BrowserSchema } from '../TableSchema';

const Browser = () => {
    document.title = 'Browser';
    const urlconf = useEnv();
    let history = useHistory();
    const api = new APIClient();

    const [loading, setLoading] = useState(false);
    const [configs, setConfigs] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [browserSchema, setBrowserSchema] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [configsBackup, setConfigsBackup] = useState([]);
    const [showAddNew, setShowAddNew] = useState(true);

    useEffect(() => {
        getBrowserConfigs({ page: 1, size: 1000 }, 'BROWSER');
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.DEVICEGROUP_EDITOR);
        let schema = [...BrowserSchema];
        if (findPriv) schema = BrowserSchema.filter((_group, index) => index < BrowserSchema.length - 1);
        setBrowserSchema([...schema]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBrowserConfigs = (paramsObj, appCode) => {
        setLoading(true);
        let params = {};
        if (paramsObj) params = paramsObj;
        else params = { page: pageNumber, size: pageSize };
        params.appCode = appCode;
        api.get(url.MANAGED_CONFIGURATIONS, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    setTotalRecords(resp.totalRecords);
                    resp.data.forEach((data) => {
                        data.policies = data.policyNames?.length > 0 ? data.policyNames?.join(',') : '—';
                        data.modifiedBy = data.lastmodifiedBy ? data.lastmodifiedBy : 'Tectoro';
                        data.modifiedDate = data.lastmodifiedTime ? getDate(data.lastmodifiedTime) : '—';
                        data.doNotDelete = data.policyNames?.length > 0;
                    });
                    setConfigs(resp.data);
                    setConfigsBackup(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                if (err === 'Application Code not Found.') setShowAddNew(false);
                setLoading(false);
            });
    };

    const handleDelete = (row) => {
        setDeleteModal(true);
        setSelectedRow(row);
    };

    const handleEdit = (row) => {
        history.push('/browser/edit/' + row.original.id);
    };

    const handleAddNew = () => {
        history.push('/browser/add');
    };

    const handleClickView = (row) => {
        history.push('/browser/view/' + row.original.id);
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        setDeleteModal(false);
        api.delete(url.MANAGED_CONFIGURATIONS + '/' + selectedRow.original.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.browserConfigDelete);
                    getBrowserConfigs({ page: 1, size: 1000 }, 'BROWSER');
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete Browser Config?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={browserConf} alt="Browser Config" width={60} height={60} />
                </div>
                <div>
                    Browser Config: <span className="fw-semibold">{selectedRow.original?.title}</span>
                </div>
            </div>
        );
    };

    const handleSearch = (val) => {
        let configArr = JSON.parse(JSON.stringify(configsBackup));
        if (val?.length > 0) configArr = configArr.filter((conf) => conf?.title?.toLowerCase()?.includes(val?.toLowerCase()));
        setConfigs(configArr);
        setTotalRecords(configArr?.length);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Browser" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Configs'}
                        searchPlaceHolder={'Name'}
                        isGlobalFilter={true}
                        searchFilter2={true}
                        addButton={showAddNew}
                        columns={browserSchema}
                        handleDelete={handleDelete}
                        handleGlobalSearch={handleSearch}
                        handleEdit={handleEdit}
                        handleClickView={handleClickView}
                        handleAddNew={handleAddNew}
                        reader={priviliges.POLICY_READER}
                        editor={priviliges.POLICY_EDITOR}
                        data={configs}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
            <DeleteModal
                hideIcon={true}
                show={deleteModal}
                hideDeleteMessage={true}
                message={message()}
                confirmText={'Yes, Delete it!'}
                onDeleteClick={handleDeleteConfirmation}
                onCloseClick={() => setDeleteModal(false)}
            />
        </React.Fragment>
    );
};

export default Browser;
