import toastMessages from '../../common/messages/toastMessages';

export const messagesSchema = [
    {
        Header: 'Title',
        accessor: 'title',
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sortable: true,
        customCell: true,
        fieldType: 'clickable',
        view: true
    },
    { Header: 'Message Type', accessor: 'type', width: 100, maxWidth: 100, minWidth: 100, sortable: true },
    { Header: 'Receiver Type', accessor: 'receiverType', width: 100, maxWidth: 100, minWidth: 100, sortable: true },
    { Header: 'Receiver', accessor: 'receiverserials', width: 150, maxWidth: 150, minWidth: 150, sortable: true },
    {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        customCell: true,
        fieldType: 'badge',
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    { Header: 'Modified By', accessor: 'modifiedBy', width: 150, minWidth: 150, maxWidth: 150, sortable: true },
    { Header: 'Modified Date', accessor: 'modifiedDate', width: 150, maxWidth: 150, minWidth: 150, sortable: false },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        // deleteFadable: true,
        recall: true,
        editFadableConditionally: true,
        fade: 'rowDelete',
        fadeEdit: 'rowEdit',
        fadeTooltipKeyDelete: 'deleteTooltip',
        fadeTooltipKeyEdit: 'editTooltip',
        width: 60,
        maxWidth: 60,
        minWidth: 60
    }
];

export const viewMessagesStatusSchema = [
    { Header: 'Serial Number', accessor: 'serial', sortable: true },
    { Header: 'Delivery Date', accessor: 'deliveryDate', sortable: false },
    { Header: 'Read Date', accessor: 'readDate', sortable: false },
    { Header: 'Status', accessor: 'msgStatus', sortable: false, customCell: true, fieldType: 'badge' }
];

export const assetsSchema = [
    {
        Header: 'Asset Name',
        accessor: 'name',
        sortable: true,
        customCell: true,
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        fieldType: 'clickable',
        view: true
    },
    { Header: 'Asset Type', accessor: 'assetType', width: 150, minWidth: 150, maxWidth: 150, sortable: true },
    { Header: 'Policies Applied', accessor: 'policies', width: 150, minWidth: 150, maxWidth: 150, sortable: true },
    { Header: 'Attachment', accessor: 'fileName', width: 150, minWidth: 150, maxWidth: 150, sortable: true },
    { Header: 'Modified By', accessor: 'modifiedBy', width: 150, minWidth: 150, maxWidth: 150, sortable: true },
    { Header: 'Modified Date', accessor: 'modifiedDate', width: 150, minWidth: 150, maxWidth: 150, sortable: false },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        minWidth: 20,
        maxWidth: 20,
        width: 20,
        edit: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this asset, as it is already being used in a policy',
        fade: 'rowDelete'
    }
];

export const callLogsSchema = [
    {
        Header: 'Serial',
        accessor: 'serial',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        customCell: true,
        fieldType: 'copy',
        fieldKey: 'serial',
        copyToastMsg: toastMessages.serialCopied
    },
    {
        Header: 'Call Type',
        accessor: 'callType',
        customCell: true,
        fieldType: 'customBadge',
        sortable: true,
        width: 50,
        maxWidth: 50,
        minWidth: 50
    },
    { Header: 'Caller Number', accessor: 'otherNumber', sortable: true, width: 100, minWidth: 100, maxWidth: 100 },
    { Header: 'Call Duration', accessor: 'duration', sortable: true, width: 100, minWidth: 100, maxWidth: 100 },
    {
        Header: 'Call Date',
        accessor: 'callDate',
        sortable: false,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    }
];

export const contentManagementSchema = [
    {
        Header: 'File Name',
        accessor: 'fileName',
        sortable: true,
        maxWidth: 200,
        minWidth: 200,
        customCell: true,
        fieldType: 'fieldClickable',
        view: true
    },
    { Header: 'File Type', accessor: 'fileType', sortable: true },
    { Header: 'File Size', accessor: 'fileSize', sortable: true },
    { Header: 'Created By', accessor: 'createdBy', width: 200, maxWidth: 200, minWidth: 200, sortable: true },
    { Header: 'Created Date', accessor: 'createdDate', sortable: false },
    // { Header: 'Shared Groups', accessor: 'sharedGroup', sortable: false, minWidth: 100, maxWidth: 100, width: 100 },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        minWidth: 20,
        maxWidth: 20,
        width: 20,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this file, as it is shared to a group.',
        fade: 'doNotDelete'
    }
];

export const contentsSchema = [
    {
        Header: 'File Name',
        accessor: 'fileName',
        sortable: false,
        customCell: true,
        fieldType: 'fieldClickable',
        view: true
    },
    { Header: 'File Type', accessor: 'fileType', sortable: false },
    { Header: 'File Size', accessor: 'fileSize', sortable: false },
    { Header: 'Created By', accessor: 'createdBy', sortable: false },
    { Header: 'Created Date', accessor: 'createdDate', sortable: false },
    {
        Header: 'Shared Groups',
        accessor: 'sharedGroupsLength',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        minWidth: 20,
        maxWidth: 20,
        width: 20,
        shareWithText: true,
        text: 'Groups'
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        minWidth: 20,
        maxWidth: 20,
        width: 20,
        delete: true
    }
];

export const userSettingsSchema = [
    {
        Header: 'Name',
        accessor: 'name',
        value: 'name',
        sortable: true,
        customCell: true,
        fieldType: 'avatarText',
        view: true,
        width: 200,
        maxWidth: 200,
        minWidth: 200
    },
    { Header: 'Policies', accessor: 'policies', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified By', accessor: 'modifiedBy', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    {
        Header: 'Actions',
        accessor: 'html',
        sortable: false,
        customCell: true,
        fieldType: 'actions',
        edit: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this configuration, as it is already being used in a policy',
        fade: 'doNotDelete'
    }
];

export const userActivitySchema = [
    { Header: 'Performed on', accessor: 'activity', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    { Header: 'Activity Type', accessor: 'activityType', sortable: true, width: 150, minWidth: 150, maxWidth: 150, view: true },
    { Header: 'Username', accessor: 'userName', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    { Header: 'Date and Time', accessor: 'date', sortable: false, width: 150, minWidth: 150, maxWidth: 150 }
];

export const osDistributionSchema = [
    {
        Header: 'Serial Number',
        accessor: 'serial',
        sortable: true,
        customCell: true,
        fieldType: 'copy',
        fieldKey: 'email',
        copyToastMsg: toastMessages.serialCopied,
        width: 150,
        minWidth: 150,
        maxWidth: 150
    },
    { Header: 'Android Version', accessor: 'androidVersion', sortable: true, width: 50, minWidth: 50, maxWidth: 50 },
    { Header: 'Device Model', accessor: 'deviceModel', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    { Header: 'IMEI', accessor: 'imei', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    { Header: 'Manufacturer', accessor: 'manufacturer', sortable: true, width: 150, minWidth: 150, maxWidth: 150 }
];

export const appInstallationSchema = [
    {
        Header: 'Serial',
        accessor: 'serial',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        customCell: true,
        fieldType: 'copy',
        fieldKey: 'serial',
        copyToastMsg: toastMessages.serialCopied
    },
    // { Header: 'App Name', accessor: 'appName', sortable: true, width: 50, maxWidth: 50, minWidth: 50 },
    { Header: 'Package Name', accessor: 'packageName', sortable: true, width: 50, maxWidth: 50, minWidth: 50 },
    { Header: 'Version', accessor: 'version', sortable: true, width: 100, minWidth: 100, maxWidth: 100 },
    // { Header: 'Status', accessor: 'status', sortable: false, width: 50, maxWidth: 50, minWidth: 50, customCell: true, fieldType: 'badge' },
    { Header: 'Modified Date', accessor: 'modifiedTime', sortable: false, width: 100, minWidth: 100, maxWidth: 100 }
];

export const tvAppInstallationSchema = [
    {
        Header: 'Serial',
        accessor: 'serial',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        customCell: true,
        fieldType: 'copy',
        fieldKey: 'serial',
        copyToastMsg: toastMessages.serialCopied
    },
    { Header: 'App Name', accessor: 'appName', sortable: true, width: 50, maxWidth: 50, minWidth: 50 },
    { Header: 'Package Name', accessor: 'packageName', sortable: true, width: 50, maxWidth: 50, minWidth: 50 },
    { Header: 'Status', accessor: 'status', sortable: false, width: 50, maxWidth: 50, minWidth: 50, customCell: true, fieldType: 'badge' },
    { Header: 'Modified Date', accessor: 'modifiedTime', sortable: false, width: 100, minWidth: 100, maxWidth: 100 }
];

export const teachersLogSchema = [
    { Header: 'Teacher Name', accessor: 'teacherName', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Teacher Code', accessor: 'teacherCode', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Class', accessor: 'class', sortable: true, width: 50, maxWidth: 50, minWidth: 50 },
    { Header: 'Subject', accessor: 'subject', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'School Name', accessor: 'schoolName', sortable: true, width: 150, minWidth: 150, maxWidth: 150 },
    { Header: 'Session Start Date', accessor: 'sessionStartDate', sortable: false, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Session End Date', accessor: 'sessionEndDate', sortable: false, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Head Master Name', accessor: 'hmName', sortable: true, width: 150, minWidth: 150, maxWidth: 150 }
];

export const geoFenceSchemaTv = [
    {
        Header: 'Serial',
        accessor: 'serial',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        customCell: true,
        fieldType: 'copy',
        fieldKey: 'serial',
        copyToastMsg: toastMessages.serialCopied
    },
    { Header: 'Latitude', accessor: 'latitude', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Longitude', accessor: 'longitude', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Status', accessor: 'status', sortable: true, customCell: true, width: 50, maxWidth: 50, minWidth: 50, fieldType: 'badge' },
    { Header: 'Date', accessor: 'date', sortable: false, width: 100, maxWidth: 100, minWidth: 100 }
];

export const geoFenceSchema = [
    {
        Header: 'Serial',
        accessor: 'serial',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        customCell: true,
        fieldType: 'copy',
        fieldKey: 'serial',
        copyToastMsg: toastMessages.serialCopied
    },
    { Header: 'Latitude', accessor: 'latitude', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Longitude', accessor: 'longitude', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Status', accessor: 'status', sortable: true, customCell: true, width: 50, maxWidth: 50, minWidth: 50, fieldType: 'badge' },
    { Header: 'Date', accessor: 'date', sortable: false, width: 100, maxWidth: 100, minWidth: 100 }
];

export const errorDevicesSchema = [
    {
        Header: 'Serial',
        accessor: 'serial',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        customCell: true,
        fieldType: 'copy',
        fieldKey: 'serial',
        copyToastMsg: toastMessages.serialCopied
    },
    { Header: 'Location', accessor: 'location', width: 100, minWidth: 100, maxWidth: 100, sortable: true },
    { Header: 'Storage', accessor: 'storage', width: 100, maxWidth: 100, minWidth: 100, sortable: true },
    {
        Header: 'Launcher Status',
        accessor: 'launcherStatus',
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        customCell: true,
        fieldType: 'infoText',
        infoTooltip: 'infoTooltip',
        sortable: false,
        showTooltipKey: 'showTooltip'
    },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 100, maxWidth: 100, minWidth: 100 }
];

export const scheduledReportsSchema = [
    { Header: 'Report Type', accessor: 'reportType', sortable: true, width: 100, minWidth: 100, maxWidth: 100 },
    { Header: 'Sent To', accessor: 'sentTo', sortable: true, width: 100, minWidth: 100, maxWidth: 100 },
    { Header: 'Sent Date', accessor: 'date', sortable: false, width: 100, minWidth: 100, maxWidth: 100 }
];

export const devicePowerStatus = [
    {
        Header: 'Serial',
        accessor: 'serial',
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        customCell: true,
        fieldType: 'copy',
        fieldKey: 'serial',
        copyToastMsg: toastMessages.serialCopied
    },
    {
        Header: 'Power Status',
        accessor: 'power',
        sortable: true,
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        customCell: true,
        fieldType: 'badge'
    },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 150, minWidth: 150, maxWidth: 150 }
];

export const BrowserSchema = [
    {
        Header: 'Name',
        accessor: 'title',
        customCell: true,
        fieldType: 'clickable',
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150
    },
    {
        Header: 'Package Name',
        accessor: 'packageName',
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150
    },
    { Header: 'Policies', accessor: 'policies', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified By', accessor: 'modifiedBy', sortable: true, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        edit: true,
        deleteFadable: true,
        fadeTooltip: 'You cannot delete this configuration, as it is already being used in a policy',
        fade: 'doNotDelete',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    }
];
