/* eslint-disable max-nested-callbacks */
import React, { useEffect, useState } from 'react';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import { Tooltip } from '../../Components/Common/Util';
import Select from 'react-select';
import appIcon from '../../assets/images/common/svg/devicesViewIcons/apps.svg';

const AppInstall = (props) => {
    const [apps, setApps] = useState([...props.apps]);
    const [policy, setPolicy] = useState('');
    const [installStatus, setInstallStatus] = useState({ name: 'All', value: '' });
    const [policies, setPolicies] = useState(props.policies);
    const [policiesBackup, setPoliciesBackup] = useState(props.policies);
    const [application, setApplication] = useState('');
    const [installStatusOptions, setInstallStatusOptions] = useState([
        { name: 'All Apps', value: '' },
        { name: 'Installed', value: '3' },
        { name: 'Not Installed', value: '1_2' }
    ]);

    useEffect(() => {
        if (props.policies) {
            setPolicies(props.policies);
            setPoliciesBackup(props.policies);
        }
    }, [props.policies]);

    useEffect(() => {
        setApps(props.apps);
    }, [props.apps]);

    const handlePolicySelection = (event) => {
        setPolicy(event);
        props.handlePolicySelection(event);
    };

    const handleApplicationFilter = (e) => {
        setApplication(e.target.value);
        props.handleApplicationFilter(e.target.value);
    };

    const handleInstallStatusSelection = (event) => {
        setInstallStatus(event);
        props.handleInstallStatusSelection(event);
    };

    return (
        <React.Fragment>
            <Col xl={12} md={12}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <h4 className="card-title mb-0 ps-2 flex-grow-1">
                        Apps <span className="text-info">({apps?.length})</span>
                    </h4>
                    <Row>
                        <Col sm="auto">
                            <div className="form-icon width-220">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    id="search-bar-0"
                                    value={application || ''}
                                    className={'holder form-control form-control-icon w-100'}
                                    placeholder={'Search by Application Name'}
                                    onChange={(e) => handleApplicationFilter(e)}
                                />
                                <i className="ri-search-line link-info"></i>
                            </div>
                        </Col>
                        {/* <Col sm="auto">
                            <Select
                                getOptionValue={(option) => option.code}
                                getOptionLabel={(option) => option.name}
                                id={'policy'}
                                name={'policy'}
                                className="min-width-200 max-width-200 width-200"
                                options={policies}
                                placeholder="Select Policy"
                                onChange={(selectedOption) => handlePolicySelection(selectedOption)}
                                value={policy || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </Col> */}
                        {/* <Col sm="auto">
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.name}
                                id={'installStatus'}
                                name={'installStatus'}
                                className="min-width-150 max-width-150 width-150"
                                options={installStatusOptions}
                                placeholder="Select Install Status"
                                onChange={(selectedOption) => handleInstallStatusSelection(selectedOption)}
                                value={installStatus || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </Col> */}
                    </Row>
                </div>
                <Row>
                    {apps?.length === 0 && !props.loading ? (
                        <Col xs={12} sm={12} md={12} xl={3} lg={3} className="w-100 h-100">
                            <Card className="card-height-100">
                                <CardBody className="d-flex align-items-center justify-content-center">
                                    <div className="d-flex align-items-center justify-content-center flex-column">
                                        <img src={appIcon} alt="No Apps" width={60} height={60} />
                                        <span className="text-muted fw-semibold mt-3">No Applications Found</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ) : (
                        apps.map((app, ind) => (
                            <Col xs={12} sm={12} md={12} xl={2} lg={3} key={ind}>
                                <div className="h-100">
                                    <Card className="card-height-100 p-0 pt-5 br-4">
                                        <CardBody className="p-0 d-flex flex-column justify-content-between">
                                            <div className="mt-1">
                                                <div className="d-flex align-items-center flex-column justify-content-center">
                                                    <div className="width-40 height-40">
                                                        <img
                                                            src={app.smallIconUrl}
                                                            alt={'icon'}
                                                            className="rounded-2"
                                                            width={40}
                                                            height={40}
                                                        />
                                                    </div>
                                                    <span className=" mt-2 font-size-10" id={`app-${ind}`}>
                                                        {app.title?.length > 23 ? app.title.substring(0, 22) + '...' : app.title}
                                                    </span>
                                                    {app.title?.length > 23 && Tooltip(`app-${ind}`, app.title)}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center flex-column">
                                                <div className="w-100 padding-left-per-20">
                                                    <Row className="mt-4">
                                                        <Col sm={8}>
                                                            <div className="d-flex align-items-center text-start">
                                                                <i className="ri-checkbox-blank-circle-fill link-success me-2" />
                                                                <span className="text-muted fs-10">Latest</span>
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <span className="fw-bold fs-12 text-success">
                                                                {app.latest ? app.latest : 0}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3 mb-3">
                                                        <Col sm={8}>
                                                            <div className="d-flex align-items-center text-start">
                                                                <i className="ri-checkbox-blank-circle-fill link-warning me-2" />
                                                                <span className="text-muted fs-10">Not Latest</span>
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <span className="fw-bold fs-12 text-warning">
                                                                {app.notLatest ? app.notLatest : 0}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3 mb-3">
                                                        <Col sm={8}>
                                                            <div className="d-flex align-items-center text-start">
                                                                <i className="ri-checkbox-blank-circle-fill link-danger me-2" />
                                                                <span className="text-muted fs-10">Not Installed</span>
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <span className="fw-bold fs-12 text-danger">
                                                                {app.notInstalled ? app.notInstalled : 0}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <div className="progress animated-progess rounded-bottom rounded-0 height-2">
                                            {app.progress?.map((item, key) => (
                                                <div
                                                    className={'progress-bar rounded-0 widthPer-' + item.width + ' ' + item.bgColor}
                                                    role="progressbar"
                                                    // style={{ width: item.width }}
                                                    aria-valuenow="30"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                    key={key}
                                                ></div>
                                            ))}
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                        ))
                    )}
                </Row>
            </Col>
        </React.Fragment>
    );
};

export default AppInstall;
