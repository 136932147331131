import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../../Components/Common/Loader';
import { Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { priviliges } from '../../../Components/constants/constants';
import { AuthUser, TenantConfig, convertUTCtoIST, splitAndConvertPascalCase, uniqBy } from '../../../Components/Common/Util';
import { APIClient } from '../../../helpers/api_helper';
import { useEnv } from '../../../envContext';
import factoryReset from '../../../assets/images/common/png/common/factoryReset.png';
import rebootDevice from '../../../assets/images/common/png/common/rebootDevice.png';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import toastMessages from '../../../common/messages/toastMessages';
import { windowsDevicesSchema } from '../TableSchema';
import { generateFile } from '../../AndroidEnterprise/Devices/AndroidEnterpriseDevicesFunc';

const WindowsDevices = () => {
    document.title = 'Windows Devices';
    const history = useHistory();
    const api = new APIClient();
    const tenantConfig = TenantConfig();
    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [checkAll, setCheckAll] = useState(false);
    const [devicesTableSchema, setDevicesTableSchema] = useState([...windowsDevicesSchema]);
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [showActionModal, setShowActionModal] = useState(false);
    const [actionParams, setActionParams] = useState({ row: '', type: '' });

    useEffect(() => {
        setLoading(true);
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.IOSDEVICE_EDITOR);
        if (findPriv) windowsDevicesSchema = windowsDevicesSchema.filter((_device, index) => index < windowsDevicesSchema.length - 1);
        let schema = [...windowsDevicesSchema];
        schema.forEach((schemaObj) => {
            if (schemaObj.Header === 'Actions') {
                schemaObj.reboot = !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('REBOOT');
                schemaObj.factoryreset = !!tenantConfig?.WINDOWS_DEVICE_ACTIONS_MONITORING?.includes('ERASE_DEVICE');
            }
        });
        const actions = schema[schema.length - 1];
        if (actions.Header === 'Actions' && !actions.reboot && !actions.factoryreset)
            schema = schema.filter((schemaObj) => schemaObj.Header !== 'Actions');
        setDevicesTableSchema(schema);
        handlePromise();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePromise = () => {
        const devicePromise = new Promise((resolve, reject) => {
            api.get(url.DEVICES, searchParams, domains.WINDOWS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp);
                    reject('Devices Failed.');
                })
                .catch((err) => {
                    reject('Devices Failed.');
                });
        });
        Promise.allSettled([devicePromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    handleDeviceBinding(result[0].value);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const getDevices = (params) => {
        setLoading(true);
        setSearchParams(params);
        api.get(url.DEVICES, params, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    handleDeviceBinding(resp);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleDeviceBinding = (devicesData) => {
        devicesData.data?.forEach((data) => {
            data.serial = data.refs?.find((ref) => ref.type === 'SERIAL')?.value || '—';
            data.version = data.software?.osversion || '—';
            data.policy = data.assignment?.policy || '—';
            data.group = data.assignment?.group || '—';
            data.deviceName = data.extra?.devicename || '—';
            data.deviceStatus = data.enroll?.status || '—';
            data.checked = false;
            data.showActions = data.enroll.status === 'ACTIVE';
            data.hideFactoryReset = data.enroll?.status !== 'ACTIVE';
            data.badgeClass = data.enroll?.status === 'NEW' ? 'primary' : data.enroll?.status === 'ACTIVE' ? 'success' : 'danger';
            data.rowView = data.enroll?.status === 'ACTIVE';
            data.model = data?.hardware?.model || '—';
            data.osversion = data?.software?.osversion || '—';
            data.enrolldate = data?.enroll?.enrolldate ? convertUTCtoIST(data?.enroll?.enrolldate) : '—';
            data.enrollmenttype = data?.enroll?.enrollmenttype ? splitAndConvertPascalCase(data?.enroll?.enrollmenttype, '_') : '—';
            data.issupervised = data?.enroll?.issupervised ? 'Yes' : 'No';
        });

        setDevices(devicesData.data || []);
        setTotalRecords(devicesData.totalRecords);
    };

    const handleCheckboxChange = (e, ind, row) => {
        let rows = [...devices];
        rows[ind].checked = e.target.checked;
        if (e.target.checked) setSelectedDevices([...selectedDevices, row.original]);
        else {
            let selDevices = [...selectedDevices];
            selDevices = selDevices.filter((device) => device.serial !== row.original.serial);
            setSelectedDevices(selDevices);
        }
        setDevices(rows);
        let unselectedRows = rows.filter((rowObj) => !rowObj.checked);
        if (unselectedRows?.length > 0) setCheckAll(false);
        else setCheckAll(true);
    };

    const handleCheckAll = (e) => {
        let rows = [...devices];
        rows.forEach((row) => (row.checked = e.target.checked));
        if (e.target.checked) {
            let selDevices = [...selectedDevices, ...rows];
            selDevices = uniqBy(selDevices, 'serial');
            setSelectedDevices(selDevices);
        } else setSelectedDevices([]);
        setCheckAll(!checkAll);
        setDevices(rows);
    };

    const onPageChange = (page) => {
        let params = { ...searchParams, page: page };
        getDevices(params);
    };

    const actions = {
        reboot: {
            info: 'Are you sure you want to reboot the device?',
            image: rebootDevice,
            actionBody: {
                command: 'Reboot',
                cmdtype: 'WINDOWS',
                params: {}
            },
            toastMessage: toastMessages.rebootDevice
        },
        erase: {
            info: 'Are you sure you want to erase the device data?',
            image: factoryReset,
            actionBody: {
                command: 'EraseDevice',
                cmdtype: 'WINDOWS',
                params: {}
            },
            toastMessage: toastMessages.eraseDevice
        }
    };

    const handleAction = (row, type) => {
        setActionParams({ row: row.original, type: type });
        setShowActionModal(true);
    };

    const handleActionsModalBody = () => {
        return (
            <React.Fragment>
                <div className="mt-3">
                    <div className="mb-4">{actions?.[actionParams.type]?.info}</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={actions?.[actionParams.type]?.image} alt="device" width={60} height={60} />
                    </div>
                    <div>
                        Serial: <span className="fw-semibold">{actionParams.row?.serial}</span>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const handleActionSubmit = () => {
        setActionParams({ row: '', type: '' });
        setShowActionModal(false);
        setLoading(true);
        const actionParamsObj = JSON.parse(JSON.stringify(actionParams));
        setActionParams({ row: '', type: '' });
        setShowActionModal(false);
        const body = { ...actions?.[actionParamsObj?.type]?.actionBody, device: actionParamsObj?.row?._id };
        api.create(url.WINDOWS_COMMANDS, body, false, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(actions?.[actionParamsObj?.type]?.toastMessage);
                    getDevices(searchParams);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleOffcanvasBody = () => {
        return <></>;
    };

    const handleSubmit = () => {};

    const handleSearch = (val) => {
        getDevices({ page: 1, size: 10, serial: val?.trim() });
    };

    const handleIsExportAll = () => {
        setLoading(true);
        api.get(url.WINDOWS_DEVICES_EXPORT, '', domains.WINDOWS_V1)
            .then((resp) => {
                generateFile(resp);
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle={'Windows Devices'} history={history} />
                    <div className="mt-4">
                        <Row className="m-0">
                            <TableContainer
                                loading={loading}
                                handleMap={(row) => {}}
                                uploadTooltip="Upload Devices"
                                doNotJustify={true}
                                searchFilter2={true}
                                handleGlobalSearch={handleSearch}
                                handleMoreFilters={() => setShowMoreFilters(true)}
                                isGlobalFilter={true}
                                checkAll={checkAll}
                                data={devices}
                                handleClickView={(row) => {}}
                                columns={devicesTableSchema}
                                totalRecords={totalRecords}
                                pageNum={searchParams.page}
                                reader={priviliges.DEVICE_READER}
                                editor={priviliges.DEVICE_EDITOR}
                                customPageSize={10}
                                handleCheckboxChange={handleCheckboxChange}
                                handleCheckAll={handleCheckAll}
                                onPageChange={onPageChange}
                                handleDeviceReboot={(row) => handleAction(row, 'reboot')}
                                handleFactoryReset={(row) => handleAction(row, 'erase')}
                                searchPlaceHolder="Serial"
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-nowrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                                exportAllLabel="Export All"
                                exportFile5l={true}
                                handleIsExportAll={handleIsExportAll}
                            />
                        </Row>
                    </div>
                    <OffcanvasModal
                        direction="end"
                        toggle={() => setShowMoreFilters(!showMoreFilters)}
                        open={showMoreFilters}
                        hideSaveButton={false}
                        handleCloseClick={() => setShowMoreFilters(!showMoreFilters)}
                        OffcanvasModalID="announcementModal"
                        handleOffcanvasBody={handleOffcanvasBody}
                        modalClassName={'w-25'}
                        offcanvasHeader={'Filters'}
                        handleSaveClick={handleSubmit}
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={showActionModal}
                        hideDeleteIcon={true}
                        hideDeleteMessage={true}
                        message={handleActionsModalBody()}
                        confirmText="Submit"
                        onDeleteClick={handleActionSubmit}
                        onCloseClick={() => {
                            setActionParams({ row: '', type: '' });
                            setShowActionModal(false);
                        }}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WindowsDevices;
