import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Collapse, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import TableContainer from '../../../Components/Common/TableContainer';
import { Tooltip, convertBytesTo, convertSecondsTo, formatDate, sortByAsc } from '../../../Components/Common/Util';
import * as url from '../../../helpers/url_helper';
import { moveGroupsSchema } from './TableSchema';

import { priviliges } from '../../../Components/constants/constants';
import factoryReset from '../../../assets/images/common/png/common/factoryReset.png';

export const handleDeleteBody = (
    hideLordicon,
    {
        wipeDevice,
        setWipeDevice,
        recordID,
        wipeExternalStorage,
        setWipeExternalStorage,
        wipeReasonMessage,
        setWipeReasonMessage,
        setWipeReasonMessageValue,
        wipeReasonMessageValue,
        deviceData
    }
) => {
    return (
        <div className="mt-2 text-center p-4">
            {!hideLordicon && (
                <div className="d-flex align-items-center justify-content-center">
                    <img src={factoryReset} alt="device" width={90} height={90} />
                </div>
            )}

            <div className="mt-3 d-flex align-items-center justify-content-center">
                <Label className="form-check-label d-flex align-items-center">
                    <input
                        className="form-check-input me-3"
                        type="checkbox"
                        value={wipeDevice}
                        id="Factory Reset"
                        checked={true}
                        onChange={(e) => setWipeDevice(e.target.checked)}
                    />
                    Factory Reset
                    <i className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15" id="factory-reset" />
                    <UncontrolledTooltip placement="bottom" target="factory-reset">
                        This device - {recordID} has been remotely wiped for security reasons. All data on the device has been erased.
                    </UncontrolledTooltip>
                </Label>
            </div>
            {Number(deviceData?.rawMsg?.softwareInfo?.androidVersion) >= 7 && (
                <>
                    <div className="mt-3 d-flex align-items-center justify-content-center">
                        <input
                            className="form-check-input me-3"
                            type="checkbox"
                            value={wipeExternalStorage}
                            id="Wipe External Storage"
                            onChange={(e) => setWipeExternalStorage(e.target.checked)}
                        />
                        <Label className="form-check-label d-flex align-items-center">
                            Wipe External Storage
                            <i className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15" id="wipe-external-storage" />
                            <UncontrolledTooltip placement="bottom" target="wipe-external-storage">
                                External storage of your device - {recordID} is being remotely wiped for security and data protection
                                purposes. All data stored on the external storage, including media files and documents, will be permanently
                                erased.
                            </UncontrolledTooltip>
                        </Label>
                    </div>
                    <div className="mt-3 d-flex align-items-center justify-content-center">
                        <input
                            className="form-check-input me-3"
                            type="checkbox"
                            value={wipeReasonMessage}
                            id="Wipe External Storage"
                            onChange={(e) => setWipeReasonMessage(e.target.checked)}
                        />
                        <Label className="form-check-label d-flex align-items-center fw-medium">
                            Wipe Reason Message
                            <i className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15" id="wipe-reason-message" />
                            <UncontrolledTooltip placement="bottom" target="wipe-reason-message">
                                Your device -{recordID} has been remotely wiped for security reasons. To ensure the protection of sensitive
                                data, the device has been reset to its default settings.
                            </UncontrolledTooltip>
                        </Label>
                    </div>
                    {wipeReasonMessage && (
                        <Input
                            type="textarea"
                            id="wipeReasonMessage"
                            name="wipeReasonMessage"
                            className="form-control mt-2"
                            onChange={(e) => setWipeReasonMessageValue(e.target.value)}
                            value={wipeReasonMessageValue}
                            placeholder="Wipe Reason Message"
                        />
                    )}
                </>
            )}
        </div>
    );
};

export const getViolationsSummary = ({ recordID, setViolationDate, setViolationSummary, setSidePanelLoader, setLoading, api, urlconf }) => {
    setLoading(true);
    let params = { id: recordID };
    api.get(url.DEVICE_REPORTS + '/violation', params)
        .then((resp) => {
            resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (resp?.data) {
                let arr = [];
                setViolationDate(resp.data.last_modified_date);
                for (let [k, v] of Object.entries(resp.data)) {
                    // eslint-disable-next-line max-depth
                    if (v.level && v.level?.toLowerCase() !== 'none' && v.details && v.details !== '') {
                        v.icon =
                            k === 'SIM1_OPERATOR' || k === 'SIM2_OPERATOR'
                                ? 'ri-sim-card-2-line'
                                : k === 'GEO_FENCE'
                                ? 'ri-map-pin-line'
                                : k === 'UNAUTHORIZED_APPS'
                                ? 'ri-apps-line'
                                : 'ri-sd-card-mini-line';
                        arr.push({ [k]: v });
                    }
                }
                setViolationSummary(arr);
            }
            setLoading(false);
            setSidePanelLoader(false);
        })
        .catch((_err) => {
            setLoading(false);
            setSidePanelLoader(false);
        });
};
export const deviceGroupBody = ({ subBreadcrumbItems, handleSubBreadcrumb, deviceGroupOptions, handleMoveView, loading }) => {
    return (
        <React.Fragment>
            <div className={'mt-5 mb-4 d-flex align-items-center'}>
                {subBreadcrumbItems?.map((crumbs, ind) => (
                    <React.Fragment key={ind}>
                        {ind !== subBreadcrumbItems?.length - 1 ? (
                            <span
                                className={`fw-medium font-size-12 text-muted ${
                                    ind !== subBreadcrumbItems?.length - 1 ? ' cursor-pointer' : ''
                                }`}
                                onClick={() => handleSubBreadcrumb(crumbs, ind)}
                            >
                                {crumbs.name}
                            </span>
                        ) : (
                            <span
                                className={`fw-medium font-size-12 ${ind !== subBreadcrumbItems?.length - 1 ? ' cursor-pointer' : ''}`}
                                style={{ color: crumbs.avatarColor }}
                            >
                                {crumbs.name}
                            </span>
                        )}
                        {ind !== subBreadcrumbItems?.length - 1 && <i className="ri-arrow-right-s-line ms-2" />}
                    </React.Fragment>
                ))}
            </div>
            <TableContainer
                loading={loading}
                data={deviceGroupOptions}
                columns={moveGroupsSchema}
                handleView={handleMoveView}
                hideAvatar={true}
                customPageSize={10}
                totalRecords={deviceGroupOptions.length ? deviceGroupOptions.length : 0}
                editor={priviliges.DEVICEGROUP_EDITOR}
                reader={priviliges.DEVICEGROUP_READER}
                className="custom-header-css"
                tableHeader="All Device Groups"
                divClass="table-responsive table-card"
                tableClass="table table-nowrap table-border table-centered align-middle"
                theadClass="bg-light text-muted"
            />
        </React.Fragment>
    );
};
const handleAppUsageTabContent = (appUsage, totalAppDetails) => {
    return (
        <>
            <div className={'border-radius-top-left-10 border-radius-top-right-10 padding-y-20 padding-x-15 appusage-background mt-2'}>
                <Row>
                    <Col sm={3} lg={3} xl={3}>
                        <div className="text-light fw-normal fs-14">TOTAL APPS</div>
                        <div className="text-light fw-semibold fs-20 margin-top-10 ms-2">{appUsage?.length}</div>
                    </Col>
                    <Col sm={5} lg={5} xl={5}>
                        <div className="text-light fw-normal fs-14">TOTAL SPENT TIME</div>
                        <div className="text-light fw-semibold fs-20 margin-top-10">{totalAppDetails.time}</div>
                    </Col>
                    <Col sm={4} lg={4} xl={4}>
                        <div className="text-light fw-normal fs-14">TOTAL DATA CONSUMED</div>
                        <div className="d-flex">
                            <div className="text-light fw-semibold fs-20 margin-top-10">
                                <div className="br-25 border border-1 border-color-light dataconsume-box padding-x-13 padding-y-7">
                                    {totalAppDetails.data}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Card className={'border-radius-bottom-right-10 border-radius-bottom-left-10 p-0 shadow-card '}>
                <div className="border-radius-bottom-left-10 border-radius-bottom-right-10">
                    <table className="table">
                        <thead className="table-light">
                            <tr>
                                <th scope="col">App Name</th>
                                <th scope="col">Consumed Data</th>
                                <th scope="col">Spend Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {appUsage?.length > 0 ? (
                                appUsage?.map((app, ind) => (
                                    <React.Fragment key={ind}>
                                        <tr>
                                            <th scope="row">{app.appName}</th>
                                            <td>{app.data}</td>
                                            <td>{app.time}</td>
                                        </tr>
                                    </React.Fragment>
                                ))
                            ) : (
                                <tr>
                                    <td>
                                        <span className="p-2">No Records Found.</span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Card>
        </>
    );
};

export const monitoringItemsFunc = ({ monitoringItems, deviceData, disableActions, handleMonitoring }) => {
    return (
        <div className="d-flex align-items-center justify-content-center gap-1">
            {monitoringItems?.filter((monitor) => monitor.show)?.length > 0 && (
                <div className="fs-15 d-flex justify-content-center align-items-center fw-medium">Monitoring :</div>
            )}
            {monitoringItems?.map((monitor, ind) => {
                return (
                    monitor.show && (
                        <div
                            key={ind}
                            className={`width-35 height-35 d-flex align-items-center justify-content-center ${
                                monitor.status
                                    ? deviceData.deviceStatusApplied?.toLowerCase() === monitor.status
                                        ? monitor.enable
                                            ? disableActions?.[monitor.enable]
                                                ? 'bg-447BD107 cursor-pointer'
                                                : 'bg-muted'
                                            : 'bg-447BD107 cursor-pointer'
                                        : 'bg-muted'
                                    : monitor.enable
                                    ? disableActions?.[monitor.enable]
                                        ? 'bg-447BD107 cursor-pointer'
                                        : 'bg-muted'
                                    : 'bg-447BD107 cursor-pointer'
                            } `}
                            onClick={() => {
                                if (
                                    (monitor.status ? deviceData.deviceStatusApplied?.toLowerCase() === monitor.status : true) &&
                                    disableActions?.[monitor.enable]
                                )
                                    handleMonitoring(monitor);
                            }}
                        >
                            <i
                                id={`monitor-${ind}`}
                                className={
                                    monitor.icon +
                                    ' fs-20  ' +
                                    (monitor.status
                                        ? deviceData.deviceStatusApplied?.toLowerCase() === monitor.status
                                            ? monitor.enable
                                                ? disableActions?.[monitor.enable]
                                                    ? 'link-info action-hover'
                                                    : 'text-muted'
                                                : 'link-info action-hover'
                                            : 'text-muted'
                                        : monitor.enable
                                        ? disableActions?.[monitor.enable]
                                            ? 'link-info action-hover'
                                            : 'text-muted'
                                        : 'link-info action-hover')
                                }
                            />
                            {Tooltip(
                                `monitor-${ind}`,
                                monitor.enable ? (disableActions?.[monitor.enable] ? monitor.label : monitor.disableTooltip) : monitor.label
                            )}
                        </div>
                    )
                );
            })}
        </div>
    );
};

export const handleGeoViolationHistoryFunc = (params) => {
    return (
        <div className="accordion-item border-0 mt-4" key={params.ind}>
            <div className="accordion-header" id="headingOne" onClick={() => params.togglecol(`col${params.ind}`)}>
                <Link
                    to="#"
                    className={classnames('accordion-button', 'p-2', 'shadow-none', {
                        collapsed: params.col[`col${params.ind}`]
                    })}
                >
                    <div className="d-flex align-items-center w-100">
                        <div className="flex-shrink-0 avatar-xxs">
                            <div
                                className={
                                    'avatar-title rounded-circle ' +
                                    (params.location.status
                                        ? params.location.status === 'OUT'
                                            ? 'bg-danger'
                                            : 'bg-success'
                                        : 'bg-primary')
                                }
                            >
                                <i className="ri-map-pin-line"></i>
                            </div>
                        </div>
                        <div className="flex-grow-1 ms-3">
                            <Row className="fs-13 mb-0 fw-semibold">
                                <Col sm={8} className="text-truncate" id={`location-${params.ind}`}>
                                    {'Latitude: ' + params.location.latitude + ' , Longitude: ' + params.location.longitude}
                                </Col>
                                {Tooltip(
                                    `location-${params.ind}`,
                                    'Latitude: ' + params.location.latitude + ' , Longitude: ' + params.location.longitude
                                )}
                                {params.location.date && (
                                    <Col sm={4} className="text-truncate text-end">
                                        <span className="fw-normal text-end" id={`date-${params.ind}`}>
                                            {params.location.date}
                                        </span>
                                        {Tooltip(`date-${params.ind}`, params.location.date)}
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </div>
                </Link>
            </div>
            <Collapse id="collapseOne" className="accordion-collapse" isOpen={!params.col[`col${params.ind}`]}>
                <div className="accordion-body ms-10 ps-1 pt-0">
                    <h6 className="mb-2">{params.location.address ? params.location.address : ''}</h6>
                </div>
            </Collapse>
        </div>
    );
};

export const handleLocationsHistoryFunc = ({ togglecol, col, location, ind }) => {
    return (
        <div className="accordion-item border-0" key={ind}>
            <div className="accordion-header" id="headingOne" onClick={() => togglecol(`col${ind}`)}>
                <Link
                    to="#"
                    className={classnames('accordion-button', 'p-2', 'shadow-none', {
                        collapsed: col[`col${ind}`]
                    })}
                >
                    <div className="d-flex align-items-center w-100">
                        <div className="flex-shrink-0 avatar-xxs">
                            <div className={'avatar-title rounded-circle bg-primary'}>
                                <i className="ri-map-pin-line"></i>
                            </div>
                        </div>
                        <div className="flex-grow-1 ms-3">
                            <Row className="fs-13 mb-0 fw-semibold">
                                <Col sm={6} className="text-truncate" id={`location-${ind}`}>
                                    {location.subLocality
                                        ? location.subLocality + (location.locality ? ', ' + location.locality : '')
                                        : location.locality
                                        ? location.locality
                                        : location.premises
                                        ? location.premisis
                                        : ''}
                                </Col>
                                {(location.subLocality
                                    ? location.subLocality + (location.locality ? ', ' + location.locality : '')
                                    : location.locality
                                    ? location.locality
                                    : location.premises
                                    ? location.premisis
                                    : ''
                                )?.length > 20 &&
                                    Tooltip(
                                        `location-${ind}`,
                                        location.subLocality
                                            ? location.subLocality + (location.locality ? ', ' + location.locality : '')
                                            : location.locality
                                            ? location.locality
                                            : location.premises
                                            ? location.premisis
                                            : ''
                                    )}
                                {location.date && (
                                    <Col sm={6} className="text-truncate text-end">
                                        <span className="fw-normal text-end" id={`date-${ind}`}>
                                            {location.dateListed}
                                        </span>
                                        {Tooltip(`date-${ind}`, location.dateListed)}
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </div>
                </Link>
            </div>
            <Collapse id="collapseOne" className="accordion-collapse" isOpen={!col[`col${ind}`]}>
                <div className="accordion-body ms-10 ps-1 pt-0">
                    <h6 className="mb-2">{location.addressLine ? location.addressLine : location.premises}</h6>
                </div>
            </Collapse>
        </div>
    );
};
export const handleAppsUsageFunc = ({ resp, setTotalAppDetails, setAppUsage, setLoading, setSidePanelLoader }) => {
    let totalTime = JSON.parse(JSON.stringify(Number(resp?.data?.total_screen_time ? resp?.data?.total_screen_time : 0) * 1000));
    let totalDetails = {
        mobileData: convertBytesTo(JSON.parse(JSON.stringify(resp?.data?.total_mobile_data ? resp?.data?.total_mobile_data : 0))),
        data: convertBytesTo(JSON.parse(JSON.stringify(resp?.data?.total_data_usage ? resp?.data?.total_data_usage : 0))),
        wifiData: convertBytesTo(JSON.parse(JSON.stringify(resp?.data?.total_wifi_data ? resp?.data?.total_wifi_data : 0)))
    };
    let appUsageArr = [];
    delete resp.data.environment;
    delete resp.data.total_mobile_data;
    delete resp.data.total_data_usage;
    delete resp.data.total_screen_time;
    delete resp.data.total_wifi_data;
    delete resp.data.id;
    delete resp.data.tenant;
    delete resp.data.source;
    delete resp.data.total_ethernet_data;
    Object.entries(resp.data).forEach((data) => {
        let { days, hours, minutes, seconds } = convertSecondsTo(JSON.parse(JSON.stringify(Number(data[1].screenTime) * 1000)));
        appUsageArr.push({
            packageName: data[0],
            title: data[1]?.appName && data[1]?.appName !== '' && data[1]?.appName !== '⁣' ? data[1]?.appName : data[0],
            timeInSeconds: Number(data[1]?.screenTime),
            spentTime: formatDate(days, hours, minutes, seconds),
            datUsage: convertBytesTo(data[1]?.totalData),
            wifiData: convertBytesTo(data[1]?.wifiData),
            mobileData: convertBytesTo(data[1]?.mobileData)
        });
    });
    let { days, hours, minutes, seconds } = convertSecondsTo(totalTime);
    totalDetails.apps = appUsageArr.length;
    totalDetails.time = formatDate(days, hours, minutes, seconds);
    setTotalAppDetails(totalDetails);
    appUsageArr = sortByAsc(appUsageArr, 'timeInSeconds');
    setAppUsage(appUsageArr);
    setLoading(false);
    setSidePanelLoader(false);
};
