import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Alert, Button, Col, Form, FormFeedback, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { CustomDPCDeviceActions, commonActionsService } from '../../../Components/Common/DeviceUtils.js';
import Loader from '../../../Components/Common/Loader';
import { AuthUser, IsAuthorized, convertTextCase, diffTwoDatesHrsMintsSeconds, getDate } from '../../../Components/Common/Util';
import factoryReset from '../../../assets/images/common/png/common/factoryReset.png';
import lockDevice from '../../../assets/images/common/png/common/lockDevice.png';
import rebootDevice from '../../../assets/images/common/png/common/rebootDevice.png';
import Scream from '../../../assets/images/common/png/common/scream.png';
import toastMessages from '../../../common/messages/toastMessages';
import * as url from '../../../helpers/url_helper';
import { devicesSchema } from './TableSchema';

import {
    togglePassword,
    togglePasswordChange,
    togglePasswordChangeVal,
    tooglePasswordStrengthIn,
    tooglePasswordStrengthOut
} from '../../../Components/Hooks/UserHooks';
import { priviliges } from '../../../Components/constants/constants';

export const deviceStatusArray = [
    { label: 'New', value: 'New', count: 0, checked: false },
    { label: 'Active', value: 'Active', count: 0, checked: false },
    { label: 'Provisioning', value: 'Provisioning', count: 0, checked: false },
    { label: 'Disabled', value: 'Disabled', count: 0, checked: false },
    { label: 'Deleting', value: 'Deleting', count: 0, checked: false },
    { label: 'Deleted', value: 'Deleted', count: 0, checked: false },
    { label: 'Retire', value: 'RETIRE', count: 0, checked: false }
];

export const onlineStatusArray = [
    { label: 'NA', value: '', count: 0, checked: false },
    { label: 'Online', value: 'ONLINE', count: 0, checked: false },
    { label: 'Away', value: 'AWAY', count: 0, checked: false },
    { label: 'Offline', value: 'OFFLINE', count: 0, checked: false }
];

export const violationStatusArray = [
    { label: 'Unauthorised Apps', value: 'apps', count: 0, checked: false },
    { label: 'Apps not Installed', value: 'not_installed', count: 0, checked: false },
    { label: 'Apps not Latest', value: 'apps_not_latest', count: 0, checked: false },
    { label: 'Data Usage', value: 'data_usage', count: 0, checked: false },
    { label: 'Policy Version not Latest', value: 'policy_not_latest', count: 0, checked: false },
    { label: 'Settings not Latest', value: 'setting_not_latest', count: 0, checked: false }
];

export const generateFile = (resp) => {
    let blob = new Blob([resp], { type: 'application/octet-stream' });
    var fileURL = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = fileURL;
    a.download = 'Devices.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const messageFunc = (obj) => {
    return obj.type === 'lock' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to lock Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={lockDevice} alt="device" width={60} height={60} />
                </div>
                <div>
                    Serial: <span className="fw-semibold">{obj.selectedDevice.original?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    ) : obj.type === 'reboot' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to reboot Device?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={rebootDevice} alt="device" width={60} height={60} />
                </div>
                <div>
                    Serial: <span className="fw-semibold">{obj.selectedDevice.original?.serial}</span>
                </div>
            </div>
        </React.Fragment>
    ) : obj.type === 'LOST_MODE' ? (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4">
                    Are you sure you want to {obj.lostModeParam === 'STOP' ? 'disable' : 'enable'} lost mode in Device?
                </div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <div
                        className={`p-1 d-flex align-items-center border border-1 fs-10 border-info rounded-3 ${
                            obj.lostModeParam === 'START' ? '' : 'mb-5'
                        }`}
                    >
                        <div
                            className={`${
                                obj.lostModeParam === 'START' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-4 py-2 cursor-pointer`}
                            onClick={() => {
                                obj.setLostModeParam('START');
                                obj.setActionTextFunc('START');
                            }}
                        >
                            START
                        </div>
                        <div
                            className={`${
                                obj.lostModeParam === 'STOP' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-4 py-2 cursor-pointer`}
                            onClick={() => {
                                obj.setLostModeParam('STOP');
                                obj.setActionTextFunc('STOP');
                            }}
                        >
                            STOP
                        </div>
                    </div>
                </div>
                {obj.lostModeParam === 'START' && (
                    <Input
                        type="text"
                        id="lostModePhoneNumber"
                        name="lostModePhoneNumber"
                        className="form-control mt-2 mb-2"
                        onChange={(e) => obj.setLostModePhoneNumber(e.target.value)}
                        value={obj.lostModePhoneNumber}
                        maxLength={10}
                        placeholder="Lost Mode Phone Number"
                    />
                )}
                {obj.lostModeParam === 'START' && (
                    <Input
                        type="text"
                        id="lostModeEmail"
                        name="lostModeEmail"
                        className="form-control mt-2 mb-2"
                        onChange={(e) => obj.setLostModeEmail(e.target.value)}
                        value={obj.lostModeEmail}
                        maxLength={225}
                        placeholder="Lost Mode Email"
                    />
                )}
                {obj.lostModeParam === 'START' && (
                    <Input
                        type="textarea"
                        id="lostModeMessage"
                        name="lostModeMessage"
                        className="form-control mt-2 mb-2"
                        onChange={(e) => obj.setLostModeMessage(e.target.value)}
                        value={obj.lostModeMessage}
                        maxLength={225}
                        placeholder="Lost Mode Message"
                    />
                )}
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div className="mt-3">
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={Scream} alt="device" width={60} height={60} />
                </div>
                <div className="mb-4">Are you sure you want to {obj.actionParam === 'START' ? 'activate' : 'de-activate'} scream?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <div className="p-1 d-flex align-items-center border border-1 fs-10 border-info rounded-3 mb-5">
                        <div
                            className={`${
                                obj.actionParam === 'STOP' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-4 py-2 cursor-pointer`}
                            onClick={() => obj.setActionParam('STOP')}
                        >
                            STOP
                        </div>
                        <div
                            className={`${
                                obj.actionParam === 'START' ? 'bg-info text-light' : 'bg-light text-dark'
                            } w-50 px-4 py-2 cursor-pointer`}
                            onClick={() => obj.setActionParam('START')}
                        >
                            START
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export const handleDeleteBody = (hideLordicon, obj, selectedRow) => {
    return (
        <div className="mt-2 text-center p-4">
            {!hideLordicon && (
                <div className="d-flex align-items-center justify-content-center">
                    <img src={factoryReset} alt="device" width={90} height={90} />
                </div>
            )}

            <div className="mt-3 d-flex align-items-center justify-content-center">
                <Label className="form-check-label d-flex align-items-center">
                    <input
                        className="form-check-input me-3"
                        type="checkbox"
                        value={obj.wipeDevice}
                        id="Factory Reset"
                        checked={true}
                        onChange={(e) => obj.setWipeDevice(e.target.checked)}
                    />
                    Factory Reset
                    <i className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15" id="factory-reset" />
                    <UncontrolledTooltip placement="bottom" target="factory-reset">
                        This device - {obj.selectedRow?.original?.serial} has been remotely wiped for security reasons. All data on the
                        device has been erased.
                    </UncontrolledTooltip>
                </Label>
            </div>
            {selectedRow?.original?.androidVersion && Number(selectedRow?.original?.androidVersion) >= 7 && (
                <>
                    <div className="mt-3 d-flex align-items-center justify-content-center">
                        <input
                            className="form-check-input me-3"
                            type="checkbox"
                            value={obj.wipeExternalStorage}
                            id="Wipe External Storage"
                            onChange={(e) => obj.setWipeExternalStorage(e.target.checked)}
                        />
                        <Label className="form-check-label d-flex align-items-center">
                            Wipe External Storage
                            <i className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15" id="wipe-external-storage" />
                            <UncontrolledTooltip placement="bottom" target="wipe-external-storage">
                                External storage of your device - {obj.selectedRow?.original?.serial} is being remotely wiped for security
                                and data protection purposes. All data stored on the external storage, including media files and documents,
                                will be permanently erased.
                            </UncontrolledTooltip>
                        </Label>
                    </div>
                    <div className="mt-3 d-flex align-items-center justify-content-center">
                        <input
                            className="form-check-input me-3"
                            type="checkbox"
                            value={obj.wipeReasonMessage}
                            id="Wipe External Storage"
                            onChange={(e) => obj.setWipeReasonMessage(e.target.checked)}
                        />
                        <Label className="form-check-label d-flex align-items-center fw-medium">
                            Wipe Reason Message
                            <i className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15" id="wipe-reason-message" />
                            <UncontrolledTooltip placement="bottom" target="wipe-reason-message">
                                Your device -{obj.selectedRow?.original?.serial} has been remotely wiped for security reasons. To ensure the
                                protection of sensitive data, the device has been reset to its default settings.
                            </UncontrolledTooltip>
                        </Label>
                    </div>
                    {obj.wipeReasonMessage && (
                        <Input
                            type="textarea"
                            id="wipeReasonMessage"
                            name="wipeReasonMessage"
                            className="form-control mt-2"
                            onChange={(e) => obj.setWipeReasonMessageValue(e.target.value)}
                            value={obj.wipeReasonMessageValue}
                            placeholder="Wipe Reason Message"
                        />
                    )}
                </>
            )}
        </div>
    );
};

export const handleIsExport = (obj, api, tenant) => {
    if (Number(obj.totalRecords) > Number(tenant.MAX_ANDROID_DEVICE)) {
        toast.error(toastMessages.filterDevicesMaxCount);
        obj.setLoading(false);
    } else {
        obj.setLoading(true);
        let selSerails = obj.selectedDevices?.map((device) => device.serial)?.join(' ');
        let params = {
            ...obj.searchParams,
            select: { page: 1, size: Number(tenant.MAX_ANDROID_DEVICE) },
            filter: {
                search: (obj.searchParams?.filter?.search ? obj.searchParams?.filter?.search + ' ' : '') + selSerails,
                dateFrom: obj.searchParams.filter?.dateFrom ? obj.searchParams.filter?.dateFrom : undefined,
                dateTo: obj.searchParams.filter?.dateTo ? obj.searchParams.filter?.dateTo : undefined,
                policy: obj.searchParams.filter?.policy ? obj.searchParams.filter?.policy : undefined,
                group: obj.searchParams.filter?.group ? obj.searchParams.filter?.group : undefined,
                deviceStatus: obj.searchParams.filter?.deviceStatus ? obj.searchParams.filter?.deviceStatus : undefined,
                onlineStatus: obj.searchParams.filter?.onlineStatus ? obj.searchParams.filter?.onlineStatus : undefined
            }
        };
        api.create(url.DEVICES_EXPORT, params)
            .then((resp) => {
                generateFile(resp);
                obj.setLoading(false);
            })
            .catch((_err) => {
                obj.setLoading(false);
            });
    }
};

export const handleNotesModalBody = (validation1) => {
    return (
        <Col>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation1.handleSubmit();
                    return false;
                }}
            >
                <div className="input-group">
                    <IsAuthorized
                        privRequired={priviliges.DEVICE_EDITOR}
                        yes={() => (
                            <div className="w-100">
                                <Input
                                    type="textarea"
                                    id="notes"
                                    name="notes"
                                    maxLength={255}
                                    className={
                                        'form-control ' +
                                        (validation1?.values?.notes && validation1?.values?.notes?.length === 255
                                            ? 'border border-danger border-1'
                                            : '')
                                    }
                                    placeholder="Enter Notes"
                                    validate={{ required: { value: true } }}
                                    onChange={validation1.handleChange}
                                    onBlur={validation1.handleBlur}
                                    value={validation1?.values['notes'] || ''}
                                    // invalid={validation1?.touched['notes'] && validation1?.errors['notes'] ? true : false}
                                />
                                <div className="d-flex align-items-center justify-content-end text-muted mt-1">
                                    {validation1?.values?.notes ? validation1?.values?.notes?.length : 0}/255
                                </div>
                            </div>
                        )}
                        no={() => {
                            <span>{validation1?.values['notes'] ? validation1?.values['notes'] : '–'}</span>;
                        }}
                    />
                </div>
                <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                    It may take some time for it to reflect the updated notes.
                </Alert>
            </Form>
        </Col>
    );
};

export const handleApplicationModalBody = (apps, value, handleOnSelectApp) => {
    return (
        <Row>
            <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                <Label className="form-label d-flex align-items-center fw-medium">
                    Select Applications <span className="red-color ps-1">*</span>
                </Label>
            </Col>
            <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                <Select
                    getOptionValue={(option) => option.packageName}
                    getOptionLabel={(option) => option.title}
                    id={'app'}
                    name="value"
                    classNamePrefix={'custom-select w-100'}
                    options={apps}
                    placeholder={'Select Applications'}
                    onChange={(selectedOption) => handleOnSelectApp(selectedOption)}
                    value={value}
                    isSearchable={true}
                    noOptionsMessage={() => 'No data found'}
                />
            </Col>
        </Row>
    );
};

export const handlePGChangeBody = (options, key, label, bulkAction) => {
    return (
        <Row>
            <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                <Label className="form-label d-flex align-items-center fw-medium">
                    Change To <span className="red-color ps-1">*</span>
                </Label>
            </Col>
            <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                <Select
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option[label]}
                    id={label}
                    name="value"
                    classNamePrefix={'custom-select w-100'}
                    options={options}
                    placeholder={`Select ${key}`}
                    onBlur={() => bulkAction.handleBlur({ target: { name: key?.toLowerCase() } })}
                    onChange={(selectedOption) => bulkAction.handleChange({ target: { name: key?.toLowerCase(), value: selectedOption } })}
                    value={bulkAction.values?.[key?.toLowerCase()] || ''}
                    isSearchable={true}
                    noOptionsMessage={() => 'No data found'}
                />
            </Col>
        </Row>
    );
};
export const handleDevices = (result, obj) => {
    if (result.records?.length > 0) {
        obj.setTotalRecords(result?.totalCount);
        let widgetCount = {};
        if (result?.totalCount) {
            widgetCount.totalDevicesCount = result?.totalCount;
        } else widgetCount.totalCount = 0;
        if (result?.aggs?.deviceStatusCount?.NEW) {
            widgetCount.deviceNewCount = result?.aggs?.deviceStatusCount?.NEW;
        } else {
            widgetCount.deviceNewCount = 0;
        }
        if (result?.aggs?.deviceStatusCount?.ACTIVE) {
            widgetCount.deviceActiveCount = result?.aggs?.deviceStatusCount?.ACTIVE;
        } else {
            widgetCount.deviceActiveCount = 0;
        }
        if (result?.aggs?.deviceStatusCount?.PROVISIONING) {
            widgetCount.deviceProvisioningCount = result?.aggs?.deviceStatusCount?.PROVISIONING;
        } else widgetCount.deviceProvisioningCount = 0;
        if (result?.aggs?.deviceStatusCount?.DISABLED) {
            widgetCount.deviceDisabledCount = result?.aggs?.deviceStatusCount?.DISABLED;
        } else widgetCount.deviceDisabledCount = 0;
        if (result?.aggs?.deviceStatusCount?.DELETED || result?.aggs?.deviceStatusCount?.DELETING) {
            let deletingCount = result?.aggs?.deviceStatusCount?.DELETING || 0;
            let deletedCount = result?.aggs?.deviceStatusCount?.DELETED || 0;
            widgetCount.deviceDeletedCount = deletedCount + deletingCount;
            widgetCount.deletedOnlyCount = deletedCount;
        } else {
            widgetCount.deviceDeletedCount = 0;
            widgetCount.deletedOnlyCount = 0;
        }
        if (result?.aggs?.deviceStatusCount?.DELETING) {
            widgetCount.deletingCount = result?.aggs?.deviceStatusCount?.DELETING || 0;
        } else widgetCount.deletingCount = 0;
        obj.setWidgetCount({
            ...widgetCount
        });
        result?.records?.forEach((data) => {
            if (data.deviceStatusApplied?.toLowerCase() === 'active') data.badgeClass = 'success';
            else if (data.deviceStatusApplied?.toLowerCase() === 'disabled') data.badgeClass = 'warning';
            else if (data.deviceStatusApplied?.toLowerCase() === 'retire') data.badgeClass = 'danger';
            else if (data.deviceStatusApplied?.toLowerCase() === 'deleted' || data.deviceStatusApplied?.toLowerCase() === 'deleting')
                data.badgeClass = 'darkBrown';
            else data.badgeClass = 'primary';
            data.noAction =
                data.policyApplied !== '' && data.policyConfigured !== '' && data.policyApplied !== data.policyConfigured ? true : false;
            data.sequence = data.deviceCode ? data.deviceCode : '—';
            data.noActionOnVersion =
                data.policyConfigured === ''
                    ? data.policyVersionApplied &&
                      data.policyVersionApplied !== '' &&
                      data.policyVersionConfigured &&
                      data.policyVersionConfigured !== '' &&
                      data.policyVersionApplied !== data.policyVersionConfigured
                        ? true
                        : false
                    : false;
            data.username = data?.username || '—';
            data.custom1 = data.custom1 || '—';
            data.noLink = data.policyApplied !== '' ? false : true;
            data.policyApplied = data.policyApplied ? data.policyApplied : '—';
            data.policyAppliedName = data.policyAppliedName ? data.policyAppliedName : '—';
            data.policyConfigured = data.policyConfigured ? data.policyConfigured : '—';
            data.policyVersionApplied = data.policyVersionApplied ? data.policyVersionApplied : '—';
            data.policyVersionConfigured = data.policyVersionConfigured ? data.policyVersionConfigured : '—';
            data.androidVersion = data.androidVersion !== '' ? data.androidVersion : '—';
            data.disableResetPassword = Number(data.androidVersion) < 7;
            data.disableScream = Number(data.androidVersion) < 7;
            data.disableReboot = Number(data.androidVersion) < 7;
            data.manufacturer = data.manufacturer !== '' ? convertTextCase(data.manufacturer, true) : '—';
            data.IMEI = data.imei?.length > 0 && data.imei?.[0] !== '' && data.imei?.[0] !== null ? data.imei?.join(', ') : '—';
            data.policyVersionApplied = data.policyVersionApplied ? data.policyVersionApplied : '—';
            data.group = data.group ? data.group : '—';
            data.deviceUser = data.deviceUser ? data.deviceUser : '—';
            data.batteryPercentage =
                data.deviceStatusApplied?.toLowerCase() !== 'new' ? (data.BATTERY_PCT ? data.BATTERY_PCT + '%' : '—') : '—';
            if (data.BATTERY_PCT <= 25) data.iconBadgeClass = 'danger';
            else if (data.BATTERY_PCT > 25 && data.BATTERY_PCT <= 50) data.iconBadgeClass = 'warning';
            else data.iconBadgeClass = 'green-1';
            data.violationDate = data.violationDate ? getDate(data.violationDate) : '—';
            data.violations = {
                sim: data.SIM_VIOLATION,
                memory: data.MEMORYCARD_VIOLATION,
                message: data.MESSAGE_VIOLATION,
                wifi: data.WIFI_VIOLATION,
                geofence: data.GEO_VIOLATION
            };
            data.enrollmentDate = data.deviceStatusApplied?.toLowerCase() !== 'new' ? getDate(data.enrollmentDate) : '—';
            data.enrollmentStatusClass = data.REGISTRATION_STATUS?.toLowerCase() === 'registered' ? 'green-1' : 'danger';
            let { hours, minutes, days, weeks, totalMinutes } = diffTwoDatesHrsMintsSeconds(data.ONLINE_DATE);
            var hDisplay = Number(hours) > 0 ? hours + (Number(hours) === 1 ? ' hour' : ' hours') : '';
            var mDisplay =
                Number(hours) === 0 && Number(minutes) === 0
                    ? 'Now'
                    : (Number(hours) === 0 ? '' : ', ') + minutes + (Number(minutes) === 1 ? ' minute ago' : ' minutes ago');
            var dDisplay = days + (Number(days) === 1 ? ' day ago' : ' days ago');
            let wDisplay = weeks + (Number(weeks) === 1 ? ' week ago' : ' weeks ago');
            if (data.ONLINE_STATUS?.toLowerCase() === 'online') data.onlineStatusClass = 'green-1';
            else if (data.ONLINE_STATUS?.toLowerCase() === 'away') data.onlineStatusClass = 'warning';
            else if (data.ONLINE_STATUS?.toLowerCase() === 'offline') data.onlineStatusClass = 'danger';
            if (!data.ONLINE_DATE || !data.ONLINE_STATUS || data.ONLINE_STATUS === '') data.onlineStatusClass = 'dark-gray';
            data.enrollmentStatusTooltip =
                'Registration Status: ' +
                (data.REGISTRATION_STATUS
                    ? convertTextCase(data.REGISTRATION_STATUS, true) + ': ' + getDate(data.enrollmentDate)
                    : 'Not Registered Yet');
            data.onlineStatusTooltip =
                'Last reported: ' +
                (data.ONLINE_DATE && data.ONLINE_STATUS
                    ? convertTextCase(data.ONLINE_STATUS, true) + ': ' + (weeks > 0 ? wDisplay : days > 0 ? dDisplay : hDisplay + mDisplay)
                    : 'Not Seen Yet');
            data.hideMapView = data.deviceStatusApplied?.toLowerCase() !== 'active';
            data.batteryReportedTooltip =
                'Last Reported: ' +
                (data.ONLINE_DATE ? (weeks > 0 ? wDisplay : days > 0 ? dDisplay : hDisplay + mDisplay) : 'Not Reported Yet');
            data.showActions = data.deviceStatusApplied?.toLowerCase() === 'active';
            data.rowView = data.deviceStatusApplied?.toLowerCase() !== 'new';
            data.hideLostMode =
                (data.policyType === 'FM' && data.androidVersion < 11) ||
                (data.policyType === 'WP' && data.androidVersion < 13) ||
                (data.policyType === 'DD' && data.androidVersion < 13) ||
                (data.policyType === 'SA' && data.androidVersion < 13) ||
                (data.policyType === 'KL' && data.androidVersion < 13) ||
                (data.policyType === 'TL' && data.androidVersion < 13) ||
                data.deviceStatusApplied?.toLowerCase() === 'deleted' ||
                data.deviceStatusApplied?.toLowerCase() === 'deleting' ||
                data.deviceStatusApplied?.toLowerCase() === 'retire' ||
                data.deviceStatusApplied?.toLowerCase() === 'new' ||
                data.deviceStatusApplied?.toLowerCase() === 'provisioning';

            data.hideFactoryReset =
                (window.location.hostname === 'apse.emmprod1.tectoro.com'
                    ? data.policyApplied === 'P026' ||
                      data.policyApplied === 'P002' ||
                      data.policyApplied === 'P023' ||
                      data.policyApplied === 'P021' ||
                      data.policyApplied === 'P023' ||
                      data.policyApplied === 'APSE002HRB'
                    : window.location.hostname === 'portal.emmprod1.tectoro.com'
                    ? data.policyApplied === 'P047' || data.policyApplied === 'P038' || data.policyApplied === 'P003'
                    : false) ||
                data.deviceStatusApplied?.toLowerCase() === 'deleted' ||
                data.deviceStatusApplied?.toLowerCase() === 'deleting' ||
                data.deviceStatusApplied?.toLowerCase() === 'retire' ||
                data.deviceStatusApplied?.toLowerCase() === 'new';
        });
        obj.setSelectedDevices([]);
        let unSelectedDevices = result.records.filter((device) => !device.checked);
        if (unSelectedDevices?.length > 0) obj.setCheckAll(false);
        else obj.setCheckAll(true);
        // eslint-disable-next-line no-unsafe-optional-chaining
        obj.setDevices([...result?.records]);
    } else {
        let widgetCount = {};
        widgetCount.deviceNewCount = 0;
        widgetCount.deviceActiveCount = 0;
        widgetCount.deviceProvisioningCount = 0;
        widgetCount.deviceDisabledCount = 0;
        widgetCount.deviceDeletedCount = 0;
        widgetCount.deletedOnlyCount = 0;
        widgetCount.deletingCount = 0;
        obj.setWidgetCount({ ...widgetCount });
        obj.setDevices([]);
        obj.setTotalRecords(0);
        obj.setCheckAll(false);
        obj.setSelectedDevices([]);
    }
};

export const handleModalBody = (hideStyles, obj) => {
    return (
        <Row className={'p-4 justify-content-center ' + (obj.resetLoader ? 'mask' : '')}>
            {obj.resetLoader && <Loader />}
            {!hideStyles && (
                <>
                    <div className="text-center mt-2">
                        <h5 className="text-primary">Reset Password?</h5>
                        <p className="text-muted mb-1">
                            Reset your password with <b>{obj.tenantConfig?.COMPANY_DEVELOPED_BY}</b>
                        </p>
                        <i className="ri-lock-password-line font-50 text-success" color="primary"></i>
                    </div>
                    <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                        Please set as new password for the device - {obj.selectedRow ? obj.selectedRow?.original?.serial : obj.recordID}
                    </Alert>
                </>
            )}
            <Col className="d-flex align-items-center justify-content-center mt-2">
                <div className="form-check form-check-inline">
                    <Input
                        type="radio"
                        value={'true'}
                        id={'default_password'}
                        name={'default_password'}
                        className="form-check-input"
                        onBlur={obj.validation.handleBlur}
                        onChange={obj.validation.handleChange}
                        checked={obj.validation.values['default_password'] === 'true'}
                    />
                    <Label className="form-check-label">{'Default Password'}</Label>
                </div>
                <div className="form-check form-check-inline">
                    <Input
                        type="radio"
                        value={'false'}
                        id={'default_password'}
                        name={'default_password'}
                        className="form-check-input"
                        onBlur={obj.validation.handleBlur}
                        onChange={obj.validation.handleChange}
                        checked={obj.validation.values['default_password'] === 'false'}
                    />
                    <Label className="form-check-label">{'Custom Password'}</Label>
                </div>
            </Col>
            <div className="p-2">
                <Form>
                    {obj.validation.values['default_password'] === 'true' && (
                        <>
                            <div className="d-flex align-items-center justify-content-center mt-4">
                                <Label className="form-label">Default Password :</Label>
                                <Alert className="alert-borderless alert-info text-center mb-2 mx-2" role="alert">
                                    {obj.tenantConfig?.DEVICE_DEFAULT_PASSWORD}
                                </Alert>
                            </div>
                            {!hideStyles && (
                                <div className="text-center mt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        onClick={(e) => obj.handleSubmit({ newPassword: obj.tenantConfig?.DEVICE_DEFAULT_PASSWORD })}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    {obj.validation.values['default_password'] === 'false' && (
                        <>
                            <div className="mb-4">
                                <Label className="form-label">New Password</Label>
                                <div className="position-relative auth-pass-inputgroup">
                                    <Input
                                        onFocus={() => tooglePasswordStrengthIn('password-contain')}
                                        onBlur={(e) => {
                                            obj.validation.handleBlur(e);
                                            togglePasswordChangeVal(obj.validation.values.newPassword) &&
                                                tooglePasswordStrengthOut('password-contain');
                                        }}
                                        name="newPassword"
                                        type="password"
                                        autoComplete="off"
                                        className="form-control pe-5 password-input"
                                        onPaste={() => false}
                                        placeholder="Enter new password"
                                        id="password-input"
                                        aria-describedby="passwordInput"
                                        maxLength={15}
                                        required
                                        onChange={(e) => {
                                            obj.validation.handleChange(e);
                                            togglePasswordChange(e.target.value);
                                        }}
                                        value={obj.validation.values.newPassword || ''}
                                        invalid={obj.validation.touched.newPassword && obj.validation.errors.newPassword ? true : false}
                                    />
                                    {obj.validation.touched.newPassword && obj.validation.errors.newPassword ? (
                                        <FormFeedback type="invalid">
                                            <div>{obj.validation.errors.newPassword}</div>
                                        </FormFeedback>
                                    ) : null}
                                    {obj.validation.values.newPassword && obj.validation.values.newPassword !== '' && (
                                        <Button
                                            color="link"
                                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                            type="button"
                                            onClick={() => togglePassword('password-input', 'password-icon1')}
                                        >
                                            <i id="password-icon1" className="ri-eye-off-fill align-middle"></i>
                                        </Button>
                                    )}
                                </div>
                                <div id="passwordInput" className="form-text">
                                    Must be at least 8 characters.
                                </div>
                            </div>
                            <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                <h5 className="fs-13">Password must contain:</h5>
                                <p id="pass-length" className="invalid fs-12 mb-2">
                                    Minimum <b>8 characters</b>
                                </p>
                                <p id="pass-max-length" className="invalid fs-12 mb-2">
                                    Maximum <b>12 characters</b>
                                </p>
                                <p id="pass-lower" className="invalid fs-12 mb-2">
                                    At least 1 <b>lowercase</b> letter (a-z)
                                </p>
                                <p id="pass-upper" className="invalid fs-12 mb-2">
                                    At least 1 <b>uppercase</b> letter (A-Z)
                                </p>
                                <p id="pass-number" className="invalid fs-12 mb-2">
                                    At least 1 <b>number</b> (0-9)
                                </p>
                                <p id="pass-special" className="invalid fs-12 mb-0">
                                    At least 1 <b>Special</b> Symbol
                                </p>
                            </div>
                            {!hideStyles && (
                                <div className="text-center mt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        onClick={(e) => obj.validation.handleSubmit()}
                                        disabled={!togglePasswordChangeVal(obj.validation.values.newPassword)}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </Form>
            </div>
        </Row>
    );
};
export const setParentArr = (parent, obj) => {
    if (parent.parent) {
        obj.path.push(parent.parent.name);
        obj.breadcrumbItems.push(parent.parent);
        if (parent.parent.parent) {
            let parentObj = setParentArr(parent.parent, obj);
            obj.path = parentObj.path;
            obj.breadcrumbItems = parentObj.breadcrumbItems;
        }
    }
    let returnObj = { path: obj.path, breadcrumbItems: obj.breadcrumbItems };
    return returnObj;
};
export const handleDeviceGroupFilterResult = (resp, setSearchResults) => {
    if (resp.status === 'success') {
        let results = [];
        resp.data.forEach((data) => {
            let obj = JSON.parse(JSON.stringify(data));
            obj.path = [];
            obj.breadcrumbItems = [];
            if (data.parent) {
                obj.path.push(data.parent.name);
                obj.breadcrumbItems.push(data.parent);
                if (data.parent.parent) {
                    let parentObj = setParentArr(data.parent, obj);
                    obj.path = parentObj.path;
                    obj.breadcrumbItems = parentObj.breadcrumbItems;
                }
            }
            obj.breadcrumbItems.reverse();
            obj.path.reverse();
            obj.breadcrumbItems.unshift({ name: 'Main Group', icon: 'ri-home-8-fill' });
            obj.path.unshift('Main Group');
            results.push(obj);
        });
        setSearchResults(results);
    }
};
export const handleBulkModalBodyPart = (bulkAction, actionTypes, handleActionChange) => {
    return (
        <>
            <Row>
                <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                    <Label className="form-label d-flex align-items-center fw-medium">
                        Purpose <span className="red-color ps-1">*</span>
                    </Label>
                </Col>
                <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                    <Input
                        name={'purpose'}
                        type={'text'}
                        maxLength={100}
                        placeholder="Enter Purpose"
                        onChange={bulkAction.handleChange}
                        onBlur={bulkAction.handleBlur}
                        value={bulkAction.values['purpose'] || ''}
                        invalid={bulkAction.touched['purpose'] && bulkAction.errors['purpose'] ? true : false}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                    <Label className="form-label d-flex align-items-center fw-medium">
                        Select Action <span className="red-color ps-1">*</span>
                    </Label>
                </Col>
                <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                    <Select
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.label}
                        id="label"
                        name="value"
                        classNamePrefix={'custom-select w-100'}
                        options={actionTypes}
                        placeholder="Select Action"
                        onBlur={() => bulkAction.handleBlur({ target: { name: 'actionType' } })}
                        onChange={(selectedOption) => handleActionChange(selectedOption)}
                        value={bulkAction.values?.actionType || ''}
                        isSearchable={true}
                        noOptionsMessage={() => 'No data found'}
                    />
                </Col>
            </Row>
        </>
    );
};
export const disableBulkAction = (bulkAction, tenantConfig, validation) => {
    return (
        /*
         * (elasticSearchObj.selectedFiltersKeys &&
         *     elasticSearchObj.selectedFiltersKeys?.length > 0 &&
         *     !bulkAction.values?.filterCriteria?.value) ||
         */
        !bulkAction.values.purpose ||
        bulkAction.values.purpose === '' ||
        !bulkAction.values?.actionType?.value ||
        (bulkAction.values?.actionType?.value === 'RESET_PASSWORD'
            ? validation.values.default_password === 'true'
                ? tenantConfig?.DEVICE_DEFAULT_PASSWORD === ''
                : validation.errors.newPassword || !togglePasswordChangeVal(validation.values.newPassword)
            : false) ||
        (bulkAction.values?.actionType?.value === 'NEWPOLICY' && !bulkAction?.values?.policy?.value) ||
        (bulkAction?.values?.actionType?.value === 'NEWGROUP' && !bulkAction?.values?.group?.code)
    );
};
export const handleActionConfirmation = (obj, api, urlconf) => {
    obj.setConfirmationModal(false);
    if (obj.type === 'scream') {
        obj.setLoading(true);
        let params = { timer: 172800, action: obj.actionParam };
        if (obj.actionParam === 'STOP') delete params.timer;
        api.create(url.DEVICE_ACTIONS + '/' + obj.selectedDevice.original.id, {
            command: 'Scream',
            params: params
        })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.screamRequested);
                    obj.setLoading(false);
                }
                obj.setLoading(false);
            })
            .catch((err) => obj.setLoading(false));
    } else if (obj.type === 'LOST_MODE') {
        obj.setLoading(true);
        let toastMsg;
        let params = {};
        params = { command: obj.lostModeParam === 'START' ? 'START_LOST_MODE' : 'STOP_LOST_MODE' };
        if (obj.lostModeParam === 'START') {
            params = {
                ...params,
                params: { lostPhoneNumber: obj.lostModePhoneNumber, lostEmailAddress: obj.lostModeEmail, lostMessage: obj.lostModeMessage }
            };
            toastMsg = toastMessages.lostModeStart;
        } else {
            params = { ...params, params: {} };
            toastMsg = toastMessages.lostModeStop;
        }
        obj.setLostModePhoneNumber('');
        obj.setLostModeEmail('');
        obj.setLostModeMessage('');
        api.create(url.DEVICE_ACTIONS + '/' + obj.selectedDevice?.original?.id + '/command', params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMsg);
                    obj.setLoading(false);
                }
            })
            .catch((err) => {
                obj.setLoading(false);
            });
    } else {
        if (Number(obj.selectedDevice?.original?.androidVersion) < 7) {
            CustomDPCDeviceActions({ setLoading: obj.setLoading, id: obj.selectedDevice?.original?.id, type: obj.type, urlconf: urlconf });
            return;
        } else
            commonActionsService({
                deviceID: obj.selectedDevice?.original?.id,
                type: obj.type,
                param: { mode: obj.lostModeParam, message: '' },
                setLoading: obj.setLoading,
                setShowModal: obj.setShowModal,
                urlconf: urlconf
            });
    }
    obj.setSelectedDevice('');
    obj.setActionText('');
};

export const selectedTabFunc = ({ tenantConfig, setHideAllFilters, setDevicesTableSchema, tenantDevicesSchema, setCheckedData }) => {
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    let findPriv = !user?.data?.privileges?.includes(priviliges.DEVICE_EDITOR);
    let schema = [...devicesSchema];
    setCheckedData([]);
    setHideAllFilters(
        !tenantConfig?.SHOW_FILTERS?.deviceStatusArr &&
            !tenantConfig?.SHOW_FILTERS?.policiesArr &&
            !tenantConfig?.SHOW_FILTERS?.selectedFiltersKeys &&
            !tenantConfig?.SHOW_FILTERS?.tagListArr &&
            !tenantConfig?.SHOW_FILTERS?.tagListBackup &&
            !tenantConfig?.SHOW_FILTERS?.violationStatusArr &&
            !tenantConfig?.SHOW_FILTERS?.onlineStatusArr
    );
    if (findPriv) schema = devicesSchema.filter((_device, index) => index < devicesSchema.length - 1);
    let schemaArr = [];
    schema.forEach((schemaObj) => {
        if (schemaObj.Header === 'Serial' || schemaObj.Header === 'checkbox') schemaArr.push(schemaObj);
        else if (schemaObj.Header === 'Actions') {
            schemaObj.lock = !!tenantConfig?.DEVICE_ACTIONS?.includes('LOCK');
            schemaObj.scream = !!tenantConfig?.DEVICE_ACTIONS?.includes('SCREAM');
            schemaObj.resetpassword = !!tenantConfig?.DEVICE_ACTIONS?.includes('RESET_PASSWORD');
            schemaObj.reboot = !!tenantConfig?.DEVICE_ACTIONS?.includes('REBOOT');
            schemaObj.factoryreset = !!tenantConfig?.DEVICE_ACTIONS?.includes('FACTORY_RESET');
            schemaObj.lostMode = !!tenantConfig?.DEVICE_ACTIONS?.includes('LOST_MODE');
            schemaObj.map = !!tenantConfig?.DEVICE_ACTIONS?.includes('MAP_VIEW');
            schemaObj.retiree = !!tenantConfig?.DEVICE_ACTIONS?.includes('RETIRE');
            schemaArr.push(schemaObj);
        } else if (tenantDevicesSchema?.includes(schemaObj.Header)) schemaArr.push(schemaObj);
    });
    setDevicesTableSchema(tenantDevicesSchema?.length > 0 ? schemaArr : schema);
};

export const setChildGroups = (groupArr, groupsData) => {
    groupArr.forEach((group) => {
        group.label = group.name;
        group.key = group.code;
        group.nameCode = group.name + ' - ' + group.code;
        groupsData.push(group);
        if (group.children) {
            let obj = setChildGroups(group.children, groupsData);
            group.children = obj.children;
            groupsData = [...obj.groupsData];
        }
    });
    return { children: groupArr, groupsData: groupsData };
};
export const setCheckedGroup = (groupsArr, group) => {
    groupsArr.forEach((groupObj) => {
        if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
        else delete groupObj.checked;
        if (groupObj.children) groupObj.children = setCheckedGroup(groupObj.children, group);
    });
    return groupsArr;
};
export const changeWidgetCountFunc = ({ elasticSearchObj, setElasticSearchObj, WidgetCount }) => {
    let keyValuePair = {
        New: 'deviceNewCount',
        Active: 'deviceActiveCount',
        Provisioning: 'deviceProvisioningCount',
        Disabled: 'deviceDisabledCount',
        Deleting: 'deletingCount',
        Deleted: 'deletedOnlyCount'
    };
    let deviceStatusArr = JSON.parse(JSON.stringify(elasticSearchObj?.deviceStatusArr));
    deviceStatusArr.forEach((status) => {
        status.count = WidgetCount?.[keyValuePair?.[status.label]]
            ? WidgetCount?.[keyValuePair?.[status.label]]
            : elasticSearchObj?.deviceStatusArr?.find((statusObj) => statusObj.label === status.label)?.count;
    });
    setElasticSearchObj({ ...elasticSearchObj, deviceStatusArr: [...deviceStatusArr] });
};
export const prepareArray = (arr, key, value, allowNA) => {
    if (key !== '') {
        arr.forEach((ele) => {
            if (key?.toLowerCase() === ele.label?.toLowerCase()) ele.count = value;
        });
    } else if (allowNA && key === '') {
        arr.forEach((ele) => {
            if (ele.label === 'NA') ele.count = value;
        });
    } else if (arr.filter((ele) => ele.label === 'NA')?.length === 0) {
        arr.push({ label: 'NA', count: value, checked: false });
    }
    return arr;
};

export const dynamicClearAllFunction = (key, elasticSearchObj, setPageNumber, setElasticSearchObj) => {
    setPageNumber(1);
    elasticSearchObj[key]?.forEach((ele) => {
        ele.checked = false;
    });
    setElasticSearchObj({ ...elasticSearchObj, [key]: elasticSearchObj[key], selectedFiltersKeys: [] });
};
