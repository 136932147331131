import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Form, Input, Label, Row, Spinner, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import AnimatedTreeComponent from '../../../Components/Common/AnimatedTreeComponent';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, TenantConfig, convertUTCtoIST, profileTypeLabel } from '../../../Components/Common/Util';
import { AndroidTVPoliciesArr } from '../../../Components/constants/commons';
import { AndroidPolicyQR, AndroidTVPolicyQR, priviliges } from '../../../Components/constants/constants';
import polClone from '../../../assets/images/common/png/common/policyClone.png';
import polDelete from '../../../assets/images/common/png/common/policyDelete.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { tvPoliciesSchema } from '../TableSchema';

const AndroidTVPolicies = () => {
    document.title = 'TV Policy';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const tenantConfig = TenantConfig();
    const [policiesTableSchema, setPoliciesTableSchema] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [policiesBackup, setPoliciesBackup] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showCloneModal, setShowCloneModal] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [totalRecords, setTotalRecords] = useState(0);
    const [qrCodeString, setQRCodeString] = useState('');
    const [policyType, setPolicyType] = useState('');
    const [showPolicyModal, setShowPolicyModal] = useState(false);
    const [policyCards, setPolicyCards] = useState([]);
    const [qrDetails, setQrDetails] = useState(false);
    const [qrDetailsObj, setQrDetailObj] = useState({ securityOptions: 'withoutLogin' });
    const [shareDetailObj, setShareDetailObj] = useState({ shareQrCode: 'group' });
    const [groups, setGroups] = useState([]);
    const [flatGroups, setFlatGroups] = useState([]);

    useEffect(() => {
        localStorage.removeItem('policyNameForTokens');
        getPolicies();
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.TVPOLICY_EDITOR);
        let schema = [...tvPoliciesSchema];
        if (findPriv) schema = tvPoliciesSchema.filter((policy, index) => index < tvPoliciesSchema.length - 2);
        setPoliciesTableSchema([...schema]);
    }, []);

    const getPolicies = (paramsObj) => {
        setLoading(true);

        let pURL = url.POLICIES;
        let params = {};
        if (paramsObj) params = paramsObj;
        else params = { page: pageNumber, size: pageSize };
        api.get(pURL, params, domains.ANDROID_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data?.length > 0) {
                    setTotalRecords(resp.totalRecords);
                    resp.data.forEach((data) => {
                        data.polType = profileTypeLabel(data.policytype?.toUpperCase());
                        data.modifiedBy = data.modifiedby ? data.modifiedby : '—';
                        data.modifiedDate = data.modifieddate ? convertUTCtoIST(data.modifieddate) : '—';
                    });
                    setPolicies(resp.data);
                    setPoliciesBackup(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleAddNew = () => {
        setPolicyType('');
        let policyArray = AndroidTVPoliciesArr;
        let configArr = [];
        policyArray.forEach((ele) => {
            configArr.push(ele);
        });
        setPolicyCards(configArr);
        setShowPolicyModal(true);
    };

    const qrRadioField = [
        {
            label: 'Share QR Code :',
            customCol: 12,
            customClass: 'mt-3',
            inputType: 'radio',
            helpText: 'Upon enabling this option, user can enrol the device without any authentication.',
            value: 'shareQrCode',
            radios: [
                {
                    label: 'Specific Device',
                    val: 'specific',
                    value: 'shareQrCode'
                },
                {
                    label: 'Group',
                    val: 'group',
                    value: 'shareQrCode'
                }
            ]
        }
    ];
    const handleEdit = (row) => {
        history.push('/atvpolicies/edit/' + row.original._id + '/' + row?.original?.policytype);
    };

    const handleDelete = (row) => {
        setDeleteModal(true);
        setSelectedRow(row);
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        setDeleteModal(false);
        api.delete(url.POLICIES + '/' + selectedRow?.original?._id, '', domains.ANDROID_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.policyDeleted);
                    getPolicies();
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleQR = (row) => {
        setSelectedRow(row);
        setQrDetails(true);
    };

    const handleClone = (row) => {
        validation.resetForm();
        setSelectedRow(row);
        setShowCloneModal(true);
    };

    const onClickSwitch = (row) => {
        setLoading(true);

        let rowData = JSON.parse(JSON.stringify(row.original));
        let obj = {
            id: rowData.id,
            status: rowData.status?.toLowerCase() === 'active' ? 'InActive' : 'Active',
            name: rowData.name,
            code: rowData.code,
            enterpriseId: rowData.enterpriseId,
            policy: rowData.policy
        };
        api.update(url.POLICIES + '/' + row?.original?.id, obj)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.policyStatusUpdated);
                    getPolicies();
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleEnrollmentToken = (row) => {
        localStorage.setItem('policyNameForTokens', row.original?.name);
        history.push('/atvpolicies/enrollmenttoken/' + row.original._id);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { code: '' },
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            setLoading(true);

            api.create(url.POLICIES + '/' + selectedRow?.original?._id + '/clone', '', true, domains.ANDROID_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        toast.success(toastMessages.policyCloned);
                        validation.resetForm();
                        setShowCloneModal(false);
                        getPolicies();
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setShowCloneModal(false);
                });
        }
    });

    const handleCloneModalBody = () => {
        return (
            <Form
                className="h-100"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <div className="mt-3">
                    <div className="mb-4">Are you sure you want to clone this Policy?</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={polClone} alt="clonepolicy" width={60} height={60} />
                    </div>
                    <div>
                        Policy Name: <span className="fw-semibold">{selectedRow.original?.name}</span>
                    </div>
                </div>
            </Form>
        );
    };

    const setCheckedGroup = (groupsObj, group) => {
        groupsObj.children?.forEach((groupVal) => {
            if (groupVal.code === group.code) groupVal.checked = !!groupVal.checked;
            if (groupsObj.checked) groupVal.checked = true;
            if (groupVal.children) groupVal = setCheckedGroup(groupVal, group);
        });
        return groupsObj;
    };

    const checkGroupsChecked = (groupsObj, groupsChecked) => {
        groupsObj.children?.forEach((groupVal) => {
            if (groupVal.checked) groupsChecked.push(groupVal.code);
            else if (groupVal.children) groupsChecked = checkGroupsChecked(groupVal, groupsChecked);
        });
        return groupsChecked;
    };

    const setUncheckGroup = (groupsObj, group) => {
        groupsObj.children?.forEach((groupVal) => {
            groupVal.checked = false;
            if (groupVal.children) groupVal = setUncheckGroup(groupVal, group);
        });
        return groupsObj;
    };

    const handleGroupSelection = (groupArr) => {
        setGroups(groupArr);
    };

    const changeGroups = (groupsArr, _checked, group) => {
        groupsArr.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
            if (groupObj.children) groupObj = setCheckedGroup(groupObj, group);
            if (groupObj.code === group.code && !groupObj.checked) groupObj = setUncheckGroup(groupObj, group);
        });
        let groupsChecked = [];
        groupsArr.forEach((groupObj) => {
            if (groupObj.checked) groupsChecked.push(groupObj.code);
            else if (groupObj.children) groupsChecked = checkGroupsChecked(groupObj, groupsChecked);
        });
        handleGroupSelection(groupsChecked);
        setGroups(groupsArr);
    };

    const radioButton = (field, index) => {
        return (
            <Row key={index} className={field.customClass ? field.customClass : ''}>
                {field?.label && (
                    <Col
                        xs={field?.customCol ? field?.customCol : 6}
                        sm={field?.customCol ? field?.customCol : 6}
                        md={field?.customCol ? field?.customCol : 6}
                        lg={field?.customCol ? field?.customCol : 6}
                        xl={field?.customCol ? field?.customCol : 6}
                    >
                        <Label className="mb-2 fw-semibold  fs-14">{field?.label}</Label>
                        <div>
                            {field.helpText && (
                                <div className={`text-muted fs-12 word-wrap-break ${field.noMargin ? '' : 'mb-2'}`}>{field.helpText}</div>
                            )}
                        </div>
                    </Col>
                )}

                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${field.noMargin ? '' : 'mb-2'}`}>
                    <Row>
                        {field?.radios?.map((ele, ind) => {
                            return (
                                <Col className="form-check form-check-inline col-12 mb-3" key={ind}>
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id={field.value}
                                        name={field.value}
                                        value={ele.val}
                                        onChange={(e) => {
                                            field.value === 'securityOptions'
                                                ? setQrDetailObj({
                                                      ...qrDetailsObj,
                                                      [field.value]: e.target.value,
                                                      pin: ''
                                                  })
                                                : setShareDetailObj({
                                                      ...shareDetailObj,
                                                      [field.value]: e.target.value
                                                  });
                                        }}
                                        checked={
                                            (field.value === 'securityOptions' ? qrDetailsObj : shareDetailObj)?.[field.value] === ele.val
                                        }
                                    />
                                    <Label className="mb-0 d-flex">{ele.label}</Label>
                                    <div>
                                        {ele.helpText && (
                                            <div className={`text-muted fs-12 word-wrap-break ${field.noMargin ? '' : 'mb-2'}`}>
                                                {ele.helpText}
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        );
    };

    const inputComponent = (field, index) => {
        return (
            <React.Fragment key={index}>
                {field?.label && (
                    <Col
                        xs={field.smallLabel ? 2 : 4}
                        sm={field.smallLabel ? 2 : 4}
                        md={field.smallLabel ? 2 : 4}
                        xl={field.smallLabel ? 2 : 4}
                        lg={field.smallLabel ? 2 : 4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Label className="form-check-label fw-medium d-flex align-items-center mb-0">
                            {field.label}
                            {field.mandatory ? (
                                <span className="red-color ps-1">*</span>
                            ) : (
                                <>
                                    <i
                                        className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                        id={`inputComponent-${index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                        {field.helpText ? field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            )}
                        </Label>
                    </Col>
                )}

                <Col
                    xs={field.largeBox ? 6 : 8}
                    sm={field.largeBox ? 6 : 8}
                    md={field.largeBox ? 6 : 8}
                    xl={field.largeBox ? 6 : 8}
                    lg={field.largeBox ? 6 : 8}
                >
                    <div className={`input-group ${field.class}`}>
                        <div className="d-flex align-items-center w-100">
                            <Input
                                name={field.value}
                                id={field.value}
                                className={`form-control ${field.class ? field.class : 'w-100'}`}
                                placeholder={`Enter ${field.label ? field?.label : field?.placeHolder}`}
                                type={field.type}
                                maxLength={field.maxLength}
                                validate={{ required: { value: true } }}
                                onChange={(e) => setQrDetailObj({ ...qrDetailsObj, pin: e.target.value })}
                                value={qrDetailsObj?.[field.value] || ''}
                            />
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        );
    };

    const dynamicComponent = (field, index) => {
        switch (field?.inputType) {
            case 'radio':
                return radioButton(field, index);
            case 'input':
                return inputComponent(field, index);
        }
    };

    const handleShowOn = (field) => {
        let flag = true;
        flag = field.showKey ? flag && qrDetailsObj[field.showKey] === field.showVal : flag;
        return flag;
    };

    const handleQRModalBody = () => {
        return qrDetails ? (
            <div className="mt-3">
                {AndroidTVPolicyQR?.map(
                    (field, index) => (field?.showOn ? handleShowOn(field, index) : true) && dynamicComponent(field, index)
                )}
            </div>
        ) : (
            <>
                <Row className="border-bottom pb-3">
                    <Col xs={12} sm={12} md={4} xl={4} lg={4}>
                        <div className={'d-flex align-items-center justify-content-center p-2 rounded-3 border flex-wrap mb-2'}>
                            {qrCodeString ? (
                                <img alt="" className="w-100" src={`data:image/png;base64,${qrCodeString}`} />
                            ) : (
                                <Spinner color="primary" className="spinner-style" />
                            )}
                        </div>
                        <span className="badge-soft-danger rounded-3 px-2 text-center fw-medium">
                            Expires in <span className="fs-13 fw-medium text-black ">10 hours</span>
                        </span>
                    </Col>

                    <Col xs={12} sm={12} md={8} xl={8} lg={8}>
                        <div className="d-flex align-items-center fs-14 fw-medium pe-2">
                            QR code Details :
                            <i
                                className="ri-edit-box-line fs-18 px-2 cursor-pointer text-secondary"
                                onClick={() => {
                                    setQrDetails(true);
                                    setShowQRModal(false);
                                }}
                            />
                        </div>
                        <div className="fs-14 my-1 fw-medium">
                            {AndroidPolicyQR?.[0]?.radios?.find((option) => option.val === qrDetailsObj?.securityOptions)?.label}
                        </div>
                        <div className="fs-14 fw-medium">Created Time</div>
                    </Col>
                </Row>
                <Row>
                    {qrRadioField?.map((field, index) => dynamicComponent(field, index))}
                    {shareDetailObj?.shareQrCode === 'group' && (
                        <div className="mb-3 mb-lg-0 w-100 shadow-card">
                            <div className="bg-soft-success padding-8 fs-14 text-success border-radius-top-left-4 border-radius-top-right-4">
                                <div className="d-flex align-items-center">
                                    <i className="ri-database-2-fill me-2" />
                                    <span>Select Groups</span>({groups.length})
                                </div>
                            </div>
                            <div className="height-400 border-radius-bottom-left-4 border-radius-bottom-right-4 p-3 overflow-auto">
                                <AnimatedTreeComponent
                                    groups={groups}
                                    flatGroups={flatGroups}
                                    changeGroups={changeGroups}
                                    // disableCheckbox={mode === 'view'}
                                />
                            </div>
                        </div>
                    )}
                </Row>
            </>
        );
    };

    const qrHeader = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Label className="p-0 m-0 mt-3 fw-semibold">Policy :</Label>
                <p className="p-0 m-0 mt-3 ms-2 fw-normal">{selectedRow?.original?.name}</p>
            </div>
        );
    };

    const onPageChange = (pgNumber, pgSize) => {
        let params = { page: pgNumber, size: pgSize };
        setPageNumber(pgNumber);
        setPageSize(pgSize);
        getPolicies(params);
    };

    const handleClickView = (row) => {
        history.push('/atvpolicies/view/' + row?.original?._id + '/' + row?.original?.policytype);
    };

    const handleGlobalSearch = (val) => {
        let filteredPolicies = [...policiesBackup];
        if (val?.length > 0)
            filteredPolicies = policiesBackup.filter(
                (policy) =>
                    policy?.code?.toLowerCase()?.includes(val?.toLowerCase()) || policy?.name?.toLowerCase()?.includes(val?.toLowerCase())
            );
        setTotalRecords(filteredPolicies.length);
        setPolicies(filteredPolicies);
    };

    const handlePolicyTypeClick = (item) => {
        setPolicyType(item);
        if (item.cards?.length > 0) {
            let configArr = [];
            item.cards.forEach((ele) => {
                configArr.push(ele);
            });
            setPolicyCards(configArr);
        }
    };

    const handlePolicyModalBody = () => {
        return (
            <div>
                <Row>
                    <div className="d-flex align-items-center justify-content-evenly">
                        {AndroidTVPoliciesArr?.length > 0 &&
                            AndroidTVPoliciesArr.map((ele, index) => {
                                return (
                                    <Card
                                        key={index}
                                        className={`mb-0 p-2 border shadow width-300 height-270 cursor-pointer ${
                                            ele.id === policyType.id ? 'border-2 border-primary' : ''
                                        }`}
                                        onClick={() => handlePolicyTypeClick(ele)}
                                    >
                                        <CardBody>
                                            <div className="d-flex flex-column align-items-center justify-content-center position-relative">
                                                {ele.id === policyType.id && (
                                                    <i className="position-absolute end-10 top-5 link-primary fs-18 ri-check-double-fill"></i>
                                                )}
                                                <div>
                                                    <img src={ele.img} alt="" height={70} width={70} />
                                                </div>
                                                <p className="fw-semibold text-info text-truncate mt-3 mb-3">{ele.label}</p>
                                                <span className="text-wrap text-center fw-normal fs-12">{ele.desc}</span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                );
                            })}
                    </div>
                </Row>
            </div>
        );
    };

    const handleQrConfirmation = (row) => {
        /*
         *`
         * setLoading(true);
         * api.get(url.POLICIES + '/' + row.original.id + url.QR_CODE)
         *     .then((resp) => {
         *         resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
         *         if (resp.status?.toLowerCase() === 'success') {
         */
        toast.success(toastMessages.qrGenerated);
        setQrDetails(false);
        setShowQRModal(true);
        // setQRCodeString(resp.data);
        setLoading(false);
        /*
         * }
         * })
         * .catch((err) => {
         *     setLoading(false);
         * });
         */
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete Policy?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={polDelete} alt="deletepolicy" width={60} height={60} />
                </div>
                <div>
                    Policy Name: <span className="fw-semibold">{selectedRow.original?.name}</span>
                </div>
            </div>
        );
    };

    const handleQRCloseClick = () => {
        setShowQRModal(false);
        setQrDetails(false);
        setSelectedRow('');
        setQrDetailObj({ securityOptions: 'withoutLogin' });
        setShareDetailObj({ shareQrCode: 'group' });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Android TV Policies" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Policies'}
                        isGlobalFilter={true}
                        addButton={true}
                        largeSearchFilter={true}
                        SearchFilterWidth={'width-280'}
                        searchFilter2={true}
                        searchPlaceHolder={'Policy Code or Name'}
                        columns={policiesTableSchema}
                        handleGlobalSearch={handleGlobalSearch}
                        handleQR={handleQR}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleAddNew={handleAddNew}
                        handleClickView={handleClickView}
                        handleClone={handleClone}
                        onClickSwitch={onClickSwitch}
                        totalRecords={totalRecords}
                        pageNum={pageNumber}
                        onPageChange={onPageChange}
                        handleEnrollmentToken={handleEnrollmentToken}
                        data={policies}
                        editor={priviliges.POLICY_EDITOR}
                        reader={priviliges.POLICY_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={deleteModal}
                        hideDeleteMessage={true}
                        message={message()}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={showCloneModal}
                        hideDeleteMessage={true}
                        message={handleCloneModalBody()}
                        onDeleteClick={validation.handleSubmit}
                        confirmText="Yes, Clone it"
                        hideDeleteIcon={true}
                        onCloseClick={() => {
                            setShowCloneModal(false);
                            validation.resetForm();
                        }}
                    />
                    {/* <CommonModal
                        size={'md'}
                        show={showQRModal}
                        disabled={false}
                        hideSaveButton={true}
                        modalheader={qrHeader()}
                        onCloseClick={() => setShowQRModal(false)}
                        handleClick={() => setShowQRModal(false)}
                        handleModalBody={() => handleQRModalBody()}
                    /> */}
                    <CommonModal
                        // customWidth={'max-width-per-50'}
                        size="lg"
                        disabled={!policyType?.path || policyType?.path === ''}
                        cancelText={'Cancel'}
                        saveText={'Proceed'}
                        show={showPolicyModal}
                        modalheader={'Create New Policy'}
                        onCloseClick={() => {
                            setShowPolicyModal(false);
                            setPolicyType('');
                        }}
                        handleClick={() => {
                            history.push('/atvpolicies/add/' + policyType.path);
                            setShowPolicyModal(false);
                        }}
                        handleModalBody={() => handlePolicyModalBody()}
                    />
                    <OffcanvasModal
                        direction="end"
                        toggle={() => handleQRCloseClick()}
                        open={showQRModal || qrDetails}
                        handleCloseClick={() => handleQRCloseClick()}
                        OffcanvasModalID="Qr_details"
                        closeButtonBorder={true}
                        saveText={!qrDetails ? 'Send' : 'Generate QR'}
                        handleOffcanvasBody={() => handleQRModalBody()}
                        modalClassName={'w-40'}
                        saveDisabled={qrDetailsObj?.securityOptions === 'withStatic' ? qrDetailsObj?.pin?.length !== 4 : false}
                        offcanvasHeader={`Policy : ${selectedRow?.original?.name}`}
                        handleSaveClick={() => handleQrConfirmation(selectedRow)}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AndroidTVPolicies;
