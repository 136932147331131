export const ANTIVIRUS_STATUS = {
    0: 'On and Monitoring',
    1: 'Disabled',
    2: 'Isn\'t monitoring & some options turned off',
    3: 'Temporarily not completely monitoring',
    4: 'Not Applicable for device'
};

export const BITLOCKER_STATUS = {
    0: 'User didn\'t consent',
    1: 'Encryption method of the OS volume doesn\'t match the BitLocker policy',
    2: 'OS volume is unprotected',
    3: 'BitLocker policy requires a TPM-only protector for the OS volume, but TPM protection is not used',
    4: 'BitLocker policy requires TPM+PIN protection for the OS volume, but a TPM+PIN protector is not used.',
    5: 'BitLocker policy requires TPM+startup key protection for the OS volume, but a TPM+startup key protector is not used',
    6: 'BitLocker policy requires TPM+PIN+startup key protection for the OS volume, but a TPM+PIN+startup key protector is not used',
    7: 'BitLocker policy requires a TPM protector to protect the OS volume, but a TPM is not used',
    8: 'Recovery key backup failed',
    9: 'Fixed drive is unprotected',
    10: 'Encryption method of the fixed drive doesn\'t match the BitLocker policy',
    11: 'To encrypt drives, the BitLocker policy requires either the user to sign in as an Administrator or if the device is joined to Microsoft Entra ID, the AllowStandardUserEncryption policy must be set to 1',
    12: 'Windows Recovery Environment (WinRE) isn\'t configured.',
    13: 'TPM isn\'t available for BitLocker, either because it isn\'t present, it has been made unavailable in the Registry, or the OS is on a removable drive',
    14: 'TPM isn\'t ready for BitLocker.',
    15: 'Network isn\'t available, which is required for recovery key backup',
    16: 'Encryption type of the OS volume for full disk versus used space only encryption doesn\'t match the BitLocker policy',
    17: 'Encryption type of the fixed drive for full disk versus used space only encryption doesn\'t match the BitLocker policy'
};

export const ANTISPYWARE_STATUS = {
    0: 'Security provider category is good and doesn\'t need user attention',
    1: 'Security provider category isn\'t monitored by Windows Security Center (WSC)',
    2: 'Security provider category is poor and the computer may be at risk',
    3: 'Security provider category is in snooze state. Snooze indicates that WSC isn\'t actively protecting the computer'
};

export const FIREWALL_STATUS = {
    0: 'On and monitoring',
    1: 'Disabled',
    2: 'Isn\'t monitoring all networks or some rules have been turned off',
    3: 'Not Applicable for device'
};

export const AGENTAPP_STATUS = {
    10: 'Initialized',
    20: 'Download In Progress',
    25: 'Pending Download Retry',
    30: 'Download Failed',
    40: 'Download Completed',
    48: 'Pending User Session',
    50: 'Enforcement In Progress',
    55: 'Pending Enforcement Retry',
    60: 'Enforcement Failed',
    70: 'Enforcement Completed'
};
