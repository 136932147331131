import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, FormFeedback, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TabsComponent from '../../../Components/Common/TabsComponent';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import AllowedDomains from '../../../assets/images/common/png/common/allowedDomains.png';
import BlockedDomains from '../../../assets/images/common/png/common/blockedDomains.png';
import TickMark from '../../../assets/images/common/svg/common/tickmark.svg';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';

const AddCallConfiguration = () => {
    let history = useHistory();
    let api = new APIClient();

    const config = useEnv();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];

    document.title = formType === 'add' ? 'Add Call Config' : formType === 'edit' ? 'Edit Call Config' : 'View Call Config';
    const [mode, setMode] = useState(formType);
    const [loading, setLoading] = useState(false);
    const [allowedList, setAllowedList] = useState({ incoming: [], outgoing: [] });
    const [blockedList, setBlockedList] = useState({ incoming: [], outgoing: [] });
    const [allowByDefaultContact, setAllowByDefaultContact] = useState({ incoming: true, outgoing: true });
    const [deleteModal, setDeleteModal] = useState(false);
    const [selVal, setSelVal] = useState('');
    const [formValues, setFormValues] = useState('');
    const [selectedTab, setSelectedTab] = useState('incoming');
    const [contactListChecked, setContactListChecked] = useState({ incoming: false, outgoing: false });
    const [contactsList, setContactsList] = useState([]);
    const [contactList, setContactList] = useState({ incoming: { allowed: [], blocked: [] }, outgoing: { allowed: [], blocked: [] } });

    useEffect(() => {
        getContactList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getContactList = () => {
        setLoading(true);
        api.get(url.CONFIG, { page: 1, size: 1000, configType: 'CONTACTS' })
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    let contacts = [];
                    resp.data.forEach((data) => contacts.push(data.config.numbers ? data.config.numbers : []));
                    let contactsArr = contacts.reduce((acc, subArray) => acc.concat(...subArray), []);
                    setContactsList(contactsArr);
                }
                if (recordID) getOneCallCOnfig();
                else setLoading(false);
            })
            .catch((err) => {
                if (recordID) getOneCallCOnfig();
                else setLoading(false);
            });
    };

    const getOneCallCOnfig = () => {
        setLoading(true);
        api.get(url.CONFIG + '/' + recordID)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    setFormValues(resp.data);
                    setContactListChecked({ ...resp.data?.config?.contactChecked });
                    setAllowByDefaultContact({
                        incoming: resp.data?.config?.incoming?.allowByDefault,
                        outgoing: resp.data?.config?.outgoing?.allowByDefault
                    });
                    setAllowedList({
                        incoming: resp.data?.config?.incoming?.allowedContacts,
                        outgoing: resp.data?.config?.outgoing?.allowedContacts
                    });
                    setBlockedList({
                        incoming: resp.data?.config?.incoming?.blockedContacts,
                        outgoing: resp.data?.config?.outgoing?.blockedContacts
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { name: formValues.name ? formValues.name : '' },
        onSubmit: (values) => {
            setLoading(true);
            let allowedIncomingNumbers = allowedList.incoming?.length > 0 ? allowedList.incoming.map((incoming) => incoming.number) : [];
            let blockedIncomingNumbers = blockedList.incoming?.length > 0 ? blockedList.incoming.map((incoming) => incoming.number) : [];
            let allowedOutgoingNumbers = allowedList.outgoing?.length > 0 ? allowedList.outgoing.map((outgoing) => outgoing.number) : [];
            let blockedOutgoingNumbers = blockedList.outgoing?.length > 0 ? blockedList.outgoing.map((outgoing) => outgoing.number) : [];
            if (contactListChecked.incoming) {
                allowedIncomingNumbers = allowedIncomingNumbers.concat(contactsList);
            }
            if (contactListChecked.outgoing) {
                allowedOutgoingNumbers = allowedOutgoingNumbers.concat(contactsList);
            }
            let obj = {
                name: values.name,
                configType: 'CALLCONFIG',
                config: {
                    contactChecked: { ...contactListChecked },
                    incoming: {
                        allowByDefault: !!allowByDefaultContact.incoming,
                        allowedContacts: !!allowByDefaultContact.incoming
                            ? allowedList?.incoming?.length > 0
                                ? allowedList.incoming
                                : []
                            : [],
                        blockedContacts: !!allowByDefaultContact.incoming
                            ? []
                            : blockedList?.incoming?.length > 0
                            ? blockedList.incoming
                            : [],
                        numbers: { allowed: allowedIncomingNumbers, blocked: blockedIncomingNumbers }
                    },
                    outgoing: {
                        allowByDefault: !!allowByDefaultContact.outgoing,
                        allowedContacts: !!allowByDefaultContact.outgoing
                            ? allowedList?.outgoing?.length > 0
                                ? allowedList.outgoing
                                : []
                            : [],
                        blockedContacts: !!allowByDefaultContact.outgoing
                            ? []
                            : blockedList?.outgoing?.length > 0
                            ? blockedList.outgoing
                            : [],
                        numbers: { allowed: allowedOutgoingNumbers, blocked: blockedOutgoingNumbers }
                    }
                }
            };
            if (formType === 'edit') obj.id = recordID;
            createNdUpdate(obj);
        }
    });

    const createNdUpdate = (dataObj) => {
        let apiService;
        if (recordID) apiService = api.update(url.CONFIG + '/' + recordID, dataObj);
        else apiService = api.create(url.CONFIG, dataObj);

        apiService
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.callConfigConfigUpdate : toastMessages.callConfigConfigCreate);
                    history.push('/callconfig');
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleCardClick = (allowed, blocked, type, key) => {
        if (formType === 'view') return;
        let arr = allowByDefaultContact[key] ? allowed[key] : blocked[key];
        if (arr?.length > 0 || contactListChecked[key]) {
            setDeleteModal(true);
            setSelVal({ ...allowByDefaultContact, [key]: !allowByDefaultContact[key] });
        } else setAllowByDefaultContact({ ...allowByDefaultContact, [key]: !allowByDefaultContact[key] });
    };

    const handleRemoveOneItem = (item) => {
        if (allowByDefaultContact[selectedTab]) {
            let arr = allowedList?.[selectedTab]?.filter((ele) => ele.number !== item.number);
            setAllowedList({ ...allowedList, [selectedTab]: arr });
        } else {
            let arr = blockedList?.[selectedTab]?.filter((ele) => ele.number !== item.number);
            setBlockedList({ ...blockedList, [selectedTab]: arr });
        }
    };

    const validateNumber = (num) => {
        let regex = new RegExp(/^[0-9]+$/);
        let test = regex.test(Number(num));
        return test && num.length === 10;
    };

    const checkExisting = (num) => {
        const allowed = allowedList?.[selectedTab]?.map((list) => list.number);
        const blocked = blockedList?.[selectedTab]?.map((list) => list.number);
        const numArr = allowed.concat(blocked);
        return numArr.includes(num);
    };

    const handleAddContact = () => {
        if (allowByDefaultContact[selectedTab]) {
            let valid = validateNumber(validation.values.numbera);
            if (!valid) {
                toast.error(toastMessages.invalidPhone);
                return;
            }
            const exists = checkExisting(validation.values.numbera);
            if (exists) {
                toast.error(toastMessages.phoneExists);
                return;
            }
            let arr = [...allowedList?.[selectedTab]];
            if (validation.values.namea && validation.values.numbera)
                arr.push({ name: validation.values.namea, number: validation.values.numbera });
            setAllowedList({ ...allowedList, [selectedTab]: arr });
            validation.setValues({ ...validation.values, namea: '', numbera: '' });
        } else {
            let valid = validateNumber(validation.values.numberb);
            if (!valid) {
                toast.error(toastMessages.invalidPhone);
                return;
            }
            const exists = checkExisting(validation.values.numberb);
            if (exists) {
                toast.error(toastMessages.phoneExists);
                return;
            }
            let arr = [...blockedList?.[selectedTab]];
            if (validation.values.nameb && validation.values.numberb)
                arr.push({ name: validation.values.nameb, number: validation.values.numberb });
            setBlockedList({ ...blockedList, [selectedTab]: arr });
            validation.setValues({ ...validation.values, nameb: '', numberb: '' });
        }
    };

    const handleDeleteConfirmation = () => {
        setDeleteModal(false);
        setAllowByDefaultContact(selVal);
        setContactListChecked({ ...contactListChecked, [selectedTab]: false });
        if (allowByDefaultContact[selectedTab]) setAllowedList({ ...allowedList, [selectedTab]: [] });
        else setBlockedList({ ...blockedList, [selectedTab]: [] });
    };

    const userLabel = (val) => {
        let name = val?.split(' ');
        if (name?.length === 1) return name?.[0]?.charAt(0)?.toUpperCase();
        else return name?.[0]?.charAt(0)?.toUpperCase() + name?.[1]?.charAt(0)?.toUpperCase();
    };

    const addUrlTemplate = (field) => {
        return (
            <Card className="border mb-sm-0 mt-2">
                <CardHeader
                    className={`pb-0 fw-semibold bg-soft-${field.color}`}
                    onClick={() => handleCardClick(allowedList, blockedList, 'contact', field.key)}
                >
                    <Row className={'d-flex g-2 align-items-center ' + (!field.param ? 'pe-none opacity-50' : '')}>
                        <Col>
                            <Label
                                className={
                                    'd-flex flex-column form-check-label fw-medium fs-15 text-' +
                                    field.color +
                                    ' ' +
                                    (formType === 'view' ? ' cursor-auto' : '')
                                }
                            >
                                {field.label}
                            </Label>
                        </Col>
                        {field.param && (
                            <Col className="m-0">
                                <div className="d-flex justify-content-end">
                                    <img src={TickMark} alt="" height="25" />
                                </div>
                            </Col>
                        )}
                    </Row>
                    <Row className="pb-2">
                        <span className="d-flex align-items-center text-truncate fs-13 mt-2 fw-normal ">
                            <i className="ri-information-line link-info me-1" />
                            {field.desc}
                        </span>
                    </Row>
                </CardHeader>
                <CardBody className={`p-0 ${!field.param ? 'pe-none opacity-50' : ''}`}>
                    <div className={`p-4 pt-2 bg-soft-${field.color}`}>
                        {field.showCheckbox && (
                            <Row className={'d-flex align-items-center' + (!field.param ? 'pe-none opacity-50' : '')}>
                                <div className="d-flex align-items-center">
                                    <Input
                                        className="me-2 mb-1 height-17 width-17"
                                        type="checkbox"
                                        onChange={(e) => setContactListChecked({ ...contactListChecked, [field.key]: e.target.checked })}
                                        value={contactListChecked?.[field.key]}
                                        disabled={formType === 'view'}
                                        checked={contactListChecked?.[field.key]}
                                        id="auth-remember-check"
                                    />
                                    <Label className="mb-0 pb-0">Contact List</Label>
                                </div>
                                <span className="d-flex align-items-center text-truncate fs-13 fw-normal ">
                                    <i className="ri-information-line link-info me-1" />
                                    {field.desc2}
                                </span>
                            </Row>
                        )}
                        <Row className={'d-flex g-2 align-items-center mt-1 ' + (!field.param ? 'pe-none opacity-50' : '')}>
                            <Col sm={12}>
                                <Label>Unknown Numbers:</Label>
                                <span className="d-flex align-items-center text-truncate fs-13 mt-1 fw-normal">
                                    <i className="ri-information-line link-info me-1" />
                                    {field.unknownDesc}
                                </span>
                            </Col>
                        </Row>
                        <Row className={'d-flex g-2 align-items-center mt-1 ' + (!field.param ? 'pe-none opacity-50' : '')}>
                            {' '}
                            {formType !== 'view' && (
                                <Row className="g-2 align-items-center">
                                    <Col>
                                        <div className="input-group">
                                            <Input
                                                name={field.val1}
                                                id={field.val1}
                                                className="form-control"
                                                placeholder={'Enter Name'}
                                                type={'text'}
                                                validate={{ required: { value: true } }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values[field.val1] || ''}
                                                invalid={validation.touched[field.val1] && validation.errors[field.val1] ? true : false}
                                            />
                                            {validation.touched[field.val1] && validation.errors[field.val1] ? (
                                                <FormFeedback type="invalid">{validation.errors[field.val1]}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="input-group">
                                            <Input
                                                maxLength={10}
                                                type="text"
                                                name={field.val2}
                                                id={field.val2}
                                                className="form-control"
                                                placeholder={'Enter Number'}
                                                validate={{ required: { value: true } }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values[field.val2] || ''}
                                                invalid={validation.touched[field.val2] && validation.errors[field.val2] ? true : false}
                                            />
                                            {validation.touched[field.val2] && validation.errors[field.val2] ? (
                                                <FormFeedback type="invalid">{validation.errors[field.val2]}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col sm="auto">
                                        <Button
                                            size="sm"
                                            type="button"
                                            color={'primary'}
                                            onClick={handleAddContact}
                                            disabled={!validation.values[field.val1] || !validation.values[field.val2]}
                                        >
                                            <span className="d-flex align-items-center">
                                                <i className="ri-add-line me-1 fs-16"></i> Add New
                                            </span>
                                        </Button>
                                    </Col>
                                    <Col sm="auto">
                                        <Button
                                            size="sm"
                                            outline
                                            type="button"
                                            color="primary"
                                            onClick={() => {
                                                allowByDefaultContact[selectedTab]
                                                    ? setAllowedList({ ...allowedList, [selectedTab]: [] })
                                                    : setBlockedList({ ...blockedList, [selectedTab]: [] });
                                                validation.setValues({ ...validation.values, bookmarkList: [] });
                                            }}
                                        >
                                            <span className="d-flex align-items-center">
                                                <i className="ri-close-circle-line me-1 fs-16"></i> Clear All
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Row>
                    </div>
                    <Row className="px-4">
                        <div className="d-flex align-items-center justify-content-between mt-3">
                            <div className="fw-medium">Unknown Numbers({contactList?.[field.key]?.[field.type]?.length})</div>
                            <div className="d-flex align-items-center justify-content-space-evenly"></div>
                        </div>
                    </Row>
                    <Row className="px-4 mt-2 height-300 max-height-300 min-height-300 overflow-auto">
                        <Col className="border">
                            <Row className={'rounded-2'}>
                                {field.list?.length > 0 ? (
                                    field.list.map((ele, ind) => (
                                        <Col key={ind} sm={6} className="pe-0 mt-2">
                                            <Card className="shadow-card">
                                                <CardBody className="p-2">
                                                    <Row>
                                                        <Col sm={10} className="d-flex">
                                                            <span className="rounded-circle header-profile-user border border-2 d-flex align-items-center justify-content-center fs-15 bg-success text-white">
                                                                {userLabel(ele.name)}
                                                            </span>
                                                            <span className="ms-2">
                                                                <div>{ele.name}</div>
                                                                <div>{ele.number}</div>
                                                            </span>
                                                        </Col>
                                                        {formType !== 'view' && (
                                                            <Col sm={2}>
                                                                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                                                    <i
                                                                        className="ri-delete-bin-line link-danger cursor-pointer fs-18"
                                                                        onClick={() => handleRemoveOneItem(ele)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))
                                ) : (
                                    <div className="d-flex align-items-center fw-normal mt-2">No Domains Found</div>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    };

    const handleTabContent = (key) => {
        return (
            <Row>
                <Col sm={6}>
                    {addUrlTemplate({
                        param: allowByDefaultContact[key],
                        color: 'success',
                        id: 'allow-list',
                        list: allowedList[key],
                        img: AllowedDomains,
                        showCheckbox: true,
                        key: key,
                        type: 'allowed',
                        val1: 'namea',
                        val2: 'numbera',
                        label: 'Allowed Numbers',
                        desc:
                            key === 'incoming'
                                ? 'The contacts added in the below sections can ONLY be allowed for incoming calls.'
                                : 'The contacts added in the below sections can ONLY be allowed for outgoing calls.',
                        desc2: 'Select this option to receive incoming calls from your contact list',
                        unknownDesc: 'You can add additional contacts that are not mentioned in the contact list.'
                    })}
                </Col>
                <Col sm={6}>
                    {addUrlTemplate({
                        param: !allowByDefaultContact[key],
                        color: 'danger',
                        id: 'block-list',
                        list: blockedList[key],
                        img: BlockedDomains,
                        key: key,
                        type: 'blocked',
                        val1: 'nameb',
                        val2: 'numberb',
                        label: 'Blocked Numbers',
                        desc:
                            key === 'incoming'
                                ? 'The contacts added in the below sections can ONLY be blocked from incoming calls.'
                                : 'The contacts added in the below sections can ONLY be blocked from outgoing calls.',
                        desc2: '',
                        unknownDesc: 'The numbers added here in the below section will be blocked from outgoing call list.'
                    })}
                </Col>
            </Row>
        );
    };

    const tabsList = [
        { tabID: 'incoming', tabName: 'Incoming Calls', tabContent: handleTabContent('incoming') },
        { tabID: 'outgoing', tabName: 'Outgoing Calls', tabContent: handleTabContent('outgoing') }
    ];

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={`${mode?.charAt(0)?.toUpperCase() + mode?.slice(1)} Call Config`}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="callconfig"
                    />
                    <React.Fragment>
                        <Card>
                            <CardHeader className="ps-1">
                                <div className="ms-3 d-flex align-items-center">
                                    Name
                                    {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                    <div className={'input-group'}>
                                        {formType !== 'view' ? (
                                            <div className="w-25 ms-5">
                                                <Input
                                                    name={'name'}
                                                    id={'name'}
                                                    className={'form-control'}
                                                    placeholder={'Enter Name'}
                                                    type={'text'}
                                                    maxLength={'30'}
                                                    validate={{ required: { value: true } }}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values['name'] || ''}
                                                    invalid={validation.touched['name'] && validation.errors['name'] ? true : false}
                                                />
                                            </div>
                                        ) : validation?.values['name'] ? (
                                            <span className="ps-1">{validation?.values['name']}</span>
                                        ) : (
                                            '–'
                                        )}
                                        {validation.touched['name'] && validation.errors['name'] ? (
                                            <p className="m-0 mt-2 text-danger">{validation.errors['name']}</p>
                                        ) : null}
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className="p-1">
                                <TabsComponent
                                    tabsList={tabsList}
                                    defaultTabID={'incoming'}
                                    enableOnView={true}
                                    mode={mode}
                                    selectedTab={selectedTab}
                                    toggleTab={(tab) => {
                                        setSelectedTab(tab);
                                        validation.setValues({ ...validation.values, numbera: '', numberb: '', namea: '', nameb: '' });
                                    }}
                                />
                            </CardBody>
                            <CardFooter>
                                <Row className="p-0 m-0">
                                    <Col className="p-0 m-0">
                                        <div className="gap-2 d-flex justify-content-end">
                                            <button type="reset" className="btn btn-light" onClick={() => history.push('/callconfig')}>
                                                Cancel
                                            </button>
                                            {mode !== 'view' && (
                                                <button
                                                    type="submit"
                                                    className="btn btn-success"
                                                    id="add-btn"
                                                    disabled={!validation.values['name'] || validation.values['name'] === '' || loading}
                                                    onClick={(e) => validation.handleSubmit()}
                                                >
                                                    {formValues?.id ? 'Update' : 'Save'}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </React.Fragment>
                </Container>
            </div>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteConfirmation}
                onCloseClick={() => setDeleteModal(false)}
                deleteMessage="If you switch all saved entries will be cleared."
            />
        </React.Fragment>
    );
};

export default AddCallConfiguration;
