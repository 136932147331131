/* eslint-disable spaced-comment */
import React, { useCallback, useEffect, useState } from 'react';
import { FilePond } from 'react-filepond';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { Enterprise, TenantConfig, getDate, noCaseSensitiveSortBy } from '../../../Components/Common/Util';
import { fileTypeValidations, priviliges } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { bulkUploadsSchema } from '../../AndroidEnterprise/TableSchema';
import ATVViewBulkUploads from './ATVViewBulkUploads';

const TVBulkRequests = () => {
    document.title = 'TV Bulk Requests';
    let history = useHistory();
    let enterPriseObj = Enterprise();

    const enterprises = enterPriseObj ? JSON.parse(enterPriseObj) : {};
    const api = new APIClient();
    const [loading, setLoading] = useState(false);
    const [bulkUploads, setBulkUploads] = useState([]);
    const [offcanvasModal, setOffcanvasModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [purpose, setPurpose] = useState('');
    const [showBulkModal, setShowBulkModal] = useState(false);
    let tenant = TenantConfig();
    let actionOptions = [];
    let uploadOptions = [];
    const [actionTypes, setActionTypes] = useState(
        !!tenant.ANDROID_TV_ENABLED && !!tenant.ANDROID_ENTERPRISE_ENABLED
            ? actionOptions
            : !!tenant.ANDROID_TV_ENABLED
            ? []
            : actionOptions
    );

    const [uploadTypes, setUploadTypes] = useState(
        !!tenant.ANDROID_TV_ENABLED && !!tenant.ANDROID_ENTERPRISE_ENABLED
            ? uploadOptions
            : !!tenant.ANDROID_TV_ENABLED
            ? []
            : uploadOptions
    );
    const [downloadType, setDownloadType] = useState('');
    const [uploadType, setUploadType] = useState(false);
    const [file, setFile] = useState('');
    const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchParams, setSearchParams] = useState({ type: 'TV', page: 1, size: 10 });
    const config = useEnv();

    useEffect(() => {
        let uploadTypeArr = JSON.parse(JSON.stringify(uploadTypes));
        let actionTypeArr = JSON.parse(JSON.stringify(actionTypes));
        uploadTypeArr.push({ label: 'Bulk upload Android Tv', value: 'androidTv', check: 'androidTv' });
        uploadTypeArr.push({ label: 'Upload Teachers Data', value: 'teacherData', check: 'teacherData' });
        uploadTypeArr = noCaseSensitiveSortBy(uploadTypeArr, 'label');
        actionTypeArr.push({ label: 'Bulk upload Android Tv', value: 'androidTv', check: 'androidTv' });
        actionTypeArr.push({ label: 'Upload Teachers Data', value: 'teacherdata', check: 'teacherData' });
        actionTypeArr = noCaseSensitiveSortBy(actionTypeArr, 'label');
        let uploadTypeArray = [];
        uploadTypeArr.forEach((obj) => {
            if (!!tenant?.TV_BULK_REQUESTS?.split(',')?.includes(obj.check)) uploadTypeArray.push(obj);
        });
        let actionTypeArray = [];
        actionTypeArr.forEach((obj) => {
            if (!!tenant?.TV_BULK_REQUESTS?.split(',')?.includes(obj.check)) actionTypeArray.push(obj);
        });
        setUploadTypes(uploadTypeArray);
        setActionTypes(actionTypeArray);
        getBulkUploads(searchParams);
    }, []);

    const toggle = useCallback(async () => {
        if (offcanvasModal) setOffcanvasModal(false);
        else setOffcanvasModal(true);
    }, [offcanvasModal]);

    const getBulkUploads = (params) => {
        setLoading(true);
        setSearchParams(params);
        api.get(url.BULK_ACTION, params)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp) {
                    setTotalRecords(resp.totalRecords);
                    resp.data.forEach((ele) => {
                        ele.purpose = ele.purpose ? ele.purpose : '—';
                        ele.createdBy = ele.createdBy ? ele.createdBy : 'Tectoro';
                        ele.createdDate = getDate(ele.createdTime);
                        ele.completedDate = getDate(ele.lastmodifiedTime);
                        ele.fileName = ele.fileName ? ele.fileName : 'Device Bulk Action';
                        ele.status =
                            ele.status?.toLowerCase() === 'done' ? 'COMPLETED' : ele.status?.toLowerCase() === 'new' ? 'NEW' : 'FAILED';
                        if (ele.status?.toLowerCase() === 'completed') ele.badgeClass = 'success';
                        else if (ele.status?.toLowerCase() === 'failed') ele.badgeClass = 'danger';
                        else ele.badgeClass = 'primary';
                    });
                    setBulkUploads(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleBulkUpload = (files, type) => {
        setLoading(true);
        let formData = new FormData();
        formData.append('file', files);
        formData.append('type', 'TV');
        if (type && type === 'actions') {
            api.create(url.BULK_ACTION, formData, true)
                .then((resp) => {
                    resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    setLoading(false);
                    if (resp.status?.toLowerCase() === 'success') {
                        toast.success(toastMessages.deviceUploaded);
                        let params = { ...searchParams, page: 1, size: 10 };
                        setPageNumber(1);
                        getBulkUploads(params);
                    }
                })
                .catch((err) => {
                    toast.error(toastMessages.deviceNotUploaded);
                    setLoading(false);
                });
        } else {
            //api call to upload devices
        }
    };

    const handleBulkUploadModalBody = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                        <Label className="form-label d-flex align-items-center fw-medium">
                            Select Action <span className="red-color ps-1">*</span>
                        </Label>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                        <Select
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            id="label"
                            name="value"
                            classNamePrefix={'custom-select w-100'}
                            options={uploadTypes}
                            placeholder="Select Action"
                            onChange={(selectedOption) => {
                                setUploadType(selectedOption);
                                setPurpose('');
                            }}
                            value={uploadType || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    </Col>
                </Row>
                {uploadType?.value && (
                    <Row>
                        <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                            <Label className="form-label d-flex align-items-center fw-medium">
                                Purpose<span className="red-color ps-1">*</span>
                            </Label>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                            <Input
                                name={purpose}
                                maxLength={100}
                                type="text"
                                placeholder="Enter purpose"
                                onChange={(e) => setPurpose(e.target.value)}
                                value={purpose || ''}
                            />
                        </Col>
                    </Row>
                )}
                <Row className="d-flex align-items-center">
                    <Col xs={12} sm={12} md={12} xl={3} lg={3}>
                        <Label className="form-label d-flex align-items-center">
                            Attachement
                            <span className="ps-1"> :</span>
                        </Label>
                    </Col>
                    <Col>
                        <FilePond
                            name="files"
                            maxFiles={1}
                            allowMultiple={true}
                            files={file}
                            className="filepond filepond-input-multiple"
                            onupdatefiles={(fileItems) => setFile(fileItems)}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const handleClickView = (row) => {
        setLoading(true);
        history.push('/atvbulkrequests/view/' + row.original?.id);
    };

    const handleDeviceUpload = () => {
        setShowBulkUploadModal(true);
    };

    const handleDevicesUpload = (formData) => {
        setShowBulkUploadModal(false);
        setFile('');
        setUploadType('');
        api.create(url.UPLOAD_DEVICES, formData, true)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.deviceUploaded);
                    let params = { ...searchParams, page: 1, size: 10 };
                    setPageNumber(1);
                    getBulkUploads(params);
                    setLoading(false);
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const handleUpload = () => {
        let fileType = file[0]?.fileExtension;
        if (fileType !== 'csv') {
            toast.error('File Extension should be ' + fileTypeValidations.bulkAction?.toString());
            return;
        }
        setLoading(true);
        let formData = new FormData();
        formData.append('file', file[0].file);
        formData.append('purpose', purpose);
        formData.append('type', 'TV');
        if (uploadType.value === 'devices') {
            formData.append('id', enterprises.id);
            handleDevicesUpload(formData);
            return;
        }
        if (uploadType.value === 'androidTv') {
            setFile('');
            setUploadType('');
            setPurpose('');
            setShowBulkUploadModal(false);
            api.create(url.TV_DEVICES + '/uploadFile', formData, true)
                .then((resp) => {
                    resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        toast.success(toastMessages.tvDevicesUpload);
                    }
                    let params = { ...searchParams, page: 1, size: 10 };
                    setPageNumber(1);
                    getBulkUploads(params);
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
            return;
        } else if (uploadType.value === 'teacherData') {
            setFile('');
            setUploadType('');
            setPurpose('');
            setShowBulkUploadModal(false);
            api.create(url.TEACHER_UPLOAD, formData, true)
                .then((resp) => {
                    resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        toast.success(toastMessages.teacherDataUploaded);
                        let params = { ...searchParams, page: 1, size: 10 };
                        setPageNumber(1);
                        getBulkUploads(params);
                    }
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
            return;
        }
        formData.append('action', uploadType.value);
        setFile('');
        setUploadType('');
        setPurpose('');
        setShowBulkUploadModal(false);
        api.create(url.BULK_ACTION, formData, true)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    let message = '';
                    switch (uploadType.value) {
                        case 'NOTES':
                            message = 'update notes on';
                            break;
                        case 'REBOOT':
                            message = 'reboot';
                            break;
                        case 'LOCK':
                            message = 'lock';
                            break;
                        case 'RESET_PASSWORD':
                            message = 'reset password on';
                            break;
                        case 'SCREAM':
                            message = 'scream on';
                            break;
                        case 'RESET':
                            message = 'factory reset';
                            break;
                        case 'NEWPOLICY':
                            message = 'change policy on';
                            break;
                        case 'NEWGROUP':
                            message = 'change group on';
                            break;
                        default:
                            break;
                    }
                    toast.success('Request raised to ' + message + ' selected devices.');
                    setLoading(false);
                    let params = { ...searchParams, page: 1, size: 10 };
                    setPageNumber(1);
                    getBulkUploads(params);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleBulkDownload = (row) => {
        setLoading(true);
        api.create(url.BULK_ACTION + '/' + row.original.id + '/items/download')
            .then((resp) => {
                let blob = new Blob([resp], { type: 'application/octet-stream' });
                var fileURL = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = fileURL;
                a.download = row.original.fileName?.replace('.csv', '') + ' ' + row.original?.createdDate?.replaceAll(',', '') + '.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleBulkSampleDownload = () => {
        setLoading(true);
        setShowBulkModal(false);
        let downloadTyp = JSON.parse(JSON.stringify(downloadType));
        setDownloadType('');
        if (downloadTyp.value === 'androidTv') {
            api.get(url.TV_DEVICES + '/template')
                .then((resp) => {
                    var fileURL = 'data:application/octet-stream;base64,' + resp;
                    var a = document.createElement('a');
                    a.href = fileURL;
                    a.download = 'Android TV Template.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setLoading(false);
                })
                .catch((_err) => {
                    setLoading(false);
                });
        } else
            api.get(url.BULK_ACTION + '/' + downloadTyp?.value + '/template')
                .then((resp) => {
                    var fileURL = 'data:application/octet-stream;base64,' + resp;
                    var a = document.createElement('a');
                    a.href = fileURL;
                    a.download = downloadTyp.label + ' Sample.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setLoading(false);
                })
                .catch((_err) => {
                    setLoading(false);
                });
    };

    const handleBulkDownloadModalBody = () => {
        return (
            <>
                <Row>
                    <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2"></Col>
                    <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                        <span className="fw-medium">Select the sample template for</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                        <Label className="form-label d-flex align-items-center fw-medium">
                            Select Action <span className="red-color ps-1">*</span>
                        </Label>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                        <Select
                            getOptionValue={(option) => option.label}
                            getOptionLabel={(option) => option.label}
                            id="label"
                            name="value"
                            classNamePrefix={'custom-select w-100'}
                            options={actionTypes}
                            placeholder="Select Action"
                            onChange={(selectedOption) => setDownloadType(selectedOption)}
                            value={downloadType || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    const handleOffcanvasBody = () => {
        return (
            <div className="position-reltive">
                <ATVViewBulkUploads selectedRow={selectedRow} />
            </div>
        );
    };

    const handleMultiUpload = (ele) => {
        setFile('');
        handleDeviceUpload();
    };

    const handleMultiDownload = (ele) => {
        setShowBulkModal(true);
    };

    const onPageChange = (pgNumber, pgSize) => {
        setLoading(true);
        let params = { ...searchParams, page: pgNumber, size: pgSize };
        setPageNumber(pgNumber);
        getBulkUploads(params);
    };

    const handleGlobalSearch = (val) => {
        setLoading(true);
        setPageNumber(1);
        let params = { ...searchParams, page: 1, size: 10, searchParam: val };
        getBulkUploads(params);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Bulk Requests" history={history} homeLink="Dashboard" />
                    <TableContainer
                        multiUploadButton={true}
                        multiDownloadButton={true}
                        multiTypeUpload={true}
                        multiTypeDownload={true}
                        multiUploadIcon="ri-upload-2-line"
                        multiDownloadIcon="ri-logout-box-r-line"
                        handleMultiUpload={handleMultiUpload}
                        handleMultiDownload={handleMultiDownload}
                        uploadTooltip="Bulk Upload Devices"
                        data={bulkUploads}
                        isGlobalFilter={true}
                        handleUpload={handleBulkUpload}
                        /*
                         * handleDownload={handleDownload}
                         * handleDeviceUpload={handleDeviceUpload}
                         */
                        handleClickView={handleClickView}
                        handleBulkDownload={handleBulkDownload}
                        columns={bulkUploadsSchema}
                        editor={priviliges.DEVICE_EDITOR}
                        reader={priviliges.DEVICE_READER}
                        searchFilter2={true}
                        pageNum={pageNumber}
                        customPageSize={10}
                        handleGlobalSearch={handleGlobalSearch}
                        onPageChange={onPageChange}
                        totalRecords={totalRecords}
                        searchPlaceHolder="Purpose/File Name"
                        className="custom-header-css"
                        tableHeader="All Bulk Requests"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <OffcanvasModal
                        direction="end"
                        toggle={toggle}
                        open={offcanvasModal}
                        hideSaveButton={true}
                        handleCloseClick={toggle}
                        OffcanvasModalID="bulkUploadModal"
                        handleOffcanvasBody={handleOffcanvasBody}
                        modalClassName={'w-40'}
                        offcanvasHeader="Show Bulk Upload"
                    />
                    <CommonModal
                        size={'md'}
                        cancelText={'Cancel'}
                        modalheader="Bulk Action Sample File Export"
                        show={showBulkModal}
                        disabled={!downloadType || !downloadType?.value || downloadType?.value === ''}
                        onCloseClick={() => setShowBulkModal(false)}
                        handleClick={() => handleBulkSampleDownload()}
                        handleModalBody={() => handleBulkDownloadModalBody()}
                    />
                    <CommonModal
                        size={'md'}
                        cancelText={'Cancel'}
                        modalheader={'Bulk Action Upload File'}
                        show={showBulkUploadModal}
                        disabled={
                            !uploadType ||
                            !uploadType?.value ||
                            uploadType?.value === '' ||
                            file?.length === 0 ||
                            (uploadType?.value === 'devices' ? false : !purpose || purpose === '')
                        }
                        onCloseClick={() => {
                            setUploadType('');
                            setShowBulkUploadModal(false);
                            setPurpose('');
                        }}
                        handleClick={() => handleUpload()}
                        handleModalBody={() => handleBulkUploadModalBody()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TVBulkRequests;
