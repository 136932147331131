/* eslint-disable max-lines */
import dns from '../../../assets/images/apple/svg/addPolicyCards/dnsProxy.svg';
import airPlay from '../../../assets/images/apple/svg/addPolicyCards/airPlay.svg';
import airPlaySecurity from '../../../assets/images/apple/svg/addPolicyCards/airPlaySecurity.svg';
import airPrint from '../../../assets/images/apple/svg/addPolicyCards/airPrinter.svg';
import calender from '../../../assets/images/apple/svg/addPolicyCards/calendar.svg';
import certificate from '../../../assets/images/apple/svg/addPolicyCards/certificateTranspare.svg';
import conferenceRoom from '../../../assets/images/apple/svg/addPolicyCards/conferenceRoom.svg';
import contacts from '../../../assets/images/apple/svg/addPolicyCards/contacts.svg';
import contentFilter from '../../../assets/images/apple/svg/addPolicyCards/contentFilter.svg';
import data from '../../../assets/images/apple/svg/addPolicyCards/data.svg';
import domain from '../../../assets/images/apple/svg/addPolicyCards/domains.svg';
import exchangeActiveSync from '../../../assets/images/apple/svg/addPolicyCards/exchangeActiveSync.svg';
import generalSettings from '../../../assets/images/apple/svg/addPolicyCards/generalSettings.svg';
import globalProxy from '../../../assets/images/apple/svg/addPolicyCards/globalHTTPProxy.svg';
import googleAccount from '../../../assets/images/apple/svg/addPolicyCards/googleAccount.svg';
import ldap from '../../../assets/images/apple/svg/addPolicyCards/ldap.svg';
import lockScreen from '../../../assets/images/apple/svg/addPolicyCards/lockScreen.svg';
import mail from '../../../assets/images/apple/svg/addPolicyCards/mail.svg';
import notification from '../../../assets/images/apple/svg/addPolicyCards/notification.svg';
import passcode from '../../../assets/images/apple/svg/addPolicyCards/passcode.svg';
import restrictions from '../../../assets/images/apple/svg/addPolicyCards/restrictions.svg';
import remote from '../../../assets/images/apple/svg/addPolicyCards/tvRemote.svg';
import webClip from '../../../assets/images/apple/svg/addPolicyCards/webClips.svg';
import wifi from '../../../assets/images/apple/svg/addPolicyCards/wifi.svg';
import Application from '../../../assets/images/apple/svg/addPolicyCards/application.svg';
import subscribedCalendar from '../../../assets/images/apple/svg/addPolicyCards/subscribedCalendar.svg';
import vpn from '../../../assets/images/apple/svg/addPolicyCards/vpn.svg';
import singleApp from '../../../assets/images/apple/svg/addPolicyCards/singleApp.svg';
import branding from '../../../assets/images/apple/svg/addPolicyCards/Branding.svg';
import Dock from '../../../assets/images/apple/svg/addPolicyCards/Dock.svg';
import Filevault from '../../../assets/images/apple/svg/addPolicyCards/Filevault.svg';
import kernelExtension from '../../../assets/images/apple/svg/addPolicyCards/kernelExtension.svg';
import timeMachine from '../../../assets/images/apple/svg/addPolicyCards/timeMachine.svg';
import timeZone from '../../../assets/images/apple/svg/addPolicyCards/timeZone.svg';
import systemExtension from '../../../assets/images/apple/svg/addPolicyCards/systemExtention.svg';
import mediaManagement from '../../../assets/images/apple/svg/addPolicyCards/mediaManagement.svg';
import energySaver from '../../../assets/images/apple/svg/addPolicyCards/energySaver.svg';
import fireWall from '../../../assets/images/apple/svg/addPolicyCards/fireWall.svg';
import fonts from '../../../assets/images/apple/svg/addPolicyCards/fonts.svg';

/*
 * Before moving on to modifying this page please note the below points
 * 1. saveKey is for handling duplicate keys in the object/schema if two fields have same value(key) our object will fail to handle it will take the last value in order to handle this we are going for saveKey.
 * Here saveKey is the key to be saved in the policy object if there is savekey in the schema or else value key will be saved.
 * 2. saveKey should not have _schemalabel extension only value key will have that extension.
 */

export const PoliciesSchema = [
    {
        label: 'Single App',
        image: singleApp,
        underDev: false,
        platforms: ['ios'],
        show: ['SA'],
        payloadIdentifier: 'com.example.myapplockpayload',
        payloadType: 'com.apple.app.lock',
        helpText:
            'Single App Mode, restricts user access to a single application, ensuring devices operate in a focused, purpose-driven mode. This feature is available on supervised iPhones, and iPads devices, preventing users from exiting the current app or launching other applications.',
        configured: false,
        isSingleApp: true,
        channel: 'DEVICE',
        fields: [
            {
                label: 'App',
                value: 'App',
                inputType: 'json',
                show: ['SA'],
                platforms: ['ios'],
                noDefaultVal: true,
                jsonFields: [
                    {
                        hideColumn: true,
                        helpText: 'Please select an app from the dropdown menu to be available on the device for the end user.',
                        value: 'Identifier',
                        inputType: 'dropdown',
                        noDefaultVal: true,
                        platforms: ['ios'],
                        show: ['SA'],
                        customOptions: true,
                        optionsKey: 'singleApps',
                        optionLabel: 'name',
                        // optionValue: 'packagename',
                        optionValue: 'adamid',
                        showBadge: true,
                        placeholder: 'Select App',
                        badgeKey: 'type',
                        badgeConditions: [
                            { value: 'STORE', label: 'Installed App' },
                            { value: 'default', label: 'System App' }
                        ]
                    },
                    {
                        label: 'Restrictions :',
                        inputType: 'heading',
                        platforms: ['ios'],
                        noDefaultVal: true,
                        show: ['SA'],
                        fieldClass: 'fw-medium text-info'
                    },
                    {
                        value: 'Options',
                        inputType: 'json',
                        platforms: ['ios'],
                        noDefaultVal: true,
                        show: ['SA'],
                        jsonFields: [
                            {
                                label: 'Disable Auto-Lock',
                                value: 'DisableAutoLock',
                                helpText: 'If true, the device doesn’t automatically go to sleep after an idle period.',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            },
                            {
                                label: 'Disable Touch',
                                value: 'DisableTouch',
                                helpText:
                                    'If true, the system disables the touch screen. In tvOS, it disables the touch surface on the Apple TV Remote.',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            },
                            {
                                label: 'Disable Screen Rotation',
                                value: 'DisableDeviceRotation',
                                helpText: 'If true, the system disables device rotation sensing.',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            },
                            {
                                label: 'Disable Volume Buttons',
                                value: 'DisableVolumeButtons',
                                helpText: 'If true, the system disables the volume buttons.',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            },
                            {
                                label: 'Disable Sleep Wake Button',
                                value: 'DisableSleepWakeButton',
                                helpText: 'If true, the system disables the sleep/wake button.',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            },
                            {
                                label: 'Disable Side Switch',
                                value: 'DisableRingerSwitch',
                                helpText:
                                    'If true, the system disables the ringer switch. When disabled, the ringer behavior depends on what position the switch was in when it was first disabled.',
                                inputType: 'checkbox',
                                fieldClass: 'mb-3',
                                platforms: ['ios'],
                                show: ['SA'],
                                defaultVal: false
                            },
                            {
                                label: 'Enable Assistive Touch',
                                value: 'EnableAssistiveTouch',
                                helpText: 'If true, the system enables AssistiveTouch.',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            },
                            {
                                label: 'Enable Invert Colors',
                                value: 'EnableInvertColors',
                                helpText: 'If true, the system enables Invert Colors. ',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            },
                            {
                                label: 'Enable Mono Audio',
                                value: 'EnableMonoAudio',
                                helpText: 'If true, the system enables Mono Audio.',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            },
                            {
                                label: 'Enable Speak Selection',
                                value: 'EnableSpeakSelection',
                                helpText: 'If true, the system enables Speak Selection.',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            },
                            {
                                label: 'Enable VoiceOver',
                                value: 'EnableVoiceOver',
                                helpText: 'If true, the system enables VoiceOver.',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            },
                            {
                                label: 'Enable Zoom',
                                value: 'EnableZoom',
                                helpText: 'If true, the system enables Zoom.',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            },
                            {
                                label: 'Enable Voice Control',
                                value: 'EnableVoiceControl',
                                helpText: 'If true, the system enables Voice Control.',
                                inputType: 'checkbox',
                                platforms: ['ios'],
                                show: ['SA'],
                                fieldClass: 'mb-3',
                                defaultVal: false
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        label: 'General Settings',
        image: generalSettings,
        underDev: false,
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        channel: 'device',
        payloadIdentifier: 'com.apple.generalSettings',
        payloadType: 'Configuration',
        helpText: 'Use this section to configure foundational settings and policies that are to be applied on managed devices',
        configured: false,
        fields: [
            {
                label: 'Name',
                value: 'PayloadDisplayName',
                colSize: true,
                noDefaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                helpText: 'Display name of the profile - will be shown on the device',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                maxLength: 50
            },
            /*
             * {
             *     label: 'Identifier',
             *     value: 'PayloadIdentifier',
             *     colSize: true,
             *     helpText:
             *         'Unique profile identifier - Installing the profile on device will Replace any installed profiles with the same identifier',
             *     inputType: 'policyInput',
             *     placeHolder: 'Enter',
             *     show: ['SA', 'MA'],
             *	   platforms: ['ios', 'macos'],
             *     maxLength: 50
             * },
             */
            {
                label: 'Description',
                value: 'PayloadDescription',
                colSize: true,
                noDefaultVal: true,
                helpText: 'Brief explanation of the contents or purpose of the profile',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 250
            },
            /*
             * {
             *     label: 'Organization',
             *     value: 'PayloadOrganization',
             *     colSize: true,
             *     helpText: 'Name of the Organization which created the profile',
             *     inputType: 'policyInput',
             *     placeHolder: 'Enter',
             *     show: ['SA', 'MA'],
             *     maxLength: 250
             * },
             */
            {
                label: 'Automatically Remove Profile',
                value: 'PayloadRemovalDisallowed',
                noDefaultVal: true,
                helpText:
                    'Supervised only. If present and set to true, the user cannot delete the profile (unless the profile has a removal password and the user provides it).',
                inputType: 'checkbox',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                fieldClass: 'fw-medium'
            }
        ]
    },
    {
        label: 'Restrictions',
        image: restrictions,
        underDev: false,
        payloadIdentifier: 'restrictions',
        payloadType: 'com.apple.applicationaccess',
        displayName: 'Restrictions',
        description: 'Restrictions',
        mandatory: true,
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        noConfigCheck: true,
        configured: false,
        channel: 'DEVICE',
        helpText: 'Use this section to impose restrictions on managed devices to prevent unauthorised access and misuse',
        fields: [
            {
                label: 'Restrictions',
                helpText: 'Use this section to restrict which apps, device functionally, and media content are available to the user',
                inputType: 'restrictions',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                configured: false,
                endAlign: true,
                fields: [
                    {
                        label: 'General Settings',
                        selected: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText:
                            'Configure this settings to manage users to personalize their user experience, manage device functionality and control system-wide settings',
                        fields: [
                            {
                                label: 'Force Automatic Date and Time',
                                helpText: 'Enabling this feature allows the system to set Date & Time automatically.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'forceAutomaticDateAndTime',
                                defaultVal: false,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Classroom to Lock to an App and Lock the Device Without Prompting',
                                helpText: 'Enabling this feature allows classroom to lock the app without prompting',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'forceClassroomUnpromptedAppAndDeviceLock',
                                defaultVal: false,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Enable Writing Tools',
                                helpText:
                                    'Enabling this feature allows users to access various writing tools for enhancing their text input and editing capabilities.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowWritingTools',
                                defaultVal: true,
                                badge: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos']
                            },
                            {
                                label: 'Enable Personalized Handwriting Results',
                                helpText:
                                    'Enabling this feature allows the system to provide tailored handwriting recognition results based on the users writing style.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowPersonalizedHandwritingResults',
                                defaultVal: true,
                                badge: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios']
                            },
                            {
                                label: 'Automatically Join Classroom Classess Without Prompting',
                                helpText:
                                    'Enabling this feature automatically gives permission to the teacher’s requests without prompting the student',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'forceClassroomAutomaticallyJoinClasses',
                                defaultVal: false,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Require Teacher Permission to Leave Classroom Unmanaged Classes',
                                helpText:
                                    'Enabling this feature allows student to request permission from the teacher to leave the classroom.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'forceClassroomRequestPermissionToLeaveClasses',
                                defaultVal: false,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Modifying Notification Settings',
                                helpText: 'Enabling this feature allows modifying notification settings',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowNotificationsModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Documents from Managed Sources in Unmanaged Destinations',
                                helpText: 'Enabling this feature allows documents moving from managed source to unmanaged source',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowOpenFromManagedToUnmanaged',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios']
                            },
                            {
                                label: 'Allow Documents from Unmanaged Sources in Managed Destinations',
                                helpText: 'Enabling this feature allows documents moving from unmanaged source to managed source',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowOpenFromUnmanagedToManaged',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios']
                            },
                            {
                                label: 'Allow OTAPKI Update',
                                helpText: 'Enabling this feature allows over-the -air PKI updates.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowOTAPKIUpdates',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios']
                            },
                            /*
                             * {
                             *     label: 'Allow Pairing with Non-Configurator Hosts',
                             *     helpText:'Enabling this feature allows pairing with non-Configurator hosts',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_PARING'
                             * },
                             */
                            {
                                label: 'Allow Putting into Recovery Mode from an Unpaired Device',
                                helpText: 'Enabling this feature allows unpaired devices to boot devices into recovery',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowUnpairedExternalBootToRecovery',
                                defaultVal: false,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            /*
                             * {
                             *     label: 'Allow Documents from Managed Sources in Unmanaged Destinations',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_DOCUMENTS_UNMANAGED',
                             *     badgeText: 'Supervised only'
                             * },
                             * {
                             *     label: 'Allow Documents from Unmanaged Sources in Managed Destinations',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_DOCUMENTS_MANAGED'
                             * },
                             */
                            {
                                label: 'Treat AirDrop as Unmanaged Destination',
                                helpText: 'Enabling this feature treats AirDrop as unmanaged destination',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'forceAirDropUnmanaged',
                                defaultVal: false
                            },
                            /*
                             * {
                             *     label: 'Allow Handoff',
                             * helpText:'Enabling this feature allows you can start work on one device, then switch to another nearby device and pick up where you left off',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_HAMDOFF',
                             *     badgeText: 'Supervised only'
                             * },
                             */
                            {
                                label: 'Allow Sending Diagnostic and Usage Data to Apple',
                                helpText: 'Enabling this feature automatically submits the diagnostic reports to Apple',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowDiagnosticSubmission',
                                defaultVal: true,
                                clear: ['allowDiagnosticSubmission'],
                                fields: [
                                    {
                                        label: 'Allow Modifying Diagnostic Settings',
                                        helpText:
                                            'Enabling this feature allows changing the diagnostic submission and app analytics settings in the Diagnostics & Usage UI in Settings.',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'allowDiagnosticSubmissionModification',
                                        defaultVal: true,
                                        inputType: 'checkbox',
                                        badge: true,
                                        enableOn: 'allowDiagnosticSubmission',
                                        badgeClass: 'warning',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios'],
                                        badgeText: 'Supervised only'
                                    }
                                ]
                            },
                            {
                                label: 'Allow Predictive Keyboard',
                                helpText: 'Enabling this feature allows predictive keyboard',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowPredictiveKeyboard',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Printer Sharing Modification',
                                helpText: 'Enabling this feature allows user to do modification on sharing printer settings',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowPrinterSharingModification',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            },
                            {
                                label: 'Allow Keyboard Shortcuts',
                                helpText: 'Enabling this feature allows keyboard shortcuts',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowKeyboardShortcuts',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Continous Path Keyboard',
                                helpText: 'Enabling this feature allows QuickPath keyboard',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowContinuousPathKeyboard',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Live Voice Mail',
                                helpText:
                                    'Enabling this feature allows you automatically see a real-time transcription as someone is leaving you a message.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowLiveVoicemail',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Local User Creation',
                                helpText: 'Enabling this feature allows to create local user',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowLocalUserCreation',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            },
                            {
                                label: 'Allow Definition Lookup',
                                helpText:
                                    'Enabling this feature allows users to tap and hold a selection to quickly access a dictionary definition of the chosen text.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowDefinitionLookup',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Device Name Modification',
                                helpText: 'Enabling this feature allows to Modify the Device Name',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowDeviceNameModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Auto Correction',
                                helpText: 'Enabling this feature allows auto correction',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowAutoCorrection',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Spell Check',
                                helpText: 'Enabling this feature allows keyboard spell check',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowSpellCheck',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Startup Disk Modification',
                                helpText: 'Enabling this feature allows to Modify Disk Utility',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowStartupDiskModification',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            },
                            /*
                             * {
                             *     label: 'Allow Screen Time',
                             * helpText:'Enabling this feature allows user to know how much time spend on apps and websites.',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_scREEN_TIME',
                             *     badge: true,
                             *     badgeClass: 'warning',
                             *     badgeText: 'Supervised only'
                             * },
                             */
                            {
                                label: 'Allow Personalized Ads Delivered by Apple',
                                helpText: 'Enabling this feature allows user to personalized Apple advertising',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowApplePersonalizedAdvertising',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Modifying Bluetooth Settings',
                                helpText: 'Enabling this feature allows modifying the bluetooth settings',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowBluetoothModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Sharing Bluetooth Modification',
                                helpText: 'Enabling this feature allows modifying the bluetooth Sharing',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowBluetoothSharingModification',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos']
                            }
                        ]
                    },
                    {
                        label: 'Password Settings',
                        selected: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText:
                            'Configure this settings to manage password-related aspects for ensuring security and protecting sensitive information from unauthorized access',
                        fields: [
                            {
                                label: 'Allow Password Autofill',
                                helpText: 'Enabling this feature allows autofilling the saved password in Safari or in apps',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowPasswordAutoFill',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Require Passcode on First Airplay Pairing',
                                helpText: 'Enabling this feature makes passcode mandatory on first AirPlay requests pairing to the device',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'forceAirPlayIncomingRequestsPairingPassword',
                                defaultVal: false
                            },
                            {
                                label: 'Require Passcode on Airplay Outgoing Request',
                                helpText: 'Enabling this feature allows requesting password from nearby devices',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'forceAirPlayOutgoingRequestsPairingPassword',
                                defaultVal: false
                            },
                            {
                                label: 'Allow Proximity Based Password Sharing Requests',
                                helpText: 'Enabling this feature allows requesting password from nearby devices',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowPasswordProximityRequests',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Password Sharing',
                                helpText: 'Enabling this feature allows sharing passwords with the Airdrop Passwords feature',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowPasswordSharing',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow AirPrint',
                                helpText: 'Enabling this feature allows AirPrint in the devices',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowAirPrint',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only',
                                clear: [
                                    'allowAirPrintiBeaconDiscovery',
                                    'allowAirPrintCredentialsStorage',
                                    'forceAirPrintTrustedTLSRequirement'
                                ],
                                fields: [
                                    {
                                        label: 'Allow Discovery of AirPrint Printers Using iBeacons',
                                        helpText:
                                            'Enabling this feature allows iBeacon discovering AirPrint printers, which prevents spurious AirPrint Bluetooth beacons from phishing for network traffic.',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'allowAirPrintiBeaconDiscovery',
                                        defaultVal: true,
                                        inputType: 'checkbox',
                                        badge: true,
                                        enableOn: 'allowAirPrint',
                                        badgeClass: 'warning',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios'],
                                        badgeText: 'Supervised only'
                                    },
                                    {
                                        label: 'Allow Storage of AirPrint Credentials in Keychain',
                                        helpText: 'Enabling this feature allows storage of user name and password for AirPrint in keychain',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'allowAirPrintCredentialsStorage',
                                        defaultVal: true,
                                        inputType: 'checkbox',
                                        badge: true,
                                        enableOn: 'allowAirPrint',
                                        badgeClass: 'warning',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios'],
                                        badgeText: 'Supervised only'
                                    },
                                    {
                                        label: 'Disallow Airprint to Destinations With Untrusted Certificates',
                                        helpText: 'This feature disables the AirPrint for untrusted certificates',
                                        inputType: 'checkbox',
                                        fieldClass: 'fw-medium',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios'],
                                        value: 'forceAirPrintTrustedTLSRequirement',
                                        defaultVal: false,
                                        enableOn: 'allowAirPrint',
                                        badge: true,
                                        badgeClass: 'warning',
                                        badgeText: 'SUpervised only'
                                    }
                                ]
                            },
                            {
                                label: 'Allow Erase All Content and Setings',
                                helpText: 'Enabling this feature allows user to Erase All Content and Settings option.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowEraseContentAndSettings',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Touch ID / Face ID to Unlock Device',
                                helpText: 'Enabling this feature allows Touch ID or Face ID from unlocking a device.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowFingerprintForUnlock',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Touch ID / Face ID Modification',
                                helpText: 'Enabling this feature allows Touch ID / Face ID Modification',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowFingerprintModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Enforced Touch ID/Face ID Timeout',
                                helpText: 'Enabling this feature enforces the user to set timeout Touch ID/Face ID',
                                value: 'enforcedFingerprintTimeout',
                                defaultVal: 172800,
                                inputType: 'policyInput',
                                type: 'number',
                                maxValue: 172800,
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos']
                            },
                            {
                                label: 'Require Touch ID / Face ID Authentication Before Autofill',
                                helpText: 'Enabling this feature allows Touch ID or Face ID authentication before Autofilling the saved',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'forceAuthenticationBeforeAutoFill',
                                defaultVal: false,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Modifying Passcode',
                                helpText: 'Enabling this feature allows modifying passcode',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowPasscodeModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Passbook While Locked',
                                helpText: 'Enabling this feature provides access to passbook while device is locked',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowPassbookWhileLocked',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios']
                            },
                            /*
                             * {
                             *     label: 'Allow Define',
                             * helpText:'Enabling this feature allows users to double-tap to search for a word’s definition',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_DEFINE',
                             *     badge: true,
                             *     badgeClass: 'warning',
                             *     badgeText: 'Supervised only'
                             * },
                             */
                            {
                                label: 'Allow Dictation',
                                helpText: 'Enabling this feature allows Dictation in the device',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowDictation',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Screen Time',
                                helpText: 'Enabling this feature allows user to know how much time spend on apps and websites.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowEnablingRestrictions',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            /*
                             * {
                             *     label: 'Allow Wallet Notification Center in Lock Screen',
                             * helpText:'Enabiling this feature, allows Wallet notification on lock screen',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_WALLET_NOTIFICATION'
                             * },
                             */
                            {
                                label: 'Show Control Center in Lock screen',
                                helpText: 'Enabiling this feature, allows Control Center to appear on the Lock screen',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowLockScreenControlCenter',
                                defaultVal: true
                            },
                            {
                                label: 'Show Notification Center in Lock screen',
                                helpText: 'Enabiling this feature, allows Notification center on the Lock screen',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowLockScreenNotificationsView',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Today View in Lock Screen',
                                helpText:
                                    'Enabiling this feature, allows swipe down to see Notification Center using the Today View in the Lock screen',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowLockScreenTodayView',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Mail Privacy Protection',
                                helpText:
                                    'Enable Mail Privacy Protection to increase your privacy by making it harder for senders to track your Mail activity.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowMailPrivacyProtection',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Managed Apps to Store Data in iCloud',
                                helpText: 'Enabling this device allows managed apps to store data in iCloud',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowManagedAppsCloudSync',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Managed to Write Unmanaged Contacts',
                                helpText: 'Enabling this feature allows access contacts from managed to unmanaged',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowManagedToWriteUnmanagedContacts',
                                defaultVal: false
                            },
                            {
                                label: 'Allow Market Place App Installation',
                                helpText: 'Enabling this feature allow user to install or update their apps from marketplace',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowMarketplaceAppInstallation',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            }
                        ]
                    },
                    {
                        label: 'Security Settings',
                        selected: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText: 'Configure this settings to protect devices, data and networks from various security threats',
                        fields: [
                            {
                                label: 'Allow Air Drop',
                                helpText: 'Enabling this feature allows Air drop in the device',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowAirDrop',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow iMessage',
                                helpText: 'Enabling this feature allows iMessage in the device',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowChat',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow USB Drive Access in Files App',
                                helpText: 'Enabling this feature allows connecting to any USB devices in the Files app',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowFilesUSBDriveAccess',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow USB Accessories While Devices is Locked',
                                helpText: 'Enabling this feature allows iOS devices to always connect to USB accessories while locked',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowUSBRestrictedMode',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Network Drive Access in Files App',
                                helpText: 'Enabling this feature allows connecting to any USB devices in the Files app',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowFilesNetworkDriveAccess',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Installing Configuration Profiles',
                                helpText: 'Enabling this feature allows installing configuration profiles and certificates',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowUIConfigurationProfileInstallation',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Universal Control',
                                helpText:
                                    'Universal Control allows each device to display its own screen and apps, while enabling seamless pointer movement, typing, and content copying between them using a single keyboard, mouse, or trackpad',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowUniversalControl',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            },
                            {
                                label: 'Allow Unmanaged to Read Managed Contacts',
                                helpText: 'Enabling this feature allows access contacts from unmanaged to managed',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowUnmanagedToReadManagedContacts',
                                defaultVal: false,
                                show: ['SA', 'MA'],
                                platforms: ['ios']
                            },
                            {
                                label: 'Allow Modifying Account Settings',
                                helpText:
                                    'Enabling this feature allows modifying accounts such as Apple IDs and Internet-based accounts such as Mail, Contacts, and Calendar',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowAccountModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Activity Continuation',
                                helpText:
                                    'Enabling this feature allows you can start work on one device, then switch to another nearby device and pick up where you left off',
                                value: 'allowActivityContinuation',
                                defaultVal: true,
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'info',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Unsupervised only'
                            },
                            {
                                label: 'Allow Find My Friends',
                                helpText: 'Enabling this feature allows Find My Friends in the Find My app',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowFindMyFriends',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Find My Devices',
                                helpText: 'Enabling this feature allows Find My devices in the Find My app',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowFindMyDevice',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Modifying Find My Friends Settings',
                                helpText: 'Enabling this feature allows changes to Find My app',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowFindMyFriendsModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            }
                            /*
                             * {
                             *     label: 'Allow Modifying Device Settings',
                             * helpText:'Enabling this feature allows modifying device settings',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_MODIFY-SETTINGS',
                             *     badge: true,
                             *     badgeClass: 'warning',
                             *     badgeText: 'Supervised only'
                             * },
                             * {
                             *     label: 'Allow Adding VPN Configurations',
                             * helpText:'Enabling this feature allows adding VPN configurations',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_VPN_CONFIG',
                             *     badge: true,
                             *     badgeClass: 'warning',
                             *     badgeText: 'Supervised only'
                             * },
                             * {
                             *     label: 'Allow Notes and Highlights Sync for Enterprise Books',
                             * helpText;'Enabling this feature allows notes and highlights sync for enterprise books',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_NOTES_HIGHLIGHTS'
                             * },
                             * {
                             *     label: 'Allow Shared Albums',
                             * helpText:'Enabling this feature allows sharing albums',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_SHARED_ALBUMS'
                             * },
                             * {
                             *     label: 'Allow Voice Dialing While Device is Locked (Depreceated in iOS 17)',
                             * helpText:'Enabling this feature allows voice dialing while device is locked',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     show: ['SA', 'MA'],
                             *     platforms: ['ios'],
                             *     value: 'ALLOW_VOICE_DAILING'
                             * },
                             * {
                             *     label: 'Require iTunes Store Password for All Purchases (Depriciated in iOS 17)',
                             * helpText:'Enabling this feature allows storing password for all purchases from iTunes',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     show: ['SA', 'MA'],
                             *  platforms: ['ios'],
                             *     value: 'REQUIRE_ITUNES_STORE'
                             * }
                             * {
                             *     label: 'Allow My Photo Stream (Disallowing Can Cause Data Loss; Depriciated in iOS 17)',
                             * helpText:'Enabling this feature allows photo streaming',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     show: ['SA', 'MA'],
                             *   platforms: ['ios'],
                             *     value: 'ALLOW_PHOTO_STREAM'
                             * }
                             */
                        ]
                    },
                    {
                        label: 'Network Settings',
                        selected: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText:
                            'Configure this settings to manage device"s network connections, troubleshoot connectivity issues, and optimize network performance',
                        fields: [
                            {
                                label: 'Allow Modifying Cellular Data App Settings',
                                helpText: 'Enabling this feature allows changing the settings for cellular data usage for apps',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowAppCellularDataModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow App Clip',
                                helpText:
                                    'Enabling this feature allows adding App Clips, and removes any existing App Clips on the device. Requires a supervised device.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowAppClips',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Modifying Cellular Plan Settings',
                                helpText: 'Enabling this feature allows changing settings related to their cellular plan ',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowCellularPlanModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Modifying eSim Settings',
                                helpText: 'Enabling this feature allows modifying eSIM settings',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowESIMModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Explicit Content',
                                helpText:
                                    'Enabling this feature prevent the playback of music with explicit content and films or TV programmes',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowExplicitContent',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow File Sharing Modification',
                                helpText: 'Enabling this feature allows modifying file sharing settings',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowFileSharingModification',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            },
                            {
                                label: 'Allow Modifying Personal Hotspot Settings',
                                helpText: 'Enabling this feature allows modifying Personal Hotspot settings',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowPersonalHotspotModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Force Wi-Fi Power On',
                                helpText: 'Enabling this feature turns on Wi-Fi and prevents turning off Wi-Fi in Settings',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'forceWiFiPowerOn',
                                defaultVal: false,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            /*
                             * {
                             *     label: 'Join Only Wi-Fi Network Installed by a Wi-Fi Payload ',
                             * helpText:'Enabling this feature allows to join the Wi-Fi networks installed by a Wi-Fi payload',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'JOIN_WI-FI NETWORK',
                             *     badge: true,
                             *     badgeClass: 'warning',
                             *     badgeText: 'Supervised only'
                             * },
                             */
                            {
                                label: 'Allow Setting up New Nearby Devices',
                                helpText: 'Enabling this feature shows prompt to set up new devices that are nearby',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowProximitySetupToNewDevice',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            }
                            /*
                             * {
                             *     label: 'Allow Automatic Sync While Roaming',
                             * helpText:'Enabling this feature allows automatic sync while roaming',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_AUTO_SYNC',
                             *     badge: true,
                             *     badgeClass: 'warning',
                             *     badgeText: 'Supervised only'
                             * }
                             */
                        ]
                    },
                    {
                        label: 'App Settings',
                        selected: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText: 'Configure the settings to manage and control the behavior of applications',
                        fields: [
                            {
                                label: 'Allow Use of iTunes Store',
                                helpText:
                                    'Enabling this feature provides access to the iTunes Music Store, allowing users to purchase and download content',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowiTunes',
                                default: true
                            },
                            {
                                label: 'Allow Use of News',
                                helpText: 'Enabling this feature provides access to News',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowNews',
                                defaultVal: true
                            },
                            {
                                label: 'Allow NFC',
                                helpText:
                                    'Near-field communication (NFC) enables wireless exchange of information between devices within close proximity, typically a few centimeters.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowNFC',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Use of Podcasts',
                                helpText: 'Enabling this feature provides access to podcast',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowPodcasts',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Use of Game Center',
                                helpText:
                                    'Enabling game center in your game helps players discover your game across their apple devices on app store',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowGameCenter',
                                defaultVal: true,
                                clear: ['allowMultiplayerGaming', 'allowAddingGameCenterFriends'],
                                fields: [
                                    {
                                        label: 'Allow Multiplayer Gaming',
                                        helpText: 'Enabling this feature provide access to multiple players.',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'allowMultiplayerGaming',
                                        defaultVal: true,
                                        inputType: 'checkbox',
                                        badge: true,
                                        enableOn: 'allowGameCenter',
                                        badgeClass: 'warning',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios', 'macos'],
                                        badgeText: 'Supervised only'
                                    },
                                    {
                                        label: 'Allow Adding Game Center Friends',
                                        helpText: 'Enabling this feature allows to send request to friends in game center',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'allowAddingGameCenterFriends',
                                        defaultVal: true,
                                        inputType: 'checkbox',
                                        badge: true,
                                        enableOn: 'allowGameCenter',
                                        badgeClass: 'warning',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios', 'macos'],
                                        badgeText: 'Supervised only'
                                    }
                                ]
                            },
                            {
                                label: 'Allow Automatic Sync While Roaming',
                                helpText: 'Enabling this feature allows automatic sync while roaming',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowGlobalBackgroundFetchWhenRoaming',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Host Pairing',
                                helpText: 'Enabling this feature control access to a device from a host computer',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowHostPairing',
                                defaultVal: true
                            },
                            {
                                label: 'Allow in App Purchase',
                                helpText: 'Enabling this feature provides access to iTunes, allowing users to purchase',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowInAppPurchases',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Internet Sharing Modification',
                                helpText: 'Enabling this feature allows modifying internet sharing settings through personal hotspot',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowInternetSharingModification',
                                defaultVal: true
                            },
                            {
                                label: 'Allow iPhone Widgets on Mac',
                                helpText: 'Enabling this feature allows changing the widgets on mac',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowiPhoneWidgetsOnMac',
                                defaultVal: true
                            },
                            {
                                label: 'Allow iTune File Sharing',
                                helpText:
                                    'Enabling this feature allows to copy files between your computer and apps on iOS or iPadOS using file sharing',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['macos'],
                                value: 'allowiTunesFileSharing',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Use of Safari',
                                helpText: 'Enabling this feature allows user to access website links through safari app',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowSafari',
                                defaultVal: true,
                                clear: [
                                    'safariAllowAutoFill',
                                    'safariForceFraudWarning',
                                    'safariAllowJavaScript',
                                    'safariAllowPopups',
                                    'allowSafari'
                                ],
                                fields: [
                                    {
                                        label: 'Enable Autofill',
                                        helpText: 'Enabling this feature automatically fills the saved information',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'safariAllowAutoFill',
                                        defaultVal: true,
                                        inputType: 'checkbox',
                                        badge: true,
                                        enableOn: 'allowSafari',
                                        badgeClass: 'warning',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios', 'macos'],
                                        badgeText: 'Supervised only'
                                    },
                                    {
                                        label: 'Force Fraud Warning',
                                        helpText: 'Enabling this feature warns you when a website that you are visiting is not secure.',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'safariForceFraudWarning',
                                        defaultVal: false,
                                        show: ['SA', 'MA'],
                                        platforms: ['ios'],
                                        inputType: 'checkbox',
                                        enableOn: 'allowSafari'
                                    },
                                    {
                                        label: 'Enable JavaScript',
                                        helpText: 'Enabling this feature controls the dynamic elements of the webpage',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'safariAllowJavaScript',
                                        defaultVal: true,
                                        show: ['SA', 'MA'],
                                        platforms: ['ios'],
                                        inputType: 'checkbox',
                                        enableOn: 'allowSafari'
                                    },
                                    {
                                        label: 'Enable Pop-Ups',
                                        helpText: ' Enabling this feature allow pop-up windows',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'safariAllowPopups',
                                        defaultVal: true,
                                        show: ['SA', 'MA'],
                                        platforms: ['ios'],
                                        enableOn: 'allowSafari',
                                        inputType: 'checkbox',
                                        badge: true,
                                        badgeClass: 'warning',
                                        badgeText: 'Supervised only'
                                    },

                                    {
                                        label: 'Safari Accept Cookies',
                                        value: 'safariAcceptCookies',
                                        defaultVal: 2,
                                        helpText: 'Controls if the safari can accept cookies or not',
                                        inputType: 'dropdown',
                                        placeHolder: 'Select',
                                        badge: true,
                                        badgeClass: 'warning',
                                        badgeText: 'SUpervised only',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios'],
                                        enableOn: 'allowSafari',
                                        options: [
                                            { label: 'Never', value: 0 },
                                            { label: 'From current website only', value: 1 },
                                            { label: 'From websites I visit', value: 1.5 },
                                            { label: 'Always', value: 2 }
                                        ]
                                    }
                                ]
                            },
                            /*
                             * {
                             *     label: 'Restrict App Usage',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'RESTRICT_APP_USAGE',
                             *     badge: true,
                             *     badgeClass: 'warning',
                             *     show: ['SA', 'MA'],
                             *   platforms: ['ios'],
                             *     badgeText: 'Supervised only',
                             *       fields: [
                             *           {
                             *               label: 'Allow Apps',
                             *               value: 'ALLOW_APPS',
                             *              helpText:'helptext',
                             *               inputType: 'dropdown',
                             *               placeHolder: 'Select',
                             *               options: [
                             *                   { label: 'Allow all apps', value: 'Allow all apps'},
                             *                   { label: 'Do not allow some apps', value: 'Do not allow some apps'},
                             *                   { label: 'Only sllow some apps', value: 'Only sllow some apps'}
                             *               ]
                             *           }
                             *       ]
                             * },
                             */
                            {
                                label: 'Allow Installing Apps Using App Store',
                                helpText: 'Enabling this feature allow user to install or update their apps through App Store',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowAppInstallation',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                clear: ['allowAutomaticAppDownloads'],
                                fields: [
                                    /*
                                     * {
                                     *     label: 'Allow Installing Apps Using App Store',
                                     * helpText:'Enabling this feature allow user to install or update their apps. This applies to App Store apps, marketplace apps, and locally installed apps.';
                                     *     fieldClass: 'fw-normal text-muted',
                                     *     value: 'ALLOW_APPS',
                                     *     inputType: 'checkbox',
                                     *     badge: true,
                                     *     badgeClass: 'warning',
                                     *     badgeText: 'Supervised only'
                                     * },
                                     */
                                    {
                                        label: 'Allow Automatic App Downloads',
                                        helpText:
                                            'Enabling this feature allow user to automatically download the apps purchased on other devices. ',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'allowAutomaticAppDownloads',
                                        defaultVal: true,
                                        inputType: 'checkbox',
                                        badge: true,
                                        enableOn: 'allowAppInstallation',
                                        badgeClass: 'warning',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios'],
                                        badgeText: 'Supervised only'
                                    }
                                ]
                            },
                            {
                                label: 'Allow Automatic Screen Saver',
                                helpText: 'Enabling this feature allows change in screen saver by setting a frequency',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['tvos'],
                                value: 'allowAutomaticScreenSaver',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Auto Unlock',
                                helpText: 'Enabling this feature, auto unlock the device through face ID & Apple watch',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowAutoUnlock',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Removing Apps',
                                helpText:
                                    'Enabling this feature allow user to remove apps from iOS device. This applies to App Store apps, marketplace apps, and locally installed apps.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowAppRemoval',
                                defaultVal: true
                            },
                            {
                                label: 'Allow ARD Remote Management Modification',
                                helpText:
                                    'Enabling this feature allows modifying the remote management features like restricting access to specific users to actions',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['macos'],
                                value: 'allowARDRemoteManagementModification',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Removing System Apps',
                                helpText: 'Enabling this feature allow user to remove system apps from the device.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowSystemAppRemoval',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Time Machine Backup',
                                helpText:
                                    'If you have a USB drive or other external storage device, you can use Time Machine to automatically back up your files, including your apps, music, photos, emails and documents.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowTimeMachineBackup',
                                defaultVal: true
                            },
                            {
                                label: 'Allow UI App Installation',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowUIAppInstallation',
                                defaultVal: true,
                                helpText:
                                    'If false, the system disables the App Store, and the systems removes its icon from the Home screen. However, users can continue to use host apps such as iTunes or Configurator to install or update their apps. In iOS 10 and later, MDM commands can override this restriction. Requires a supervised device. Available in iOS 9 and later, and watchOS 10 and later.'
                            },
                            /*
                             * {
                             *     label: 'Allow App Clips ',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     badge: true,
                             *     badgeClass: 'warning',
                             *     badgeText: 'Supervised only',
                             *     value: 'ALLOW_APP_CLIPS'
                             * },
                             * {
                             *     label: 'Allow In-App Purchase',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_APP_PURCHASE'
                             * },
                             * {
                             *     label: 'Allow Apps to Request to Track',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_APPS_REQUEST'
                             * },
                             */
                            {
                                label: 'Allow Trusting New Enterprise App Authors',
                                helpText: 'Enabling this feature allow user to provisioning apps by universal provisioning profiles',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowEnterpriseAppTrust',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Enterprise Book Backup',
                                helpText: 'Enabling this device allows backup of enterprise books',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowEnterpriseBookBackup',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Notes and Highlights Sync for Enterprise Books',
                                helpText:
                                    'Enabling this feature provide access to books and audiobooks in the Books app on other devices such as your iPad and Mac.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowEnterpriseBookMetadataSync',
                                defaultVal: true
                            }
                        ]
                    },
                    {
                        label: 'Media Settings',
                        selected: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText: 'Configure the settings to manage and control media content and related functionality',
                        fields: [
                            {
                                label: 'Rating Region - Drop Down with Countries',
                                helpText: 'Enabling this feature allow user to provide rating for the given region',
                                value: 'ratingRegion',
                                noDefaultVal: true,
                                inputType: 'dropdown',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                fieldClass: 'fw-medium',
                                options: [
                                    { label: 'Australia', value: 'au' },
                                    { label: 'Canada', value: 'ca' },
                                    { label: 'Denmark', value: 'de' },
                                    { label: 'France', value: 'fr' },
                                    { label: 'Ireland', value: 'ie' },
                                    { label: 'Japan', value: 'jp' },
                                    { label: 'New Zealand', value: 'nz' },
                                    { label: 'United Kingdom', value: 'gb' },
                                    { label: 'United Stated', value: 'us' }
                                ]
                            },
                            {
                                label: 'Rating Movies',
                                value: 'ratingMovies',
                                defaultVal: 1000,
                                helpText:
                                    'The maximum level of movie content allowed on the device. Available in iOS 4 and later, and tvOS 11.3 and later',
                                inputType: 'dropdown',
                                placeHolder: 'Select',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                options: [
                                    { label: 'Dont Allow Movies', value: 0 },
                                    { label: 'G', value: 100 },
                                    { label: 'PG', value: 200 },
                                    { label: 'PG-13', value: 300 },
                                    { label: 'R', value: 400 },
                                    { label: 'NC-17', value: 500 },
                                    { label: 'Allow All Movies', value: 1000 }
                                ]
                            },
                            {
                                label: 'TV Shows',
                                value: 'ratingTVShows',
                                defaultVal: 1000,
                                helpText: 'The maximum level of TV show content allowed on the device.',
                                inputType: 'dropdown',
                                placeHolder: 'Select',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                options: [
                                    { label: 'Dont Allow TV Shows', value: 0 },
                                    { label: 'TV-Y', value: 100 },
                                    { label: 'TV-Y7', value: 200 },
                                    { label: 'TV-G', value: 300 },
                                    { label: 'TV-PG', value: 400 },
                                    { label: 'TV-14', value: 500 },
                                    { label: 'TV-MA', value: 600 },
                                    { label: 'Allow All TV Shows', value: 1000 }
                                ]
                            },
                            {
                                label: 'Apps',
                                value: 'ratingApps',
                                defaultVal: 1000,
                                helpText:
                                    'The maximum level of app content allowed on the device. Preinstalled (first party) apps ignore this restriction. Available in iOS 4 and later, and tvOS 11.3 and later. Support for this restriction on unsupervised devices is deprecated.',
                                inputType: 'dropdown',
                                placeHolder: 'Select',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                options: [
                                    { label: 'Dont Allow Apps', value: 0 },
                                    { label: '4+', value: 100 },
                                    { label: '9+', value: 200 },
                                    { label: '12+', value: 300 },
                                    { label: '17+', value: 600 },
                                    { label: 'Allow All Apps', value: 1000 }
                                ]
                            },
                            /*
                             * {
                             *     label: 'Allow Playbook of Explict Music, Podacats & iTunes U Media',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_MUSIC_MEDIA',
                             *     badge: true,
                             *     badgeClass: 'warning',
                             *     badgeText: 'Supervised only'
                             * },
                             * {
                             *     label: 'Allow Explict Sexual Content in Apple Books',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_SEXUAL_CONTENT'
                             * },
                             */
                            {
                                label: 'Require Managed Paste Board',
                                helpText: 'Enabling this feature allows Clipboard pasting ',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'requireManagedPasteboard',
                                defaultVal: false,
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos']
                            },
                            {
                                label: 'Allow Modifying Wallpaper',
                                helpText: 'Enabling this feature allow users to modify the wallpaper',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowWallpaperModification',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Apple Music',
                                helpText: 'Enabling this feature allows user to access Apple Music service.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowMusicService',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Radio',
                                helpText: 'Enabling this feature allows user to access Apple Music radio.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowRadioService',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Rapid Security Response Installation',
                                helpText: 'Enabling this allows the New type of software release for iOS, to fix security issues',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowRapidSecurityResponseInstallation',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos']
                            },
                            {
                                label: 'Allow Rapid Security Response Removal',
                                helpText:
                                    'Allowing this allows the user to remove rapid security response, it removes all the responses applied since the last software update',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowRapidSecurityResponseRemoval',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos']
                            },
                            {
                                label: 'Allow Remote Apple Events Modification',
                                helpText:
                                    'Remote apple events allows applications on other MACos/computer to send apple events to this computer ',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowRemoteAppleEventsModification',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            },
                            {
                                label: 'Allow Use of Camera',
                                helpText: 'Enabling this feature allows user to access camera in iOS device',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowCamera',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                clear: ['allowVideoConferencing'],
                                fields: [
                                    {
                                        label: 'Allow Face Time',
                                        helpText: 'Enabling this feature allows user to access Facetime.',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'allowVideoConferencing',
                                        defaultVal: true,
                                        show: ['SA', 'MA'],
                                        platforms: ['ios', 'macos'],
                                        badge: true,
                                        badgeClass: 'warning',
                                        badgeText: 'Supervised only',
                                        enableOn: 'allowCamera',
                                        inputType: 'checkbox'
                                    }
                                ]
                            },
                            {
                                label: 'Allow Adding VPN Configuration',
                                helpText: 'Enabling this allows user to add VPN configuration',
                                value: 'allowVPNCreation',
                                defaultVal: true,
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Screenshots and Screen Recording',
                                helpText:
                                    'Enabling this feature allows the user to take screenshot of the display and capturing a screen recording.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowScreenShot',
                                defaultVal: true,
                                clear: ['allowRemoteScreenObservation', 'forceClassroomUnpromptedScreenObservation'],
                                fields: [
                                    {
                                        label: 'Allow AirPlay, View Screen by Classroom, and Screen Sharing',
                                        helpText: 'Enabling this feature allows remote screen observation by the Classroom app',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'allowRemoteScreenObservation',
                                        defaultVal: true,
                                        show: ['SA', 'MA'],
                                        platforms: ['ios', 'macos'],
                                        enableOn: 'allowScreenShot',
                                        inputType: 'checkbox'
                                    },
                                    {
                                        label: 'Allow Classroom to Perform AirPlay and View Screen Without Prompting',
                                        helpText:
                                            'Enabling this feature allows automatic permission to that course teacher’s requests to observe the student’s screen without prompting the student',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'forceClassroomUnpromptedScreenObservation',
                                        defaultVal: false,
                                        inputType: 'checkbox',
                                        badge: true,
                                        badgeClass: 'warning',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios', 'macos'],
                                        enableOn: 'allowScreenShot',
                                        badgeText: 'Supervised only'
                                    }
                                ]
                            },
                            {
                                label: 'Force Delayed App Software Updates',
                                helpText: 'Enables this feature delays app software updates upto 30 days',
                                value: 'forceDelayedAppSoftwareUpdates',
                                defaultVal: false,
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Force Delayed Major Software Updates',
                                helpText: 'Enables this feature delays the major software updates between 1and 90 days',
                                value: 'forceDelayedMajorSoftwareUpdates',
                                defaultVal: false,
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos']
                            },
                            {
                                label: 'Force Delayed Software Updates',
                                helpText: 'Enables this feature delays OS update between 1-30 days',
                                value: 'forceDelayedSoftwareUpdates',
                                defaultVal: false,
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Shared Albums',
                                helpText: 'In the photos app, user can create albums to share photos and videos',
                                value: 'allowSharedStream',
                                defaultVal: true,
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Enable Image Playground',
                                helpText:
                                    'Enabling this feature allows users to access the image playground functionality for creating and manipulating images.',
                                value: 'allowImagePlayground',
                                defaultVal: true,
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badge: true
                            },
                            {
                                label: 'Enable iPhone Mirroring',
                                helpText:
                                    'Enabling this feature allows users to mirror their iPhone display on a compatible device or screen.',
                                value: 'allowiPhoneMirroring',
                                defaultVal: true,
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos']
                            },
                            {
                                label: 'Allow Apple Books',
                                helpText: 'Enabling this feature allows the Book Store tab from the Books app',
                                value: 'allowBookstore',
                                defaultVal: true,
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow Apple Books Erotica',
                                value: 'allowBookstoreErotica',
                                defaultVal: true,
                                helpText: 'Allow Apple Books Erotica',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Enable Generative Emoji',
                                value: 'allowGenmoji',
                                defaultVal: true,
                                helpText: 'Enabling this feature allows the use of generative emoji in the system',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios']
                            },
                            {
                                label: 'Enable Image Wand',
                                value: 'allowImageWand',
                                defaultVal: true,
                                helpText:
                                    'Enabling this feature allows users to utilize the image wand tool for selecting and editing images',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios']
                            }
                        ]
                    },
                    {
                        label: 'iCloud Settings',
                        selected: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText: 'Configure this settings to manage the users iCloud account and its associated features on Apple devices',
                        fields: [
                            {
                                label: 'Allow iCloud Address Book',
                                helpText: 'Enabling this sync the Contacts to iCloud',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowCloudAddressBook',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            },
                            {
                                label: 'Allow iCloud Bookmarks',
                                helpText: 'Accessing website bookmarks(iCloud stores websites bookmarks)',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['macos'],
                                value: 'allowCloudBookmarks',
                                defaultVal: true
                            },
                            {
                                label: 'Allow iCloud Calendar',
                                helpText: 'Enabling this sync the Calender to iCloud',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['macos'],
                                value: 'allowCloudCalendar',
                                defaultVal: true
                            },
                            {
                                label: 'Allow iCloud Desktop & Documents',
                                helpText: 'Enabling this will sync Desktop and documents to icloud',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['macos'],
                                value: 'allowCloudDesktopAndDocuments',
                                defaultVal: true
                            },
                            {
                                label: 'Allow iCloud Backup',
                                helpText: 'Enabling this device allows backing up the device to iCloud',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowCloudBackup',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow iCloud Documents & Data',
                                helpText: 'Enabling this device allows document and key-value syncing to iCloud',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowCloudDocumentSync',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow iCloud Freeform',
                                helpText: 'Enabling this feature allows user to use icloud with freeform application.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowCloudFreeform',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            },
                            {
                                label: 'Allow iCloud Keychain',
                                helpText: 'Enabling this device allows iCloud keychain synchronization.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowCloudKeychainSync',
                                defaultVal: true
                            },
                            {
                                label: 'Allow iCloud Mail',
                                helpText: 'Enabling this sync the mail to iCloud',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['macos'],
                                value: 'allowCloudMail',
                                defaultVal: true
                            },
                            {
                                label: 'Allow iCloud Notes',
                                helpText: 'Enabling this sync the notes to iCloud',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['macos'],
                                value: 'allowCloudNotes',
                                defaultVal: true
                            },
                            /*
                             * {
                             *     label: 'Allow Managed Apps to Store Data in iCloud',
                             * helpText:'Enabling this device allows managed apps to store data in iCloud',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     show: ['SA', 'MA'],
                             *  platforms: ['ios'],
                             *     value: 'ALLOW_MANAGE_APPS'
                             * },
                             * {
                             *     label: 'Allow Backup of Enterprise Books',
                             * helpText:'Enabling this device allows backup of enterprise books',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     show: ['SA', 'MA'],
                             *   platforms: ['ios'],
                             *     value: 'ALLOW_BACKUP'
                             * },
                             */
                            {
                                label: 'Allow iCloud Photos',
                                helpText: 'Enabling this feature allows backing up photos in iCloud Photo Library',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowCloudPhotoLibrary',
                                defaultVal: true
                            },
                            {
                                label: 'Allow iCloud Private Relay',
                                helpText:
                                    'Enabling this, the Private relay to hides the IP address and browsing activity in safari and keeps the internet activity private.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                value: 'allowCloudPrivateRelay',
                                defaultVal: true,
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Allow iCloud Reminders',
                                helpText:
                                    'Enabling this aalows the user to create and manage to-do items and organizing in lists using remainders',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['macos'],
                                value: 'allowCloudReminders',
                                defaultVal: true
                            },
                            {
                                label: 'Allow Content Caching',
                                helpText:
                                    'A service in macOS speeds up downloading Apple software and iCloud data by saving content that local Apple devices have already downloaded.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['macos'],
                                value: 'allowContentCaching',
                                defaultVal: true
                            },
                            {
                                label: 'Force Encrypted Backups',
                                helpText: 'Enabling this feature encrypts all backups.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'forceEncryptedBackup',
                                defaultVal: false
                            },
                            {
                                label: 'Allow Apps to Request to Track',
                                helpText: 'Enabling this feature allows the app to track the activity',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'forceLimitAdTracking',
                                defaultVal: false
                            },
                            {
                                label: 'Force on Device only Dictation',
                                helpText:
                                    'This feature enables that dictation is processed entirely on the device, without sending any data to Apple servers.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['macos'],
                                value: 'forceOnDeviceOnlyDictation',
                                defaultVal: false
                            },
                            {
                                label: 'Force on Device only Translation',
                                helpText:
                                    'This feature enables that translation is processed entirely on the device, without sending any data to Apples servers.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'forceOnDeviceOnlyTranslation',
                                defaultVal: false
                            },
                            {
                                label: 'Force Preserve ESIM on Erase',
                                helpText:
                                    'This feature enables that  that when a device is erased, the eSIM information is retained and not deleted.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'forcePreserveESIMOnErase',
                                defaultVal: false
                            }
                        ]
                    },
                    {
                        label: 'Siri Settings',
                        selected: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText: 'Configure this settings to manage and control Siri functionality and customise its behaviour',
                        fields: [
                            {
                                label: 'Allow Siri',
                                helpText: 'Enabling this feature allows user to use Siri',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                value: 'allowAssistant',
                                defaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                clear: [
                                    'allowAssistantWhileLocked',
                                    'forceAssistantProfanityFilter',
                                    'allowAssistantUserGeneratedContent',
                                    'allowSpotlightInternetResults'
                                ],
                                fields: [
                                    {
                                        label: 'Allow Siri While Device is Locked',
                                        helpText: 'Enabling this feature allows user to use Siri when the device is in locked state',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'allowAssistantWhileLocked',
                                        defaultVal: true,
                                        show: ['SA', 'MA'],
                                        platforms: ['ios', 'macos'],
                                        inputType: 'checkbox',
                                        enableOn: 'allowAssistant'
                                    },
                                    {
                                        label: 'Enable Siri Profanity Filter',
                                        helpText: 'Enabling this feature blocks the swear words.',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'forceAssistantProfanityFilter',
                                        defaultVal: false,
                                        inputType: 'checkbox',
                                        badge: true,
                                        badgeClass: 'warning',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios', 'macos'],
                                        badgeText: 'Supervised only',
                                        enableOn: 'allowAssistant'
                                    },
                                    {
                                        label: 'Show User-Generated Content in Siri',
                                        helpText: 'Enabling this feature allows querying user-generated content from the web',
                                        fieldClass: 'fw-normal text-muted',
                                        value: 'allowAssistantUserGeneratedContent',
                                        defaultVal: true,
                                        inputType: 'checkbox',
                                        badge: true,
                                        badgeClass: 'warning',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios'],
                                        badgeText: 'Supervised only',
                                        enableOn: 'allowAssistant'
                                    },
                                    {
                                        label: 'Allow Siri Suggestions',
                                        value: 'allowSpotlightInternetResults',
                                        defaultVal: true,
                                        helpText:
                                            'if false, the system disables Spotlight Internet search results in Siri Suggestions. Available in iOS 8 and later, and macOS 10.11 and later. Support for this restriction on unsupervised devices is deprecated.',
                                        inputType: 'checkbox',
                                        badge: true,
                                        badgeClass: 'warning',
                                        badgeText: 'Supervised only',
                                        show: ['SA', 'MA'],
                                        platforms: ['ios', 'macos'],
                                        enableOn: 'allowAssistant',
                                        fieldClass: 'fw-normal text-muted'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Software Update',
                        selected: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText: '',
                        fields: [
                            {
                                label: 'Defer Software Updates (Days)',
                                helpText:
                                    'This feature enables the admin to delay the installation of software updates on devices for a specified number of days',
                                value: 'enforcedSoftwareUpdateDelay',
                                defaultVal: 30,
                                inputType: 'policyInput',
                                type: 'number',
                                maxValue: 90,
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                badge: true,
                                badgeClass: 'warning',
                                badgeText: 'Supervised only'
                            },
                            {
                                label: 'Enforced Software Update Major OS Deferred Install Delay',
                                helpText: 'This feature enables admin to enforce a delay on the installation of major OS updates.',
                                value: 'enforcedSoftwareUpdateMajorOSDeferredInstallDelay',
                                defaultVal: 30,
                                inputType: 'policyInput',
                                type: 'number',
                                maxValue: 90,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            },
                            {
                                label: 'Enforced Software Update Minor OS Deferred Install Delay',
                                helpText: 'This feature enables admin to enforce a delay on the installation of minor OS updates',
                                value: 'enforcedSoftwareUpdateMinorOSDeferredInstallDelay',
                                defaultVal: 30,
                                inputType: 'policyInput',
                                type: 'number',
                                maxValue: 90,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            },
                            {
                                label: 'Enforced Software Update Non OS Deferred Install Delay',
                                helpText:
                                    'This feature enables admin to enforce a delay on the installation of non-operating system software updates.',
                                value: 'enforcedSoftwareUpdateNonOSDeferredInstallDelay',
                                defaultVal: 30,
                                inputType: 'policyInput',
                                type: 'number',
                                maxValue: 90,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            }
                        ]
                    },
                    {
                        label: 'Certificate Settings',
                        selected: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText:
                            'Configure this settings to manage certificates to secure communication, authenticate users and devices and enforce security policies',

                        fields: [
                            {
                                label: 'Allow Users to Accept Untrusted TLS Certificates',
                                helpText: 'Enabling this feature allows untrusted HTTPS certificates.',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'allowUntrustedTLSPrompt',
                                defaultVal: true
                            }
                            /*
                             * {
                             *     label: 'Allow Automatic Updates to Certificate Trust Settings',
                             *     helpText:'Enabling this feature allows automatic updates to certificate without a prompt to user.',
                             *     inputType: 'checkbox',
                             *     fieldClass: 'fw-medium',
                             *     value: 'ALLOW_TRUST_CERTI',
                             *     badge: true,
                             *     badgeClass: 'warning',
                             *     badgeText: 'Supervised only'
                             * }
                             */
                        ]
                    }
                    /*
                     * {
                     *     label: 'Apple Watch',
                     *     selected: true,
                     *     helpText: 'Configure this settings to manage wearable smartwatches that integrate with iOS devices',
                     *     fields: [
                     *         {
                     *             label: 'Force Apple Watch Wrist Detection',
                     *             helpText:
                     *                 'Enabiling this feature locks the Apple Watch automatically when it’s removed from the user’s wrist.',
                     *             inputType: 'checkbox',
                     *             fieldClass: 'fw-medium',
                     *             show: ['SA', 'MA'],
                     *           platforms: ['ios'],
                     *             value: 'forceWatchWristDetection'
                     *         },
                     *         {
                     *             label: 'Join Only Wi-Fi Networks Installed by a Wi-Fi Payload',
                     *             helpText: 'Join Only Wi-Fi Networks Installed by a Wi-Fi Payload',
                     *             inputType: 'checkbox',
                     *             fieldClass: 'fw-medium',
                     *             show: ['SA', 'MA'],
                     *           platforms: ['ios'],
                     *             badge: true,
                     *             badgeClass: 'warning',
                     *             badgeText: 'Supervised only',
                     *             value: 'forceWiFiToAllowedNetworksOnly'
                     *         },
                     *         {
                     *             label: 'Allow Pairing with Apple Watch',
                     *             helpText: 'Enabling this feature allows pairing the Apple watch with supervised devices',
                     *             inputType: 'checkbox',
                     *             fieldClass: 'fw-medium',
                     *             value: 'allowPairedWatch',
                     *             badge: true,
                     *             badgeClass: 'warning',
                     *             show: ['SA', 'MA'],
                     *           platforms: ['ios'],
                     *             badgeText: 'Supervised only'
                     *         }
                     *     ]
                     * }
                     */
                    /*
                     * {
                     *     label: 'Apple TV',
                     *     selected: true,
                     *     helpText:
                     *         'Configure this settings to stream digital content to a television set or other compatible display device',
                     *     fields: [
                     *         {
                     *             label: 'Allow Pairing with Remote App (tvOS Only)',
                     *             inputType: 'checkbox',
                     *             fieldClass: 'fw-medium',
                     *             show: ['SA', 'MA'],
                     *           platforms: ['ios'],
                     *             value: 'allowRemoteAppPairing',
                     *             badge: true,
                     *             badgeClass: 'warning',
                     *             badgeText: 'Supervised only'
                     *         },
                     *         {
                     *             label: 'Allow Incoming AirPlay Requests (tvOS Only)',
                     *             inputType: 'checkbox',
                     *             fieldClass: 'fw-medium',
                     *             show: ['SA', 'MA'],
                     *             platforms: ['ios','macos'],
                     *             value: 'allowAirPlayIncomingRequests',
                     *             badge: true,
                     *             badgeClass: 'warning',
                     *             badgeText: 'Supervised only'
                     *         },
                     *         {
                     *             label: 'Allow Device Sleep (tvOS Only)',
                     *             value: 'allowDeviceSleep',
                     *             inputType: 'checkbox',
                     *             show: ['SA', 'MA'],
                     *           platforms: ['ios'],
                     *             fieldClass: 'fw-medium',
                     *             badge: true,
                     *             badgeClass: 'warning',
                     *             badgeText: 'Supervised only'
                     *         },
                     *     ]
                     * }
                     */
                ]
            }
        ]
    },
    {
        label: 'Domain',
        image: domain,
        payloadIdentifier: 'com.example.mysafaridomainspayload',
        payloadType: 'com.apple.domains',
        configured: false,
        underDev: true,
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        displayName: 'Domain',
        description: 'Domain',
        channel: 'USER',
        helpText:
            'Use this section to manage website access permissions on user devices, allowing or blocking browsing to specific websites as needed',
        fields: [
            {
                label: 'Unmarked Email Domains',
                value: 'EmailDomains',
                val: 'EmailDomain',
                helpText: 'Enter the list of email domains.',
                inputType: 'tableInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100,
                noDefaultVal: true
            },
            {
                label: 'Managed Safari Web Domains',
                value: 'WebDomains',
                val: 'WebDomain',
                helpText: 'Specify a list of email domains that have not been marked as managed',
                inputType: 'tableInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 100,
                noDefaultVal: true
            },
            {
                label: 'Safari Password Autofill Domains(Supervised only)',
                value: 'SafariPasswordAutoFillDomains',
                val: 'SafariPasswordAutoFillDomain',
                helpText:
                    'Specify a list of domains for which Safaris autofill feature is allowed to automatically populate saved passwords',
                inputType: 'tableInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 100,
                noDefaultVal: true
            },
            {
                label: 'Cross Site Tracking Prevention Relaxed Domains',
                value: 'CrossSiteTrackingPreventionRelaxedDomains',
                val: 'CrossSiteTrackingPreventionRelaxedDomain',
                helpText: 'Enter the list of urls that need to be allowed.',
                inputType: 'tableInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                max: 10,
                maxLength: 100,
                noDefaultVal: true
            }
        ]
    },
    {
        label: 'Global HTTP Proxy',
        image: globalProxy,
        underDev: true,
        payloadIdentifier: 'com.example.myglobalhttpproxypayload',
        payloadType: 'com.apple.proxy.http.global',
        displayName: 'Global HTTP Proxy',
        description: 'Global HTTP Proxy',
        show: ['SA', 'MA'],
        channel: 'DEVICE',
        platforms: ['ios', 'macos'],
        helpText: 'Use this section to configure and manage proxy server settings for network traffic',
        configured: false,
        fields: [
            {
                label: 'Proxy Type',
                value: 'ProxyType_http',
                saveKey: 'ProxyType',
                defaultVal: 'Manual',
                helpText:
                    'Select the type of proxy from the Dropdown Auto -Proxy PAC URL - Specify the URLs used to retrieve proxy settings',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                options: [
                    { label: 'Manual', value: 'Manual', optionsField: 'Manual' },
                    { label: 'Auto', value: 'Auto', optionsField: 'Auto' }
                ],
                clearFields: [
                    { key: 'ProxyServer_Global_http', value: '' },
                    { key: 'ProxyServerPort_Global_http', value: '' },
                    { key: 'ProxyUsername_Global_HTTP_Proxy', value: '' },
                    { key: 'ProxyPassword_Global_HTTP_Proxy', value: '' },
                    { key: 'ProxyCaptiveLoginAllowed_Manual', value: false },
                    { key: 'ProxyPACURL_Global_HTTP_Proxy', value: '' },
                    { key: 'ProxyPACFallbackAllowed_Global_HTTP_Proxy', value: false },
                    { key: 'ProxyCaptiveLoginAllowed_Auto', value: false }
                ],
                Manual: [
                    {
                        label: 'Proxy Server and Port',
                        helpText: 'Enter the proxy server details..',
                        inputType: 'multiInput',
                        noDefaultVal: true,
                        seperation: ':',
                        inputs: [
                            {
                                class: 'widthPer-80',
                                type: 'text',
                                value: 'ProxyServer_Global_http',
                                inputType: 'policyInput',
                                noDefaultVal: true,
                                placeHolder: 'Proxy Server Host',
                                saveKey: 'ProxyServer'
                            },
                            {
                                class: 'widthPer-20',
                                type: 'number',
                                value: 'ProxyServerPort_Global_http',
                                inputType: 'policyInput',
                                placeHolder: 'Port',
                                saveKey: 'ProxyServerPort',
                                noDefaultVal: true,
                                minValue: 0,
                                maxValue: 65535
                            }
                        ],
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 50
                    },
                    {
                        label: 'User Name',
                        value: 'ProxyUsername_Global_HTTP_Proxy',
                        saveKey: 'ProxyUsername',
                        noDefaultVal: true,
                        helpText: 'Enter the user name to connect to the proxy',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 50
                    },
                    {
                        label: 'Password',
                        value: 'ProxyPassword_Global_HTTP_Proxy',
                        saveKey: 'ProxyPassword',
                        type: 'password',
                        noDefaultVal: true,
                        helpText: 'Enter the password to authenticate with the proxy',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 50
                    },
                    {
                        label: 'Allow Bypassing proxy to Access Captive Networks',
                        helpText: 'This feature enables that devices can connect to captive networks, such as public Wi-Fi hotspots',
                        inputType: 'checkbox',
                        defaultVal: false,
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'ProxyCaptiveLoginAllowed_Manual',
                        saveKey: 'ProxyCaptiveLoginAllowed'
                    }
                ],
                Auto: [
                    {
                        label: 'Proxy PAC URL',
                        value: 'ProxyPACURL_Global_HTTP_Proxy',
                        saveKey: 'ProxyPACURL',
                        noDefaultVal: true,
                        helpText:
                            'The URL of the PAC file that defines the proxy configuration. Starting in iOS 13 and macOS 10.15, only URLs that begin with http:// or https:// are allowed.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    },
                    {
                        label: 'Allow direct connection if PAC is unreachable',
                        helpText: 'This feature enables uninterrupted network access even when the device cannot reach the PAC file.',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'ProxyPACFallbackAllowed_Global_HTTP_Proxy',
                        saveKey: 'ProxyPACFallbackAllowed',
                        defaultVal: false
                    },
                    {
                        label: 'Allow bypassing proxy to access captive networks',
                        helpText: 'Allow bypassing proxy to access captive networks',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'ProxyCaptiveLoginAllowed_Auto',
                        saveKey: 'ProxyCaptiveLoginAllowed',
                        defaultVal: false
                    }
                ]
            }
        ]
    },
    {
        label: 'DNS Proxy',
        image: dns,
        underDev: true,
        payloadIdentifier: 'com.example.mydnsproxypayload',
        payloadType: 'com.apple.dnsProxy.managed',
        helpText: 'Use this section to custom DNS servers that devices will use to resolve domain names into IP addresses',
        displayName: 'DNS Proxy',
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        channel: 'DEVICE',
        description: 'DNS Proxy',
        configured: false,
        fields: [
            {
                label: 'App Bundle ID',
                value: 'AppBundleIdentifier',
                noDefaultVal: true,
                helpText: 'Enter the bundle identifier of the app containing DNS proxy',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            },
            {
                label: 'Provider Bundle ID',
                value: 'ProviderBundleIdentifier',
                noDefaultVal: true,
                helpText: 'Enter the bundle identifier of the DNS proxy network extension',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            }
            /*
             * {
             *     label: 'Provider Configuration',
             *     value: 'ProviderConfiguration',
             *     helpText: 'Admin can configure the values',
             *     inputType: 'policyInput',
             *     placeHolder: 'Enter',
             *     show: ['SA', 'MA'],
             *           platforms: ['ios', 'macos'],
             *     maxLength: 250
             * }
             */
        ]
    },
    {
        label: 'Content Filter',
        image: contentFilter,
        underDev: false,
        payloadIdentifier: 'com.example.mywebcontentfilterpayload',
        payloadType: 'com.apple.webcontent-filter',
        displayName: 'Content Filter',
        description: 'Content Filter',
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        channel: 'DEVICE',
        configured: false,
        fields: [
            {
                label: 'Filter Type',
                value: 'FilterType',
                helpText: 'The type of filter, built-in or plug-in. In macOS, the system only supports the plug-in value.',
                inputType: 'dropdown',
                noDefaultVal: true,
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                options: [
                    {
                        label: 'Built-in Adult Content',
                        value: 'BuiltIn',
                        optionsField: 'adult',
                        checkFields: ['AutoFilterEnabled', 'PermittedURLs', 'DenyListURLs']
                    },
                    {
                        label: 'Built-in Specific Websites',
                        value: 'BuiltIn',
                        optionsField: 'specific',
                        checkFields: ['AllowListBookmarks']
                    },
                    { label: 'Plugin (Third Party App)', value: 'Plugin', optionsField: 'Plugin' }
                ],
                clearFields: [
                    { key: 'AutoFilterEnabled', value: false },
                    { key: 'PermittedURLs', value: [] },
                    { key: 'DenyListURLs', value: [] },
                    { key: 'AllowListBookmarks', value: [] },
                    { key: 'Title', value: '' },
                    { key: 'URL', value: '' },
                    { key: 'UserDefinedName_plugin_third_party', value: '' },
                    { key: 'ContentFilterUUID', value: '' },
                    { key: 'PluginBundleID', value: '' },
                    { key: 'ServerAddress', value: '' },
                    { key: 'UserName_plugin_third_party', value: '' },
                    { key: 'Password', value: '' },
                    { key: 'Organization', value: '' },
                    { key: 'FilterBrowsers', value: false },
                    { key: 'FilterSockets', value: false },
                    { key: 'FilterPackets', value: false },
                    { key: 'VendorConfig', value: [] },
                    { key: 'Key', value: '' },
                    { key: 'Value', value: '' }
                ],
                adult: [
                    {
                        label: 'Built-in: Limit Adult Content :',
                        inputType: 'heading',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        fieldClass: 'fw-medium text-info mb-2 fs-12'
                    },
                    {
                        label: 'Enable Auto Filter',
                        helpText: 'If true, the system enables automatic filtering. Use when Filter Type is BuiltIn.',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        value: 'AutoFilterEnabled',
                        defaultVal: false
                    },
                    {
                        label: 'Allowed URLs',
                        value: 'PermittedURLs',
                        helpText:
                            'An array or URLs that are accessible whether or not the automatic filter allows access. Use when Filter Type is BuiltIn. Requires that AutoFilterEnabled is true.',
                        inputType: 'tableInput',
                        val: 'PermittedURL_FilterType',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 1000,
                        noDefaultVal: true
                    },
                    {
                        label: 'Unallowed URLs',
                        value: 'DenyListURLs',
                        helpText: 'An array of URLs that are inaccessible. Use when Filter Type is BuiltIn.',
                        inputType: 'tableInput',
                        val: 'DenyListURL_FilterType',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 1000,
                        noDefaultVal: true
                    }
                ],
                specific: [
                    {
                        label: 'Built-in: Specific Websites Only :',
                        inputType: 'heading',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        fieldClass: 'fw-medium text-info mb-2 fs-12'
                    },
                    {
                        label: 'Allow List Bookmarks',
                        value: 'AllowListBookmarks',
                        helpText: 'An array of dictionaries that define the pages that the user can bookmark or visit',
                        inputType: 'tableObject',
                        noDefaultVal: true,
                        mandatory: ['Title', 'URL'],
                        tableContent: [
                            {
                                label: 'Title',
                                value: 'Title',
                                inputType: 'policyInput',
                                noDefaultVal: true,
                                hideLabel: true,
                                columnClass: ''
                            },
                            { label: 'URL', value: 'URL', inputType: 'policyInput', noDefaultVal: true, hideLabel: true, columnClass: '' }
                        ],
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 100
                    }
                ],
                Plugin: [
                    {
                        label: 'Filter Name',
                        value: 'UserDefinedName_plugin_third_party',
                        saveKey: 'UserDefinedName',
                        noDefaultVal: true,
                        helpText: 'The display name for this filtering configuration. Required when Filter Type is Plugin.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 50
                    },
                    {
                        label: 'Content Filter UUID',
                        value: 'ContentFilterUUID',
                        noDefaultVal: true,
                        helpText: 'A globally unique identifier for this content filter configuration.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 20
                    },
                    {
                        label: 'Plugin Bundle Identifier',
                        value: 'PluginBundleID',
                        noDefaultVal: true,
                        helpText: 'The bundle ID of the plug-in that provides filtering service.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    },
                    {
                        label: 'Server Address',
                        value: 'ServerAddress',
                        noDefaultVal: true,
                        helpText: 'Optional. Server address (may be IP address, hostname, or URL).',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 50
                    },
                    {
                        label: 'User Name',
                        value: 'UserName_plugin_third_party',
                        saveKey: 'UserName',
                        noDefaultVal: true,
                        helpText: 'Optional. A username for the service.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 20
                    },
                    {
                        label: 'Password',
                        value: 'Password_',
                        type: 'password',
                        noDefaultVal: true,
                        helpText: 'Optional. A password for the service.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 20
                    },
                    {
                        label: 'Organization',
                        value: 'Organization',
                        noDefaultVal: true,
                        helpText: 'Enter the organization name.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 50
                    },
                    {
                        label: 'Filter Browsers',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        defaultVal: false,
                        value: 'FilterBrowsers',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        helpText: 'If true, the system enables filtering browsers. Use when Filter Type is Plugin.'
                    },
                    {
                        label: 'Filter Sockets',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        value: 'FilterSockets',
                        defaultVal: false,
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        helpText:
                            'If true, enables the filtering of sockets. Use when Filter Type is Plugin.  Note : At least one of FilterBrowsers or FilterSockets needs to be true.'
                    },
                    {
                        label: 'Filter Packets',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        value: 'FilterPackets',
                        defaultVal: false,
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        helpText:
                            'If true and Filter Type is Plugin, the system enables filtering network packets. Use when Filter Type is Plugin.'
                    },
                    {
                        label: 'Custom Data',
                        value: 'VendorConfig',
                        helpText: 'The custom dictionary that the filtering service plug-in needs. Use when Filter Type is Plugin.',
                        inputType: 'tableObject',
                        mandatory: ['Key', 'Value'],
                        noDefaultVal: true,
                        tableContent: [
                            { label: 'Key', value: 'Key', noDefaultVal: true, inputType: 'policyInput', hideLabel: true, columnClass: '' },
                            {
                                label: 'Value',
                                value: 'Value',
                                noDefaultVal: true,
                                inputType: 'policyInput',
                                hideLabel: true,
                                columnClass: ''
                            }
                        ],
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    }
                ]
            },
            {
                label: 'Filter Type',
                value: 'FilterType_mac',
                saveKey: 'FilterType',
                helpText: 'The type of filter, built-in or plug-in. In macOS, the system only supports the plug-in value.',
                inputType: 'dropdown',
                noDefaultVal: true,
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                options: [{ label: 'Plugin (Third Party App)', value: 'Plugin', optionsField: 'Plugin' }],
                clearFields: [
                    { key: 'UserDefinedName_plugin_third_party', value: '' },
                    { key: 'ContentFilterUUID', value: '' },
                    { key: 'PluginBundleID', value: '' },
                    { key: 'ServerAddress', value: '' },
                    { key: 'UserName_plugin_third_party', value: '' },
                    { key: 'Password', value: '' },
                    { key: 'Organization', value: '' },
                    { key: 'FilterBrowsers', value: false },
                    { key: 'FilterSockets', value: false },
                    { key: 'FilterPackets', value: false },
                    { key: 'VendorConfig', value: [] },
                    { key: 'Key', value: '' },
                    { key: 'Value', value: '' }
                ],
                Plugin: [
                    {
                        label: 'Filter Name',
                        value: 'UserDefinedName_plugin_third_party',
                        saveKey: 'UserDefinedName',
                        noDefaultVal: true,
                        helpText: 'The display name for this filtering configuration. Required when Filter Type is Plugin.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 50
                    },
                    {
                        label: 'Content Filter UUID',
                        value: 'ContentFilterUUID',
                        noDefaultVal: true,
                        helpText: 'A globally unique identifier for this content filter configuration.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 20
                    },
                    {
                        label: 'Plugin Bundle Identifier',
                        value: 'PluginBundleID',
                        noDefaultVal: true,
                        helpText: 'The bundle ID of the plug-in that provides filtering service.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    },
                    {
                        label: 'Server Address',
                        value: 'ServerAddress',
                        noDefaultVal: true,
                        helpText: 'Optional. Server address (may be IP address, hostname, or URL).',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 50
                    },
                    {
                        label: 'User Name',
                        value: 'UserName_plugin_third_party',
                        saveKey: 'UserName',
                        noDefaultVal: true,
                        helpText: 'Optional. A username for the service.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 20
                    },
                    {
                        label: 'Password',
                        value: 'Password_plugin',
                        saveKey: 'Password',
                        type: 'password',
                        noDefaultVal: true,
                        helpText: 'Optional. A password for the service.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 20
                    },
                    {
                        label: 'Organization',
                        value: 'Organization',
                        noDefaultVal: true,
                        helpText: 'Enter the organization name.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 50
                    },
                    {
                        label: 'Filter Browsers',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        defaultVal: false,
                        value: 'FilterBrowsers',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText: 'If true, the system enables filtering browsers. Use when Filter Type is Plugin.'
                    },
                    {
                        label: 'Filter Sockets',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        value: 'FilterSockets',
                        defaultVal: false,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText:
                            'If true, enables the filtering of sockets. Use when Filter Type is Plugin.  Note : At least one of FilterBrowsers or FilterSockets needs to be true.'
                    },
                    {
                        label: 'Filter Packets',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        value: 'FilterPackets',
                        defaultVal: false,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText:
                            'If true and Filter Type is Plugin, the system enables filtering network packets. Use when Filter Type is Plugin.'
                    },
                    {
                        label: 'Custom Data',
                        value: 'VendorConfig',
                        helpText: 'The custom dictionary that the filtering service plug-in needs. Use when Filter Type is Plugin.',
                        inputType: 'tableObject',
                        mandatory: ['Key', 'Value'],
                        noDefaultVal: true,
                        tableContent: [
                            { label: 'Key', value: 'Key', noDefaultVal: true, inputType: 'policyInput', hideLabel: true, columnClass: '' },
                            {
                                label: 'Value',
                                value: 'Value',
                                noDefaultVal: true,
                                inputType: 'policyInput',
                                hideLabel: true,
                                columnClass: ''
                            }
                        ],
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    }
                ]
            }
        ]
    },
    {
        label: 'Certificate Transparency',
        image: certificate,
        underDev: true,
        payloadIdentifier: 'com.example.mycerttransparencypayload',
        payloadType: 'com.apple.security.certificatetransparency',
        helpText: 'Use this section to configure certificate transperency settings',
        displayName: 'Certificate Transparency',
        show: ['SA', 'MA'],
        platforms: ['ios'],
        channel: 'DEVICE',
        description: 'Certificate Transparency',
        configured: false,
        fields: [
            {
                label: 'Excluded Certificates',
                value: 'DisabledForCerts',
                helpText:
                    'Enter the certificates to be excluded from Certificate Transperency enforcement. The value should be set to the SHA-256 hash of the certificates subject public key info',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                noDefaultVal: true,
                inputType: 'tableObject',
                mandatory: ['Algorithm', 'Hash'],
                tableContent: [
                    {
                        label: 'Algorithm',
                        value: 'Algorithm',
                        noDefaultVal: true,
                        inputType: 'policyInput',
                        hideLabel: true,
                        columnClass: ''
                    },
                    { label: 'Hash', value: 'Hash', noDefaultVal: true, inputType: 'policyInput', hideLabel: true, columnClass: '' }
                ]
            },
            {
                label: 'Excluded Domains',
                value: 'DisabledForDomains',
                noDefaultVal: true,
                helpText: 'Domains to be excluded from Certificate Transparency enforcement',
                inputType: 'tableInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 250,
                val: 'DisabledForDomain_Certificate_Transparency'
            }
        ]
    },
    {
        label: 'Passcode',
        image: passcode,
        underDev: true,
        payloadIdentifier: 'com.example.mypasscodepayload',
        payloadType: 'com.apple.mobiledevice.passwordpolicy',
        displayName: 'Passcode',
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        description: 'Passcode',
        channel: 'DEVICE',
        helpText: 'Use this section to manage passcode complexity to mitigate the risk of unauthorized access',
        configured: false,
        fields: [
            {
                label: 'Allow Force PIN',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                value: 'forcePIN',
                defaultVal: false,
                notMandatory: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                helpText: 'The system forces the user to enter a PIN.'
            },
            {
                label: 'Allow Simple Value',
                inputType: 'checkbox',
                notMandatory: true,
                fieldClass: 'fw-medium',
                value: 'allowSimple',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                helpText: 'Allow the use of repeating, ascending and descending character sequences'
            },
            {
                label: 'Require Alphanumeric Value',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                value: 'requireAlphanumeric',
                notMandatory: true,
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                helpText: 'Requires passcodes to include both letters and numbers'
            },
            {
                label: 'Minimum Passcode Length',
                value: 'minLength',
                notMandatory: true,
                defaultVal: 0,
                helpText: 'Smallest number of passcode characters allowed',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                options: [
                    { label: '0', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                    { label: '6', value: 6 },
                    { label: '7', value: 7 },
                    { label: '8', value: 8 },
                    { label: '9', value: 9 },
                    { label: '10', value: 10 },
                    { label: '11', value: 11 },
                    { label: '12', value: 12 },
                    { label: '13', value: 13 },
                    { label: '14', value: 14 },
                    { label: '15', value: 15 },
                    { label: '16', value: 16 }
                ]
            },
            {
                label: 'Minimum Number of Complex Characters',
                value: 'minComplexChars',
                notMandatory: true,
                helpText: 'Smallest number of non-alphanumeric characters allowed.',
                inputType: 'policyInput',
                minValue: 0,
                maxValue: 4,
                type: 'number',
                defaultVal: 0,
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos']
            },
            {
                label: 'Maximum Passcode Age(1-730 days)',
                value: 'maxPINAgeInDays',
                helpText: 'Period after which passcode requires to be changed',
                inputType: 'policyInput',
                noDefaultVal: true,
                type: 'number',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                defaultVal: 1,
                minValue: 1,
                platforms: ['ios', 'macos'],
                maxLength: 30
            },
            {
                label: 'Maximum Auto-Lock',
                value: 'maxInactivity',
                notMandatory: true,
                noDefaultVal: true,
                helpText: 'Longest auto-lock time available to the user',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                options: [
                    { label: '1 minute', value: 1 },
                    { label: '2 minutes', value: 2 },
                    { label: '3 minutes', value: 3 },
                    { label: '4 minutes', value: 4 },
                    { label: '5 minutes', value: 5 },
                    { label: '10 minutes', value: 10 },
                    { label: '15 minutes', value: 15 }
                ]
            },
            {
                label: 'Passcode History',
                value: 'pinHistory',
                noDefaultVal: true,
                notMandatory: true,
                helpText: 'Choose the maximum number of previous passcodes stored in history that cannot be reused by an end user',
                inputType: 'policyInput',
                minValue: 1,
                maxValue: 50,
                type: 'number',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos']
            },
            {
                label: 'Maximum Grace Period for Device Lock',
                value: 'maxGracePeriod',
                notMandatory: true,
                defaultVal: 0,
                helpText: 'Set the maximum duration for the device lock grace period accessible to the user',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                type: 'number',
                maxValue: 50,
                minValue: 0,
                show: ['SA', 'MA'],
                platforms: ['macos']
            },
            {
                label: 'Maximum Number of Failed Attempts',
                value: 'maxFailedAttempts',
                notMandatory: true,
                helpText:
                    'Secure the device by automatically wiping it after a specified number of unsuccessful attempts to prevent unauthorized access',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                type: 'number',
                maxValue: 11,
                minValue: 2,
                defaultVal: 11,
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos']
            },
            {
                label: 'Minutes Until Failed Login Reset',
                value: 'minutesUntilFailedLoginReset',
                helpText:
                    'The number of minutes before the system resets the login after the maximum number of unsuccessful login attempts is reached. This key requires setting maxFailedAttempts',
                inputType: 'policyInput',
                type: 'number',
                noDefaultVal: true,
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos']
            },
            {
                label: 'Change at Next Auth',
                value: 'changeAtNextAuth',
                helpText:
                    'If true, the system causes a password reset to occur the next time the user tries to authenticate. If this key is set in a device profile, the setting takes effect for all users, and admin authentications may fail until the admin user password is also reset. Available in macOS 10.13 and later.',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos']
            },
            {
                label: 'Custom Regex',
                value: 'customRegex',
                helpText:
                    'Specifies a regular expression, and its description, used to enforce password compliance. Use the simpler passcode restrictions whenever possible, and rely on regular expression matching only when necessary. Mistakes in regular expressions can lead to frustrating user experiences, such as unsatisfiable passcode policies, or policy descriptions that don’t match the enforced policy.',
                inputType: 'json',
                placeHolder: 'Select',
                noDefaultVal: true,
                notMandatory: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                jsonFields: [
                    {
                        label: 'Password Content Regex',
                        value: 'passwordContentRegex',
                        inputType: 'policyInput',
                        notMandatory: true,
                        noDefaultVal: true,
                        helpText:
                            'A regular expression string that the system matches against the password to determine whether it complies with a policy.',
                        maxLength: 2048,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'Password Content Description',
                        value: 'passwordContentDescription',
                        helpText:
                            'Specifies a regular expression, and its description, used to enforce password compliance. Use the simpler passcode restrictions whenever possible, and rely on regular expression matching only when necessary. Mistakes in regular expressions can lead to frustrating user experiences, such as unsatisfiable passcode policies, or policy descriptions that don’t match the enforced policy.',
                        inputType: 'json',
                        notMandatory: true,
                        placeHolder: 'Select',
                        noDefaultVal: true,
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        jsonFields: [
                            {
                                label: 'Description',
                                value: 'ANY',
                                placeHolder: 'A localized description.',
                                inputType: 'policyInput',
                                notMandatory: true,
                                noDefaultVal: true,
                                maxLength: 2048,
                                show: ['SA', 'MA'],
                                platforms: ['macos']
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        label: 'Wi-Fi',
        image: wifi,
        underDev: false,
        payloadIdentifier: 'com.example.mywifipayload',
        payloadType: 'com.apple.wifi.managed',
        displayName: 'Wi-Fi',
        description: 'Wi-Fi',
        schemaType: 'array',
        schemaValue: 'wifi',
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        channel: 'DEVICE',
        helpText: 'Use this section to configure Wi-Fi settings',
        configured: false,
        fields: [
            {
                labe: 'Wi-Fi',
                value: 'wifi',
                addButtonText: '+ Add New Wi-Fi',
                inputType: 'array',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                PayloadType: 'com.apple.wifi.managed',
                cardFields: [
                    { label: 'Wi-Fi Name/SSID', value: 'SSID_STR' },
                    { label: 'Security Type', value: 'EncryptionType', type: 'dropdown' },
                    { label: 'Proxy Type', value: 'ProxyType_WiFi', type: 'dropdown' }
                ],
                arrayFields: [
                    {
                        label: 'Service Set Identifier(SSID)',
                        value: 'SSID_STR',
                        noDefaultVal: true,
                        helpText: 'Identification of the wireless network to connect to',
                        inputType: 'policyInput',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        placeHolder: 'Enter',
                        maxLength: 20
                    },
                    {
                        label: 'Hidden Network',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        value: 'HIDDEN_NETWORK',
                        defaultVal: false,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText: 'Enable if the target network is not open or actively broadcasting'
                    },
                    {
                        label: 'Auto Join',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        value: 'AutoJoin',
                        defaultVal: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText: 'Enable automatic connection to this wireless network'
                    },
                    {
                        label: 'Disable Captive Network Detection',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        value: 'CaptiveBypass',
                        defaultVal: false,
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        helpText: 'Suppress the captive network assistant from appearing'
                    },
                    {
                        label: 'Disable Association MAC Randomization',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        value: 'DisableAssociationMACRandomization',
                        defaultVal: false,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        helpText: 'Connections to this Wi-Fi network will use a non-private MAC address'
                    },
                    {
                        label: 'Is Hotspot',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        value: 'IsHotspot',
                        defaultVal: false,
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        helpText: 'Hotspot Connecttion'
                    },
                    {
                        label: 'Security Type',
                        value: 'EncryptionType',
                        helpText: 'Wireless network encryption to use when connecting',
                        inputType: 'dropdown',
                        defaultVal: 'Any',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        placeHolder: 'Select',
                        options: [
                            { label: 'None', value: 'None' },
                            { label: 'WEP', value: 'WEP', optionsField: 'WEP' },
                            { label: 'WPA/WPA2 Personal', value: 'WPA', optionsField: 'WPA' },
                            { label: 'WPA2/WPA3 Personal', value: 'WPA2', optionsField: 'WPA2' },
                            { label: 'WPA3 Personal (iOS 13 or later)', value: 'WPA3', optionsField: 'WPA3' },
                            { label: 'Any Personal (except WPA3-Only)', value: 'Any', optionsField: 'Any' }
                            /*
                             * { label: 'WPA/WPA2 Personal', value: 'WPA/WPA2 Personal' },
                             * { label: 'WPA2/WPA3 Personal', value: 'WPA2/WPA3 Personal' },
                             * { label: 'WPA3 Personal (iOS 13 or later)', value: 'WPA3 Personal (iOS 13 or later)' },
                             * { label: 'Any Personal (except WPA3-Only)', value: 'Any Personal (except WPA3-Only)' },
                             * { label: 'Dynamic WEP', value: 'Dynamic WEP' },
                             * { label: 'WPA/WPA2 Enetrprise', value: ' WPA/WPA2 Enetrprise' },
                             * { label: 'WPA2/WPA3 Enetrprise', value: 'WPA2/WPA3 Enetrprise' },
                             * { label: 'WPA3 Enterprise (iOS 13 or later)', value: 'WPA3 Enterprise (iOS 13 or later)' },
                             * { label: 'Any Enterprise', value: 'Any Enterprise' }
                             */
                        ],
                        WEP: [
                            {
                                label: 'Password',
                                value: 'Password_wifi',
                                saveKey: 'Password',
                                noDefaultVal: true,
                                type: 'password',
                                helpText: 'Password for the wireless network',
                                inputType: 'policyInput',
                                placeHolder: 'Enter',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                maxLength: 20
                            }
                        ],
                        WPA: [
                            {
                                label: 'Password',
                                value: 'Password_wifi',
                                saveKey: 'Password',
                                noDefaultVal: true,
                                type: 'password',
                                helpText: 'Password for the wireless network',
                                inputType: 'policyInput',
                                placeHolder: 'Enter',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                maxLength: 20
                            }
                        ],
                        WPA2: [
                            {
                                label: 'Password',
                                value: 'Password_wifi',
                                saveKey: 'Password',
                                noDefaultVal: true,
                                type: 'password',
                                helpText: 'Password for the wireless network',
                                inputType: 'policyInput',
                                placeHolder: 'Enter',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                maxLength: 20
                            }
                        ],
                        WPA3: [
                            {
                                label: 'Password',
                                value: 'Password_wifi',
                                saveKey: 'Password',
                                noDefaultVal: true,
                                type: 'password',
                                helpText: 'Password for the wireless network',
                                inputType: 'policyInput',
                                placeHolder: 'Enter',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                maxLength: 20
                            }
                        ],
                        Any: [
                            {
                                label: 'Password',
                                value: 'Password_wifi',
                                saveKey: 'Password',
                                noDefaultVal: true,
                                type: 'password',
                                helpText: 'Password for the wireless network',
                                inputType: 'policyInput',
                                placeHolder: 'Enter',
                                show: ['SA', 'MA'],
                                platforms: ['ios', 'macos'],
                                maxLength: 20
                            }
                        ]
                    },
                    {
                        label: 'Proxy Setup',
                        value: 'ProxyType_WiFi',
                        saveKey: 'ProxyType',
                        defaultVal: 'None',
                        helpText:
                            'The proxy type, if any, to use. If you choose the manual proxy type, you need the proxy server address, including its port and optionally a user name and password into the proxy server. If you choose the auto proxy type, you can enter a proxy autoconfiguration (PAC) URL. Available in iOS 5.0 and later, and on all versions of macOS.',
                        inputType: 'dropdown',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        clearFields: [
                            { key: 'ProxyServer_wifi', value: '' },
                            { key: 'ProxyServerPort_wifi', value: '' },
                            { key: 'ProxyUsername_wifi', value: '' },
                            { key: 'ProxyPassword_wifi', value: '' },
                            { key: 'ProxyPACURL_wifi', value: '' },
                            { key: 'ProxyPACFallbackAllowed_wifi', value: '' }
                        ],
                        placeHolder: 'Select',
                        options: [
                            { label: 'None', value: 'None' },
                            { label: 'Manual', value: 'Manual', optionsField: 'Manual' },
                            { label: 'Automatic', value: 'Automatic', optionsField: 'Automatic' }
                        ],
                        Manual: [
                            {
                                label: 'Proxy Server and Port',
                                helpText: 'Enter the server details.',
                                inputType: 'multiInput',
                                seperation: ':',
                                inputs: [
                                    {
                                        class: 'widthPer-80',
                                        type: 'text',
                                        value: 'ProxyServer_wifi',
                                        inputType: 'policyInput',
                                        placeHolder: 'Proxy Server Host',
                                        saveKey: 'ProxyServer'
                                    },
                                    {
                                        class: 'widthPer-20',
                                        type: 'number',
                                        value: 'ProxyServerPort_wifi',
                                        inputType: 'policyInput',
                                        placeHolder: 'Port',
                                        saveKey: 'ProxyServerPort',
                                        noDefaultVal: true,
                                        minValue: 0,
                                        maxValue: 65535
                                    }
                                ],
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                maxLength: 50
                            },
                            {
                                label: 'User Name',
                                value: 'ProxyUsername_wifi',
                                saveKey: 'ProxyUsername',
                                noDefaultVal: true,
                                helpText: 'The user name used to authenticate to the proxy server.',
                                inputType: 'policyInput',
                                placeHolder: 'Enter',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                maxLength: 50
                            },
                            {
                                label: 'Password',
                                value: 'ProxyPassword_wifi',
                                saveKey: 'ProxyPassword',
                                noDefaultVal: true,
                                type: 'password',
                                helpText: 'The password used to authenticate to the proxy server.',
                                inputType: 'policyInput',
                                placeHolder: 'Enter',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                maxLength: 50
                            }
                        ],
                        Automatic: [
                            {
                                label: 'Proxy Server URL',
                                value: 'ProxyPACURL_wifi',
                                saveKey: 'ProxyPACURL',
                                noDefaultVal: true,
                                helpText: 'Optional. The URL of the PAC file that defines the proxy configuration.',
                                inputType: 'policyInput',
                                placeHolder: 'Enter',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                maxLength: 100
                            },
                            {
                                label: 'Allow direct connection if PAC is unreachable',
                                inputType: 'checkbox',
                                fieldClass: 'fw-medium',
                                show: ['SA', 'MA'],
                                platforms: ['ios'],
                                value: 'ProxyPACFallbackAllowed_wifi',
                                saveKey: 'ProxyPACFallbackAllowed',
                                defaultVal: false,
                                helpText:
                                    'Optional. If false, prevents the device from connecting directly to the destination if the PAC file is unreachable. Default is false.'
                            }
                        ]
                    }

                    /*
                     * {
                     *     label: 'Network Type',
                     *     value: 'NETWORK_TYPE',
                     *     helpText: 'Configures network to appear as legacy or passpoint hotspot',
                     *     inputType: 'dropdown',
                     *     placeHolder: 'Select',
                     *     options: [
                     *         { label: 'Standard', value: 'Standard' },
                     *         { label: 'Legacy Hotspot', value: 'Legacy Hotspot' }
                     *         // { label: 'Passpoint', value: 'Passpoint' }
                     *     ]
                     * },
                     */

                    /*
                     * {
                     *     label: 'Fast Lane QoS Marking',
                     *     value: 'QoSMarkingPolicy',
                     *     helpText: 'Quality of service (QoS) marking applied to network traffic to prioritize data',
                     *     inputType: 'json',
                     *     show: ['SA', 'MA'],
                     *   platforms: ['ios'],
                     *     jsonFields: [
                     *         {
                     *             label: 'Enable QoS Marking',
                     *             value: 'QoSMarkingEnabled',
                     *             helpText:
                     *                 'If true, disables L3 marking and only uses L2 marking for traffic that goes to the Wi-Fi network.If false, the system behaves as if Wi-Fi doesn’t have an association with a Cisco QoS fast lane network.',
                     *             inputType: 'checkbox',
                     *             show: ['SA', 'MA'],
                     *   platforms: ['ios'],
                     *             fieldClass: 'fw-medium'
                     *         },
                     *         {
                     *             label: 'Allow Apple Audio Video Calls',
                     *             value: 'QoSMarkingAppleAudioVideoCalls',
                     *             helpText:
                     *                 'If true, adds audio and video traffic of built-in audio/video services, such as FaceTime and Wi-Fi Calling, to the allow list for L2 and L3 marking for traffic that goes to the Wi-Fi network.',
                     *             inputType: 'checkbox',
                     *             show: ['SA', 'MA'],
                     *   platforms: ['ios'],
                     *             fieldClass: 'fw-medium'
                     *         },
                     *         {
                     *             label: 'QoS Marking Allow List App Identifiers',
                     *             value: 'QoSMarkingAllowListAppIdentifiers',
                     *             helpText:
                     *                 'An array of app bundle identifiers that defines the allow list for L2 and L3 marking for traffic that goes to the Wi-Fi network. If the array isn’t present, but the QoSMarkingPolicy key is present — even empty — no apps can use L2 and L3 marking',
                     *             inputType: 'policyInput',
                     *             placeHolder: 'Enter',
                     *             show: ['SA', 'MA'],
                     *   platforms: ['ios'],
                     *             maxLength: 250
                     *         }
                     *     ]
                     * }
                     */
                ]
            }
        ]
    },
    /*
     * {
     *     label: 'VPN',
     *     image: vpn,
     *     underDev: true,
     *     payloadIdentifier: 'com.example.myvpnmanagedprofile',
     *     payloadType: 'com.apple.vpn.managed',
     *     displayName: 'VPN',
     *     description: 'VPN',
     *     configured: false,
     *     helpText:
     *         'A virtual private network (VPN) adds security and anonymity to users when they connect to web-based services and sites. A VPN hides the user’s actual public IP address and “tunnels” traffic between the user’s device and the remote server. Most users sign up for a VPN service to avoid being tracked online, and they often use it on public Wi-Fi where there are greater risks to the safety of their data.',
     *     fields: [
     *         {
     *             label: 'Connection Name',
     *             value: 'UserDefinedName',
     *             noDefaultVal: true,
     *             helpText: 'Display name of the connection (displayed on the device)',
     *             inputType: 'policyInput',
     *             placeHolder: 'Enter',
     *             show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *             maxLength: 20
     *         },
     *         {
     *             label: 'Connection Type',
     *             value: 'VPNType',
     *             noDefaultVal: true,
     *             helpText: 'Type of connection enabled by this policy',
     *             inputType: 'dropdown',
     *             placeHolder: 'Select',
     *             show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *             options: [
     *                 { label: 'VPN', value: 'VPN', optionsField: 'VPN' },
     *                 { label: 'IPsec', value: 'IPsec', optionsField: 'IPsec' },
     *                   { label: 'IKEv2', value: 'IKEv2' },
     *                   { label: 'PPTP (iOS 9 and OS X 10.11 and earlier)', value: 'PPTP (iOS 9 and OS X 10.11 and earlier)' },
     *                   { label: 'Cisco AnyConnect', value: 'Cisco AnyConnect' },
     *                   { label: 'Juniper SSL', value: 'Juniper SSL' },
     *                   { label: 'Pulse Secure', value: 'Pulse Secure' },
     *                   { label: 'F5 SSL', value: 'F5 SSL' },
     *                   { label: 'SonicWALL Mobile Connect', value: 'SonicWALL Mobile Connect' },
     *                   { label: 'Aruba VIA', value: 'Aruba VIA' },
     *                   { label: 'Check Point Mobile VPN', value: 'Check Point Mobile VPN' },
     *                   { label: 'Custom SSL', value: 'Custom SSL' }
     *             ],
     *             VPN: [
     *                 {
     *                     label: 'Connection Sub Type',
     *                     value: 'VPNSubType',
     *                     noDefaultVal: true,
     *                     helpText: 'Sub Type of connection enabled by this policy',
     *                     inputType: 'dropdown',
     *                     placeHolder: 'Select',
     *                     show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                     options: [{ label: 'Sonic WALL Mobile Connect', value: 'com.sonicwall.SonicWALL-SSLVPN.vpnplugin' }]
     *                 },
     *                 {
     *                     label: 'Server',
     *                     value: 'RemoteAddress',
     *                     noDefaultVal: true,
     *                     helpText: 'Host name or IP address for server',
     *                     inputType: 'policyInput',
     *                     placeHolder: 'Enter',
     *                     maxLength: 50
     *                 },
     *                 {
     *                     label: 'Account',
     *                     value: 'AuthName',
     *                     noDefaultVal: true,
     *                     helpText: 'User account for authenticating the connection',
     *                     inputType: 'policyInput',
     *                     placeHolder: 'Enter',
     *                     maxLength: 20
     *                 },
     *                 {
     *                     label: 'Login Group or Domain',
     *                     value: 'VendorConfig',
     *                     helpText: 'User password for authenticating the connection',
     *                     inputType: 'tableObject',
     *                     tableContent: [
     *                         { label: 'Group', value: 'Group', inputType: 'policyInput', noDefaultVal: true },
     *                         { label: 'Login Group or Domain', value: 'LoginGroupOrDomain', inputType: 'policyInput', noDefaultVal: true }
     *                     ]
     *                 },
     *                 {
     *                     label: 'User Authentication',
     *                     value: 'AuthenticationMethod_VPN',
     *                     saveKey: 'AuthenticationMethod',
     *                     noDefaultVal: true,
     *                     helpText: 'Authentication Method',
     *                     inputType: 'dropdown',
     *                     placeHolder: 'Select',
     *                     show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                     options: [{ label: 'Password', value: 'Password' }]
     *                 },
     *                 {
     *                     label: 'Password',
     *                     value: 'AuthPassword',
     *                     type: 'password',
     *                     noDefaultVal: true,
     *                     helpText: 'User password for authenticating the connection',
     *                     inputType: 'policyInput',
     *                     placeHolder: 'Enter',
     *                     maxLength: 20
     *                 },
     *                 {
     *                     label: 'Send all traffic through VPN',
     *                     inputType: 'dropdown',
     *                     fieldClass: 'fw-medium',
     *                     value: 'IncludeAllNetworks',
     *                     defaultVal: 0,
     *                     show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                     helpText: 'Send all traffic through VPN',
     *                     options: [
     *                         { label: 'False', value: 0 },
     *                         { label: 'True', value: 1 }
     *                     ]
     *                 },
     *                 {
     *                     label: 'Exclude Local Networks',
     *                     inputType: 'dropdown',
     *                     fieldClass: 'fw-medium',
     *                     value: 'ExcludeLocalNetworks',
     *                     noDefaultVal: true,
     *                     show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                     helpText: 'Exclude Local Networks',
     *                     placeHolder: 'Select',
     *                     options: [
     *                         { label: 'False', value: 0 },
     *                         { label: 'True', value: 1 }
     *                     ]
     *                 },
     *                 {
     *                     label: 'Disconnect On Idle',
     *                     inputType: 'dropdown',
     *                     fieldClass: 'fw-medium',
     *                     value: 'DisconnectOnIdle',
     *                     defaultVal: 0,
     *                     show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                     helpText: 'Disconnect On Idle',
     *                     placeHolder: 'Select',
     *                     options: [
     *                         { label: 'False', value: 0 },
     *                         { label: 'True', value: 1, optionsField: 1 }
     *                     ],
     *                     1: [
     *                         {
     *                             label: 'Disconnect On Idle Timer',
     *                             value: 'DisconnectOnIdleTimer',
     *                             helpText: 'Disconnect On Idle Timer',
     *                             noDefaultVal: true,
     *                             inputType: 'policyInput',
     *                             placeHolder: 'Enter',
     *                             maxLength: 20
     *                         }
     *                     ]
     *                 },
     *                 {
     *                     label: 'Provider Type',
     *                     inputType: 'dropdown',
     *                     fieldClass: 'fw-medium',
     *                     value: 'ProviderType',
     *                     defaultVal: 'packet-tunnel',
     *                     show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                     helpText: 'Provider Type',
     *                     placeHolder: 'Select',
     *                     options: [
     *                         { label: 'Packet Tunnel', value: 'packet-tunnel' },
     *                         { label: 'App Proxy', value: 'app-proxy' }
     *                     ]
     *                 }
     *             ],
     *             IPsec: [
     *                 {
     *                     label: 'Server',
     *                     value: 'RemoteAddress',
     *                     helpText: 'Host name or IP address for server',
     *                     inputType: 'policyInput',
     *                     placeHolder: 'Enter',
     *                     maxLength: 50
     *                 },
     *                 {
     *                     label: 'Account',
     *                     value: 'XAuthName',
     *                     helpText: 'User account for authenticating the connection',
     *                     inputType: 'policyInput',
     *                     placeHolder: 'Enter',
     *                     maxLength: 20
     *                 },
     *                 {
     *                     label: 'Password',
     *                     value: 'XAuthPassword',
     *                     type: 'password',
     *                     helpText: 'User password for authenticating the connection',
     *                     inputType: 'policyInput',
     *                     placeHolder: 'Enter',
     *                     maxLength: 20
     *                 },
     *                 {
     *                     label: 'Machine Authentication',
     *                     value: 'AuthenticationMethod_Ipsec',
     *                     saveKey: 'AuthenticationMethod',
     *                     helpText: 'Authentication type for connection',
     *                     inputType: 'dropdown',
     *                     placeHolder: 'Select',
     *                     show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                     options: [{ label: 'Shared Secret', value: 'SharedSecret', optionsField: 'SharedSecret' }],
     *                     SharedSecret: [
     *                         {
     *                             label: 'Group Name',
     *                             value: 'LocalIdentifier',
     *                             helpText: 'Group Identifier for Connection',
     *                             inputType: 'policyInput',
     *                             placeHolder: 'Enter',
     *                             maxLength: 50
     *                         },
     *                         {
     *                             label: 'Shared Secret',
     *                             value: 'SharedSecret',
     *                             helpText: 'Shared secret for the connection',
     *                             inputType: 'policyInput',
     *                             placeHolder: 'Enter',
     *                             show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                             maxLength: 20
     *                         },
     *                         {
     *                             label: 'Promt for Password',
     *                             inputType: 'checkbox',
     *                             fieldClass: 'fw-medium',
     *                             value: 'PromptForVPNPIN',
     *                             show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                             helpText: 'Promt user for password on the device'
     *                         }
     *                     ]
     *                 }
     *             ]
     *         }
     *     ]
     * },
     */
    {
        label: 'AirPlay',
        image: airPlay,
        underDev: false,
        payloadIdentifier: 'com.example.myairplaysettingspayload',
        payloadType: 'com.apple.airplay',
        displayName: 'AirPlay',
        description: 'AirPlay',
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        channel: 'USER',
        configured: false,
        helpText: 'Use this section to allows wireless streaming and sharing of media across Apple devices and compatible accessories',
        fields: [
            {
                label: 'Passwords',
                value: 'Passwords',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                noDefaultVal: true,
                helpText: 'Enter the Airplay destination and its password to which device can connect',
                inputType: 'tableObject',
                mandatory: ['Password'],
                conditionalMandatory: ['DeviceID', 'DeviceName'],
                tableContent: [
                    {
                        label: 'Device ID',
                        value: 'DeviceID',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        val: 'DeviceID_Passwords',
                        noDefaultVal: true,
                        saveKey: 'DeviceID',
                        inputType: 'policyInput',
                        hideLabel: true
                    },
                    {
                        label: 'Device Name',
                        value: 'DeviceName',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        val: 'device_name',
                        noDefaultVal: true,
                        inputType: 'policyInput',
                        hideLabel: true
                    },
                    {
                        label: 'Password',
                        value: 'Password_airplay',
                        saveKey: 'password',
                        val: 'pwd',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        noDefaultVal: true,
                        inputType: 'policyInput',
                        hideLabel: true,
                        type: 'password'
                    }
                ],
                placeHolder: 'Enter',
                maxLength: 100
            },
            {
                label: 'Allowed',
                value: 'AllowList',
                val: 'AllowList_AirPlay',
                helpText: 'Enter the allowed Airplay destinations to which device is allowed to connect. Ex: xx:xx:xx:xx:xx:xx',
                inputType: 'tableObject',
                noDefaultVal: true,
                mandatory: ['DeviceID_Allowed'],
                tableContent: [
                    {
                        label: 'Device ID',
                        value: 'DeviceID_Allowed',
                        saveKey: 'DeviceID',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        inputType: 'policyInput',
                        placeHolder: 'Ex: xx:xx:xx:xx:xx:xx',
                        hideLabel: true,
                        noDefaultVal: true
                    }
                ],
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                max: 10,
                maxLength: 100
            }
        ]
    },

    {
        label: 'AirPlay Security(tvOS)',
        image: airPlaySecurity,
        underDev: false,
        payloadIdentifier: 'com.example.myairplaysecuritypayload',
        payloadType: 'com.apple.airplay.security',
        displayName: 'AirPlay Security',
        show: ['SA', 'MA'],
        channel: 'DEVICE',
        platforms: ['tvos'],
        description: 'AirPlay Security',
        helpText: 'Use this section to allow secure transmission of data transmitted between devices',
        configured: false,
        fields: [
            {
                label: 'Access',
                value: 'AccessType',
                noDefaultVal: true,
                helpText: 'Networking requirement for devices connecting to Apple TV via AirPlay',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['tvos'],
                options: [
                    { label: 'Devices on any Wi-Fi network', value: 'ANY', optionsField: 'ANY' },
                    { label: 'Devices on the same Wi-Fi network', value: 'WIFI_ONLY', optionsField: 'WIFI_ONLY' }
                ],
                clearFields: [{ key: 'SecurityType', value: '' }],
                ANY: [
                    {
                        label: 'Security',
                        value: 'SecurityType',
                        noDefaultVal: true,
                        helpText: 'Security requirement for devices connecting to Apple TV via AirPlay',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['tvos'],
                        options: [
                            { label: 'First-time passcode', value: 'PASSCODE_ONCE' },
                            { label: 'Passcode', value: 'PASSCODE_ALWAYS' },
                            { label: 'Password', value: 'PASSWORD', optionsField: 'PASSWORD' }
                        ],
                        clearFields: [{ key: 'Password', value: '' }],
                        PASSWORD: [
                            {
                                label: 'Password',
                                saveKey: 'Password',
                                noDefaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['tvos'],
                                type: 'password',
                                value: 'Password_AirPlay_Security',
                                helpText: 'User password for authenticating the connection',
                                inputType: 'policyInput',
                                placeHolder: 'Enter',
                                maxLength: 20
                            }
                        ]
                    }
                ],
                WIFI_ONLY: [
                    {
                        label: 'Security',
                        value: 'SecurityType',
                        helpText: 'Security requirement for devices connecting to Apple TV via AirPlay',
                        inputType: 'dropdown',
                        noDefaultVal: true,
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['tvos'],
                        options: [
                            { label: 'None', value: 'None' },
                            { label: 'First-time passcode', value: 'PASSCODE_ONCE' },
                            { label: 'Passcode', value: 'PASSCODE_ALWAYS' },
                            { label: 'Password', value: 'PASSWORD', optionsField: 'PASSWORD' }
                        ],
                        clearFields: [{ key: 'Password', value: '' }],
                        PASSWORD: [
                            {
                                label: 'Password',
                                saveKey: 'Password',
                                noDefaultVal: true,
                                show: ['SA', 'MA'],
                                platforms: ['tvos'],
                                type: 'password',
                                value: 'Password_AirPlay_Security',
                                helpText: 'User password for authenticating the connection',
                                inputType: 'policyInput',
                                placeHolder: 'Enter',
                                maxLength: 20
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        label: 'AirPrint',
        image: airPrint,
        underDev: false,
        payloadIdentifier: 'com.example.myairprintpayload',
        payloadType: 'com.apple.airprint',
        displayName: 'AirPrint',
        description: 'AirPrint',
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        channel: 'USER',
        configured: false,
        helpText:
            'Use this section to that enable wireless printing from iOS and macOS devices to AirPrint-compatible printers without the need for additional drivers or software',
        fields: [
            {
                label: 'Printers',
                value: 'AirPrint',
                helpText: 'Enter the Host name/IP address, Port and Resource path',
                inputType: 'tableObject',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                colSize: 12,
                showHeader: true,
                noDefaultVal: true,
                mandatory: ['IPAddress', 'ResourcePath'],
                tableContent: [
                    {
                        label: 'Use TLS',
                        value: 'ForceTLS',
                        helpText:
                            'If true, AirPrint connections are secured by Transport Layer Security (TLS). Available only in iOS 11 and later.',
                        inputType: 'checkbox',
                        noDefaultVal: true,
                        notMandatory: true,
                        hideLabel: true,
                        columnClass: 'align-middle text-center',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios']
                    },
                    {
                        label: 'Host or IP Address',
                        value: 'IPAddress',
                        noDefaultVal: true,
                        helpText: 'The IP address or hostname of the AirPrint destination',
                        inputType: 'policyInput',
                        hideLabel: true,
                        columnClass: 'align-middle',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 50
                    },
                    {
                        label: 'Port',
                        value: 'Port',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText:
                            'The listening port of the AirPrint destination. Available only in iOS 11 and later Minimum Value: 0 Maximum Value: 65535',
                        inputType: 'policyInput',
                        type: 'number',
                        hideLabel: true,
                        columnClass: 'align-middle',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 50
                    },
                    {
                        label: 'Resource Path',
                        value: 'ResourcePath',
                        noDefaultVal: true,
                        helpText: 'The resource path associated with the printer. This path corresponds to the rp parameter of the _ipps.',
                        inputType: 'policyInput',
                        hideLabel: true,
                        columnClass: 'align-middle',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos']
                    }
                ]
            }
        ]
    },
    {
        label: 'Calendar',
        image: calender,
        underDev: true,
        payloadIdentifier: 'com.example.mycaldavpayload',
        payloadType: 'com.apple.caldav.account',
        displayName: 'Calendar',
        description: 'Calendar',
        show: ['SA', 'MA'],
        channel: 'USER',
        platforms: ['ios', 'macos'],
        helpText: 'Use this section to manage and deploy Calendar account.',
        configured: false,
        fields: [
            {
                label: 'Account Description',
                value: 'CalDAVAccountDescription',
                noDefaultVal: true,
                helpText: 'The display name of the account(company  CalDAV account)',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 250
            },
            {
                label: 'Account Host Name and Port',
                helpText: 'The Account host details.',
                inputType: 'multiInput',
                noDefaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                seperation: ':',
                inputs: [
                    {
                        class: 'widthPer-80',
                        type: 'text',
                        value: 'CalDAVHostName_Calendar',
                        saveKey: 'CalDAVHostName',
                        inputType: 'policyInput',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        noDefaultVal: true,
                        placeHolder: 'Account Host'
                    },
                    {
                        class: 'widthPer-20',
                        type: 'number',
                        value: 'CalDAVPort_Calendar',
                        saveKey: 'CalDAVPort',
                        inputType: 'policyInput',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        placeHolder: 'Port',
                        noDefaultVal: true,
                        minValue: 0,
                        maxValue: 65535
                    }
                ],
                maxLength: 50
            },
            {
                label: 'Principal URL',
                value: 'CalDAVPrincipalURL',
                noDefaultVal: true,
                notMandatory: true,
                helpText: 'The principal URL for the CalDAV account',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            },
            {
                label: 'Account User Name',
                value: 'CalDAVUsername',
                noDefaultVal: true,
                helpText: 'The CalDAV user name',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            },
            {
                label: 'Account Password',
                value: 'CalDAVPassword',
                type: 'password',
                noDefaultVal: true,
                notMandatory: true,
                helpText: 'The CalDAV password',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            },
            {
                label: 'Use SSL ',
                noDefaultVal: true,
                helpText: 'Enable source socket layer communication with CalDAV server',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'CalDAVUseSSL'
            }
        ]
    },
    {
        label: 'Subscribed Calendars',
        image: subscribedCalendar,
        underDev: true,
        payloadIdentifier: 'com.example.mysubscribedcalpayload',
        payloadType: 'com.apple.subscribedcalendar.account',
        displayName: 'Subscribed Calendars',
        show: ['SA', 'MA'],
        platforms: ['ios'],
        channel: 'DEVICE',
        description: 'Subscribed Calendars',
        helpText: 'Use this section to manage and deploy calendar subscriptions to iOS devices',
        configured: false,
        fields: [
            {
                label: 'Description',
                value: 'SubCalAccountDescription',
                noDefaultVal: true,
                helpText: 'Enter the description of the calendar subscription',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 250
            },
            {
                label: 'URL',
                value: 'SubCalAccountHostName',
                noDefaultVal: true,
                helpText: 'The URL of the calender file',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 100
            },
            {
                label: 'User Name',
                value: 'SubCalAccountUsername',
                notMandatory: true,
                noDefaultVal: true,
                helpText: 'The user name for this subscription',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                platforms: ['ios'],
                show: ['SA', 'MA'],
                maxLength: 100
            },
            {
                label: 'Password',
                value: 'SubCalAccountPassword',
                type: 'password',
                notMandatory: true,
                noDefaultVal: true,
                helpText: 'Enter the password for this subscription',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 100
            },
            {
                label: 'Use SSL',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                noDefaultVal: true,
                helpText: 'This feature enables to encrypt the data transmitted over networks, ensuring secure and private communication.',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                value: 'SubCalAccountUseSSL'
            }
        ]
    },
    {
        label: 'Contacts',
        image: contacts,
        underDev: true,
        channel: 'USER',
        payloadIdentifier: 'com.example.mycardavpayload',
        payloadType: 'com.apple.carddav.account',
        displayName: 'Contacts',
        description: 'Contacts',
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        configured: false,
        fields: [
            {
                label: 'Account Description',
                value: 'CardDAVAccountDescription',
                helpText: 'The display name of the account(company  Card DAV account)',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 250,
                noDefaultVal: true
            },
            {
                label: 'Host Name and Port',
                helpText: 'The Account host details.',
                inputType: 'multiInput',
                noDefaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                seperation: ':',
                inputs: [
                    {
                        class: 'widthPer-80',
                        type: 'text',
                        value: 'CardDAVHostName_subcal',
                        saveKey: 'CardDAVHostName',
                        inputType: 'policyInput',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        noDefaultVal: true,
                        placeHolder: 'Account Host'
                    },
                    {
                        class: 'widthPer-20',
                        type: 'number',
                        value: 'CardDAVHostPort_subcal',
                        saveKey: 'CardDAVHostPort',
                        inputType: 'policyInput',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        placeHolder: 'Port',
                        noDefaultVal: true,
                        minValue: 0,
                        maxValue: 65535
                    }
                ],
                maxLength: 50
            },
            {
                label: 'Principal URL',
                value: 'CardDAVPrincipalURL',
                helpText: 'The principal URL for the Card DAV account',
                inputType: 'policyInput',
                notMandatory: true,
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100,
                noDefaultVal: true
            },
            {
                label: 'Account User Name',
                value: 'CardDAVUsername',
                notMandatory: true,
                helpText: 'The Card DAV user name',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100,
                noDefaultVal: true
            },
            {
                label: 'Account Password',
                value: 'CardDAVPassword',
                type: 'password',
                helpText: 'The Card DAV password',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100,
                noDefaultVal: true
            },
            {
                label: 'Use SSL ',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                helpText: 'This feature enables to encrypt the data transmitted over networks, ensuring secure and private communication.',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'CardDAVUseSSL',
                noDefaultVal: true
            }
        ]
    },
    {
        label: 'Exchange ActiveSync',
        image: exchangeActiveSync,
        underDev: true,
        payloadIdentifier: 'com.example.myeaspayload',
        payloadType: 'com.apple.eas.account',
        displayName: 'Exchange ActiveSync',
        description: 'Exchange ActiveSync',
        channel: 'DEVICE',
        show: ['SA', 'MA'],
        platforms: ['ios'],
        helpText: 'Use this section to define settings for connecting to your Exchange server',
        configured: false,
        fields: [
            {
                label: 'Exchange Active Sync Host',
                value: 'Host',
                noDefaultVal: true,
                helpText: 'Microsoft exchange server',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 50
            },
            {
                label: 'Use SSL',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                helpText: 'This feature enables to encrypt the data transmitted over networks, ensuring secure and private communication.',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                value: 'SSL',
                defaultVal: false
            },
            {
                label: 'User Name',
                value: 'UserName_Exchange',
                saveKey: 'UserName',
                noDefaultVal: true,
                helpText: 'Name for the Exchange Active Sync account',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 50
            },
            {
                label: 'Email Address',
                value: 'EmailAddress_exchange',
                saveKey: 'EmailAddress',
                noDefaultVal: true,
                helpText: 'Enter the email address of the account',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 100
            },
            {
                label: 'Use OAuth Authentication',
                helpText:
                    'This feature enables OAuth authentication securely authorizes and authenticates users without exposing their credentials.',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                value: 'OAuth',
                defaultVal: true,
                inputType: 'checkbox',
                true: [
                    {
                        label: 'OAuth Sign-In URL',
                        value: 'OAuthSignInURL',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText: 'Required for Mac. The URL for OAuth authentication (if auto-discovery is not used).',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 50
                    },
                    {
                        label: 'OAuth Token Request URL',
                        value: 'OAuthTokenRequestURL',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText: 'Optional. The URL that this account should use for token requests via OAuth. iPad and iPhone only.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 50
                    }
                ],
                false: [
                    {
                        label: 'Password',
                        value: 'Password_exchange',
                        saveKey: 'Password',
                        noDefaultVal: true,
                        type: 'password',
                        helpText: 'Enter the password for the account',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 20
                    },
                    {
                        label: 'Override Previous Password',
                        helpText: 'This feature enables to override the pevious password.',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        value: 'OverridePreviousPassword',
                        defaultVal: false
                    }
                ]
            },
            {
                label: 'Past Days of Mail to Sync',
                value: 'MailNumberOfPastDaysToSync',
                defaultVal: 7,
                helpText: 'The number of past days of mail to synchronize',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                options: [
                    { label: 'No Limit', value: 0 },
                    { label: '1 day', value: 1 },
                    { label: '3 days', value: 3 },
                    { label: '1 week', value: 7 },
                    { label: '2 weeks', value: 14 },
                    { label: '1 month', value: 31 }
                ]
            },
            {
                label: 'Restrict Messages to be Moved',
                inputType: 'checkbox',
                helpText: 'This feature enables the messages to move within the messages application.',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                value: 'PreventMove_Exchange',
                saveKey: 'PreventMove',
                defaultVal: false
            },
            {
                label: 'Restict Recent Addresess to be Synced',
                helpText: 'This feature enables recent saved address to be synced',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                value: 'disableMailRecentsSyncing_Exchange',
                saveKey: 'disableMailRecentsSyncing',
                defaultVal: false
            },
            {
                label: 'Allow Mail Drop',
                inputType: 'checkbox',
                helpText:
                    'This feature enable to send large email attachments by uploading them to iCloud and sharing a download link with recipients.',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                value: 'allowMailDrop_Exchange',
                saveKey: 'allowMailDrop',
                defaultVal: false
            },
            {
                label: 'Use only in mail',
                inputType: 'checkbox',
                helpText: 'Send outgoing mail from this account only from mail app',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                value: 'PreventAppSheet_Exchange',
                saveKey: 'PreventAppSheet',
                defaultVal: false
            },
            {
                label: 'Enable S/MIME Signing',
                helpText: 'Enabling S/MIME signing in Apple devices and services allows users to digitally sign their emails.',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                defaultVal: false,
                value: 'SMIMESigningEnabled_Exchange',
                saveKey: 'SMIMESigningEnabled',
                fields: [
                    {
                        label: 'Allow User to Enable or Disable S/MIME Signin',
                        helpText:
                            'This feature enables users to enable or disable S/MIME signing for their emails, granting them control over the digital signature status.',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        enableOn: 'SMIMESigningEnabled_Exchange',
                        value: 'SMIMESigningUserOverrideable_Exchange',
                        saveKey: 'SMIMESigningUserOverrideable',
                        defaultVal: false
                    }
                ]
            },
            {
                label: 'Enable S/MIME Encryption by Default',
                helpText:
                    'This feature enable S/MIME encryption by default for enhanced email privacy and protection of sensitive information.',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                value: 'SMIMEEncryptByDefault_Exchange',
                saveKey: 'SMIMEEncryptByDefault',
                defaultVal: false
            },
            {
                label: 'Enable Per Message Encryption Switch',
                helpText: 'This feature enable per message encryption',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                value: 'SMIMEEnableEncryptionPerMessageSwitch_Exchange',
                saveKey: 'SMIMEEnableEncryptionPerMessageSwitch',
                defaultVal: false
            },
            {
                label: 'Enabled Services:',
                inputType: 'heading',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                fieldClass: 'fw-medium text-info mb-1 fs-12',
                helpText: 'Enabled services for this account. At least one of them should be enabled'
            },
            {
                label: 'Mail',
                fieldClass: 'fw-medium',
                value: 'EnableMail',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                inputType: 'checkbox'
            },
            {
                label: 'Contacts',
                fieldClass: 'fw-medium',
                value: 'EnableContacts',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                inputType: 'checkbox'
            },
            {
                label: 'Calenders',
                fieldClass: 'fw-medium',
                value: 'EnableCalendars',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                inputType: 'checkbox'
            },
            {
                label: 'Reminders',
                fieldClass: 'fw-medium',
                value: 'EnableReminders',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                inputType: 'checkbox'
            },
            {
                label: 'Notes',
                fieldClass: 'fw-medium',
                value: 'EnableNotes',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                inputType: 'checkbox'
            },
            {
                label: 'Account Modification:',
                inputType: 'heading',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                fieldClass: 'fw-medium text-info mb-1 fs-12',
                helpText: 'Allow users to modify the state of the following services'
            },
            {
                label: 'Account Modification Mail',
                helpText: 'This feature enables modifying account settings in Apple mail.',
                fieldClass: 'fw-medium',
                value: 'EnableMailUserOverridable',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                inputType: 'checkbox'
            },
            {
                label: 'Account Modification Contacts',
                helpText: 'This feature enables modifying the contacts.',
                fieldClass: 'fw-medium',
                value: 'EnableContactsUserOverridable',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                inputType: 'checkbox'
            },
            {
                label: 'Account Modification Calenders',
                helpText: 'This feature enables modifying calenders',
                fieldClass: 'fw-medium',
                value: 'EnableCalendarsUserOverridable',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                inputType: 'checkbox'
            },
            {
                label: 'Account Modification Reminders',
                helpText: 'This feature enables modifying reminders',
                fieldClass: 'fw-medium',
                value: 'EnableRemindersUserOverridable',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                inputType: 'checkbox'
            },
            {
                label: 'Account Modification Notes',
                helpText: 'This feature enables modifying notes',
                fieldClass: 'fw-medium',
                value: 'EnableNotesUserOverridable',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                inputType: 'checkbox'
            }
            /*
             * {
             *     label: 'Communication Service Rule',
             *     value: 'Communicationservicerule',
             *     helpText: 'Choose a default app to be used when calling contacts from this account',
             *     inputType: 'json',
             *     placeHolder: 'Enter',
             *     maxLength: 20,
             *     show: ['SA', 'MA'],
             *           platforms: ['ios'],
             *     jsonFields: [
             *         {
             *             label: 'Default Service Handlers',
             *             value: 'DefaultServiceHandlers',
             *             helpText: 'The default handlers to use for contacts from this account',
             *             inputType: 'json',
             *             placeHolder: 'Enter',
             *             show: ['SA', 'MA'],
             *           platforms: ['ios'],
             *             maxLength: 50,
             *             jsonFields: [
             *                 {
             *                     label: 'AudioCall',
             *                     value: 'AudioCall_exchange',
             *                     saveKey: 'AudioCall',
             *                     helpText:
             *                         'The bundle identifier for the default application that handles audio calls to contacts from this account.',
             *                     inputType: 'policyInput',
             *                     placeHolder: 'Enter',
             *                     show: ['SA', 'MA'],
             *           platforms: ['ios'],
             *                     maxLength: 50
             *                 }
             *             ]
             *         }
             *     ]
             * }
             */
        ]
    },
    {
        label: 'Exchange Web Services',
        image: exchangeActiveSync,
        underDev: true,
        payloadIdentifier: 'com.apple.ews.account',
        payloadType: 'com.apple.ews.account',
        displayName: 'Exchange Web Services',
        description: 'Exchange Web Services',
        channel: 'DEVICE',
        show: ['SA', 'MA'],
        platforms: ['macos'],
        helpText: 'Use this section to to configure an Exchange Web Services account for Contacts, Mail, Notes, Reminders, and Calendar.',
        configured: false,
        fields: [
            {
                label: 'Allow Mail Drop',
                helpText: 'If true, the system enables Mail Drop.',
                fieldClass: 'fw-medium',
                value: 'allowMailDrop_ews',
                saveKey: 'allowMailDrop',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Authentication Certificate UUID',
                value: 'AuthenticationCertificateUUID',
                noDefaultVal: true,
                helpText: 'The UUID of the certificate payload within the same profile to use for the identity credential.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'Email Address',
                value: 'EmailAddress_ews',
                saveKey: 'EmailAddress',
                noDefaultVal: true,
                helpText:
                    'The full email address for the account. If the email address string isn’t present in the payload, the device prompts for it during profile installation.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'External Host',
                value: 'ExternalHost',
                noDefaultVal: true,
                helpText: 'The external server address.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'External Path',
                value: 'ExternalPath',
                noDefaultVal: true,
                helpText: 'The external server path.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'External Port',
                value: 'ExternalPort',
                noDefaultVal: true,
                helpText: 'The external server port number.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                type: 'number',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxValue: 9999
            },
            {
                label: 'External SSL',
                helpText: 'If true, the system enables SSL for connections to the external server.',
                fieldClass: 'fw-medium',
                value: 'ExternalSSL',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Host',
                value: 'Host_ews',
                saveKey: 'Host',
                noDefaultVal: true,
                helpText: 'The Exchange server host name or IP address. Ignored if using OAuth.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'OAuth',
                helpText: 'If true, the system enables OAuth for authentication. Don’t specify a password if OAuth is true.',
                fieldClass: 'fw-medium',
                value: 'OAuth',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'OAuth Sign-In URL',
                value: 'OAuthSignInURL_ews',
                saveKey: 'OAuthSignInURL',
                noDefaultVal: true,
                helpText:
                    'The URL to load into a web view for authentication through OAuth when autodiscovery isn’t used. This setting requires a Host value.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'Password',
                value: 'Password_ews',
                saveKey: 'Password',
                noDefaultVal: true,
                helpText: 'The password of the account. Use only with encrypted profiles.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'Path',
                value: 'Path_ews',
                saveKey: 'Path',
                noDefaultVal: true,
                helpText: 'The server path.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'Payload Certificate UUID',
                value: 'PayloadCertificateUUID_ews',
                saveKey: 'PayloadCertificateUUID',
                noDefaultVal: true,
                helpText: 'The UUID of the certificate payload within the same profile to use for the identity credential.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'Port',
                value: 'Port_ews',
                saveKey: 'Port',
                noDefaultVal: true,
                helpText: 'The server port number.',
                inputType: 'policyInput',
                type: 'number',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxValue: 9999
            },
            {
                label: 'Use SSL',
                helpText: 'If true, the system enables SSL.',
                fieldClass: 'fw-medium',
                value: 'SSL_ews',
                saveKey: 'SSL',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Username',
                value: 'UserName_ews',
                saveKey: 'UserName',
                noDefaultVal: true,
                helpText:
                    'The user name for this Exchange account. Required for noninteractive installation, such as through MDM. If missing, the system prompts the user for it during interactive profile installation.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos']
            }
        ]
    },
    {
        label: 'Google Account',
        image: googleAccount,
        underDev: false,
        payloadIdentifier: 'com.example.mygoogleaccountpayload',
        payloadType: 'com.apple.google-oauth',
        helpText: 'Google Account',
        configured: false,
        show: ['SA', 'MA'],
        platforms: ['ios'],
        channel: 'DEVICE',
        displayName: 'Google Account',
        description: 'Google Account',
        fields: [
            {
                label: 'Account Description',
                value: 'AccountDescription',
                noDefaultVal: true,
                notMandatory: true,
                helpText: 'The display name of the account',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 250
            },
            {
                label: 'Account Name',
                value: 'AccountName',
                noDefaultVal: true,
                notMandatory: true,
                helpText: 'Enter the full name of user account',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 100
            },
            {
                label: 'Email Address',
                value: 'EmailAddress_google',
                saveKey: 'EmailAddress',
                noDefaultVal: true,
                helpText: 'Enter the Google email address of the account',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 100
            }
            /*
             * {
             *     label: 'Communication Service Rules',
             *     value: 'CommunicationServiceRules',
             *     helpText: 'Choose a default app to be used when calling contacts from this account',
             *     inputType: 'json',
             *     placeHolder: 'Enter',
             *     show: ['SA', 'MA'],
             *           platforms: ['ios'],
             *     maxLength: 20,
             *     jsonFields: [
             *         {
             *             label: 'Default Service Handlers',
             *             value: 'DefaultServiceHandlers',
             *             helpText: 'A dictionary that defines which app to use for audio calls from this account',
             *             inputType: 'json',
             *             placeHolder: 'Enter',
             *             maxLength: 20,
             *             show: ['SA', 'MA'],
             *           platforms: ['ios'],
             *             jsonFields: [
             *                 {
             *                     label: 'AudioCall',
             *                     value: 'AudioCall_google',
             *                     saveKey: 'AudioCall',
             *                     helpText:
             *                         'The bundle identifier for the default application that handles audio calls to contacts from this account.',
             *                     inputType: 'policyInput',
             *                     placeHolder: 'Enter',
             *                     show: ['SA', 'MA'],
             *           platforms: ['ios'],
             *                     maxLength: 50
             *                 }
             *             ]
             *         }
             *     ]
             * }
             */
        ]
    },
    {
        label: 'LDAP',
        image: ldap,
        underDev: true,
        payloadIdentifier: 'com.example.myldappayload',
        payloadType: 'com.apple.ldap.account',
        configured: false,
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        channel: 'USER',
        helpText: 'Use this section to access and manage directory services on networks for user authentication and authorization',
        fields: [
            {
                label: 'Account Description',
                value: 'LDAPAccountDescription',
                noDefaultVal: true,
                notMandatory: true,
                helpText: 'Enter the display name of the account (e.x. "Company LDAP Account")',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 250
            },
            {
                label: 'Account User Name',
                value: 'LDAPAccountUserName',
                noDefaultVal: true,
                notMandatory: true,
                helpText: 'Enter the user name for this LDAP account',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            },
            {
                label: 'Account Password',
                value: 'LDAPAccountPassword',
                noDefaultVal: true,
                type: 'password',
                notMandatory: true,
                helpText: 'The password for this LDAP account',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            },
            {
                label: 'Account Host Name',
                value: 'LDAPAccountHostName',
                noDefaultVal: true,
                helpText: 'Enter the LDAP host name or IP address',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            },
            {
                label: 'Use SSL',
                value: 'LDAPAccountUseSSL',
                defaultVal: true,
                helpText: 'This feature enables to encrypt the data transmitted over networks, ensuring secure and private communication.',
                inputType: 'checkbox',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                fieldClass: 'fw-medium'
            },
            {
                label: 'Search Settings',
                value: 'LDAPSearchSettings',
                helpText: 'Configure the Search settings for this LDAP server',
                inputType: 'tableObject',
                noDefaultVal: true,
                mandatory: ['LDAPSearchSettingDescription', 'LDAPSearchSettingScope', 'LDAPSearchSettingSearchBase'],
                tableContent: [
                    {
                        label: 'Description',
                        value: 'LDAPSearchSettingDescription',
                        inputType: 'policyInput',
                        noDefaultVal: true,
                        hideLabel: true,
                        columnClass: ''
                    },
                    {
                        value: 'LDAPSearchSettingScope',
                        inputType: 'dropdown',
                        options: [
                            { label: 'Base', value: 'LDAPSearchSettingScopeBase' },
                            { label: 'One Level', value: 'LDAPSearchSettingScopeOneLevel' },
                            { label: 'Subtree', value: 'LDAPSearchSettingScopeSubtree' }
                        ],
                        noDefaultVal: true,
                        hideLabel: true,
                        columnClass: 'width-200'
                    },
                    {
                        label: 'Search Base',
                        value: 'LDAPSearchSettingSearchBase',
                        inputType: 'policyInput',
                        noDefaultVal: true,
                        hideLabel: true,
                        columnClass: ''
                    }
                ],
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            }
        ]
    },
    {
        label: 'Mail',
        image: mail,
        underDev: true,
        payloadIdentifier: 'com.example.mymailpayload',
        payloadType: 'com.apple.mail.managed',
        show: ['SA', 'MA'],
        channel: 'USER',
        platforms: ['ios', 'macos'],
        helpText: 'Use this section to define settings for connecting to your POP or IMAP email accounts',
        configured: false,
        fields: [
            {
                label: 'Account Description',
                value: 'EmailAccountDescription',
                noDefaultVal: true,
                notMandatory: true,
                helpText: 'Enter the display name of the account (e.x. "Company Mail Account")',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 250
            },
            {
                label: 'Account Type',
                value: 'EmailAccountType',
                noDefaultVal: true,
                helpText: 'Select the protocol for accessing the email account',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 50,
                options: [
                    { label: 'IMAP', value: 'EmailTypeIMAP' },
                    { label: 'POP', value: 'EmailTypePOP' }
                ]
            },
            {
                label: 'User Display Name',
                value: 'EmailAccountName',
                noDefaultVal: true,
                notMandatory: true,
                helpText: 'The display name of the user',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            },
            {
                label: 'Email Address',
                value: 'EmailAddress_Mail',
                saveKey: 'EmailAddress',
                notMandatory: true,
                noDefaultVal: true,
                helpText: 'Enter the email address of the account',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            },
            {
                label: 'Restrict User to Move Messages from this Account',
                inputType: 'checkbox',
                helpText: 'This feature restricts the ability for messages to move within the Messages application.',
                fieldClass: 'fw-medium',
                value: 'PreventMove_Mail',
                saveKey: 'PreventMove',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos']
            },
            {
                label: 'Disable recent email sync',
                helpText: 'This feature disables the syncing of recently saved addresses.',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'disableMailRecentsSyncing_Mail',
                saveKey: 'disableMailRecentsSyncing',
                notMandatory: true,
                defaultVal: false
            },
            {
                label: 'Allow Mail Drop',
                helpText:
                    'This feature enable to send large email attachments by uploading them to iCloud and sharing a download link with recipients.',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'allowMailDrop_Mail',
                saveKey: 'allowMailDrop',
                defaultVal: false,
                notMandatory: true
            },
            {
                label: 'Use Only in Mail',
                helpText: 'Send outgoing mail from this account only from the mail app',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'PreventAppSheet_mail',
                saveKey: 'PreventAppSheet',
                defaultVal: false
            },
            {
                label: 'Enable S/MIME Signing',
                inputType: 'checkbox',
                helpText: 'Enabling S/MIME signing in Apple devices and services allows users to digitally sign their emails.',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'SMIMESigningEnabled_Mail',
                saveKey: 'SMIMESigningEnabled',
                defaultVal: false
            },
            {
                label: 'Allow User to Enable or Disable S/MIME Signing',
                helpText:
                    'This feature enables users to enable or disable S/MIME signing for their emails, granting them control over the digital signature status.',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'SMIMESigningUserOverrideable_Mail',
                saveKey: 'SMIMESigningUserOverrideable',
                defaultVal: false
            },
            {
                label: 'Allow User to Override Default S/MIME Encryption',
                helpText: 'Enabling this feature ensures that emails are encrypted by default using S/MIME.',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'SMIMEEncryptByDefaultUserOverrideable',
                defaultVal: false
            },
            {
                label: 'Enable S/MIME Encryption by Default',
                helpText:
                    'This feature enable S/MIME encryption by default for enhanced email privacy and protection of sensitive information.',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'SMIMEEncryptByDefault_Mail',
                saveKey: 'SMIMEEncryptByDefault',
                defaultVal: false
            },
            {
                label: 'Enable S/MIME Encryption',
                helpText: ' Enabling this feature enforces S/MIME encryption for all outgoing emails',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'SMIMEEncryptionEnabled',
                defaultVal: false
            },
            {
                label: 'Enable Per Message Encryption Switch',
                helpText: 'This feature enable per message encryption',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'SMIMEEnableEncryptionPerMessageSwitch_Mail',
                saveKey: 'SMIMEEnableEncryptionPerMessageSwitch',
                defaultVal: false
            },
            // Incoming
            {
                label: 'Mail Server Name and Port',
                helpText: 'The mail server host details.',
                inputType: 'multiInput',
                noDefaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                seperation: ':',
                inputs: [
                    {
                        class: 'widthPer-80',
                        type: 'text',
                        value: 'IncomingMailServerHostName_mail',
                        saveKey: 'IncomingMailServerHostName',
                        inputType: 'policyInput',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        noDefaultVal: true,
                        placeHolder: 'Account Host'
                    },
                    {
                        class: 'widthPer-20',
                        type: 'number',
                        value: 'IncomingMailServerPortNumber_mail',
                        saveKey: 'IncomingMailServerPortNumber',
                        inputType: 'policyInput',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        placeHolder: 'Port',
                        noDefaultVal: true,
                        minValue: 0,
                        maxValue: 65535
                    }
                ],
                maxLength: 50
            },
            {
                label: 'Incoming User Name',
                value: 'IncomingMailServerUsername',
                notMandatory: true,
                noDefaultVal: true,
                helpText: 'The user name used to connect to the server for incoming mail',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            },
            {
                label: 'Incoming Authentication Type',
                value: 'IncomingMailServerAuthentication',
                noDefaultVal: true,
                helpText: 'The authentication method for the incoming mail server',
                inputType: 'dropdown',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                placeHolder: 'Select',
                options: [
                    { label: 'None', value: 'EmailAuthNone' },
                    { label: 'Password', value: 'EmailAuthPassword', optionsField: 'EmailAuthPassword' },
                    { label: 'MD5 Challenge-Response', value: 'EmailAuthCRAMMD5', optionsField: 'EmailAuthCRAMMD5' },
                    { label: 'NTLM', value: 'EmailAuthNTLM', optionsField: 'EmailAuthNTLM' },
                    { label: 'HTTP MD5 Digest', value: 'EmailAuthHTTPMD5', optionsField: 'EmailAuthHTTPMD5' }
                ],
                clearFields: [{ key: 'IncomingPassword', value: '' }],
                EmailAuthPassword: [
                    {
                        label: 'Password',
                        value: 'IncomingPassword',
                        notMandatory: true,
                        noDefaultVal: true,
                        type: 'password',
                        helpText: 'The password for the incoming mail server',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    }
                ],
                EmailAuthCRAMMD5: [
                    {
                        label: 'Password',
                        value: 'IncomingPassword',
                        defaultVal: false,
                        notMandatory: true,
                        type: 'password',
                        helpText: 'The password for the incoming mail server',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    }
                ],
                EmailAuthNTLM: [
                    {
                        label: 'Password',
                        value: 'IncomingPassword',
                        noDefaultVal: true,
                        type: 'password',
                        helpText: 'The password for the incoming mail server',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    }
                ],
                EmailAuthHTTPMD5: [
                    {
                        label: 'Password',
                        value: 'IncomingPassword',
                        noDefaultVal: true,
                        type: 'password',
                        helpText: 'The password for the incoming mail server',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    }
                ]
            },
            {
                label: 'Incoming Use SSL ',
                helpText: 'This feature is enabled incoming Use SSL to encrypt and secure your incoming emails.',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'IncomingMailServerUseSSL',
                notMandatory: true,
                defaultVal: false
            },
            // Outgoing
            {
                label: 'Mail Server Name and Port',
                helpText: 'The mail server host details.',
                inputType: 'multiInput',
                noDefaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                seperation: ':',
                inputs: [
                    {
                        class: 'widthPer-80',
                        type: 'text',
                        value: 'OutgoingMailServerHostName_mail',
                        saveKey: 'OutgoingMailServerHostName',
                        inputType: 'policyInput',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        noDefaultVal: true,
                        placeHolder: 'Account Host'
                    },
                    {
                        class: 'widthPer-20',
                        type: 'number',
                        value: 'OutgoingMailServerPortNumber_mail',
                        saveKey: 'OutgoingMailServerPortNumber',
                        inputType: 'policyInput',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        placeHolder: 'Port',
                        noDefaultVal: true,
                        minValue: 0,
                        maxValue: 65535
                    }
                ],
                maxLength: 50
            },
            {
                label: 'Outgoing User Name',
                value: 'OutgoingMailServerUsername',
                notMandatory: true,
                noDefaultVal: true,
                helpText: 'The user name used to connect to the server for outgoing mail',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                maxLength: 100
            },
            {
                label: 'Outgoing Authentication Type',
                value: 'OutgoingMailServerAuthentication',
                noDefaultVal: true,
                helpText: 'The authentication method for the outgoing mail server',
                inputType: 'dropdown',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                placeHolder: 'Select',
                options: [
                    { label: 'None', value: 'EmailAuthNone' },
                    { label: 'Password', value: 'EmailAuthPassword' },
                    { label: 'MD5 Challenge-Response', value: 'EmailAuthCRAMMD5' },
                    { label: 'NTLM', value: 'EmailAuthNTLM' },
                    { label: 'HTTP MD5 Digest', value: 'EmailAuthHTTPMD5' }
                ],
                clearFields: [{ key: 'OutgoingPassword', value: '' }],
                EmailAuthPassword: [
                    {
                        label: 'Password',
                        value: 'OutgoingPassword',
                        notMandatory: true,
                        noDefaultVal: true,
                        type: 'password',
                        helpText: 'The password for the outgoing mail server',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    }
                ],
                EmailAuthCRAMMD5: [
                    {
                        label: 'Password',
                        value: 'OutgoingPassword',
                        notMandatory: true,
                        noDefaultVal: true,
                        type: 'password',
                        helpText: 'The password for the outgoing mail server',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    }
                ],
                EmailAuthNTLM: [
                    {
                        label: 'Password',
                        value: 'OutgoingPassword',
                        notMandatory: true,
                        noDefaultVal: true,
                        type: 'password',
                        helpText: 'The password for the outgoing mail server',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    }
                ],
                EmailAuthHTTPMD5: [
                    {
                        label: 'Password',
                        value: 'OutgoingPassword',
                        notMandatory: true,
                        noDefaultVal: true,
                        type: 'password',
                        helpText: 'The password for the outgoing mail server',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100
                    }
                ]
            },
            {
                label: 'Outgoing Use SSL ',
                helpText: 'This feature is enabled outgoing Use SSL to encrypt and secure your incoming emails.',
                inputType: 'checkbox',
                fieldClass: 'fw-medium',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'OutgoingMailServerUseSSL',
                notMandatory: true,
                defaultVal: false
            }
        ]
    },
    {
        label: 'SCEP',
        image: airPlaySecurity,
        underDev: true,
        payloadIdentifier: 'com.example.myscepcertpayload',
        payloadType: 'com.apple.security.scep',
        configured: false,
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        value: 'PayloadContent',
        channel: 'USER',
        helpText: 'Use this section to define settings for obtaining certificates from SCEP servers',
        fields: [
            {
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                value: 'PayloadContent',
                inputType: 'json',
                notMandatory: true,
                jsonFields: [
                    {
                        label: 'URL',
                        value: 'SCEP_URL',
                        saveKey: 'URL',
                        helpText: 'Enter the base URL for the SCEP server',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100,
                        noDefaultVal: true
                    },
                    {
                        label: 'Name',
                        value: 'SCEP_Name',
                        notMandatory: true,
                        saveKey: 'Name',
                        helpText: 'Enter the name of the instance: CA-IDENT',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100,
                        noDefaultVal: true
                    },
                    {
                        label: 'Subject',
                        value: 'Subject',
                        notMandatory: true,
                        helpText: 'Representation of a X.500 name. Example O=Company Name/CN=Foo',
                        inputType: 'policyInput',
                        type: 'oids',
                        placeHolder: 'Representation of a X.500 name. Example O=Company Name/CN=Foo',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 250,
                        noDefaultVal: true
                    },
                    {
                        label: 'NT Principal Name',
                        value: 'ntPrincipalName',
                        parent: 'SubjectAltName',
                        notMandatory: true,
                        helpText: 'Enter an NT principal name for use in the certificate request',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100,
                        noDefaultVal: true
                    },
                    {
                        label: 'DNS Name',
                        value: 'dNSName',
                        notMandatory: true,
                        parent: 'SubjectAltName',
                        helpText: 'The DNS Name',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100,
                        noDefaultVal: true
                    },
                    {
                        label: 'RFC 822 Name',
                        value: 'rfc822Name',
                        notMandatory: true,
                        parent: 'SubjectAltName',
                        helpText: 'The RFC 822 (email address)',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100,
                        noDefaultVal: true
                    },
                    {
                        label: 'Uniform Resource Identifier',
                        notMandatory: true,
                        value: 'uniformResourceIdentifier',
                        parent: 'SubjectAltName',
                        helpText: 'The Uniform Resource Identifier.',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100,
                        noDefaultVal: true
                    },
                    {
                        label: 'Retries',
                        value: 'Retries',
                        notMandatory: true,
                        defaultVal: 3,
                        helpText:
                            'The frequency of polling attempts made to the SCEP server for a signed certificate before discontinuing the process',
                        inputType: 'policyInput',
                        type: 'number',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 20
                    },
                    {
                        label: 'Retry Delay',
                        value: 'RetryDelay',
                        notMandatory: true,
                        defaultVal: 10,
                        helpText: 'Enter the number of seconds to wait between poll attempts',
                        inputType: 'policyInput',
                        type: 'number',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 20
                    },
                    {
                        label: 'Challenge',
                        value: 'Challenge',
                        notMandatory: true,
                        noDefaultVal: true,
                        helpText: 'Used as the pre-shared secret for automatic enrollment',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 50
                    },
                    {
                        label: 'Key Size',
                        value: 'Keysize',
                        defaultVal: 1024,
                        notMandatory: true,
                        helpText: 'Keysize in bits. 4096 is nit supported in iOS 13, iPadOS 12 or tvOS 13 and earlier',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        options: [
                            { label: '1024', value: 1024 },
                            { label: '2048', value: 2048 },
                            { label: '4096', value: 4096 }
                        ]
                    },
                    {
                        label: 'Fingerprint',
                        notMandatory: true,
                        helpText: 'This feature enables finger print.',
                        value: 'CAFingerprint',
                        inputType: 'upload',
                        placeHolder: 'Enter',
                        convert: 'hex',
                        maxFileSize: '4MB',
                        maxFiles: 1,
                        accept: ['.p12'],
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 50,
                        noDefaultVal: true
                    }
                ]
            }
        ]
    },
    {
        label: 'Cellular',
        image: data,
        underDev: true,
        payloadIdentifier: 'com.example.mycellularnetworkpayload',
        payloadType: 'com.apple.cellular',
        show: ['SA', 'MA'],
        channel: 'DEVICE',
        platforms: ['ios'],
        helpText: 'Use this section to configure APN settings to communicate device with the mobile carrier network',
        configured: false,
        fields: [
            {
                label: 'APN',
                value: 'APNsItem',
                helpText: 'Access Point Name (APN) configuration',
                inputType: 'json',
                placeHolder: 'Select',
                noDefaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                jsonFields: [
                    {
                        label: 'Default APN Name',
                        value: 'Cellular_Name',
                        saveKey: 'Name',
                        helpText: 'Default APN Name - Enter the Access Point Name for the default APN configuration',
                        inputType: 'policyInput',
                        noDefaultVal: true,
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 100
                    },
                    {
                        label: 'Default APN Authentication Type',
                        value: 'AuthenticationType',
                        helpText: 'Default APN Authentication Type - Type of Authentication used by the default APN configuration',
                        inputType: 'dropdown',
                        noDefaultVal: true,
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        options: [
                            { label: 'PAP', value: 'PAP' },
                            { label: 'CHAP', value: 'CHAP' }
                        ]
                    },
                    {
                        label: 'Default APN User Name',
                        value: 'Username_cellular',
                        saveKey: 'Username',
                        helpText: 'Default APN User Name - Enter the user name for authenticating the connection',
                        inputType: 'policyInput',
                        noDefaultVal: true,
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 100
                    },
                    {
                        label: 'Default APN Password',
                        value: 'Password_cellular',
                        saveKey: 'Password',
                        noDefaultVal: true,
                        type: 'password',
                        helpText: 'Default APN Password - Enter the password for authenticating the connection',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 100
                    },
                    {
                        label: 'Proxy Server and Port',
                        helpText: 'Enter the proxy server details.',
                        inputType: 'multiInput',
                        noDefaultVal: true,
                        seperation: ':',
                        inputs: [
                            {
                                class: 'widthPer-80',
                                type: 'text',
                                value: 'ProxyServer_cellular',
                                inputType: 'policyInput',
                                noDefaultVal: true,
                                placeHolder: 'Proxy Server Host',
                                saveKey: 'ProxyServer'
                            },
                            {
                                class: 'widthPer-20',
                                type: 'number',
                                value: 'ProxyServerPort_cellular',
                                inputType: 'policyInput',
                                placeHolder: 'Port',
                                saveKey: 'ProxyServerPort',
                                noDefaultVal: true,
                                minValue: 0,
                                maxValue: 65535
                            }
                        ],
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        maxLength: 50
                    },
                    {
                        label: 'Allowed Protocol Mask',
                        value: 'AllowedProtocolMask',
                        helpText: 'The Internet Protocol versions that the system supports. Available in iOS 10.3 and later',
                        inputType: 'dropdown',
                        noDefaultVal: true,
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        options: [
                            { label: 'IPv4', value: 1 },
                            { label: 'IPv6', value: 2 },
                            { label: 'Both', value: 3 }
                        ]
                    },
                    {
                        label: 'Allowed Protocol Mask in Roaming',
                        value: 'AllowedProtocolMaskInRoaming',
                        helpText: 'The Internet Protocol versions that the system supports while roaming. Available in iOS 10.3 and later.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        noDefaultVal: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        options: [
                            { label: 'IPv4', value: 1 },
                            { label: 'IPv6', value: 2 },
                            { label: 'Both', value: 3 }
                        ]
                    },
                    {
                        label: 'Allowed Protocol Mask in Domestic Roaming',
                        value: 'AllowedProtocolMaskInDomesticRoaming',
                        helpText: 'The Internet Protocol versions that the system supports while roaming. Available in iOS 10.3 and later.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        noDefaultVal: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        options: [
                            { label: 'IPv4', value: 1 },
                            { label: 'IPv6', value: 2 },
                            { label: 'Both', value: 3 }
                        ]
                    }
                ]
            }
        ]
    },
    {
        label: 'Notifications',
        image: notification,
        underDev: false,
        payloadIdentifier: 'com.example.mynotificationspayload',
        payloadType: 'com.apple.notificationsettings',
        configured: false,
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        channel: 'USER',
        helpText: 'Use this section to configure notification settings for each app',
        fields: [
            {
                inputType: 'array',
                addButtonText: '+ Add New Setting',
                PayloadType: 'com.apple.notificationsettings',
                label: 'Notification Settings',
                value: 'NotificationSettings',
                helpText: 'Enter the name of an appor its bundle identifier to search for it, Click to continue',
                placeHolder: 'Enter',
                maxLength: 20,
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                cardFields: [
                    { label: 'App Bundle ID', value: 'BundleIdentifier' },
                    { label: 'Notifications Enabled', value: 'NotificationsEnabled', type: 'boolean' },
                    { label: 'Notification Grouping', value: 'GroupingType', type: 'dropdown' }
                ],
                arrayFields: [
                    {
                        label: 'App Bundle ID',
                        value: 'BundleIdentifier',
                        helpText: 'The bundle identifier of the app to which to apply these notification settings',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100,
                        noDefaultVal: true
                    },
                    {
                        label: 'Notifications Enabled',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'NotificationsEnabled',
                        helpText: 'This feature enables notifications.',
                        noDefaultVal: true
                    },
                    {
                        label: 'Show in Notification Center',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'ShowInNotificationCenter',
                        helpText: 'This enables to receive notifications.',
                        noDefaultVal: true
                    },
                    {
                        label: 'Sounds',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'SoundsEnabled',
                        helpText: 'This feature enables notification sounds.',
                        noDefaultVal: true
                    },
                    {
                        label: 'Badge App Icon',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'BadgesEnabled',
                        helpText: 'This feature  show unread email counts on the app icon badge.',
                        noDefaultVal: true
                    },

                    {
                        label: 'Show In Lock Screen',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'ShowInLockScreen',
                        helpText: 'This feature enables to display email notifications.',
                        noDefaultVal: true
                    },
                    {
                        label: 'Show In CarPlay',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'ShowInCarPlay',
                        helpText:
                            'This feature enables receiving email notifications and access the innbox through  carplay while driving. ',
                        noDefaultVal: true
                    },
                    {
                        label: 'Critical Alerts',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'CriticalAlertEnabled',
                        helpText: 'This feature enables urgent messages bypass Do Not Disturb and mute settings for prompt delivery.',
                        noDefaultVal: true
                    },
                    {
                        label: 'Unlocked Alert Style',
                        value: 'AlertType',
                        helpText: 'The type of alert for notifications for this app',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        noDefaultVal: true,
                        options: [
                            { label: 'None', value: 0 },
                            { label: 'Temporary Banner', value: 1 },
                            { label: 'Persistent Banner', value: 2 }
                        ]
                    },
                    {
                        label: 'Notification Grouping',
                        value: 'GroupingType',
                        helpText: 'The type of grouping for notifications for this app',
                        inputType: 'dropdown',
                        noDefaultVal: true,
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        options: [
                            { label: 'Automatic: Group notifications into app-specified groups', value: 0 },
                            { label: 'By app: Group notifications into one group', value: 1 },
                            { label: 'Off: Do not group notifications', value: 2 }
                        ]
                    },
                    {
                        label: 'Show Preview',
                        value: 'PreviewType',
                        helpText:
                            'The type previews for notifications. This key overrides the value at Settings>Notifications>Show Previews.',
                        inputType: 'dropdown',
                        noDefaultVal: true,
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        options: [
                            { label: 'Always: Previews will be shown when the device is locked and unlocked', value: 0 },
                            { label: 'When Unlocked: Previews will only be shown when the device is unlocked', value: 1 },
                            { label: 'Never: Previews will never be shown', value: 2 }
                        ]
                    }
                ]
            }
        ]
    },
    /*
     * {
     *     label: 'Conference Room Display',
     *     image: conferenceRoom,
     *     payloadIdentifier: 'com.example.myconferenceroompayload',
     *     payloadType: 'com.apple.conferenceroomdisplay',
     *     helpText:
     *         'Use this section to enable the setup and management of iOS devices (typically iPads) to function as dedicated displays for conference room scheduling and information',
     *     configured: false,
     *     fields: [
     *         {
     *             label: 'Custom Message',
     *             value: 'Message',
     *             helpText: 'Enter the message that has to be displayed on-screen in Conference Room Display Mode',
     *             inputType: 'policyInput',
     *             placeHolder: 'Enter',
     *             show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *             maxLength: 250
     *         }
     *     ]
     * },
     */
    /*
     * {
     *     label: 'TV Remote',
     *     image: remote,
     *     payloadIdentifier: 'com.example.mytvremotepayload',
     *     payloadType: 'com.apple.tvremote',
     *     configured: false,
     *     helpText: 'Use this section to control permitted iOS devices with remote',
     *     fields: [
     *         {
     *             label: 'Allowed Remotes (tvOS only)',
     *             value: 'AllowedRemotes',
     *             helpText: 'Add the MAC addresses of permitted iOS devices to this list to enable remote control with only these devices',
     *             inputType: 'json',
     *             placeHolder: 'Enter',
     *             maxLength: 20,
     *             show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *             jsonFields: [
     *                 {
     *                     label: 'Remote Device ID',
     *                     value: 'RemoteDeviceID_Remote',
     *                     saveKey: 'RemoteDeviceID',
     *                     helpText:
     *                         'The MAC address of a permitted iOS device that can control this Apple TV. Use the format xx:xx:xx:xx:xx:xx, which isn’t case-sensitive.',
     *                     inputType: 'policyInput',
     *                     placeHolder: 'Enter',
     *                     show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                     type: 'number',
     *                     maxLength: 50
     *                 },
     *                 {
     *                     label: 'TV Device Name',
     *                     value: 'TVDeviceName_Remote',
     *                     saveKey: 'TVDeviceName',
     *                     helpText: 'The name of an Apple TV device that the system permits this iOS device to control',
     *                     inputType: 'policyInput',
     *                     placeHolder: 'Enter',
     *                     show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                     maxLength: 50
     *                 }
     *             ]
     *         },
     *         {
     *             label: 'Allowed TVs (iOS only)',
     *             value: 'AllowedTVs',
     *             helpText:
     *                 'Add the MAC addresses of authorized tvOS devices into this list to limit remote control access exclusively to these devices',
     *             inputType: 'json',
     *             placeHolder: 'Enter',
     *             maxLength: 20,
     *             show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *             jsonFields: [
     *                 {
     *                     label: 'Remote Device ID',
     *                     value: 'RemoteDeviceID_TV',
     *                     saveKey: 'RemoteDeviceID',
     *                     helpText:
     *                         'The MAC address of a permitted iOS device that can control this Apple TV. Use the format xx:xx:xx:xx:xx:xx, which isn’t case-sensitive.',
     *                     inputType: 'policyInput',
     *                     placeHolder: 'Enter',
     *                     type: 'number',
     *                     show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                     maxLength: 20
     *                 },
     *                 {
     *                     label: 'TV Device Name',
     *                     value: 'TVDeviceName_TV',
     *                     saveKey: 'TVDeviceName',
     *                     helpText: 'The name of an Apple TV device that the system permits this iOS device to control',
     *                     inputType: 'policyInput',
     *                     placeHolder: 'Enter',
     *                     show: ['SA', 'MA'],
     *                   platforms: ['ios'],
     *                     maxLength: 50
     *                 }
     *             ]
     *         }
     *     ]
     * },
     */
    {
        label: 'Lock Screen Message',
        image: lockScreen,
        underDev: false,
        payloadIdentifier: 'com.example.mylockscreenpayload',
        payloadType: 'com.apple.shareddeviceconfiguration',
        helpText: 'Use this section to set up a lock screen message',
        configured: false,
        channel: 'DEVICE',
        show: ['SA', 'MA'],
        platforms: ['ios'],
        fields: [
            {
                label: 'Lock Screen Footnote',
                value: 'LockScreenFootnote',
                helpText: 'Enter the message that has to be displayed on the login window and lock screen',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                notMandatory: true,
                platforms: ['ios'],
                maxLength: 100,
                noDefaultVal: true
            },
            {
                label: 'Asset Tag Information',
                value: 'AssetTagInformation',
                helpText: 'Enter the message that has to be displayed at the bottom of the login window and lock screen',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                notMandatory: true,
                show: ['SA', 'MA'],
                platforms: ['ios'],
                maxLength: 100,
                noDefaultVal: true
            }
        ]
    },
    {
        label: 'Web Clips',
        image: webClip,
        underDev: false,
        payloadIdentifier: 'com.example.mywebclippayload',
        payloadType: 'com.apple.webClip.managed',
        schemaType: 'array',
        schemaValue: 'webclip',
        channel: 'USER',
        helpText:
            'Use this section to create shortcuts or bookmarks deployed to iOS devices to provide quick access to specific web pages or web applications',
        configured: false,
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        fields: [
            {
                labe: 'Web Clips',
                value: 'webclip',
                addButtonText: '+ Add New Web Clip',
                inputType: 'array',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                PayloadType: 'com.apple.webClip.managed',
                cardFields: [
                    { label: 'Label', value: 'Label' },
                    { label: 'URL', value: 'Web_Clips_URL' }
                ],
                arrayFields: [
                    {
                        label: 'Label',
                        value: 'Label',
                        helpText: 'Enter the name to be displayed for the Web Clip',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100,
                        noDefaultVal: true
                    },
                    {
                        label: 'URL',
                        value: 'Web_Clips_URL',
                        saveKey: 'URL',
                        helpText: 'Enter the URL to be displayed when opening the Web Clip',
                        inputType: 'policyInput',
                        placeHolder: 'Enter',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        maxLength: 100,
                        noDefaultVal: true
                    },
                    {
                        label: 'Icon',
                        value: 'Icon',
                        helpText:
                            'The PNG icon to show on the Home screen. If not set, the system displays a white square. For best results, provide a square image that’s no larger than 400 x 400 pixels and less than 1 MB when uncompressed. The graphics file is automatically scaled and cropped to fit, if necessary, and converted to PNG format. Web clip icons are 144 x 144 pixels for iPad devices with a Retina display, and 114 x 114 pixels for iPhone devices. To prevent the device from adding a shine to the image, set Precomposed to true.',
                        inputType: 'upload',
                        convert: 'base64',
                        maxFiles: 1,
                        maxFileSize: '1MB',
                        acceptedFileTypes: ['image/png', 'image/jpeg', 'image/jpg'],
                        accept: ['.png', '.jpg', '.jpeg'],
                        placeHolder: 'Choose',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        imageValidateSizeMinWidth: 400,
                        imageValidateSizeMaxWidth: 400,
                        imageValidateSizeMinHeight: 400,
                        imageValidateSizeMaxHeight: 400,
                        noDefaultVal: true
                    },
                    {
                        label: 'Removable',
                        helpText: 'Enable removal of the Web Clip',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'IsRemovable',
                        noDefaultVal: true
                    },
                    {
                        label: 'Precomposed Icon ',
                        helpText: 'The icon will be displayed with no added visual effects',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'Precomposed',
                        noDefaultVal: true
                    },
                    {
                        label: 'Full Screen',
                        helpText: 'Displayed the web clip as a full screen application',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos'],
                        value: 'FullScreen',
                        noDefaultVal: true
                    },
                    {
                        label: 'Ignore Manifest Scope',
                        helpText: 'Allow web pages that are not in the manifest to load in full screen mode',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        show: ['SA', 'MA'],
                        platforms: ['ios'],
                        value: 'IgnoreManifestScope',
                        noDefaultVal: true
                    }
                ]
            }
        ]
    },
    {
        label: 'Branding',
        image: branding,
        underDev: false,
        payloadIdentifier: 'com.example.mdmservice.wallpaper',
        payloadType: 'com.apple.wallpaper',
        configured: false,
        show: ['SA', 'MA'],
        channel: 'device',
        platforms: ['ios', 'macos'],
        helpText:
            'Branding for iOS Mobile Device Management (MDM) involves customizing the appearance and functionality of MDM solutions to reflect a company’s brand identity.',
        fields: [
            {
                label: 'Home Screen Wallpaper',
                value: 'homeScreen',
                key: 'Image',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                path: ['homeScreen', 'Settings', 0, 'Image'],
                inputType: 'upload',
                convert: 'base64',
                maxFileSize: '4MB',
                helpText:
                    'Sets the wallpaper for the Home screen on iPads and iPhones. The maximum file size is 4MB, and the device must be supervised.',
                maxFiles: 1,
                acceptedFileTypes: ['image/*'],
                accept: ['.png', '.jpg', '.jpeg', '.svg'],
                Where: 2,
                Item: 'Wallpaper',
                noDefaultVal: true
            },
            {
                label: 'Lock Screen Wallpaper',
                value: 'lockScreen',
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                path: ['lockScreen', 'Settings', 0, 'Image'],
                inputType: 'upload',
                convert: 'base64',
                key: 'Image',
                maxFileSize: '4MB',
                helpText:
                    'Sets the wallpaper for the Lock screen on iPads and iPhones. The maximum file size is 4MB, and the device must be supervised.',
                maxFiles: 1,
                acceptedFileTypes: ['image/*'],
                accept: ['.png', '.jpg', '.jpeg', '.svg'],
                Where: 1,
                Item: 'Wallpaper',
                noDefaultVal: true
            }
        ]
    },
    {
        label: 'Dock',
        image: Dock,
        underDev: false,
        payloadIdentifier: 'com.example.mydockpayload',
        payloadType: 'com.apple.dock',
        configured: false,
        show: ['SA', 'MA'],
        channel: 'DEVICE',
        platforms: ['macos'],
        helpText:
            'Use this section to configure the dock in macOS for customizing application shortcuts, adjusting appearance settings, and managing dock behavior preferences.',
        fields: [
            {
                label: 'Allow Dock Fix/Correction',
                helpText:
                    'Enable this setting to allow external processes, such as apps or system updates, to automatically modify the Docks configuration without user intervention.',
                fieldClass: 'fw-medium',
                value: 'AllowDockFixupOverride',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Enable Auto-Hide for Dock',
                helpText: 'Enabling this automatically hides the Dock when not in use.',
                fieldClass: 'fw-medium',
                value: 'autohide',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Lock Auto-Hide Setting for Dock',
                helpText: 'Enabling this option prevents users from changing or modifying the Docks auto-hide setting.',
                fieldClass: 'fw-medium',
                value: 'autohide-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Enable Lock for Dock Items',
                helpText: 'Enabling this option locks the items in the Dock, preventing users from adding, removing, or rearranging them.',
                fieldClass: 'fw-medium',
                value: 'contents-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Control Double-Click Action on Title Bar',
                value: 'dblclickbehavior',
                defaultVal: 'minimize',
                helpText:
                    'Enabling this option controls the action when a user double-clicks the title bar of a window, such as minimizing or zooming.',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                options: [
                    { label: 'Minimize', value: 'minimize' },
                    { label: 'Maximize', value: 'maximize' },
                    { label: 'None', value: 'none' }
                ]
            },
            {
                label: 'Lock Double-Click Behavior for Windows',
                helpText: 'Enabling this option locks the double-click behavior for windows, preventing users from changing it.',
                fieldClass: 'fw-medium',
                value: 'dblclickbehavior-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Set Maximum Size for Dock Icons with Magnification',
                value: 'largesize',
                defaultVal: 16,
                helpText: 'Enabling this option sets the maximum size for Dock icons when magnification is enabled.',
                inputType: 'policyInput',
                type: 'number',
                maxValue: 128,
                minValue: 16,
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['macos']
            },
            {
                label: 'Activate Animated Transitions for Application Launches',
                helpText:
                    'Enabling Launchanim in the Dock activates animated transitions for launching applications, enhancing the visual experience.',
                fieldClass: 'fw-medium',
                value: 'launchanim',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Lock App Launch Animation Settings',
                helpText: 'Enabling Launchanim-immutable in the Dock prevents changes to the animated launch behavior of applications.',
                fieldClass: 'fw-medium',
                value: 'launchanim-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Enable Icon Magnification on Hover',
                helpText: 'Enabling this option allows you to change the magnification size of icons as you move the pointer over them..',
                fieldClass: 'fw-medium',
                value: 'magnification',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Lock Magnification settings for Dock Icons',
                helpText: 'Enabling this option locks the magnification setting for the Dock.',
                fieldClass: 'fw-medium',
                value: 'magnify-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Disable Changes to Dock Icon Size during Magnification',
                helpText: 'Enabling this option locks the size of Dock icons during magnification, preventing users from changing it.',
                fieldClass: 'fw-medium',
                value: 'magsize-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Configure User Access to Specific Folders',
                value: 'MCXDockSpecialFolders',
                defaultVal: 'AddDockMCXMyApplicationsFolder',
                helpText:
                    'Enabling this setting allows admins to specify certain folders, such as Documents, Downloads, or specific shared folders for user access.',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                options: [
                    { label: 'Add Dock MCX My Applications Folder', value: 'AddDockMCXMyApplicationsFolder' },
                    { label: 'Add Dock MCX Documents Folder', value: 'AddDockMCXDocumentsFolder' },
                    { label: 'Add Dock MCX Shared Folder', value: 'AddDockMCXSharedFolder' },
                    { label: 'Add Dock MCX Original Network Home Folder', value: 'AddDockMCXOriginalNetworkHomeFolder' }
                ]
            },
            {
                label: 'Enable this option to minimize app windows to the Dock',
                value: 'mineffect',
                defaultVal: 'genie',
                helpText: 'Enabling this option allows you to minimize an app window to the Dock.',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                options: [
                    { label: 'Genie', value: 'genie' },
                    { label: 'Scale', value: 'scale' }
                ]
            },
            {
                label: 'Enable to Lock Minimize Animation settings',
                value: 'mineffect-immutable',
                helpText: 'Enabling this option locks the minimizing animation setting.',
                fieldClass: 'fw-medium',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Minimize Windows to App Icon',
                helpText: 'Enabling this option sets windows to minimize directly into the app icon on the Mac.',
                fieldClass: 'fw-medium',
                value: 'minimize-to-application',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Lock Windows to minimize to Dock',
                helpText: 'Enabling this option locks the system to minimize windows into their dock.',
                fieldClass: 'fw-medium',
                value: 'minintoapp-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Set Dock Position on Screen',
                value: 'orientation',
                defaultVal: 'bottom',
                helpText: 'Enabling this option allows you to set the position of the Dock on the screen (left, bottom, or right).',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                options: [
                    { label: 'Bottom', value: 'bottom' },
                    { label: 'Left', value: 'left' },
                    { label: 'Right', value: 'right' }
                ]
            },
            {
                label: 'Lock Dock Position and Orientation',
                helpText:
                    'Enabling this option locks the position of the Dock on the screen, preventing users from changing its orientation.',
                fieldClass: 'fw-medium',
                value: 'position-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Show Indicator for Open Apps in Dock',
                helpText: 'Enabling this option shows a dot below an apps icon in the Dock, indicating it is open and running.',
                fieldClass: 'fw-medium',
                value: 'show-process-indicators',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Lock Display of Open App Indicators in Dock',
                helpText: 'Enabling this option in the Dock locks the display of indicators for open applications.',
                fieldClass: 'fw-medium',
                value: 'showindicators-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Show Recent Applications and Documents',
                helpText:
                    'Enabling this feature displays recent applications and documents for quick access to your most frequently used items.',
                fieldClass: 'fw-medium',
                value: 'show-recents',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Lock Display of Recent Apps and Documents',
                helpText: 'Enabling this feature locks the display of recent applications and documents, preventing any changes.',
                fieldClass: 'fw-medium',
                value: 'showrecents-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Disable Dock Icon Size Adjustment',
                helpText: 'Enabling size-immutable in the Dock prevents users from adjusting the size of the Dock icons.',
                fieldClass: 'fw-medium',
                value: 'size-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Enable Window Grouping as Tabs',
                value: 'windowtabbing',
                defaultVal: 'manual',
                helpText: 'Enabling this feature allows multiple windows of the same application to be grouped together as tabs.',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                options: [
                    { label: 'Manual', value: 'manual' },
                    { label: 'Always', value: 'always' },
                    { label: 'Full Screen', value: 'fullscreen' }
                ]
            },
            {
                label: 'Disable Window Tabbing Changes',
                helpText: 'Enabling this feature prevents users from changing the window tabbing behavior.',
                fieldClass: 'fw-medium',
                value: 'windowtabbing-immutable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            }
        ]
    },
    {
        label: 'FileVault',
        image: Filevault,
        underDev: false,
        payloadIdentifier: 'com.example.myfdefilevaultpayload',
        payloadType: 'com.apple.MCX.FileVault2',
        configured: false,
        show: ['SA', 'MA'],
        channel: 'DEVICE',
        platforms: ['macos'],
        helpText:
            'Use this section to configure FileVault in macOS for enabling disk encryption, securing your data, and protecting your information from unauthorized access.',
        fields: [
            {
                label: 'Enable FileVault',
                helpText: 'Enabling this feature encrypts the contents of  the  startup disk.',
                fieldClass: 'fw-medium',
                value: 'Enable',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Account User Name',
                value: 'Username',
                noDefaultVal: true,
                helpText: 'Enter the username to manage the file vault settings.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'User Must Provide Missing Information',
                helpText: 'Enabling this feature prompts the user to enter any missing information required for FileVault setup.',
                fieldClass: 'fw-medium',
                value: 'UserEntersMissingInfo',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Enable Recovery Key for FileVault',
                helpText:
                    'Enabling this feature allows you to use a recovery key to unlock your FileVault-encrypted disk if you forget your password.',
                fieldClass: 'fw-medium',
                value: 'UseRecoveryKey',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Store Password in Keychain',
                helpText: 'Enabling this feature allows you to store your FileVault recovery key in your Keychain.',
                fieldClass: 'fw-medium',
                value: 'UseKeychain',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Display Recovery Key',
                helpText: 'Enabling this feature shows your FileVault recovery key for viewing and saving.',
                fieldClass: 'fw-medium',
                value: 'ShowRecoveryKey',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Certificate UUID for Payload',
                value: 'PayloadCertificateUUID',
                noDefaultVal: true,
                helpText: 'Enabling this feature associates a unique identifier (UUID) with the FileVault payload certificate.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'FileVault Password',
                value: 'Password_filevault',
                saveKey: 'Password',
                noDefaultVal: true,
                helpText:
                    'Enabling this feature requires you to set a password for your FileVault-encrypted disk, ensuring secure access to your data.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'Output Path for FileVault Configuration',
                value: 'OutputPath',
                noDefaultVal: true,
                helpText: 'Enabling this feature specifies the output path for FileVault-related logs or recovery keys.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100
            },
            {
                label: 'Force Enable FileVault During Setup',
                helpText: 'Enabling this feature forces the FileVault setup assistant to run during the initial setup process.',
                fieldClass: 'fw-medium',
                value: 'ForceEnableInSetupAssistant',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Enable Encryption Delay',
                value: 'Defer',
                noDefaultVal: true,
                helpText: 'Enabling this feature allows you to postpone the encryption process.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                type: 'number',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 4
            },
            {
                label: 'Max Bypass Attempts for User Login Deferral',
                value: 'DeferForceAtUserLoginMaxBypassAttemptsDefer',
                helpText:
                    'Enabling this feature allows you to set the maximum number of bypass attempts for user login when FileVault is enabled',
                inputType: 'policyInput',
                type: 'number',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                noDefaultVal: true,
                minValue: -1,
                maxValue: 9999
            },
            {
                label: 'Do Not Prompt on Logout',
                helpText: 'Enabling this option prevents prompts to enable FileVault when the user logs out.',
                fieldClass: 'fw-medium',
                value: 'DeferDontAskAtUserLogout',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            }
        ]
    },
    {
        label: 'Time Machine',
        image: timeMachine,
        underDev: false,
        payloadIdentifier: 'com.example.mytimemachinepayload',
        payloadType: 'com.apple.MCX.TimeMachine',
        configured: false,
        show: ['SA', 'MA'],
        channel: 'DEVICE',
        platforms: ['macos'],
        helpText:
            'Use this section to remotely configure and manage Time Machine settings devices. It enables configuration of automated backups, specifying backup destinations, and enforcing backup policies to ensure data security and recovery.',
        fields: [
            {
                label: 'Backup Location',
                value: 'BackupDestURL',
                helpText:
                    'The Backup Test URL feature in Time Machine for macOS checks the integrity and accessibility of backup files by testing a specific URL, ensuring the backup process to store the data.',
                inputType: 'policyInput',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                noDefaultVal: true,
                minLength: 100
            },
            {
                label: 'Auto Backup',
                helpText:
                    'The Auto Backup feature in macOS automatically saves files and system settings to a designated location at regular intervals, ensuring consistent backups and easy recovery in case of data loss.',
                fieldClass: 'fw-medium',
                value: 'AutoBackup',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Backup All Volumes',
                helpText:
                    'Backup All Volumes in macOS lets users back up all connected drives, ensuring that both system files and personal documents are safely stored to prevent data loss.',
                fieldClass: 'fw-medium',
                value: 'BackupAllVolumes',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Skip System Files',
                helpText:
                    'Skip System Files in macOS allows users to exclude system files from backups, focusing on user data and applications to reduce backup size and time while preserving important personal files.',
                fieldClass: 'fw-medium',
                value: 'BackupSkipSys',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Mobile Backup',
                helpText:
                    'Mobile Backup in macOS refers to the ability to back up data from mobile devices, such as iPhones and iPads, to a Mac.',
                fieldClass: 'fw-medium',
                value: 'MobileBackups',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Backup Size Limit',
                value: 'BackupSizeMB',
                notMandatory: true,
                helpText:
                    'Backup Size in macOS refers to the total amount of storage space required to store a backup of files, applications, and system settings.',
                inputType: 'policyInput',
                placeHolder: 'Enter',
                type: 'number',
                maxValue: 999,
                minValue: 0,
                defaultVal: 0,
                show: ['SA', 'MA'],
                platforms: ['macos']
            },
            {
                label: 'Base Paths',
                value: 'BasePaths',
                val: 'basepath',
                helpText:
                    'Base Path in macOS is the main directory where backup files are stored, serving as the root location for organizing and accessing backup data.',
                inputType: 'tableInput',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                notMandatory: true,
                noDefaultVal: true,
                minLength: 100
            },
            {
                label: 'Paths to skip',
                value: 'SkipPaths',
                val: 'skippath',
                helpText:
                    'Skip Paths allows users to remove specific files or folders from backups, making the backup smaller and faster by excluding unwanted data.',
                inputType: 'tableInput',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                noDefaultVal: true,
                notMandatory: true,
                minLength: 100
            }
        ]
    },
    {
        label: 'Time Zone',
        image: timeZone,
        underDev: false,
        payloadIdentifier: 'com.example.mytimeserverpayload',
        payloadType: 'com.apple.MCX',
        configured: false,
        show: ['SA', 'MA'],
        channel: 'DEVICE',
        platforms: ['macos'],
        helpText:
            'Use this section to remotely set and manage the time zone settings on macOS devices to ensure that devices are synchronized with the correct time zone, which is essential for scheduling, logging, and time-sensitive applications.',
        fields: [
            {
                label: 'Time Server',
                value: 'timeServer',
                helpText:
                    'Time Server in macOS keeps the system clock accurate by syncing with a network time source and adjusting for the correct time zone to ensure all devices show the same time.',
                inputType: 'policyInput',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                noDefaultVal: true,
                minLength: 100
            },
            {
                label: 'Time Zone',
                value: 'timeZone',
                helpText: 'Time Zone in macOS is a setting that determines the local time for a specific area.',
                inputType: 'policyInput',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                noDefaultVal: true,
                minLength: 100
            }
        ]
    },
    {
        label: 'Font',
        image: fonts,
        underDev: false,
        payloadIdentifier: 'com.example.myfontpayload',
        payloadType: 'com.apple.font',
        configured: false,
        show: ['SA', 'MA'],
        channel: 'USER',
        platforms: ['macos'],
        helpText:
            'Use this section to customize the font settings on the device. You can adjust the size, style and appearance of text to improve readability and personalize the device to match your preferences.',
        fields: [
            {
                label: 'Name',
                value: 'Name',
                helpText: 'The user-visible name for the font. This field is replaced by the actual name of the font after installation.',
                inputType: 'policyInput',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                notMandatory: true,
                noDefaultVal: true,
                minLength: 100
            },
            {
                label: 'Font',
                helpText:
                    'In macOS, a font represents a specific style and size of text characters used for displaying text on screen and in printed materials. Fonts on macOS are used within applications to define the appearance of text, such as its weight (bold, light), size, and design (serif, sans-serif).',
                value: 'Font',
                inputType: 'upload',
                placeHolder: 'Enter',
                convert: 'base64',
                maxFileSize: '4MB',
                maxFiles: 1,
                accept: ['.eot', '.ttf', 'woff', 'woff2'],
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 50,
                noDefaultVal: true
            }
        ]
    },
    {
        label: 'Energy Saver',
        image: energySaver,
        underDev: false,
        payloadIdentifier: 'com.example.myenergysaverpayload',
        payloadType: 'com.apple.MCX',
        configured: false,
        show: ['SA', 'MA'],
        channel: 'DEVICE',
        platforms: ['macos'],
        helpText:
            'Use this section to manage and configure Energy Saver settings on macOS devices to remotely adjust options such as sleep settings, screen brightness and power management preferences to optimize energy consumption.',
        fields: [
            {
                label: 'Delete FileVault Key on Standby',
                helpText: 'The encryption key for the hard drive is removed when the device goes into standby mode.',
                fieldClass: 'fw-medium',
                value: 'DestroyFVKeyOnStandby',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Disable Sleep',
                helpText:
                    'Disable Sleep in Energy Saver on macOS prevents the computer from automatically entering sleep mode, keeping it active and ready for use at all times.',
                fieldClass: 'fw-medium',
                value: 'SleepDisabled',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Scheduling for Power On',
                value: 'RepeatingPowerOn',
                helpText:
                    'Scheduling for Power On in Energy Saver on macOS lets users choose specific times for their Mac to automatically turn on, ensuring its ready for use when needed.',
                inputType: 'json',
                notMandatory: true,
                placeHolder: 'Select',
                noDefaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                jsonFields: [
                    {
                        label: 'Event Type',
                        saveKey: 'eventtype',
                        value: 'eventtype_on',
                        helpText:
                            'Event Type in Energy Saver on macOS indicates the different actions or triggers, like waking the computer or starting a scheduled task, helping to manage the Mac`s response to power-related events.',
                        inputType: 'dropdown',
                        options: [
                            { label: 'Wake', value: 'wake' },
                            { label: 'Power ON', value: 'poweron' },
                            { label: 'Wake Power ON', value: 'wakepoweron' },
                            { label: 'Sleep', value: 'sleep' },
                            { label: 'Shutdown', value: 'shutdown' },
                            { label: 'Restart', value: 'restart' }
                        ],
                        noDefaultVal: true,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'Time',
                        saveKey: 'time',
                        value: 'time_on',
                        placeholder: 'Select Time',
                        inputType: 'timePicker',
                        options: { enableTime: true, noCalendar: true, dateFormat: 'h:i K' },
                        saveType: 'minutes',
                        helpText: 'Time in macOS refers to the current clock setting that displays the hours and minutes.',
                        noDefaultVal: true,
                        notMandatory: true,
                        maxLength: 100,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'Weekdays',
                        saveKey: 'weekdays',
                        value: 'weekdays_on',
                        defaultVal: 1,
                        notMandatory: true,
                        helpText:
                            'Weekdays  refers to the specific days of the week when scheduled power settings or actions, like waking the computer, can take place.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: 'MON', value: 1 },
                            { label: 'TUE', value: 2 },
                            { label: 'WED', value: 4 },
                            { label: 'THU', value: 8 },
                            { label: 'FRI', value: 16 },
                            { label: 'SAT', value: 32 },
                            { label: 'SUN', value: 64 }
                        ]
                    }
                ]
            },
            {
                label: 'Scheduling for Power Off',
                value: 'RepeatingPowerOff',
                helpText:
                    'Scheduling for Power Off in Energy Saver on macOS allows users to set specific times for their Mac to automatically turn off, ensuring it conserves energy when not in use.',
                inputType: 'json',
                placeHolder: 'Select',
                notMandatory: true,
                noDefaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                jsonFields: [
                    {
                        label: 'Event Type',
                        saveKey: 'eventtype',
                        value: 'eventtype_off',
                        helpText: 'This setting controls how long the disk stays inactive before it goes to sleep to save power.',
                        inputType: 'dropdown',
                        noDefaultVal: true,
                        options: [
                            { label: 'Wake', value: 'wake' },
                            { label: 'Power ON', value: 'poweron' },
                            { label: 'Wake Power ON', value: 'wakepoweron' },
                            { label: 'Sleep', value: 'sleep' },
                            { label: 'Shutdown', value: 'shutdown' },
                            { label: 'Restart', value: 'restart' }
                        ],
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'Time',
                        saveKey: 'time',
                        value: 'time_off',
                        placeholder: 'Select Time',
                        inputType: 'timePicker',
                        options: { enableTime: true, noCalendar: true, dateFormat: 'h:i K' },
                        saveType: 'minutes',
                        helpText: 'Time in macOS refers to the current clock setting that displays the hours and minutes.',
                        noDefaultVal: true,
                        notMandatory: true,
                        type: 'number',
                        minValue: 1,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'Weekdays',
                        saveKey: 'weekdays',
                        value: 'weekdays_off',
                        notMandatory: true,
                        defaultVal: 1,
                        helpText:
                            'Weekdays  refers to the specific days of the week when scheduled power settings or actions, like waking the computer, can take place.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: 'MON', value: 1 },
                            { label: 'TUE', value: 2 },
                            { label: 'WED', value: 4 },
                            { label: 'THU', value: 8 },
                            { label: 'FRI', value: 16 },
                            { label: 'SAT', value: 32 },
                            { label: 'SUN', value: 64 }
                        ]
                    }
                ]
            },
            {
                label: 'The settings for a desktop computer',
                value: 'Com.apple.EnergySaver.desktop.ACPower',
                uncontrolledId: 'energysaverDesktop',
                helpText:
                    'Desktop Computer Settings are options that let users change how their desktop computer works, including power management, display settings, and other preferences.',
                inputType: 'json',
                notMandatory: true,
                placeHolder: 'Select',
                noDefaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                jsonFields: [
                    {
                        label: 'Automatic Restart On Power Loss',
                        saveKey: 'Automatic Restart On Power Loss',
                        value: 'Automatic Restart On Power Loss desktopComputer',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText:
                            'Automatic Restart on Power Loss in macOS Energy Saver automatically turns your Mac back on after a power outage, ensuring it starts up again without needing manual action.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Disk Sleep Timer',
                        saveKey: 'Disk Sleep Timer',
                        value: 'Disk Sleep Timer desktopComputer',
                        uncontrolledId: 'DiskSleepTimerdesktopComputer',
                        helpText: 'This setting controls how long the disk stays inactive before it goes to sleep to save power.',
                        inputType: 'policyInput',
                        type: 'number',
                        notMandatory: true,
                        noDefaultVal: true,
                        maxValue: 180,
                        minValue: 1,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'Display Sleep Timer',
                        saveKey: 'Display Sleep Timer',
                        value: 'Display Sleep Timer desktopComputer',
                        uncontrolledId: 'DisplaySleepTimerdesktopComputer',
                        helpText: 'This setting controls how long the screen stays inactive before it goes to sleep to save power.',
                        inputType: 'policyInput',
                        type: 'number',
                        noDefaultVal: true,
                        notMandatory: true,
                        maxValue: 180,
                        minValue: 1,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'System Sleep Timer',
                        saveKey: 'System Sleep Timer',
                        value: 'System Sleep Timer desktopComputer',
                        uncontrolledId: 'SystemSleepTimerdesktopComputer',
                        helpText:
                            'A System Sleep Timer automatically puts your device to sleep after a set period of no activity to save battery life.',
                        inputType: 'policyInput',
                        type: 'number',
                        noDefaultVal: true,
                        notMandatory: true,
                        maxValue: 180,
                        minValue: 1,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'Wake on LAN',
                        saveKey: 'Wake on LAN',
                        value: 'Wake on LAN desktopComputer',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText:
                            'Wake on LAN allows your Mac to wake up from sleep when it receives a network signal. This feature helps you access your Mac remotely without keeping it fully on, saving energy.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Wake On Modem Ring',
                        saveKey: 'Wake On Modem Ring',
                        value: 'Wake On Modem Ring desktopComputer',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText: 'Wake on modem ring allows the system to wake up from sleep when a modem ring is detected.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Dynamic Power Step',
                        saveKey: 'Dynamic Power Step',
                        value: 'Dynamic Power Step desktopComputer',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText: 'Automatically adjusts the processor`s speed to save power and improve performance when needed.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Reduce Processor Speed',
                        saveKey: 'Reduce Processor Speed',
                        value: 'Reduce Processor Speed desktopComputer',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText: 'Reduce processsor speed allow us lowers the processor speed to save energy and reduce heat.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    }
                ]
            },
            {
                label: 'The Settings for a laptop using AC power',
                value: 'Com.apple.EnergySaver.portable.ACPower',
                uncontrolledId: 'energysaverPortable',
                helpText:
                    'These settings manage how the laptop uses power while plugged into AC, ensuring optimal performance and efficient power use.',
                inputType: 'json',
                notMandatory: true,
                placeHolder: 'Select',
                noDefaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                jsonFields: [
                    {
                        label: 'Automatic Restart On Power Loss',
                        saveKey: 'Automatic Restart On Power Loss',
                        value: 'Automatic Restart On Power Loss ACPower',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText:
                            'Automatic Restart on Power Loss in macOS Energy Saver automatically turns your Mac back on after a power outage, ensuring it starts up again without needing manual action.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Disk Sleep Timer',
                        saveKey: 'Disk Sleep Timer',
                        value: 'Disk Sleep Timer ACPower',
                        uncontrolledId: 'DiskSleepTimerACPower',
                        helpText: 'This setting controls how long the disk stays inactive before it goes to sleep to save power.',
                        inputType: 'policyInput',
                        type: 'number',
                        notMandatory: true,
                        noDefaultVal: true,
                        maxValue: 180,
                        minValue: 1,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'Display Sleep Timer',
                        saveKey: 'Display Sleep Timer',
                        value: 'Display Sleep Timer ACPower',
                        uncontrolledId: 'DisplaySleepTimerACPower',
                        helpText: 'This setting controls how long the screen stays inactive before it goes to sleep to save power.',
                        inputType: 'policyInput',
                        type: 'number',
                        noDefaultVal: true,
                        notMandatory: true,
                        maxValue: 180,
                        minValue: 1,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'System Sleep Timer',
                        saveKey: 'System Sleep Timer',
                        value: 'System Sleep Timer ACPower',
                        uncontrolledId: 'SystemSleepTimerACPower',
                        helpText:
                            'A System Sleep Timer automatically puts your device to sleep after a set period of no activity to save battery life.',
                        inputType: 'policyInput',
                        type: 'number',
                        noDefaultVal: true,
                        notMandatory: true,
                        maxValue: 180,
                        minValue: 1,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'Wake on LAN',
                        saveKey: 'Wake on LAN',
                        value: 'Wake on LAN ACPower',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText:
                            'Wake on LAN allows your Mac to wake up from sleep when it receives a network signal. This feature helps you access your Mac remotely without keeping it fully on, saving energy.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Wake On Modem Ring',
                        saveKey: 'Wake On Modem Ring',
                        value: 'Wake On Modem Ring ACPower',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText: 'Wake on modem ring allows the system to wake up from sleep when a modem ring is detected.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Dynamic Power Step',
                        saveKey: 'Dynamic Power Step',
                        value: 'Dynamic Power Step ACPower',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText: 'Automatically adjusts the processor`s speed to save power and improve performance when needed.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Reduce Processor Speed',
                        saveKey: 'Reduce Processor Speed',
                        value: 'Reduce Processor Speed ACPower',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText: 'Reduce processsor speed allow us lowers the processor speed to save energy and reduce heat.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    }
                ]
            },
            {
                label: 'The Settings for a laptop using battery power',
                value: 'Com.apple.EnergySaver.portable.BatteryPower',
                uncontrolledId: 'energysaverBattery',
                helpText:
                    'These settings manage how the laptop uses power while plugged into AC, ensuring optimal performance and efficient power use.',
                inputType: 'json',
                notMandatory: true,
                placeHolder: 'Select',
                noDefaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                jsonFields: [
                    {
                        label: 'Automatic Restart On Power Loss',
                        saveKey: 'Automatic Restart On Power Loss',
                        value: 'Automatic Restart On Power Loss Battery',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText:
                            'Automatic Restart on Power Loss in macOS Energy Saver automatically turns your Mac back on after a power outage, ensuring it starts up again without needing manual action.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Disk Sleep Timer',
                        saveKey: 'Disk Sleep Timer',
                        value: 'Disk Sleep Timer Battery',
                        uncontrolledId: 'DiskSleepTimerACPower',
                        helpText: 'This setting controls how long the disk stays inactive before it goes to sleep to save power.',
                        inputType: 'policyInput',
                        type: 'number',
                        notMandatory: true,
                        noDefaultVal: true,
                        maxValue: 180,
                        minValue: 1,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'Display Sleep Timer',
                        saveKey: 'Display Sleep Timer',
                        value: 'Display Sleep Timer Battery',
                        uncontrolledId: 'DisplaySleepTimerACPower',
                        helpText: 'This setting controls how long the screen stays inactive before it goes to sleep to save power.',
                        inputType: 'policyInput',
                        type: 'number',
                        noDefaultVal: true,
                        notMandatory: true,
                        maxValue: 180,
                        minValue: 1,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'System Sleep Timer',
                        saveKey: 'System Sleep Timer',
                        value: 'System Sleep Timer Battery',
                        uncontrolledId: 'SystemSleepTimerACPower',
                        helpText:
                            'A System Sleep Timer automatically puts your device to sleep after a set period of no activity to save battery life.',
                        inputType: 'policyInput',
                        type: 'number',
                        noDefaultVal: true,
                        notMandatory: true,
                        maxValue: 180,
                        minValue: 1,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    },
                    {
                        label: 'Wake on LAN',
                        saveKey: 'Wake on LAN',
                        value: 'Wake on LAN Battery',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText:
                            'Wake on LAN allows your Mac to wake up from sleep when it receives a network signal. This feature helps you access your Mac remotely without keeping it fully on, saving energy.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Wake On Modem Ring',
                        saveKey: 'Wake On Modem Ring',
                        value: 'Wake On Modem Ring Battery',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText: 'Wake on modem ring allows the system to wake up from sleep when a modem ring is detected.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Dynamic Power Step',
                        saveKey: 'Dynamic Power Step',
                        value: 'Dynamic Power Step Battery',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText: 'Automatically adjusts the processor`s speed to save power and improve performance when needed.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    },
                    {
                        label: 'Reduce Processor Speed',
                        saveKey: 'Reduce Processor Speed',
                        value: 'Reduce Processor Speed Battery',
                        noDefaultVal: true,
                        notMandatory: true,
                        helpText: 'Reduce processsor speed allow us lowers the processor speed to save energy and reduce heat.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        options: [
                            { label: '0 : False', value: 0 },
                            { label: '1 : True', value: 1 }
                        ]
                    }
                ]
            }
        ]
    },
    {
        label: 'System Extensions',
        image: systemExtension,
        underDev: false,
        payloadIdentifier: 'com.example.mysystemextensionpayload',
        payloadType: 'com.apple.nsextension',
        configured: false,
        show: ['SA', 'MA'],
        channel: 'DEVICE',
        platforms: ['macos'],
        helpText:
            'Use this section to manage and configure system extensions on macOS devices to enable advanced capabilities, such as security tools and network monitoring.',
        fields: [
            {
                label: 'Allow Users to approve system extensions',
                helpText: 'This setting lets users approve system extensions before they are installed on the device.',
                fieldClass: 'fw-medium',
                value: 'AllowUserOverrides_system',
                saveKey: 'AllowUserOverrides',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Allowed Team Identifiers',
                saveKey: 'AllowedTeamIdentifiers',
                value: 'AllowedTeamIdentifiers_system',
                val: 'AllowedTeamIdentifier',
                helpText:
                    'This setting lists the team identifiers for developers whose system extensions are allowed to run on the device.',
                inputType: 'tableInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100,
                noDefaultVal: true
            }
            /*
             * {
             *     label: 'Allowed System Extensions',
             *     value: 'AllowedSystemExtensions',
             *     helpText:
             *         'A dictionary of approved system extensions on the computer. The dictionary maps the team identifiers (keys) to arrays of bundle identifiers, where the bundle identifier defines the system extension to be installed.',
             *     inputType: 'tableObject',
             *     noDefaultVal: true,
             *     mandatory: ['TeamIdentifier', 'AllowedSystemExtensions'],
             *     tableContent: [
             *         {
             *             label: 'Team Identifier',
             *             value: 'TeamIdentifier',
             *             inputType: 'policyInput',
             *             hideLabel: true,
             *             columnClass: '',
             *             noDefaultVal: true
             *         },
             *         {
             *             label: 'Allowed System Extensions',
             *             value: 'AllowedSystemExtensions',
             *             inputType: 'policyInput',
             *             noDefaultVal: true,
             *             hideLabel: true,
             *             columnClass: ''
             *         }
             *     ],
             *     placeHolder: 'Enter',
             *     show: ['SA', 'MA'],
             *     platforms: ['macos'],
             *     maxLength: 100
             * },
             * {
             *     label: 'Allowed System Extension Types',
             *     value: 'AllowedSystemExtensionTypes',
             *     helpText:
             *         'Enter the value to specify which types of system extensions are permitted to be installed and run on the device.',
             *     inputType: 'json',
             *     notMandatory: true,
             *     placeHolder: 'Select',
             *     noDefaultVal: true,
             *     show: ['SA', 'MA'],
             *     platforms: ['macos'],
             *     jsonFields: [
             *         {
             *             label: 'Team Identifier ',
             *             value: 'TeamIdentifier ',
             *             placeHolder:
             *                 'Enter the value to specify development teams that are authorized to install and run specific types of system extensions.',
             *             inputType: 'policyInput',
             *             noDefaultVal: true,
             *             notMandatory: true,
             *             maxLength: 50,
             *             show: ['SA', 'MA'],
             *             platforms: ['macos']
             *         },
             *         {
             *             label: 'Driver Extension ',
             *             helpText:
             *                 'A driver extension enables the system to interact with hardware devices, enhancing their functionality and performance.',
             *             fieldClass: 'fw-medium',
             *             value: 'DriverExtension',
             *             defaultVal: true,
             *             show: ['SA', 'MA'],
             *             platforms: ['macos'],
             *             inputType: 'checkbox'
             *         },
             *         {
             *             label: 'Network Extension',
             *             helpText:
             *                 'A network extension allows apps to customize network behavior and improve connectivity for network-related tasks.',
             *             fieldClass: 'fw-medium',
             *             value: 'NetworkExtension',
             *             defaultVal: true,
             *             show: ['SA', 'MA'],
             *             platforms: ['macos'],
             *             inputType: 'checkbox'
             *         },
             *         {
             *             label: 'Endpoint Security Extension',
             *             helpText:
             *                 'An endpoint security extension provides protection by monitoring and controlling system activities to enhance security against threats.',
             *             fieldClass: 'fw-medium',
             *             value: 'EndpointSecurityExtension',
             *             defaultVal: true,
             *             show: ['SA', 'MA'],
             *             platforms: ['macos'],
             *             inputType: 'checkbox'
             *         }
             *     ]
             * },
             * {
             *     label: 'Removable System Extensions',
             *     value: 'RemovableSystemExtensions',
             *     helpText: 'A list of system extensions that are allowed to remove themselves from the machine.',
             *     inputType: 'tableObject',
             *     noDefaultVal: true,
             *     mandatory: ['TeamIdentifier', 'RemovableSystemExtensions'],
             *     tableContent: [
             *         {
             *             label: 'Team Identifier',
             *             value: 'TeamIdentifier',
             *             inputType: 'policyInput',
             *             noDefaultVal: true,
             *             hideLabel: true,
             *             columnClass: ''
             *         },
             *         {
             *             label: 'Removable System Extensions',
             *             value: 'RemovableSystemExtensions',
             *             inputType: 'policyInput',
             *             noDefaultVal: true,
             *             hideLabel: true,
             *             columnClass: ''
             *         }
             *     ],
             *     placeHolder: 'Enter',
             *     show: ['SA', 'MA'],
             *     platforms: ['ios'],
             *     maxLength: 100
             * }
             */
        ]
    },
    {
        label: 'Kernel Extensions',
        image: kernelExtension,
        underDev: false,
        payloadIdentifier: 'com.example.mysystempolicykernalextensionspayload',
        payloadType: 'com.apple.syspolicy.kernel-extension-policy',
        configured: false,
        show: ['SA', 'MA'],
        channel: 'DEVICE',
        platforms: ['macos'],
        helpText:
            'Use this section to manage and control kernel extensions on macOS devices. Kernel extensions are modules that extend the capabilities of the macOS kernel, often used for hardware drivers or security tools.',
        fields: [
            {
                label: 'Allow Users to approve kernel extensions',
                helpText: 'This setting lets users approve system extensions before they are installed on the device.',
                fieldClass: 'fw-medium',
                saveKey: 'AllowUserOverrides',
                value: 'AllowUserOverrides_kernal',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Allow Non administrative users can approve kernel extensions',
                helpText:
                    'Allow Non-Administrative Users to Approve Kernel Extensions lets regular users approve specific kernel extensions on macOS, giving them more control while still needing their permission for security.',
                fieldClass: 'fw-medium',
                value: 'AllowNonAdminUserApprovals',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Allowed Team Identifiers',
                saveKey: 'AllowedTeamIdentifiers',
                value: 'AllowedTeamIdentifiers_kernal',
                val: 'AllowedTeamIdentifier',
                helpText:
                    'A Team Identifier in macOS is a unique code for a development team assigned by Apple, helping to identify their apps and ensure that only trusted developers can run their software.',
                inputType: 'tableInput',
                placeHolder: 'Enter',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                maxLength: 100,
                noDefaultVal: true
            }
        ]
    },
    {
        label: 'Firewall',
        image: fireWall,
        underDev: false,
        payloadIdentifier: 'com.example.myfirewallpayload',
        payloadType: 'com.apple.security.firewall',
        configured: false,
        show: ['SA', 'MA'],
        channel: 'DEVICE',
        platforms: ['macos'],
        helpText:
            'Use this section to manage and configure the device firewall settings remotely by enabling and customizing the incoming network connections for specific apps or services',
        fields: [
            {
                label: 'Enable Firewall',
                helpText:
                    'Activating this feature enhances your device`s security by controlling incoming network connections. The firewall monitors incoming network connections and can block unauthorized access to your Mac. It allows only the connections that you  permit.',
                fieldClass: 'fw-medium',
                value: 'EnableFirewall',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Allow Built-in Apps',
                helpText:
                    'Allowing built-in apps in macOS involves configuring the firewall to permit specific pre-installed applications to receive incoming network connections. This process ensures that essential features of apps like Mail, Messages, and Safari function correctly, enabling smooth communication and data transfer. ',
                fieldClass: 'fw-medium',
                value: 'AllowSigned',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Allow Signed Apps',
                helpText:
                    'Enable to allow downloaded signed software to recethe firewall automatically allows incoming connections for apps and services that are signed with a valid Apple developer certificate or recognized third-party certificates.',
                fieldClass: 'fw-medium',
                value: 'AllowSignedApp',
                defaultVal: true,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Block all Incoming Connections',
                helpText: 'Enable to block all incoming connections to prevent unauthorized access to your device.',
                fieldClass: 'fw-medium',
                value: 'BlockAllIncoming',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Enable Stealth Mode',
                helpText:
                    'Enable to keep track of incoming connection attempts, providing valuable information for monitoring and troubleshooting network security.',
                fieldClass: 'fw-medium',
                value: 'EnableLogging',
                defaultVal: false,
                show: ['SA', 'MA'],
                platforms: ['macos'],
                inputType: 'checkbox'
            },
            {
                label: 'Applications',
                value: 'Applications',
                helpText: 'Select the applications  that have been configured to allow incoming network connections that has firewall.',
                inputType: 'tableObject',
                placeHolder: 'Select',
                noDefaultVal: true,
                show: ['SA', 'MA'],
                showHeader: true,
                platforms: ['macos'],
                mandatory: ['BundleID_apps'],
                tableContent: [
                    {
                        label: 'Allow App',
                        value: 'Allowed_apps',
                        saveKey: 'Allowed',
                        // helpText: 'Enables this to allow receiving incoming network connections for selected applications.',
                        fieldClass: 'fw-medium',
                        defaultVal: true,
                        hideLabel: true,
                        show: ['SA', 'MA'],
                        platforms: ['macos'],
                        inputType: 'checkbox'
                    },
                    {
                        label: 'App Bundle ID',
                        value: 'BundleID_apps',
                        saveKey: 'BundleID',
                        // helpText: 'The App Bundle ID is a unique identifier assigned to an application to control network access.',
                        inputType: 'policyInput',
                        hideLabel: true,
                        noDefaultVal: true,
                        maxLength: 2048,
                        show: ['SA', 'MA'],
                        platforms: ['macos']
                    }
                ]
            }
        ]
    },
    {
        label: 'Media Management',
        image: mediaManagement,
        underDev: false,
        payloadIdentifier: 'com.example.mymediamanagementpayload',
        payloadType: 'com.apple.DiscRecording',
        configured: false,
        show: ['SA', 'MA'],
        channel: 'USER',
        platforms: ['macos'],
        helpText:
            'Use this section to control and manage media access and usage on macOS devices by configuring the restrictions on use of external media such as USB drives, CDs, DVDs, and disk images',
        fields: [
            {
                label: 'Burn Support',
                value: 'BurnSupport',
                noDefaultVal: true,
                helpText:
                    'The system allows normal default operation. Setting this key to on doesn’t enable disc burn support if other mechanisms or preferences disabled it.',
                inputType: 'dropdown',
                placeHolder: 'Select',
                show: ['SA', 'MA'],
                platforms: ['macos'],
                options: [
                    { label: 'On', value: 'on' },
                    { label: 'Off', value: 'off' },
                    { label: 'Authenticate', value: 'authenticate' }
                ]
            }
        ]
    },
    {
        label: 'Applications',
        image: Application,
        underDev: false,
        payloadIdentifier: 'apps',
        payloadType: 'apps',
        displayName: 'Applications',
        description: 'Applications',
        show: ['SA', 'MA'],
        platforms: ['ios', 'macos'],
        configured: false,
        helpText:
            'Creating a profile for an iOS app involves setting up configuration details that define how the app should be installed, managed, and utilized on iOS devices',
        fields: [
            {
                label: 'Applications',
                helpText:
                    'Creating a profile for an iOS app involves setting up configuration details that define how the app should be installed, managed, and utilized on iOS devices',
                inputType: 'application',
                configured: false,
                show: ['SA', 'MA'],
                platforms: ['ios', 'macos'],
                endAlign: true,
                fields: [
                    {
                        label: 'Install As Managed',
                        helpText:
                            'This feature enables  allows centralized installation of email configurations, ensuring adherence to organizational policies and security standards.',
                        inputType: 'checkbox',
                        fieldClass: 'fw-medium',
                        value: 'InstallAsManaged',
                        noDefaultVal: true,
                        isApp: true,
                        appShow: ['vpp', 'epa'],
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos']
                    },
                    {
                        label: 'Management Flags',
                        value: 'ManagementFlags',
                        noDefaultVal: true,
                        helpText:
                            'A bitwise OR of the management flags, Available in iOS 5 and later, macOS 11 and later, and tvOS 10.2 and later',
                        inputType: 'dropdown',
                        appShow: ['vpp', 'epa'],
                        placeHolder: 'Select',
                        options: [
                            { label: '1: If Install As Managed is True, Remove the App Upon Removal of the MDM Profile', value: 1 },
                            { label: '4: Prevent Backup of App Data', value: 4 },
                            { label: 'Both 1 and 4', value: 5 }
                        ],
                        isApp: true,
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos']
                    },
                    /*
                     * {
                     *     label: 'iOS App',
                     *     inputType: 'checkbox',
                     *     fieldClass: 'fw-medium',
                     *     isApp: true,
                     *     value: 'iOSApp',
                     *     show: ['vpp', 'epa']
                     * },
                     * {
                     *     label: 'Request Requires Network Tether',
                     *     inputType: 'checkbox',
                     *     fieldClass: 'fw-medium',
                     *     isApp: true,
                     *     value: 'RequestRequiresNetworkTether',
                     *     show: ['vpp', 'epa']
                     * },
                     * {
                     *     label: 'Managed Apps Only',
                     *     inputType: 'checkbox',
                     *     fieldClass: 'fw-medium',
                     *     value: 'ManagedAppsOnly',
                     *     isApp: true,
                     *     show: ['vpp', 'epa']
                     * },
                     */
                    {
                        label: 'Request Type',
                        value: 'RequestType',
                        helpText: 'The request type to install an app.',
                        inputType: 'dropdown',
                        placeHolder: 'Select',
                        noDefaultVal: true,
                        appShow: ['vpp', 'epa'],
                        isApp: true,
                        options: [
                            { label: 'Install Application', value: 'InstallApplication' },
                            { label: 'Install Enterprise Application', value: 'InstallEnterpriseApplication' },
                            { label: 'Installed Application List', value: 'InstalledApplicationList' }
                        ],
                        show: ['SA', 'MA'],
                        platforms: ['ios', 'macos']
                    }
                    /*
                     * {
                     *     label: 'Options',
                     *     value: 'Options',
                     *     helpText:
                     *         'Enter the certificates to be excluded from Certificate Transpercy enforcement. The value should be set to the SHA-256 hash of the certificates subject public key info',
                     *     inputType: 'json',
                     *     placeHolder: 'Enter',
                     *     maxLength: 20,
                     *     show: ['vpp'],
                     *     isApp: true,
                     *     jsonFields: [
                     *         {
                     *             label: 'Purchase Method',
                     *             value: 'PurchaseMethod',
                     *             helpText: `The apps purchase type, which must be one of the following values:
                     * 					0: Free apps and Legacy Volume Purchase Program (VPP) with a redemption code. This option is only available in iOS.
                     * 					1: Volume Purchase Program (VPP) app assignment.`,
                     *             inputType: 'dropdown',
                     *             placeHolder: 'Select',
                     *             isApp: true,
                     *             options: [
                     *                 {
                     *                     label: '0: Free apps and Legacy Volume Purchase Program (VPP) with a redemption code. This option is only available in iOS',
                     *                     value: 0
                     *                 },
                     *                 { label: '1: Volume Purchase Program (VPP) app assignment.', value: 1 }
                     *             ],
                     *             show: ['vpp']
                     *         }
                     *     ]
                     * }
                     */
                    /*
                     * {
                     *     label: 'Attributes',
                     *     value: 'Attributes',
                     *     helpText:
                     *         'A dictionary that contains the initial attributes of the app, if you choose to provide it. Available in iOS 7 and later, and tvOS 10.2 and later.',
                     *     inputType: 'json',
                     *     placeHolder: 'Enter',
                     *     maxLength: 20,
                     *     show: ['vpp', 'epa'],
                     *     jsonFields: [
                     *         {
                     *             label: 'Associated Domains',
                     *             value: 'AssociatedDomains',
                     *             helpText:
                     *                 'An array that contains the associated domains to add to this app. Available in iOS 13 and later.',
                     *             inputType: 'policyInput',
                     *             placeHolder: 'Enter',
                     *             maxLength: 200,
                     *             show: ['vpp', 'epa']
                     *         },
                     *         {
                     *             label: 'Associated Domains Enable DirectDownloads',
                     *             value: 'AssociatedDoxmainsEnableDirectDownloads',
                     *             inputType: 'checkbox',
                     *             fieldClass: 'fw-medium',
                     *             show: ['vpp', 'epa']
                     *         },
                     *         {
                     *             label: 'Cellular Slice UUID',
                     *             value: 'CellularSliceUUID',
                     *             helpText:
                     *                 'The data network name (DNN) or app category. For DNN, the value is DNN:name, where name is the carrier-provided DNN name. For app category, the value is AppCategory:category, where category is a carrier-provided string like “Enterprise1”.',
                     *             inputType: 'policyInput',
                     *             placeHolder: 'Enter',
                     *             maxLength: 200,
                     *             show: ['vpp', 'epa']
                     *         },
                     *         {
                     *             label: 'Content Filter UUID',
                     *             value: 'ContentFilterUUID',
                     *             helpText: 'The content filter UUID for this app. Available in iOS 16 and later.',
                     *             inputType: 'policyInput',
                     *             placeHolder: 'Enter',
                     *             maxLength: 200,
                     *             show: ['vpp', 'epa']
                     *         },
                     *         {
                     *             label: 'DNS Proxy UUID',
                     *             value: 'DNSProxyUUID',
                     *             helpText: 'The DNS proxy UUID for this app. Available in iOS 16 and later.',
                     *             inputType: 'policyInput',
                     *             placeHolder: 'Enter',
                     *             maxLength: 200,
                     *             show: ['vpp', 'epa']
                     *         },
                     *         {
                     *             label: 'Relay UUID',
                     *             value: 'RelayUUID',
                     *             helpText: 'The relay UUID for this app. Available in iOS 17 and later.',
                     *             inputType: 'policyInput',
                     *             placeHolder: 'Enter',
                     *             maxLength: 200,
                     *             show: ['vpp', 'epa']
                     *         },
                     *         {
                     *             label: 'Removable',
                     *             value: 'Removable',
                     *             inputType: 'checkbox',
                     *             fieldClass: 'fw-medium',
                     *             show: ['vpp', 'epa']
                     *         },
                     *         {
                     *             label: 'TapToPayScreenLock',
                     *             value: 'TapToPayScreenLock',
                     *             inputType: 'checkbox',
                     *             fieldClass: 'fw-medium',
                     *             show: ['vpp', 'epa']
                     *         },
                     *         {
                     *             label: 'VPN UUID',
                     *             value: 'VPNUUID',
                     *             helpText:
                     *                 'A per-app VPN unique identifier for this app. Available in iOS 7 and later, and tvOS 10.2 and later.',
                     *             inputType: 'policyInput',
                     *             placeHolder: 'Enter',
                     *             maxLength: 200,
                     *             show: ['vpp', 'epa']
                     *         }
                     *     ]
                     * }
                     */
                ]
            }
        ]
    }
];
