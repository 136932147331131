import React, { useEffect, useState } from 'react';
import { FilePond } from 'react-filepond';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, DownloadBunnyFile, getDate } from '../../../Components/Common/Util';
import { priviliges, WIFI_OPTIONS } from '../../../Components/constants/constants';
import certificateDelete from '../../../assets/images/common/png/delete/deleteCertificate.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { certificatesTableSchema } from '../TableSchema';

const CertificateManagement = () => {
    document.title = 'Certificate Management';
    let history = useHistory();
    const urlconf = useEnv();
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    let api = new APIClient();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [certificates, setCertificates] = useState([]);
    const [showCertificateModal, setShowCertificateModal] = useState(false);
    const [files, setFiles] = useState([]);
    const [tableSchema, setTableSchema] = useState(certificatesTableSchema);
    const [selectedRow, setSelectedRow] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [uploadDetails, setUploadDetails] = useState({ alias: '', fileType: '', extension: '', password: '' });

    useEffect(() => {
        setLoading(true);
        const findPriv = !user?.data?.privileges?.includes(priviliges.CERTIFICATE_EDITOR);
        if (findPriv) setTableSchema(certificatesTableSchema.filter((schema) => schema.Header !== 'Actions'));
        getCerificates({ page: 1, size: 10 });
    }, []);

    const getCerificates = (params) => {
        api.get(url.CERTIFICATES, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    resp.data.forEach((data) => {
                        data.type = data.type || '—';
                        data.password = data.password ? data.password : '—';
                        data.modifiedBy = data.lastmodifiedBy ? data.lastmodifiedBy : 'Tectoro';
                        data.modifiedDate = data.lastmodifiedTime ? getDate(data.lastmodifiedTime) : '—';
                        data.policiesApplied = data.policies?.length > 0 ? data.policies?.join(', ') : '—';
                        data.issuedOn = data.createdTime ? getDate(data.createdTime) : '—';
                        data.doNotDelete = !!data.policies?.length > 0;
                    });
                    setCertificates(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const onPageChange = (page) => {
        let params = { page: page, size: 10 };
        getCerificates(params);
    };

    const handleUpload = () => {
        setLoading(true);
        const formData = new FormData();
        const fileName = files?.[0]?.filename;
        const fileNameArr = fileName?.split('.');
        formData.append('fileName', fileName);
        formData.append('aliasName', uploadDetails.alias);
        formData.append('type', uploadDetails.fileType?.value);
        if (uploadDetails.extension?.reqPassword) {
            if (!uploadDetails.password) {
                toast.error(toastMessages.passwordRequired);
                return;
            }
            formData.append('password', uploadDetails.password);
        }
        if (uploadDetails.extension?.value && `.${fileNameArr?.[fileNameArr.length - 1]}` !== uploadDetails.extension?.value) {
            toast.error(toastMessages.invalidFileFormat);
            return;
        }
        formData.append('files', files?.[0]?.file);
        setShowCertificateModal(false);
        api.create(url.CERTIFICATES, formData)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.certificateUploaded);
                    setPageNumber(1);
                    getCerificates({ page: 1, size: 10 });
                }
                setUploadDetails({ alias: '', fileType: '', extension: '', password: '' });
                setFiles([]);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleClickView = (row) => {
        DownloadBunnyFile(row.original.url, row.original.serverFileName, setLoading);
    };

    const handleDeleteConfirmation = () => {
        setLoading(false);
        api.delete(url.CERTIFICATES + '/' + selectedRow.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.certificateDeleted);
                    setPageNumber(1);
                    setSelectedRow('');
                    setShowDeleteModal(false);
                    getCerificates({ page: 1, size: 10 });
                }
            })
            .catch((err) => setLoading(false));
    };

    const handleDelete = (row) => {
        setSelectedRow(row.original);
        setShowDeleteModal(true);
    };

    const handleModalBody = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                        <Label className="form-label d-flex align-items-center fw-medium">
                            Name<span className="red-color ps-1">*</span>
                        </Label>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                        <Input
                            name={'name'}
                            maxLength={100}
                            type="text"
                            placeholder="Enter Name"
                            onChange={(e) => setUploadDetails({ ...uploadDetails, alias: e.target.value })}
                            value={uploadDetails.alias || ''}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                        <Label className="form-label d-flex align-items-center fw-medium">
                            Certificate Type<span className="red-color ps-1">*</span>
                        </Label>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                        <div className="d-flex align-items-center w-100">
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                id={'fileType'}
                                name={'fileType'}
                                options={WIFI_OPTIONS}
                                placeholder="Select Certificate Type"
                                onChange={(e) => setUploadDetails({ ...uploadDetails, fileType: e })}
                                value={uploadDetails.fileType}
                                isSearchable={true}
                                className="min-width-150 w-100"
                                classNamePrefix={'custom-select'}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                        <Label className="form-label d-flex align-items-center fw-medium">
                            Extension Type<span className="red-color ps-1">*</span>
                        </Label>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                        <div className="d-flex align-items-center w-100">
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                id={'extension'}
                                name={'extension'}
                                options={uploadDetails?.fileType?.extensions}
                                placeholder="Select Extension Type"
                                onChange={(e) => setUploadDetails({ ...uploadDetails, extension: e })}
                                isDisabled={uploadDetails.fileType === '' || uploadDetails.fileType?.value === ''}
                                value={uploadDetails.extension}
                                isSearchable={true}
                                className="min-width-150 w-100"
                                classNamePrefix={'custom-select'}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center">
                    <Col xs={12} sm={12} md={12} xl={3} lg={3}>
                        <Label className="form-label d-flex align-items-center">
                            Attachement
                            <span className="ps-1"> :</span>
                        </Label>
                    </Col>
                    <Col>
                        <FilePond
                            name="files"
                            maxFiles={1}
                            acceptedFileTypes={['pem', 'cer']}
                            allowMultiple={true}
                            files={files}
                            className="filepond filepond-input-multiple"
                            onupdatefiles={(fileItems) => setFiles(fileItems)}
                        />
                    </Col>
                </Row>
                {uploadDetails.extension?.reqPassword && files.length > 0 && (
                    <Row>
                        <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                            <Label className="form-label d-flex align-items-center fw-medium">
                                Password<span className="red-color ps-1">*</span>
                            </Label>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                            <Input
                                name={'password'}
                                maxLength={100}
                                type="text"
                                placeholder="Enter Password"
                                onChange={(e) => setUploadDetails({ ...uploadDetails, password: e.target.value })}
                                value={uploadDetails.password || ''}
                            />
                        </Col>
                    </Row>
                )}
            </React.Fragment>
        );
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to delete this Certificate?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={certificateDelete} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>
                    Certificate Alias : <span className="fw-semibold">{selectedRow?.aliasName}</span>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content h-100">
                <Container fluid>
                    <BreadCrumb pageTitle={'Certificates'} history={history} homeLink="Dashboard" />
                    <TableContainer
                        tableHeader={'All Certificates'}
                        isGlobalFilter={true}
                        addButton={true}
                        reader={priviliges.CERTIFICATE_READER}
                        editor={priviliges.CERTIFICATE_EDITOR}
                        handleAddNew={() => setShowCertificateModal(true)}
                        columns={tableSchema}
                        data={certificates || []}
                        pageNum={pageNumber}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        handleDelete={handleDelete}
                        handleClickView={handleClickView}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <CommonModal
                        size={'lg'}
                        cancelText={'Cancel'}
                        modalheader={'Upload Certificate'}
                        show={showCertificateModal}
                        disabled={
                            !uploadDetails.alias ||
                            uploadDetails.alias === '' ||
                            !files ||
                            files?.length === 0 ||
                            !uploadDetails.extension ||
                            uploadDetails.extension === ''
                        }
                        onCloseClick={() => {
                            setShowCertificateModal(false);
                            setUploadDetails({ alias: '', fileType: '', extension: '', password: '' });
                            setFiles([]);
                            setLoading(false);
                        }}
                        handleClick={() => handleUpload()}
                        handleModalBody={() => handleModalBody()}
                    />
                    <DeleteModal
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={message()}
                        show={showDeleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => {
                            setShowDeleteModal(false);
                            setSelectedRow('');
                        }}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CertificateManagement;
