import React, { useEffect, useState } from 'react';
import { FilePond } from 'react-filepond';
import { toast } from 'react-toastify';
import { Alert, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { applePushData } from '../../../Components/constants/constants';
import ApplePushImage from '../../../assets/images/apple/png/common/apple_push.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import APNCard from './APNCard';

const ApplePushNotifications = (props) => {
    const api = new APIClient();
    const [apkFiles, setAPKFiles] = useState([]);
    const urlconf = useEnv();

    const [showSteps, setShowSteps] = useState(false);
    const [showInitialScreen, setShowInitialScreen] = useState(true);
    const [apnData, setApnData] = useState(props.apns ? { ...props.apns } : '');

    useEffect(() => {
        setApnData(props.apns ? { ...props.apns } : '');
    }, [props.apns]);

    const onConfigureApn = () => {
        setShowSteps(true);
        setShowInitialScreen(false);
    };

    const handleAPKFileUpload = (fileItems) => {
        setAPKFiles(fileItems);
    };

    const onUploadClick = (ele) => {
        if (ele === 'Download File.csv') {
            props.setLoading(true);
            api.get(url.APN_CERT, {}, domains.IOS_V1)
                .then((resp) => {
                    let csv = resp;
                    let a = document.createElement('a');
                    a.href = 'data:text/plist;charset=utf-8,' + encodeURI(csv);
                    a.target = '_blank';
                    a.download = 'AppleNotifications.plist';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    props.setLoading(false);
                })
                .catch(() => props.setLoading(false));
        }
        if (ele === 'Sign In to Apple Portal') {
            let a = document.createElement('a');
            a.href = ' https://identity.apple.com/pushcert/';
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    const onAPNSave = () => {
        props.setLoading(true);
        const formData = new FormData();
        formData.append('file', apkFiles?.[0]?.file);
        api.patch(url.APN_CERT_UPLOAD, formData, false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.APNCreated);
                    setShowSteps(false);
                    props.getAPNData();
                    setAPKFiles([]);

                    setShowInitialScreen(true);
                    toast.success(toastMessages.APNSuccess);
                }
                props.setLoading(false);
            })
            .catch(() => props.setLoading(false));
    };

    const handleAction = (type) => {
        if (type === 'change') setShowSteps(true);
        else if (type === 'delete') {
            props.handleDelete({ enabled: true, id: apnData?._id });
            setShowInitialScreen(true);
        }
    };

    return (
        <Card className="card-height-100">
            <CardHeader className="card-height-100">
                <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                    <div className="fs-14 ">Apple Push Notifications Service (APNs)</div>
                    <div className={`d-flex justify-content-center align-items-center fs-12 ${!apnData ? 'text-danger' : 'text-primary'}`}>
                        {!apnData ? (
                            <i className="ri-error-warning-fill fs-16 px-1 text-danger"></i>
                        ) : (
                            <i className="ri-checkbox-circle-fill fs-16 px-1 text-primary"></i>
                        )}
                        {!apnData ? 'Not Configured' : 'Configured'}
                    </div>
                </div>
                <div className="padding-top-10 font-size-12 fw-medium text-muted">
                    Apple Push Notification Certificate is an initial set-up that helps to establish a connection between your Apple Account
                    and Tectoro EMM Solution.
                </div>
            </CardHeader>
            {!apnData && showInitialScreen ? (
                <div>
                    <CardBody>
                        <div className="py-20 text-center">
                            <div>
                                <img height={65} src={ApplePushImage} alt="img"></img>
                            </div>
                            <div className="fw-medium fs-14 py-2">Apple Push Notifications Service (APNs)</div>
                            <div className="font-size-12 text-start fw-medium text-muted px-2">
                                Please click "Configure" and follow the steps provided to set up APNs (Apple Push Notification service). For
                                best practice, it's recommended to use a generic corporate email address rather than a personal one, since
                                the certificate needs to be renewed annually
                            </div>
                            <div className="py-4">
                                <Button className="bg-primary px-15 py-3 border-none fw-medium fs-12" onClick={() => onConfigureApn()}>
                                    Configure
                                </Button>
                            </div>
                            {/* <div className="fs-12 fw-medium">
                                <span className="text-danger fs-13 fw-semibold">*</span>
                                <i>APNs is mandatory for Apple device enrolment</i>
                            </div> */}
                        </div>
                    </CardBody>
                </div>
            ) : !showSteps ? (
                <div>
                    <CardBody>
                        <div className="text-center  py-2">
                            <div>
                                <img height={65} src={ApplePushImage} alt="img"></img>
                            </div>
                            <div className="fw-medium fs-14 py-2">Apple Push Notifications Service (APNs)</div>
                        </div>
                        <APNCard data={apnData} handleAction={handleAction} />
                    </CardBody>
                </div>
            ) : (
                <CardBody>
                    <div>
                        {applePushData.map((ele, ind) => {
                            return (
                                <div
                                    className={` py-4 ${
                                        ind !== applePushData.length - 1 ? 'border-1 border-bottom-dashed border-grey' : ''
                                    }`}
                                    key={ind}
                                >
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img height={32} src={ele.image} alt="img"></img>
                                        </div>
                                        <div className="px-2">
                                            <div className="text-primary fw-medium">{ele.step}</div>
                                            <div className="fw-semibold fs-13">{ele.title}</div>
                                        </div>
                                    </div>
                                    <div className="py-2 fs-12 fw-medium text-muted">{ele.text}</div>
                                    <div className="d-flex align-items-center align-items-center text-secondary cursor-pointer">
                                        <div>
                                            {ind !== applePushData.length - 1 ? (
                                                <img height={ind === 0 ? 16 : 14} src={ele.icon} alt="img"></img>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div
                                            className="text-decoration-underline px-1 fs-13 fw-semibold"
                                            onClick={() => onUploadClick(ele.linkText)}
                                        >
                                            {ele.linkText}
                                        </div>
                                    </div>
                                    {ele.alertText && (
                                        <Alert className="alert-borderless alert-warning text-center mx-2 mt-2 mb-0" role="alert">
                                            {ele.alertText}
                                        </Alert>
                                    )}
                                </div>
                            );
                        })}
                        <div className="filepondmarginremoval w-95 mx-3">
                            <FilePond
                                name="files"
                                minFileSize="1KB"
                                maxFileSize="300MB"
                                maxFiles={1}
                                allowMultiple={true}
                                files={apkFiles}
                                className="filepond filepond-input-multiple"
                                onupdatefiles={(fileItems) => handleAPKFileUpload(fileItems)}
                            />
                        </div>
                        <div className="d-flex justify-content-end align-items-center margin-top-10 px-3">
                            <Button
                                className="bg-light border-primary text-primary mx-3 fs-12 fw-semibold px-5"
                                onClick={() => {
                                    setShowInitialScreen(true);
                                    setShowSteps(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="bg-primary border-none px-8 fs-12 fw-semibold"
                                onClick={() => onAPNSave()}
                                disabled={apkFiles.length === 0}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </CardBody>
            )}
        </Card>
    );
};

export default ApplePushNotifications;
