import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { GetAuthmodes, TenantConfig, convertTextCase, loginUtil } from '../../Components/Common/Util';
// import { togglePassword } from '../../Components/Hooks/UserHooks';
import { useDispatch } from 'react-redux';
import CommonModal from '../../Components/Common/CommonModal';
import { tenantConfig as tenant } from '../../Components/constants/TenantConfig';
import google from '../../assets/images/common/png/common/google.png';
import ldap from '../../assets/images/common/png/common/ldap.png';
import microsoft from '../../assets/images/common/png/common/microsoft.png';
import toastMessages from '../../common/messages/toastMessages';
import { useEnv } from '../../envContext';
import { APIClient, setAuthorization } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';
import { authModes as authAction, authUser } from '../../store/actions';
import AuthSlider from './AuthSlider';
import favicon from '../../assets/images/favicon/favicon.ico';

const Login = () => {
    document.title = 'SignIn';
    const urlconf = useEnv();
    let history = useHistory();
    const api = new APIClient();

    const tenantConfigData = TenantConfig();
    let tenantData = tenant?.[urlconf.REACT_APP_COMPANY_ID];
    document.querySelector('link[rel="icon"]').href = tenantConfigData?.FAV_ICON ? tenantConfigData?.FAV_ICON : favicon;

    const [loading, setLoading] = useState(false);
    let accountId = localStorage.getItem('account');
    const authModeString = GetAuthmodes();
    let authModes = authModeString ? JSON.parse(authModeString) : [];
    const images = { microsoft: microsoft, google: google, ldap: ldap };
    const [showPassword, setShowPassword] = useState(false);
    const [selectedAuth, setSelectedAuth] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showAuthPassword, setShowAuthPassword] = useState(false);
    const [authLoading, setAuthLoading] = useState(false);
    const dispatch = useDispatch();

    const toggleAuthPassword = () => {
        setShowAuthPassword(!showAuthPassword);
    };

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        let params = new URLSearchParams(decodeURIComponent(history?.location?.search));
        const code = params.get('code');
        const state = params.get('state');
        if (code && state) window.location.replace(window.location.origin + '/#/callback?code=' + code + '&state=' + state);
        else if (!accountId) history.push('/account');
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { username: '', password: '' },
        validationSchema: Yup.object({
            username: Yup.string().required('Please Enter Your User Name'),
            password: Yup.string().required('Please Enter Your Password')
        }),
        onSubmit: (values) => {
            setLoading(true);
            let params = { tenant: accountId, username: values.username, password: values.password, app: 'portal', device: '' };
            api.create(url.LOGIN, params, false, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        if (resp.data?.email) {
                            setAuthorization(resp?.data?.token);
                            resp.data.privileges = resp.data.privileges?.split(',');
                            localStorage.setItem('account', resp.data.tenant);
                            dispatch(authUser(JSON.stringify(resp)));
                            loginUtil({
                                user: resp,
                                ten: resp.data.tenant,
                                api: api,
                                history: history,
                                dispatch: dispatch,
                                tenantObj: tenantConfigData,
                                tenantData: tenantData,
                                urlconf: urlconf
                            });
                        }
                        if (!resp.data?.email) toast.success(resp.data ? resp.data : toastMessages.otpSent);
                        localStorage.setItem('account', accountId);
                        localStorage.setItem('authObj', JSON.stringify({ username: values.username, password: values.password }));
                        history.push('/login-otp-verification');
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    });
    const authValidation = useFormik({
        enableReinitialize: true,
        initialValues: { authusername: '', authpassword: '' },
        validationSchema: Yup.object({
            authusername: Yup.string().required('Please Enter Your User Name'),
            authpassword: Yup.string().required('Please Enter Your Password')
        })
    });

    const handleAuthMode = (mode) => {
        if (mode.mode === 'ldap') {
            setShowModal(true);
            setSelectedAuth(mode);
        } else {
            setLoading(true);
            api.get(url.AUTH_LOGIN, { tenant: accountId, mode: mode.mode, app: 'portal' }, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        window.location.replace(resp.data);
                    }
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        }
    };

    const onCloseClick = () => {
        setShowModal(false);
        authValidation.setErrors({});
        authValidation.setTouched({});
        authValidation.setValues({ authusername: '', authpassword: '' });
    };

    const onAuthSignIn = () => {
        setAuthLoading(true);
        let params = {
            tenant: accountId,
            username: authValidation.values.authusername,
            password: authValidation.values.authpassword,
            app: 'portal'
        };
        api.create(url.AUTH_LDAP, params, false, domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.status === 'success') {
                    setAuthorization(resp?.data?.token);
                    resp.data.privileges = resp.data.privileges?.split(',');
                    localStorage.setItem('account', resp.data.tenant);
                    dispatch(authUser(JSON.stringify(resp)));
                    loginUtil({
                        user: resp,
                        ten: resp.data.tenant,
                        api: api,
                        history: history,
                        dispatch: dispatch,
                        tenantObj: tenantConfigData,
                        tenantData: tenantData,
                        urlconf: urlconf
                    });
                    localStorage.removeItem('authObj');
                    setAuthLoading(false);
                }
            })
            .catch((err) => setAuthLoading(false));
    };

    const handleBackToAccount = () => {
        localStorage.removeItem('account');
        dispatch(authAction(null));
        history.push('/account');
    };

    const handleModalBody = () => {
        return (
            <div className="p-5 pt-3">
                <div className="d-flex align-items-center justify-content-center">
                    <i className="position-absolute end-10 top-5 fs-20 ri-close-line cursor-pointer" onClick={onCloseClick}></i>
                    <div className="height-45 width-45">
                        <img src={images[selectedAuth.mode]} alt={selectedAuth.mode} className="w-100 h-100" />
                    </div>
                    <h3 className="ps-2 mb-0">
                        {selectedAuth.mode === 'ldap' ? selectedAuth.mode?.toUpperCase() : convertTextCase(selectedAuth.mode)}
                    </h3>
                </div>
                <div>
                    <Label className="form-label">User Name</Label>
                    <Input
                        name="authusername"
                        placeholder="Enter User Name"
                        type="text"
                        onChange={authValidation.handleChange}
                        onBlur={authValidation.handleBlur}
                        value={authValidation.values.authusername || ''}
                        invalid={authValidation.touched.authusername && authValidation.errors.authusername ? true : false}
                    />
                    {authValidation.touched.authusername && authValidation.errors.authusername ? (
                        <FormFeedback type="invalid">{authValidation.errors.authusername}</FormFeedback>
                    ) : null}
                </div>
                <div className="pt-3">
                    <Label className="form-label">Password</Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                        <Input
                            name="authpassword"
                            id="password-input"
                            value={authValidation.values.authpassword || ''}
                            type={showAuthPassword ? 'text' : 'password'}
                            className={'form-control pe-5'}
                            placeholder="Enter your password"
                            autoComplete="new-password"
                            onChange={authValidation.handleChange}
                            onBlur={authValidation.handleBlur}
                            invalid={authValidation.touched.authpassword && authValidation.errors.authpassword ? true : false}
                        />
                        {authValidation.touched.authpassword && authValidation.errors.authpassword && (
                            <FormFeedback type="invalid">{authValidation.errors.authpassword}</FormFeedback>
                        )}
                        {authValidation.values.authpassword && authValidation.values.authpassword !== '' && (
                            <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none cursor-auto text-muted"
                                type="button"
                                id="password-addon"
                                onClick={toggleAuthPassword}
                            >
                                <i
                                    id="password-icon"
                                    className={`${showAuthPassword ? 'ri-eye-line' : 'ri-eye-off-line'} align-middle cursor-pointer`}
                                ></i>
                            </button>
                        )}
                    </div>
                </div>
                <div className="pt-4">
                    <button
                        className={'btn btn-success btn-load w-100'}
                        disabled={authValidation.values.authusername === '' || authValidation.values.authpassword === '' || authLoading}
                        onClick={() => onAuthSignIn()}
                    >
                        <span className="d-flex align-items-center justify-content-center">
                            Sign In
                            {authLoading && (
                                <span className="ms-2 spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </span>
                            )}
                        </span>
                    </button>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center overflow-y-auto">
                {/* <div className="bg-overlay"></div> */}
                <Col lg={12}>
                    <Card className=" overflow-x-hidden m-0">
                        <Row className={'g-0 vh-100 vw-100'}>
                            <AuthSlider />
                            <Col lg={4} className={'d-flex justify-content-center align-items-center'}>
                                <div className="p-lg-12 p-6 w-100">
                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-center mb-5 text-white-50">
                                                <div className="mb-5">
                                                    <div className="d-inline-block auth-logo">
                                                        <img src={tenantConfigData?.COMPANY_LOGO} alt="" height={40} />
                                                    </div>
                                                </div>
                                                {tenantConfigData?.PRIMARY_HEADER && (
                                                    <h4 className={'mt-4 text-dark'}>{tenantConfigData.PRIMARY_HEADER}</h4>
                                                )}
                                                {tenantConfigData?.SECONDARY_HEADER && (
                                                    <h5 className="fw-medium text-muted">{tenantConfigData.SECONDARY_HEADER}</h5>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>

                                    <div>
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            action="#"
                                        >
                                            <div className="d-flex align-items-center justify-content-center mb-1">
                                                <p className={'fs-18 fw-medium text-primary'}>Login Account</p>
                                            </div>
                                            <div className="mb-4">
                                                <Label className="form-label">Account ID</Label>
                                                <Input
                                                    name="accountID"
                                                    className={'form-control fw-semibold '}
                                                    placeholder="Enter Account ID"
                                                    type="text"
                                                    value={accountId || ''}
                                                    disabled={true}
                                                />
                                            </div>
                                            {authModes.some((auth) => auth.mode === 'local') && (
                                                <div className="mb-4">
                                                    <Label className="form-label">User Name</Label>
                                                    <Input
                                                        name="username"
                                                        className={'form-control'}
                                                        placeholder="Enter User Name"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.username || ''}
                                                        invalid={validation.touched.username && validation.errors.username ? true : false}
                                                    />
                                                    {validation.touched.username && validation.errors.username ? (
                                                        <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            )}
                                            {authModes.some((auth) => auth.mode === 'local') && (
                                                <div className="mb-4">
                                                    <Label className="form-label">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            id="password-input"
                                                            value={validation.values.password || ''}
                                                            type={showPassword ? 'text' : 'password'}
                                                            className={'form-control pe-5'}
                                                            placeholder="Enter your password"
                                                            autoComplete="new-password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password && (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        )}
                                                        {validation.values.password && validation.values.password !== '' && (
                                                            <button
                                                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none cursor-auto text-muted"
                                                                type="button"
                                                                id="password-addon"
                                                                onClick={togglePassword}
                                                            >
                                                                <i
                                                                    id="password-icon"
                                                                    className={`ri ${
                                                                        showPassword ? 'ri-eye-line' : 'ri-eye-off-line'
                                                                    } align-middle cursor-pointer`}
                                                                ></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {/* <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label">Remember me</Label>
                                                </div> */}

                                            {authModes.some((auth) => auth.mode === 'local') && (
                                                <div className="mt-6">
                                                    <button
                                                        type="submit"
                                                        className={'btn btn-success btn-load w-100'}
                                                        disabled={
                                                            validation.values.email === '' ||
                                                            validation.values.password === '' ||
                                                            validation.errors.email ||
                                                            loading
                                                        }
                                                    >
                                                        <span className="d-flex align-items-center justify-content-center">
                                                            Sign In
                                                            {loading && (
                                                                <span className="ms-2 spinner-border" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </span>
                                                            )}
                                                        </span>
                                                    </button>
                                                </div>
                                            )}
                                            <div className="w-100">
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <div className="cursor-pointer text-primary" onClick={handleBackToAccount}>
                                                        Back to Account ID
                                                    </div>
                                                    {authModes.some((auth) => auth.mode === 'local') && (
                                                        <Link to="/forgot-password" className="text-primary">
                                                            Forgot password?
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                            {authModes?.length > 1 && (
                                                <div className="w-100 mt-3">
                                                    <div className="text-center mb-4">Sign in with</div>
                                                    <div className="d-flex align-items-center justify-content-center flex-wrap gap-4">
                                                        {authModes?.map(
                                                            (mode, ind) =>
                                                                mode.mode !== 'local' && (
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center border br-3 py-2 px-3 cursor-pointer"
                                                                        onClick={() => handleAuthMode(mode)}
                                                                        key={ind}
                                                                    >
                                                                        <div className="height-20 width-20 br-5">
                                                                            <img
                                                                                src={images[mode.mode]}
                                                                                alt={mode.mode}
                                                                                className="w-100 h-100"
                                                                            />
                                                                        </div>
                                                                        <span className="fs-12 ps-2 align-item-center">
                                                                            {mode.mode === 'ldap'
                                                                                ? mode.mode?.toUpperCase()
                                                                                : convertTextCase(mode.mode)}
                                                                        </span>
                                                                    </div>
                                                                )
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            <CommonModal
                                                show={showModal}
                                                onCloseClick={onCloseClick}
                                                handleModalBody={handleModalBody}
                                                hideFooter={true}
                                                hideHeader={true}
                                            />
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </div>
        </React.Fragment>
    );
};

export default Login;
