/* eslint-disable max-depth */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useRef, useState } from 'react';
import { FilePond } from 'react-filepond';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ReactTransliterate } from 'react-transliterate';
import { Button, Card, Col, Form, Input, Label, Row } from 'reactstrap';
import AnimatedTreeComponent from '../../../Components/Common/AnimatedTreeComponent';
import Loader from '../../../Components/Common/Loader';
import { AuthUser, DownloadBunnyFile, TenantConfig, fileValidation } from '../../../Components/Common/Util';
import { FORMATS, LANGUAGES, MONTHS, TYPES, fileTypeValidations } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domain from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';

const AddMessage = (props) => {
    document.title = `${props.mode} Message`;
    const urlconf = useEnv();
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    const fp = useRef();
    let tenantData = TenantConfig();
    const parsedTenant = tenantData ? JSON.parse(JSON.stringify(tenantData)) : '';
    const api = new APIClient();
    let emptyObj = {
        sendTo: '',
        senderInfo: [],
        sendType: 'ALL',
        title: '',
        message: '',
        time: '',
        type: '',
        scheduledTime: '',
        messageWithStyle: '',
        files: [],
        messageType: 'IMMEDIATE'
    };
    const sendToOptions = [
        { label: 'Device', value: 'DEVICE' },
        { label: 'Device Group', value: 'GROUP' }
    ];
    const languageOptions = [...LANGUAGES];

    const items = ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo'];

    const [mode, setMode] = useState(props.mode);
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [extraObj, setExtraObj] = useState({});
    const [policies, setPolicies] = useState([]);
    const [files, setFiles] = useState([]);
    const [checkField, setCheckField] = useState(false);
    const [language, setLanguage] = useState({ label: 'English', value: 'en' });
    const [styleComponent, setStyleComponent] = useState(false);
    const [deviceGroups, setDeviceGroups] = useState([...props.deviceGroups]);
    const [typesArr, setTypesArr] = useState([...TYPES]);
    const [dateVal, setDateVal] = useState('');
    const [invalidDate, setInvalidDate] = useState(false);
    const [value, setValue] = useState('');
    const [openPanel, setOpenPanel] = useState(false);
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        if (props.deviceGroups) setDeviceGroups(props.deviceGroups);
    }, [props.deviceGroups]);

    useEffect(() => {
        props.saveFormData(currentDetailsObj);
    }, [currentDetailsObj]);

    useEffect(() => {
        let obj = { ...currentDetailsObj, type: { label: 'Announcement', value: 'ANNOUNCEMENT', key: 'announcement' } };
        if (files?.length > 0)
            obj = {
                ...obj,
                files: files,
                type: props.currentDetailsObj?.type
                    ? props.currentDetailsObj?.type
                    : currentDetailsObj?.type
                    ? currentDetailsObj?.type
                    : { label: 'Announcement', value: 'ANNOUNCEMENT', key: 'announcement' }
            };
        setCurrentDetailsObj({ ...obj });
    }, [files]);

    useEffect(() => {
        if (props.recordID) getAnnouncement();
        setMode(props.mode);
    }, []);

    /*
     * const getPolicies = () => {
     *     props.setCanvasLoader(true);
     *     let pURL = url.POLICIES;
     *     let params = { page: 1, size: 2000 };
     *     api.get(pURL, params)
     *         .then((resp) => {
     *             resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
     *             let arr = [];
     *             if (resp.data?.length > 0) {
     *                 resp.data.forEach((ele) => {
     *                     if (ele.status?.toLowerCase() === 'active') arr.push({ label: ele.name, value: ele.code });
     *                 });
     *                 setPolicies(arr);
     *             }
     *             props.setCanvasLoader(false);
     *         })
     *         .catch((err) => {
     *             props.setCanvasLoader(false);
     *         });
     * };
     */

    const prepareSentGroups = (groups, receiver) => {
        groups.forEach((group) => {
            if (receiver.includes(group.code)) group.checked = true;
            if (group.children) group.children = prepareSentGroups(group.children, receiver);
        });
        return groups;
    };

    const prepareSelectedGroups = (groups, selectedGroups) => {
        groups.forEach((group) => {
            if (group.checked) selectedGroups.push(group.code);
            if (group.children) selectedGroups = prepareSelectedGroups(group.children, selectedGroups);
        });
        return selectedGroups;
    };

    const handleSentGroups = (receiver) => {
        let groups = JSON.parse(JSON.stringify(props.deviceGroups));
        let selectedGroups = [];
        groups.forEach((group) => {
            if (receiver.includes(group.code)) group.checked = true;
            if (group.children) group.children = prepareSentGroups(group.children, receiver);
        });
        groups.forEach((group) => {
            if (group.checked) selectedGroups.push(group.code);
            if (group.children) selectedGroups = prepareSelectedGroups(group.children, selectedGroups);
        });
        return { groups: groups, senderInfo: selectedGroups };
    };

    const getAnnouncement = (polArr) => {
        props.setCanvasLoader(true);
        api.get(url.MESSAGES + '/' + props.recordID, '', domain.MESSAGES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    let senderInfo = '';
                    let fieldObj = {};
                    let sendTo = sendToOptions.find((ele) => ele.value?.toLowerCase() === resp.data.receivertype?.toLowerCase());
                    /*
                     * if (resp.data.receivertype?.toLowerCase() === 'policy') {
                     *     senderInfo = polArr.find((ele) => ele.value === resp.data.receiver);
                     *     fieldObj = { label: sendTo?.label, value: 'senderInfo', options: polArr, isMulti: true };
                     * } else
                     */
                    if (resp.data.receivertype?.toLowerCase() === 'device') {
                        senderInfo = mode !== 'view' ? resp.data.receiver : resp.data.receiver_serial;
                        fieldObj = { label: sendTo?.label, value: 'device', type: 'text' };
                    } else if (resp.data.receivertype?.toLowerCase() === 'group') {
                        if (resp.data.receiver?.[0] === '*') {
                            senderInfo = resp.data.receiver;
                            fieldObj = { label: sendTo?.label, value: 'senderInfo', options: props.deviceGroups };
                        } else {
                            let prepareGroups = handleSentGroups(resp.data.receiver);
                            senderInfo = prepareGroups.senderInfo;
                            setDeviceGroups(prepareGroups.groups);
                            fieldObj = { label: sendTo?.label, value: 'senderInfo', options: prepareGroups.groups };
                        }
                    }
                    let scheduledTime = resp?.data?.scheduledtime ? new Date(resp?.data?.scheduledtime) : '';
                    if (resp.data?.scheduledtime) setDateVal(scheduledTime);
                    let obj = {
                        type: { label: 'Announcement', value: 'ANNOUNCEMENT', key: 'announcement' },
                        messageType: resp.data?.deliverytype,
                        sendTo: sendTo,
                        sendType: resp?.data?.receiver?.[0] === '*' ? 'ALL' : 'SOME',
                        senderInfo: senderInfo,
                        title: resp.data.title,
                        question: resp.data.title,
                        answer: resp.data.answer,
                        format: FORMATS.find((format) => format.key === resp.data.format),
                        messageWithStyle: resp.data.message,
                        files: resp.data.files,
                        scheduledTime: scheduledTime ? new Date(scheduledTime).toISOString() : ''
                    };
                    if (mode === 'view') setFiles(resp.data.files);
                    setExtraObj({ ...fieldObj });
                    setCurrentDetailsObj({ ...obj });
                    props.setRecordDetails(JSON.parse(JSON.stringify(obj)));
                    props.setCanvasLoader(false);
                }
            })
            .catch((_err) => {
                props.setCanvasLoader(false);
            });
    };

    const handleChange = (e, field) => {
        if (field.label === 'Device') {
            setCurrentDetailsObj({ ...currentDetailsObj, message: '', messageWithStyle: '', files: [], [field.value]: e.target.value });
            return;
        }
        setCurrentDetailsObj({ ...currentDetailsObj, [field.value]: e.target.value });
    };

    const handleSelectChange = (e, field) => {
        setExtraObj({});
        setCheckField(false);
        let cObj = { ...currentDetailsObj, senderInfo: [], [field.value]: e };
        if (e.value === 'GROUP') cObj.sendType = 'SOME';
        setCurrentDetailsObj(cObj);
        let fieldObj;
        if (e.value === 'POLICY') fieldObj = { label: e.label, value: 'senderInfo', options: policies, isMulti: true };
        else if (e.value === 'DEVICE') fieldObj = { label: e.label, value: 'device', type: 'text' };
        else if (e.value === 'GROUP') fieldObj = { label: e.label, value: 'senderInfo', options: props.deviceGroups, hideSendType: true };
        else fieldObj = {};
        setExtraObj(fieldObj);
    };

    const handleSubSelectChange = (e, field) => {
        setCurrentDetailsObj({ ...currentDetailsObj, [field.value]: e });
    };

    const handleDownload = (file) => {
        const fileURL = file.endpoint + file.serverfilename;
        DownloadBunnyFile(fileURL, file.filename, props.setCanvasLoader);
    };

    const handleTypeChange = (e, field) => {
        setCurrentDetailsObj({
            ...currentDetailsObj,
            sendTo: '',
            senderInfo: [],
            sendType: 'ALL',
            title: '',
            message: '',
            time: '',
            type: '',
            scheduledTime: '',
            messageWithStyle: '',
            files: [],
            messageType: 'IMMEDIATE'
        });
    };

    const selectComponent = (field) => {
        return (
            <div className="mb-3 mb-lg-0">
                <Row className="d-flex align-items-center">
                    <Col xs={12} sm={12} md={12} xl={3} lg={3}>
                        <Label className="mb-0 form-label d-flex align-items-center">
                            {field.label}
                            {mode !== 'view' ? <span className="red-color ps-1"> *</span> : <span className="ps-1"> :</span>}
                        </Label>
                    </Col>
                    <Col>
                        {mode !== 'view' ? (
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                isMulti={field.isMulti ? true : false}
                                isClearable={field.isMulti ? true : false}
                                closeMenuOnSelect={field.isMulti ? false : true}
                                id={field.label}
                                name={field.value}
                                options={field.options}
                                placeholder={`Select ${field.label}`}
                                onChange={(e) =>
                                    field.value === 'sendTo'
                                        ? handleSelectChange(e, field)
                                        : field.value === 'type'
                                        ? handleTypeChange(e, field)
                                        : handleSubSelectChange(e, field)
                                }
                                value={currentDetailsObj[field.value] || ''}
                                isSearchable={true}
                                classNamePrefix={'custom-select'}
                                noOptionsMessage={() => 'No data found'}
                            />
                        ) : currentDetailsObj[field.value]?.length > 0 ? (
                            currentDetailsObj[field.value]
                                ?.map((v) => v.value)
                                ?.toString()
                                ?.split(',')
                                ?.join(', ')
                        ) : currentDetailsObj[field.value]?.label ? (
                            currentDetailsObj[field.value]?.label
                        ) : currentDetailsObj[field.value] ? (
                            currentDetailsObj[field.value]
                        ) : (
                            '–'
                        )}
                    </Col>
                </Row>
            </div>
        );
    };

    const checkExisting = (reference, _type) => {
        let deviceArr = [...currentDetailsObj.senderInfo, reference?.trim()];
        setCurrentDetailsObj({
            ...currentDetailsObj,
            device: '',
            senderInfo: deviceArr
        });
        if (currentDetailsObj.senderInfo?.includes(reference?.trim())) {
            toast.error(toastMessages.deviceAlreadyExists);
            return;
        }
        /*
         * props.setCanvasLoader(true);
         * api.get(url.DEVICES + '/serial/' + reference?.trim()?.toUpperCase())
         *     .then((resp) => {
         *         resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
         *         if (resp?.data) {
         *             if (resp.status?.toLowerCase() === 'success') {
         *                 let deviceArr = [...currentDetailsObj.senderInfo, reference?.trim()?.toUpperCase()];
         *                 setCurrentDetailsObj({
         *                     ...currentDetailsObj,
         *                     device: '',
         *                     senderInfo: deviceArr
         *                 });
         *                 setCheckField(true);
         *             }
         *             props.setCanvasLoader(false);
         *         }
         *     })
         *     .catch((_err) => props.setCanvasLoader(false));
         */
    };

    const handleLanguageChange = (e) => {
        setLanguage(e);
    };

    const handleStyle = (tab) => {
        setStyleComponent(true);
        setCurrentDetailsObj({ ...currentDetailsObj, messageWithStyle: '<div>' + currentDetailsObj.message + '</div>' });
    };

    const handleRemoveDevice = (device) => {
        let deviceArr = currentDetailsObj.senderInfo;
        deviceArr = deviceArr.filter((deviceObj) => deviceObj.id !== device.id);
        setCurrentDetailsObj({ ...currentDetailsObj, senderInfo: deviceArr });
    };

    const lookupDevices = () => {
        props.setCanvasLoader(true);
        api.create(url.DEVICE_LOOKUP, { select: { page: 1, size: 100 }, filter: { search: value } })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    setDevices(resp.data.records);
                }
                props.setCanvasLoader(false);
                setOpenPanel(true);
            })
            .catch((err) => props.setCanvasLoader(false));
    };

    const handleDeviceSelect = (device) => {
        let deviceArr = [...currentDetailsObj.senderInfo, device];
        let deviceFound = false;
        currentDetailsObj?.senderInfo?.forEach((info) => {
            if (info.id === device.id) deviceFound = true;
        });
        if (deviceFound) {
            toast.error(toastMessages.deviceAlreadyExists);
            return;
        }
        setCurrentDetailsObj({
            ...currentDetailsObj,
            device: '',
            senderInfo: deviceArr
        });
    };

    const inputComponent = (field) => {
        return (
            <div className="mb-3 mb-lg-0">
                <Row className="d-flex align-items-center">
                    <Col xs={12} sm={12} md={12} xl={3} lg={3}>
                        <Label className="mb-0 form-label d-flex align-items-center">
                            {field.label}
                            {mode !== 'view' ? <span className="red-color ps-1"> *</span> : <span className="ps-1"> :</span>}
                        </Label>
                    </Col>
                    <Col>
                        <div className="input-group">
                            {mode !== 'view' ? (
                                <>
                                    {field.label !== 'Device' && (
                                        <Input
                                            id={field.label}
                                            type={field.type}
                                            name={field.value}
                                            maxLength={field.maxLength}
                                            className="form-control"
                                            placeholder={`Enter ${field.label}`}
                                            onChange={(e) => handleChange(e, field)}
                                            value={currentDetailsObj[field.value] || ''}
                                        />
                                    )}
                                    {field.label === 'Device' && (
                                        <Row>
                                            <Col sm="9">
                                                <div className={`form-icon ${props.largeSearchFilter ? 'w-100' : ''}`}>
                                                    <input
                                                        autoComplete="off"
                                                        type="text"
                                                        maxLength={props.filter1CharLimit ? props.filter1CharLimit : ''}
                                                        id="search-bar-0"
                                                        value={value || ''}
                                                        className={
                                                            (props.showTooltip ? 'holder' : '') +
                                                            ' form-control form-control-icon dash-filter-picker'
                                                        }
                                                        placeholder={`Search ${
                                                            props.searchPlaceHolder ? 'by ' + props.searchPlaceHolder : ''
                                                        }`}
                                                        onChange={(e) => setValue(e.target.value?.replace('  ', ' '))}
                                                    />
                                                    <i className="ri-search-line link-info"></i>
                                                </div>
                                                <div id="overlay">
                                                    {value && value !== '' && openPanel && (
                                                        <Card
                                                            className={
                                                                'w-100 pt-2 z-index-99 border border-1 border-success position-absolute end-0 height-200 overflow-y-auto'
                                                            }
                                                        >
                                                            <div className="d-flex align-items-center justify-content-end me-3">
                                                                <i
                                                                    className="ri-close-circle-fill text-muted cursor-pointer"
                                                                    onClick={() => setOpenPanel(false)}
                                                                />
                                                            </div>
                                                            <ul className="pe-3 ps-3">
                                                                {devices?.length > 0 ? (
                                                                    devices.map((result, ind) => (
                                                                        <li
                                                                            key={ind}
                                                                            className={
                                                                                'list-style-none py-2 cursor-pointer ' +
                                                                                (ind !== devices?.length - 1
                                                                                    ? 'border-2 border-bottom'
                                                                                    : '')
                                                                            }
                                                                            onClick={() => handleDeviceSelect(result)}
                                                                        >
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="fw-semibold">{result.serial}</div>
                                                                                <div className="ms-1 text-muted">
                                                                                    - {result.source?.toUpperCase()}
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                ) : (
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                        No Results Found.
                                                                    </div>
                                                                )}
                                                            </ul>
                                                        </Card>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col sm="3">
                                                <Button
                                                    type="button"
                                                    color={'primary'}
                                                    className="ms-2"
                                                    disabled={!value || value === ''}
                                                    onClick={lookupDevices}
                                                >
                                                    Search
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            ) : currentDetailsObj[field.value] ? (
                                <span className="text-truncate">{currentDetailsObj[field.value]}</span>
                            ) : field.label === 'Device' ? (
                                ''
                            ) : (
                                '–'
                            )}
                        </div>
                    </Col>
                </Row>
                {field.value === 'device' && (
                    <div className="filter-choices-input overflow-auto p-2">
                        <div className="choices" data-type="text">
                            <div className="choices__inner min-height-1">
                                <div className="choices__list choices__list--multiple">
                                    {currentDetailsObj?.senderInfo?.length > 0 ? (
                                        currentDetailsObj?.senderInfo.map((ele, ind) => {
                                            return (
                                                <div
                                                    key={ind}
                                                    className={`choices__item choices__item--selectable bg-${field.color} border-white`}
                                                >
                                                    {ele?.serial ? ele.serial : ele}
                                                    {mode !== 'view' && (
                                                        <button
                                                            type="button"
                                                            className="choices__button"
                                                            onClick={() => handleRemoveDevice(ele)}
                                                        >
                                                            Remove item
                                                        </button>
                                                    )}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="d-flex align-items-center justify-content-center fs-14 fw-normal mt-2">
                                                No Devices Found
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const setCheckedGroup = (groupsArr, group) => {
        groupsArr.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
            if (groupObj.children) groupObj.children = setCheckedGroup(groupObj.children, group);
        });
        return groupsArr;
    };

    const setCheckedGroupArr = (groups, checkedGroups) => {
        groups.forEach((groupObj) => {
            if (groupObj.checked) checkedGroups.push({ label: groupObj.label, value: groupObj.code });
            if (groupObj.children) checkedGroups = setCheckedGroupArr(groupObj.children, checkedGroups);
        });
        return checkedGroups;
    };

    const changeGroups = (groups, _checked, group) => {
        let checkedGroups = [];
        groups.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
            if (groupObj.children) groupObj.children = setCheckedGroup(groupObj.children, group);
        });
        groups.forEach((groupObj) => {
            if (groupObj.checked) checkedGroups.push({ label: groupObj.label, value: groupObj.code });
            if (groupObj.children) checkedGroups = setCheckedGroupArr(groupObj.children, checkedGroups);
        });
        setCurrentDetailsObj({ ...currentDetailsObj, senderInfo: checkedGroups });
        setDeviceGroups(groups);
    };

    const treeComponent = () => {
        return (
            <div className="overflow-auto border p-4">
                <div className="max-height-350 height-300 min-height-50">
                    <AnimatedTreeComponent
                        groups={deviceGroups}
                        flatGroups={props.groupsData}
                        changeGroups={changeGroups}
                        disableCheckbox={mode === 'view'}
                    />
                </div>
            </div>
        );
    };

    const handleScheduledDate = (e) => {
        setDateVal(e);
        let selectedDate = new Date(e);
        let currentDate = new Date();
        setInvalidDate(selectedDate < currentDate);
        setCurrentDetailsObj({ ...currentDetailsObj, time: e, scheduledTime: new Date(e).toISOString() });
    };

    const handleFileDelete = (file) => {
        props.setCanvasLoader(true);
        api.delete(url.FILES + '/' + file.serverFilename, '', domain.MESSAGES_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    let filesArr = currentDetailsObj.files;
                    filesArr = filesArr.filter((fileObj) => file.serverFilename !== fileObj.serverFilename);
                    setCurrentDetailsObj({ ...currentDetailsObj, files: filesArr });
                }
                props.setCanvasLoader(false);
            })
            .catch((_err) => props.setCanvasLoader(false));
    };

    const handleFiles = (fileItems) => {
        if (fileItems) {
            let fileType = fileItems?.fileType?.split('/')[0];
            let formData = new FormData();
            let validObj = fileValidation(fileType, fileItems?.fileExtension, fileTypeValidations);
            if (!validObj.valid) {
                toast.error('File Extension should be ' + validObj.message);
                return;
            }
            if (fileItems?.fileSize > 30000000) {
                toast.error(toastMessages.fileSizeLimit);
                return;
            }
            props.setCanvasLoader(true);
            formData.append('file', fileItems?.file);
            api.create(url.FILES, formData, true, domain.MESSAGES_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        setCurrentDetailsObj({ ...currentDetailsObj, files: currentDetailsObj.files.concat(resp.data) });
                    }
                    props.setCanvasLoader(false);
                })
                .catch((_err) => props.setCanvasLoader(false));
        }
    };

    return (
        <React.Fragment>
            <Form className="h-100">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
                    <div className="h-100">
                        <div className="mb-3 mb-lg-0 d-flex flex-column gap-4">
                            {typesArr?.length > 1 && selectComponent({ label: 'Type', value: 'type', options: typesArr })}
                            {currentDetailsObj?.type?.value === 'ANNOUNCEMENT' ? (
                                <>
                                    {parsedTenant.ENABLE_SCHEDULE_MESSAGE && (
                                        <Row className="d-flex align-items-center">
                                            <Col xs={12} sm={12} md={12} xl={3} lg={3}>
                                                <Label className="mb-0 form-label d-flex align-items-center">
                                                    Message type
                                                    {mode !== 'view' ? (
                                                        <span className="red-color ps-1"> *</span>
                                                    ) : (
                                                        <span className="ps-1"> :</span>
                                                    )}
                                                </Label>
                                            </Col>
                                            <Col>
                                                {mode !== 'view' ? (
                                                    <Row>
                                                        <Col sm="auto">
                                                            <div className="form-check form-check-inline">
                                                                <Input
                                                                    type="radio"
                                                                    value={'immediate'}
                                                                    id={'immediate'}
                                                                    name={'immediate'}
                                                                    className="form-check-input"
                                                                    onChange={(e) => {
                                                                        setCurrentDetailsObj({
                                                                            ...currentDetailsObj,
                                                                            messageType: 'IMMEDIATE',
                                                                            scheduledTime: ''
                                                                        });
                                                                        setDateVal('');
                                                                    }}
                                                                    checked={currentDetailsObj.messageType?.toUpperCase() === 'IMMEDIATE'}
                                                                />
                                                                <Label className="form-check-label">Standard</Label>
                                                            </div>
                                                        </Col>
                                                        <Col sm="auto">
                                                            <div className="form-check form-check-inline">
                                                                <Input
                                                                    type="radio"
                                                                    value={'scheduled'}
                                                                    id={'scheduled'}
                                                                    name={'scheduled'}
                                                                    className="form-check-input"
                                                                    onChange={(e) =>
                                                                        setCurrentDetailsObj({
                                                                            ...currentDetailsObj,
                                                                            messageType: 'SCHEDULED'
                                                                        })
                                                                    }
                                                                    checked={currentDetailsObj.messageType?.toUpperCase() === 'SCHEDULED'}
                                                                />
                                                                <Label className="form-check-label">Scheduled</Label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    currentDetailsObj?.messageType
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                    {currentDetailsObj.messageType === 'SCHEDULED' && (
                                        <Row>
                                            <Col
                                                xs={4}
                                                md={4}
                                                sm={4}
                                                lg={3}
                                                xl={3}
                                                className="d-flex align-items-center justify-content-between"
                                            >
                                                <Label className="form-check-label fw-medium d-flex align-items-center">
                                                    Date and time
                                                    {props.mode !== 'view' ? (
                                                        <span className="red-color ps-1">*</span>
                                                    ) : (
                                                        <span className="ps-1">:</span>
                                                    )}
                                                </Label>
                                            </Col>
                                            <Col xs={5} sm={5} md={5} xl={5} lg={5}>
                                                {mode !== 'view' ? (
                                                    <div className="form-icon flex-nowrap">
                                                        <Flatpickr
                                                            ref={fp}
                                                            value={dateVal}
                                                            placeholder={'Select date and time to send'}
                                                            className={'form-control form-control-icon w-100 date-filter-picker'}
                                                            options={{
                                                                enableTime: true,
                                                                mode: 'single',
                                                                dateFormat: 'd/m/Y H:i',
                                                                minDate: 'today',
                                                                minuteIncrement: 1
                                                            }}
                                                            onChange={(e) => handleScheduledDate(e)}
                                                        />
                                                        <i className="ri-calendar-2-line" />
                                                    </div>
                                                ) : (
                                                    dateVal.getDate() +
                                                    ' ' +
                                                    MONTHS[dateVal.getMonth() + 1] +
                                                    ', ' +
                                                    dateVal.getFullYear() +
                                                    ' ' +
                                                    dateVal.getHours() +
                                                    ':' +
                                                    (dateVal.getMinutes() < 10 ? '0' + dateVal.getMinutes() : dateVal.getMinutes())
                                                )}
                                                {invalidDate && (
                                                    <div className="text-danger fs-10 fw-medium mt-2 d-flex">
                                                        <i className="me-2 ri-information-line fs-16" />
                                                        {toastMessages.timeValidation}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                    {inputComponent({ label: 'Title', value: 'title', type: 'text', maxLength: 30 })}
                                    {selectComponent({ label: 'Send To', value: 'sendTo', options: sendToOptions })}
                                    {currentDetailsObj?.sendTo?.value &&
                                        currentDetailsObj?.sendTo?.value !== '' &&
                                        !extraObj.hideSendType && (
                                            <Row className="d-flex align-items-center">
                                                <Col xs={12} sm={12} md={12} xl={3} lg={3}></Col>
                                                <Col>
                                                    {mode !== 'view' ? (
                                                        <Row>
                                                            <Col sm="auto">
                                                                <div className="form-check form-check-inline">
                                                                    <Input
                                                                        type="radio"
                                                                        value={'all'}
                                                                        id={'all'}
                                                                        name={'all'}
                                                                        className="form-check-input"
                                                                        onChange={(e) => {
                                                                            setCurrentDetailsObj({
                                                                                ...currentDetailsObj,
                                                                                sendType: 'ALL',
                                                                                senderInfo: []
                                                                            });
                                                                        }}
                                                                        checked={currentDetailsObj.sendType?.toUpperCase() === 'ALL'}
                                                                    />
                                                                    <Label className="form-check-label">All</Label>
                                                                </div>
                                                            </Col>
                                                            <Col sm="auto">
                                                                <div className="form-check form-check-inline">
                                                                    <Input
                                                                        type="radio"
                                                                        value={'some'}
                                                                        id={'some'}
                                                                        name={'some'}
                                                                        className="form-check-input"
                                                                        onChange={(e) =>
                                                                            setCurrentDetailsObj({
                                                                                ...currentDetailsObj,
                                                                                sendType: 'SOME',
                                                                                senderInfo: []
                                                                            })
                                                                        }
                                                                        checked={currentDetailsObj.sendType?.toUpperCase() === 'SOME'}
                                                                    />
                                                                    <Label className="form-check-label">Specific</Label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ) : (
                                                        currentDetailsObj?.sendType
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                    {currentDetailsObj?.sendType === 'SOME' &&
                                        Object.keys(extraObj)?.length > 0 &&
                                        (extraObj.label?.toLowerCase() === 'device'
                                            ? inputComponent(extraObj)
                                            : extraObj.label?.toLowerCase() === 'device group'
                                            ? treeComponent()
                                            : selectComponent(extraObj))}
                                    {currentDetailsObj?.title &&
                                        currentDetailsObj?.sendTo &&
                                        (currentDetailsObj?.sendType === 'ALL' ? true : currentDetailsObj?.senderInfo?.length > 0) && (
                                            <>
                                                <Row>
                                                    <Col className="mb-2">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <Label className="form-label d-flex align-items-center">
                                                                Message
                                                                {mode !== 'view' ? (
                                                                    <span className="red-color ps-1"> *</span>
                                                                ) : (
                                                                    <span className="ps-1"> :</span>
                                                                )}
                                                            </Label>
                                                            {mode !== 'view' &&
                                                                (styleComponent ? (
                                                                    'English'
                                                                ) : (
                                                                    <Select
                                                                        getOptionValue={(option) => option.value}
                                                                        getOptionLabel={(option) => option.label}
                                                                        id={'language'}
                                                                        name={'language'}
                                                                        options={languageOptions}
                                                                        placeholder="Select Language"
                                                                        onChange={(e) => handleLanguageChange(e)}
                                                                        value={language || { label: 'English', value: 'en' }}
                                                                        isSearchable={true}
                                                                        classNamePrefix="custom-select"
                                                                        noOptionsMessage={() => 'No language found'}
                                                                    />
                                                                ))}
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} xl={12} lg={12}>
                                                        {!styleComponent && mode === 'add' ? (
                                                            <ReactTransliterate
                                                                className="w-100 p-3"
                                                                renderComponent={(component) => <textarea {...component} rows={10} />}
                                                                value={currentDetailsObj.message}
                                                                onChangeText={(text) =>
                                                                    setCurrentDetailsObj({ ...currentDetailsObj, message: text })
                                                                }
                                                                lang={language.value}
                                                            />
                                                        ) : (
                                                            <CKEditor
                                                                id="editor"
                                                                editor={ClassicEditor}
                                                                disabled={mode === 'view'}
                                                                config={{
                                                                    toolbar: { items: items },
                                                                    placeholder: 'Type or paste your content here!',
                                                                    fillEmptyBlocks: false,
                                                                    forceEnterMode: false,
                                                                    ignoreEmptyParagraph: false,
                                                                    isReadOnly: mode === 'view'
                                                                }}
                                                                data={currentDetailsObj.messageWithStyle}
                                                                onChange={(event, editor) =>
                                                                    setCurrentDetailsObj({
                                                                        ...currentDetailsObj,
                                                                        messageWithStyle: editor.getData()
                                                                    })
                                                                }
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                                {!styleComponent && mode === 'add' && (
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-label waves-effect right waves-light"
                                                            disabled={!currentDetailsObj.message}
                                                            onClick={handleStyle}
                                                        >
                                                            Click to style the content
                                                            <i className="ri-arrow-right-s-line label-icon align-middle fs-16 ms-2" />
                                                        </button>
                                                    </div>
                                                )}
                                                <Row className="d-flex align-items-center">
                                                    <Col xs={12} sm={12} md={12} xl={3} lg={3}>
                                                        <Label className="form-label d-flex mb-0 align-items-center">
                                                            Attachement
                                                            <span className="ps-1"> :</span>
                                                        </Label>
                                                    </Col>
                                                    <Col>
                                                        {mode === 'add' ? (
                                                            currentDetailsObj?.files?.length < 2 ? (
                                                                <>
                                                                    <FilePond
                                                                        name="files"
                                                                        minFileSize="5KB"
                                                                        maxFileSize="30MB"
                                                                        maxFiles={1}
                                                                        allowMultiple={true}
                                                                        files={[]}
                                                                        className="filepond filepond-input-multiple"
                                                                        onaddfile={(_error, fileItems) => handleFiles(fileItems)}
                                                                    />
                                                                    {currentDetailsObj?.files.map((item, index) => (
                                                                        <Col key={index}>
                                                                            <i
                                                                                className="ri-delete-bin-line link-danger me-2 cursor-pointer"
                                                                                onClick={() => handleFileDelete(item, index)}
                                                                            />
                                                                            <span
                                                                                onClick={() => handleDownload(item)}
                                                                                className="text-success text-decoration-underline cursor-pointer"
                                                                            >
                                                                                {item.filename}
                                                                            </span>
                                                                        </Col>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                currentDetailsObj?.files?.map((item, index) => {
                                                                    return (
                                                                        <Col key={index}>
                                                                            <i
                                                                                className="ri-delete-bin-line link-danger me-2 cursor-pointer"
                                                                                onClick={() => handleFileDelete(item, index)}
                                                                            />
                                                                            <span
                                                                                onClick={() => handleDownload(item)}
                                                                                className="text-success text-decoration-underline cursor-pointer"
                                                                            >
                                                                                {item.filename}
                                                                            </span>
                                                                        </Col>
                                                                    );
                                                                })
                                                            )
                                                        ) : mode === 'edit' ? (
                                                            currentDetailsObj?.files?.length < 2 ? (
                                                                <>
                                                                    <FilePond
                                                                        name="files"
                                                                        minFileSize="5KB"
                                                                        maxFileSize="30MB"
                                                                        maxFiles={1}
                                                                        allowMultiple={true}
                                                                        files={[]}
                                                                        className="filepond filepond-input-multiple"
                                                                        onaddfile={(_error, fileItems) => handleFiles(fileItems)}
                                                                    />
                                                                    {currentDetailsObj?.files.map((item, index) => (
                                                                        <Col key={index}>
                                                                            <i
                                                                                className="ri-delete-bin-line link-danger me-2 cursor-pointer"
                                                                                onClick={() => handleFileDelete(item, index)}
                                                                            />
                                                                            <span
                                                                                onClick={() => handleDownload(item)}
                                                                                className="text-success text-decoration-underline cursor-pointer"
                                                                            >
                                                                                {item.filename}
                                                                            </span>
                                                                        </Col>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                currentDetailsObj?.files?.map((item, index) => {
                                                                    return (
                                                                        <Col key={index}>
                                                                            <i
                                                                                className="ri-delete-bin-line link-danger me-2 cursor-pointer"
                                                                                onClick={() => handleFileDelete(item, index)}
                                                                            />
                                                                            <span
                                                                                onClick={() => handleDownload(item)}
                                                                                className="text-success text-decoration-underline cursor-pointer"
                                                                            >
                                                                                {item.filename}
                                                                            </span>
                                                                        </Col>
                                                                    );
                                                                })
                                                            )
                                                        ) : currentDetailsObj.files?.length > 0 ? (
                                                            currentDetailsObj?.files.map((item, index) => {
                                                                return (
                                                                    <Col
                                                                        key={index}
                                                                        onClick={() => handleDownload(item)}
                                                                        className="text-success text-decoration-underline cursor-pointer"
                                                                    >
                                                                        {item.filename}
                                                                    </Col>
                                                                );
                                                            })
                                                        ) : (
                                                            '–'
                                                        )}
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                </>
                            ) : (
                                <>
                                    {selectComponent({ label: 'Send To', value: 'sendTo', options: sendToOptions })}
                                    {Object.keys(extraObj)?.length > 0 &&
                                        (extraObj.label?.toLowerCase() === 'device'
                                            ? inputComponent(extraObj)
                                            : extraObj.label?.toLowerCase() === 'device group'
                                            ? treeComponent()
                                            : selectComponent(extraObj))}
                                    {extraObj.label?.toLowerCase() === 'device' && checkField && !props.canvasLoader && (
                                        <Row>
                                            <Col xs={12} sm={12} md={12} xl={3} lg={3}></Col>
                                            <Col>
                                                <div className="m-0 p-0 text-danger">No Device Found.</div>
                                            </Col>
                                        </Row>
                                    )}
                                    {
                                        <>
                                            {selectComponent({ label: 'Format', value: 'format', options: FORMATS, mandatory: true })}
                                            {currentDetailsObj?.format?.value &&
                                                (currentDetailsObj?.format?.value === 'txt' ? (
                                                    <Row>
                                                        <Col className="mb-2">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <Label className="form-label d-flex align-items-center">
                                                                    Description
                                                                    {mode !== 'view' ? (
                                                                        <span className="red-color ps-1"> *</span>
                                                                    ) : (
                                                                        <span className="ps-1"> :</span>
                                                                    )}
                                                                </Label>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} xl={12} lg={12}>
                                                            <CKEditor
                                                                id="editor"
                                                                editor={ClassicEditor}
                                                                disabled={mode === 'view'}
                                                                config={{
                                                                    toolbar: { items: items },
                                                                    placeholder: 'Type or paste your content here!',
                                                                    fillEmptyBlocks: false,
                                                                    forceEnterMode: false,
                                                                    ignoreEmptyParagraph: false,
                                                                    isReadOnly: mode === 'view'
                                                                }}
                                                                data={currentDetailsObj.messageWithStyle}
                                                                onChange={(event, editor) =>
                                                                    setCurrentDetailsObj({
                                                                        ...currentDetailsObj,
                                                                        messageWithStyle: editor.getData()
                                                                    })
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} xl={3} lg={3}>
                                                            <Label className="form-label d-flex align-items-center">
                                                                Attachement
                                                                {mode !== 'view' ? (
                                                                    <span className="red-color ps-1"> *</span>
                                                                ) : (
                                                                    <span className="ps-1"> :</span>
                                                                )}
                                                            </Label>
                                                        </Col>
                                                        <Col>
                                                            {mode === 'add' ? (
                                                                <FilePond
                                                                    name="files"
                                                                    minFileSize="5KB"
                                                                    maxFileSize="30MB"
                                                                    maxFiles={1}
                                                                    allowMultiple={true}
                                                                    files={files}
                                                                    className="filepond filepond-input-multiple"
                                                                    onupdatefiles={(fileItems) => setFiles(fileItems)}
                                                                />
                                                            ) : currentDetailsObj.files?.length > 0 ? (
                                                                currentDetailsObj?.files.map((item, index) => {
                                                                    return (
                                                                        <Col
                                                                            key={index}
                                                                            onClick={() => handleDownload(item)}
                                                                            className="text-success text-decoration-underline cursor-pointer"
                                                                        >
                                                                            {item.filename}
                                                                        </Col>
                                                                    );
                                                                })
                                                            ) : (
                                                                '–'
                                                            )}
                                                        </Col>
                                                    </Row>
                                                ))}
                                            {currentDetailsObj?.format?.value && (
                                                <>
                                                    {inputComponent({
                                                        label: 'Question',
                                                        value: 'question',
                                                        maxLength: '100',
                                                        mandatory: true
                                                    })}
                                                    {inputComponent({
                                                        label: 'Correct Answer',
                                                        value: 'answer',
                                                        maxLength: '100',
                                                        mandatory: true
                                                    })}
                                                </>
                                            )}
                                        </>
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </Col>
            </Form>
        </React.Fragment>
    );
};

export default AddMessage;
