import { toast } from 'react-toastify';
import toastMessages from '../../common/messages/toastMessages';
import { APIClient } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import * as domains from '../../helpers/domain_helper';

const api = new APIClient();
export const commonActionsService = (props) => {
    props.setLoading(true);
    props.setShowModal(false);
    let action;
    let toastMsg;

    if (props.type === 'reboot') {
        action = 'REBOOT';
        toastMsg = toastMessages.rebootDevice;
    } else if (props.type === 'lock') {
        action = 'LOCK';
        toastMsg = toastMessages.lockDevice;
    } else {
        action = 'RESET_PASSWORD';
        toastMsg = toastMessages.devicePassword;
    }

    let params = {
        command: action,
        params: props.type === 'resetpassword' ? { newPassword: props.values.newPassword } : undefined
    };
    api.create(url.DEVICE_ACTIONS + '/' + props.deviceID + '/command', params)
        .then((resp) => {
            resp = props.urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (resp.status?.toLowerCase() === 'success') {
                toast.success(toastMsg);
                props.getCommandHistory(props.deviceGroupOptions, true);
                props.setLoading(false);
            }
        })
        .catch((err) => {
            props.setLoading(false);
        });
};

export const CustomDPCDeviceActions = (props) => {
    props.setLoading(true);
    let toastMsg;
    let params = {};
    if (props.type === 'lock') {
        params.command = 'LOCK';
        toastMsg = toastMessages.lockDevice;
    }
    if (props.type === 'CAMERA') {
        params.command = props.command;
    } else {
        params.command = 'WIPE';
        toastMsg = toastMessages.factoryReset;
    }
    api.create(url.DEVICE_ACTIONS + '/' + props.id + '/command', params)
        .then((resp) => {
            resp = props.urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (resp.status?.toLowerCase() === 'success') {
                toast.success(toastMsg);
                props.setLoading(false);
            }
        })
        .catch((err) => props.setLoading(false));
};

export const tvDeviceActions = (props) => {
    props.setLoading(true);
    let toastMsg = props.toastMsg;
    let params = { ...props.actionBody };
    api.create(url.TV_COMMANDS, params, false, domains.ANDROID_V1)
        .then((resp) => {
            resp = props.urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (resp.status?.toLowerCase() === 'success') {
                toast.success(toastMsg);
                props.setLoading(false);
            }
        })
        .catch((err) => props.setLoading(false));
};
