const settingFieldsArray = [
    {
        heading: 'Network Settings',
        bgClass: 'bg-e0ebf8 text-primary fw-semibold ',
        hideAll: true
    },
    {
        heading: 'Wi-Fi Settings',
        image: 'wifi',
        value: 'show_wifi',
        password_value: 'req_admin_password_wifi',
        password_type: 'wifi',
        password: 'wifi',
        req_timer: 'req_timer_wifi',
        timer: 'timer_wifi'
    },
    /*
     * {
     *     heading: 'SIM Settings',
     *     image: 'sim',
     *     value: 'show_sim',
     *     password_value: 'req_admin_password_sim',
     *     password_type: 'sim',
     *     password: 'sim'
     * },
     */
    {
        heading: 'Data Roaming',
        image: 'globe',
        value: 'show_data_roaming',
        password_value: 'req_admin_password_data',
        password_type: 'data_roaming',
        password: 'data_roaming',
        req_timer: 'req_timer_data_roaming',
        timer: 'timer_data_roaming'
    },
    {
        heading: 'Bluetooth',
        image: 'bluetooth',
        value: 'show_bluetooth',
        password_value: 'req_admin_password_bluetooth',
        password_type: 'bluetooth',
        password: 'bluetooth',
        req_timer: 'req_timer_bluetooth',
        timer: 'timer_bluetooth'
    },
    {
        heading: 'Mobile Data',
        image: 'mobiledata',
        value: 'show_mobile_data',
        password_value: 'req_admin_password_mobile_data',
        password_type: 'mobile_data',
        password: 'mobile_data',
        req_timer: 'req_timer_mobile_data',
        timer: 'timer_mobile_data'
    },
    {
        heading: 'Enable/Disable SIM',
        image: 'enableSIM',
        value: 'show_enable_sim',
        password_value: 'req_admin_password_enable_sim',
        password_type: 'enable_sim',
        password: 'enable_sim',
        req_timer: 'req_timer_enable_sim',
        timer: 'timer_enable_sim'
    },
    { heading: 'Display Settings', bgClass: 'bg-e0ebf8 text-primary fw-semibold ', hideAll: true },
    {
        heading: 'Screen Orientation',
        image: 'screenOrientation',
        value: 'show_screen_orientation',
        password_value: 'req_admin_password_screen_orientation',
        password_type: 'screen_orientation',
        password: 'screen_orientation',
        req_timer: 'req_timer_screen_orientation',
        timer: 'timer_screen_orientation'
    },
    {
        heading: 'Brightness',
        image: 'brightness',
        value: 'show_brigntness',
        password_value: 'req_admin_password_brightness',
        password_type: 'brightness',
        password: 'brightness',
        req_timer: 'req_timer_brightness',
        timer: 'timer_brightness'
    },
    { heading: 'Device Details', bgClass: 'bg-e0ebf8 text-primary fw-semibold ', hideAll: true },
    {
        heading: 'Device details',
        image: 'details',
        value: 'show_device_details',
        password_value: 'req_admin_password_device_details',
        password_type: 'details',
        password: 'details',
        req_timer: 'req_timer_device_details',
        timer: 'timer_device_details'
    },
    {
        heading: 'Device Policy',
        image: 'policy',
        value: 'show_policy',
        password_value: 'req_admin_password_policy',
        password_type: 'policy_settings',
        password: 'policy_settings',
        req_timer: 'req_timer_policy_settings',
        timer: 'timer_policy_settings'
    },
    /*
     * { heading: 'Apps', bgClass: 'bg-e0ebf8 text-primary fw-semibold ', hideAll: true },
     * {
     *     heading: 'App Updates',
     *     image: 'appUpdates',
     *     disableShow: true,
     *     value: 'show_app_update',
     *     password_value: 'req_admin_password_app_update',
     *     password_type: 'app_updates',
     *     password: 'app_updates'
     * },
     */
    { heading: 'Control Settings', bgClass: 'bg-e0ebf8 text-primary fw-semibold ', hideAll: true },
    {
        heading: 'System Settings',
        image: 'systemSettings',
        value: 'show_system_settings',
        password_value: 'req_admin_password_system_settings',
        password_type: 'system_settings',
        password: 'system_settings',
        req_timer: 'req_timer_system_settings',
        timer: 'timer_system_settings'
    },
    {
        heading: 'Keyboard Settings',
        image: 'keyboard',
        value: 'show_keyboard',
        password_value: 'req_admin_password_keyboard',
        password_type: 'keyboard',
        password: 'keyboard',
        req_timer: 'req_timer_keyboard',
        timer: 'timer_keyboard'
    },
    { heading: 'Refresh Screen Settings', bgClass: 'bg-e0ebf8 text-primary fw-semibold ', hideAll: true },
    {
        heading: 'Wi-Fi',
        image: 'refreshWifi',
        value: 'show_refresh_wifi',
        disableShow: true,
        password_value: 'req_refresh_wifi',
        password_type: 'refresh_wifi',
        password: 'refresh_wifi',
        req_timer: 'req_timer_refresh_wifi',
        timer: 'timer_refresh_wifi'
    },
    {
        heading: 'Kiosk Exit Password',
        image: 'kioskExit',
        value: 'show_kiosk_exit',
        hideOnTenant: true,
        tenantkey: 'KIOSK_EXIT',
        password_value: 'req_admin_password_kiosk_exit',
        password_type: 'kiosk_exit',
        password: 'kiosk_exit',
        req_timer: 'req_timer_kiosk_exit',
        timer: 'timer_kiosk_exit'
    }
];

export default settingFieldsArray;
