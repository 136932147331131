/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FilePond } from 'react-filepond';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Input, Label } from 'reactstrap';
import { COUNTRIES } from '../../../Components/constants/Countries';
import { volumePurchaseData } from '../../../Components/constants/constants';
import VPPImage from '../../../assets/images/apple/png/common/apps_books.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import VPPCard from './VPPCard';

const VolumePurchaseProgram = ({ setLoading, getVPPData, vpp, handleDelete }) => {
    const api = new APIClient();
    const [apkFiles, setAPKFiles] = useState([]);
    const urlconf = useEnv();
    const [showSteps, setShowSteps] = useState(false);
    const [showInitialScreen, setShowInitialScreen] = useState(true);
    const [vppData, setVppData] = useState(vpp ? { ...vpp } : '');
    const [country, setCountry] = useState();
    const [autoUpdate, setAutoUpdate] = useState(false);

    useEffect(() => {
        setVppData(vpp ? { ...vpp } : '');
    }, [vpp]);

    const onConfigureApn = () => {
        setShowSteps(true);
        setShowInitialScreen(false);
    };

    const handleCountrySave = () => {
        setLoading(true);
        api.patch(
            url.VPP_REGION,
            { region: country?.code, appautoupdate: autoUpdate ? 'Y' : 'N', accounttype: 'BUSINESS' },
            false,
            domains.IOS_V1
        )
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.countrySaved);
                    setCountry(country);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleFileSave = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', apkFiles?.[0]?.file);
        api.patch(url.VPP_TOKEN_UPLOAD, formData, false, domains.IOS_V1)
            .then((newRep) => {
                newRep = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(newRep) : newRep;

                if (newRep.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.vppConfigured);
                    setShowSteps(false);
                    setShowInitialScreen(true);
                    setCountry('');
                    setAutoUpdate(false);
                    setAPKFiles([]);
                    getVPPData();
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const onDownloadClick = (ele) => {
        if (ele === 'Sign In to Apple Business Manager') {
            let a = document.createElement('a');
            a.href = 'https://business.apple.com/#/main/preferences/mdmserver-new';
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        if (ele === 'Sign In to Apple School Manager') {
            let a = document.createElement('a');
            a.href = 'https://school.apple.com/';
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    const handleAction = (type) => {
        if (type === 'change') setShowSteps(true);
        else if (type === 'delete') {
            handleDelete({ enabled: true, id: vppData?._id });
            setShowInitialScreen(true);
        }
    };

    return (
        <Card className="card-height-100">
            <CardHeader>
                <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                    <div className="fs-14 ">Apps & Books(VPP)</div>
                    <div className={`d-flex justify-content-center align-items-center fs-12 ${!vppData ? 'text-danger' : 'text-primary'}`}>
                        {!vppData ? (
                            <i className="ri-error-warning-fill fs-16 px-1 text-danger"></i>
                        ) : (
                            <i className="ri-checkbox-circle-fill fs-16 px-1 text-primary"></i>
                        )}
                        {!vppData ? 'Not Configured' : 'Configured'}
                    </div>
                </div>
                <div className="padding-top-10 font-size-12 fw-medium text-muted">
                    Volume Purchase Program(VPP) by Apple, streamlines app management for organizations. It facilitates businesses, schools
                    and enterprises to buy apps in bulk for their devices through MDM.
                </div>
            </CardHeader>
            {!vppData && showInitialScreen ? (
                <div>
                    <CardBody>
                        <div className=" text-center py-20">
                            <div>
                                <img height={65} src={VPPImage} alt="img"></img>
                            </div>
                            <div className="fw-medium fs-14 py-2">Apps & Books(VPP)</div>
                            <div className="font-size-12 text-start fw-medium text-muted px-2">
                                Configuring Apple VPP simplifies bulk app purchases for organizations, enhancing the volume of purchases and
                                multiple payment options.
                            </div>
                            <div className="py-4">
                                <Button className="bg-primary px-15 py-3 border-none fw-medium fs-12" onClick={() => onConfigureApn()}>
                                    Configure
                                </Button>
                            </div>
                            {/* <div className="fs-12 fw-medium">
                                <span className="text-danger fs-13 fw-semibold">*</span>
                                <i>VPP is mandatory for Silent Pushing of Application</i>
                            </div> */}
                        </div>
                    </CardBody>
                </div>
            ) : !showSteps ? (
                <div>
                    <CardBody>
                        <div className=" text-center padding-top-35">
                            <div>
                                <img height={69} src={VPPImage} alt="img"></img>
                            </div>
                            <div className="fw-semibold fs-14 py-3">Apps & Books(VPP)</div>
                        </div>
                        <VPPCard data={vppData} handleAction={handleAction} />
                    </CardBody>
                </div>
            ) : (
                <CardBody>
                    <div>
                        {volumePurchaseData.map((ele, ind) => {
                            return (
                                <div
                                    className={` py-4 ${
                                        ind !== volumePurchaseData.length - 1 ? 'border-1 border-bottom-dashed border-grey' : ''
                                    }`}
                                    key={ind}
                                >
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img height={32} src={ele.image} alt="img"></img>
                                        </div>
                                        <div className="px-2">
                                            <div className="text-primary fw-medium">{ele.step}</div>
                                            <div className="fw-semibold fs-13">{ele.title}</div>
                                        </div>
                                    </div>
                                    <div className="py-2 fs-12 fw-medium text-muted">{ele.text}</div>
                                    <div className="d-flex justify-content-between align-items-center text-secondary cursor-pointer">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div>
                                                {ind !== volumePurchaseData.length - 1 ? (
                                                    <img height={ind === 0 ? 16 : 14} src={ele.icon} alt="img"></img>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div
                                                className="text-decoration-underline px-1 fs-13 fw-semibold"
                                                onClick={() => onDownloadClick(ele.linkText)}
                                            >
                                                {ele.linkText}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div>{ind === 0 ? <img height={ind === 0 ? 16 : 14} src={ele.icon2} alt="img"></img> : ''}</div>
                                            <div
                                                className="text-decoration-underline px-1 fs-13 fw-semibold "
                                                onClick={() => onDownloadClick(ele.linkText2)}
                                            >
                                                {ele?.linkText2}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <Label htmlFor="name">
                            Country/Region
                            <span className="red-color ps-1"> *</span>
                        </Label>
                        <div className="text-muted fs-12 mb-1">
                            Apps in the VPP will be displayed based on the selection of the Country/Religion
                        </div>
                        <Select
                            getOptionValue={(option) => option.disaplay_dial_code}
                            getOptionLabel={(option) => option.name}
                            id="country"
                            name="disaplay_dial_code"
                            classNamePrefix={'custom-select w-100'}
                            options={COUNTRIES}
                            placeholder={'Select Country'}
                            onChange={(selectedOption) => setCountry(selectedOption)}
                            value={country}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                        <div className="d-flex align-items-center">
                            <Input
                                className="me-2 my-2"
                                type="checkbox"
                                checked={autoUpdate}
                                onChange={(e) => setAutoUpdate(e.target.checked)}
                            />
                            <div>Auto App Update</div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center margin-top-10 px-3 mb-3">
                            <Button className="bg-primary border-none px-8 fs-12 fw-semibold" onClick={handleCountrySave}>
                                Save
                            </Button>
                        </div>
                        <div>
                            {/* <div className={`${serverName === '' ? 'mask pe-none' : ''}`}> */}
                            {/* <div className="pt-2 fw-semibold fs-13">Upload VPP Token</div>
                            <div className="py-2 fw-medium fs-12 text-muted">
                                The VPP token must be uploaded below to compare the VPP setup process. This token expires in one year and
                                must be renewed.
                            </div> */}
                            <div className="filepondmarginremoval w-95">
                                <FilePond
                                    name="files"
                                    minFileSize="1KB"
                                    maxFileSize="300MB"
                                    maxFiles={1}
                                    allowMultiple={true}
                                    files={apkFiles}
                                    className="filepond filepond-input-multiple"
                                    onupdatefiles={(fileItems) => setAPKFiles(fileItems)}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center margin-top-10 px-3">
                            <Button
                                className="bg-light border-primary text-primary mx-3 px-5 fs-12 fw-semibold"
                                onClick={() => {
                                    setShowInitialScreen(true);
                                    setShowSteps(false);
                                    setCountry('');
                                    setAutoUpdate(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button className="bg-primary border-none px-8 fs-12 fw-semibold" onClick={handleFileSave}>
                                Save
                            </Button>
                        </div>
                    </div>
                </CardBody>
            )}
        </Card>
    );
};

export default VolumePurchaseProgram;
