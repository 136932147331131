import React from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import BYODImage from '../../../assets/images/common/png/common/enroll-byod.png';

const BYOD = ({ setshowCards }) => {
    const onEnrollByod = () => {
        setshowCards(false);
    };

    return (
        <Card className="card-height-100">
            <CardHeader>
                <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                    <div className="fs-14 ">Bring Your Own Device(BYOD)</div>
                </div>
                <div className="padding-top-10 font-size-12 fw-medium text-muted">
                    Bring Your Own Device (BYOD) in Mobile Device Management (MDM) refers to the practice of managing personal devices used
                    by employees for work purposes within an organization’s MDM environment.
                </div>
            </CardHeader>
            <CardBody>
                <div className=" text-center py-20">
                    <div>
                        <img height={65} src={BYODImage} alt="img"></img>
                    </div>
                    <div className="fw-medium fs-14 py-2">Bring Your Own Device</div>
                    <div className="font-size-12 text-start fw-medium text-muted px-2">
                        Enrolling devices in BYOD MDM empowers organizations to have a balance between employee flexibility and corporate
                        security, enabling employees to use their preferred devices.
                    </div>
                    <div className="py-4">
                        <Button className="bg-primary px-15 py-3 border-none fw-medium fs-12" onClick={() => onEnrollByod()}>
                            Enroll Device
                        </Button>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default BYOD;
