import React from 'react';
import { Redirect } from 'react-router-dom';

// Dashboard

// login
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword';
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';

import Error401 from '../Components/Common/Errors/Error401';
import Error404 from '../Components/Common/Errors/Error404';
import Error500 from '../Components/Common/Errors/Error500';
import Maintenance from '../Components/Common/Errors/Maintenance';
import NoAccess from '../Components/Common/Errors/NoAccess';
import Offlinepage from '../Components/Common/Errors/Offlinepage';
import Fallback from '../Components/Common/Fallback';
import { priviliges } from '../Components/constants/constants';
import AddApplication from '../pages/AndroidEnterprise/Applications/AddApplications';
import AndroidApplications from '../pages/AndroidEnterprise/Applications/Applications';
import ManagedConfigurations from '../pages/AndroidEnterprise/Applications/ManagedConfigurations';
import AEViewBulkUploads from '../pages/AndroidEnterprise/BulkRequests/AEViewBulkUploads';
import AndroidBulkRequests from '../pages/AndroidEnterprise/BulkRequests/AndroidBulkRequests';
import AndroidDashboard from '../pages/AndroidEnterprise/Dashboard/AndroidDashboard';
import AndroidEnterpriseDevices from '../pages/AndroidEnterprise/Devices/AndroidEnterpriseDevices';
import AndroidEnterpriseViewDevices from '../pages/AndroidEnterprise/Devices/AndroidEnterpriseViewDevices';
import ViewAndroidMap from '../pages/AndroidEnterprise/Devices/ViewAndroidMap';
import AddLauncherSetup from '../pages/AndroidEnterprise/LauncherSetup/AddLauncherSetup';
import LauncherSetup from '../pages/AndroidEnterprise/LauncherSetup/LauncherSetup';
import AddPolicy from '../pages/AndroidEnterprise/Policies/AddPolicy';
import AuditLog from '../pages/AndroidEnterprise/Policies/AuditLog';
import EnrollmentToken from '../pages/AndroidEnterprise/Policies/ErollmentToken';
import Policy from '../pages/AndroidEnterprise/Policies/Policy';
import AEReports from '../pages/AndroidEnterprise/Reports/Reports';
import AddAndroidTVPolicies from '../pages/AndroidTV/AndroidTVPolicies/AddAndroidTVPolicies';
import AndroidTVPolicies from '../pages/AndroidTV/AndroidTVPolicies/AndroidTVPolicies';
import TvEnrollmentToken from '../pages/AndroidTV/AndroidTVPolicies/TvEnrollmentToken';
import AddAndroidTvApp from '../pages/AndroidTV/Applications/AddAndroidTvApp';
import Applications from '../pages/AndroidTV/Applications/Applications';
import TVBulkRequests from '../pages/AndroidTV/BulkRequests/ATVBulkUploads';
import ATVViewBulkUploads from '../pages/AndroidTV/BulkRequests/ATVViewBulkUploads';
import AndroidTvDashboard from '../pages/AndroidTV/Dashboard/AndroidTvDashboard';
import AndroidTVDevices from '../pages/AndroidTV/Devices/AndroidTVDevices';
import AndroidTVViewDevices from '../pages/AndroidTV/Devices/AndroidTVViewDevices';
import TvMapView from '../pages/AndroidTV/Devices/TvMapView';
import AddTvLauncherSetup from '../pages/AndroidTV/LauncherSetup/AddTvLauncherSetup';
import TvLauncherSetup from '../pages/AndroidTV/LauncherSetup/TvLauncherSetup';
import TVReports from '../pages/AndroidTV/Reports/TVReports';
import IOSApps from '../pages/AppleIOS/Apps/IOSApps';
import IOSDashboard from '../pages/AppleIOS/Dashboard/IOSDashboard';
import IOSDevices from '../pages/AppleIOS/Devices/IOSDevices';
import ViewIOSDevices from '../pages/AppleIOS/Devices/ViewIOSDevices';
import AddIOSPolicy from '../pages/AppleIOS/Policies/AddIOSPolicy';
import IosEnrollmentToken from '../pages/AppleIOS/Policies/IosEnrollmentToken';
import IOSPolicies from '../pages/AppleIOS/Policies/IOSPolicies';
import EnrollmentStepsEdit from '../pages/AppleIOS/Setup/EnrollmentStepsEdit';
import InitialSetup from '../pages/AppleIOS/Setup/Setup';
import ZeroTouch from '../pages/ApplicationManagement/ZeroTouch/ZeroTouch';
import AccountDetails from '../pages/Authentication/AccountDetails';
import AuthCallback from '../pages/Authentication/AuthCallback';
import SuccessMsg from '../pages/Authentication/SuccessMsg';
import TwosVerify from '../pages/Authentication/TwosVerify';
import AddAPP from '../pages/CommonFeatures/AppProtectionPolicy/AddAPP';
import AddConditionalAccess from '../pages/CommonFeatures/AppProtectionPolicy/AddConditionalAccess';
import AddTectoroAPP from '../pages/CommonFeatures/AppProtectionPolicy/AddTectoroAPP';
import AppProtection from '../pages/CommonFeatures/AppProtectionPolicy/AppProtection';
import AppProtectionPolicy from '../pages/CommonFeatures/AppProtectionPolicy/AppProtectionPolicy';
import EditApp from '../pages/CommonFeatures/AppProtectionPolicy/EditApp';
import AddCallConfiguration from '../pages/CommonFeatures/CallConfiguration/AddCallConfiguration';
import CallConfigurations from '../pages/CommonFeatures/CallConfiguration/CallConfigurations';
import CertificateManagement from '../pages/CommonFeatures/CertificateManagement/CertificateManagement';
import AddContact from '../pages/CommonFeatures/ContactList/AddContact';
import ContactList from '../pages/CommonFeatures/ContactList/ContactList';
import AddFirewall from '../pages/CommonFeatures/Firewall/AddFirewall';
import Firewall from '../pages/CommonFeatures/Firewall/Firewall';
import AddGeoFence from '../pages/CommonFeatures/GeoFence/AddGeoFence';
import GeoFence from '../pages/CommonFeatures/GeoFence/GeoFence';
import License from '../pages/CommonFeatures/License/License';
import LicenseView from '../pages/CommonFeatures/License/LicenseView';
import AddODMFeatures from '../pages/CommonFeatures/ODMFeatures/AddODMFeatures';
import ODMFeatures from '../pages/CommonFeatures/ODMFeatures/ODMFeatures';
import AddSimSettings from '../pages/CommonFeatures/SimSettings/AddSimSettings';
import SimSettings from '../pages/CommonFeatures/SimSettings/SimSettings';
import AddTimeFence from '../pages/CommonFeatures/TimeFence/AddTimeFence';
import TimeFence from '../pages/CommonFeatures/TimeFence/TimeFence';
import AddWifiFence from '../pages/CommonFeatures/WifiFence/AddWifiFence';
import WifiFence from '../pages/CommonFeatures/WifiFence/WifiFence';
import MyDeviceDetails from '../pages/Devices/MyDeviceDetails';
import MyDevices from '../pages/Devices/MyDevices';
import AddEnrollmentSettings from '../pages/EnrollmentSettings/AddEnrollmentSettings';
import EnrollmentSettings from '../pages/EnrollmentSettings/EnrollmentSettings';
import Enterprises from '../pages/Enterprises/Enterprises';
import Tenant from '../pages/Enterprises/Tenant';
import AddBrowserSettings from '../pages/Functions/Browser/AddBrowserSettings';
import Browser from '../pages/Functions/Browser/Browser';
import Files from '../pages/Functions/Files/Files';
import Messages from '../pages/Functions/Messages/Messages';
import GetStarted from '../pages/GetStarted/GetStarted';
import Assets from '../pages/SystemAdminstration/Assets/Assets';
import AuthModes from '../pages/SystemAdminstration/AuthenticationModes/AuthModes';
import ConfigureAuthModes from '../pages/SystemAdminstration/AuthenticationModes/ConfigureAuthModes';
import DeviceGroupsTable from '../pages/SystemAdminstration/DeviceGroups/DeviceGroupsTable';
import DeviceGroupsTree from '../pages/SystemAdminstration/DeviceGroups/DeviceGroupsTree';
import LoginHistory from '../pages/SystemAdminstration/Reports/LoginHistory';
import AdminReports from '../pages/SystemAdminstration/Reports/Reports';
import Roles from '../pages/SystemAdminstration/Roles/Roles';
import CreateTenantConfig from '../pages/SystemAdminstration/TenantConfig/CreateTenantConfig';
import Users from '../pages/SystemAdminstration/Users/Users';
import WindowsApps from '../pages/Windows/Apps/WindowsApps';
import WindowsDashboard from '../pages/Windows/Dashboard/WindowsDashboard';
import ViewWindowsDevices from '../pages/Windows/Devices/ViewWindowsDevices';
import WindowsDevices from '../pages/Windows/Devices/WindowsDevices';
import WindowsEnrollment from '../pages/Windows/Enrollment/WindowsEnrollment';
import AddWindowsPolicy from '../pages/Windows/Policies/AddWindowsPolicy';
import EnrollmentConfig from '../pages/Windows/Policies/EnrollmentConfig';
import WindowsPolicies from '../pages/Windows/Policies/WindowsPolicies';
import AddWindowsScript from '../pages/Windows/Scripts/AddWindowsScript';
import WindowsScripts from '../pages/Windows/Scripts/WindowsScripts';
import WindowsSetup from '../pages/Windows/Setup/WindowsSetup';

const authProtectedRoutes = [
    // { path: '/dashboard', component: Dashboard },

    // get started
    { path: '/getstarted', component: GetStarted },

    { path: '/mytenant', component: Tenant, reader: priviliges.TENANT_READER, editor: priviliges.TENANT_EDITOR },
    { path: '/mytenant/add', component: CreateTenantConfig, reader: priviliges.TENANT_READER, editor: priviliges.TENANT_EDITOR },
    { path: '/mytenant/view/:id', component: CreateTenantConfig, reader: priviliges.TENANT_READER, editor: priviliges.TENANT_EDITOR },
    { path: '/mytenant/edit/:id', component: CreateTenantConfig, reader: priviliges.TENANT_READER, editor: priviliges.TENANT_EDITOR },

    { path: '/aedevices', component: AndroidEnterpriseDevices, reader: priviliges.DEVICE_READER, editor: priviliges.DEVICE_EDITOR },
    {
        path: '/aedevices/view/:id',
        component: AndroidEnterpriseViewDevices,
        reader: priviliges.DEVICE_READER,
        editor: priviliges.DEVICE_EDITOR
    },
    { path: '/aedevices/viewmap/:id', component: ViewAndroidMap, reader: priviliges.DEVICE_READER, editor: priviliges.DEVICE_EDITOR },

    { path: '/aeconsole', component: AndroidDashboard },

    { path: '/aeenterprise', component: Enterprises, reader: priviliges.ENTERPRISE_READER, editor: priviliges.ENTERPRISE_EDITOR },
    { path: '/aepolicies', component: Policy, reader: priviliges.POLICY_READER, editor: priviliges.POLICY_EDITOR },
    { path: '/aepolicies/add/:type', component: AddPolicy, reader: priviliges.POLICY_READER, editor: priviliges.POLICY_EDITOR },
    { path: '/aepolicies/edit/:id/:type', component: AddPolicy, reader: priviliges.POLICY_READER, editor: priviliges.POLICY_EDITOR },
    { path: '/aepolicies/takeAction/:id/:type', component: AddPolicy, reader: priviliges.POLICY_READER, editor: priviliges.POLICY_EDITOR },
    { path: '/aepolicies/view/:id/:type', component: AddPolicy, reader: priviliges.POLICY_READER, editor: priviliges.POLICY_EDITOR },
    { path: '/aepolicies/checker/:id/:type', component: AddPolicy, reader: priviliges.POLICY_READER, editor: priviliges.POLICY_EDITOR },
    { path: '/aepolicies/takeAction/:id/:type', component: AddPolicy, reader: priviliges.POLICY_READER, editor: priviliges.POLICY_EDITOR },
    { path: '/aepolicies/auditlog/:id', component: AuditLog, reader: priviliges.POLICY_READER, editor: priviliges.POLICY_EDITOR },

    {
        path: '/aepolicies/enrollmenttoken/:id/:type',
        component: EnrollmentToken,
        reader: priviliges.POLICY_READER,
        editor: priviliges.POLICY_EDITOR
    },

    { path: '/aeapps', component: AndroidApplications, reader: priviliges.APPLICATION_READER, editor: priviliges.APPLICATION_EDITOR },
    { path: '/aeapps/add', component: AddApplication, reader: priviliges.APPLICATION_READER, editor: priviliges.APPLICATION_EDITOR },
    {
        path: '/aeapps/:packagename',
        component: ManagedConfigurations,
        reader: priviliges.APPLICATION_READER,
        editor: priviliges.APPLICATION_EDITOR
    },

    { path: '/aelaunchersetup', component: LauncherSetup, reader: priviliges.APPLICATION_READER, editor: priviliges.APPLICATION_EDITOR },
    {
        path: '/aelaunchersetup/add',
        component: AddLauncherSetup,
        reader: priviliges.APPLICATION_READER,
        editor: priviliges.APPLICATION_EDITOR
    },
    {
        path: '/aelaunchersetup/:type/:id',
        component: AddLauncherSetup,
        reader: priviliges.APPLICATION_READER,
        editor: priviliges.APPLICATION_EDITOR
    },

    { path: '/aebulkrequests', component: AndroidBulkRequests, reader: priviliges.DEVICE_READER, editor: priviliges.DEVICE_EDITOR },
    { path: '/aebulkrequests/view/:id', component: AEViewBulkUploads, reader: priviliges.DEVICE_READER, editor: priviliges.DEVICE_EDITOR },
    { path: '/atvbulkrequests', component: TVBulkRequests, reader: priviliges.TVDEVICE_READER, editor: priviliges.TVDEVICE_EDITOR },
    {
        path: '/atvbulkrequests/view/:id',
        component: ATVViewBulkUploads,
        reader: priviliges.TVDEVICE_READER,
        editor: priviliges.TVDEVICE_EDITOR
    },

    { path: '/atvdevices', component: AndroidTVDevices, reader: priviliges.TVDEVICE_READER, editor: priviliges.TVDEVICE_EDITOR },
    {
        path: '/atvdevices/view/:id',
        component: AndroidTVViewDevices,
        reader: priviliges.TVDEVICE_READER,
        editor: priviliges.TVDEVICE_EDITOR
    },
    { path: '/atvdevices/viewmap/:id', component: TvMapView, reader: priviliges.TVDEVICE_READER, editor: priviliges.TVDEVICE_EDITOR },

    { path: '/atvconsole', component: AndroidTvDashboard, reader: priviliges.TVDEVICE_READER },

    { path: '/atvpolicies', component: AndroidTVPolicies, reader: priviliges.TVDEVICE_READER, editor: priviliges.TVDEVICE_EDITOR },
    {
        path: '/atvpolicies/add/:type',
        component: AddAndroidTVPolicies,
        reader: priviliges.TVDEVICE_READER,
        editor: priviliges.TVDEVICE_EDITOR
    },
    {
        path: '/atvpolicies/edit/:id/:type',
        component: AddAndroidTVPolicies,
        reader: priviliges.TVDEVICE_READER,
        editor: priviliges.TVDEVICE_EDITOR
    },
    {
        path: '/atvpolicies/view/:id/:type',
        component: AddAndroidTVPolicies,
        reader: priviliges.TVDEVICE_READER,
        editor: priviliges.TVDEVICE_EDITOR
    },
    {
        path: '/atvpolicies/enrollmenttoken/:id',
        component: TvEnrollmentToken,
        reader: priviliges.TVDEVICE_READER,
        editor: priviliges.TVDEVICE_EDITOR
    },

    { path: '/authmodes', component: AuthModes },
    { path: '/authmodes/:type/configure', component: ConfigureAuthModes },
    { path: '/authmodes/:type/view/:id', component: ConfigureAuthModes },
    { path: '/authmodes/:type/edit/:id', component: ConfigureAuthModes },

    { path: '/mydevices', component: MyDevices, reader: priviliges.MYDEVICES, editor: priviliges.MYDEVICES },
    { path: '/mydevices/view/:type/:id', component: MyDeviceDetails, reader: priviliges.MYDEVICES, editor: priviliges.MYDEVICES },

    { path: '/atvapps', component: Applications, reader: priviliges.TVAPPLICATION_READER, editor: priviliges.TVAPPLICATION_EDITOR },
    { path: '/atvapps/add', component: AddAndroidTvApp, reader: priviliges.TVAPPLICATION_READER, editor: priviliges.TVAPPLICATION_EDITOR },
    {
        path: '/atvapps/:type/:id',
        component: AddAndroidTvApp,
        reader: priviliges.TVAPPLICATION_READER,
        editor: priviliges.TVAPPLICATION_EDITOR
    },

    {
        path: '/atvlaunchersetup',
        component: TvLauncherSetup,
        reader: priviliges.TVAPPLICATION_READER,
        editor: priviliges.TVAPPLICATION_EDITOR
    },
    {
        path: '/atvlaunchersetup/add',
        component: AddTvLauncherSetup,
        reader: priviliges.TVAPPLICATION_READER,
        editor: priviliges.TVAPPLICATION_EDITOR
    },
    {
        path: '/atvlaunchersetup/edit/:id',
        component: AddTvLauncherSetup,
        reader: priviliges.TVAPPLICATION_READER,
        editor: priviliges.TVAPPLICATION_EDITOR
    },
    {
        path: '/atvlaunchersetup/view/:id',
        component: AddTvLauncherSetup,
        reader: priviliges.TVAPPLICATION_READER,
        editor: priviliges.TVAPPLICATION_EDITOR
    },

    { path: '/assets', component: Assets, reader: priviliges.ASSET_READER, editor: priviliges.ASSET_EDITOR },
    { path: '/zerotouch', component: ZeroTouch, reader: priviliges.ZEROTOUCH_READER },
    { path: '/users', component: Users, reader: priviliges.USER_READER, editor: priviliges.USER_EDITOR },
    { path: '/users/auditlog/:id', component: AuditLog, reader: priviliges.USER_READER, editor: priviliges.USER_EDITOR },
    { path: '/roles', component: Roles, reader: priviliges.ROLE_READER, editor: priviliges.ROLE_EDITOR },
    { path: '/devicegroups', component: DeviceGroupsTable, reader: priviliges.GROUP_READER, editor: priviliges.GROUP_EDITOR },
    { path: '/devicegroups/treeView/:code', component: DeviceGroupsTree, reader: priviliges.GROUP_READER, editor: priviliges.GROUP_EDITOR },
    { path: '/devicegroups/auditlog/:id', component: AuditLog, reader: priviliges.GROUP_READER, editor: priviliges.GROUP_EDITOR },

    // Reports
    { path: '/aereports', component: AEReports, reader: priviliges.DEVICE_READER, editor: priviliges.DEVICE_EDITOR },
    { path: '/atvreports', component: TVReports, reader: priviliges.TVDEVICE_READER, editor: priviliges.TVDEVICE_EDITOR },

    // License
    { path: '/license', component: License },
    { path: '/license/view/:id', component: LicenseView },

    // Enrollment Settings
    { path: '/enrollmentsettings', component: EnrollmentSettings, reader: priviliges.TVDEVICE_READER },
    { path: '/enrollmentsettings/:mode', component: AddEnrollmentSettings, reader: priviliges.TVDEVICE_READER },

    // Common Features
    { path: '/browser', component: Browser, reader: priviliges.APPLICATION_READER, editor: priviliges.APPLICATION_EDITOR },
    { path: '/browser/add', component: AddBrowserSettings, reader: priviliges.APPLICATION_READER, editor: priviliges.APPLICATION_EDITOR },
    {
        path: '/browser/edit/:id',
        component: AddBrowserSettings,
        reader: priviliges.APPLICATION_READER,
        editor: priviliges.APPLICATION_EDITOR
    },
    {
        path: '/browser/view/:id',
        component: AddBrowserSettings,
        reader: priviliges.APPLICATION_READER,
        editor: priviliges.APPLICATION_EDITOR
    },
    { path: '/files', component: Files, reader: priviliges.FILE_READER, editor: priviliges.FILE_EDITOR },

    {
        path: '/appprotectionpolicy',
        component: AppProtectionPolicy,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/appprotectionpolicy/:ostype',
        component: AppProtection,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/appprotectionpolicy/microsoft/:platform/add',
        component: AddAPP,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/appprotectionpolicy/tectoro/:platform/add',
        component: AddTectoroAPP,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/appprotectionpolicy/tectoro/:platform/edit/:id',
        component: AddTectoroAPP,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/appprotectionpolicy/microsoft/:platform/edit/:id',
        component: EditApp,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/appprotectionpolicy/conditionalaccess/:domain/add',
        component: AddConditionalAccess,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/appprotectionpolicy/conditionalaccess/:domain/edit/:id',
        component: AddConditionalAccess,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },

    { path: '/firewall', component: Firewall, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    { path: '/firewall/add', component: AddFirewall, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    {
        path: '/firewall/:type/:id',
        component: AddFirewall,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    { path: '/geofence', component: GeoFence, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    { path: '/geofence/add', component: AddGeoFence, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    {
        path: '/geofence/:type/:id',
        component: AddGeoFence,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    { path: '/timefence', component: TimeFence, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    { path: '/timefence/add', component: AddTimeFence, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    {
        path: '/timefence/:type/:id',
        component: AddTimeFence,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },

    { path: '/wififence', component: WifiFence, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    { path: '/wififence/add', component: AddWifiFence, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    {
        path: '/geofence/:type/:id',
        component: AddWifiFence,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },

    { path: '/timefence', component: TimeFence, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    { path: '/timefence/add', component: AddTimeFence, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    { path: '/wififence', component: WifiFence, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    { path: '/wififence/add', component: AddWifiFence, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    { path: '/simsetting', component: SimSettings, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    {
        path: '/simsetting/add',
        component: AddSimSettings,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/simsetting/:type/:id',
        component: AddSimSettings,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    { path: '/messages', component: Messages, reader: priviliges.MESSAGE_READER, editor: priviliges.MESSAGE_EDITOR },
    {
        path: '/certificatemanagement',
        component: CertificateManagement,
        reader: priviliges.CERTIFICATE_READER,
        editor: priviliges.CERTIFICATE_EDITOR
    },
    { path: '/contactlist', component: ContactList, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    { path: '/contactlist/add', component: AddContact, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    {
        path: '/contactlist/edit/:id',
        component: AddContact,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/contactlist/view/:id',
        component: AddContact,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/callconfig',
        component: CallConfigurations,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/callconfig/add',
        component: AddCallConfiguration,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/callconfig/edit/:id',
        component: AddCallConfiguration,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/callconfig/view/:id',
        component: AddCallConfiguration,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    { path: '/odmfeatures', component: ODMFeatures, reader: priviliges.CONFIGURATION_READER, editor: priviliges.CONFIGURATION_EDITOR },
    {
        path: '/odmfeatures/:platform/add',
        component: AddODMFeatures,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/odmfeatures/:platform/edit/:id',
        component: AddODMFeatures,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },
    {
        path: '/odmfeatures/:platform/view/:id',
        component: AddODMFeatures,
        reader: priviliges.CONFIGURATION_READER,
        editor: priviliges.CONFIGURATION_EDITOR
    },

    // Apple iOS
    { path: '/iosdashboard', component: IOSDashboard, reader: priviliges.IOSDEVICE_READER, editor: priviliges.IOSDEVICE_EDITOR },
    { path: '/iosdevices', component: IOSDevices, reader: priviliges.IOSDEVICE_READER, editor: priviliges.IOSDEVICE_EDITOR },
    { path: '/iosdevices/view/:id', component: ViewIOSDevices, reader: priviliges.IOSDEVICE_READER, editor: priviliges.IOSDEVICE_EDITOR },
    { path: '/iospolicies', component: IOSPolicies, reader: priviliges.IOSPOLICY_READER, editor: priviliges.IOSPOLICY_EDITOR },
    { path: '/iospolicies/add/:type', component: AddIOSPolicy, reader: priviliges.IOSPOLICY_READER, editor: priviliges.IOSPOLICY_EDITOR },
    { path: '/iossetup', component: InitialSetup, reader: priviliges.IOSDEVICE_READER, editor: priviliges.IOSDEVICE_EDITOR },
    {
        path: '/iossetup/edit',
        component: EnrollmentStepsEdit,
        reader: priviliges.IOSDEVICE_READER,
        editor: priviliges.IOSDEVICE_EDITOR
    },
    {
        path: '/iossetup/view',
        component: EnrollmentStepsEdit,
        reader: priviliges.IOSDEVICE_READER,
        editor: priviliges.IOSDEVICE_EDITOR
    },
    { path: '/iosapps', component: IOSApps, reader: priviliges.IOSDEVICE_READER, editor: priviliges.IOSDEVICE_EDITOR },
    {
        path: '/iospolicies/edit/:id/:type',
        component: AddIOSPolicy,
        reader: priviliges.IOSPOLICY_READER,
        editor: priviliges.IOSPOLICY_EDITOR
    },
    {
        path: '/iospolicies/view/:id/:type',
        component: AddIOSPolicy,
        reader: priviliges.IOSPOLICY_READER,
        editor: priviliges.IOSPOLICY_EDITOR
    },
    {
        path: '/iospolicies/enrollmenttoken/:id',
        component: IosEnrollmentToken,
        reader: priviliges.IOSPOLICY_READER,
        editor: priviliges.IOSPOLICY_EDITOR
    },

    // Windows
    {
        path: '/windowsdashboard',
        component: WindowsDashboard,
        reader: priviliges.WINDOWSDEVICE_READER,
        editor: priviliges.WINDOWSDEVICE_EDITOR
    },
    { path: '/windowssetup', component: WindowsSetup, reader: priviliges.WINDOWSPOLICY_READER, editor: priviliges.WINDOWSPOLICY_EDITOR },
    {
        path: '/windowsenrollment',
        component: WindowsEnrollment,
        reader: priviliges.WINDOWSPOLICY_READER,
        editor: priviliges.WINDOWSPOLICY_EDITOR
    },
    {
        path: '/windowspolicies',
        component: WindowsPolicies,
        reader: priviliges.WINDOWSPOLICY_READER,
        editor: priviliges.WINDOWSPOLICY_EDITOR
    },
    {
        path: '/windowspolicies/add/:type',
        component: AddWindowsPolicy,
        reader: priviliges.WINDOWSPOLICY_READER,
        editor: priviliges.WINDOWSPOLICY_EDITOR
    },
    {
        path: '/windowspolicies/edit/:id/:type',
        component: AddWindowsPolicy,
        reader: priviliges.WINDOWSPOLICY_READER,
        editor: priviliges.WINDOWSPOLICY_EDITOR
    },
    {
        path: '/windowspolicies/view/:id/:type',
        component: AddWindowsPolicy,
        reader: priviliges.WINDOWSPOLICY_READER,
        editor: priviliges.WINDOWSPOLICY_EDITOR
    },
    {
        path: '/windowspolicies/enrollmentconfig/:code',
        component: EnrollmentConfig,
        reader: priviliges.WINDOWSPOLICY_READER,
        editor: priviliges.WINDOWSPOLICY_EDITOR
    },
    {
        path: '/windowsdevices',
        component: WindowsDevices,
        reader: priviliges.WINDOWSDEVICE_READER,
        editor: priviliges.WINDOWSDEVICE_EDITOR
    },
    {
        path: '/windowsdevices/view/:id',
        component: ViewWindowsDevices,
        reader: priviliges.WINDOWSDEVICE_READER,
        editor: priviliges.WINDOWSDEVICE_EDITOR
    },
    { path: '/windowsapps', component: WindowsApps, reader: priviliges.WINDOWSDEVICE_READER, editor: priviliges.WINDOWSDEVICE_EDITOR },
    {
        path: '/windowsscripts',
        component: WindowsScripts,
        reader: priviliges.WINDOWSDEVICE_READER,
        editor: priviliges.WINDOWSDEVICE_EDITOR
    },
    {
        path: '/windowsscripts/add/:type',
        component: AddWindowsScript,
        reader: priviliges.WINDOWSDEVICE_READER,
        editor: priviliges.WINDOWSDEVICE_EDITOR
    },

    { path: '/adminreports', component: AdminReports, reader: priviliges.USER_READER },
    { path: '/adminreports/loginhistory', component: LoginHistory, reader: priviliges.USER_READER },

    //   This route should be at the end of all other routes
    { path: '/', exact: true, component: () => <Redirect to="/aeenterprise" /> }
];

const publicRoutes = [
    // Authentication Page
    { path: '/account', component: AccountDetails },
    { path: '/logout', component: Logout },
    { path: '/login', component: Login },
    { path: '/forgot-password', component: ForgetPasswordPage },
    { path: '/login-otp-verification', component: TwosVerify },
    { path: '/forgot-otp-verification', component: TwosVerify },
    { path: '/successmsg', component: SuccessMsg },
    { path: '/register', component: Register },
    { path: '/auth-404', component: Error404 },
    { path: '/auth-401', component: Error401 },
    { path: '/auth-500', component: Error500 },
    { path: '/auth-noaccess', component: NoAccess },
    { path: '/auth-offline', component: Offlinepage },
    { path: '/maintenance', component: Maintenance },
    { path: '/fallback', component: Fallback },
    { path: '/callback', component: AuthCallback }
];

export { authProtectedRoutes, publicRoutes };
