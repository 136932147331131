import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Row } from 'reactstrap';
import AzureImage from '../../../assets/images/common/png/common/link-azure.png';
import { LinkADComponents, LinkADSchema } from './LinkADConstants';
import { getDate } from '../../../Components/Common/Util';
import { useFormik } from 'formik';
import { APIClient } from '../../../helpers/api_helper';
import { useEnv } from '../../../envContext';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';

const LinkAzureDirectory = ({ setLoading }) => {
    const api = new APIClient();
    const urlconf = useEnv();
    const [showInitialScreen, setShowInitialScreen] = useState(true);
    const [formType, setFormType] = useState('view');
    const [azureObj, setAzureObj] = useState('');

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: LinkADSchema.reduce((defaultValues, obj) => {
            defaultValues[obj.value] = '';
            return defaultValues;
        }, {})
    });

    useEffect(() => {
        getLinkADData();
    }, []);

    const getLinkADData = () => {
        setLoading(true);
        api.get(url.LINK_AZURE, {}, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data && resp.data?.length > 0) {
                    setAzureObj(resp.data[0]);
                    setShowInitialScreen(false);
                }
            })
            .finally(() => setLoading(false));
    };

    const onConfigureAzure = () => {
        setShowInitialScreen(false);
        setFormType('add');
    };

    const onCancelClick = () => {
        setFormType(formType === 'add' ? 'view' : 'view');
        setShowInitialScreen(formType === 'add');
    };

    const handleSubmit = () => {
        setLoading(true);
        const endpoint = formType === 'add' ? url.LINK_AZURE : `${url.LINK_AZURE}/${azureObj?._id}`;
        const method = formType === 'add' ? 'create' : 'patch';

        api[method](endpoint, validation.values, false, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.ADUpdated : toastMessages.ADCreated);
                    setFormType('view');
                    getLinkADData();
                }
            })
            .finally(() => setLoading(false));
    };

    const onEditClick = () => {
        setFormType('edit');
        validation.setValues(
            LinkADSchema.reduce((defaultValues, obj) => {
                defaultValues[obj.value] = azureObj[obj.value];
                return defaultValues;
            }, {})
        );
    };

    const onDeleteClick = () => {
        setLoading(true);
        api.delete(url.LINK_AZURE + '/' + azureObj?._id, '', domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.ADDeleted);
                    getLinkADData();
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <Card className="card-height-100">
            <CardHeader>
                <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                    <div className="fs-14 ">Link with Microsoft Entra</div>
                    <div
                        className={`d-flex justify-content-center align-items-center fs-12 ${
                            azureObj === '' ? 'text-danger' : 'text-primary'
                        }`}
                    >
                        {azureObj === '' ? (
                            <i className="ri-error-warning-fill fs-16 px-1 text-danger"></i>
                        ) : (
                            <i className="ri-checkbox-circle-fill fs-16 px-1 text-primary"></i>
                        )}
                        {azureObj === '' ? 'Not Configured' : 'Configured'}
                    </div>
                </div>
                <div className="padding-top-10 font-size-12 fw-medium text-muted">
                    Bring Your Own Device (BYOD) in Mobile Device Management (MDM) refers to the practice of managing personal devices used
                    by employees for work purposes within an organization’s MDM environment.
                </div>
            </CardHeader>
            <CardBody>
                {showInitialScreen ? (
                    <div className=" text-center py-20">
                        <img height={65} src={AzureImage} alt="img"></img>
                        <div className="fw-medium fs-14 py-2">Link with Microsoft Entra</div>
                        <div className="font-size-12 text-start fw-medium text-muted px-2">
                            Enrolling devices in BYOD MDM empowers organizations to have a balance between employee flexibility and
                            corporate security, enabling employees to use their preferred devices.
                        </div>
                        <div className="py-4">
                            <Button className="bg-primary px-15 py-3 border-none fw-medium fs-12" onClick={() => onConfigureAzure()}>
                                Configure
                            </Button>
                        </div>
                    </div>
                ) : formType === 'add' || formType === 'edit' ? (
                    <>
                        <div className="text-center py-2">
                            <img height={65} src={AzureImage} alt="img"></img>
                            <div className="fw-medium fs-14 py-2">Link with Microsoft Entra</div>
                        </div>
                        <Row className="m-0 px-0 pt-3">
                            {LinkADSchema.map((field, index) => {
                                return (
                                    <LinkADComponents key={index} field={field} index={index} formType={formType} validation={validation} />
                                );
                            })}
                        </Row>
                        <div className="d-flex justify-content-end align-items-center margin-top-10 px-3">
                            <Button
                                className="bg-light border-primary text-primary mx-3 fs-12 fw-semibold px-5"
                                onClick={() => onCancelClick()}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="bg-primary border-none px-8 fs-12 fw-semibold"
                                disabled={LinkADSchema.some((field) => field.mandatory && !validation.values[field.value])}
                                onClick={() => handleSubmit()}
                            >
                                {formType === 'add' ? 'Save' : 'Update'}
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="text-center py-2">
                            <img height={65} src={AzureImage} alt="img"></img>
                            <div className="fw-medium fs-14 py-2">Link with Microsoft Entra</div>
                        </div>
                        <Card className="border">
                            <CardHeader className="border-bottom-dashed">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className="fs-12 d-flex">
                                            <div className="text-muted">{'Last Modified - '}</div>
                                            <div className="fw-medium">{getDate(azureObj.modifieddate)}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <i
                                            className="ri-edit-box-line px-1 fs-16 text-secondary cursor-pointer"
                                            onClick={() => onEditClick()}
                                        ></i>
                                        <i
                                            className="ri-delete-bin-6-line px-1 fs-16 text-danger cursor-pointer"
                                            onClick={() => onDeleteClick()}
                                        ></i>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row className="m-0 px-0 pt-3">
                                    {[{ label: 'Modified By', value: 'modifiedby', inputType: 'input' }, ...LinkADSchema].map(
                                        (field, index) => {
                                            return (
                                                <LinkADComponents
                                                    key={index}
                                                    field={field}
                                                    index={index}
                                                    formType={formType}
                                                    validation={{ values: azureObj }}
                                                />
                                            );
                                        }
                                    )}
                                </Row>
                            </CardBody>
                        </Card>
                    </>
                )}
            </CardBody>
        </Card>
    );
};

export default LinkAzureDirectory;
