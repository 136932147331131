/* eslint-disable max-lines */
/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import {
    Enterprise,
    TenantConfig,
    Tooltip,
    diffTwoDatesHrsMintsSeconds,
    getFormTypeAndRecordId,
    profileTypeLabel
} from '../../../Components/Common/Util';
import { luncherTypes, securityOptions } from '../../../Components/constants/constants';
import AndroidIcon from '../../../assets/images/android/svg/android.svg';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { DynamicComponents } from './AddPolicyFunctions';
import { AddPolicyHTML } from './AddPolicyHTML';
import ApplicationsList from './ApplicationsList';
import { PoliciesFieldsSchema } from './PoliciesFieldsSchema';
import { checkValue, policyInitValues, prepareDataObj } from './PolicyUtil';

const AddPolicy = ({ code, policyType, policyData, mode }) => {
    const api = new APIClient();
    let history = useHistory();
    const tenant = TenantConfig();
    let accountId = localStorage.getItem('account');

    const formTypeAndId = getFormTypeAndRecordId(window.location.hash, true);
    let formType = mode ? mode : formTypeAndId['formType'];
    formType = formType === 'takeAction' ? 'view' : formType;

    document.title = formType === 'edit' ? 'Edit Policy' : formType === 'add' ? 'Add Policy' : 'View Policy';
    let recordID = code ? code : formTypeAndId['recordID'];
    let polType = policyType ? policyType : formTypeAndId['queryType'];
    let enterprise = Enterprise();
    let enterpriseObj = enterprise ? JSON.parse(enterprise) : {};

    const personalAppOptions = [
        { label: 'Available', value: 'AVAILABLE' },
        { label: 'Blocked', value: 'BLOCKED' },
        { label: 'Install Type Unspecified', value: 'INSTALL_TYPE_UNSPECIFIED' }
    ];

    const fp1 = useRef(null);
    const fp2 = useRef(null);
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [defaultData, setDefaultData] = useState();
    const [applicationData, setApplicationData] = useState([]);
    const [selectedAppData, setSelectedAppData] = useState({});
    const [applicationObj, setApplicationObj] = useState({});
    const [wifiConfiguration, setWifiConfiguration] = useState([]);
    const [addWifiConfig, setAddWifiConfig] = useState(false);
    const [wifiMode, setWifiMode] = useState('add');
    const [selectedWifiConf, setSelectedWifiConf] = useState();
    const [selectedTab, setSelectedTab] = useState('apps');
    const [showConfig, setShowConfig] = useState(false);
    const [activeInd, setActiveInd] = useState(-1);
    const [showAddAction, setShowAddAction] = useState(false);
    const [actionMode, setActionMode] = useState('add');
    const [selectedActionInd, setSelectedActionInd] = useState(-1);
    const [selectedSetupAction, setSelectedSetupAction] = useState('');
    const [showKioskAppConfig, setShowKioskAppConfig] = useState(false);
    const [details, setDetails] = useState({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selected, setSelected] = useState(PoliciesFieldsSchema[0]);
    const [isInvalidMail, setIsInvalidMail] = useState(false);
    const [profileDetails, setProfileDetails] = useState(JSON.parse(JSON.stringify(PoliciesFieldsSchema)));
    const [availableApplications, setAvailableApplications] = useState([]);
    const [setupExisting, setSetupExisting] = useState(false);
    const [showKioskSettings, setShowKioskSettings] = useState(false);
    const [showKioskConfig, setShowKioskConfig] = useState(false);
    const [selectedLauncherApp, setSelectedLauncherApp] = useState('');
    const [showAppError, setShowAppError] = useState(false);
    const [launcherAppsData, setLauncherAppsData] = useState([]);
    const [kioskApps, setKioskApps] = useState(luncherTypes);
    const [assets, setAssets] = useState({ wallpaper: [], bootanimation: [], shutdownlogo: [] });
    const [kioskDetails, setKioskDetails] = useState('');
    const [showKioskConfirmation, setShowKioskConfirmation] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [remarks, setRemarks] = useState('');
    const [configPackages, setConfigPackages] = useState({ allowed: [], default: [], blocked: [] });
    const [confPackageName, setConfPackageName] = useState({ allowed: '', blocked: '' });
    const [configApps, setConfigApps] = useState({ enable: [], disable: [] });
    const [confApp, setConfApp] = useState({ enable: '', disable: '' });
    const [launcherPackagename, setLauncherPackagename] = useState('');
    const [alertModal, setAlertModal] = useState(false);
    const [selectedToggleDetails, setSelectedToggleDetails] = useState('');
    const [showPackageConfig, setShowPackageConfig] = useState(false);
    const [packageModalHeader, setPackageModalHeader] = useState('');
    const [packageType, setPackageType] = useState('');
    const [searchPackage, setSearchPackage] = useState('');
    const [configPackagesBackup, setConfigPackagesBackup] = useState({ allowed: [], default: [], blocked: [] });
    const [protectedPolicy, setProtectedPolicy] = useState(false);
    const urlconf = useEnv();
    const [tectoroApplications, setTectoroApplications] = useState([]);
    const [configurations, setConfigurations] = useState({
        firewalls: [],
        wififences: [],
        geofences: [],
        simSettings: [],
        certificates: [],
        callconfigs: [],
        contacts: [],
        acerodmfeatures: []
    });

    useEffect(() => {
        if (
            !code &&
            !(
                tenant?.COMPANY_POLICY_ACCESS?.includes(polType?.toUpperCase()) ||
                tenant.EMM_DD_POLICY_TYPES?.includes(polType?.toUpperCase())
            )
        ) {
            history.push('/auth-noaccess');
        }
        profileDetails.forEach((policy) => {
            if (policy.fields?.length > 0)
                policy.fields = policy.fields.filter((field) => {
                    if (field.optionsBasedOnTenant) {
                        field.options = field.options.filter((option) => tenant?.[option.hideOnTenant]);
                    }
                    return (
                        (field.hideOnTenant ? tenant?.[field.hideOnTenant] : true) &&
                        field?.profileType?.includes(profileTypeLabel(polType)) &&
                        (field.optionsBasedOnTenant ? field.options?.length > 0 : true)
                    );
                });

            if (policy.appFields?.length > 0)
                policy.appFields = policy.appFields.filter((field) => {
                    return field?.profileType?.includes(profileTypeLabel(polType));
                });
        });
        let arr = profileDetails?.filter((ele) => ele.fields?.length > 0 || ele.customBody);
        arr.forEach((ele, index) => (ele.active = index === 0 ? true : false));
        setSelected({ ...arr?.[0] });
        setProfileDetails(JSON.parse(JSON.stringify([...arr])));

        setLoading(true);
        promiseHandler();
    }, []);

    const promiseHandler = () => {
        const configPromise = new Promise((resolve, reject) => {
            api.get(url.CONFIG, { page: 1, size: 100000, metrics: false })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Configs failed.');
                })
                .catch((err) => reject('Configs failed.'));
        });

        const assetPromise = new Promise((resolve, reject) => {
            api.get(url.ASSETS, { page: 1, size: 2000 })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Asset failed.');
                })
                .catch((err) => reject('Asset failed.'));
        });

        const managedConfigPromise = new Promise((resolve, reject) => {
            api.get(url.MANAGED_CONFIGURATIONS, { page: 1, size: 1000, metrics: false })
                .then(async (resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Managed config failed.');
                })
                .catch((err) => reject('Managed config failed.'));
        });

        const policyPromise = new Promise((resolve, reject) => {
            if (!code) {
                let apiCall;
                let type =
                    formTypeAndId['formType'] === 'takeAction' || formType === 'viewChecker'
                        ? 'takeAction'
                        : code
                        ? 'getPolicyByCode'
                        : recordID
                        ? 'getProfileByID'
                        : null;
                if (type === 'takeAction') apiCall = api.get(url.POLICY_WORKFLOW + '/' + recordID);
                else if (type === 'getPolicyByCode') apiCall = api.get(url.POLICIES + '/code/' + recordID);
                else if (type === 'getProfileByID') apiCall = api.get(url.POLICIES + '/' + recordID);
                else {
                    formTypeAndId['formType'] === 'takeAction'
                        ? (apiCall = api.get(url.POLICY_WORKFLOW + '/' + recordID))
                        : (apiCall = api.get(
                              url.DEFAULT_POLICY + '/' + (polType === 'SA' ? 'DD' : polType === 'KL' || polType === 'TL' ? 'FM' : polType)
                          ));
                }
                apiCall
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);
                        else reject('Policy failed.');
                    })
                    .catch((_err) => reject('Policy failed.'));
            } else resolve(policyData.data);
        });

        const applicationPromise = new Promise((resolve, reject) => {
            let params = { enterpriseId: enterpriseObj.id, page: 1, size: 1000, metrics: false };
            api.get(url.APPLICATIONS, params)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Application failed.');
                })
                .catch((err) => reject('Application failed..'));
        });

        const certificatePromise = new Promise((resolve, reject) => {
            if (tenant.CERTIFICATE_MANAGEMENT)
                api.get(url.CERTIFICATES, { page: 1, size: 5000 })
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);
                        else reject('Certificates failed.');
                    })
                    .catch((_err) => reject('Certificates failed.'));
            else reject('Certificate not enabled');
        });

        Promise.allSettled([configPromise, assetPromise, managedConfigPromise, policyPromise, applicationPromise, certificatePromise])
            .then(async (result) => {
                if (
                    result[0].status === 'rejected' ||
                    result[1].status === 'rejected' ||
                    result[2].status === 'rejected' ||
                    (result[3].status === 'rejected' && result[3].reason === 'Policy failed.') ||
                    (result[4].status === 'rejected' && result[4].reason === 'Application failed..') ||
                    (result[5].status === 'rejected' && result[5].reason !== 'Certificate not enabled')
                ) {
                    toast.error(toastMessages.noSufficientPriviliges);
                    history.push('/aepolicies');
                }
                let configObj = {
                    geofences: [],
                    wififences: [],
                    firewalls: [],
                    simSettings: [],
                    certificates: [],
                    callconfigs: [],
                    contacts: [],
                    acerodmfeatures: [],
                    lavaodmfeatures: []
                };
                if (result[0].status === 'fulfilled') {
                    result[0]?.value.forEach((data) => {
                        // if (data.configType === 'GEOFENCE') configObj.geofences.push(data);else
                        if (data.configType === 'WIFIFENCE') configObj.wififences.push(data);
                        else if (data.configType === 'FIREWALL') configObj.firewalls.push(data);
                        else if (data.configType === 'SIM') configObj.simSettings.push(data);
                        else if (data.configType === 'CALLCONFIG') configObj.callconfigs.push(data);
                        else if (data.configType === 'CONTACTS') configObj.contacts.push(data);
                        else if (data.configType === 'ODMFEATURES' && data.config.type === 'acer') configObj.acerodmfeatures.push(data);
                        else if (data.configType === 'ODMFEATURES' && data.config.type === 'lava') configObj.lavaodmfeatures.push(data);
                    });
                }
                let assetsArr = [];
                let setupActionPacks = [];
                if (result[1]?.status === 'fulfilled') {
                    let wallpaperArr = [];
                    let bootImagesArr = [];
                    let bootAnimationsArr = [];
                    let bootSoundArr = [];
                    let buzzersArr = [];
                    let logos = [];
                    let shutDownlogo = [];
                    let bgImages = [];
                    if (result[1]?.value?.length > 0) {
                        result[1]?.value?.forEach((ele) => {
                            let obj = { label: ele.name, value: ele.serverFileName, serverFileName: ele.serverFileName };
                            assetsArr.push(ele);
                            if (ele.assetType === 'Launcher Logo' && /\.(jpe?g|png|JPE?G|PNG|)$/.test(ele.serverFileName)) logos.push(obj);
                            if (ele.assetType === 'Launcher Background Image' && /\.(jpe?g|png|JPE?G|PNG|)$/.test(ele.serverFileName))
                                bgImages.push(obj);
                            if (ele.assetType === 'Wallpaper' && /\.(jpe?g|png|JPE?G|PNG|)$/.test(ele.serverFileName))
                                wallpaperArr.push(obj);
                            if (ele.assetType === 'Shutdown Logo') shutDownlogo.push(obj);
                            if (ele.assetType === 'Boot Logo Image') bootImagesArr.push(obj);
                            if (ele.assetType === 'Boot Animation') bootAnimationsArr.push(obj);
                            if (ele.assetType === 'Boot Sound') bootSoundArr.push(obj);
                            if (ele.assetType === 'Buzzer Sound') buzzersArr.push(obj);
                        });
                    }
                    setAssets({ wallpaper: wallpaperArr, bootanimation: bootAnimationsArr, shutdownlogo: shutDownlogo });
                }
                let managedConfigs = [];
                if (result[2]?.status === 'fulfilled') {
                    result?.[2]?.value?.forEach((data) => {
                        managedConfigs.push({
                            config: { ...data.config },
                            title: data.title,
                            managedConfigId: data.managedConfigId,
                            packageName: data.packageName
                        });
                    });
                }
                let obj;
                if (result?.[3]?.status === 'fulfilled') {
                    let dataObj = result?.[3]?.value?.policy ? result?.[3]?.value?.policy : result?.[3]?.value;
                    dataObj.kioskCustomLauncherEnabled =
                        polType === 'KL' ? true : polType === 'TL' || polType === 'SA' ? false : dataObj.kioskCustomLauncherEnabled;
                    if (polType === 'FM') {
                        delete dataObj.kioskCustomLauncherEnabled;
                    }
                    if (polType === 'KL') setShowKioskSettings(true);
                    obj = {
                        policyName: result?.[3]?.value?.name,
                        code: result?.[3]?.value?.code,
                        config: result?.[3]?.value?.config,
                        status: result?.[3]?.value?.status,
                        ...dataObj
                    };
                    /*
                     * let geos = configObj?.geofences?.find(
                     *     (conf) => conf.id === result?.[3]?.value?.config?.GEOFENCE?.restrictions?.zones?.[0]?.id
                     * );
                     */
                    let firewall = configObj?.firewalls?.find((conf) => conf.id === result?.[3]?.value?.config?.FIREWALL?.restrictions?.id);
                    let sims = configObj?.simSettings?.find((conf) => conf.id === result?.[3]?.value?.config?.SIM?.restrictions?.id);
                    let contacts = configObj?.contacts?.find(
                        (contact) => contact.id === result[3]?.value?.config?.CONTACTS?.restrictions?.id
                    );
                    let callconfig = configObj?.callconfigs?.find(
                        (conf) => conf.id === result[3]?.value?.config?.CALLCONFIG?.restrictions?.id
                    );
                    let acerodmfeatures = configObj?.acerodmfeatures?.find(
                        (conf) => conf.id === result[3]?.value?.config?.ODMFEATURES?.acer?.restrictions?.id
                    );

                    let lavaodmfeatures = configObj?.lavaodmfeatures?.find(
                        (conf) => conf.id === result[3]?.value?.config?.ODMFEATURES?.lava?.restrictions?.id
                    );

                    let wallpaper = assetsArr.find((asset) => asset.serverFileName === result?.[3]?.value?.config?.settings?.wallpaper);
                    let bootAnimation = assetsArr.find(
                        (asset) => asset.serverFileName === result?.[3]?.value?.config?.settings?.bootAnimation
                    );
                    let lockScreenWallpaper = assetsArr.find(
                        (asset) => asset.serverFileName === result?.[3]?.value?.config?.settings?.lockScreenWallpaper
                    );
                    let shutdownLogo = assetsArr.find(
                        (asset) => asset.serverFileName === result?.[3]?.value?.config?.settings?.shutdownlogo
                    );
                    if (result[5]?.status === 'fulfilled') {
                        const certificateIds = result?.[3]?.value?.config?.CERTIFICATE_MANAGEMENT?.restrictions?.certificates?.map(
                            (cert) => cert.id
                        );
                        obj.certificates = result[5]?.value?.filter((cert) => certificateIds?.includes(cert.id));
                    }
                    let featureType = [];
                    if (firewall) obj.firewall = firewall;
                    // if (geos) obj.geofence = geos;
                    if (sims) obj.sim = sims;
                    if (contacts) obj.contacts = contacts;
                    if (callconfig) obj.callconfig = callconfig;
                    if (acerodmfeatures) {
                        featureType.push({ label: 'Acer', value: 'acer' });
                        obj.acerodmfeatures = acerodmfeatures;
                    }
                    if (lavaodmfeatures) {
                        featureType.push({ label: 'Lava', value: 'lava' });
                        obj.lavaodmfeatures = lavaodmfeatures;
                    }

                    if (wallpaper) obj.wallpaper = { label: wallpaper.name, value: wallpaper.serverFileName };
                    if (lockScreenWallpaper)
                        obj.lockScreenWallpaper = { label: lockScreenWallpaper.name, value: lockScreenWallpaper.serverFileName };
                    if (bootAnimation) obj.bootAnimation = { label: bootAnimation.name, value: bootAnimation.serverFileName };
                    if (shutdownLogo) obj.shutdownlogo = { label: shutdownLogo.name, value: shutdownLogo.serverFileName };
                    setDefaultData({ ...dataObj });
                    let wifiConfigs = [];
                    obj?.openNetworkConfiguration?.NetworkConfigurations?.forEach((config) => {
                        wifiConfigs.push({
                            wifiName: config?.Name,
                            ssid: config?.WiFi?.SSID,
                            security: securityOptions?.find((option) => option.value === config?.WiFi?.Security),
                            passphrase: config?.WiFi?.Passphrase,
                            HiddenSSID: config?.WiFi?.HiddenSSID
                        });
                    });
                    setWifiConfiguration([...wifiConfiguration, ...wifiConfigs]);
                    let startDate = obj.systemUpdate?.freezePeriods?.[0]?.startDate;
                    let endDate = obj.systemUpdate?.freezePeriods?.[0]?.endDate;
                    if (startDate) {
                        startDate = new Date(startDate.month + '/' + startDate.day + '/' + startDate.year);
                        obj.startDate = startDate;
                    }
                    if (endDate) {
                        endDate = new Date(endDate.month + '/' + endDate.day + '/' + endDate.year);
                        obj.endDate = endDate;
                    }

                    let features = [];
                    let featuresArr = PoliciesFieldsSchema?.find((schema) => schema.label === 'Configurations');
                    featuresArr = featuresArr?.fields?.find((field) => field.label === 'Features');
                    if (featuresArr?.options?.length > 0) {
                        featuresArr?.options.forEach((feature) => {
                            if (tenant?.[feature.hideOnTenant] && obj.config?.feature?.[feature.value]) features.push(feature);
                        });
                    }
                    let defaultPolOptions = PoliciesFieldsSchema?.find((schema) => schema.label === 'Communication Settings');
                    defaultPolOptions = defaultPolOptions?.fields?.find((field) => field.label === 'Default Permission Policy')?.options;
                    let networkSettings = PoliciesFieldsSchema?.find((schema) => schema.label === 'Network Settings');
                    let ssidTypes = networkSettings?.fields?.find((field) => field.value === 'wifiSsidPolicyType')?.options;
                    let defaultPermissionPolicy = defaultPolOptions.find((option) => option.value === dataObj.defaultPermissionPolicy);
                    obj = await prepareData(obj, managedConfigs, result[4]?.status === 'fulfilled' ? result[4]?.value : []);
                    obj.wifiSsidPolicyType = ssidTypes?.find(
                        (type) => type.value === dataObj?.deviceConnectivityManagement?.wifiSsidPolicy?.wifiSsidPolicyType
                    );
                    obj.wifiSsids = dataObj?.deviceConnectivityManagement?.wifiSsidPolicy?.wifiSsids
                        ?.map((ssid) => ssid.wifiSsid)
                        ?.join(',');
                    obj.feature = features;
                    let arr = [];
                    obj.setupActions?.forEach((action) => {
                        arr.push({
                            title: action?.title?.defaultMessage,
                            description: action?.description?.defaultMessage,
                            launchApp: action?.launchApp?.packageName
                        });
                    });
                    obj.defaultPermissionPolicyInPolicy = defaultPermissionPolicy;
                    setupActionPacks = arr.map((setupObj) => setupObj.launchApp);
                    obj.setupActions = [...arr];
                    let kioskAppsArr = [...kioskApps];
                    if (obj.kioskCustomLauncherEnabled === false) kioskAppsArr[0].checked = true;
                    else kioskAppsArr[0].checked = false;
                    if (kioskAppsArr[0].checked) setShowKioskSettings(true);
                    if (formType === 'view' || formType === 'edit' || code) {
                        kioskAppsArr = kioskAppsArr.filter((app) => app.checked);
                    }
                    setKioskApps(kioskAppsArr);
                    obj.odmFeatureType = [...featureType];
                    setFormValues({ ...obj });
                }
                let packages = [];
                let configPacks = { allowed: [], default: [], blocked: [] };
                let configEnableDisable = { enable: [], disable: [] };
                if (result[4]?.status === 'fulfilled') {
                    let tectoroApps = [];
                    let fields = PoliciesFieldsSchema.find((schema) => schema.label === 'Applications')?.fields;
                    let defaultPermissions = fields?.find((field) => field.value === 'defaultPermissionPolicy')?.options;
                    let delegatedOptions = fields.find((field) => field.value === 'delegatedScopes')?.options;
                    let autoUpdateOptions = fields.find((field) => field.value === 'autoUpdateMode')?.options;
                    let permissionOptions = fields.find((field) => field.value === 'permissionGrants')?.options;
                    let vpnExemptionOptions = fields.find((field) => field.value === 'alwaysOnVpnLockdownExemption')?.options;
                    let networkTypes = fields.find((field) => field.value === 'networkTypeConstraint')?.options;
                    let charginOptions = fields.find((field) => field.value === 'chargingConstraint')?.options;
                    let idleOptions = fields.find((field) => field.value === 'deviceIdleConstraint')?.options;
                    let providerPolicyOptions = fields.find((field) => field.value === 'credentialProviderPolicy')?.options;
                    let userControlOptions = fields.find((field) => field.value === 'userControlSettings')?.options;
                    if (result[4]?.value && result[4]?.value?.length > 0) {
                        let kioskFound = false;
                        let appObj = JSON.parse(JSON.stringify(applicationObj));
                        result[4]?.value?.forEach((ele) => {
                            if (ele.code) tectoroApps.push(ele.packageName);
                            if (ele.code === 'LAUNCHER') setLauncherPackagename(ele.packageName);
                            packages.push(ele.packageName);
                            ele.managedConfigs = managedConfigs.filter((config) => config.packageName === ele.packageName);
                            ele.personalApplication =
                                obj.personalUsagePolicies?.personalApplications?.filter((pack) => pack.packageName === ele.packageName)
                                    ?.length > 0;
                            let found = false;
                            if (obj.applications?.length > 0) {
                                obj.applications.forEach((app) => {
                                    if (ele.packageName === app.packageName) {
                                        kioskFound = true;
                                        found = true;
                                        ele.selected = true;
                                        ele.kiosk = app.installType === 'KIOSK';
                                        // conditional code
                                        ele.HRBlocked =
                                            (window.location.hostname === 'apse.emmprod1.tectoro.com'
                                                ? obj.code === 'P026' ||
                                                  obj.code === 'P002' ||
                                                  obj.code === 'P023' ||
                                                  obj.code === 'P021' ||
                                                  obj.code === 'P023' ||
                                                  obj.code === 'APSE002HRB'
                                                : window.location.hostname === 'portal.emmprod1.tectoro.com'
                                                ? obj.code === 'P047' || obj.code === 'P038' || obj.code === 'P003'
                                                : false) && app.packageName === 'com.samsung.android.knox.kpu'
                                                ? true
                                                : false;
                                        appObj[app.packageName].credentialProviderPolicy = providerPolicyOptions?.find(
                                            (option) => option.value === app.credentialProviderPolicy
                                        );
                                        appObj[app.packageName].userControlSettings = userControlOptions?.find(
                                            (option) => option.value === app.userControlSettings
                                        );
                                        appObj[app.packageName].installPriority = app.installPriority ? app.installPriority : '';
                                        if (
                                            (app.installType === 'FORCE_INSTALLED' || app.installType === 'PREINSTALLED') &&
                                            (app.installConstraint?.[0]?.networkTypeConstraint ||
                                                app.installConstraint?.[0]?.chargingConstraint ||
                                                app.installConstraint?.[0]?.deviceIdleConstraint)
                                        ) {
                                            appObj[app.packageName].networkTypeConstraint = networkTypes?.find(
                                                (type) => type.value === app.installConstraint?.[0]?.networkTypeConstraint
                                            );
                                            appObj[app.packageName].chargingConstraint = charginOptions?.find(
                                                (type) => type.value === app.installConstraint?.[0]?.chargingConstraint
                                            );
                                            appObj[app.packageName].deviceIdleConstraint = idleOptions?.find(
                                                (type) => type.value === app.installConstraint?.[0]?.deviceIdleConstraint
                                            );
                                        }
                                        ele.setupAction = setupActionPacks?.includes(app.packageName);
                                        ele.fieldsList = profileDetails[profileDetails?.length - 1]?.fields;
                                        if (ele.kiosk) {
                                            let permissionGrantVals = app.permissionGrants?.map((permission) => permission.permission);
                                            setSelectedLauncherApp(ele);
                                            appObj = {
                                                ...applicationObj,
                                                ...appObj,
                                                [ele.packageName]: {
                                                    ...app,
                                                    kiosk: ele.kiosk,
                                                    defaultPermissionPolicy: defaultPermissions.find(
                                                        (option) => option.value === app.defaultPermissionPolicy
                                                    ),
                                                    delegatedScopes: delegatedOptions.filter((option) =>
                                                        app.delegatedScopes?.includes(option.value)
                                                    ),
                                                    autoUpdateMode: autoUpdateOptions?.find(
                                                        (option) => option.value === app.autoUpdateMode
                                                    ),
                                                    permissionGrants: permissionOptions?.filter((option) =>
                                                        permissionGrantVals?.includes(option.value)
                                                    ),
                                                    alwaysOnVpnLockdownExemption: vpnExemptionOptions.find(
                                                        (option) => option.value === app.alwaysOnVpnLockdownExemption
                                                    ),
                                                    managedConfiguration: ele.managedConfigs?.find(
                                                        (managedConfig) =>
                                                            app?.managedConfiguration?.managedConfigId === managedConfig?.managedConfigId
                                                    ),
                                                    managedConfigId:
                                                        ele.managedConfigs?.length > 0
                                                            ? ele.managedConfigs?.find(
                                                                  (config) => config.managedConfigId === ele?.managedConfigId
                                                              )
                                                            : null,
                                                    permittedAccessibilityServices: appObj[ele.packageName]?.permittedAccessibilityServices,
                                                    selected: true,
                                                    userControlSettings: userControlOptions?.find(
                                                        (option) => option.value === app.userControlSettings
                                                    )
                                                }
                                            };
                                            if (appObj[app.packageName]?.managedConfiguration) {
                                                appObj[app.packageName].managedConfigId = {
                                                    config: { ...appObj[app.packageName]?.managedConfiguration },
                                                    managedConfigId: appObj[app.packageName]?.managedConfiguration?.managedConfigId,
                                                    title: appObj[app.packageName]?.managedConfiguration?.title
                                                };
                                            }
                                        }
                                        return ele;
                                    }
                                });
                                if (found) configPacks.allowed.push({ ...ele, default: true });
                            } else {
                                ele.active = false;
                                ele.selected = false;
                                if (ele.code !== 'LAUNCHER') configPacks.default.push(ele);
                            }
                        });
                        setTectoroApplications(tectoroApps);
                        if (!kioskFound && polType === 'TL') {
                            let launcherPackage = '';
                            result[4].value.forEach((app) => {
                                if (app.code === 'LAUNCHER') {
                                    launcherPackage = app;
                                    app.selected = true;
                                    app.kiosk = true;
                                }
                            });
                            configPacks.allowed.push({ ...launcherPackage, default: true });
                            setSelectedLauncherApp(launcherPackage);
                            appObj[launcherPackage.packageName] = {
                                installType: { label: 'Kiosk', value: 'KIOSK' },
                                autoUpdateMode: { label: 'High Priority', value: 'AUTO_UPDATE_HIGH_PRIORITY' },
                                defaultPermissionPolicy: { label: 'Grant', value: 'GRANT' },
                                extensionConfig: true,
                                delegatedScopes: [
                                    { label: 'Cert Install', value: 'CERT_INSTALL' },
                                    { label: 'Managed Configurations', value: 'MANAGED_CONFIGURATIONS' },
                                    { label: 'Block Uninstall', value: 'BLOCK_UNINSTALL' },
                                    { label: 'Permission Grant', value: 'PERMISSION_GRANT' },
                                    { label: 'Package Access', value: 'PACKAGE_ACCESS' },
                                    { label: 'Enable System App', value: 'ENABLE_SYSTEM_APP' },
                                    { label: 'Network Activity Logs', value: 'NETWORK_ACTIVITY_LOGS' },
                                    { label: 'Security Logs', value: 'SECURITY_LOGS' }
                                ]
                            };
                        }
                        setApplicationObj({ ...appObj });
                        let allowedConfigPacks = configPacks.allowed?.map((allow) => allow.packageName);
                        let blockedConfigPacks = configPacks.blocked?.map((block) => block.packageName);
                        if (result?.[3]?.status === 'fulfilled') {
                            setProtectedPolicy(!!result?.[3]?.value?.config?.protectedPolicy);
                            if (tenant.AE_ENABLE_DISABLE_PACKAGE) {
                                result?.[3]?.value?.config?.ENABLE_DISABLE_APPS?.restrictions?.enable?.forEach((app) => {
                                    configEnableDisable.enable.push({ packageName: app });
                                });
                                result?.[3]?.value?.config?.ENABLE_DISABLE_APPS?.restrictions?.disable?.forEach((app) => {
                                    configEnableDisable.disable.push({ packageName: app });
                                });
                            }
                            result?.[3]?.value?.config?.PACKAGE?.restrictions?.allowed.forEach((allow) => {
                                if (!allowedConfigPacks.includes(allow)) {
                                    let found = false;
                                    let appObject;
                                    if (result?.[4]?.status === 'fulfilled') {
                                        let appArr = result?.[4]?.value?.filter((app) => app.packageName === allow);
                                        if (appArr.length > 0) {
                                            found = true;
                                            appObject = appArr[0];
                                        }
                                    }
                                    if (found) {
                                        configPacks.allowed.push(appObject);
                                    } else configPacks.allowed.push({ packageName: allow });
                                }
                            });
                            result?.[3]?.value?.config?.PACKAGE?.restrictions?.blocked.forEach((block) => {
                                if (!blockedConfigPacks.includes(block)) {
                                    let found = false;
                                    let appObject;
                                    if (result?.[4]?.status === 'fulfilled') {
                                        let appArr = result?.[4]?.value?.filter((app) => app.packageName === block);
                                        if (appArr.length > 0) {
                                            found = true;
                                            appObject = appArr[0];
                                        }
                                    }
                                    if (found) {
                                        if (appObject.code !== 'LAUNCHER') configPacks.blocked.push(appObject);
                                    } else {
                                        configPacks.blocked.push({ packageName: block });
                                    }
                                }
                            });
                        }
                        let selectedApps = result[4]?.value?.filter((data) => data.selected);
                        let unselectedApps = result[4]?.value?.filter((data) => !data.selected);
                        let unselectedPackages = unselectedApps.map((app) => app.packageName);
                        let defaultApps = [...unselectedApps];
                        result[3]?.value?.config?.PACKAGE?.restrictions?.blocked?.forEach((block) => {
                            if (unselectedPackages.includes(block)) {
                                defaultApps = defaultApps.filter((defApp) => defApp.packageName !== block);
                            }
                        });
                        result[3]?.value?.config?.PACKAGE?.restrictions?.allowed?.forEach((allow) => {
                            if (unselectedPackages.includes(allow)) {
                                defaultApps = defaultApps.filter((defApp) => defApp.packageName !== allow);
                            }
                        });
                        setConfigApps(configEnableDisable);
                        setConfigPackages({ ...configPacks, default: defaultApps });
                        setConfigPackagesBackup({ ...configPacks, default: defaultApps });
                        setSelectedAppData(result[4]?.value?.filter((ele) => ele.active && ele.selected)?.[0]);
                        result[4].value = selectedApps.concat(unselectedApps);
                        /*
                         * resp.data.forEach((app, ind) => {
                         *     if (app.kiosk) setSelectedInd(ind);
                         * });
                         */
                        let launcherAppsArr = JSON.parse(JSON.stringify(selectedApps));
                        if (obj.config?.kioskLauncherTheme?.applications?.length > 0) {
                            let selectedAppPackages = obj.config?.kioskLauncherTheme?.applications?.map((app) => app.packageName);
                            launcherAppsArr.forEach((app) => {
                                if (selectedAppPackages.includes(app.packageName)) {
                                    app.selected = true;
                                } else app.selected = false;
                            });
                        } else
                            launcherAppsArr.forEach((app) => {
                                app.selected = false;
                            });
                        let selectedLauncherApps = launcherAppsArr.filter((app) => app.selected);
                        let unSelectedLauncherApps = launcherAppsArr.filter((app) => !app.selected);
                        launcherAppsArr = selectedLauncherApps.concat(unSelectedLauncherApps);
                        if (mode === 'view' || formType === 'view') {
                            setLauncherAppsData(launcherAppsArr);
                            setApplicationData(selectedApps);
                        } else {
                            setLauncherAppsData(launcherAppsArr);
                            setApplicationData(result[4]?.value);
                        }
                        setAvailableApplications(packages);
                    }
                }
                if (result[5].status === 'fulfilled') {
                    if (result[5]?.value !== '')
                        result[5]?.value.forEach((data) => {
                            configObj.certificates.push(data);
                        });
                }
                setConfigurations({ ...configObj });
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const getApplications = (eData, obj, managedConfigs, setupActionPacks, refresh) => {
        setLoading(true);
        let packages = [];
        let params = { enterpriseId: eData.id, page: 1, size: 1000 };
        api.get(url.APPLICATIONS, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                let fields = PoliciesFieldsSchema.find((schema) => schema.label === 'Applications')?.fields;
                let defaultPermissions = fields?.find((field) => field.value === 'defaultPermissionPolicy')?.options;
                let delegatedOptions = fields.find((field) => field.value === 'delegatedScopes')?.options;
                let autoUpdateOptions = fields.find((field) => field.value === 'autoUpdateMode')?.options;
                let permissionOptions = fields.find((field) => field.value === 'permissionGrants')?.options;
                let vpnExemptionOptions = fields.find((field) => field.value === 'alwaysOnVpnLockdownExemption')?.options;
                if (resp && resp?.data && resp?.data?.length > 0) {
                    resp.data.forEach((ele) => {
                        packages.push(ele.packageName);
                        if (ele.packageName === refresh?.packageName) {
                            ele.selected = true;
                            ele.active = true;
                            refresh.details.tracks = ele.details.tracks;
                        }
                        ele.managedConfigs = managedConfigs.filter((config) => config.packageName === ele.packageName);
                        if (obj.applications?.length > 0) {
                            obj.applications.forEach((app) => {
                                if (ele.packageName === app.packageName) {
                                    ele.selected = true;
                                    if (refresh && ele.packageName === refresh?.packageName) ele.active = true;
                                    ele.kiosk = app.installType === 'KIOSK';

                                    // conditional code
                                    ele.HRBlocked =
                                        (window.location.hostname === 'apse.emmprod1.tectoro.com'
                                            ? obj.code === 'P026' ||
                                              obj.code === 'P002' ||
                                              obj.code === 'P023' ||
                                              obj.code === 'P021' ||
                                              obj.code === 'P023' ||
                                              obj.code === 'APSE002HRB'
                                            : window.location.hostname === 'portal.emmprod1.tectoro.com'
                                            ? obj.code === 'P047' || obj.code === 'P038' || obj.code === 'P003'
                                            : false) && app.packageName === 'com.samsung.android.knox.kpu'
                                            ? true
                                            : false;
                                    ele.setupAction = setupActionPacks?.includes(app.packageName);
                                    ele.fieldsList = profileDetails[profileDetails?.length - 1]?.fields;
                                    if (ele.kiosk) {
                                        let permissionGrantVals = app.permissionGrants?.map((permission) => permission.permission);
                                        setSelectedLauncherApp(ele);
                                        let appObj = {
                                            ...applicationObj,
                                            [ele.packageName]: {
                                                ...app,
                                                kiosk: ele.kiosk,
                                                defaultPermissionPolicy: defaultPermissions.find(
                                                    (option) => option.value === app.defaultPermissionPolicy
                                                ),
                                                delegatedScopes: delegatedOptions.filter((option) =>
                                                    app.delegatedScopes?.includes(option.value)
                                                ),
                                                autoUpdateMode: autoUpdateOptions?.find((option) => option.value === app.autoUpdateMode),
                                                permissionGrants: permissionOptions?.filter((option) =>
                                                    permissionGrantVals?.includes(option.value)
                                                ),
                                                alwaysOnVpnLockdownExemption: vpnExemptionOptions.find(
                                                    (option) => option.value === app.alwaysOnVpnLockdownExemption
                                                ),
                                                managedConfiguration: ele.managedConfigs?.find(
                                                    (managedConfig) =>
                                                        app?.managedConfiguration?.managedConfigId === managedConfig?.managedConfigId
                                                ),
                                                managedConfigId:
                                                    ele.managedConfigs?.length > 0
                                                        ? ele.managedConfigs?.find(
                                                              (config) => config.managedConfigId === ele?.managedConfigId
                                                          )
                                                        : null,
                                                selected: true
                                            }
                                        };
                                        if (appObj[app.packageName]?.managedConfiguration) {
                                            appObj[app.packageName].managedConfigId = {
                                                config: { ...appObj[app.packageName]?.managedConfiguration },
                                                managedConfigId: appObj[app.packageName]?.managedConfiguration?.managedConfigId,
                                                title: appObj[app.packageName]?.managedConfiguration?.title
                                            };
                                        }
                                        setApplicationObj({ ...appObj });
                                    }
                                    return ele;
                                }
                            });
                        } else {
                            ele.active = false;
                            ele.selected = false;
                        }
                    });
                    if (refresh) setSelectedAppData(refresh);
                    else setSelectedAppData(resp.data.filter((ele) => ele.active && ele.selected)?.[0]);
                    let selectedApps = resp.data.filter((data) => data.selected);
                    let unselectedApps = resp.data.filter((data) => !data.selected);
                    resp.data = selectedApps.concat(unselectedApps);
                    /*
                     * resp.data.forEach((app, ind) => {
                     *     if (app.kiosk) setSelectedInd(ind);
                     * });
                     */
                    let launcherAppsArr = JSON.parse(JSON.stringify(selectedApps));
                    if (obj.config?.kioskLauncherTheme?.applications?.length > 0) {
                        let selectedAppPackages = obj.config?.kioskLauncherTheme?.applications?.map((app) => app.packageName);
                        launcherAppsArr.forEach((app) => {
                            if (selectedAppPackages.includes(app.packageName)) {
                                app.selected = true;
                            } else app.selected = false;
                        });
                    } else
                        launcherAppsArr.forEach((app) => {
                            app.selected = false;
                        });
                    let selectedLauncherApps = launcherAppsArr.filter((app) => app.selected);
                    let unSelectedLauncherApps = launcherAppsArr.filter((app) => !app.selected);
                    launcherAppsArr = selectedLauncherApps.concat(unSelectedLauncherApps);
                    if (mode === 'view' || formType === 'view') {
                        setLauncherAppsData(launcherAppsArr);
                        setApplicationData(selectedApps);
                    } else {
                        setLauncherAppsData(launcherAppsArr);
                        setApplicationData(resp.data);
                    }
                    setAvailableApplications(packages);
                }
                setLoading(false);
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const prepareData = (obj, managedConfigs, apps) => {
        let permittedAccessibilityServices = obj?.permittedAccessibilityServices;
        /*
         * obj.personalCameraDisabled = obj?.personalUsagePolicies?.cameraDisabled;
         * obj.personalScreenCaptureDisabled = obj?.personalUsagePolicies?.screenCaptureDisabled;
         */
        profileDetails.forEach((profile) => {
            if (profile?.fields?.length > 0) {
                profile.fields.forEach((field) => {
                    for (let [key, value] of Object.entries(obj)) {
                        if (key !== 'applications' && field.value === key) obj = setData(field, value, obj);
                        else if (key !== 'applications' && value && field.parentValue && field.parentValue === key) {
                            let listObj = value?.length > 0 ? value?.[0] : value;
                            for (let [key1, value1] of Object.entries(listObj)) {
                                if (field.value === key1) obj = setData(field, value1, obj);
                            }
                        } else if (key === 'applications' && value?.length > 0 && field.parentValue && field.parentValue === key) {
                            // eslint-disable-next-line no-loop-func
                            value.forEach((listObj) => {
                                for (let [key1, value1] of Object.entries(listObj)) {
                                    if (field.value === key1)
                                        if (field.options?.length > 0 && typeof value1 === 'string') {
                                            let findObj = field.options.find((option) => option.value === value1);
                                            applicationObj[listObj.packageName] = {
                                                ...applicationObj[listObj.packageName],
                                                [field.value]: { ...findObj }
                                            };
                                        } else if (field.options?.length > 0 && value1?.length > 0) {
                                            let arr = [];
                                            let listArr = [];
                                            if (obj?.personalUsagePolicies?.personalApplications) {
                                                let personalApp = obj?.personalUsagePolicies?.personalApplications?.filter(
                                                    (app) => app.packageName === listObj.packageName
                                                );
                                                applicationObj[listObj.packageName] = {
                                                    ...applicationObj[listObj.packageName],
                                                    personalApplication: personalApp?.[0] ? true : false,
                                                    personalAppInstallType: personalAppOptions.find(
                                                        (opt) => opt.value === personalApp?.[0]?.installType
                                                    )
                                                };
                                            }
                                            value1.forEach((val) => {
                                                if (checkValue(val?.[field?.objectKey]) && Object.keys(val)?.length > 0) {
                                                    if (
                                                        field.isMulti &&
                                                        field.options?.length > 0 &&
                                                        typeof val[field.objectKey] === 'string'
                                                    ) {
                                                        let findObj = field.options.find((option) => option.value === val[field.objectKey]);
                                                        listArr.push(findObj);
                                                        applicationObj[listObj.packageName] = {
                                                            ...applicationObj[listObj.packageName],
                                                            [field.value]: listArr
                                                        };
                                                    } else if (field.options?.length > 0 && typeof val[field.objectKey] === 'string') {
                                                        let findObj = field.options.find((option) => option.value === val[field.objectKey]);
                                                        applicationObj[listObj.packageName] = {
                                                            ...applicationObj[listObj.packageName],
                                                            [field.value]: findObj
                                                        };
                                                    } else
                                                        applicationObj[listObj.packageName] = {
                                                            ...applicationObj[listObj.packageName],
                                                            [field.value]: val[field.objectKey]
                                                        };
                                                } else {
                                                    field.options.forEach((option) => {
                                                        if (option.value === val) arr.push(option);
                                                    });
                                                    applicationObj[listObj.packageName] = {
                                                        ...applicationObj[listObj.packageName],
                                                        [field.value]: [...arr]
                                                    };
                                                }
                                            });
                                        } else {
                                            applicationObj[listObj.packageName] = {
                                                ...applicationObj[listObj.packageName],
                                                [field.value]: value1
                                            };
                                        }
                                }
                                applicationObj[listObj.packageName] = {
                                    ...applicationObj[listObj.packageName],
                                    packageName: listObj.packageName,
                                    accessibleTrackIds: listObj.accessibleTrackIds,
                                    managedConfiguration: managedConfigs?.find(
                                        (config) => config.managedConfigId === listObj?.managedConfiguration?.managedConfigId
                                    ),
                                    managedConfigId: listObj?.managedConfigurationTemplate?.templateId
                                };
                                if (applicationObj[listObj.packageName]?.managedConfiguration) {
                                    applicationObj[listObj.packageName].managedConfigId = {
                                        config: { ...applicationObj[listObj.packageName]?.managedConfiguration },
                                        managedConfigId: applicationObj[listObj.packageName]?.managedConfiguration?.managedConfigId,
                                        title: applicationObj[listObj.packageName]?.managedConfiguration?.title
                                    };
                                }
                                if (permittedAccessibilityServices?.packageNames?.length > 0) {
                                    applicationObj[listObj.packageName].permittedAccessibilityServices =
                                        permittedAccessibilityServices?.packageNames?.includes(listObj.packageName);
                                }
                            });
                            obj = { ...obj, ...applicationObj };
                            setApplicationObj({ ...applicationObj });
                        }
                    }
                });
            }
        });
        return obj;
    };

    const setData = (field, value, obj) => {
        if (field.options?.length > 0 && typeof value === 'string') {
            let findObj = field.options.find((option) => option.value === value);
            obj = { ...obj, [field.value]: findObj };
        } else if (field.options?.length > 0 && value?.length > 0) {
            let arr = [];
            field.options.forEach((option) => {
                value.forEach((val) => {
                    if (option.value === val) arr.push(option);
                });
            });
            obj = { ...obj, [field.value]: [...arr] };
        } else if (checkValue(value?.[field?.objectKey]) && Object.keys(value)?.length > 0) {
            if (field.options?.length > 0 && typeof value[field.objectKey] === 'string') {
                let findObj = field.options.find((option) => option.value === value[field.objectKey]);
                obj = { ...obj, [field.value]: findObj };
            } else obj = { ...obj, [field.value]: value[field.objectKey] };
        } else obj = { ...obj, [field.value]: value };
        return obj;
    };

    const handleProfileDetail = (profileObj, index) => {
        profileObj = { ...profileObj, active: true };
        let profileDetailsArr = [];
        profileDetails.map((detail) => {
            if (profileDetails.indexOf(detail) === index) return profileDetailsArr.push(profileObj);
            else return profileDetailsArr.push({ ...detail, active: false });
        });
        setShowConfig(false);
        setShowAddAction(false);
        setShowKioskAppConfig(false);
        setSelected(profileObj);
        setAddWifiConfig(false);
        validation.setValues({
            ...validation.values,
            wifiName: '',
            ssid: '',
            security: '',
            HiddenSSID: false,
            passphrase: '',
            title: '',
            description: '',
            launchApp: ''
        });
        setProfileDetails(profileDetailsArr);
    };
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: policyInitValues(formValues),
        validationSchema: Yup.object({
            policyName: Yup.string().required('Please enter your policy name'),
            passphrase: Yup.string().min(8, 'Should be atleast 8 characters')
        })
    });

    const prepareWorkProfileWorkApps = () => {
        let personalApplications = [];
        Object.entries(applicationObj).forEach((app) => {
            if (app[1]?.personalApplication)
                personalApplications.push({ packageName: app[0], installType: app[1]?.personalAppInstallType?.value });
        });
        return personalApplications;
    };

    const handleSubmit = (typeParam) => {
        let values = validation.values;
        if (checkSaveDisable()) {
            toast.error(toastMessages.fillAllMandatoryFields);
            return;
        }
        if (polType === 'SA') {
            let appObj = JSON.parse(JSON.stringify(applicationObj));
            let found = false;
            Object.entries(appObj).forEach((app) => {
                if (app[1].installType?.value === 'KIOSK' || app[1].installType === 'KIOSK') {
                    found = true;
                    return;
                }
            });
            if (!found || Object.entries(appObj).length === 0) {
                toast.error(toastMessages.kioskAppNotFound);
                return;
            }
        }

        let data = prepareDataObj(
            values,
            formValues,
            enterpriseObj,
            polType,
            defaultData,
            wifiConfiguration,
            applicationObj,
            launcherPackagename,
            availableApplications,
            tenant,
            accountId,
            tectoroApplications
        );
        let fullyManagedDataObj = data.fullyManagedDataObj;
        let workProfileDataObj = data.workProfileDataObj;
        let dedictedDataObj = data.dedictedDataObj;

        let workProfileWorkApps = prepareWorkProfileWorkApps();

        if (workProfileWorkApps?.length > 0) {
            workProfileDataObj.policy.personalUsagePolicies.personalApplications = workProfileWorkApps;
        }

        let dataObj = {};
        if (polType === 'FM' || polType === 'KL' || polType === 'TL') dataObj = fullyManagedDataObj;
        else if (polType === 'WP') {
            /*
             * if (Number(values?.maxDaysWithWorkOff) !== 0 && Number(values?.maxDaysWithWorkOff) < 3) {
             *     toast.error(toastMessages.maxDaysWorkoffLimit);
             *     return;
             * }
             */
            dataObj = workProfileDataObj;
        } else if (polType === 'SA') dataObj = dedictedDataObj;

        let kioskLauncherThemeArr = [];
        let launcherAppsDataArr = launcherAppsData?.filter((app) => app.selected);
        launcherAppsDataArr.forEach((ele) => {
            kioskLauncherThemeArr.push({ appName: ele.title, packageName: ele.packageName });
        });
        dataObj.config = {
            kioskLauncherTheme: { applications: kioskLauncherThemeArr },
            settings: {
                homeScreenWallpaper: values?.wallpaper?.value ? values?.wallpaper?.value : '',
                wallpaper: values?.wallpaper?.value ? values?.wallpaper?.value : '',
                lockScreenWallpaper: values?.lockScreenWallpaper?.value ? values?.lockScreenWallpaper?.value : '',
                bootAnimation: values?.bootAnimation?.value ? values?.bootAnimation?.value : null,
                shutdownlogo: values?.shutdownlogo?.value ? values?.shutdownlogo?.value : null
                /*
                 * kioskExitPasswordType: values?.default_password === 'true' ? 'default' : 'custom',
                 * kioskExitPassword: values?.default_password === 'true' ? tenant.DEVICE_DEFAULT_PASSWORD : values?.kioskExitPassword
                 * bootImage: values?.bootImage?.value ? values?.bootImage?.value : null,
                 * bootSound: values?.bootSound?.value ? values?.bootSound?.value : null,
                 * buzzer: values?.buzzer?.value ? values?.buzzer?.value : null
                 */
            }
        };

        if (configPackages?.allowed?.length > 0 || configPackages?.blocked?.length > 0)
            dataObj.config.PACKAGE = {
                featureEnabled: true,
                restrictions: {
                    allowed: configPackages?.allowed?.length > 0 ? configPackages?.allowed?.map((allow) => allow.packageName) : [],
                    blocked: configPackages?.blocked?.length > 0 ? configPackages?.blocked?.map((allow) => allow.packageName) : []
                }
            };

        if (tenant.AE_ENABLE_DISABLE_PACKAGE && (configApps?.enable?.length > 0 || configApps?.disable?.length > 0)) {
            dataObj.config.ENABLE_DISABLE_APPS = {
                featureEnabled: true,
                restrictions: {
                    enable: configApps.enable?.length > 0 ? configApps.enable?.map((enable) => enable.packageName) : [],
                    disable: configApps.disable?.length > 0 ? configApps.disable?.map((disable) => disable.packageName) : []
                }
            };
        }

        /*
         * if (values.geofence?.config && tenant?.GEOFENCE)
         *     dataObj.config.GEOFENCE = {
         *         featureEnabled: !!values?.geofence,
         *         restrictions: { zones: [{ ...values.geofence.config, id: values.geofence.id }] }
         *     };
         */

        if (values?.firewall && tenant?.FIREWALL) {
            dataObj.config.FIREWALL = {
                featureEnabled: true,
                restrictions: { ...values?.firewall?.config, id: values?.firewall?.id }
            };
            dataObj.policy.recommendedGlobalProxy = values?.firewall?.config?.storageUrl
                ? {
                      host: null,
                      port: null,
                      excludedHosts: [],
                      pacUri: values?.firewall?.config?.storageUrl
                  }
                : null;
        } else {
            delete dataObj.policy.recommendedGlobalProxy;
        }

        if (values?.simsetting && tenant?.SIM_SETTINGS)
            dataObj.config.SIM = { featureEnabled: true, restrictions: { ...values?.simsetting?.config, id: values?.simsetting?.id } };

        if (values.certificates && tenant?.CERTIFICATE_MANAGEMENT)
            dataObj.config.CERTIFICATE_MANAGEMENT = {
                featureEnabled: !!values?.certificates,
                restrictions: { certificates: values?.certificates }
            };

        if (tenant?.ODM_FEATURES) dataObj.config.ODMFEATURES = {};
        if (validation.values.odmFeatureType?.find((feature) => feature.value === 'acer'))
            dataObj.config.ODMFEATURES.acer = {
                featureEnabled: !!values?.acerodmfeatures,
                restrictions: { ...values?.acerodmfeatures?.config, id: values?.acerodmfeatures?.id }
            };
        if (validation.values.odmFeatureType?.find((feature) => feature.value === 'lava'))
            dataObj.config.ODMFEATURES.lava = {
                featureEnabled: !!values?.lavaodmfeatures,
                restrictions: { ...values?.lavaodmfeatures?.config, id: values?.lavaodmfeatures?.id }
            };

        if (values.callconfig) {
            dataObj.config.CALLCONFIG = {
                featureEnabled: !!values?.callconfig,
                restrictions: {
                    ...values?.callconfig?.config,
                    id: values?.callconfig?.id
                }
            };
        }
        if (values.contacts) {
            dataObj.config.CONTACTS = {
                featureEnabled: !!values?.contacts,
                restrictions: {
                    ...values?.contacts.config,
                    id: values?.contacts?.id
                }
            };
        }
        dataObj.config = {
            ...dataObj.config,
            protectedPolicy: !!protectedPolicy,
            feature: {
                enable_internal_storage_details: true,
                enable_call_logs: !!values?.feature?.filter((feature) => feature.value === 'enable_call_logs')?.length > 0,
                enable_app_usage_report: !!values?.feature?.filter((feature) => feature.value === 'enable_app_usage_report')?.length > 0,
                enable_violations: !!values?.feature?.filter((feature) => feature.value === 'enable_violations')?.length > 0,
                enable_location_history: !!values?.feature?.filter((feature) => feature.value === 'enable_location_history')?.length > 0,
                enable_sim_operators: !!values?.feature?.filter((feature) => feature.value === 'enable_sim_operators')?.length > 0,
                enable_battery: !!values?.feature?.filter((feature) => feature.value === 'enable_battery')?.length > 0,
                enable_last_modified_date:
                    !!values?.feature?.filter((feature) => feature.value === 'enable_last_modified_date')?.length > 0,
                enable_personal_account: !!values?.feature?.filter((feature) => feature.value === 'enable_personal_account')?.length > 0,
                enable_wallpaper: !!values?.feature?.filter((feature) => feature.value === 'enable_wallpaper')?.length > 0,
                enable_lock_screen_wallpaper:
                    !!values?.feature?.filter((feature) => feature.value === 'enable_lock_screen_wallpaper')?.length > 0,
                enable_boot_animation: !!values?.feature?.filter((feature) => feature.value === 'enable_boot_animation')?.length > 0,
                enable_shutdown_logo: !!values?.feature?.filter((feature) => feature.value === 'enable_shutdown_logo')?.length > 0
            }
        };
        if (formType === 'edit') dataObj.id = recordID;
        if (typeParam === 'REJECTED') dataObj.remarks = remarks;
        setShowRejectModal(false);
        setRemarks('');
        createNdUpdateProfile(JSON.parse(JSON.stringify({ ...dataObj })), formType, typeParam);
    };

    const postAction = (message) => {
        toast.success(message);
        validation.resetForm();
        setLoading(false);
        history.push('/aepolicies');
    };

    const createNdUpdateProfile = (body, modeType, typeParam) => {
        let apiService = '';
        setLoading(true);
        let message = '';
        if (tenant.ENABLE_WORKFLOW) {
            body.policyStatus = typeParam;
            if (formValues.code) body.code = formValues.code;
            if (modeType === 'edit') {
                message = 'Policy updated successfully.';
                apiService = api.create(url.POLICY_WORKFLOW, body);
            } else if (typeParam === 'APPROVED') {
                message = 'Policy approved successfully.';
                body.policyStatus = typeParam;
                apiService = api.update(url.POLICY_WORKFLOW + '/' + recordID, body);
            } else if (typeParam === 'REJECTED') {
                message = 'Policy rejected successfully.';
                body.policyStatus = typeParam;
                apiService = api.update(url.POLICY_WORKFLOW + '/' + recordID, body);
            } else {
                message = 'Policy sent for review.';
                delete body.code;
                apiService = api.create(url.POLICY_WORKFLOW, body);
            }
        } else {
            if (modeType === 'edit') {
                message = 'Policy updated successfully.';
                apiService = api.update(url.POLICIES + '/' + recordID, body);
            } else {
                message = 'Policy created successfully.';
                apiService = api.create(url.POLICIES, body);
            }
        }

        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    postAction(message);
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const handleChange = (e, key, type) => {
        let invalid = false;
        let appObj = JSON.parse(JSON.stringify(applicationObj));
        if (
            (key[0] === 'delegatedScopes' && e.length > applicationObj[selectedAppData.packageName][key[0]]?.length) ||
            (key[0] === 'extensionConfig' && e.target.checked)
        )
            if (
                (key[0] === 'delegatedScopes' && e?.[e.length - 1]?.value === 'SECURITY_LOGS') ||
                (key[0] === 'delegatedScopes' && e?.[e.length - 1]?.value === 'NETWORK_ACTIVITY_LOGS') ||
                (key[0] === 'delegatedScopes' && e?.[e.length - 1]?.value === 'CERT_SELECTION') ||
                (key[0] === 'extensionConfig' && e.target.checked)
            ) {
                Object.entries(applicationObj).forEach((obj) => {
                    let found = false;
                    if (key[0] === 'delegatedScopes' && appObj[obj[0]].delegatedScopes?.length > 0) {
                        appObj[obj[0]].delegatedScopes.forEach((scope) => {
                            if (scope.value === e[e.length - 1].value) found = true;
                        });
                    } else if (key[0] === 'extensionConfig')
                        found = (obj[1].extensionConfig && Object.entries(obj[1].extensionConfig).length > 0) || obj[1].extensionConfig;

                    if (found) {
                        setDetails({
                            e:
                                type === 'checkbox'
                                    ? e.target.checked
                                    : type === 'dropdown'
                                    ? e
                                    : type === 'input'
                                    ? e.target.value
                                    : e.value
                                    ? e.value
                                    : e,
                            label: e?.[e?.length - 1]?.label ? e[e.length - 1]?.label : e.target.checked,
                            key: key,
                            obj: obj
                        });
                        setShowConfirmation(true);
                        invalid = true;
                    }
                });
            }
        if (invalid) return;
        applicationData.forEach((app) => {
            if (app.packageName === selectedAppData?.packageName && app.kiosk) {
                if (appObj[selectedAppData?.packageName]) {
                    appObj[selectedAppData?.packageName] = {
                        ...appObj[selectedAppData?.packageName],
                        [key]:
                            type === 'checkbox'
                                ? e.target.checked
                                : type === 'dropdown'
                                ? e
                                : type === 'input'
                                ? e.target.value
                                : e.value
                                ? e.value
                                : e,
                        installType: { label: 'Kiosk', value: 'KIOSK' }
                    };
                } else {
                    appObj[selectedAppData?.packageName] = {};
                    appObj[selectedAppData?.packageName] = {
                        ...appObj[selectedAppData?.packageName],
                        [key]:
                            type === 'checkbox'
                                ? e.target.checked
                                : type === 'dropdown'
                                ? e
                                : type === 'input'
                                ? e.target.value
                                : e.value
                                ? e.value
                                : e,
                        installType: { label: 'Kiosk', value: 'KIOSK' }
                    };
                }
            } else {
                if (appObj[selectedAppData?.packageName]) {
                    appObj[selectedAppData?.packageName] = {
                        ...appObj[selectedAppData?.packageName],
                        [key]:
                            type === 'checkbox'
                                ? e.target.checked
                                : type === 'dropdown'
                                ? e
                                : type === 'input'
                                ? e.target.value
                                : e.value
                                ? e.value
                                : e
                    };
                } else {
                    appObj[selectedAppData?.packageName] = {};
                    appObj[selectedAppData?.packageName] = {
                        ...appObj[selectedAppData?.packageName],
                        [key]:
                            type === 'checkbox'
                                ? e.target.checked
                                : type === 'dropdown'
                                ? e
                                : type === 'input'
                                ? e.target.value
                                : e.value
                                ? e.value
                                : e
                    };
                }
            }
        });
        setApplicationObj(appObj);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };

    const handleAlert = (e, field) => {
        if (e.target.checked === field.showAlertOn) {
            setSelectedToggleDetails({ field: field, value: e });
            setAlertModal(true);
        } else validation.setValues({ ...validation.values, [field.value]: e.target.checked });
    };

    const handleChangeValue = () => {
        validation.setValues({
            ...validation.values,
            [selectedToggleDetails?.field?.value]: !selectedToggleDetails?.value?.target?.checked
        });
        setSelectedToggleDetails('');
        setAlertModal(false);
    };

    const handleLaunchAppChange = (e) => {
        let found = false;
        validation.setValues({ ...validation.values, launchApp: e });
        applicationData.forEach((app) => {
            if (app.packageName === e.packageName && app.selected && !app.setupAction) {
                found = true;
                return;
            }
        });
        if (found) setSetupExisting(true);
        else setSetupExisting(false);
    };

    const handleLocationModeChange = (e) => {
        let values = { ...validation.values };
        values.locationMode = e;
        if (e.value === 'LOCATION_ENFORCED') values.shareLocationDisabled = true;
        validation.setValues({ ...values });
    };

    const handleConfigKiosk = (appObj) => {
        setSelectedLauncherApp(appObj);
        let found = false;
        applicationData.forEach((app) => {
            if (app.selected && !app.kiosk && app.packageName === appObj.packageName) {
                found = true;
                return;
            }
        });
        if (found) {
            setShowKioskConfig(false);
            setShowAppError(true);
            return;
        } else setShowAppError(false);
        let appObject = {};
        let selApp = {};
        Object.entries(applicationObj).forEach((obj) => {
            if (appObj.sel ? true : obj[1].installType?.value !== 'KIOSK' && obj[1].installType !== 'KIOSK') {
                appObject = { ...appObject, [obj[0]]: obj[1] };
            }
            if (obj[1].installType === 'KIOSK' || obj[1].installType?.value === 'KIOSK')
                selApp = applicationData.find((app) => app.packageName === obj[0]);
        });
        appObject[appObj.packageName] = appObject[appObj.packageName]
            ? appObject[appObj.packageName]
            : {
                  installType: { label: 'Kiosk', value: 'KIOSK' },
                  autoUpdateMode: { label: 'High Priority', value: 'AUTO_UPDATE_HIGH_PRIORITY' },
                  defaultPermissionPolicy: { label: 'Grant', value: 'GRANT' },
                  extensionConfig: true,
                  delegatedScopes:
                      appObj.code === 'LAUNCHER'
                          ? [
                                { label: 'Cert Install', value: 'CERT_INSTALL' },
                                { label: 'Managed Configurations', value: 'MANAGED_CONFIGURATIONS' },
                                { label: 'Block Uninstall', value: 'BLOCK_UNINSTALL' },
                                { label: 'Permission Grant', value: 'PERMISSION_GRANT' },
                                { label: 'Package Access', value: 'PACKAGE_ACCESS' },
                                { label: 'Enable System App', value: 'ENABLE_SYSTEM_APP' },
                                { label: 'Network Activity Logs', value: 'NETWORK_ACTIVITY_LOGS' },
                                { label: 'Security Logs', value: 'SECURITY_LOGS' }
                            ]
                          : [{ label: 'Cert Install', value: 'CERT_INSTALL' }]
              };
        let ind = applicationData.findIndex((app) => app.packageName === appObj.packageName);
        let appData = { ...appObject };
        appObj = { ...appObj, fieldsList: selected.appFields, kiosk: true, selected: true, active: true };
        let appDetailsArr = [];
        applicationData.map((detail) => {
            if (detail.packageName === appObj.packageName) return appDetailsArr.push(appObj);
            else if (detail.kiosk) return appDetailsArr.push({ ...detail, kiosk: false, active: false, selected: false });
            else return appDetailsArr.push({ ...detail, kiosk: false, active: false });
        });
        let application = applicationData.find((app) => app.packageName === appObj.packageName);
        let configs = [];
        if (application.managedConfigs?.length > 0) {
            configs = application.managedConfigs.filter(
                (config) =>
                    config.managedConfigId === appData?.[appObj.packageName]?.managedConfigId?.[0]?.managedConfigId ||
                    config.managedConfigId === appData?.[appObj.packageName]?.managedConfigId
            );
        }
        if (appData[appObj.packageName].managedConfigId?.config) {
            appData[appObj.packageName].managedConfiguration = appData[appObj.packageName].managedConfigId?.config;
        } else {
            appData[appObj.packageName].managedConfigId = configs?.length > 0 ? configs : [];
        }
        setApplicationObj(appData);
        let configsPacks = { ...configPackages };
        let allowedIndex = configsPacks.allowed.findIndex((allowed) => allowed.packageName === selApp.packageName);
        let blockedIndex = configsPacks.blocked.findIndex((blocked) => blocked.packageName === appObj?.packageName);
        let defaultIndex = configsPacks.default.findIndex((defaultApp) => defaultApp.packageName === appObj?.packageName);
        if (blockedIndex > -1) configsPacks.blocked.splice(blockedIndex, 1);
        if (allowedIndex > -1) configsPacks.allowed.splice(allowedIndex, 1);
        if (defaultIndex > -1) configsPacks.default.splice(defaultIndex, 1);
        if (!configsPacks.allowed.find((allowed) => allowed.packageName === appObj?.packageName))
            configsPacks.allowed.push({ ...appObj, default: true });
        setSelectedAppData({ ...appObj, selected: true, active: true });
        setActiveInd(ind);
        setShowKioskConfig(true);
        setApplicationData([...appDetailsArr]);
        setShowKioskAppConfig(true);
    };

    const handleCardClick = (option) => {
        if (validation.values.kioskCustomLauncherEnabled === option.value)
            validation.setValues({ ...validation.values, kioskCustomLauncherEnabled: '' });
        else validation.setValues({ ...validation.values, kioskCustomLauncherEnabled: option.value });
    };

    const handleAddFRPMail = (field) => {
        let isValid = true;
        if (field.validator) {
            let regex = field.validator === 'email' ? /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ : '';
            let pattern = new RegExp(regex);
            isValid = pattern.test(validation.values[field.value]?.trim());
            setIsInvalidMail(!isValid);
            if (!isValid) {
                return;
            } else if (validation.values[field.list]?.includes(validation.values[field.value]?.trim())) {
                toast.error(toastMessages.frpMailAlreadyExists);
                return;
            }
        }
        let listObj = [];
        if (validation.values[field.list]) listObj = [...validation.values[field.list], validation.values[field.value]?.trim()];
        else listObj.push(validation.values[field.value]?.trim());

        validation.setValues({ ...validation.values, [field.list]: [...listObj], [field.value]: '' });
    };

    const handleRemoveOneFRPMail = (ele, field) => {
        let listArr = [...validation.values[field.list]];
        listArr = listArr.filter((list) => list !== ele);
        validation.setValues({ ...validation.values, [field.list]: [...listArr] });
    };

    const togglePackage = useCallback(async () => {
        let configPacks = JSON.parse(JSON.stringify(configPackagesBackup));
        configPacks.default.forEach((pack) => (pack.checked = false));
        setConfigPackages(configPacks);
        setConfigPackagesBackup(configPacks);
        setShowPackageConfig(!showPackageConfig);
    }, [showPackageConfig]);

    const toggle = (appObj) => {
        appObj = { ...appObj, fieldsList: selected.fields, active: true };
        let appDetailsArr = [];
        applicationData.map((detail) => {
            if (detail.packageName === appObj.packageName) return appDetailsArr.push(appObj);
            else return appDetailsArr.push({ ...detail, active: false });
        });
        setSelectedAppData(appObj);
        setApplicationData([...appDetailsArr]);
    };

    const handleList = (e, item) => {
        let invalid = false;
        let launcherApplications = [...launcherAppsData];
        let configPacks = { ...configPackages };
        if (e.target.checked) {
            let findAllowedIndex = configPacks.allowed.findIndex((allow) => allow.packageName === item.packageName);
            let findIndex = configPacks.blocked.findIndex((block) => block.packageName === item.packageName);
            if (findIndex !== -1) configPacks.blocked.splice(findIndex, 1);
            if (findAllowedIndex !== -1) configPacks.allowed.splice(findAllowedIndex, 1, { ...item, default: true });
            else configPacks.allowed.push({ ...item, default: true });
        }
        if (e.target.checked) {
            let findIndex = configPacks.default.findIndex((defApp) => defApp.packageName === item.packageName);
            if (findIndex !== -1) configPacks.default.splice(findIndex, 1);
        } else {
            let findIndex = configPacks.default.findIndex((defApp) => defApp.packageName === item.packageName);
            let findAllowedIndex = configPacks.allowed.findIndex((allowedApp) => allowedApp.packageName === item.packageName);
            if (findAllowedIndex !== -1) configPacks.allowed.splice(findAllowedIndex, 1);
            if (findIndex === -1) configPacks.default.push(item);
        }
        setConfigPackagesBackup(configPacks);
        setConfigPackages(configPacks);
        applicationData.forEach((ele) => {
            if (ele.id === item.id) {
                if (ele.kiosk) invalid = true;
                else ele.selected = e.target.checked;
            }
            let launcherPackages = launcherApplications.map((launch) => launch.packageName);
            if (ele.selected) {
                if (launcherApplications?.length > 0) {
                    if (!launcherPackages.includes(ele.packageName))
                        launcherApplications.push({ ...ele, selected: ele.packageName !== launcherPackagename });
                } else launcherApplications.push({ ...ele, selected: true });
            } else {
                if (launcherPackages?.includes(ele.packageName)) {
                    let index = launcherApplications.findIndex((app) => app.packageName === ele.packageName);
                    launcherApplications.splice(index, 1);
                }
            }
        });
        setLauncherAppsData(launcherApplications);
        if (invalid) {
            toast.error(toastMessages.selectedInKiosk);
            return;
        }
        for (let [key, _value] of Object.entries(applicationObj)) {
            if (item.packageName === key) {
                delete applicationObj[key];
            }
        }
        let appData = { ...applicationObj };
        if (!appData[item.packageName] && e.target.checked)
            appData[item.packageName] = {
                autoUpdateMode: { label: 'High Priority', value: 'AUTO_UPDATE_HIGH_PRIORITY' },
                defaultPermissionPolicy: { label: 'Grant', value: 'GRANT' },
                delegatedScopes:
                    item.code === 'LAUNCHER'
                        ? [
                              { label: 'Cert Install', value: 'CERT_INSTALL' },
                              { label: 'Managed Configurations', value: 'MANAGED_CONFIGURATIONS' },
                              { label: 'Block Uninstall', value: 'BLOCK_UNINSTALL' },
                              { label: 'Permission Grant', value: 'PERMISSION_GRANT' },
                              { label: 'Package Access', value: 'PACKAGE_ACCESS' },
                              { label: 'Enable System App', value: 'ENABLE_SYSTEM_APP' }
                          ]
                        : [{ label: 'Cert Install', value: 'CERT_INSTALL' }],
                installType: { label: 'Force Installed', value: 'FORCE_INSTALLED' },
                personalAppInstallType: { label: 'Available', value: 'AVAILABLE' }
            };
        setApplicationObj({ ...appData });
        let selectedApps = applicationData.filter((app) => app.selected);
        let unSelectedApps = applicationData.filter((app) => !app.selected);
        let applications = selectedApps.concat(unSelectedApps);
        setApplicationData([...applications]);
    };

    const handleCancelWifiConfig = () => {
        setAddWifiConfig(false);
        validation.setValues({ ...validation.values, wifiName: '', ssid: '', HiddenSSID: false, security: '', passphrase: '' });
    };

    const handleAddWifiConfig = () => {
        let found = false;
        wifiConfiguration.forEach((config) => {
            if (config.ssid === validation.values.ssid) found = true;
        });
        if (found) {
            toast.error(toastMessages.existingSSID);
            return;
        }
        let configObj = {
            wifiName: validation.values.wifiName,
            ssid: validation.values.ssid,
            security: validation.values.security,
            passphrase: validation?.values?.passphrase,
            HiddenSSID: validation?.values?.HiddenSSID
        };
        setWifiConfiguration([...wifiConfiguration, configObj]);
        validation.setValues({ ...validation.values, wifiName: '', ssid: '', security: '', passphrase: '', HiddenSSID: false });
        setAddWifiConfig(false);
    };

    const handleUpdateWifiConfig = () => {
        let configList = [...wifiConfiguration];
        configList.forEach((conf) => {
            if (conf.ssid === selectedWifiConf.ssid) {
                conf.wifiName = validation.values.wifiName;
                conf.ssid = validation.values.ssid;
                conf.security = validation.values.security;
                conf.passphrase = validation?.values?.passphrase;
                conf.HiddenSSID = validation.values?.HiddenSSID;
            }
        });
        setAddWifiConfig(false);
        validation.setValues({ ...validation.values, wifiName: '', ssid: '', security: '', passphrase: '', HiddenSSID: false });
        setWifiConfiguration(configList);
    };

    const handleRemoveWifiConfig = (ele) => {
        let configList = [...wifiConfiguration];
        configList = configList.filter((add) => add.ssid !== ele.ssid);
        setWifiConfiguration(configList);
    };

    const handleEditWifiConfig = (obj) => {
        validation.setValues({
            ...validation.values,
            wifiName: obj.wifiName,
            ssid: obj.ssid,
            security: obj.security,
            passphrase: obj.passphrase,
            HiddenSSID: obj.HiddenSSID
        });
        setSelectedWifiConf(obj);
        setAddWifiConfig(true);
        setWifiMode('edit');
    };

    const checkSaveDisable = () => {
        let flag = false;
        Object.entries(applicationObj)?.forEach((obj) => {
            if (Number(obj?.[1]?.installPriority) > 10000) flag = true;
        });
        if (validation.values.systemUpdate?.value === 'WINDOWED') {
            let { days } =
                !validation.values.startDate || !validation.values.endDate
                    ? -1
                    : diffTwoDatesHrsMintsSeconds(validation.values.startDate, validation.values.endDate);
            if (!validation.values.startDate || !validation.values.endDate) flag = true;
            else if (days < 0) flag = true;
        }
        if (validation.values.odmFeatureType?.find((type) => type.value === 'acer') && !validation.values.acerodmfeatures) flag = true;
        if (validation.values.odmFeatureType?.find((type) => type.value === 'lava') && !validation.values.lavaodmfeatures) flag = true;
        if (validation.values.passwordConfiguration === 'complex') {
            flag =
                !validation.values.passwordMinimumLength ||
                !validation.values.passwordMinimumSymbols ||
                !validation.values.passwordMinimumLowerCase ||
                !validation.values.passwordMinimumUpperCase ||
                !validation.values.maximumFailedPasswordsForWipe ||
                !validation.values.passwordHistoryLength ||
                !validation.values.passwordExpirationTimeout;
        } else if (validation.values.passwordConfiguration === 'simple') {
            flag =
                !validation.values.passwordType?.value ||
                !validation.values.maximumFailedPasswordsForWipe ||
                !validation.values.passwordHistoryLength ||
                !validation.values.passwordExpirationTimeout;
        }
        return (
            !validation.values.policyName ||
            validation.values.policyName === '' ||
            validation.values.policyName?.length < 4 ||
            (validation.values.vpnConfigDisabled && (!validation.values.packageName || validation.values.packageName === '')) ||
            (validation.values.wifiSsidPolicyType?.value ? !validation.values.wifiSsids || validation.values.wifiSsids === '' : false) ||
            (validation.values.vpnConfigDisabled
                ? !validation.values.packageName ||
                  validation.values.packageName === '' ||
                  validation.values.packageName?.toUpperCase() === validation.values.packageName?.toLowerCase()
                : false) ||
            flag
        );
    };

    const handleConfig = (appObj) => {
        let ind = applicationData.findIndex((app) => app.packageName === appObj.packageName);
        if (applicationData[ind].selected) {
            let invalid = false;
            applicationData.forEach((ele) => {
                if (ele.packageName === appObj.packageName && ele.kiosk) {
                    invalid = true;
                }
            });
            if (invalid) {
                toast.error(toastMessages.selectedInKiosk);
                return;
            }
            appObj = { ...appObj, fieldsList: selected.fields, active: true };
            let appDetailsArr = [];
            applicationData.map((detail) => {
                if (detail.packageName === appObj.packageName) return appDetailsArr.push(appObj);
                else return appDetailsArr.push({ ...detail, active: false });
            });
            let appData = { ...applicationObj };
            let tracksString = appData[appObj.packageName]?.accessibleTrackIds
                ?.map((track) => {
                    return track?.trackId ? track.trackId : track;
                })
                ?.join(',');
            // eslint-disable-next-line array-callback-return
            appData[appObj.packageName].accessibleTrackIds = applicationData[ind]?.details?.tracks?.map((track) => {
                if (tracksString?.includes(track?.trackId)) return track;
            });
            let application = applicationData.find((app) => app.packageName === appObj.packageName);
            let configs = [];
            if (application.managedConfigs?.length > 0) {
                configs = application.managedConfigs.filter(
                    (config) =>
                        config.managedConfigId === appData?.[appObj.packageName]?.managedConfigId?.[0]?.managedConfigId ||
                        config.managedConfigId === appData?.[appObj.packageName]?.managedConfigId
                );
            }
            if (appData[appObj.packageName].managedConfigId?.config) {
                appData[appObj.packageName].managedConfiguration = appData[appObj.packageName].managedConfigId?.config;
            } else {
                appData[appObj.packageName].managedConfigId = configs?.length > 0 ? configs : [];
            }
            setApplicationObj({ ...applicationObj, ...appData });
            setSelectedAppData(appObj);
            setActiveInd(ind);
            setApplicationData([...appDetailsArr]);
            setShowConfig(true);
        } else toast.error(toastMessages.selectCheckbox);
    };

    const handleGoBack = () => {
        const applications = [...applicationData];
        delete applications[activeInd]?.active;
        setApplicationData([...applications]);
        setShowConfig(false);
        setShowKioskConfig(false);
        setShowKioskAppConfig(false);
    };

    const handleLauncherList = (e) => {
        let launcherApplications = [...launcherAppsData];
        launcherApplications.forEach((app) => {
            if (selectedAppData.packageName === app.packageName) app.selected = e.target.checked;
        });
        let selectedApps = launcherApplications.filter((app) => app.selected);
        let unSelectedApps = launcherApplications.filter((app) => !app.selected);
        launcherApplications = selectedApps.concat(unSelectedApps);
        setLauncherAppsData(launcherApplications);
    };

    const launcherApps = () => {
        return (
            <ApplicationsList
                cardData={launcherAppsData}
                code={code}
                useTooltip2={true}
                disableConfig={true}
                handleAppSelection={handleLauncherList}
                formType={formType}
            />
        );
    };

    const handleConfirmation = () => {
        let appObj = { ...applicationObj };
        if (details.key[0] === 'delegatedScopes') {
            let options = appObj[details.obj[0]].delegatedScopes.filter((detail) => detail.value !== details.e[details.e.length - 1].value);
            appObj[details.obj[0]].delegatedScopes = [...options];
        } else {
            delete appObj[details.obj[0]][details.key[0]];
        }
        if (appObj[selectedAppData.packageName]) appObj[selectedAppData?.packageName][details.key[0]] = details.e;
        else {
            appObj[selectedAppData?.packageName] = {};
            appObj[selectedAppData?.packageName][details.key[0]] = details.e;
        }
        setApplicationObj(appObj);
        setShowConfirmation(false);
    };

    const copyToClipboard = async (e, val, id) => {
        navigator.clipboard.writeText(val).then(() => {
            toast.success(toastMessages.packageNameCopied);
        });
    };

    const handleReloadApp = () => {
        let params = { enterpriseId: enterpriseObj.id, packageName: selectedAppData.packageName };
        api.create(url.APPLICATIONS, params).then((resp) => {
            resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            getApplications(enterpriseObj, { applications: formValues.applications }, selectedAppData);
        });
    };

    const handleSwitchPackage = (row, index, type) => {
        if (type === 'allow') {
            let packageArr = JSON.parse(JSON.stringify(configPackages.allowed));
            packageArr.splice(index, 1);
            setConfigPackages({ ...configPackages, allowed: packageArr, blocked: [...configPackages.blocked, row] });
        } else {
            let packageArr = JSON.parse(JSON.stringify(configPackages.blocked));
            packageArr.splice(index, 1);
            setConfigPackages({
                ...configPackages,
                blocked: packageArr,
                allowed: [...configPackages.allowed, { ...row, doNotDelete: true }]
            });
        }
    };

    const handleSwitchAppPackage = (row, index, type) => {
        if (type === 'enable') {
            let packageArr = JSON.parse(JSON.stringify(configApps.enable));
            packageArr.splice(index, 1);
            setConfigApps({ ...configApps, enable: packageArr, disable: [...configApps.disable, row] });
        } else {
            let packageArr = JSON.parse(JSON.stringify(configApps.disable));
            packageArr.splice(index, 1);
            setConfigApps({ ...configApps, disable: packageArr, enable: [...configApps.enable, row] });
        }
    };

    const handlePackageChange = (e, type) => {
        setConfPackageName({ ...confPackageName, [type]: e.target.value });
    };

    const handleAddPackage = (destination, checkIn) => {
        if (!confPackageName[destination] || confPackageName[destination] === '') return;
        let confPacks = { ...configPackages };
        if (confPacks[checkIn]?.filter((pack) => pack.packageName === confPackageName[destination])?.length > 0) {
            toast.error(destination === 'allowed' ? toastMessages.packageExistsInBlocked : toastMessages.packageExistsInAllowed);
        } else if (confPacks[destination]?.filter((pack) => pack.packageName === confPackageName[destination])?.length > 0) {
            toast.error(destination === 'allowed' ? toastMessages.packageExistsInAllowed : toastMessages.packageExistsInBlocked);
        } else {
            confPacks[destination].push({ packageName: confPackageName[destination]?.trim(), deletable: true });
        }
        setConfPackageName({ ...confPackageName, [destination]: '' });
        setConfigPackages(confPacks);
    };

    const handleAppPackageChange = (e, type) => {
        setConfApp({ ...confApp, [type]: e.target.value });
    };

    const handleAddAppPackageName = (destination, checkIn) => {
        if (!confApp[destination] || confApp[destination] === '') return;
        let confPacks = { ...configApps };
        if (confPacks[checkIn]?.filter((pack) => pack.packageName === confApp[destination])?.length > 0) {
            toast.error(destination === 'enable' ? toastMessages.packageExistsInDisable : toastMessages.packageExistsInEnable);
        } else if (confPacks[destination]?.filter((pack) => pack.packageName === confApp[destination])?.length > 0) {
            toast.error(destination === 'enable' ? toastMessages.packageExistsInEnable : toastMessages.packageExistsInDisable);
        } else {
            confPacks[destination].push({ packageName: confApp[destination]?.trim() });
        }
        setConfApp({ ...confApp, [destination]: '' });
        setConfigApps(confPacks);
    };

    const handlePackagesModal = (type, header) => {
        setShowPackageConfig(true);
        setPackageModalHeader(header);
        setPackageType(type);
    };

    const handleMoveToDefault = (app, type) => {
        let configPacks = JSON.parse(JSON.stringify(configPackages));
        let list = configPacks[type].filter((pack) => pack.packageName !== app.packageName);
        delete app.default;
        configPacks.default.push(app);
        setConfigPackages({ ...configPacks, [type]: [...list] });
        setConfigPackagesBackup({ ...configPacks, [type]: [...list] });
    };

    const handleTabContent = (type) => {
        return type === 'apps' ? (
            applicationData?.length > 0 ? (
                <div>
                    {!showConfig && (
                        <ApplicationsList
                            cardData={[...applicationData]}
                            toggle={toggle}
                            code={code}
                            handleAppSelection={handleList}
                            formType={formType}
                            handleConfig={handleConfig}
                        />
                    )}
                    {showConfig && (
                        <Row className="ms-1 mt-3 gap-3 align-items-center">
                            {applicationData.filter((ele) => ele.active && ele.selected)?.length > 0 && (
                                <div className="d-flex justify-content-between p-0">
                                    <div
                                        className="text-success fw-medium fs-14 d-flex align-items-center cursor-pointer"
                                        onClick={handleGoBack}
                                    >
                                        <i className="ri-arrow-left-line me-2" />
                                        Go Back
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {/* <Button size="sm" className="btn btn-success me-3" onClick={() => setOffcanvasModal(true)}>
                                            <span className="d-flex align-items-center">
                                                {applicationObj[appPackageName]?.mcmId ? (
                                                    <i className="ri-edit-box-line fs-16" />
                                                ) : (
                                                    <i className="ri-add-line fs-16"></i>
                                                )}
                                                <span className="ps-1">App Managed Configuration</span>
                                            </span>
                                        </Button> */}
                                        {formType !== 'view' && (
                                            <div className="d-flex">
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    outline
                                                    id="refresh"
                                                    className="ps-2 pe-2 pt-1 pb-1"
                                                    onClick={handleReloadApp}
                                                >
                                                    <span className="d-flex align-items-center fs-18">
                                                        <i className="ri-refresh-line align-bottom"></i>
                                                    </span>
                                                </Button>
                                                {Tooltip('refresh', 'Reload Data')}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <Row className="mb-2">
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={selectedAppData?.smallIconUrl} alt="icon" height={100} />
                                </div>
                            </Row>
                            <Row className="p-0 align-items-center">
                                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                                    <Label className="mb-0 fw-medium d-flex align-items-center justify-content-between">
                                        Application Name :
                                    </Label>
                                </Col>
                                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                                    <div className="input-group fw-medium">{selectedAppData?.title}</div>
                                </Col>
                            </Row>
                            <Row className="p-0 align-items-center mt-1">
                                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center justify-content-between">
                                    <Label className="mb-0 fw-medium d-flex align-items-center justify-content-between">
                                        Package Name :
                                    </Label>
                                </Col>
                                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                                    <div className="input-group fw-medium">
                                        {selectedAppData?.packageName}
                                        <span className="me-2">
                                            <i
                                                id={'copy'}
                                                onClick={(e) => copyToClipboard(e, selectedAppData?.packageName, 'copy')}
                                                className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                            ></i>
                                            {Tooltip('copy', 'Copy')}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            {selectedAppData?.active &&
                                selectedAppData?.selected &&
                                selectedAppData?.fieldsList?.length > 0 &&
                                selectedAppData.fieldsList.map((field, index) => {
                                    return (
                                        (field.hide
                                            ? field.hideOnApplication
                                                ? applicationData.find((ele) => ele.active && ele.selected)
                                                    ? applicationObj?.[
                                                          applicationData.find((ele) => ele.active && ele.selected)?.packageName
                                                      ]?.[field.hide]
                                                    : false
                                                : validation.values?.[field.hide] || validation.values?.[field.value]?.value === field.hide
                                            : field.hideWhile
                                            ? selectedAppData?.[field.checkHide] !== field.hideWhile
                                            : true) && (
                                            <Row className="p-0 align-items-center" key={index}>
                                                <DynamicComponents
                                                    field={field}
                                                    index={index}
                                                    code={code}
                                                    formType={formType}
                                                    selected={selected}
                                                    handleChange={handleChange}
                                                    validation={validation}
                                                    handleKeyDown={handleKeyDown}
                                                    applicationObj={applicationObj}
                                                    selectedAppData={selectedAppData}
                                                    handleAlert={handleAlert}
                                                    mode={mode}
                                                    launcherAppsData={launcherAppsData}
                                                    handleLauncherList={handleLauncherList}
                                                    configurations={configurations}
                                                    applicationData={applicationData}
                                                    assets={assets}
                                                    handleLaunchAppChange={handleLaunchAppChange}
                                                    handleLocationModeChange={handleLocationModeChange}
                                                    handleAddFRPMail={handleAddFRPMail}
                                                    isInvalidMail={isInvalidMail}
                                                    handleRemoveOneFRPMail={handleRemoveOneFRPMail}
                                                    handleCardClick={handleCardClick}
                                                    fp1={fp1}
                                                    fp2={fp2}
                                                />
                                            </Row>
                                        )
                                    );
                                })}
                        </Row>
                    )}
                </div>
            ) : (
                <NoRecordsFound text="Applications" />
            )
        ) : type === 'packages' ? (
            <>
                <Alert className="alert-borderless bg-soft-tFence mt-3 mb-0 text-dark" role="alert">
                    <span className="fw-semibold me-1">Note : </span>
                    <ol>
                        <li>
                            The applications included in the policy are permitted by default. To add any other app, please click the gears(
                            <i className="ri-settings-3-line link-info" />) symbol and select the desired apps to either allow or block.
                        </li>
                        <li>
                            Packages on the blocked list cannot be removed directly. Follow these steps for safe removal:
                            <ol type="a">
                                <li>Move the blocked package to the allowed packages list</li>
                                <li>Update the policy and make sure the changes are successfully applied on all the devices</li>
                                <li>Return to this screen and delete the package as required. </li>
                            </ol>
                        </li>
                    </ol>
                </Alert>
                <Row className="mt-4">
                    <Col sm={6} className="padding-right-10">
                        <Card>
                            <CardHeader className="bg-soft-success text-success border-radius-top-left-10 border-radius-top-right-10">
                                <div className="d-flex align-items-center justify-content-between">
                                    <span></span>
                                    <span>Allowed Packages ({configPackages?.allowed?.length})</span>
                                    <span>
                                        {formType !== 'view' && (
                                            <i
                                                className="ri-settings-3-line fs-18 link-success cursor-pointer"
                                                onClick={() => handlePackagesModal('allowed', 'Add to Allowed Packages')}
                                            />
                                        )}
                                    </span>
                                </div>
                            </CardHeader>
                            <CardBody className="height-500 overflow-y-auto">
                                {formType !== 'view' && (
                                    <div className="d-flex align-items-center mb-3 gap-2">
                                        <Input
                                            name={'allowedPackge'}
                                            id={'allowedPackge'}
                                            className={'form-control'}
                                            placeholder={'Enter Package'}
                                            type={'text'}
                                            onChange={(e) => handlePackageChange(e, 'allowed')}
                                            value={confPackageName.allowed}
                                        />
                                        <div className="d-flex align-items-center gap-2">
                                            <i
                                                id="add"
                                                className={'ri-add-circle-fill link-success font-size-30 cursor-pointer'}
                                                onClick={() => handleAddPackage('allowed', 'blocked')}
                                            />
                                            {Tooltip('add', 'Add Allowed Package')}
                                        </div>
                                    </div>
                                )}
                                <table className="table-responsive w-100">
                                    <tbody>
                                        {configPackages?.allowed?.length > 0 ? (
                                            configPackages?.allowed?.map((allow, ind) => (
                                                <tr key={ind}>
                                                    <td className="py-3 max-width-30 min-width-30 width-30">
                                                        <img
                                                            src={allow.smallIconUrl ? allow.smallIconUrl : AndroidIcon}
                                                            alt={'pack'}
                                                            width={30}
                                                        />
                                                    </td>
                                                    <td className="py-3 max-width-250 min-width-250 width-250">
                                                        <span className="fw-medium me-2">
                                                            {allow.title ? allow.title : allow.packageName}
                                                        </span>
                                                        {allow.default ? (
                                                            <span className={'lh-sm badge badge-soft-success fw-normal'}>
                                                                Default Package
                                                            </span>
                                                        ) : (
                                                            <span className={'lh-sm badge badge-soft-info fw-normal'}>Custom Package</span>
                                                        )}
                                                    </td>
                                                    <td className="py-3 max-width-50 min-width-50 width-50">
                                                        <div className="d-flex align-items-center gap-2">
                                                            <>
                                                                <i
                                                                    id={`switch-${ind}`}
                                                                    className={`${
                                                                        allow.default ? 'ri-information-line' : 'ri-arrow-left-right-line'
                                                                    } link-primary fs-18 cursor-pointer`}
                                                                    onClick={() =>
                                                                        allow.default ? undefined : handleSwitchPackage(allow, ind, 'allow')
                                                                    }
                                                                />
                                                                {Tooltip(
                                                                    `switch-${ind}`,
                                                                    allow.default
                                                                        ? 'Cannot move to block as it is a policy app'
                                                                        : 'Move to block list'
                                                                )}
                                                            </>
                                                            {!allow.default && (
                                                                <>
                                                                    <i
                                                                        id={`switchToDefault-${ind}`}
                                                                        className={`ri-delete-bin-line ${
                                                                            allow.doNotDelete && !allow.deletable
                                                                                ? 'text-muted'
                                                                                : 'link-success cursor-pointer'
                                                                        } fs-18`}
                                                                        onClick={() =>
                                                                            allow.doNotDelete && !allow.deletable
                                                                                ? undefined
                                                                                : handleMoveToDefault(allow, 'allowed')
                                                                        }
                                                                    />
                                                                    {Tooltip(
                                                                        `switchToDefault-${ind}`,
                                                                        allow.doNotDelete && !allow.deletable
                                                                            ? 'Please update the policy.'
                                                                            : 'Move to default list'
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td>No applications in allowed list.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={6} className="padding-left-10">
                        <Card>
                            <CardHeader className="bg-soft-danger text-danger border-radius-top-left-10 border-radius-top-right-10">
                                <div className="d-flex align-items-center justify-content-between">
                                    <span></span>
                                    <span>Blocked Packages ({configPackages?.blocked?.length})</span>
                                    <span>
                                        {formType !== 'view' && (
                                            <i
                                                className="ri-settings-3-line fs-18 link-danger cursor-pointer"
                                                onClick={() => handlePackagesModal('blocked', 'Add to Blocked Packages')}
                                            />
                                        )}
                                    </span>
                                </div>
                            </CardHeader>
                            <CardBody className="height-500 overflow-y-auto">
                                {formType !== 'view' && (
                                    <div className="d-flex align-items-center mb-3 gap-2">
                                        <Input
                                            name={'blockedPackge'}
                                            id={'blockedPackge'}
                                            className={'form-control'}
                                            placeholder={'Enter Package'}
                                            type={'text'}
                                            onChange={(e) => handlePackageChange(e, 'blocked')}
                                            value={confPackageName.blocked}
                                        />
                                        <div className="d-flex align-items-center gap-2">
                                            <i
                                                id="addBlocked"
                                                className={'ri-add-circle-fill link-danger font-size-30 cursor-pointer'}
                                                onClick={() => handleAddPackage('blocked', 'allowed')}
                                            />
                                            {Tooltip('addBlocked', 'Add Blocked Package')}
                                        </div>
                                    </div>
                                )}
                                <table className="table-responsive w-100">
                                    <tbody>
                                        {configPackages?.blocked?.length > 0 ? (
                                            configPackages?.blocked?.map((block, ind) => (
                                                <tr key={ind}>
                                                    <td className="py-3 max-width-30 min-width-30 width-30">
                                                        <img
                                                            src={block.smallIconUrl ? block.smallIconUrl : AndroidIcon}
                                                            alt={'pack'}
                                                            width={30}
                                                        />
                                                    </td>
                                                    <td className="py-3 max-width-250 min-width-250 width-250">
                                                        <span className="fw-medium">{block.title ? block.title : block.packageName}</span>
                                                    </td>
                                                    <td className="py-3 max-width-50 min-width-50 width-50">
                                                        <div className="d-flex align-items-center gap-2">
                                                            <>
                                                                <i
                                                                    id={`switchToAllow-${ind}`}
                                                                    className="ri-arrow-left-right-line link-primary fs-18 cursor-pointer"
                                                                    onClick={() => handleSwitchPackage(block, ind, 'block')}
                                                                />
                                                                {Tooltip(`switchToAllow-${ind}`, 'Move to allow list')}
                                                                {block.deletable && (
                                                                    <>
                                                                        <i
                                                                            id={`switchToDefaultFromBlock-${ind}`}
                                                                            className={
                                                                                'ri-delete-bin-line link-success cursor-pointer fs-18'
                                                                            }
                                                                            onClick={() => handleMoveToDefault(block, 'blocked')}
                                                                        />
                                                                        {Tooltip(`switchToDefaultFromBlock-${ind}`, 'Move to default list')}
                                                                    </>
                                                                )}
                                                            </>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td>No applications in blocked list.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        ) : type === 'enable_disable_apps' ? (
            <Row className="mt-4">
                <Col sm={6} className="padding-right-10">
                    <Card>
                        <CardHeader className="bg-soft-success text-success border-radius-top-left-10 border-radius-top-right-10">
                            <div className="d-flex align-items-center justify-content-center">
                                <span>Enable Apps ({configApps?.enable?.length})</span>
                            </div>
                        </CardHeader>
                        <CardBody className="height-500 overflow-y-auto">
                            {formType !== 'view' && (
                                <div className="d-flex align-items-center mb-3 gap-2">
                                    <Input
                                        name={'enable'}
                                        id={'enable'}
                                        className={'form-control'}
                                        placeholder={'Enter Package Name'}
                                        type={'text'}
                                        onChange={(e) => handleAppPackageChange(e, 'enable')}
                                        value={confApp.enable}
                                    />
                                    <div className="d-flex align-items-center gap-2">
                                        <i
                                            id="addEnable"
                                            className={'ri-add-circle-fill link-success font-size-30 cursor-pointer'}
                                            onClick={() => handleAddAppPackageName('enable', 'disable')}
                                        />
                                        {Tooltip('addEnable', 'Add Enable App')}
                                    </div>
                                </div>
                            )}
                            <table className="table-responsive w-100">
                                <tbody>
                                    {configApps?.enable?.length > 0 ? (
                                        configApps?.enable?.map((enable, ind) => (
                                            <tr key={ind}>
                                                <td className="py-3 max-width-30 min-width-30 width-30">
                                                    <img
                                                        src={enable.smallIconUrl ? enable.smallIconUrl : AndroidIcon}
                                                        alt={'pack'}
                                                        width={30}
                                                    />
                                                </td>
                                                <td className="py-3 max-width-250 min-width-250 width-250">
                                                    <span className="fw-medium">{enable.title ? enable.title : enable.packageName}</span>
                                                </td>
                                                <td className="py-3 max-width-50 min-width-50 width-50">
                                                    <i
                                                        id={`switch-${ind}`}
                                                        className={'ri-arrow-left-right-line  link-primary fs-18 cursor-pointer'}
                                                        onClick={() => handleSwitchAppPackage(enable, ind, 'enable')}
                                                    />
                                                    {Tooltip(`switch-${ind}`, 'Move to Disable')}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td>No apps found in enable list.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={6} className="padding-left-10">
                    <Card>
                        <CardHeader className="bg-soft-danger text-danger border-radius-top-left-10 border-radius-top-right-10">
                            <div className="d-flex align-items-center justify-content-center">
                                <span>Disable Apps ({configApps?.disable?.length})</span>
                            </div>
                        </CardHeader>
                        <CardBody className="height-500 overflow-y-auto">
                            {formType !== 'view' && (
                                <div className="d-flex align-items-center mb-3 gap-2">
                                    <Input
                                        name={'disable'}
                                        id={'disable'}
                                        className={'form-control'}
                                        placeholder={'Enter Package Name'}
                                        type={'text'}
                                        onChange={(e) => handleAppPackageChange(e, 'disable')}
                                        value={confApp.disable}
                                    />
                                    <div className="d-flex align-items-center gap-2">
                                        <i
                                            id="addDisable"
                                            className={'ri-add-circle-fill link-danger font-size-30 cursor-pointer'}
                                            onClick={() => handleAddAppPackageName('disable', 'enable')}
                                        />
                                        {Tooltip('addDisable', 'Add Disable App')}
                                    </div>
                                </div>
                            )}
                            <table className="table-responsive w-100">
                                <tbody>
                                    {configApps?.disable?.length > 0 ? (
                                        configApps?.disable?.map((disable, ind) => (
                                            <tr key={ind}>
                                                <td className="py-3 max-width-30 min-width-30 width-30">
                                                    <img
                                                        src={disable.smallIconUrl ? disable.smallIconUrl : AndroidIcon}
                                                        alt={'pack'}
                                                        width={30}
                                                    />
                                                </td>
                                                <td className="py-3 max-width-250 min-width-250 width-250">
                                                    <span className="fw-medium">{disable.title ? disable.title : disable.packageName}</span>
                                                </td>
                                                <td className="py-3 max-width-50 min-width-50 width-50">
                                                    <i
                                                        id={`switchToAllow-${ind}`}
                                                        className="ri-arrow-left-right-line link-primary fs-18 cursor-pointer"
                                                        onClick={() => handleSwitchAppPackage(disable, ind, 'disable')}
                                                    />
                                                    {Tooltip(`switchToAllow-${ind}`, 'Move to allow')}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td>No apps found in disable list.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        ) : (
            launcherApps()
        );
    };

    const tabsList = [
        { tabID: 'apps', tabName: 'Applications', tabContent: handleTabContent('apps'), icon: 'ri-apps-fill' },
        /*
         * {
         *     tabID: 'launcher_apps',
         *     tabName: 'Launcher Apps',
         *     tabContent: handleTabContent('launcher_apps'),
         *     icon: 'ri-home-gear-line'
         * },
         */
        {
            tabID: 'pacakges',
            tabName: 'Packages',
            tabContent: handleTabContent('packages'),
            icon: 'ri-file-text-line'
        },
        {
            tabID: 'enable_disable_apps',
            tabName: 'Enable/Disable Apps',
            hide: !tenant.AE_ENABLE_DISABLE_PACKAGE,
            tabContent: handleTabContent('enable_disable_apps'),
            icon: 'ri-android-line'
        }
    ];

    /*
     * const handleSelect = (e) => {
     *     let index = applicationData.findIndex((app) => app.packageName === e.packageName);
     *     let appObj = {};
     *     Object.entries(applicationObj).forEach((obj) => {
     *         if (obj[1].installType?.value !== 'KIOSK') {
     *             appObj = { ...appObj, [obj[0]]: obj[1] };
     *         }
     *     });
     *     appObj[e.packageName] = {
     *         installType: { label: 'Kiosk', value: 'KIOSK' },
     *         autoUpdateMode: { label: 'High Priority', value: 'AUTO_UPDATE_HIGH_PRIORITY' },
     *         defaultPermissionPolicy: { label: 'Grant', value: 'GRANT' },
     *         delegatedScopes: [{ label: 'Cert Install', value: 'CERT_INSTALL' }]
     *     };
     *     // setSelectedInd(index);
     *     handleListKiosk({ target: { checked: true } }, e, index);
     *     // setSelectedAppInKiosk(e);
     *     setApplicationObj(appObj);
     * };
     */

    const handleRemoveSetupAction = (ind) => {
        let actions = [...validation.values.setupActions];
        let appObj = { ...applicationObj };
        delete appObj[actions[ind]?.launchApp];
        let appData = [...applicationData];
        let launchApp = '';
        appData.forEach((app) => {
            if (app.packageName === actions[ind]?.launchApp) {
                app.setupAction = false;
                app.selected = false;
                app.active = false;
                launchApp = app;
            }
        });
        setApplicationData([...appData]);
        setApplicationObj(appObj);
        let configs = { ...configPackages };
        let blockedIndex = configs.blocked.findIndex((blocked) => blocked.packageName === launchApp?.packageName);
        let allowedIndex = configs.allowed.findIndex((allowed) => allowed.packageName === launchApp?.packageName);
        if (allowedIndex > -1) configs.allowed.splice(allowedIndex, 1);
        if (blockedIndex > -1) configs.blocked.splice(blockedIndex, 1);
        if (!configs.default.find((allowed) => allowed.packageName === launchApp?.packageName)) configs.default.push({ ...launchApp });
        setConfigPackages(configs);
        setConfigPackagesBackup(configs);
        actions = actions.filter((_action, index) => index !== ind);
        validation.setValues({ ...validation.values, setupActions: [...actions] });
    };

    const handleEditSetupAction = (action, ind) => {
        setSelectedActionInd(ind);
        setSelectedSetupAction(action);
        let launchApp = applicationData.find((app) => app.packageName === action.launchApp);
        validation.setValues({ ...validation.values, title: action.title, description: action.description, launchApp: launchApp });
        setActionMode('edit');
        setShowAddAction(true);
    };

    const handleCancelSetupAction = () => {
        validation.setValues({ ...validation.values, title: '', description: '', launchApp: '' });
        setActionMode('add');
        setShowAddAction(false);
    };

    const handleUpdateSetupAction = () => {
        let actions = [...validation.values.setupActions];
        let appObj = { ...applicationObj };
        delete appObj[actions[selectedActionInd]?.launchApp];
        let appData = [...applicationData];
        appData.forEach((app) => {
            if (app.packageName === validation.values.launchApp?.packageName) {
                app.setupAction = true;
                app.selected = true;
                app.active = true;
            } else if (app.setupAction) {
                delete app.setupAction;
                delete app.active;
                delete app.selected;
            }
        });
        setApplicationData([...appData]);
        appObj[validation.values.launchApp?.packageName] = {
            installType: { label: 'Required for Set Up', value: 'REQUIRED_FOR_SETUP' },
            autoUpdateMode: { label: 'High Priority', value: 'AUTO_UPDATE_HIGH_PRIORITY' },
            defaultPermissionPolicy: { label: 'Grant', value: 'GRANT' },
            delegatedScopes: [{ label: 'Cert Install', value: 'CERT_INSTALL' }]
        };
        setApplicationObj(appObj);
        actions[selectedActionInd] = {
            title: validation.values.title,
            description: validation.values.description,
            launchApp: validation.values.launchApp?.packageName
        };
        validation.setValues({ ...validation.values, setupActions: actions, title: '', description: '', launchApp: '' });
        let configs = { ...configPackages };
        let allowedIndex = configs.allowed.findIndex((allowed) => allowed.packageName === selectedSetupAction.launchApp);
        let blockedIndex = configs.blocked.findIndex((blocked) => blocked.packageName === validation.values.launchApp?.packageName);
        let defaultIndex = configs.default.findIndex((defaultApp) => defaultApp.packageName === validation.values.launchApp?.packageName);
        if (allowedIndex > -1) configs.allowed.splice(allowedIndex, 1);
        if (blockedIndex > -1) configs.blocked.splice(blockedIndex, 1);
        if (defaultIndex > -1) configs.default.splice(defaultIndex, 1);
        if (!configs.allowed.find((allowed) => allowed.packageName === validation.values.launchApp?.packageName))
            configs.allowed.push({ ...validation.values.launchApp, default: true });
        setShowAddAction(false);
    };

    const handleAddSetupAction = () => {
        let actions = [];
        if (validation.values.setupActions && validation.values.setupActions?.length > 0) {
            actions = [...validation.values.setupActions];
        }
        actions.push({
            title: validation.values.title,
            description: validation.values.description,
            launchApp: validation.values.launchApp?.packageName
        });
        let appObj = { ...applicationObj };
        const delegatedScopes =
            validation.values.launchApp.packageName === launcherPackagename
                ? [
                      { label: 'Cert Install', value: 'CERT_INSTALL' },
                      { label: 'Managed Configurations', value: 'MANAGED_CONFIGURATIONS' },
                      { label: 'Block Uninstall', value: 'BLOCK_UNINSTALL' },
                      { label: 'Permission Grant', value: 'PERMISSION_GRANT' },
                      { label: 'Package Access', value: 'PACKAGE_ACCESS' },
                      { label: 'Enable System App', value: 'ENABLE_SYSTEM_APP' },
                      { label: 'Network Activity Logs', value: 'NETWORK_ACTIVITY_LOGS' },
                      { label: 'Security Logs', value: 'SECURITY_LOGS' },
                      { label: 'Cert Selection', value: 'CERT_SELECTION' }
                  ]
                : [
                      { label: 'Cert Install', value: 'CERT_INSTALL' },
                      { label: 'Managed Configurations', value: 'MANAGED_CONFIGURATIONS' },
                      { label: 'Block Uninstall', value: 'BLOCK_UNINSTALL' },
                      { label: 'Permission Grant', value: 'PERMISSION_GRANT' },
                      { label: 'Package Access', value: 'PACKAGE_ACCESS' },
                      { label: 'Cert Selection', value: 'CERT_SELECTION' }
                  ];
        appObj[validation.values.launchApp.packageName] = {
            installType: { label: 'Required for Set Up', value: 'REQUIRED_FOR_SETUP' },
            autoUpdateMode: { label: 'High Priority', value: 'AUTO_UPDATE_HIGH_PRIORITY' },
            defaultPermissionPolicy: { label: 'Grant', value: 'GRANT' },
            delegatedScopes: delegatedScopes
        };
        let appData = [...applicationData];
        appData.forEach((app) => {
            if (app.packageName === validation.values.launchApp?.packageName) {
                app.setupAction = true;
                app.selected = true;
                app.active = true;
            } else if (app.setupAction) {
                delete app.setupAction;
                delete app.active;
                delete app.selected;
            }
        });
        setApplicationData([...appData]);
        setApplicationObj(appObj);
        let configs = { ...configPackages };
        let blockedIndex = configs.blocked.findIndex((blocked) => blocked.packageName === validation.values.launchApp?.packageName);
        let defaultIndex = configs.default.findIndex((defaultApp) => defaultApp.packageName === validation.values.launchApp?.packageName);
        if (!configs.allowed.find((allowed) => allowed.packageName === validation.values.launchApp?.packageName))
            configs.allowed.push({ ...validation.values.launchApp, default: true });
        if (blockedIndex > -1) configs.blocked.splice(blockedIndex, 1);
        if (defaultIndex > -1) configs.default.splice(defaultIndex, 1);
        setConfigPackages(configs);
        setConfigPackagesBackup(configs);
        validation.setValues({ ...validation.values, setupActions: [...actions], title: '', description: '', launchApp: '' });
        setShowAddAction(false);
    };

    const handleKioskAppSelection = (kiosk, index) => {
        let found = false;
        Object.entries(applicationObj).forEach((obj) => {
            if (obj[1].installType === 'KIOSK' || obj[1].installType?.value === 'KIOSK') found = true;
        });
        if (found) {
            setKioskDetails({ kiosk: kiosk, index: index });
            setShowKioskConfirmation(true);
        } else {
            setKioskDetails('');
            setShowKioskConfirmation(false);
            handleKioskAppConfimation(kiosk, index);
        }
    };

    const handleKioskAppConfimation = (kiosk, index) => {
        setSelectedLauncherApp('');
        let appObject = {};
        let packageName = '';
        Object.entries(applicationObj).forEach((obj) => {
            if (obj[1].installType !== 'KIOSK' && obj[1].installType?.value !== 'KIOSK') appObject = { ...appObject, [obj[0]]: obj[1] };
            else packageName = obj[0];
        });
        setApplicationObj(appObject);
        let appsArr = JSON.parse(JSON.stringify(applicationData));
        appsArr.forEach((app) => {
            if (packageName && app.packageName === packageName) app.selected = false;
            app.kiosk = false;
        });
        setApplicationData(appsArr);
        let kioskAppsArr = JSON.parse(JSON.stringify(kioskApps));
        kioskAppsArr.forEach((app, ind) => {
            if (index === ind) {
                let applicationObjData = { ...applicationObj };
                delete applicationObjData[selectedLauncherApp.packageName];
                setApplicationObj(applicationObjData);
                if (!app.checked && kiosk.showKioskConfig) setShowKioskSettings(true);
                else {
                    validation.setValues({
                        ...validation.values,
                        powerButtonActions: formValues?.powerButtonActions ? formValues?.powerButtonActions : null,
                        systemErrorWarnings: formValues?.systemErrorWarnings ? formValues.systemErrorWarnings : null,
                        systemNavigation: formValues?.systemNavigation ? formValues.systemNavigation : null,
                        statusBar: formValues?.statusBar ? formValues.statusBar : null,
                        deviceSettings: formValues?.deviceSettings ? formValues.deviceSettings : null
                    });
                    setShowKioskSettings(false);
                }
                app.checked = !app.checked;
            } else app.checked = false;
        });
        setKioskApps(kioskAppsArr);
        setShowKioskConfirmation(false);
        setKioskDetails('');
    };

    const handleSelectedTab = (tab) => {
        const applications = [...applicationData];
        delete applications[activeInd]?.active;
        setApplicationData([...applications]);
        setShowConfig(false);
        setShowKioskConfig(false);
        setShowKioskAppConfig(false);
        setSelectedTab(tab);
    };

    const handleKioskCardClick = (kiosk, ind) => {
        if (selectedLauncherApp && selectedLauncherApp !== '') handleConfigKiosk({ ...selectedLauncherApp, sel: true });
        if (kiosk.checked && kiosk.showConfig) setShowKioskAppConfig(true);
    };

    const handleChangeKioskApp = () => {
        // To remove lint error
    };

    const handleReject = () => {
        setShowRejectModal(true);
    };

    const handlePackageSearch = (e) => {
        setSearchPackage(e.target.value);
        let configPacks = JSON.parse(JSON.stringify(configPackagesBackup));
        configPacks.default = configPacks?.default?.filter((conf) => conf.title?.toLowerCase().includes(e.target.value?.toLowerCase()));
        setConfigPackages({ ...configPacks });
    };

    const handlePackageSelect = (e, app, ind) => {
        let configPacks = JSON.parse(JSON.stringify(configPackagesBackup));
        configPacks.default.forEach((defApp) => {
            if (defApp.packageName === app.packageName) defApp.checked = e.target.checked;
        });
        setConfigPackagesBackup(configPacks);
        let configs = JSON.parse(JSON.stringify(configPackages));
        configs.default.forEach((defApp) => {
            if (defApp.packageName === app.packageName) defApp.checked = e.target.checked;
        });
        setConfigPackages(configs);
    };

    const handleAddPackConfigs = () => {
        let selectedPacks = configPackagesBackup.default?.filter((conf) => conf.checked);
        let unselectedPacks = configPackagesBackup.default?.filter((conf) => !conf.checked);
        if (packageType === 'blocked') selectedPacks = selectedPacks.filter((pack) => pack.code !== 'LAUNCHER');
        selectedPacks.forEach((pack) => {
            delete pack.checked;
            pack.deletable = packageType === 'blocked' ? true : undefined;
        });
        setConfigPackagesBackup({
            allowed: packageType === 'allowed' ? [...configPackagesBackup.allowed, ...selectedPacks] : configPackagesBackup.allowed,
            default: unselectedPacks,
            blocked: packageType === 'blocked' ? [...configPackagesBackup.blocked, ...selectedPacks] : configPackagesBackup.blocked
        });
        setConfigPackages({
            allowed: packageType === 'allowed' ? [...configPackagesBackup.allowed, ...selectedPacks] : configPackagesBackup.allowed,
            default: unselectedPacks,
            blocked: packageType === 'blocked' ? [...configPackagesBackup.blocked, ...selectedPacks] : configPackagesBackup.blocked
        });
        setShowPackageConfig(false);
    };

    return (
        <AddPolicyHTML
            loading={loading}
            code={code}
            formType={formType}
            enterpriseObj={enterpriseObj}
            polType={polType}
            protectedPolicy={protectedPolicy}
            tenant={tenant}
            validation={validation}
            selected={selected}
            profileDetails={profileDetails}
            addWifiConfig={addWifiConfig}
            showAddAction={showAddAction}
            setupExisting={setupExisting}
            handleCancelSetupAction={handleCancelSetupAction}
            actionMode={actionMode}
            showKioskAppConfig={showKioskAppConfig}
            kioskApps={kioskApps}
            showKioskSettings={showKioskSettings}
            selectedLauncherApp={selectedLauncherApp}
            showAppError={showAppError}
            showKioskConfig={showKioskConfig}
            selectedAppData={selectedAppData}
            wifiConfiguration={wifiConfiguration}
            wifiMode={wifiMode}
            tabsList={tabsList?.filter((tab) => !tab.hide)}
            showConfig={showConfig}
            formTypeAndId={formTypeAndId}
            showKioskConfirmation={showKioskConfirmation}
            showRejectModal={showRejectModal}
            showConfirmation={showConfirmation}
            alertModal={alertModal}
            showPackageConfig={showPackageConfig}
            setProtectedPolicy={setProtectedPolicy}
            setActionMode={setActionMode}
            setShowAddAction={setShowAddAction}
            handleUpdateSetupAction={handleUpdateSetupAction}
            handleAddSetupAction={handleAddSetupAction}
            handleKioskAppSelection={handleKioskAppSelection}
            handleChangeKioskApp={handleChangeKioskApp}
            handleKioskCardClick={handleKioskCardClick}
            applicationData={applicationData}
            handleConfigKiosk={handleConfigKiosk}
            setWifiMode={setWifiMode}
            setAddWifiConfig={setAddWifiConfig}
            handleRemoveWifiConfig={handleRemoveWifiConfig}
            handleEditWifiConfig={handleEditWifiConfig}
            securityOptions={securityOptions}
            handleCancelWifiConfig={handleCancelWifiConfig}
            handleUpdateWifiConfig={handleUpdateWifiConfig}
            handleAddWifiConfig={handleAddWifiConfig}
            handleSelectedTab={handleSelectedTab}
            handleReject={handleReject}
            handleSubmit={handleSubmit}
            checkSaveDisable={checkSaveDisable}
            handleKioskAppConfimation={handleKioskAppConfimation}
            kioskDetails={kioskDetails}
            handleProfileDetail={handleProfileDetail}
            handleRemoveSetupAction={handleRemoveSetupAction}
            handleEditSetupAction={handleEditSetupAction}
            setKioskDetails={setKioskDetails}
            setShowKioskConfirmation={setShowKioskConfirmation}
            handleAddPackConfigs={handleAddPackConfigs}
            packageType={packageType}
            packageModalHeader={packageModalHeader}
            handleChangeValue={handleChangeValue}
            setSelectedToggleDetails={setSelectedToggleDetails}
            setAlertModal={setAlertModal}
            setShowConfirmation={setShowConfirmation}
            handleConfirmation={handleConfirmation}
            remarks={remarks}
            setShowRejectModal={setShowRejectModal}
            setRemarks={setRemarks}
            togglePackage={togglePackage}
            details={details}
            selectedToggleDetails={selectedToggleDetails}
            handleGoBack={handleGoBack}
            copyToClipboard={copyToClipboard}
            assets={assets}
            handleLaunchAppChange={handleLaunchAppChange}
            handleLocationModeChange={handleLocationModeChange}
            handleAddFRPMail={handleAddFRPMail}
            isInvalidMail={isInvalidMail}
            handleRemoveOneFRPMail={handleRemoveOneFRPMail}
            handleCardClick={handleCardClick}
            fp1={fp1}
            fp2={fp2}
            handleAlert={handleAlert}
            mode={mode}
            configurations={configurations}
            handleKeyDown={handleKeyDown}
            applicationObj={applicationObj}
            handleChange={handleChange}
            searchPackage={searchPackage}
            handlePackageSearch={handlePackageSearch}
            configPackages={configPackages}
            handlePackageSelect={handlePackageSelect}
        />
    );
};

export default AddPolicy;
