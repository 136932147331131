import { Circle, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useState } from 'react';
import Geocode from 'react-geocode';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import greenMarker from '../../../assets/images/common/svg/common/greenmarker.svg';
import redMarker from '../../../assets/images/common/svg/common/redmarker.svg';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';

const TvMapView = () => {
    const urlconf = useEnv();

    Geocode.setApiKey(urlconf.REACT_APP_MAP_TOKEN);
    Geocode.setLanguage('en');
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: urlconf.REACT_APP_MAP_TOKEN
    });
    let history = useHistory();
    let api = new APIClient();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let recordID = formTypeAndId['recordID'];

    const [loading, setLoading] = useState(false);
    const mapStyles = { width: '98%', height: '95%' };
    const [mapObj, setMapObj] = useState(null);
    const [deviceData, setDeviceData] = useState({ serial: '', center: { lat: 17.43274, lng: 78.3843461 }, geoRadius: 0 });
    const [policy, setPolicy] = useState({ center: { lat: 17.43274, lng: 78.3843461 }, geoRadius: 0 });

    const getAddress = async (latLng) => {
        let addressString = '';
        await Geocode.fromLatLng(latLng.lat, latLng.lng)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.results) {
                    resp.results[0].address_components.forEach((addressObj, ind) => {
                        addressString = addressString + (ind !== 0 ? ', ' : '') + addressObj.long_name?.toString();
                    });
                }
            })
            .catch((_err) => {
                toast.error(toastMessages.addressNotFound);
            });

        return addressString;
    };

    const getDeviceLocation = (map) => {
        setLoading(true);
        api.get(url.TV_DEVICES + '/lastlocation/' + recordID)
            .then(async (resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    let configuredLocation = resp.data?.lastSeenLocation?.policy?.config?.GEOFENCE?.restrictions?.zones[0];
                    let lastSeen = resp.data.lastSeenLocation;
                    let configuredAddress = configuredLocation?.latitude
                        ? await getAddress({
                              lat: configuredLocation?.latitude ? Number(configuredLocation?.latitude).toFixed(7) : 17.43274,
                              lng: configuredLocation?.longitude ? Number(configuredLocation?.longitude).toFixed(7) : 78.3843461
                          })
                        : '—';
                    let deviceAddress = lastSeen.latitude
                        ? await getAddress({
                              lat: lastSeen.latitude ? Number(lastSeen.latitude).toFixed(7) : 17.43274,
                              lng: lastSeen.longitude ? Number(lastSeen.longitude).toFixed(7) : 78.3843461
                          })
                        : '—';
                    setPolicy({
                        geoRadius: configuredLocation?.radius,
                        center: {
                            lat: configuredLocation?.latitude ? Number(configuredLocation?.latitude) : 17.43274,
                            lng: configuredLocation?.longitude ? Number(configuredLocation?.longitude) : 78.3843461
                        },
                        address: configuredAddress
                    });
                    setDeviceData({
                        serial: resp.data.serial,
                        geoRadius: configuredLocation?.radius,
                        center: {
                            lat: lastSeen.latitude ? Number(lastSeen.latitude) : 17.43274,
                            lng: lastSeen.longitude ? Number(lastSeen.longitude) : 78.3843461
                        },
                        policy: resp.data.policyName ? resp.data.policyName : '—',
                        group: resp.data?.groupName ? resp.data?.groupName : '—',
                        status: lastSeen.status,
                        showCircle: lastSeen.status === 'OUT',
                        address: deviceAddress
                    });

                    const bounds = new window.google.maps.Circle({
                        radius: configuredLocation?.radius,
                        center: {
                            lat: lastSeen.latitude ? Number(lastSeen.latitude) : 17.43274,
                            lng: lastSeen.longitude ? Number(lastSeen.longitude) : 78.3843461
                        }
                    });
                    map.fitBounds(bounds);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const onLoad = useCallback(async function callback(map) {
        getDeviceLocation(map);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMapObj(null);
    }, []);

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle={'Tv Map View'} history={history} homeLink="Dashboard" showBack={true} backLink="atvdevices" />
                    {deviceData.address !== '—' ? (
                        <Card className="card-height-100">
                            <CardHeader>
                                <Row>
                                    <Col xxl={2} xl={2} lg={3} md={4} sm={6} xs={6} xxs={12}>
                                        <div className="text-grey-text">Serial Number</div>
                                        <div className="fw-medium">{deviceData.serial}</div>
                                    </Col>
                                    <Col xxl={2} xl={2} lg={3} md={4} sm={6} xs={6} xxs={12}>
                                        <div className="text-grey-text">Policy</div>
                                        <div className="fw-medium">{deviceData.policy}</div>
                                    </Col>
                                    <Col xxl={2} xl={2} lg={3} md={4} sm={6} xs={6} xxs={12}>
                                        <div className="text-grey-text">Device Group</div>
                                        <div className="fw-medium">{deviceData.group}</div>
                                    </Col>
                                    <Col xxl={3} xl={3} lg={3} md={5} sm={6} xs={6} xxs={12}>
                                        <div className="text-grey-text">Configured Location</div>
                                        <div className="fw-medium">
                                            <i className="ri-checkbox-blank-circle-fill link-success me-1" />
                                            {policy.address}
                                        </div>
                                    </Col>
                                    {deviceData.status === 'OUT' && (
                                        <Col xxl={3} xl={3} lg={4} md={5} sm={6} xs={6} xxs={12}>
                                            <div className="text-grey-text">Violated Location</div>
                                            <div className="fw-medium">
                                                <i className="ri-checkbox-blank-circle-fill link-danger me-1" />
                                                {deviceData.address}
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </CardHeader>
                            <CardBody className="heightVh-60">
                                {isLoaded && (
                                    <GoogleMap
                                        mapContainerStyle={mapStyles}
                                        center={deviceData.center}
                                        zoom={16}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                    >
                                        <Marker
                                            position={deviceData.center}
                                            icon={{
                                                url: greenMarker,
                                                scaledSize: new window.google.maps.Size(64, 64)
                                            }}
                                        />
                                        <Circle
                                            center={deviceData.center}
                                            radius={deviceData.geoRadius}
                                            options={{
                                                fillColor: '#59AF5067',
                                                color: '#0D807B',
                                                strokeColor: '#0D807B',
                                                strokeOpacity: 0.8,
                                                strokeWeight: 2,
                                                fillOpacity: 0.35
                                            }}
                                        />
                                        {deviceData.showCircle && (
                                            <Marker
                                                position={policy.center}
                                                icon={{
                                                    url: redMarker,
                                                    scaledSize: new window.google.maps.Size(64, 64)
                                                }}
                                            />
                                        )}
                                        {deviceData.showCircle && (
                                            <Circle
                                                center={policy.center}
                                                radius={policy.geoRadius}
                                                options={{
                                                    fillColor: '#D8202067',
                                                    color: '#D82020',
                                                    strokeColor: '#D82020',
                                                    strokeOpacity: 0.8,
                                                    strokeWeight: 2,
                                                    fillOpacity: 0.35
                                                }}
                                            />
                                        )}
                                    </GoogleMap>
                                )}
                                {/* <Map
                                zoom={16}
                                style={mapStyles}
                                google={props.google}
                                onClick={null}
                                onTilesloaded={setCurrentLocation}
                                initialCenter={deviceData.center}
                                center={deviceData.center}
                                keyboardShortcuts={false}
                                streetViewControl={false}
                            >
                                <Marker icon={tv} position={deviceData.center} />
                                <Circle
                                    radius={deviceData.geoRadius}
                                    center={deviceData.center}
                                    fillColor="#59AF5067"
                                    color="#0D807B"
                                    strokeColor="#0D807B"
                                />

                                {deviceData.showCircle && <Marker icon={home} position={policy.center} />}
                                {deviceData.showCircle && (
                                    <Circle
                                        radius={policy.geoRadius}
                                        center={policy.center}
                                        fillColor="#D8202067"
                                        color="#D82020"
                                        strokeColor="#D82020"
                                    />
                                )}
                            </Map> */}
                            </CardBody>
                        </Card>
                    ) : (
                        <Card>
                            <NoRecordsFound />
                        </Card>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TvMapView;
