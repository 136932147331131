import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, convertBytesTo, getDate } from '../../../Components/Common/Util';
import { fileTypeValidations, priviliges } from '../../../Components/constants/constants';
import polDelete from '../../../assets/images/common/png/common/policyDelete.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { assetsSchema } from '../../Functions/TableSchema';
import AddAsset from './AddAsset';
import axios from 'axios';

const Assets = () => {
    document.title = 'Assets';
    const urlconf = useEnv();
    let history = useHistory();
    const api = new APIClient();
    const [assetsTableSchema, setAssetsTableSchema] = useState([]);
    const [assets, setAssets] = useState([]);
    const [assetsBackup, setAssetsBackup] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [mode, setMode] = useState();
    const [offcanvasModal, setOffcanvasModal] = useState(false);
    const [currentDetailsObj, setCurrentDetailsObj] = useState();
    const [errors, setErrors] = useState({});
    const [canvasLoader, setCanvasLoader] = useState(false);

    useEffect(() => {
        getAssets();
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.ASSET_EDITOR);
        let schema = [...assetsSchema];
        if (findPriv) schema = assetsSchema.filter((policy, index) => index < assetsSchema.length - 1);
        setAssetsTableSchema([...schema]);
    }, []);

    useEffect(() => {
        if (!offcanvasModal) document.title = 'Assets';
    }, [offcanvasModal]);

    const getAssets = () => {
        setLoading(true);
        let params = { page: 1, size: 2000 };
        api.get(url.ASSETS, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((ele) => {
                        ele.assetSize = convertBytesTo(ele.fileSize);
                        ele.modifiedBy = ele.lastmodifiedBy ? ele.lastmodifiedBy : 'Tectoro';
                        ele.modifiedDate = ele.lastmodifiedTime ? getDate(ele.lastmodifiedTime) : '—';
                        ele.policies = ele.policyNames?.length > 0 ? ele.policyNames?.join(', ') : '—';
                        ele.rowDelete = ele.policyNames?.length !== 0;
                    });
                    setTotalRecords(resp.totalRecords);
                    setAssetsBackup(resp.data);
                    setAssets(resp.data);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleAddNew = (row) => {
        setMode('add');
        setOffcanvasModal(true);
        setSelectedRow(row);
    };

    const handleClickView = (row) => {
        setMode('view');
        setOffcanvasModal(true);
        setSelectedRow(row);
    };

    const handleEdit = (row) => {
        setMode('edit');
        setOffcanvasModal(true);
        setSelectedRow(row);
    };
    const handleDelete = (row) => {
        setDeleteModal(true);
        setSelectedRow(row);
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        setDeleteModal(false);
        api.delete(url.ASSETS + '/' + selectedRow.original.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.assetDeleted);
                    getAssets();
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const toggle = useCallback(async () => {
        if (offcanvasModal) setOffcanvasModal(false);
        else setOffcanvasModal(true);
    }, [offcanvasModal]);

    const saveFormData = (detailsObj) => {
        setCurrentDetailsObj({ ...detailsObj });
    };

    const formErrors = (errorsObj) => {
        setErrors(errorsObj);
    };

    const handleSubmit = async () => {
        const dataObj = {
            name: currentDetailsObj?.name?.trim(),
            type: currentDetailsObj?.assetType?.value
        };
        if (saveDisabled()) {
            toast.error(toastMessages.fillAllMandatoryFields);
            return;
        }
        if (mode !== 'edit' && currentDetailsObj?.assetType?.value === 'Wallpaper') {
            let fileSize = 0;
            let validTypes = fileTypeValidations.wallpaper;
            let invalidType = [];
            for (let i = 0; i < currentDetailsObj?.files?.length; i++) {
                let fileArr = currentDetailsObj?.files[i]?.filename?.split('.');
                invalidType.push(validTypes.includes(fileArr[fileArr.length - 1]?.toLowerCase()));
                fileSize = fileSize + currentDetailsObj?.files[i].fileSize;
            }
            if (invalidType.includes(false)) {
                toast.error('File extension should be ' + validTypes?.join(' / '));
                return;
            }
            if (fileSize > 5000000) {
                toast.error(toastMessages.wallpaperGT5);
                return;
            }
        }
        if (
            mode !== 'edit' &&
            currentDetailsObj?.assetType?.value === 'Boot Animation' &&
            currentDetailsObj?.files[0]?.fileExtension !== 'zip'
        ) {
            toast.error('File extension should be ' + fileTypeValidations['bootAnimation']?.join());
            return;
        }
        if (
            mode !== 'edit' &&
            currentDetailsObj?.assetType?.value === 'Shutdown Logo' &&
            currentDetailsObj?.files[0]?.fileExtension !== 'zip'
        ) {
            toast.error('File extension should be ' + fileTypeValidations['shutdownlogo']?.join());
            return;
        }
        if (mode !== 'edit' && currentDetailsObj?.files?.length) {
            let fileSize = 0;
            for (let i = 0; i < currentDetailsObj?.files?.length; i++) {
                fileSize = fileSize + currentDetailsObj?.files[i].fileSize;
            }
            if (fileSize > 30000000) {
                toast.error(toastMessages.filesSizeGT30);
                return;
            }
        }
        setCanvasLoader(true);
        if (mode === 'edit') {
            api.update(url.ASSETS + `/${selectedRow?.original?.id}/${currentDetailsObj?.name?.trim()}`)
                .then((final) => {
                    final = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(final) : final;
                    if (final.status === 'Updated Successfully' || final.status === 'success') {
                        toast.success(toastMessages.assetUpdated);
                        setCurrentDetailsObj({});
                        setCanvasLoader(false);
                        toggle();
                        setLoading(true);
                        getAssets();
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setCanvasLoader(false);
                });
        } else {
            setCanvasLoader(true);
            const file = await currentDetailsObj?.files?.[0]?.file?.arrayBuffer();
            api.create(url.IOSAPPS_UPLOAD + '?fileName=' + currentDetailsObj?.files?.[0]?.filename)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    dataObj.filename = resp.data?.serverfilename;
                    axios({
                        method: 'PUT',
                        data: file,
                        'Content-Type': 'application/octet-stream',
                        headers: { Authorization: '' },
                        url: resp.data?.url
                    })
                        .then((fileResp) => {
                            let paramKeys = ['?'];
                            Object.keys(dataObj).map((key) => {
                                if (dataObj[key]) paramKeys.push(key + '=' + dataObj[key]);
                                return paramKeys;
                            });
                            const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : '';
                            api.create(url.ASSETS + queryString)
                                .then((final) => {
                                    final = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(final) : final;
                                    if (final.status === 'success') {
                                        setCanvasLoader(false);
                                        toggle();
                                        toast.success(toastMessages.assetCreated);
                                        setCurrentDetailsObj({});
                                        setLoading(true);
                                        getAssets();
                                        setLoading(false);
                                    }
                                })
                                .catch((err) => {
                                    setCanvasLoader(false);
                                    toggle();
                                });
                        })
                        .catch((err) => {
                            setCanvasLoader(false);
                            toggle();
                        });
                })
                .catch((err) => {
                    setCanvasLoader(false);
                    toggle();
                });
        }
    };

    const handleOffcanvasBody = () => {
        return (
            <div className="position-reltive">
                <AddAsset
                    mode={mode}
                    selectedRow={selectedRow?.original}
                    toggle={toggle}
                    handleSubmit={handleSubmit}
                    saveFormData={saveFormData}
                    formErrors={formErrors}
                    setCanvasLoader={setCanvasLoader}
                />
            </div>
        );
    };

    const saveDisabled = () => {
        let flag = true;
        Object.keys(errors).forEach((element) => {
            if (errors[element]) flag = false;
        });
        return (
            !currentDetailsObj?.name ||
            currentDetailsObj?.name === '' ||
            (mode === 'add' ? !currentDetailsObj?.assetType?.value || currentDetailsObj?.assetType?.value === '' : false) ||
            (mode === 'add' ? currentDetailsObj?.files?.length === 0 : false) ||
            !flag
        );
    };

    const handleGlobalSearch = (val) => {
        let filteredAssets = [...assetsBackup];
        if (val?.length > 0) filteredAssets = assetsBackup.filter((asset) => asset?.name?.toLowerCase()?.includes(val?.toLowerCase()));
        setTotalRecords(filteredAssets.length);
        setAssets(filteredAssets);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete Asset?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={polDelete} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>
                    File Name: <span className="fw-semibold">{selectedRow?.original?.name}</span>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Assets" history={history} homeLink="Dashboard" />
                    <Col xl={12}>
                        <TableContainer
                            loading={loading}
                            tableHeader={'All Assets'}
                            isGlobalFilter={true}
                            searchFilter2={true}
                            searchPlaceHolder={'Asset Name'}
                            handleGlobalSearch={handleGlobalSearch}
                            totalRecords={totalRecords}
                            addButton={true}
                            columns={assetsTableSchema}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            handleClickView={handleClickView}
                            handleAddNew={handleAddNew}
                            reader={priviliges.ASSET_READER}
                            editor={priviliges.ASSET_EDITOR}
                            data={assets}
                            className="custom-header-css"
                            divClass="table-responsive table-card"
                            tableClass="table table-nowrap table-border table-centered align-middle"
                            theadClass="bg-light text-muted"
                        />
                    </Col>
                    <DeleteModal
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={message()}
                        show={deleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        deleteMessage={'Are you sure you want to delete this asset ?'}
                    />
                    <OffcanvasModal
                        direction="end"
                        toggle={toggle}
                        open={offcanvasModal}
                        hideSaveButton={mode === 'view'}
                        handleCloseClick={toggle}
                        OffcanvasModalID="assetModal"
                        handleOffcanvasBody={handleOffcanvasBody}
                        modalClassName={mode === 'view' ? 'w-25' : 'w-30'}
                        saveText={mode === 'edit' ? 'Update' : 'Save'}
                        offcanvasHeader={mode === 'add' ? 'Add New Asset' : `${mode?.charAt(0)?.toUpperCase() + mode?.slice(1)} Asset`}
                        saveDisabled={saveDisabled() || loading}
                        handleSaveClick={handleSubmit}
                        loading={canvasLoader}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Assets;
