/* eslint-disable max-nested-callbacks */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, convertTextCase, getDate } from '../../../Components/Common/Util';
import { priviliges as privsArr } from '../../../Components/constants/constants';
import delRole from '../../../assets/images/common/png/delete/role.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { privilegesSchema, rolesSchema } from '../TableSchema';

const Roles = () => {
    document.title = 'Roles';
    const urlconf = useEnv();
    let history = useHistory();
    let api = new APIClient();
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [priviliges, setPriviliges] = useState([]);
    const [priviligesBackup, setPriviligesBackup] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [showAddRoleModal, setShowAddRoleModal] = useState(false);
    const [mode, setMode] = useState('add');
    const [roleName, setRoleName] = useState('');
    const [checkAll, setCheckAll] = useState(false);
    const [checkAllReaders, setCheckAllReaders] = useState(false);
    const [checkAllEditors, setCheckAllEditors] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [tableSchema, setTableSchema] = useState(rolesSchema);
    const [deleteModal, setDeleteModal] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });

    useEffect(() => {
        setLoading(true);
        let authUser = AuthUser();
        let userData = authUser ? JSON.parse(authUser) : '';
        let findPriv = !userData?.data?.privileges?.includes(privsArr.ROLE_EDITOR);
        let schema = [...rolesSchema];
        if (findPriv) schema = schema.filter((role, index) => index < rolesSchema.length - 2);
        setTableSchema(schema);
        getPriviliges();
        getRoles(searchParams);
    }, []);

    const getRoles = (params) => {
        setSearchParams(params);
        api.get(url.ROLES, params, domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                setSearchVal('');
                if (resp?.data) {
                    resp.data.forEach((data) => {
                        data.roleId = data._id;
                        data.doNotDelete = data.exists || data.issystemrole;
                        data.privilegesLength = data.privileges?.split(',').length;
                        data.modifiedBy = data.modifiedby;
                        data.modifiedDate = data.modifieddate ? getDate(data.modifieddate) : '—';
                    });
                    setTotalRecords(resp.totalRecords);
                    setRoles(resp.data);
                } else setRoles([]);
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const getPriviliges = () => {
        let params = { page: 1, size: 100 };
        api.get(url.PRIVILEGES, params, domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                let priviligesArray = [];
                if (resp.data?.length > 0) {
                    /*
                     * resp.data.push(privsArr.TENANTMASTER_EDITOR);
                     * resp.data.push(privsArr.TENANTMASTER_READER);
                     */
                    let priviligesArr = resp.data;
                    let sortArr = priviligesArr.sort();
                    sortArr.forEach((item, index) => {
                        let label = item?.split('_');
                        let priviligeLabel = item === 'DEVICEGROUP_ADMIN' ? 'Devicegroup Admin' : convertTextCase(label?.[0], true);
                        let found = priviligesArray.find((privilige) => privilige.label === priviligeLabel);
                        if (found) {
                            let obj = { ...found, editor: false, reader: false, all: false };
                            if (label[1] === 'EDITOR') obj.editorPriv = item;
                            else if (label[0] === 'CALLLOG') obj.readerPriv = item;
                            else if (label[0] === 'MAKER' || label[0] === 'CHECKER') obj.editorPriv = item;
                            else obj.readerPriv = item;
                            obj.anyPriv = obj.readerPriv ? obj.readerPriv : obj.editorPriv;
                            let objInd = priviligesArr.findIndex((priv) => priv.label === priviligeLabel);
                            priviligesArray.splice(objInd, 1, obj);
                        } else {
                            let obj = { label: priviligeLabel, index: index };
                            if (label[1] === 'EDITOR') obj.editorPriv = item;
                            else if (label[0] === 'CALLLOG') obj.readerPriv = item;
                            else if (label[0] === 'MAKER' || label[0] === 'CHECKER') obj.editorPriv = item;
                            else obj.readerPriv = item;
                            obj.anyPriv = obj.readerPriv ? obj.readerPriv : obj.editorPriv;
                            priviligesArray.push(obj);
                        }
                    });
                }
                setPriviligesBackup(JSON.parse(JSON.stringify(priviligesArray)));
                setPriviliges(priviligesArray);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleAddNew = () => {
        setMode('add');
        setSelectedRole('');
        setShowAddRoleModal(true);
    };

    const toggle = useCallback(async () => {
        setRoleName('');
        setPriviliges(JSON.parse(JSON.stringify(priviligesBackup)));
        setCheckAllEditors(false);
        setCheckAllReaders(false);
        setCheckAll(false);
        setShowAddRoleModal(!showAddRoleModal);
    }, [showAddRoleModal]);

    const saveDisabled = () => {
        let privs = priviliges?.filter((role) => (role.editorPriv && role.editor) || (role.readerPriv && role.reader));
        return !roleName || privs.length === 0;
    };

    const checkPrivilegeChange = (privs) => {
        let currentPrivs = selectedRole?.privileges?.split(',');
        currentPrivs = currentPrivs.sort();
        let newPrivs = privs?.split(',');
        newPrivs = newPrivs?.sort();
        if (newPrivs?.join(',') === currentPrivs?.join(',')) return undefined;
        return privs;
    };

    const handleSubmit = () => {
        if (roleName?.length > 20) {
            toast.error(toastMessages.roleNameLengthExceeded);
        }
        setLoading(true);
        let selectedRoles = [];
        priviliges?.forEach((role) => {
            if (role.editorPriv && role?.editor) selectedRoles.push(role.editorPriv);
            if (role.readerPriv && role.reader) selectedRoles.push(role.readerPriv);
        });
        setCheckAllEditors(false);
        setCheckAllReaders(false);
        setCheckAll(false);
        let dataObj = { name: roleName, privileges: selectedRoles?.join(',') };
        createNdUpdate(dataObj);
    };

    const createNdUpdate = (dataObj) => {
        let serviceCall;
        if (mode === 'edit') {
            let params = {
                name: selectedRole.name === roleName ? undefined : roleName,
                privileges: checkPrivilegeChange(dataObj.privileges)
            };
            params = JSON.parse(JSON.stringify(params));
            serviceCall = api.patch(url.ROLES + '/' + selectedRole._id, params, false, domains.IDM);
        } else serviceCall = api.create(url.ROLES, dataObj, false, domains.IDM);
        serviceCall
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(mode === 'edit' ? toastMessages.roleUpdate : toastMessages.roleCreate);
                    setShowAddRoleModal(false);
                    setRoleName('');
                    setPriviliges(JSON.parse(JSON.stringify(priviligesBackup)));
                    setSelectedRole('');
                    setMode('add');
                    getRoles(searchParams);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleCheckboxChange = (e, _index, row, cell) => {
        let priviligesArr = [...priviliges];
        priviligesArr.forEach((privilige) => {
            if (row.original?.anyPriv === privilige.anyPriv) {
                privilige[cell.column.id] = e.target.checked;
                if (cell.column.id === 'all') {
                    privilige.reader = privilige.readerPriv && e.target.checked;
                    privilige.editor = privilige.editorPriv && e.target.checked;
                } else {
                    privilige.all = (privilige.editorPriv ? privilige.editor : true) && (privilige.readerPriv ? privilige.reader : true);
                }
            }
        });
        let editor = true;
        let reader = true;
        priviligesArr.forEach((privilige) => {
            if (privilige.editorPriv && !privilige.editor) editor = false;
            if (privilige.readerPriv && !privilige.reader) reader = false;
        });
        setCheckAllReaders(reader);
        setCheckAllEditors(editor);
        setCheckAll(reader && editor);
        setPriviliges([...priviligesArr]);
    };

    const handleCheckAll = (e, column) => {
        if (column.id === 'all') {
            let priviligeArr = [...priviliges];
            priviligeArr.forEach((privilige) => {
                if (privilige.editorPriv) privilige.editor = e.target.checked;
                if (privilige.readerPriv) privilige.reader = e.target.checked;
                privilige.all = e.target.checked;
            });
            setPriviliges([...priviligeArr]);
            setCheckAll(e.target.checked);
            setCheckAllEditors(e.target.checked);
            setCheckAllReaders(e.target.checked);
        } else if (column.id === 'editor') {
            let priviligesArr = [...priviliges];
            priviligesArr.forEach((privilige) => {
                if (privilige.editorPriv) privilige.editor = e.target.checked;
                privilige.all = (privilige.readerPriv ? privilige.reader : true) && e.target.checked;
            });
            let found = false;
            priviligesArr.forEach((privilige) => {
                if ((privilige.editorPriv ? !privilige.editor : false) || (privilige.readerPriv ? !privilige.reader : false)) {
                    privilige.all = false;
                    found = true;
                }
            });
            setCheckAll(!found);
            setPriviliges([...priviligesArr]);
            setCheckAllEditors(e.target.checked);
        } else if (column.id === 'reader') {
            let priviligesArr = [...priviliges];
            priviligesArr.forEach((privilige) => {
                if (privilige.readerPriv) privilige.reader = e.target.checked;
                privilige.all = (privilige.editorPriv ? privilige.editor : true) && e.target.checked;
            });
            let found = false;
            priviligesArr.forEach((privilige) => {
                if ((privilige.editorPriv ? !privilige.editor : false) || (privilige.readerPriv ? !privilige.reader : false)) {
                    privilige.all = false;
                    found = true;
                }
            });
            setCheckAll(!found);
            setPriviliges([...priviligesArr]);
            setCheckAllReaders(e.target.checked);
        }
    };

    const onPageChange = (pgNum) => {
        setPageNumber(pgNum);
    };

    const handleOffcanvasBody = () => {
        return (
            <React.Fragment>
                <Row className="mb-5">
                    <Col xs={12} sm={12} md={12} xl={3} lg={3} className="d-flex align-items-center">
                        <Label className="form-label d-flex align-items-center">
                            Role Name
                            {mode === 'add' ? <span className="red-color ps-1"> *</span> : <span className="ps-1"> :</span>}
                        </Label>
                    </Col>
                    <Col>
                        <div className="input-group">
                            {mode === 'add' ? (
                                <Input
                                    name={'roleName'}
                                    id={'roleName'}
                                    className="form-control"
                                    placeholder={'Enter Role Name'}
                                    type={'text'}
                                    maxLength={20}
                                    validate={{ required: { value: true } }}
                                    onChange={(e) => setRoleName(e.target.value)}
                                    value={roleName || ''}
                                    disabled={mode === 'edit'}
                                />
                            ) : roleName ? (
                                <span className="d-flex align-items-center">{roleName}</span>
                            ) : (
                                '–'
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <TableContainer
                            loading={loading}
                            isGlobalFilter={false}
                            columns={privilegesSchema}
                            data={priviliges}
                            mode={mode}
                            all={checkAll}
                            write={checkAllEditors}
                            read={checkAllReaders}
                            hideDisplayTotalCount={true}
                            hideCheckBoxTooltip={true}
                            pageNum={pageNumber}
                            hidePagination={true}
                            customPageSize={100}
                            handleCheckAll={handleCheckAll}
                            onPageChange={onPageChange}
                            handleCheckboxChange={handleCheckboxChange}
                            className="custom-header-css"
                            divClass="table-responsive table-card rounded"
                            tableClass="table table-nowrap table-border table-centered align-middle privilege-table mb-0"
                            theadClass="bg-light text-muted"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const handleEdit = (row) => {
        setSelectedRole(row.original);
        setRoleName(row.original.name);
        let privArr = JSON.parse(JSON.stringify(priviligesBackup));
        if (privArr?.length > 0) {
            privArr.forEach((a) => {
                row.original?.privileges?.split(',')?.forEach((b) => {
                    if (a.editorPriv === b) a.editor = true;
                    else if (a.readerPriv === b) a.reader = true;
                    a.all = (a.editorPriv ? a.editor : true) && (a.readerPriv ? a.reader : true);
                });
            });
        }
        let editor = true;
        let reader = true;
        privArr.forEach((role) => {
            if (role.editorPriv && !role.editor) editor = false;
            if (role.readerPriv && !role.reader) reader = false;
        });
        setPriviliges(privArr);
        setCheckAllEditors(editor);
        setCheckAllReaders(reader);
        setCheckAll(editor && reader);
        setMode('edit');
        setShowAddRoleModal(true);
    };

    const handlePriviligeView = (row) => {
        setSelectedRole(row.original);
        setRoleName(row.original.name);
        let privArr = JSON.parse(JSON.stringify(priviligesBackup));
        if (privArr?.length > 0) {
            privArr.forEach((a) => {
                row.original?.privileges?.split(',')?.forEach((b) => {
                    if (a.editorPriv === b) a.editor = true;
                    else if (a.readerPriv === b) a.reader = true;
                    a.all = (a.editorPriv ? a.editor : true) && (a.readerPriv ? a.reader : true);
                });
            });
        }
        let editor = true;
        let reader = true;
        privArr.forEach((role) => {
            if (role.editorPriv && !role.editor) editor = false;
            if (role.readerPriv && !role.reader) reader = false;
        });
        setPriviliges(privArr);
        setCheckAllEditors(editor);
        setCheckAllReaders(reader);
        setCheckAll(editor && reader);
        setMode('view');
        setShowAddRoleModal(true);
    };

    const handleDelete = (row) => {
        setSelectedRole(row.original);
        setDeleteModal(true);
    };

    const handleDeleteConfirmation = () => {
        setDeleteModal(false);
        setLoading(true);
        api.delete(url.ROLES + '/' + selectedRole._id, '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.roleDelete);
                    getRoles(searchParams);
                }
                setSelectedRole('');
                setLoading(false);
            })
            .catch((err) => {
                setSelectedRole('');
                setLoading(false);
            });
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to delete Role?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={delRole} alt="deleteROle" width={60} height={60} />
                </div>
                <div>
                    Role Name: <span className="fw-semibold">{selectedRole?.name}</span>
                </div>
            </div>
        );
    };

    const handleGlobalSearch = (val) => {
        setLoading(true);
        getRoles({ ...searchParams, name: val });
    };

    const handlePageChange = (page) => {
        setLoading(true);
        getRoles({ ...searchParams, page: page });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Roles" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Roles'}
                        isGlobalFilter={true}
                        addButton={true}
                        pageNum={searchParams.page}
                        customPageSize={searchParams.size}
                        onPageChange={handlePageChange}
                        searchVal={searchVal}
                        largeSearchFilter={true}
                        SearchFilterWidth={'width-280'}
                        searchFilter2={true}
                        searchPlaceHolder={'Role Name'}
                        columns={tableSchema}
                        handleAddNew={handleAddNew}
                        handleEdit={handleEdit}
                        handlePriviligeView={handlePriviligeView}
                        handleDelete={handleDelete}
                        handleGlobalSearch={handleGlobalSearch}
                        totalRecords={totalRecords}
                        data={roles}
                        editor={privsArr.ROLE_EDITOR}
                        reader={privsArr.ROLE_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <OffcanvasModal
                        direction="end"
                        toggle={toggle}
                        open={showAddRoleModal}
                        handleCloseClick={toggle}
                        saveDisabled={saveDisabled()}
                        OffcanvasModalID="role"
                        hideSaveButton={mode === 'view'}
                        handleOffcanvasBody={handleOffcanvasBody}
                        modalClassName={'w-40'}
                        saveText={mode === 'edit' ? 'Update' : 'Save'}
                        handleSaveClick={handleSubmit}
                        offcanvasHeader={mode === 'edit' ? 'Edit Role' : mode === 'view' ? 'View Role' : 'Add Role'}
                    />
                    <DeleteModal
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={message()}
                        show={deleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        // deleteMessage={`Are you sure you want to remove this device group code -${selectedRow?.name} ?`}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Roles;
