import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { convertUTCtoIST, diffTwoDatesHrsMintsSeconds, getFormTypeAndRecordId, getTime } from '../../../Components/Common/Util';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { useEnv } from '../../../envContext';
import { useHistory } from 'react-router-dom';
import { licenseViewSchema } from './TableSchema';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import standardImg from '../../../assets/images/common/png/common/StandardImage.png';

const LicenseView = () => {
    document.title = 'View License';
    const [loading, setLoading] = useState(false);
    const [licenseData, setLicenseData] = useState([]);
    const history = useHistory();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    const [detailsObj, setDetailsObj] = useState();
    const [headersObj, setHeadersObj] = useState();
    const formType = formTypeAndId['formType'];
    const recordId = formTypeAndId['recordID'];
    const api = new APIClient();
    const urlconf = useEnv();

    useEffect(() => {
        getLicenses();
    }, []);

    const getLicenses = () => {
        setLoading(true);
        api.get(url.LICENSE + '/' + recordId, '', domains.MDM_COMMONS)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    const data = resp?.data;
                    const { totalDaysNoAbs } = data.EndDate ? diffTwoDatesHrsMintsSeconds(data.EndDate, new Date()) : 0;

                    const headerObj = {
                        startDate: data.StartDate ? convertUTCtoIST(data.StartDate, true) : '_',
                        endDate: data.EndDate ? convertUTCtoIST(data.EndDate, true) : '_',
                        remainingDays:
                            totalDaysNoAbs + 1 < 0 ? 'Expired' : totalDaysNoAbs + 1 === 1 ? '1 day' : totalDaysNoAbs + 1 + ' days'
                    };
                    const obj = {
                        totalLicenses: data.Total,
                        usedLicenses: data.Assigned,
                        remainingLicenses: data.Total - data.Assigned
                    };
                    setHeadersObj(headerObj);
                    setDetailsObj(obj);
                    setLicenseData(resp?.data);
                    setLoading(false);
                }
            })
            .catch((err) => setLoading(false));
    };

    const detailsSchema = [
        {
            label: 'Total Quantity',
            accessor: 'totalLicenses'
        },
        {
            label: 'Used Licenses',
            accessor: 'usedLicenses'
        },
        {
            label: 'Remaining Licenses',
            accessor: 'remainingLicenses',
            customClass: 'text-primary'
        }
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        title="License Management"
                        pageTitle="View License"
                        showBack={true}
                        backLink={'license'}
                        history={history}
                    />
                    <Card className="p-4">
                        <Row>
                            <Col xs={3} sm={3} md={2} lg={2} xl={1} className="d-flex align-items-center justify-content-start">
                                <div className="p-2 bg-soft-secondary rounded-2">
                                    <img src={standardImg} alt="license" width={40} />
                                </div>
                            </Col>
                            <Col xs={9} sm={9} md={10} lg={10} xl={10} className="d-flex align-items-center justify-content-start">
                                {licenseViewSchema?.map((field, index) => {
                                    return (
                                        <div key={index} className="pe-5">
                                            <div className="mb-2 fw-medium text-muted ">{field?.label}</div>
                                            <div
                                                className={`fw-medium  d-flex align-items-center ${
                                                    headersObj?.[field.accessor] === 'Expired'
                                                        ? 'rounded-5 badge-soft-danger text-center p-1'
                                                        : ''
                                                }`}
                                            >
                                                {field?.dateIcon && headersObj?.[field.accessor] !== 'Expired' && (
                                                    <i className={field?.dateIcon} />
                                                )}
                                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                    {headersObj?.[field.accessor]}
                                                </EllipsisToolTip>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <CardHeader className="fs-14 fw-medium">License Details</CardHeader>
                        <CardBody className="d-flex p-6">
                            {detailsSchema?.map((field, index) => {
                                return (
                                    <div key={index} className="pe-8">
                                        <div className="mb-2 fw-medium text-muted ">{field?.label}</div>
                                        <div className={`fw-medium ${field?.customClass}`}>
                                            <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                {detailsObj?.[field.accessor]}
                                            </EllipsisToolTip>
                                        </div>
                                    </div>
                                );
                            })}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default LicenseView;
