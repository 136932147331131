import React from 'react';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import toastMessages from '../../common/messages/toastMessages';

export const selectComponent = (props) => {
    const handleActionChange = (option, key) => {
        if (option.value !== 'PolicySwitch') {
            props.validation.setValues({ ...props.validation.values, [key]: { action: option, policy: undefined } });
        } else {
            props.validation.setValues({
                ...props.validation.values,
                [key]: { action: option, policy: props.validation?.values[key]?.policy }
            });
        }
    };
    const handlePolicyChange = (policy, key) => {
        props.validation.setValues({
            ...props.validation.values,
            [key]: { ...props.validation?.values[key], policy: policy }
        });
    };
    return (
        <Row key={props.index}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Label className="mb-0 mt-2 fw-medium">{props.field.label} </Label>
                {props.field.mandatory && <span className="ms-2 text-danger">*</span>}
                <div className="text-muted wrap-text-word mb-1 fs-11">{props.field.helpText}</div>
            </Col>

            <Col
                sm={props.validation.values[props.field.value]?.action?.value !== 'PolicySwitch' ? 12 : 6}
                md={props.validation.values[props.field.value]?.action?.value !== 'PolicySwitch' ? 12 : 6}
                lg={props.validation.values[props.field.value]?.action?.value !== 'PolicySwitch' ? 12 : 6}
                xl={props.validation.values[props.field.value]?.action?.value !== 'PolicySwitch' ? 12 : 6}
                xxl={props.validation.values[props.field.value]?.action?.value !== 'PolicySwitch' ? 12 : 6}
            >
                {props.formType !== 'view' ? (
                    <Select
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.label}
                        options={props.field?.options}
                        placeholder="Select"
                        isDisabled={props.formType === 'view'}
                        onChange={(selectedOption) => {
                            handleActionChange(selectedOption, props.field.value);
                        }}
                        value={props.validation?.values[props.field.value]?.action || ''}
                        isSearchable={true}
                        noOptionsMessage={() => 'No data found'}
                    />
                ) : props.validation?.values?.[props.field.value]?.length > 0 ? (
                    props.validation?.values?.[props.field.value]
                        ?.map((v) => v.label)
                        ?.toString()
                        ?.split(',')
                        ?.join(', ')
                ) : props.validation?.values[props.field.value]?.label ? (
                    props.validation?.values[props.field.value]?.label
                ) : props.validation?.values[props.field.value] ? (
                    props.validation?.values[props.field.value]
                ) : (
                    '–'
                )}
            </Col>
            {props.validation.values[props.field.value]?.action?.value === 'PolicySwitch' && (
                <Col sm={6} md={6} lg={6} xl={6} xxl={6}>
                    {props.formType !== 'view' ? (
                        <Select
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.name}
                            value={props.validation?.values[props.field.value]?.policy || ''}
                            isDisabled={props.formType === 'view'}
                            options={props.policies}
                            placeholder="Select"
                            onChange={(policy) => {
                                handlePolicyChange(policy, props.field.value);
                            }}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    ) : props.validation?.values?.[props.field.value]?.length > 0 ? (
                        props.validation?.values?.[props.field.value]
                            ?.map((v) => v.label)
                            ?.toString()
                            ?.split(',')
                            ?.join(', ')
                    ) : props.validation?.values[props.field.value]?.label ? (
                        props.validation?.values[props.field.value]?.label
                    ) : props.validation?.values[props.field.value] ? (
                        props.validation?.values[props.field.value]
                    ) : (
                        '–'
                    )}
                </Col>
            )}
        </Row>
    );
};

const tableTimeInput = (props) => {
    const handleDisable = () => {
        let flag = false;
        props.field.tableContent?.forEach((content) => {
            flag = !props.validation.values[content.value] || props.validation.values[content.value] === '';
        });
        return flag;
    };
    const handleAdd = () => {
        let values = [...(props.validation.values[props.field.value] || [])];
        let validValues = JSON.parse(JSON.stringify(props.validation.values));
        let obj = {};
        let validObj = {};
        props.field.tableContent.forEach((content) => {
            const dateObj = validValues[content.value];
            validObj[content.value] = new Date(dateObj).getHours();
            const date = new Date(dateObj).getHours() + ':00';
            obj[content.value] = date;
            delete validValues[content.value];
        });
        if (props.field.max ? values?.length <= props.field.max : true) {
            let status = false;
            if (validObj.endTime - validObj.startTime >= 1) {
                status = true;
            }
            (props.validation.values.timeSlots || []).forEach((slot) => {
                const start = slot.startTime.split(':')[0];
                const end = slot.endTime.split(':')[0];
                if (
                    (validObj.startTime > start && validObj.startTime < end) ||
                    (validObj.endTime > start && validObj.endTime < end) ||
                    (start > validObj.startTime && start < validObj.endTime) ||
                    (end > validObj.startTime && end < validObj.endTime)
                )
                    status = false;
            });
            if (status) values.push(obj);
            else toast.error(toastMessages.invalidTime);
        }
        props.validation.setValues({ ...validValues, [props.field.value]: values });
    };
    const handleDelete = (ind) => {
        let values = [...(props.validation.values[props.field.value] || [])];
        values.splice(ind, 1);
        let clearObj = {};
        props.field.tableContent.forEach((content) => (clearObj[content.value] = ''));
        props.validation.setValues({ ...props.validation.values, ...clearObj, [props.field.value]: values });
    };

    const handleTime = (value, date) => {
        value === 'startTime'
            ? props.validation?.setValues({ ...props.validation.values, [value]: date, endTime: '' })
            : props.validation?.setValues({ ...props.validation.values, [value]: date });
    };

    return (
        <React.Fragment key={props.index}>
            <Row>
                <Col sm={6}>
                    <Label className="mb-0 fw-semibold d-flex align-items-center">
                        {props.field.label}
                        {props.field.mandatory && props.formType !== 'view' && <span className="red-color ps-1">*</span>}
                    </Label>
                    <div className="text-muted font-size-12 mb-2 word-wrap-break ">{props.field.helpText && props.field.helpText}</div>
                    <Table bordered>
                        <thead>
                            <tr>
                                {props.field.tableContent.map((content, index) => (
                                    <td key={index} className="fw-semibold">
                                        {content.label}
                                    </td>
                                ))}
                                <td className="fw-semibold">Actions</td>
                            </tr>
                        </thead>
                        <tbody>
                            {(props.validation.values?.[props.field.value] || [])?.map((value, ind) => (
                                <tr key={ind}>
                                    {props.field.tableContent?.map((content, index) => (
                                        <td key={index}>
                                            {content.type === 'time'
                                                ? Number(value[content.value]?.split(':')?.[0]) < 12
                                                    ? Number(value[content.value]?.split(':')?.[0]) === 0
                                                        ? '12:00 AM'
                                                        : value[content.value] + ' AM'
                                                    : Number(value[content.value]?.split(':')?.[0] - 12) === 0
                                                    ? '12:00 PM'
                                                    : Number(value[content.value]?.split(':')?.[0] - 12) + ':00 PM'
                                                : value[content.value]}
                                        </td>
                                    ))}
                                    {props.formType !== 'view' && (
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <Button color="danger" outline size="sm" onClick={() => handleDelete(ind)}>
                                                    <i className="ri-delete-bin-6-line fs-16" />
                                                </Button>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                            {(props.field.max && props.validation.values[props.field.value]?.length
                                ? props.validation.values[props.field.value]?.length < props.field.max
                                : true) &&
                                (props.formType !== 'view' ? (
                                    <tr className={'border-none'}>
                                        {props.field.tableContent?.map((content, index) => (
                                            <td key={index} className={'border-none'}>
                                                <div className="d-flex align-items-center gap-2">
                                                    <div className="form-icon flex-nowrap">
                                                        <Flatpickr
                                                            placeholder={content.label}
                                                            value={props.validation?.values?.[content?.value]}
                                                            name={content?.value}
                                                            className="form-control form-control-icon min-width-160 me-2"
                                                            options={content.options}
                                                            onChange={(evt) => {
                                                                handleTime(content?.value, evt);
                                                            }}
                                                        />
                                                        <i className={content.icon} />
                                                    </div>
                                                </div>
                                            </td>
                                        ))}
                                        <td className={'border-none'}>
                                            <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                                <Button color="success" outline size="sm" disabled={handleDisable()} onClick={handleAdd}>
                                                    <i className="ri-check-line fs-16" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ) : !props.validation.values[props.field.value]?.length ? (
                                    '–'
                                ) : (
                                    ''
                                ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export const DynamicComponents = (props) => {
    return (
        <React.Fragment key={props.index}>
            {props.field.inputType === 'select' ? (
                selectComponent({
                    field: props.field,
                    index: props.index,
                    validation: props.validation,
                    formType: props.formType,
                    policies: props.policies
                })
            ) : props.field.inputType === 'time' ? (
                tableTimeInput({
                    field: props.field,
                    index: props.index,
                    validation: props.validation,
                    formType: props.formType,
                    handleKeyDown: props.handleKeyDown
                })
            ) : (
                <Row className="my-2">
                    <div className="fw-medium">{props.field.label}</div>
                    <div className="text-muted wrap-text-word fs-11">{props.field.helpText}</div>
                </Row>
            )}
        </React.Fragment>
    );
};
