import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import getChartColorsArray from '../../Components/Common/ChartsDynamicColor';
import AndroidImage from '../../assets/images/android/png/andriod.png';
import { Tooltip, getChartsData } from '../../Components/Common/Util';
import NoRecordsFound from '../../Components/Common/NoRecordsFound';

const AndroidVersion = (props) => {
    let history = useHistory();

    let dataColors = '["--EMM-androidv0", "--EMM-androidv1", "--EMM-androidv2","--EMM-androidv3","--EMM-androidv4","--EMM-androidv5"]';
    let dataColorsArr = ['androidv0', 'androidv1', 'androidv2', 'androidv3', 'androidv4', 'androidv5'];
    var dountchartUserDeviceColors = getChartColorsArray(dataColors);

    const [labels, setLabels] = useState([]);
    const [series, setSeries] = useState([]);
    const [details, setDetails] = useState([]);

    useEffect(() => {
        if (props.details?.length > 0) {
            setLabels(props.details.map((detail) => detail.label));
            setSeries(props.details?.map((detail) => detail.count));
            let detailArr = JSON.parse(JSON.stringify(props.details));
            setDetails(detailArr.splice(0, 3));
        }
    }, [props]);

    const options = getChartsData({ colors: dountchartUserDeviceColors, labels: labels });
    return (
        <React.Fragment>
            <Card className="card-height-100">
                <CardHeader className="align-items-center d-flex fw-semibold gap-1 padding-y-25">
                    <h4 className="card-title mb-0">OS Distribution</h4>
                    <i className="ri-information-line link-info" id={'info-os-distribution'} />
                    {Tooltip('info-os-distribution', 'This chart displays the distribution of OS versions across all devices.')}
                </CardHeader>
                <div className="bg-white">
                    <CardBody className="card-body">
                        {details?.length > 0 && (
                            <ReactApexChart dir="ltr" options={options} series={series} type="donut" height="250" className="apex-charts" />
                        )}
                        <div className="pt-2 ps-4 pe-4 bg-white">
                            <ul className="list-group list-group-flush border border-2 border-dashed mb-0 mt-2">
                                {details?.length > 0 ? (
                                    details.map((ele, index) => {
                                        return (
                                            <li key={index} className="list-group-item px-0">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1 ms-2">
                                                        <p className="fs-14 mb-0 d-flex align-items-center">
                                                            <i
                                                                className={`ri-checkbox-blank-circle-fill fs-14 align-middle text-${
                                                                    dataColorsArr[index % 6]
                                                                } me-1`}
                                                            ></i>
                                                            {ele.label}
                                                        </p>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <p className="fs-14 mb-0">{ele.count}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <NoRecordsFound />
                                )}
                            </ul>
                            {props.details?.length > 3 && (
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <span className="text-info fw-medium cursor-pointer" onClick={props.showDetailsModal}>
                                        Show All
                                    </span>
                                </div>
                            )}
                        </div>
                    </CardBody>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default AndroidVersion;
