import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import getChartColorsArray from '../../Components/Common/ChartsDynamicColor';
import deviceDistribution from '../../assets/images/common/svg/common/device_distribution.svg';
import { Tooltip, getChartsData } from '../../Components/Common/Util';
import NoRecordsFound from '../../Components/Common/NoRecordsFound';

const Manufacturer = (props) => {
    let history = useHistory();
    let dataColors =
        '["--EMM-manufacture1", "--EMM-manufacture2", "--EMM-manufacture3","--EMM-manufacture4","--EMM-manufacture5","--EMM-manufacture6","--EMM-manufacture7"]';
    let dataColorsArr = ['manufacture1', 'manufacture2', 'manufacture3', 'manufacture4', 'manufacture5', 'manufacture6', 'manufacture7'];
    var dountchartUserDeviceColors = getChartColorsArray(dataColors);
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [details, setDetails] = useState([]);

    useEffect(() => {
        if (props.details?.length > 0) {
            setSeries(props.details.map((detail) => detail.count));
            setLabels(props.details.map((detail) => detail.label));
            let detailArr = JSON.parse(JSON.stringify(props.details));
            setDetails(detailArr.splice(0, 3));
        }
    }, [props]);
    const options = getChartsData({ colors: dountchartUserDeviceColors, labels: labels });

    return (
        <React.Fragment>
            <Card className="card-height-100">
                <CardHeader className="align-items-center fw-semibold d-flex gap-1 padding-y-25">
                    <h4 className="card-title mb-0">Device Distribution</h4>
                    <i className="ri-information-line link-info" id={'info-device-distribution'} />
                    {Tooltip('info-device-distribution', 'This chart displays the distribution of devices by brand.')}
                </CardHeader>
                <div className="bg-white">
                    <CardBody className="card-body">
                        {details?.length > 0 && (
                            <ReactApexChart dir="ltr" options={options} series={series} type="donut" height="250" className="apex-charts" />
                        )}
                        <div className="pt-2 ps-4 pe-4 bg-white">
                            <ul className="list-group list-group-flush border border-2 border-dashed mb-0 mt-2">
                                {details?.length > 0 ? (
                                    details.map((ele, index) => {
                                        return (
                                            <li key={index} className="list-group-item px-0">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1 ms-2">
                                                        <p className={'fs-14 mb-0 d-flex align-items-center'}>
                                                            <i
                                                                className={`ri-checkbox-blank-circle-fill fs-14 align-middle me-1 text-${
                                                                    dataColorsArr[index % 7]
                                                                }`}
                                                            ></i>
                                                            <span className={`text-${dataColorsArr[index % 7]} px-1`}>{ele.label}</span>
                                                        </p>
                                                    </div>
                                                    <div className={'flex-shrink-0'}>
                                                        <p className="fs-14 mb-0">{ele.count}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <NoRecordsFound />
                                )}
                            </ul>
                            {props.details?.length > 3 && (
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <span onClick={props.showDetailsModal} className=" text-info fw-medium cursor-pointer">
                                        Show All
                                    </span>
                                </div>
                            )}
                        </div>
                    </CardBody>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default Manufacturer;
