import React, { useEffect, useState } from 'react';
import { PROTECTION_POLICY, policiesArray } from './Schema';
import TableContainer from '../../../Components/Common/TableContainer';
import CommonModal from '../../../Components/Common/CommonModal';
import { Card, CardBody, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import { getDate, getFormTypeAndRecordId, timeSort } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import polDelete from '../../../assets/images/common/png/common/policyDelete.png';
import DeleteModal from '../../../Components/Common/DeleteModal';

const ConditionalAccess = (props) => {
    const [showPolicyModal, setShowPolicyModal] = useState(false);
    const [policyId, setPolicyId] = useState('');
    const [policies, setPolicies] = useState([]);
    const [selectedRow, setSelectedRow] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    const osType = formTypeAndId.recordID;
    const history = useHistory();
    const urlconf = useEnv();
    const api = new APIClient();

    useEffect(() => {
        if (props.selectedTab === 'conditionalAccess') {
            props.setLoading(true);
            getPolicies(formTypeAndId.recordID === 'tectoro' ? '' : url.MS_CONDITIONAL_ACCESS);
        }
    }, [props.selectedTab]);

    const getPolicies = (apiUrl) => {
        const policiesPromise = new Promise((resolve, reject) => {
            api.get(apiUrl, '', domains.MDM_COMMONS)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        resolve(resp);
                    } else reject('Policy failed');
                })
                .catch((err) => reject(err));
        });
        Promise.allSettled([policiesPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    result[0].value.data.forEach((data) => {
                        data.name = data.displayName;
                        data.rowView = true;
                        data.createdDate = data.createdDateTime ? getDate(data.createdDateTime) : '—';
                        data.modifiedBy = data.modifiedby || '—';
                        data.modifiedDate = data.modifiedDateTime ? getDate(data.modifiedDateTime) : '—';
                    });
                    setPolicies(timeSort(result[0].value?.data || [], 'modifiedDateTime'));
                }
                props.setLoading(false);
            })
            .catch((err) => {
                props.setLoading(false);
            });
    };

    const handleModalBody = () => {
        return (
            <div>
                <Row className="d-flex align-items-center justify-content-evenly">
                    {policiesArray.map((ele, index) => {
                        return (
                            <Card
                                key={index}
                                className={`d-flex align-items-center justify-content-center mb-0 p-0 border shadow width-220 height-220 cursor-pointer
									${ele.id === policyId ? 'border-2 border-primary' : ''}`}
                                onClick={() => setPolicyId(ele.id)}
                            >
                                <CardBody className="d-flex flex-column align-items-center justify-content-center position-relative">
                                    {ele.id === policyId && (
                                        <i className="position-absolute end-10 top-5 link-primary fs-18 ri-check-double-fill"></i>
                                    )}
                                    <div>
                                        <img src={ele.img} alt="" height={60} width={60} />
                                    </div>
                                    <p className="fw-semibold text-info text-truncate mt-3 mb-3">{ele.label}</p>
                                    <span className="text-wrap text-center fw-normal fs-12">{ele.desc}</span>
                                </CardBody>
                            </Card>
                        );
                    })}
                </Row>
            </div>
        );
    };

    const handleDelete = (row) => {
        setSelectedRow(row.original);
        setShowDeleteModal(true);
    };

    const deletePolicyMessage = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to delete this policy?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={polDelete} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>
                    Policy Name: <span className="fw-semibold">{selectedRow.name}</span>
                </div>
            </div>
        );
    };

    const handleDeleteConfirm = () => {
        props.setLoading(true);
        setShowDeleteModal(false);
        api.delete(url.MS_CONDITIONAL_ACCESS + '/' + selectedRow?.id, '', domains.MDM_COMMONS)
            .then((resp) => {
                setSelectedRow('');
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.conditionalAccessDeleted);
                    getPolicies(formTypeAndId.recordID === 'tectoro' ? '' : url.MS_CONDITIONAL_ACCESS);
                } else props.setLoading(false);
            })
            .catch((err) => props.setLoading(false));
    };

    const handleClickView = (row) => {
        history.push(`/appprotectionpolicy/conditionalaccess/${osType}/edit/${row.original.id}`);
    };

    return (
        <React.Fragment>
            <TableContainer
                isGlobalFilter={true}
                tableHeader={'Conditional Access'}
                addButton={true}
                columns={PROTECTION_POLICY?.filter((obj) => obj.Header !== 'Apps')}
                data={policies}
                handleAddNew={() => history.push(`/appprotectionpolicy/conditionalaccess/${formTypeAndId.recordID}/add`)}
                handleDelete={handleDelete}
                handleClickView={handleClickView}
                customPageSize={10}
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="table table-nowrap table-border table-centered align-middle"
                theadClass="bg-light text-muted"
            />
            {/* <CommonModal
                size={'md'}
                show={showPolicyModal}
                modalheader={'Add Policy'}
                onCloseClick={() => {
                    setPolicyId('');
                    setShowPolicyModal(false);
                }}
                saveText={'Proceed'}
                cancelText={'Cancel'}
                disabled={!policyId}
                handleClick={() => {
                    history.push(`/appprotectionpolicy/conditionalaccess/${formTypeAndId.recordID}/add`);
                    setShowPolicyModal(false);
                }}
                handleModalBody={handleModalBody}
            /> */}
            <DeleteModal
                size="md"
                show={showDeleteModal}
                hideIcon={true}
                confirmation={true}
                onDeleteClick={handleDeleteConfirm}
                message={deletePolicyMessage()}
                onCloseClick={() => {
                    setShowDeleteModal(false);
                    setSelectedRow('');
                }}
                confirmText="Yes, Delete it!"
                hideDeleteMessage={true}
                hideDeleteIcon={true}
            />
        </React.Fragment>
    );
};

export default ConditionalAccess;
