import React, { useEffect, useState } from 'react';
import Loader from '../../../Components/Common/DotsLoader';
import { Alert, Card, CardBody, CardFooter, Container, Input } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { DownloadBunnyFile, getFormTypeAndRecordId, sortBy, TenantConfig } from '../../../Components/Common/Util';
import { useFormik } from 'formik';
import TabsComponent from '../../../Components/Common/TabsComponent';
import CommonModal from '../../../Components/Common/CommonModal';
import { CustomLauncherThemeTemplate, SettingsTemplate } from './TvLauncherTabBodies';
import TVSettingFieldsArray from './TvSettingFields';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';

const AddTvLauncherSetup = () => {
    const urlconf = useEnv();
    const api = new APIClient();
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState('');
    const tenant = TenantConfig();
    const [selectedTab, setSelectedTab] = useState('branding');
    const [header, setHeader] = useState('');
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    const [type, setType] = useState('');
    const [key, setKey] = useState('');
    const [settings, setSettings] = useState([...TVSettingFieldsArray]);
    const [showDefaultPassword, setShowDefaultPassword] = useState(false);
    const [applications, setApplications] = useState([]);
    const [timerModal, setTimerModal] = useState(false);
    const history = useHistory();
    const [timerValue, setTimerValue] = useState({ minutes: '00', seconds: '05' });

    const [openDropDown, setOpenDropDown] = useState({});

    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];

    document.title = formType === 'view' ? 'View Launcher' : formType === 'edit' ? 'Edit Launcher' : 'Add Launcher';

    const [featureEnabled, setFeatureEnabled] = useState({
        show_wifi: false,
        show_bluetooth: false,
        show_screen_orientation: false,
        show_brigntness: false,
        show_device_details: true,
        show_system_settings: false,
        show_kiosk_exit: false,
        req_admin_password_wifi: false,
        req_admin_password_bluetooth: false,
        req_admin_password_screen_orientation: false,
        req_admin_password_brightness: false,
        req_admin_password_device_details: false,
        req_admin_password_system_settings: false,
        req_admin_password_kiosk_exit: false
    });
    const [passwords, setPasswords] = useState({
        wifi: tenant?.DEVICE_DEFAULT_PASSWORD,
        bluetooth: tenant?.DEVICE_DEFAULT_PASSWORD,
        screen_orientation: tenant?.DEVICE_DEFAULT_PASSWORD,
        details: tenant?.DEVICE_DEFAULT_PASSWORD,
        system_settings: tenant?.DEVICE_DEFAULT_PASSWORD,
        brightness: tenant?.DEVICE_DEFAULT_PASSWORD,
        kiosk_exit: tenant?.DEVICE_DEFAULT_PASSWORD
    });

    let valuesArr = ['wifi', 'bluetooth', 'screen_orientation', 'brightness', 'details', 'system_settings', 'kiosk_exit'];

    useEffect(() => {
        /*
         * let settingsArr = JSON.parse(JSON.stringify(settings));
         * settingsArr = settingsArr.filter((setting) => (setting.tenantkey ? tenant[setting.tenantkey] : true));
         * setSettings(settingsArr);
         */
        handlePromise();
    }, []);

    const handlePromise = () => {
        const appsPromise = new Promise((resolve, reject) => {
            setLoading(true);
            api.get(url.TV_APPS, '', domains.ANDROID_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Policy failed.');
                })
                .catch((err) => reject('Policy failed.'));
        });

        const policyPromise = new Promise((resolve, reject) => {
            if (recordID) {
                setLoading(true);
                api.get(url.CONFIG + '/' + recordID, '', domains.ANDROID_V1)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);
                        else reject('Policy failed.');
                    })
                    .catch((err) => reject('Policy failed.'));
            } else {
                reject('Policy failed.');
            }
        });

        Promise.allSettled([policyPromise, appsPromise])
            .then((result) => {
                let folderApps = [];
                if (result[0].status === 'fulfilled') {
                    const data = result[0].value;
                    folderApps =
                        data?.config?.branding?.folders?.folders?.length > 0
                            ? prepareFoldersValue(data?.config?.branding?.folders?.folders, result[1].value || [])
                            : [];
                    let obj = {
                        setting_default_password: data.config?.settings?.default_password
                            ? data.config?.settings?.default_password
                            : data.config?.settings?.kioskExitPassword,
                        setting_custom_password: data.config?.settings?.custom_password
                            ? data.config?.settings?.custom_password
                            : data.config?.settings?.kioskExitPassword
                    };
                    setFeatureEnabled({
                        show_wifi: !!data.config.settings?.wifi?.show,
                        show_bluetooth: !!data?.config?.settings?.bluetooth?.show,
                        show_screen_orientation: !!data?.config?.settings?.orientation?.show,
                        show_brigntness: !!data?.config?.settings?.brightness?.show,
                        show_device_details: !!data?.config?.settings?.details?.show,
                        show_system_settings: !!data?.config?.settings?.systemSettings?.show,
                        show_kiosk_exit: !!data?.config?.settings?.exitQR?.show,

                        req_admin_password_wifi: !!data?.config?.settings?.wifi?.reqPassword,
                        req_admin_password_bluetooth: !!data?.config?.settings?.bluetooth?.reqPassword,
                        req_admin_password_screen_orientation: !!data?.config?.settings?.orientation?.reqPassword,
                        req_admin_password_brightness: !!data?.config?.settings?.brightness?.reqPassword,
                        req_admin_password_device_details: !!data?.config?.settings?.details?.reqPassword,
                        req_admin_password_system_settings: !!data?.config?.settings?.systemSettings?.reqPassword,
                        req_admin_password_kiosk_exit: !!data?.config?.settings?.exitQR?.reqPassword
                    });
                    setPasswords({
                        wifi: data.config?.settings?.wifi?.password ? data.config?.settings?.wifi?.password : obj.setting_default_password,
                        bluetooth: data?.config?.settings?.bluetooth?.password
                            ? data?.config?.settings?.bluetooth?.password
                            : obj.setting_default_password,
                        screen_orientation: data?.config?.settings?.orientation?.password
                            ? data?.config?.settings?.orientation?.password
                            : obj.setting_default_password,
                        brightness: data?.config?.settings?.brightness?.password
                            ? data?.config?.settings?.brightness?.password
                            : obj.setting_default_password,
                        details: data?.config?.settings?.details?.password
                            ? data?.config?.settings?.details?.password
                            : obj.setting_default_password,
                        system_settings: data?.config?.settings?.systemSettings?.password
                            ? data?.config?.settings?.systemSettings?.password
                            : obj.setting_default_password,
                        kiosk_exit: data?.config?.settings?.exitQR?.password
                            ? data?.config?.settings?.exitQR?.password
                            : obj.setting_default_password
                    });
                    setFormValues({
                        ...obj,
                        name: data?.name,
                        enableHeader: !!data?.config?.branding?.header?.enabled,
                        companyName: data?.config?.branding?.header?.companyName,
                        headerTransparent: !!data?.config?.branding?.header?.enableTransparency,
                        headerBackgroundColor: data?.config?.branding?.header?.bgColor,
                        headerText: data?.config?.branding?.header?.text,
                        headerTextColor: data?.config?.branding?.header?.textColor,
                        showSerial: data?.config?.branding?.header?.showSerial,
                        showIMEI: data?.config?.branding?.header?.imei,
                        enableFooter: !!data?.config?.branding?.footer?.enabled,
                        footerBackgroundColor: data.config?.branding?.footer?.bgColor,
                        footerTextColor: data.config?.branding?.footer?.textColor,
                        grid: data.config?.branding?.gridSize,
                        iconOrder: data.config?.branding?.iconOrder,
                        enableCompanyLogo: data.config?.branding?.companyLogo?.enabled,
                        companyLogo: data.config?.branding?.companyLogo?.logo,
                        enableLockScreenMessage: data?.config?.branding?.enabled,
                        lockScreenMessage: data.config?.branding?.lockScreenMessage?.message,
                        iconLabelTransparent: !!data.config?.branding?.appIconLabelTransparency,
                        iconTextColor: data.config?.branding?.appIconTextColor,
                        bookmarks: data.config?.branding?.bookmarks,
                        enableAppLabel: !data.config?.branding?.hideappLabel,
                        footerText: data.config?.branding?.footer?.text,
                        enableLabelShadow: data.config?.branding?.appLabelShadow,
                        appsPerRow: [
                            { label: 1, value: 1 },
                            { label: 2, value: 2 },
                            { label: 3, value: 3 },
                            { label: 4, value: 4 },
                            { label: 5, value: 5 }
                        ].find((val) => val.value === data.config?.branding?.iconsPerRow),
                        iconSize: data.config?.branding?.iconSize,
                        footerTransparent: data.config?.branding?.footer?.enableTransparency,
                        enableFolder: !!data?.config?.branding?.folders?.enabled,
                        folders: folderApps,

                        req_timer_wifi: !!data.config?.settings?.wifi?.reqTimer,
                        req_timer_bluetooth: !!data?.config?.settings?.bluetooth?.reqTimer,
                        req_timer_brightness: !!data?.config?.settings?.brightness?.reqTimer,
                        req_timer_device_details: !!data?.config?.settings?.details?.reqTimer,
                        req_timer_system_settings: !!data?.config?.settings?.systemSettings?.reqTimer,
                        req_timer_kiosk_exit: !!data.config?.settings?.exitQR?.reqTimer,
                        req_timer_screen_orientation: !!data?.config?.settings?.orientation?.reqTimer,

                        timer_wifi: data?.config?.settings?.wifi?.closeTimer ? data?.config?.settings?.wifi?.closeTimer : 0,
                        timer_bluetooth: data?.config?.settings?.bluetooth?.closeTimer ? data?.config?.settings?.bluetooth?.closeTimer : 0,
                        timer_brightness: data?.config?.settings?.brightness?.closeTimer
                            ? data?.config?.settings?.brightness?.closeTimer
                            : 0,
                        timer_device_details: data?.config?.settings?.details?.closeTimer ? data?.config?.settings?.details?.closeTimer : 0,
                        timer_system_settings: data?.config?.settings?.systemSettings?.closeTimer
                            ? data?.config?.settings?.systemSettings?.closeTimer
                            : 0,
                        timer_kiosk_exit: data?.config?.settings?.exitQR?.closeTimer ? data?.config?.settings?.exitQR?.closeTimer : 0,
                        timer_screen_orientation: data?.config?.settings?.orientation?.closeTimer
                            ? data?.config?.settings?.orientation?.closeTimer
                            : 0,

                        wifi: data.config?.settings?.wifi?.password_type,
                        bluetooth: data.config?.settings?.bluetooth?.password_type,
                        screen_orientation: data.config?.settings?.orientation?.password_type,
                        details: data.config?.settings?.details?.password_type,
                        kiosk_exit: data.config?.settings?.exitQR?.password_type,
                        brightness: data.config?.settings?.brightness?.password_type,
                        system_settings: data?.config?.settings?.systemSettings?.password_type
                    });
                }
                if (result[1].status === 'fulfilled') {
                    let apps = [];
                    folderApps.forEach((folder) => {
                        apps = apps.concat(folder.apps);
                    });
                    apps = apps.map((app) => app.packagename);
                    result[1].value = (result[1].value || [])?.filter((app) => !apps.includes(app.packagename));
                    setApplications(sortBy(result[1].value, 'name'));
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...formValues,
            name: formValues?.name ? formValues.name : '',
            setting_default_password: formValues?.setting_default_password
                ? formValues?.setting_default_password
                : formValues?.kioskExitPassword
                ? formValues?.kioskExitPassword
                : tenant?.DEVICE_DEFAULT_PASSWORD,
            setting_default_password_changing: formValues?.setting_default_password
                ? formValues?.setting_default_password
                : formValues?.kioskExitPassword
                ? formValues?.kioskExitPassword
                : tenant?.DEVICE_DEFAULT_PASSWORD,
            setting_custom_password: formValues?.setting_custom_password
                ? formValues?.setting_custom_password
                : formValues?.kioskExitPassword
                ? formValues?.kioskExitPassword
                : tenant?.DEVICE_DEFAULT_PASSWORD
                ? tenant?.DEVICE_DEFAULT_PASSWORD
                : '',
            setting_custom_password_changing: formValues?.setting_custom_password
                ? formValues?.setting_custom_password
                : formValues?.kioskExitPassword
                ? formValues?.kioskExitPassword
                : tenant?.DEVICE_DEFAULT_PASSWORD
                ? tenant?.DEVICE_DEFAULT_PASSWORD
                : '',
            close_time: formValues?.close_time || '00:05',

            wifi: formValues.wifi === 'false' ? 'false' : 'true',
            bluetooth: formValues.bluetooth === 'false' ? 'false' : 'true',
            details: formValues.details === 'false' ? 'false' : 'true',
            system_settings: formValues.system_settings === 'false' ? 'false' : 'true',
            kiosk_exit: formValues.kiosk_exit === 'false' ? 'false' : 'true',
            brightness: formValues.brightness === 'false' ? 'false' : 'true',
            screen_orientation: formValues.screen_orientation === 'false' ? 'false' : 'true',

            enableHeader: !!formValues?.enableHeader,
            showSerial: !!formValues?.showSerial,
            showIMEI: !!formValues?.showIMEI,
            headerBackgroundColor: formValues?.headerBackgroundColor || '#0D807B',
            headerTextColor: formValues?.headerTextColor || '#000000',
            enableFooter: !!formValues?.enableFooter,
            footerBackgroundColor: formValues?.footerBackgroundColor || '#0D807B',
            footerTextColor: formValues?.footerTextColor || '#000000',
            backgroundColor: formValues?.backgroundColor || '#FFFFFF',
            grid: formValues?.grid || '6x6',
            lockScreenMessage: formValues?.lockScreenMessage || '',
            showSoftKeys: formValues?.showSoftKeys || { recent: false, home: false },
            showSystemApps: !!formValues.showSystemApps,
            showPrivateApps: !!formValues.showPrivateApps,
            iconOrder: formValues?.iconOrder || 'atoz',
            enableLockScreenMessage: !!formValues?.enableLockScreenMessage,
            iconLabelTransparent: !!formValues?.iconLabelTransparent,
            iconTextColor: formValues?.appIconTextColor || '#FFFFFF',
            enableCompanyLogo: !!formValues?.enableCompanyLogo,
            bookmarks: formValues?.bookmarks?.length > 0 ? formValues?.bookmarks : [],
            enableAppLabel: !!formValues?.enableAppLabel,
            footerText: formValues?.footerText,
            enableLabelShadow: !!formValues?.enableLabelShadow,
            headerTransparent: !!formValues?.headerTransparent,
            footerTransparent: !!formValues?.footerTransparent,
            iconSize: formValues?.iconSize ? formValues.iconSize : '80',
            appsPerRow: formValues?.appsPerRow ? formValues?.appsPerRow : { label: 4, value: 4 },
            companyLogo: formValues?.companyLogo || undefined,
            companyName: formValues?.companyName || undefined,
            headerText: formValues?.headerText || undefined,

            req_timer_wifi: formValues?.req_timer_wifi ? formValues?.req_timer_wifi : false,
            req_timer_bluetooth: formValues?.req_timer_bluetooth ? formValues?.req_timer_bluetooth : false,
            req_timer_brightness: formValues?.req_timer_brightness ? formValues?.req_timer_brightness : false,
            req_timer_device_details: formValues?.req_timer_device_details ? formValues?.req_timer_device_details : false,
            req_timer_system_settings: formValues?.req_timer_system_settings ? formValues?.req_timer_system_settings : false,
            req_timer_exit_qr: formValues?.req_timer_exit_qr ? formValues.req_timer_exit_qr : false,
            req_timer_screen_orientation: formValues?.req_timer_screen_orientation ? formValues?.req_timer_screen_orientation : false,

            prepareTabs: !!formValues?.name,

            enableFolder: !!formValues.enableFolder,
            folders: formValues?.folders?.length > 0 ? formValues?.folders : []
        },
        onSubmit: (values) => {
            const dataObj = {
                name: values?.name,
                config: {
                    branding: {
                        header: {
                            enabled: values?.enableHeader,
                            companyName: values?.companyName,
                            text: values?.headerText,
                            bgColor: values?.headerBackgroundColor,
                            textColor: values?.headerTextColor,
                            showSerial: values?.showSerial,
                            enableTransparency: values?.headerTransparent
                        },
                        companyLogo: {
                            enabled: values?.enableCompanyLogo,
                            logo: values?.companyLogo
                        },
                        iconsPerRow: values?.appsPerRow?.value,
                        iconSize: values?.iconSize,
                        enableAppLabel: values?.enableAppLabel,
                        appLabelShadow: values?.enableLabelShadow,
                        iconOrder: values?.iconOrder,
                        footer: {
                            enabled: values?.enableFooter,
                            text: values?.footerText,
                            bgColor: values?.footerBackgroundColor,
                            textColor: values?.footerTextColor,
                            enableTransparency: values?.footerTransparent
                        },
                        folders: {
                            enabled: values?.enableFolder,
                            folders: values?.folders
                        }
                    },
                    settings: {
                        wifi: {
                            show: featureEnabled?.show_wifi,
                            reqPassword: featureEnabled?.req_admin_password_wifi,
                            reqTimer: values?.req_timer_wifi,
                            closeTimer: values?.timer_wifi,
                            password_type: values?.wifi,
                            password: passwords?.wifi
                        },
                        bluetooth: {
                            show: featureEnabled?.show_bluetooth,
                            reqPassword: featureEnabled?.req_admin_password_bluetooth,
                            reqTimer: values?.req_timer_bluetooth,
                            closeTimer: values?.timer_bluetooth,
                            password_type: values?.bluetooth,
                            password: passwords?.bluetooth
                        },
                        orientation: {
                            show: featureEnabled?.show_screen_orientation,
                            reqPassword: featureEnabled?.req_admin_password_screen_orientation,
                            reqTimer: values?.req_timer_screen_orientation,
                            closeTimer: values?.timer_screen_orientation,
                            password_type: values?.screen_orientation,
                            password: passwords?.screen_orientation
                        },
                        brightness: {
                            show: featureEnabled?.show_brigntness,
                            reqPassword: featureEnabled?.req_admin_password_brightness,
                            reqTimer: values?.req_timer_brightness,
                            closeTimer: values?.timer_brightness,
                            password_type: values?.brightness,
                            password: passwords?.brightness
                        },
                        details: {
                            show: featureEnabled?.show_device_details,
                            reqPassword: featureEnabled?.req_admin_password_device_details,
                            reqTimer: values?.req_timer_device_details,
                            closeTimer: values?.timer_device_details,
                            password_type: values?.details,
                            password: passwords?.details
                        },
                        systemSettings: {
                            show: featureEnabled?.show_system_settings,
                            reqPassword: featureEnabled?.req_admin_password_system_settings,
                            reqTimer: values?.req_timer_system_settings,
                            closeTimer: values?.timer_system_settings,
                            password_type: values?.system_settings,
                            password: passwords?.system_settings
                        },
                        exitQR: {
                            show: featureEnabled?.show_kiosk_exit,
                            reqPassword: featureEnabled?.req_admin_password_kiosk_exit,
                            reqTimer: values?.req_timer_kiosk_exit,
                            closeTimer: values?.timer_kiosk_exit,
                            password_type: values?.kiosk_exit,
                            password: passwords?.kiosk_exit
                        }
                    }
                }
            };
            let apiService;
            if (formType === 'edit') apiService = api.patch(url.CONFIG + '/' + recordID, dataObj, true, domains.ANDROID_V1);
            else apiService = api.create(url.CONFIG, dataObj, true, domains.ANDROID_V1);
            apiService
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        toast.success(formType === 'edit' ? toastMessages.configurationUpdated : toastMessages.configurationCreated);
                        history.push('/atvlaunchersetup');
                    }
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        }
    });

    const prepareFoldersValue = (folders, apps) => {
        let arr = [];
        folders?.forEach((folder) => {
            let appArr = apps?.filter((app) => folder.apps.find((folderApp) => folderApp.packagename === app.packagename));
            arr.push({ name: folder.name, apps: appArr });
        });
        return arr;
    };

    const getFolderApps = (folders) => {
        let apps = [];
        folders.forEach((folder) => (apps = apps.concat(folder.apps)));
        return apps;
    };

    const handleEnableChange = (e, keyVal, setting, isVal) => {
        let features = { ...featureEnabled, [keyVal]: e };
        if (isVal === 'value' && !e) {
            features[setting.password_value] = false;
            validation.setValues({ ...validation.values, [setting.password_type]: 'true' });
            setPasswords({ ...passwords, [setting.password]: validation.values['setting_default_password'] });
        }
        setFeatureEnabled({ ...features });
    };

    const handlePassword = (passwordType, keyVal) => {
        setType(passwordType);
        setKey(keyVal);
        setHeader(passwordType === 'default_password' ? 'Default Password' : 'Custom Password');
        setShowDefaultPassword(true);
    };

    const handleDownload = (val) => {
        DownloadBunnyFile(val, val, setLoading);
    };

    const defaultPasswordBody = () => {
        return (
            <div>
                <div className="input-group">
                    {formType !== 'view' ? (
                        <>
                            <div className="d-flex align-items-center w-100">
                                <Input
                                    name={key}
                                    id={key}
                                    className="form-control"
                                    placeholder={type === 'default_password' ? 'Enter Default Password' : 'Enter Custom Password'}
                                    type="text"
                                    maxLength={12}
                                    validate={{ required: { value: true } }}
                                    onChange={validation.handleChange}
                                    value={validation.values?.[key] || ''}
                                    invalid={validation.touched?.[key] && validation.errors?.[key] ? true : false}
                                />
                            </div>
                            <div className="w-100">
                                <div className="d-flex align-items-center justify-content-center">
                                    <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                                        <div className="d-flex">
                                            <i className="ri-information-line link-warning me-2 d-flex align-items-center ps-1 fs-15" />{' '}
                                            This will act as {type === 'default_password' ? 'default' : 'custom'} password
                                        </div>
                                    </Alert>
                                </div>
                            </div>
                        </>
                    ) : validation?.values?.[key] ? (
                        validation?.values?.[key]
                    ) : (
                        '–'
                    )}
                    {validation.touched?.[key] && validation.errors?.[key] ? (
                        <p className="m-0 mt-2 text-danger">{validation.errors?.[key]}</p>
                    ) : null}
                </div>
            </div>
        );
    };

    const handlePasswordTypeChange = (e, keyVal, passwordType) => {
        if (passwordType === 'default') setPasswords({ ...passwords, [keyVal]: validation.values.setting_default_password });
        else setPasswords({ ...passwords, [keyVal]: validation.values.setting_custom_password });
    };

    const handlePasswordChange = (e, keyVal) => {
        setPasswords({ ...passwords, [keyVal]: e.target.value });
    };

    const handleIndividualTimerChange = (e, field) => {
        if (Number(e.target.value) > 600) return;
        let regex = new RegExp(/^[0-9]+$/);
        let isValid = regex.test(e.target.value);
        if (isValid || e.target.value === '') validation.setValues({ ...validation.values, [field]: e.target.value });
    };

    const handleCloseDefaultPassword = () => {
        validation.setValues({
            ...validation.values,
            close_time_changing: validation.values.close_time,
            setting_default_password_changing: validation.values.setting_default_password
        });
        setShowDefaultPassword(false);
        setTimerModal(false);
    };

    const handleDefaultPasswordChange = (e, val) => {
        let values = { ...validation.values };
        let passwordsObj = { ...passwords };
        valuesArr.forEach((value) => {
            if (validation.values[value] === val) passwordsObj[value] = e;
        });
        if (val === 'false') values.setting_custom_password = e;
        else values.setting_default_password = e;
        setPasswords({ ...passwordsObj });
        setShowDefaultPassword(false);
        validation.setValues({ ...values });
    };

    useEffect(() => {
        if (Number(validation.values.appsPerRow) > 5) {
            validation.setValues({ ...validation.values, appsPerRow: 5 });
        }
    }, [validation.values.appsPerRow]);

    const [tabsList, setTabsList] = useState([]);

    useEffect(() => {
        const tabs = [];
        if (tenant.EMM_LAUNCHER_BRANDING) tabs.push({ tabID: 'branding', tabName: 'Branding', tabContent: tabsBody('branding') });
        tabs.push({ tabID: 'admin_settings', tabName: 'Settings', tabContent: tabsBody('admin_settings') });
        setTabsList(tabs);
        if (tabs?.length === 1) setSelectedTab(tabs?.[0]?.tabID);
    }, [validation.values, featureEnabled, passwords, applications]);

    const handleBrandingChange = (brandingKey, val) => {
        validation.setValues({ ...validation.values, [brandingKey]: val });
    };

    const handleBookmark = (e, remove) => {
        let bookmarkArr = [...validation.values.bookmarks];
        if (remove) {
            bookmarkArr = bookmarkArr.filter((bookmark) => bookmark.url !== e.url);
            validation.setValues({ ...validation.values, bookmarks: [...bookmarkArr] });
        } else {
            const bookmarkFound = bookmarkArr.find((bookmark) => bookmark.url === validation.values.bookmark);
            if (bookmarkFound) {
                toast.error(toastMessages.existingBookmark);
                return;
            }
            validation.setValues({
                ...validation.values,
                bookmark: '',
                bookmarkName: '',
                bookmarks: [...validation.values.bookmarks, { name: validation.values.bookmarkName, url: validation.values.bookmark }]
            });
        }
    };

    const handleFileUpload = (files, fileKey) => {
        if (
            // (fileKey === 'alertSound' && files?.filename?.endsWith('.mp3')) ||
            fileKey === 'companyLogo' &&
            ['.jpg', '.jpeg', '.png'].some((extension) => files?.filename?.endsWith(extension))
        ) {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', files?.file);
            api.create(url.FILE, formData)
                .then((resp) => {
                    // setFileParams({ file: '', type: '' });
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') validation.setValues({ ...validation.values, [fileKey]: resp.data });
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        } else {
            toast.error(toastMessages.invalidFileFormat);
            validation.setValues({ ...validation.values, [fileKey]: '' });
        }
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };

    const toggleDropdown = (ind) => {
        setOpenDropDown({ [ind]: !openDropDown[ind] });
    };

    const tabsBody = (tab) => {
        if (tab === 'admin_settings')
            return (
                <SettingsTemplate
                    formType={formType}
                    validation={validation}
                    handlePassword={handlePassword}
                    featureEnabled={featureEnabled}
                    handleEnableChange={handleEnableChange}
                    handleIndividualTimerChange={handleIndividualTimerChange}
                    handlePasswordTypeChange={handlePasswordTypeChange}
                    handlePasswordChange={handlePasswordChange}
                    passwords={passwords}
                    settings={settings}
                />
            );
        else
            return (
                <CustomLauncherThemeTemplate
                    validation={validation}
                    formType={formType}
                    handleKeyDown={handleKeyDown}
                    applications={applications}
                    setApplications={setApplications}
                    openDropDown={openDropDown}
                    toggleDropdown={toggleDropdown}
                    handleDownload={handleDownload}
                    handleBrandingChange={handleBrandingChange}
                    handleBookmark={handleBookmark}
                    handleFileUpload={handleFileUpload}
                    handleEditImage={(fileType) => {
                        validation.setValues({ ...validation.values, [fileType]: '' });
                    }}
                />
            );
    };

    const checkSaveDisable = () => {
        let invalid = false;
        if (validation?.values?.enableCompanyLogo) invalid = !validation.values?.companyLogo;
        if (validation?.values?.enableFooter) invalid = !validation?.values?.footerText;
        if (validation?.values?.enableFolder) invalid = !validation?.values?.folders?.length > 0;
        TVSettingFieldsArray?.forEach((setting) => {
            if (
                setting.req_timer && !!validation.values[setting.req_timer]
                    ? !validation.values[setting.timer] ||
                      validation.values[setting.timer] === '' ||
                      Number(validation.values[setting.timer]) === 0
                    : false
            ) {
                invalid = true;
                return;
            }
        });
        return !validation.values.name || validation.values.name === '' || invalid;
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={formType === 'view' ? 'View Launcher' : formType === 'edit' ? 'Edit Launcher' : 'Add Launcher'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="atvlaunchersetup"
                    />
                    <Card>
                        <CardBody>
                            <div className="ms-1 mb-3 d-flex align-items-center">
                                Name
                                {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                <div className={'input-group ms-2'}>
                                    {formType !== 'view' ? (
                                        <div className="w-25 ms-5">
                                            <Input
                                                name={'name'}
                                                id={'name'}
                                                className={'form-control'}
                                                placeholder={'Enter Name'}
                                                type={'text'}
                                                maxLength={'30'}
                                                validate={{ required: { value: true } }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values['name'] || ''}
                                                invalid={validation.touched['name'] && validation.errors['name'] ? true : false}
                                            />
                                        </div>
                                    ) : validation?.values['name'] ? (
                                        validation?.values['name']
                                    ) : (
                                        '–'
                                    )}
                                    {validation.touched['name'] && validation.errors['name'] ? (
                                        <p className="m-0 mt-2 text-danger">{validation.errors['name']}</p>
                                    ) : null}
                                </div>
                            </div>
                            <TabsComponent
                                paddingRequired={true}
                                paddingClass={'p-2'}
                                tabsList={tabsList}
                                defaultTabID={selectedTab}
                                toggleTab={(tab) => setSelectedTab(tab)}
                            />
                        </CardBody>
                        <CardFooter>
                            <div className="d-flex align-items-center gap-2 justify-content-end">
                                <button type="reset" className="btn btn-light" onClick={() => history.push('/atvlaunchersetup')}>
                                    Cancel
                                </button>
                                {formType !== 'view' && (
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        id="add-btn"
                                        disabled={checkSaveDisable()}
                                        onClick={validation.handleSubmit}
                                    >
                                        {formType === 'edit' ? 'Update' : 'Save'}
                                    </button>
                                )}
                            </div>
                        </CardFooter>
                    </Card>
                    <CommonModal
                        size={'md'}
                        cancelText={'Cancel'}
                        modalheader={header}
                        show={showDefaultPassword}
                        disabled={
                            type === 'default_password'
                                ? !validation.values.setting_default_password_changing ||
                                  validation.values.setting_default_password_changing === '' ||
                                  validation.values.setting_default_password_changing?.length < 4
                                : !validation.values.setting_custom_password_changing ||
                                  validation.values.setting_custom_password_changing === '' ||
                                  validation.values.setting_custom_password_changing?.length < 4
                        }
                        onCloseClick={() => handleCloseDefaultPassword()}
                        handleClick={() =>
                            handleDefaultPasswordChange(
                                type === 'default_password'
                                    ? validation.values.setting_default_password_changing
                                    : validation.values.setting_custom_password_changing,
                                type === 'default_password' ? 'true' : 'false'
                            )
                        }
                        handleModalBody={() => defaultPasswordBody()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddTvLauncherSetup;
