import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import { Tooltip, getFormTypeAndRecordId } from '../../../Components/Common/Util';
import { DEFAULT_ALLOWED_DOMAINS } from '../../../Components/constants/constants';
import AllowedDomains from '../../../assets/images/common/png/common/allowedDomains.png';
import BlockedDomains from '../../../assets/images/common/png/common/blockedDomains.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';

const AddFirewall2 = () => {
    const urlconf = useEnv();

    let history = useHistory();
    let api = new APIClient();
    const [loading, setLoading] = useState(false);
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let mode = formTypeAndId['formType'];
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    const [firewall, setFirewall] = useState('');
    const [uploadType, setUploadType] = useState('');
    const [fileUploader, setFileUploader] = useState('');
    const [firewallTypes, setFirewallTypes] = useState([
        { label: 'Allowed Domains', description: 'The below added domains will be accessible by the users on the device.', key: 'allowed' },
        {
            label: 'Blocked Domains',
            description: 'The below added domains will be inaccessible by the users on the device.',
            key: 'blocked'
        }
    ]);

    document.title =
        formType === 'view' || formType === 'viewChecker' ? 'View Firewall' : formType === 'edit' ? 'Edit Firewall' : 'Add Firewall';

    const [showDomains, setShowDomains] = useState(false);
    const [modalHeader, setModalHeader] = useState('');
    const [viewType, setViewType] = useState('');
    const [filterVal, setFilterVal] = useState('');
    const [windowFilter, setWindowFilter] = useState({ allowed: '', blocked: '' });
    const [listValidationPanel, setListValidationPanel] = useState({
        allowed: [],
        blocked: [],
        default: DEFAULT_ALLOWED_DOMAINS
    });
    const [listValidationWindow, setListValidationWindow] = useState({
        allowed: [],
        blocked: [],
        default: DEFAULT_ALLOWED_DOMAINS
    });

    useEffect(() => {
        if (recordID) getFirewall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFirewall = () => {
        setLoading(true);
        api.get(url.CONFIG + '/' + recordID)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    let record = resp.data;
                    let obj = {
                        ...listValidation.values,
                        allowed: [...record?.config?.allowedDomains],
                        blocked: [...record?.config?.blockedDomains],
                        defaultAction: record?.config?.defaultAction,
                        name: record?.name
                    };
                    listValidation.setValues(obj);
                    setListValidationPanel(obj);
                    setListValidationWindow(obj);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { allowed: '', blocked: '' }
    });

    const listValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            allowed: [],
            blocked: [],
            defaultAction: 'allow',
            name: '',
            default: DEFAULT_ALLOWED_DOMAINS
        },
        onSubmit: (values) => {
            setLoading(true);
            let obj = {
                name: values?.name,
                configType: 'FIREWALL',
                config: { allowedDomains: values?.allowed, blockedDomains: values?.blocked, defaultAction: values?.defaultAction }
            };
            if (recordID) {
                obj.id = recordID;
                obj.config = { ...obj.config, storageUrl: firewall?.storageUrl };
            }
            createNdUpdate(obj);
        }
    });

    const createNdUpdate = (dataObj) => {
        let apiService;
        if (recordID) apiService = api.update(url.CONFIG + '/' + recordID, dataObj);
        else apiService = api.create(url.CONFIG, dataObj);

        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(recordID ? toastMessages.firewallUpdated : toastMessages.firewallCreated);
                    history.push('/firewall');
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    const handleAddDomain = (type) => {
        let arr = JSON.parse(JSON.stringify(listValidation.values[type.key]));
        if (
            listValidation.values.allowed.includes(validation.values[type.key]) ||
            listValidation.values.blocked.includes(validation.values[type.key]) ||
            listValidation.values.default?.includes(validation.values[type.key])
        ) {
            toast.error(toastMessages.domainAlreadyExists);
            return;
        }
        arr.push(validation.values[type.key]);
        let obj = { ...listValidation.values, [type.key]: [...arr] };
        setWindowFilter({ allowed: '', blocked: '' });
        listValidation.setValues(obj);
        setListValidationWindow(obj);
        setListValidationPanel(obj);
        validation.setValues({ ...validation.values, [type.key]: '' });
    };

    const handleRemoveDomain = (ele, type) => {
        let arr = JSON.parse(JSON.stringify(listValidation.values[type.key]));
        arr = arr.filter((val) => val !== ele);
        let obj = { ...listValidation.values, [type.key]: [...arr] };
        listValidation.setValues(obj);
        setListValidationWindow(obj);
        setListValidationPanel(obj);
    };

    const downloadSampleFile = () => {
        setLoading(true);
        var csvFileData = 'Domain';
        var a = document.createElement('a');
        a.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvFileData);
        a.target = '_blank';
        a.download = 'Firewall template.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    };

    const processData = (lines, type) => {
        let urlsArr = [];
        let linesArr = [];
        lines.forEach((line, ind) => {
            if (ind !== 0 && !linesArr.includes(line)) linesArr.push(line);
        });
        linesArr.forEach((line) => {
            if (line?.length > 0) {
                line = line?.trimEnd('/r');

                if (
                    type.key === 'allowed' &&
                    !listValidation.values?.blocked?.includes(line?.trimEnd('/r')) &&
                    !listValidation.values?.default?.includes(line?.trimEnd('/r')) &&
                    !listValidation.values?.allowed?.includes(line?.trimEnd('/r'))
                )
                    urlsArr.push(line?.trimEnd('/r')?.trim());
                else if (
                    type.key === 'blocked' &&
                    !listValidation.values?.allowed?.includes(line?.trimEnd('/r')) &&
                    !listValidation.values?.blocked?.includes(line?.trimEnd('/r')) &&
                    !listValidation.values?.default?.includes(line?.trimEnd('/r'))
                )
                    urlsArr.push(line?.trimEnd('/r')?.trim());
            }
        });
        let obj = { ...listValidation.values, [type.key]: [...listValidation.values[type.key], ...urlsArr] };
        listValidation.setValues(obj);
        setListValidationWindow(obj);
        setListValidationPanel(obj);
    };

    const handleUploadCSV = (e, type) => {
        const file = e.target.files[0];
        if (!file.name?.endsWith('.csv')) {
            toast.error(toastMessages.invalidFileFormat);
            return;
        }
        const reader = new FileReader();

        reader.onload = function (ev) {
            const contents = ev.target.result;
            const lines = contents.split('\n');
            if (lines[0]?.trim('/r') === 'Domain') processData(lines, type);
            else return;
        };
        reader.readAsText(file);
    };

    const handleUploadFile = (type) => {
        setUploadType(type);
        fileUploader.click();
    };

    const handleClearAll = (type) => {
        validation.setValues({ ...validation.values, [type.key]: '' });
        let obj = { ...listValidation.values, [type.key]: [] };
        listValidation.setValues(obj);
        setListValidationWindow(obj);
        setListValidationPanel(obj);
    };

    const handleViewAll = (type) => {
        setModalHeader(type.key === 'allowed' ? 'Allowed Domains' : type.key === 'blocked' ? 'Blocked Domains' : 'Default Allowed Domains');
        setViewType(type.key);
        setFilterVal('');
        setShowDomains(true);
    };

    const closeModal = () => {
        setModalHeader('');
        setFilterVal('');
        setListValidationPanel({ ...listValidation.values });
        setShowDomains(false);
    };

    const handleFilter = (e) => {
        setFilterVal(e.target.value);
        let arr = listValidation.values[viewType];
        arr = arr.filter((val) => val?.includes(e.target.value));
        setListValidationPanel({ ...listValidationPanel, [viewType]: arr });
    };

    const handleWindowFilter = (e, type) => {
        setWindowFilter({ ...windowFilter, [type.key]: e.target.value });
        let arr = listValidation.values[type.key];
        arr = arr.filter((val) => val?.includes(e.target.value));
        setListValidationWindow({ ...listValidationPanel, [type.key]: arr });
    };

    const handleOffcanvasBody = () => {
        return (
            <>
                {viewType === 'default' ? (
                    <div className="border rounded bg-soft-warning p-2">
                        <span className="fw-bold">Note :</span>
                        <span className="fw-medium"> These domains are required by default for smooth functioning of MDM</span>
                    </div>
                ) : (
                    <div className="form-icon">
                        <input
                            autoComplete="off"
                            type="text"
                            id="search-bar-0"
                            value={filterVal || ''}
                            className={'form-control form-control-icon dash-filter-picker'}
                            placeholder={'Search'}
                            onChange={handleFilter}
                        />
                        <i className="ri-search-line link-info"></i>
                    </div>
                )}
                <div className="pt-4">
                    <div className="bg-soft-secondary p-2 fw-semibold border rounded">Domain Name</div>
                </div>
                {listValidationPanel[viewType]?.map((ele, index) => {
                    return (
                        <div key={index} className="p-2 border">
                            <div className="d-flex align-items-center justify-content-between">
                                {ele}
                                {viewType !== 'default' && formType !== 'view' ? (
                                    <i
                                        className="ri-delete-bin-line cursor-pointer fs-16 link-primary"
                                        onClick={() => handleRemoveDomain(ele, { key: viewType })}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={formType === 'view' ? 'View Firewall' : formType === 'edit' ? 'Edit Firewall' : 'Add Firewall'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="firewall"
                    />
                    <Card>
                        <CardHeader>
                            <div className="fw-semibold fs-15 mb-1">Firewall</div>
                            <div className="fs-13">
                                Firewall can be configured to allow or block specific domains based on organizational policies and security
                                requirements. The Firewall configuration will be saved as a proxy on the device. It can take up to 30mins
                                depending on the device model.
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                Name
                                {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                <div className={'input-group'}>
                                    {formType !== 'view' ? (
                                        <div className="w-30 ms-5">
                                            <Input
                                                name={'name'}
                                                id={'name'}
                                                className={'form-control'}
                                                placeholder={'Enter Name'}
                                                type={'text'}
                                                maxLength={'30'}
                                                validate={{ required: { value: true } }}
                                                onChange={listValidation.handleChange}
                                                onBlur={listValidation.handleBlur}
                                                value={listValidation.values['name'] || ''}
                                                invalid={listValidation.touched['name'] && listValidation.errors['name'] ? true : false}
                                            />
                                        </div>
                                    ) : listValidation?.values['name'] ? (
                                        <span className="ps-1">{listValidation?.values['name']}</span>
                                    ) : (
                                        '–'
                                    )}
                                    {listValidation.touched['name'] && listValidation.errors['name'] ? (
                                        <p className="m-0 mt-2 text-danger">{listValidation.errors['name']}</p>
                                    ) : null}
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {firewallTypes.map((type, ind) => (
                                    <Col xs={12} md={12} sm={12} lg={6} xl={6} key={ind}>
                                        <Card className="card-height-100 mb-0">
                                            <CardBody className={type.key === 'allowed' ? 'badge-soft-success' : 'badge-soft-danger'}>
                                                <Row>
                                                    <Col>
                                                        <div className="fs-14 fw-semibold">{type.label}</div>
                                                    </Col>
                                                    {type.key === 'allowed' && (
                                                        <Col className="d-flex justify-content-end">
                                                            <div
                                                                className="text-decoration-underline text-primary fw-medium fs-13 cursor-pointer"
                                                                onClick={() => handleViewAll({ key: 'default' })}
                                                            >
                                                                Default Allowed Domains ({listValidationWindow?.default?.length})
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Row>
                                                <Row>
                                                    <div className="d-flex align-items-center fs-13 py-2">
                                                        <i className="ri-error-warning-line"></i>
                                                        <div className="px-1">{type.description}</div>
                                                    </div>
                                                </Row>
                                                <Row className="align-items-start">
                                                    {mode !== 'view' && (
                                                        <>
                                                            <Col>
                                                                <Input
                                                                    name={type.key}
                                                                    id={type.key}
                                                                    className="form-control"
                                                                    placeholder={'Enter Domain'}
                                                                    validate={{ required: { value: true } }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    onKeyDown={(e) => handleKeyDown(e)}
                                                                    value={validation.values[type.key] || ''}
                                                                    invalid={
                                                                        validation.touched[type.key] && validation.errors[type.key]
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm="auto">
                                                                <Button
                                                                    size="sm"
                                                                    type="button"
                                                                    disabled={!validation.values[type.key]}
                                                                    color={'primary'}
                                                                    onClick={() => handleAddDomain(type)}
                                                                >
                                                                    <span className="d-flex align-items-center">
                                                                        <i className="ri-add-line fs-16"></i> Add New
                                                                    </span>
                                                                </Button>
                                                            </Col>
                                                        </>
                                                    )}
                                                    {formType !== 'view' && (
                                                        <Col sm="auto">
                                                            <Button
                                                                size="sm"
                                                                outline
                                                                type="button"
                                                                color="primary"
                                                                onClick={downloadSampleFile}
                                                            >
                                                                <span className="d-flex align-items-center">
                                                                    <i className="ri-download-2-fill me-1 fs-16"></i> Sample File
                                                                </span>
                                                            </Button>
                                                        </Col>
                                                    )}
                                                    {formType !== 'view' && (
                                                        <Col sm="auto">
                                                            <Button
                                                                size="sm"
                                                                type="button"
                                                                color={'primary'}
                                                                onClick={() => handleUploadFile(type)}
                                                            >
                                                                <span className="d-flex align-items-center">
                                                                    <i className="ri-upload-2-fill me-1 fs-16"></i> Upload File
                                                                </span>
                                                            </Button>
                                                            <input
                                                                hidden
                                                                id="file"
                                                                type="file"
                                                                ref={(file) => setFileUploader(file)}
                                                                onChange={(ev) => handleUploadCSV(ev, uploadType)}
                                                                onClick={(ev) => (ev.target.value = null)}
                                                            />
                                                        </Col>
                                                    )}
                                                </Row>
                                            </CardBody>
                                            <Card className="p-0">
                                                <CardHeader className="p-3 border-bottom-dashed border-2">
                                                    <Row className="align-items-center">
                                                        <Col className="fs-14 fw-semibold">
                                                            All Domains ({listValidationWindow?.[type.key]?.length})
                                                        </Col>
                                                        <Col>
                                                            <div className={'form-icon'}>
                                                                <input
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    value={windowFilter[type.key] || ''}
                                                                    autoFocus={true}
                                                                    className={'form-control form-control-icon dash-filter-picker'}
                                                                    placeholder={'Search by Domain'}
                                                                    onChange={(e) => handleWindowFilter(e, type)}
                                                                />
                                                                <i className="ri-search-line link-info"></i>
                                                            </div>
                                                        </Col>
                                                        {formType !== 'view' && (
                                                            <Col sm="auto">
                                                                <div className="w-100">
                                                                    <Button
                                                                        size="md"
                                                                        className="bg-light border-light text-primary"
                                                                        onClick={() => handleClearAll(type)}
                                                                    >
                                                                        Clear All Domains
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <div className="filter-choices-input height-205 overflow-auto">
                                                            <div className="choices" data-type="text">
                                                                <div className="choices__inner min-height-1">
                                                                    <div className="choices__list choices__list--multiple">
                                                                        {listValidationWindow?.[type.key]?.length > 0 ? (
                                                                            listValidationWindow?.[type.key]?.map((ele, indd) => {
                                                                                return (
                                                                                    <div
                                                                                        key={indd}
                                                                                        className={`choices__item choices__item--selectable bg-${
                                                                                            type.key === 'allowed' ? 'success' : 'danger'
                                                                                        } border-white`}
                                                                                    >
                                                                                        <div id={`domain-${indd}-${type.key}`}>
                                                                                            {ele}
                                                                                            {formType !== 'view' && (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="choices__button"
                                                                                                    onClick={() =>
                                                                                                        handleRemoveDomain(ele, type)
                                                                                                    }
                                                                                                >
                                                                                                    Remove item
                                                                                                </button>
                                                                                            )}
                                                                                        </div>
                                                                                        {Tooltip(`domain-${indd}-${type.key}`, ele)}
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <div className="d-flex height-180 align-items-center justify-content-center">
                                                                                <div className="d-flex align-items-center justify-content-center flex-column">
                                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                                        <img
                                                                                            alt=""
                                                                                            height={40}
                                                                                            className="opacity-50"
                                                                                            src={
                                                                                                type.key === 'allowed'
                                                                                                    ? AllowedDomains
                                                                                                    : BlockedDomains
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="d-flex align-items-center justify-content-center fs-13 fw-normal mt-2">
                                                                                        No Domains Found
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </CardBody>
                                                <CardFooter className="border-top-dashed border-2 d-flex justify-content-center fs-14 fw-medium text-primary text-decoration-underline p-2">
                                                    <span
                                                        className={`${
                                                            listValidation.values[type.key]?.length > 0 ? 'cursor-pointer' : 'text-muted'
                                                        }`}
                                                        onClick={() =>
                                                            listValidation.values[type.key]?.length > 0 ? handleViewAll(type) : undefined
                                                        }
                                                    >
                                                        View All
                                                    </span>
                                                </CardFooter>
                                            </Card>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                            <Col>
                                <Row>
                                    <Label className="form-check-label fw-semibold d-flex align-items-center fs-14">
                                        Unlisted Domains
                                        <i className="ms-1 ri-information-line" />
                                    </Label>
                                </Row>
                                <Row className="mt-1">
                                    <div className="d-flex align-items-center fs-13 text-muted">
                                        You can choose a default action whether to allow or block the domains that are not listed above.
                                    </div>
                                </Row>
                                <Row className="mt-2">
                                    <Col sm="auto">
                                        <div className="form-check form-check-inline">
                                            <Input
                                                type="radio"
                                                value={'allow'}
                                                id={'defaultAction'}
                                                name={'defaultAction'}
                                                className="form-check-input"
                                                disabled={formType === 'view'}
                                                onChange={listValidation.handleChange}
                                                checked={listValidation.values.defaultAction === 'allow'}
                                            />
                                            <Label className="form-check-label">Allowed</Label>
                                        </div>
                                    </Col>
                                    <Col sm="auto">
                                        <div className="form-check form-check-inline">
                                            <Input
                                                type="radio"
                                                value={'block'}
                                                id={'defaultAction'}
                                                name={'defaultAction'}
                                                className="form-check-input"
                                                disabled={formType === 'view'}
                                                onChange={listValidation.handleChange}
                                                checked={listValidation.values.defaultAction === 'block'}
                                            />
                                            <Label className="form-check-label">Blocked</Label>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </CardBody>
                        <CardFooter>
                            <div className="d-flex align-items-center gap-2 justify-content-end">
                                <button type="reset" className="btn btn-light" onClick={() => history.push('/firewall')}>
                                    Cancel
                                </button>
                                {formType !== 'view' && (
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        id="add-btn"
                                        disabled={!listValidation.values.name || listValidation.values.name === ''}
                                        onClick={(e) => listValidation.handleSubmit()}
                                    >
                                        {formType === 'edit' ? 'Update' : 'Save'}
                                    </button>
                                )}
                            </div>
                        </CardFooter>
                    </Card>
                    <OffcanvasModal
                        direction="end"
                        toggle={closeModal}
                        open={showDomains}
                        handleCloseClick={closeModal}
                        OffcanvasModalID="firewall"
                        handleOffcanvasBody={handleOffcanvasBody}
                        modalClassName={'w-40'}
                        hideSaveButton={true}
                        offcanvasHeader={modalHeader}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddFirewall2;
