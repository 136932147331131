import React from 'react';
import { Col, Input, Label, Row } from 'reactstrap';

const inputComponent = (props) => {
    const handleNumberChange = (e, field) => {
        let regex = new RegExp(/^\d*$/);
        let valid = regex.test(e.target.value);
        if (
            (valid &&
                (field.checkVal
                    ? Number(props.validation.values[field.checkVal]) >=
                      Number(e.target.value) +
                          field.checkArray
                              .map((ele) => (props.validation.values[ele] ? Number(props.validation.values[ele]) : 0))
                              .reduce((acc, cur) => {
                                  return acc + cur;
                              })
                    : true)) ||
            e.target.value === ''
        ) {
            props.validation.handleChange(e);
        }
    };

    return (
        <Row key={props.index}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-2">
                <Label className="mb-1 fw-medium fs-13 d-flex align-items-center">
                    {props.field.icon && <i className={`${props.field?.icon} me-1 fs-18`}></i>}
                    {props.field.label} {props?.field?.mandatory && <span className="fw-14 text-danger">*</span>}
                </Label>
                <div>{props.field.helpText && <div className="text-muted fs-12 word-wrap-break">{props.field.helpText}</div>}</div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-2">
                <div className="input-group" key={props.index}>
                    <Input
                        className="form-control"
                        type={props.field.type}
                        id={props.field.value}
                        name={props.field.value}
                        placeholder={`Enter ${props.field.label ? props.field.label : ''}`}
                        value={props.validation.values?.[props.field.value] || ''}
                        onChange={(e) => handleNumberChange(e, props.field)}
                        onBlur={props.validation.handleBlur}
                        disabled={props.formType === 'view'}
                    />
                </div>
            </Col>
        </Row>
    );
};

export const radioComponent = (props) => {
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Label className={`${props?.field?.labelColor && 'text-success'} fw-medium form-check-label`}>{props.field.label}</Label>
            </Col>
            {props.field?.helpText && (
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="fs-13 text-muted">
                    {props?.field?.helpText}
                </Col>
            )}

            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-2">
                <Row>
                    {props.field?.radios.map((ele, index) => (
                        <Col key={index} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="form-check form-check-inline mt-2 ">
                                <Input
                                    className="form-check-input"
                                    type="radio"
                                    disabled={ele?.disabled}
                                    id={ele.value}
                                    name={props?.field?.value}
                                    value={ele?.value}
                                    onBlur={props.validation.handleBlur}
                                    onChange={(event) => {
                                        props?.validation.values?.[props?.field?.value] === 'any' &&
                                            props?.validation.setValues({ ...props.validation.values, android: '', windows: '', ios: '' });
                                        props.validation.handleChange({
                                            target: { name: event?.target?.name, value: event?.target?.value }
                                        });
                                    }}
                                    checked={props.validation.values[props.field.value] === ele.value}
                                    // defaultChecked={props?.validation?.values?.platform}
                                />
                                <Label className="form-check-label fw-medium">{ele.label}</Label>
                            </div>
                            {ele?.helpText && <div className="fs-13 text-muted px-4">{ele?.helpText}</div>}
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    );
};

export const Dynamicomponents = (props) => {
    return (
        <React.Fragment>
            {props?.field?.inputType === 'input'
                ? inputComponent({
                      field: props.field,
                      validation: props.validation,
                      index: props.index,
                      formType: props.formType
                  })
                : props.field.inputType === 'radio'
                ? radioComponent({
                      field: props.field,
                      validation: props.validation,
                      index: props.index,
                      formType: props.formType
                  })
                : ''}
        </React.Fragment>
    );
};
