import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    FormFeedback,
    Input,
    Label,
    Row,
    UncontrolledTooltip
} from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';

const ConfigureAuthModes = () => {
    const api = new APIClient();
    const [isInvalidMail, setIsInvalidMail] = useState(false);
    const [store, setstore] = useState({});
    const [chipsStore, setChipsStore] = useState([]);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const urlconf = useEnv();
    const [showPassword, setShowPassword] = useState(false);

    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let mode = formTypeAndId['formType'];
    const formType = window.location.hash.split('/')[2];
    const recordID = mode === 'view' || mode === 'edit' ? window.location.hash?.split('/')?.[4] : '';

    const info = {
        microsoft: 'Single Sign-on using Office 365 or Azure Active Directory Authentication',
        google: 'Single Sign-on using google for business',
        ldap: 'Single Sign-on using Office 365 or Azure Active Directory Authentication',
        tectoro: 'Single Sign-on using a password securely set by an employee'
    };

    const chipsField = {
        label: 'Domain Name',
        value: 'domainName',
        type: 'text',
        placeholder: 'Enter Domain Name Ex: company.com',
        helpText: 'Add your Domain names',
        invalidMsg: 'not a valid domain',
        validator: 'email',
        list: 'Domains'
    };
    const toggleField = {
        label: 'Two Factor Authentication',
        helpText: 'Authentication Modes',
        index: 0,
        formType: 'unview',
        value: 'tfa'
    };
    const radioField = {
        label: 'Authentication Modes',
        label1: 'Email',
        val1: 'email',
        label2: 'SMS',
        val2: 'sms',
        helpText: 'Authentication Modes',
        index: 0,
        formType: 'unview',
        value: 'authentic'
    };

    const ConfigurationSchema = [
        {
            label: 'LDAP Server Name',
            value: 'ldapServerName',
            placeholder: 'dap://ldap.example.com:389',
            helpText: 'Directory service for managing user info, essential for network access.',
            type: 'text'
        },
        {
            label: 'Base DN',
            value: 'baseDn',
            placeholder: 'ou=Users,o=yourorg,dc=example,dc=com',
            helpText: 'Starting point for LDAP searches, like root directory.',
            type: 'text'
        },
        {
            label: 'Bind User',
            value: 'bindUser',
            placeholder: 'Enter Username',
            helpText: 'Account used to access LDAP directory for authentication.',
            type: 'text'
        },
        {
            label: 'Bind Password',
            value: 'bindPassword',
            placeholder: 'Enter Password',
            helpText: 'Password for accessing LDAP directory through a Bind User.',
            type: 'password'
        }
    ];

    const MappingSchema = [
        /*
         * {
         *     label: 'UserName',
         *     value: 'userName',
         *     placeholder: 'Enter Username',
         *     helpText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s',
         *     type: 'text'
         * },
         */
        {
            label: 'Email',
            value: 'email',
            placeholder: 'Enter Email',
            helpText: 'Representation of email addresses within the LDAP directory.',
            type: 'text'
        },
        {
            label: 'Firstname',
            value: 'firstName',
            placeholder: 'Enter First Name',
            helpText: 'Initial or given name of a user stored in LDAP.',
            type: 'text'
        },
        {
            label: 'Lastname',
            value: 'lastName',
            placeholder: 'Enter Last Name',
            helpText: 'Surname attribute for user entries in the directory.',
            type: 'text'
        }
    ];

    useEffect(() => {
        if (recordID) {
            getAuthMode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordID]);

    const getAuthMode = () => {
        setLoading(true);
        api.get(url.AUTH_MODES_CREATE + '/' + recordID, '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    handleDataBinding(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleDataBinding = (data) => {
        switch (formType) {
            case 'ldap':
                validation.setValues({
                    ...validation.values,
                    ldapServerName: data?.config?.server,
                    baseDn: data?.config?.basedn,
                    bindUser: data?.config?.connuser,
                    bindPassword: data?.config?.connpass,
                    email: data?.config?.email,
                    firstName: data?.config?.firstname,
                    lastName: data?.config?.lastname
                });
                break;
            case 'google':
            case 'microsoft':
                chipsValidation.setValues({ ...chipsValidation.values, Domains: data?.config?.allowedDomain });
                break;
            case 'tectoro':
                tectoroValidation.setValues({
                    ...tectoroValidation.values,
                    tfa: data?.config?.tfa,
                    authentic: data?.config?.otpmode
                });
                break;
            default:
                break;
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            ldapServerName: '',
            baseDn: '',
            bindUser: '',
            bindPassword: '',
            userName: '',
            email: '',
            firstName: '',
            lastName: ''
        },
        validationSchema: Yup.object({
            ldapServerName: Yup.string().required('Please Enter servername'),
            baseDn: Yup.string().required('Please Enter base Dn'),
            bindUser: Yup.string().required('Please Enter bind user'),
            bindPassword: Yup.string()
                .required('Please Enter bind password')
                .min(8, 'Password must be at least 8 characters')
                .max(15, 'Password must be atmost 15 characters'),
            email: Yup.string().required('Please Enter email'),
            firstName: Yup.string().required('Please Enter firstname'),
            lastName: Yup.string().required('Please Enter lastname')
        }),
        onSubmit: (values) => {
            handleSave(values);
            validation.resetForm();
        }
    });

    const handleSave = (values) => {
        setLoading(true);
        let dataObj = {
            mode: formType === 'tectoro' ? 'local' : formType,
            status: 'A',
            config:
                formType === 'ldap'
                    ? {
                          server: values.ldapServerName,
                          connuser: values.bindUser,
                          connpass: values.bindPassword,
                          basedn: values.baseDn,
                          email: values.email,
                          firstname: values.firstName,
                          lastname: values.lastName
                      }
                    : formType === 'tectoro'
                    ? {
                          tfa: tectoroValidation.values.tfa,
                          otpmode: tectoroValidation.values.tfa ? tectoroValidation.values.authentic : undefined
                      }
                    : { allowedDomain: values.Domains }
        };
        let apiService;
        if (mode === 'edit') apiService = api.patch(url.AUTH_MODES_CREATE + '/' + recordID, dataObj, false, domains.IDM);
        else apiService = api.create(url.AUTH_MODES_CREATE, dataObj, false, domains.IDM);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(mode === 'edit' ? toastMessages.authModeUpdated : toastMessages.authModeCreated);
                    history.push('/authmodes');
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const chipsValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            options: []
        },
        validationSchema: Yup.object({
            options: Yup.array().required('enter any email')
        }),
        onSubmit: (values) => {
            handleSave(values);
            chipsValidation.resetForm();
        }
    });

    const tectoroValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            tfa: false,
            authentic: ['email']
        },
        onSubmit: (values) => {
            // handleSave(values);
            tectoroValidation.resetForm();
        }
    });

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };

    const handleAddFRPMail = (field) => {
        let isValid = true;
        if (field.validator) {
            let regex = field.validator === 'email' ? /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ : '';
            let pattern = new RegExp(regex);
            isValid = pattern.test(chipsValidation.values[field.value]?.trim());
            setIsInvalidMail(!isValid);
            if (!isValid) {
                return;
            } else if (chipsValidation.values[field.list]?.includes(chipsValidation.values[field.value]?.trim())) {
                toast.error(toastMessages.domainAlreadyExists);
                return;
            }
        }
        let listObj = [];
        if (chipsValidation.values[field.list])
            listObj = [...chipsValidation.values[field.list], chipsValidation.values[field.value]?.toLowerCase()?.trim()];
        else listObj.push(chipsValidation.values[field.value]?.toLowerCase()?.trim());

        chipsValidation.setValues({ ...chipsValidation.values, [field.list]: [...listObj], [field.value]: '' });
    };

    const handleRemoveOneFRPMail = (ele, field) => {
        let listArr = [...chipsValidation.values[field.list]];
        listArr = listArr.filter((list) => list !== ele);
        chipsValidation.setValues({ ...chipsValidation.values, [field.list]: [...listArr] });
    };

    const handleSaveLdap = () => {
        setstore(validation.values);
        validation.handleSubmit();
    };

    const handleSaveChips = () => {
        setChipsStore(chipsValidation.values);
        chipsValidation.handleSubmit();
    };

    const handleCancel = () => {
        history.push('/authmodes');
    };

    useEffect(() => {
        if ((formType === 'microsoft' || formType === 'google') && mode === 'view') {
            chipsValidation.setFieldValue('Domains', chipsStore.Domains);
        } else if (formType === 'ldap' && mode === 'view') {
            validation.setValues({
                ldapServerName: store.ldapServerName,
                baseDn: store.baseDn,
                bindUser: store.bindUser,
                bindPassword: store.bindPassword,
                userName: store.userName,
                email: store.email,
                firstName: store.firstName,
                lastName: store.lastName
            });
        }
    }, [store]);

    const chipsComponent = (field, validationObj) => {
        return (
            <Row className="mt-2">
                <Col xs={12} md={12} sm={12} lg={12} xl={12}>
                    <Label className="mb-0 fw-medium d-flex align-items-center">{field.label}</Label>
                    {mode !== 'view' && <div className="fs-11 py-1">{field.helpText}</div>}
                </Col>
                <Col xs={3} md={3} sm={3} lg={6} xl={6}>
                    <div className="d-flex align-items-center">
                        {mode !== 'view' && (
                            <>
                                <div className="input-group widthPer-60 me-2">
                                    <Input
                                        name={field.value}
                                        id={field.value}
                                        className="form-control"
                                        placeholder={field.placeholder}
                                        type={field.type}
                                        validate={{ required: { value: true } }}
                                        onChange={validationObj.handleChange}
                                        onBlur={validationObj.handleBlur}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        value={validationObj.values[field.value] || ''}
                                        invalid={validationObj.touched[field.value] && validationObj.errors[field.value] ? true : false}
                                    />
                                </div>
                                <Button
                                    size="sm"
                                    type="button"
                                    disabled={!validationObj.values[field.value]}
                                    color={'primary'}
                                    onClick={() => handleAddFRPMail(field)}
                                >
                                    <span className="d-flex align-items-center">
                                        <i className="ri-add-line me-1 fs-16"></i> Add Domain
                                    </span>
                                </Button>
                            </>
                        )}
                    </div>
                    {isInvalidMail ? <p className="mt-1 mb-1 text-danger fs-11">{field.invalidMsg}</p> : ''}
                    <div className={`filter-choices-input height-205 overflow-auto ${mode === 'view' ? '' : 'p-2'}`}>
                        <div className="choices" data-type="text">
                            <div className="choices__inner min-height-1">
                                <div className="choices__list choices__list--multiple">
                                    {validationObj.values?.[field.list]?.length > 0 &&
                                        validationObj.values?.[field.list]?.map((ele, indd) => {
                                            return (
                                                <div key={indd} className="choices__item choices__item--selectable bg-primary border-white">
                                                    <div id={`bookmark-${indd}`}>
                                                        {ele}
                                                        {mode !== 'view' && (
                                                            <button
                                                                type="button"
                                                                className="choices__button"
                                                                onClick={() => handleRemoveOneFRPMail(ele, field)}
                                                            >
                                                                Remove item
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {validationObj.values?.[field.list]?.length === 0 && mode === 'view' ? '—' : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    };

    const inputComponent = (field, validationObj) => {
        return (
            <Col xs={field?.xs} sm={field?.sm} md={field?.md} lg={field?.lg} xl={field?.xl}>
                <div className="mb-3">
                    {field.label && (
                        <Label className="form-label m-0">
                            {field.label} {field?.mandatory && <span className="text-danger">*</span>}
                        </Label>
                    )}
                    <div className="fs-11 py-1">{field?.helpText}</div>
                    {mode !== 'view' ? (
                        <Input
                            name={field.value}
                            type={field.type}
                            placeholder={field?.placeholder}
                            onChange={validationObj.handleChange}
                            onBlur={validationObj.handleBlur}
                            value={validationObj.values[field?.value] || ''}
                            invalid={validationObj.touched[field?.value] && validationObj.errors[field?.value] ? true : false}
                        />
                    ) : validationObj.values?.[field.value] ? (
                        <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                                name="password"
                                id="password-input"
                                disabled
                                value={validationObj.values?.[field.value] || ''}
                                type={field.type === 'password' ? (showPassword ? 'text' : 'password') : field.type}
                                className={'form-control pe-5'}
                            />
                            {validationObj.values[field.value] && validationObj.values[field.value] !== '' && field.type === 'password' && (
                                <button
                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none cursor-auto text-muted"
                                    type="button"
                                    id="password-addon"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    <i
                                        id="password-icon"
                                        className={`ri ${showPassword ? 'ri-eye-line' : 'ri-eye-off-line'} align-middle cursor-pointer`}
                                    ></i>
                                </button>
                            )}
                        </div>
                    ) : (
                        '—'
                    )}
                    {validationObj.touched[field?.value] && validationObj.errors[field?.value] ? (
                        <FormFeedback type="invalid">
                            <div>{validationObj.errors[field?.value]}</div>
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
        );
    };

    const toggleButton = (validationObj) => {
        return (
            <Row>
                <Col xs={6} md={6} sm={6} lg={4} xl={4} className="d-flex align-items-center justify-content-between">
                    <Label className="mb-0 fw-medium d-flex align-items-center">
                        {toggleField.label}
                        {toggleField.helpText ? (
                            <>
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={`toggleButton-${toggleField.index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`toggleButton-${toggleField.index}`}>
                                    {toggleField.helpText ? toggleField.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={8} xl={8} className="padding-left-0">
                    {mode !== 'view' ? (
                        <div className="form-check form-switch form-switch-lg">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name={toggleField.value}
                                id={toggleField.label}
                                checked={validationObj?.values[toggleField.value] || false}
                                onChange={validationObj.handleChange}
                                onBlur={validationObj.handleBlur}
                            />
                        </div>
                    ) : validationObj?.values?.[toggleField.value] ? (
                        'Yes'
                    ) : (
                        'No'
                    )}
                </Col>
            </Row>
        );
    };

    const radioButton = (radioFields, validationObj) => {
        const handleAuthModes = (val) => {
            let values = { ...validationObj.values };
            let modes = [...(values[radioFields.value] || [])];
            if (modes.includes(val)) modes = modes.filter((modeObj) => modeObj !== val);
            else modes.push(val);
            validationObj.setValues({ ...validationObj.values, [radioFields.value]: modes });
        };

        return (
            <Row>
                <Col xs={6} md={6} sm={6} lg={4} xl={4} className="d-flex align-items-center justify-content-between">
                    <Label className="mb-0 fw-medium d-flex align-items-center">
                        {radioFields.label}
                        {radioFields.helpText ? (
                            <>
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={`radioButton-${radioFields.index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`radioButton-${radioFields.index}`}>
                                    {radioFields.helpText ? radioFields.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={8} xl={8}>
                    {
                        <>
                            <div className="form-check form-check-inline">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={radioFields.value}
                                    name={radioFields.value}
                                    disabled={mode === 'view'}
                                    value={radioField.val1}
                                    onBlur={validationObj.handleBlur}
                                    onChange={() => handleAuthModes(radioField.val1)}
                                    checked={validationObj.values[radioFields.value]?.includes(radioField.val1)}
                                />
                                <Label className="mb-0">{radioFields.label1}</Label>
                            </div>
                            <div className="form-check form-check-inline">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={radioFields.value}
                                    name={radioFields.value}
                                    disabled={mode === 'view'}
                                    value={radioField.val2}
                                    onBlur={validationObj.handleBlur}
                                    onChange={() => handleAuthModes(radioField.val2)}
                                    checked={validationObj.values[radioFields.value]?.includes(radioField.val2)}
                                />
                                <Label className="mb-0">{radioFields.label2}</Label>
                            </div>
                        </>
                    }
                </Col>
            </Row>
        );
    };

    const checkTectoroDisable = () => {
        return !!tectoroValidation.values.tfa ? tectoroValidation.values.authentic?.length === 0 : false;
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        title="Authentication Modes"
                        pageTitle="Authentication Modes"
                        history={history}
                        showBack={true}
                        backLink="authmodes"
                    />
                    {formType === 'tectoro' && (
                        <Card>
                            <CardHeader>
                                <div className="fw-semibold fs-14">{formType === 'tectoro' ? 'Tectoro' : ''}</div>
                                <div className="fs-11">{info[formType]}</div>
                            </CardHeader>
                            <CardBody className="padding-bottom-155">
                                <Row>
                                    <div>{toggleButton(tectoroValidation)}</div>
                                </Row>
                                <Row className="py-2">
                                    {tectoroValidation.values.tfa && <> {radioButton(radioField, tectoroValidation)}</>}
                                </Row>
                            </CardBody>
                            <CardFooter className="d-flex justify-content-end">
                                {mode !== 'view' && (
                                    <Button
                                        className="bg-primary text-white border-primary m-1 px-5"
                                        disabled={checkTectoroDisable()}
                                        onClick={handleSave}
                                    >
                                        {mode === 'edit' ? 'Update' : 'Save'}
                                    </Button>
                                )}
                                <Button className="bg-white text-primary border-primary m-1" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </CardFooter>
                        </Card>
                    )}
                    {(formType === 'microsoft' || formType === 'google') && (
                        <Card>
                            <CardHeader>
                                <div className="fw-semibold fs-14">{formType === 'google' ? 'Google' : 'Microsoft'}</div>
                                <div className="fs-11">{info[formType]}</div>
                            </CardHeader>
                            <CardBody className="padding-bottom-155">{chipsComponent(chipsField, chipsValidation)}</CardBody>
                            <CardFooter className="d-flex justify-content-end">
                                {mode !== 'view' && (
                                    <Button
                                        className="bg-primary text-white border-primary m-1 px-5"
                                        disabled={!chipsValidation.values.Domains || chipsValidation.values.Domains?.length === 0}
                                        onClick={handleSaveChips}
                                    >
                                        {mode === 'edit' ? 'Update' : 'Save'}
                                    </Button>
                                )}
                                <Button className="bg-white text-primary border-primary m-1" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </CardFooter>
                        </Card>
                    )}
                    {formType === 'ldap' && (
                        <Card>
                            <CardHeader>
                                <div className="fw-semibold text-grey fs-14">LDAP</div>
                                <div className="fs-11">{info[formType]}</div>
                            </CardHeader>
                            <CardBody className="padding-bottom-10">
                                <div className="mb-5">
                                    <div className="fw-semibold text-primary mb-2 fs-14">Configuration</div>
                                    <Row>
                                        {ConfigurationSchema?.map((field, index) => (
                                            <Col key={index} xs={6} md={6} sm={6} lg={6} xl={6}>
                                                {inputComponent(field, validation)}
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                                <div className="fw-semibold text-primary mb-2 fs-14">Mapping</div>
                                <Row>
                                    {MappingSchema?.map((field, index) => (
                                        <Col key={index} xs={6} md={6} sm={6} lg={6} xl={6}>
                                            {inputComponent(field, validation)}
                                        </Col>
                                    ))}
                                </Row>
                            </CardBody>
                            <CardFooter className="d-flex justify-content-end">
                                {mode !== 'view' && (
                                    <Button
                                        className="bg-primary text-white border-primary m-1 px-5"
                                        disabled={
                                            validation.values.baseDn === '' ||
                                            validation.values.bindPassword === '' ||
                                            validation.values.bindUser === '' ||
                                            validation.values.email === '' ||
                                            validation.values.firstName === '' ||
                                            validation.values.lastName === '' ||
                                            validation.values.ldapServerName === ''
                                        }
                                        onClick={handleSaveLdap}
                                    >
                                        {mode === 'edit' ? 'Update' : 'Save'}
                                    </Button>
                                )}
                                <Button className="bg-white text-primary border-primary m-1" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </CardFooter>
                        </Card>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ConfigureAuthModes;
