export const LAVA_SCHEMA = [
    {
        label: 'Navigation bar :',
        helpText:
            'Navigation bar refers to the on-screen navigation controls typically found at the bottom of the display. These controls allow users to navigate through the device’s interface, switch between apps, or perform basic actions.'
    },
    {
        label: 'Home',
        helpText: 'Enabling this feature helps user to access Home icon on the screen, which helps in navigating to home screen.',
        inputType: 'switch',
        value: 'home',
        badges: ['S 87']
    },
    {
        label: 'Recent',
        helpText: 'Enabling this feature helps user to access Recent icon on the screen, which helps user access to recent apps.',
        inputType: 'switch',
        value: 'recent',
        badges: ['S 87']
    },
    {
        label: 'Back',
        helpText: 'Enabling this feature helps user to access Back icon on the screen, which helps user to navigate back. ',
        inputType: 'switch',
        value: 'back',
        badges: ['S 87']
    },
    {
        label: 'Hard Keys',
        helpText: 'Hard keys refer to the physical buttons on the device that are used to perform certain functions.'
    },
    {
        label: 'Volume Up',
        helpText: 'Enabling this feature helps user to increase the volume of the device. ',
        inputType: 'switch',
        value: 'volumeUp',
        badges: ['S 87']
    },
    {
        label: 'Volume Down',
        helpText: 'Enabling this feature helps user to decrease the volume of the device. ',
        inputType: 'switch',
        value: 'volumeDown',
        badges: ['S 87']
    },
    {
        label: 'Power',
        helpText: 'Enabling this feature helps user to access power button of the device. ',
        inputType: 'switch',
        value: 'power',
        badges: ['S 87']
    },
    {
        label: 'Split Window',
        helpText: 'Enabling this feature helps user to access the usage of split window on the screen. ',
        inputType: 'switch',
        value: 'splitWindow',
        badges: ['S 87']
    },
    {
        label: 'Factory Mode',
        helpText: 'Enabling this feature helps user to access the settings under factory mode. ',
        inputType: 'switch',
        value: 'factoryMode',
        badges: ['S 87']
    },
    {
        label: 'Device Recovery Mode',
        helpText: 'Enabling this feature helps user to access Recovery mode for wiping data, clearing cache etc. ',
        inputType: 'switch',
        value: 'deviceRecoryMode',
        badges: ['S 87']
    }
];

export const ACER_SCHEMA = [
    {
        label: 'Navigation bar :',
        helpText:
            'Navigation bar refers to the on-screen navigation controls typically found at the bottom of the display. These controls allow users to navigate through the device’s interface, switch between apps, or perform basic actions.'
    },
    {
        label: 'Home',
        helpText: 'Enabling this feature helps user to access Home icon on the screen, which helps in navigating to home screen.',
        inputType: 'switch',
        value: 'home',
        badges: ['T4-82L', 'T9-422L', 'T4-1212L']
    },
    {
        label: 'Recent',
        helpText: 'Enabling this feature helps user to access Recent icon on the screen, which helps user access to recent apps. ',
        inputType: 'switch',
        value: 'recent',
        badges: ['T4-82L', 'T9-422L', 'T4-1212L']
    },
    {
        label: 'Back',
        helpText: 'Enabling this feature helps user to access Back icon on the screen, which helps user to navigate back.',
        inputType: 'switch',
        value: 'back',
        badges: ['T4-82L', 'T9-422L', 'T4-1212L']
    },
    {
        label: 'Hard Keys',
        helpText: 'Hard keys refer to the physical buttons on the device that are used to perform certain functions.'
    },
    {
        label: 'Volume Up',
        helpText: 'Enabling this feature helps user to increase the volume of the device.',
        inputType: 'switch',
        value: 'volumeUp',
        badges: ['T4-82L', 'T9-422L', 'T4-1212L']
    },
    {
        label: 'Volume Down',
        helpText: 'Enabling this feature helps user to decrease the volume of the device.',
        inputType: 'switch',
        value: 'volumeDown',
        badges: ['T4-82L', 'T9-422L', 'T4-1212L']
    },
    {
        label: 'Power',
        helpText: 'Enabling this feature helps user to access power button of the device. ',
        inputType: 'switch',
        value: 'power',
        badges: ['T4-82L', 'T9-422L', 'T4-1212L']
    },
    {
        label: 'Notifications',
        helpText: 'Enabling this feature helps user to access the notification panel on the screen.',
        inputType: 'switch',
        value: 'notifications',
        badges: ['T4-82L', 'T9-422L', 'T4-1212L']
    },
    {
        label: 'Factory Mode',
        helpText: 'Enabling this feature helps user to access the settings under factory mode. ',
        inputType: 'switch',
        value: 'factoryMode',
        badges: ['T4-82L', 'T9-422L', 'T4-1212L']
    }
];

export const tableSchema = [
    { Header: 'Name', accessor: 'name', sortable: true, customCell: true, fieldType: 'clickable' },
    { Header: 'Policies', accessor: 'policies', sortable: true },
    { Header: 'Feature Type', accessor: 'type', sortable: true },
    { Header: 'Modified Date', accessor: 'modifiedDate', sortable: true },
    { Header: 'Modified By', accessor: 'modifiedBy', sortable: true },
    { Header: 'Actions', accessor: 'actions', customCell: true, fieldType: 'actions', edit: true, deleteFadable: true, sortable: true }
];
