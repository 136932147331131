import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

// Import Images
import error from '../../../assets/images/common/svg/Error/error.svg';
import ParticlesAuth from '../../../pages/Authentication/ParticlesAuth';

const Error404 = () => {
    document.title = '404 Error';

    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center pt-4">
                                        <div>
                                            <img src={error} alt="" className="error-basic-img move-animation" />
                                        </div>
                                        <div className="mt-n4">
                                            <h1 className="display-1 fw-semibold">404</h1>
                                            <h3 className="text-uppercase">Sorry, Page not Found 😭</h3>
                                            <p className="text-muted mb-4">The page you are looking for is not available</p>
                                            <Link to="/" className="btn btn-success">
                                                <div className="d-flex align-items-center">
                                                    <i className="ri-home-4-line me-1"></i>Back to home
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth>
            </div>
        </React.Fragment>
    );
};

export default Error404;
