import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import delLauncher from '../../../assets/images/common/png/delete/delLauncherSetting.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { LauncherSetupSchema } from '../TableSchema';

const LauncherSetup = () => {
    document.title = 'Launcher';
    let api = new APIClient();
    let history = useHistory();
    const urlconf = useEnv();

    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [configs, setConfigs] = useState([]);
    const [configsBackup, setConfigsBackup] = useState([]);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 1000 });
    const [selectedConfig, setSelectedConfig] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [tableSchema, setTableSchema] = useState([]);
    const [showAddNew, setShowAddNew] = useState(true);

    useEffect(() => {
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.APPLICATION_EDITOR);
        let schema = [...LauncherSetupSchema];
        if (findPriv) schema = LauncherSetupSchema.filter((item, index) => index < LauncherSetupSchema.length - 1);
        setTableSchema([...schema]);
        setLoading(true);
        getConfigs('LAUNCHER');
    }, []);

    const getConfigs = (appCode) => {
        let params = { ...searchParams, appCode: appCode };
        api.get(url.MANAGED_CONFIGURATIONS, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((data) => {
                        data.name = data.title;
                        data.policies = data.policyNames?.length > 0 ? data.policyNames?.join(',') : '—';
                        data.doNotDelete = data.policyNames?.length > 0;
                        data.modifiedBy = data.lastmodifiedBy ? data.lastmodifiedBy : 'Tectoro';
                        data.modifiedDate = getDate(data.lastmodifiedTime);
                    });
                    setTotalRecords(resp.totalRecords);
                    setConfigs(resp.data);
                    setConfigsBackup(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                if (err === 'Application Code not Found.') setShowAddNew(false);
                setLoading(false);
            });
    };

    const handleSearch = (val) => {
        let configArr = JSON.parse(JSON.stringify(configsBackup));
        if (val?.length > 0) configArr = configArr.filter((conf) => conf?.name?.toLowerCase()?.includes(val?.toLowerCase()));
        setConfigs(configArr);
        setTotalRecords(configArr?.length);
    };

    const handleDelete = (row) => {
        setSelectedConfig(row.original);
        setDeleteModal(true);
    };

    const handleEdit = (row) => {
        history.push('/aelaunchersetup/edit/' + row.original.id);
    };

    const handleAddNew = () => {
        history.push('/aelaunchersetup/add');
    };

    const handleDeleteConfirmation = () => {
        setDeleteModal(false);
        setLoading(true);
        api.delete(url.MANAGED_CONFIGURATIONS + '/' + selectedConfig?.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.launcherConfigDeleted);
                    getConfigs('LAUNCHER');
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleClickView = (row) => {
        history.push('/aelaunchersetup/view/' + row.original.id);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete Launcher Setting?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={delLauncher} alt="deletepolicy" width={60} height={60} />
                </div>
                <div>
                    Setting Name: <span className="fw-semibold">{selectedConfig?.name}</span>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Launcher" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Configs'}
                        isGlobalFilter={true}
                        searchFilter2={true}
                        searchPlaceHolder={'Name'}
                        handleGlobalSearch={handleSearch}
                        totalRecords={totalRecords}
                        customPageSize={10}
                        addButton={showAddNew}
                        columns={tableSchema}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleClickView={handleClickView}
                        handleAddNew={handleAddNew}
                        reader={priviliges.APPLICATION_READER}
                        editor={priviliges.APPLICATION_EDITOR}
                        data={configs}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
            <DeleteModal
                hideIcon={true}
                show={deleteModal}
                hideDeleteMessage={true}
                message={message()}
                confirmText={'Yes, Delete it!'}
                onDeleteClick={handleDeleteConfirmation}
                onCloseClick={() => setDeleteModal(false)}
            />
        </React.Fragment>
    );
};

export default LauncherSetup;
