import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { APIClient } from '../../../helpers/api_helper';
import { useEnv } from '../../../envContext';
import toastMessages from '../../../common/messages/toastMessages';

const BYODSetup = ({ setshowCards, setLoading }) => {
    const api = new APIClient();
    const urlconf = useEnv();
    const [assignmentType, setAssignmentType] = useState('user');
    const [userList, setUserList] = useState([]);
    const [policiesList, setPoliciesList] = useState([]);
    const [email, setemail] = useState(false);
    const [user, setUser] = useState(null);
    const [policy, setPolicy] = useState(null);

    const handlePromise = () => {
        const usersPromise = new Promise((resolve, reject) => {
            api.get(url.USERS, { page: 1, size: 100 }, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) {
                        resolve(resp.data);
                    }
                    reject('Applications Failed.');
                })
                .catch((err) => {
                    reject('Applications Failed.');
                });
        });
        const policiesPromise = new Promise((resolve, reject) => {
            api.get(url.POLICIES, { page: 1, size: 100 }, domains.WINDOWS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) {
                        resolve(resp.data);
                    }
                    reject('Applications Failed.');
                })
                .catch((err) => {
                    reject('Applications Failed.');
                });
        });
        Promise.allSettled([usersPromise, policiesPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    setUserList(result[0].value);
                }
                if (result[1].status === 'fulfilled') {
                    setPoliciesList(result[1].value);
                }
            })
            .finally((err) => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        handlePromise();
    }, []);

    const onCheckboxChange = (e) => {
        setemail(e);
    };

    const onSaveClick = () => {
        setLoading(true);
        api.create(
            url.BYODSETUP,
            {
                Tenant: user?.tenant,
                Username: user?.username,
                Email: user?.email,
                PolicyCode: policy?.code
            },
            false,
            domains.WINDOWS_V1
        )
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                setLoading(false);
                setshowCards(true);
                toast.success(toastMessages.byodSetup);
            })
            .catch((err) => {
                setLoading(false);
                setshowCards(true);
            });
    };

    return (
        <Card>
            <CardHeader>
                <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold text-muted">
                    <div className="fs-14 ">Enroll Device</div>
                </div>
                <div className="padding-top-10 font-size-12 fw-medium text-muted">
                    BYOD enrolment allows the user to perform self-enrolment in MDM. Admin will be sending an Email or SMS with steps to be
                    followed for self-enrolment.
                </div>
            </CardHeader>
            <CardBody>
                <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                    <div className="fs-14 d-flex align-items-center">
                        Assignment Type
                        <span className="red-color ps-1">*</span>
                        {/* <i className="ri-information-line d-flex align-items-center text-muted ps-1 fs-15" id="AssignmentType" />
                        <UncontrolledTooltip placement="bottom" target="AssignmentType">
                            Admin can send email or sms to single user or group of users
                        </UncontrolledTooltip> */}
                    </div>
                </div>
                <div className="pt-1 font-size-12 fw-medium text-muted">Admin can send email or sms to single user or group of users</div>
                <Row className="py-3 border-bottom ">
                    <Col>
                        <Row>
                            <Col>
                                <div className="form-check form-check-inline" onClick={() => setAssignmentType('user')}>
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        value={assignmentType}
                                        checked={assignmentType === 'user'}
                                    />
                                    <Label className="form-check-label ps-1">User</Label>
                                </div>
                            </Col>
                            <Col>
                                {/* <div className="form-check form-check-inline" onClick={() => setAssignmentType('group')}>
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        value={assignmentType}
                                        checked={assignmentType === 'group'}
                                    />
                                    <Label className="form-check-label ps-1">Group</Label>
                                </div> */}
                            </Col>
                        </Row>
                        <Row className="pt-5">
                            <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                                <div className="fs-14 d-flex align-items-center">
                                    User
                                    <span className="red-color ps-1">*</span>
                                    {/* <i className="ri-information-line d-flex align-items-center text-muted ps-1 fs-15" id="User" />
                                    <UncontrolledTooltip placement="bottom" target="User">
                                        Select user from the dropdown.
                                    </UncontrolledTooltip> */}
                                </div>
                            </div>
                            <div className="pt-1 font-size-12 fw-medium text-muted">Select user from the dropdown.</div>
                            <div className="pt-2">
                                <Select
                                    getOptionValue={(option) => option.email}
                                    getOptionLabel={(option) => option.username}
                                    options={userList}
                                    onChange={(e) => setUser(e)}
                                    value={user}
                                    isSearchable={true}
                                    placeholder={'Select User'}
                                    noOptionsMessage={() => 'No data found'}
                                />
                            </div>
                        </Row>
                        <Row className="pt-5">
                            <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                                <div className="fs-14 d-flex align-items-center">
                                    Policy
                                    <span className="red-color ps-1">*</span>
                                    {/* <i className="ri-information-line d-flex align-items-center text-muted ps-1 fs-15" id="Policy" />
                                    <UncontrolledTooltip placement="bottom" target="Policy">
                                        Select policy from the dropdown.
                                    </UncontrolledTooltip> */}
                                </div>
                            </div>
                            <div className="pt-1 font-size-12 fw-medium text-muted">Select policy from the dropdown.</div>
                            <div className="pt-2">
                                <Select
                                    getOptionValue={(option) => option.code}
                                    getOptionLabel={(option) => option.name}
                                    options={policiesList}
                                    onChange={(e) => setPolicy(e)}
                                    value={policy}
                                    placeholder={'Select Policy'}
                                    noOptionsMessage={() => 'No data found'}
                                />
                            </div>
                        </Row>
                        <Row className="pt-5">
                            <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                                <div className="fs-14 d-flex align-items-center">
                                    Send Enrolment Request Via
                                    <span className="red-color ps-1">*</span>
                                    {/* <i
                                        className="ri-information-line d-flex align-items-center text-muted ps-1 fs-15"
                                        id="SendEnrolmentRequestVia"
                                    />
                                    <UncontrolledTooltip placement="bottom" target="SendEnrolmentRequestVia">
                                        Based on the selection, an email or SMS will be sent to user for self-enrolment
                                    </UncontrolledTooltip> */}
                                </div>
                            </div>
                            <div className="pt-1 font-size-12 fw-medium text-muted">
                                Based on the selection, an email or SMS will be sent to user for self-enrolment
                            </div>
                            <Row className="pt-3">
                                <Col>
                                    <div className="form-check form-check-inline">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={email}
                                            onChange={(e) => onCheckboxChange(e.target.checked)}
                                        />
                                        <Label className="form-check-label ps-1">Email</Label>
                                    </div>
                                </Col>
                                <Col>
                                    {/* <div className="form-check form-check-inline">
                                        <Input className="form-check-input" type="checkbox" />
                                        <Label className="form-check-label ps-1">SMS</Label>
                                    </div> */}
                                </Col>
                            </Row>
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>
                <div className="d-flex justify-content-end align-items-center margin-top-10 px-3">
                    <Button
                        className="bg-light border-primary text-primary mx-3 px-5 fs-12 fw-semibold"
                        onClick={() => {
                            setshowCards(true);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="bg-primary border-none px-8 fs-12 fw-semibold"
                        disabled={user && Object.keys(user).length > 0 && policy && Object.keys(policy).length > 0 && email ? false : true}
                        onClick={() => onSaveClick()}
                    >
                        Save
                    </Button>
                </div>
            </CardBody>
        </Card>
    );
};

export default BYODSetup;
