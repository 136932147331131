import React, { useState, useEffect } from 'react';
import { Card, CardHeader, AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion, Button, Row, Col } from 'reactstrap';
import AnimatedTreeComponent from '../../../Components/Common/AnimatedTreeComponent';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import { Tooltip } from '../../../Components/Common/Util';
import DeviceStatusMenuIcon from '../../../assets/images/common/svg/common/device-status-icon.svg';
import AppStatusMenuIcon from '../../../assets/images/common/svg/common/app-status-icon.svg';
import PolicyMenuIcon from '../../../assets/images/common/svg/common/policy-menu-icon.svg';
import DeviceGroupMenuIcon from '../../../assets/images/common/svg/common/device-groups-icon.svg';
import OnlineStatusMenuIcon from '../../../assets/images/common/svg/common/online-status-menu-icon.svg';
import ViolationsIcon from '../../../assets/images/common/svg/common/violation-status-menu-icon.svg';

const Filters = (props) => {
    const [elasticSearchObj, setElasticSearchObj] = useState(JSON.parse(JSON.stringify(props.elasticSearchObj)));
    const [checked, setChecked] = useState(props.checked);
    const [expanded, setExpanded] = useState(props.expanded);

    useEffect(() => {
        setElasticSearchObj(JSON.parse(JSON.stringify(props.elasticSearchObj)));
        setChecked(props.checked);
        setExpanded(props.expanded);
    }, [props.elasticSearchObj, props.checked, props.expanded]);

    const returnArrayLength = (arr) => {
        return arr.filter((ele) => ele.checked)?.length;
    };

    const changeGroups = (groups, checkedData) => {
        setElasticSearchObj({ ...elasticSearchObj, tagListArr: groups });
        props.handleChecked(groups, checkedData);
    };

    return (
        <React.Fragment>
            {/* <Card className={`${!props.showFilterMenu ? 'card-height-100' : ''}`}> */}
            <Card>
                <CardHeader>
                    <div className="d-flex align-items-center">
                        {/* <div className={`d-flex align-items-center ${!props.showFilterMenu ? 'justify-content-center' : ''}`}> */}
                        {/* <div className="flex-grow-1">
                            <div className={`d-flex align-items-center ${!props.showFilterMenu ? 'justify-content-center' : ''}`}>
                                <i
                                    className={`${
                                        props.showFilterMenu ? 'ri-arrow-left-line' : 'ri-arrow-right-line d-flex justify-content-center'
                                    } link-primary cursor-pointer fs-18 me-2`}
                                    onClick={props.handleFilterMenuClick}
                                ></i>
                                {props.showFilterMenu && <h5 className="fs-16 m-0">Filters</h5>}
                            </div>
                        </div> */}
                        {/* {props.showFilterMenu && ( */}
                        <div className="flex-shrink-0">
                            <Button
                                color="link"
                                className="p-0"
                                onClick={props.clearAllFilters}
                                disabled={elasticSearchObj?.selectedFiltersKeys?.length === 0}
                            >
                                Clear All
                            </Button>
                        </div>
                        {/* )} */}
                    </div>
                    {elasticSearchObj?.selectedFiltersKeys?.length > 0 && (
                        <div className="filter-choices-input mt-3">
                            <div className="choices" data-type="text">
                                <div className="choices__inner min-height-1">
                                    <div className="choices__list choices__list--multiple">
                                        {elasticSearchObj.selectedFiltersKeys.map((ele, ind) => {
                                            return (
                                                <div key={ind} className="choices__item choices__item--selectable">
                                                    {ele.label}
                                                    <button
                                                        type="button"
                                                        className="choices__button"
                                                        onClick={() => props.handleRemoveFilter(ele)}
                                                    >
                                                        Remove item
                                                    </button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </CardHeader>
                {/* {props.showFilterMenu ? ( */}
                <UncontrolledAccordion flush={true} defaultOpen={['1', '2', '3', '4', '5', '6']} stayOpen={true}>
                    {props.tenantConfig?.SHOW_FILTERS?.deviceStatusArr && (
                        <AccordionItem>
                            <AccordionHeader targetId="1">
                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                    <img alt="" src={DeviceStatusMenuIcon} />
                                    <span className="ms-1 text-primary"> Device Status</span>
                                </span>{' '}
                                {returnArrayLength(elasticSearchObj.deviceStatusArr) > 0 && (
                                    <span className="badge bg-success rounded-pill align-middle ms-1">
                                        {returnArrayLength(elasticSearchObj.deviceStatusArr)}
                                    </span>
                                )}
                            </AccordionHeader>
                            <AccordionBody accordionId="1">
                                <div className="text-body pt-1">
                                    <div className="d-flex flex-column gap-1">
                                        {elasticSearchObj.deviceStatusArr?.length > 0 ? (
                                            elasticSearchObj.deviceStatusArr.map((element, ind) => {
                                                return (
                                                    <div className="d-flex align-items-center" key={ind}>
                                                        <div className="flex-grow-1">
                                                            <div className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={element.checked}
                                                                    id={`deviceStatus${ind}`}
                                                                    className="form-check-input"
                                                                    onChange={(e) => props.handleDeviceStatusFilter(e, element)}
                                                                />
                                                                <label
                                                                    className="form-check-label fw-normal"
                                                                    htmlFor={`deviceStatus${ind}`}
                                                                >
                                                                    {element.label}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <span className="badge bg-light text-muted">{element.count}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="fs-13 d-flex align-items-center justify-content-center">
                                                {'No Records Found.'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                    )}
                    {props.tenantConfig?.SHOW_FILTERS?.onlineStatusArr && (
                        <AccordionItem>
                            <AccordionHeader targetId="2">
                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                    <img alt="" src={OnlineStatusMenuIcon} />
                                    <span className="ms-1 text-primary"> Online Status</span>
                                </span>{' '}
                                {returnArrayLength(elasticSearchObj.onlineStatusArr) > 0 && (
                                    <span className="badge bg-success rounded-pill align-middle ms-1">
                                        {returnArrayLength(elasticSearchObj.onlineStatusArr)}
                                    </span>
                                )}
                            </AccordionHeader>
                            <AccordionBody accordionId="2">
                                <div className="text-body pt-1">
                                    <div className="d-flex flex-column gap-1">
                                        {elasticSearchObj.onlineStatusArr?.length > 0 ? (
                                            elasticSearchObj.onlineStatusArr.map((element, ind) => {
                                                return (
                                                    <div className="d-flex align-items-center" key={ind}>
                                                        <div className="flex-grow-1">
                                                            <div className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={element.checked}
                                                                    id={`onlineStatus${ind}`}
                                                                    className="form-check-input"
                                                                    onChange={(e) => props.handleOnlineStatusFilter(e, element)}
                                                                />
                                                                <label
                                                                    className="form-check-label fw-normal"
                                                                    htmlFor={`onlineStatus${ind}`}
                                                                >
                                                                    {element.label}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <span className="badge bg-light text-muted">{element.count}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="fs-13 d-flex align-items-center justify-content-center">
                                                {'No Records Found.'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                    )}
                    {props.tenantConfig?.SHOW_FILTERS?.appStatusArr && (
                        <AccordionItem id="apps">
                            <AccordionHeader targetId="3" className="border-top">
                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                    <img alt="" src={AppStatusMenuIcon} />
                                    <span className="ms-1 text-primary"> App Status</span>
                                </span>
                                {returnArrayLength(elasticSearchObj?.applicationsArr) > 0 && (
                                    <span className="badge bg-success rounded-pill align-middle ms-1">
                                        {returnArrayLength(elasticSearchObj?.applicationsArr)}
                                    </span>
                                )}
                            </AccordionHeader>
                            <AccordionBody accordionId="3">
                                <div className="overflow-auto pe-3">
                                    <div className="d-flex flex-column gap-1 max-height-300 text-body pt-0">
                                        {elasticSearchObj.applicationsArr?.length > 0 ? (
                                            elasticSearchObj.applicationsArr.map((element, ind) => {
                                                return (
                                                    <div className="d-flex align-items-center py-1" key={ind}>
                                                        <div className="flex-grow-1 widthPer-70">
                                                            <div className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={element.checked}
                                                                    id={`appStatus${ind}`}
                                                                    className="form-check-input"
                                                                    onChange={(e) => props.handleAppStatusFilter(e, element)}
                                                                />
                                                                <label
                                                                    className="form-check-label d-flex align-items-center w-100 fw-normal text-truncate"
                                                                    htmlFor={`appStatus${ind}`}
                                                                >
                                                                    <img
                                                                        src={element.smallIconUrl}
                                                                        alt="icon"
                                                                        className="me-1"
                                                                        width={20}
                                                                        height={20}
                                                                    />
                                                                    <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                        {element.title}
                                                                    </EllipsisToolTip>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <span className="badge bg-light text-muted text-truncate">
                                                                {Number(element.latestApp) +
                                                                    Number(element.notLatestApp) +
                                                                    Number(element.notInstallApp)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="fs-13 d-flex align-items-center justify-content-center">
                                                {'No Records Found.'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center max-height-100">
                                    <Row>
                                        {props.appStatus.map((appStatus, ind) => (
                                            <Col
                                                key={ind}
                                                sm="auto"
                                                className={`mt-2 ${
                                                    elasticSearchObj?.applicationsArr?.filter((app) => app.checked)?.length > 0
                                                        ? ''
                                                        : 'pe-none opacity-50'
                                                }`}
                                            >
                                                <div
                                                    className={`d-flex align-items-center ${
                                                        appStatus.checked ? `bg-${appStatus.class}` : `bg-soft-${appStatus.class}`
                                                    } br-5 py-1 px-2 cursor-pointer font-size-10`}
                                                    onClick={() => props.handleAppStatus(appStatus.value)}
                                                >
                                                    <i
                                                        className={`ri-checkbox-blank-circle-fill me-1 ${
                                                            appStatus.checked ? 'link-white' : `link-${appStatus.class}`
                                                        }`}
                                                    />
                                                    <span className={`${appStatus.checked ? 'text-white' : `text-${appStatus.class}`}`}>
                                                        {appStatus.label}
                                                    </span>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                    )}
                    {props.tenantConfig?.SHOW_FILTERS?.policiesArr && (
                        <AccordionItem>
                            <AccordionHeader targetId="4" className="border-top">
                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                    <img alt="" src={PolicyMenuIcon} />
                                    <span className="ms-1 text-primary"> Policies</span>
                                </span>{' '}
                                {returnArrayLength(elasticSearchObj.policiesArr) > 0 && (
                                    <span className="badge bg-success rounded-pill align-middle ms-1">
                                        {returnArrayLength(elasticSearchObj.policiesArr)}
                                    </span>
                                )}
                            </AccordionHeader>
                            <AccordionBody accordionId="4" className="overflow-auto">
                                <div className="d-flex flex-column gap-1 max-height-300 text-body pt-0">
                                    {elasticSearchObj?.policiesArr?.length > 0 ? (
                                        elasticSearchObj.policiesArr.map((ele, index) => {
                                            return (
                                                <div className="d-flex align-items-center" key={index}>
                                                    <div className="flex-grow-1 w-auto text-truncate word-ellipsis">
                                                        <div className="form-check">
                                                            <input
                                                                type="checkbox"
                                                                checked={ele.checked}
                                                                id={`policyRadio${index}`}
                                                                className="form-check-input"
                                                                onChange={(e) => props.handlePolicyFilter(e, ele)}
                                                            />
                                                            <label
                                                                className="form-check-label fw-normal word-ellipsis"
                                                                htmlFor={`policyRadio${index}`}
                                                            >
                                                                <span className="fs-13 fw-normal word-ellipsis" id={`policy-${index}`}>
                                                                    {ele.name ? ele.name : ele.label}
                                                                </span>
                                                                {ele.name?.length > 20 &&
                                                                    Tooltip(`policy-${index}`, ele.name ? ele.name : ele.label)}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0 mb-2">
                                                        <span className="badge bg-light text-muted">{ele.count}</span>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="fs-13 d-flex align-items-center justify-content-center">{'No Records Found.'}</div>
                                    )}
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                    )}
                    {props.tenantConfig?.SHOW_FILTERS?.tagListArr && (
                        <AccordionItem className="devicegroup_accordion">
                            <AccordionHeader targetId="5">
                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                    <img alt="" src={DeviceGroupMenuIcon} />
                                    <span className="ms-1 text-primary"> Device Groups</span>
                                </span>{' '}
                                {checked?.length > 0 && (
                                    <span className="badge bg-success rounded-pill align-middle ms-1">{checked?.length}</span>
                                )}
                            </AccordionHeader>
                            <AccordionBody accordionId="5" className="overflow-auto pt-0">
                                {/* <div className="p-4">
                                        <div className="search-box search-box-sm border rounded">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control bg-light border-0"
                                                value={deviceGroupSearchValue || ''}
                                                placeholder="Search Device Group"
                                                onChange={(e) => {
                                                    setDeviceGroupSearchValue(e.target.value);
                                                    props.handleDeviceGroupFilter(e.target.value);
                                                }}
                                            />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div> */}
                                <div className="d-flex flex-column gap-2 text-body p-4 pe-3">
                                    <div className="d-flex flex-column gap-1 max-height-315 device-filter-panel">
                                        {elasticSearchObj?.tagListArr?.length > 0 ? (
                                            <AnimatedTreeComponent
                                                groups={elasticSearchObj?.tagListArr}
                                                flatGroups={props.flatGroups}
                                                changeGroups={changeGroups}
                                                showCount={true}
                                                showSmallerLabel={true}
                                                checkOnLabelClick={true}
                                            />
                                        ) : (
                                            <div className="fs-13 d-flex align-items-center justify-content-center">
                                                {'No Records Found.'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                    )}

                    {props.tenantConfig?.SHOW_FILTERS?.violationStatusArr && (
                        <AccordionItem>
                            <AccordionHeader targetId="6" className="border-top">
                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                    <img alt="" src={ViolationsIcon} />
                                    <span className="ms-1 text-primary"> Violation Status</span>
                                </span>{' '}
                                {returnArrayLength(elasticSearchObj.violationStatusArr) > 0 && (
                                    <span className="badge bg-success rounded-pill align-middle ms-1">
                                        {returnArrayLength(elasticSearchObj.violationStatusArr)}
                                    </span>
                                )}
                            </AccordionHeader>
                            <AccordionBody accordionId="6" className="overflow-auto">
                                <div className="d-flex flex-column gap-1 max-height-300 text-body pt-0">
                                    {elasticSearchObj?.violationStatusArr?.length > 0 ? (
                                        elasticSearchObj.violationStatusArr.map((ele, index) => {
                                            return (
                                                <div className="d-flex align-items-center" key={index}>
                                                    <div className="flex-grow-1 w-auto text-truncate word-ellipsis">
                                                        <div className="form-check">
                                                            <input
                                                                type="checkbox"
                                                                checked={ele.checked}
                                                                id={`violationRadio${index}`}
                                                                className="form-check-input"
                                                                onChange={(e) => props.handleViolationSatusFilter(e, ele)}
                                                            />
                                                            <label
                                                                className="form-check-label fw-normal word-ellipsis"
                                                                htmlFor={`violationRadio${index}`}
                                                            >
                                                                <span className="fs-13 fw-normal word-ellipsis" id={`violation-${index}`}>
                                                                    {ele.name ? ele.name : ele.label}
                                                                </span>
                                                                {ele.name?.length > 20 &&
                                                                    Tooltip(`violation-${index}`, ele.name ? ele.name : ele.label)}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0 mb-2">
                                                        <span className="badge bg-light text-muted">{ele.count}</span>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="fs-13 d-flex align-items-center justify-content-center">{'No Records Found.'}</div>
                                    )}
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                    )}
                </UncontrolledAccordion>
                {/* ) : (
                    <Col>
                        {props.tenantConfig?.SHOW_FILTERS?.deviceStatusArr && (
                            <Row className="g-0 p-3 border-2 border-bottom">
                                <div
                                    className="d-flex align-items-center justify-content-center cursor-pointer"
                                    onClick={props.handleFilterMenuClick}
                                >
                                    <img alt="" src={DeviceStatusMenuIcon} id="device_status" />
                                    {Tooltip('device_status', 'Device Status')}
                                </div>
                            </Row>
                        )}
                        {props.tenantConfig?.SHOW_FILTERS?.onlineStatusArr && (
                            <Row className="g-0 p-3 border-2 border-bottom">
                                <div
                                    className="d-flex align-items-center justify-content-center cursor-pointer"
                                    onClick={props.handleFilterMenuClick}
                                >
                                    <img alt="" src={OnlineStatusMenuIcon} id="online_status" />
                                    {Tooltip('online_status', 'Online Status')}
                                </div>
                            </Row>
                        )}
                        {props.tenantConfig?.SHOW_FILTERS?.appStatusArr && (
                            <Row className="g-0 p-3 border-2 border-bottom">
                                <div
                                    className="d-flex align-items-center justify-content-center cursor-pointer"
                                    onClick={props.handleFilterMenuClick}
                                >
                                    <img alt="" src={AppStatusMenuIcon} id="app_status" />
                                    {Tooltip('app_status', 'App Status')}
                                </div>
                            </Row>
                        )}
                        {props.tenantConfig?.SHOW_FILTERS?.policiesArr && (
                            <Row className="g-0 p-3 border-2 border-bottom">
                                <div
                                    className="d-flex align-items-center justify-content-center cursor-pointer"
                                    onClick={props.handleFilterMenuClick}
                                >
                                    <img alt="" src={PolicyMenuIcon} id="policies" />
                                    {Tooltip('policies', 'Policies')}
                                </div>
                            </Row>
                        )}
                        {props.tenantConfig?.SHOW_FILTERS?.tagListArr && (
                            <Row className="g-0 p-3 border-2 border-bottom">
                                <div
                                    className="d-flex align-items-center justify-content-center cursor-pointer"
                                    onClick={props.handleFilterMenuClick}
                                >
                                    <img alt="" src={DeviceGroupMenuIcon} id="device_groups" />
                                    {Tooltip('device_groups', 'Device Groups')}
                                </div>
                            </Row>
                        )}
                        {props.tenantConfig?.SHOW_FILTERS?.violationStatusArr && (
                            <Row className="g-0 p-3 border-2 border-bottom">
                                <div
                                    className="d-flex align-items-center justify-content-center cursor-pointer"
                                    onClick={props.handleFilterMenuClick}
                                >
                                    <img alt="" src={ViolationsIcon} id="violations" />
                                    {Tooltip('violations', 'Violations')}
                                </div>
                            </Row>
                        )}
                    </Col>
                )
                } */}
            </Card>
        </React.Fragment>
    );
};

export default Filters;
