import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { androidTvAppsSchema } from '../TableSchema';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';

const Applications = () => {
    document.title = 'Apps';
    let history = useHistory();
    let api = new APIClient();
    const urlconf = useEnv();

    const [apps, setApps] = useState([]);
    const [tableSchema, setTableSchema] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [appToRemove, setAppToRemove] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.TVAPPLICATION_EDITOR);
        let schema = [...androidTvAppsSchema];
        if (findPriv) schema = androidTvAppsSchema.filter((item, index) => index < androidTvAppsSchema.length - 1);
        setTableSchema([...schema]);
        handlePromise();
    }, []);

    const handlePromise = () => {
        const androidTvApps = new Promise((resolve, reject) => {
            let params = { page: 1, size: 1000 };
            api.get(url.TV_APPS, params, domains.ANDROID_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp);
                    else reject('Apps failed.');
                })
                .catch((err) => reject('Apps failed.'));
        });

        Promise.allSettled([androidTvApps]).then((result) => {
            if (result[0]?.status === 'fulfilled') {
                result[0]?.value?.data?.forEach((data) => {
                    data.appName = data.appName ? data.appName : '—';
                    data.packageName = data.packagename ? data.packagename : '—';
                    data.appVersion = data.appVersion ? data.appVersion : '—';
                    data.modifiedDate = data.modifieddate ? getDate(data.modifieddate) : '—';
                });
                setApps(result[0]?.value?.data);
                setTotalRecords(result[0]?.value?.totalRecords);
            }
            setLoading(false);
        });
    };

    const handleAddApp = () => {
        history.push('/atvapps/add');
    };

    const handleView = (row) => {};

    const handleEdit = (row) => {
        history.push('/atvapps/edit/' + row.original._id);
    };

    const handleClickView = (row) => {
        history.push('/atvapps/view/' + row.original._id);
    };

    const handleCountViewGroup = (row) => {};

    const handleDelete = (row) => {
        setDeleteModal(true);
        setAppToRemove(row.original);
    };

    const handleIconView = (row) => {};

    const handleDeleteConfirmation = () => {
        setLoading(true);
        setDeleteModal(false);
        api.delete(url.TV_APPS + '/' + appToRemove?._id, '', domains.ANDROID_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.appDeleted);
                    handlePromise();
                } else setLoading(false);
            })
            .catch((err) => setLoading(false))
            .finally(() => setAppToRemove(''));
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="text-muted mb-4">Are you sure you want to delete?</div>
                <img src={appToRemove?.icondownloadurl} alt={appToRemove?.name} width="80" height="80" />
                <div className="fw-normal mt-4">{appToRemove.name}</div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle={'Apps'} history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        searchFilter2={true}
                        isGlobalFilter={true}
                        largeSearchFilter={true}
                        addButton={true}
                        columns={tableSchema}
                        handleAddNew={handleAddApp}
                        handleView={handleView}
                        handleEdit={handleEdit}
                        handleClickView={handleClickView}
                        handleCountViewPolicy={handleCountViewGroup}
                        data={apps}
                        handleDelete={handleDelete}
                        handleIconView={handleIconView}
                        searchPlaceHolder={'App Name'}
                        reader={priviliges.TVAPPLICATION_READER}
                        editor={priviliges.TVAPPLICATION_EDITOR}
                        tableHeader={'All Apps'}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        show={deleteModal}
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={message()}
                        confirmText={'Delete'}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        deleteMessage={'Are you sure you want to delete this App ?'}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Applications;
