import React from 'react';
import { Card, CardBody, CardHeader, Col, FormFeedback, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { Tooltip } from '../../../Components/Common/Util';

const windowsAuthTypes = {
    NO_AUTH: 'Without Login',
    TECTORO_LOGIN: 'Tectoro Login',
    STATIC_PIN: 'Static PIN'
};

export const EnrollmentConfigCard = ({ index, item, onMakeDefault, onAssignClick, onConfigEdit, onConfigDelete }) => {
    return (
        <Col xl={6} lg={6} md={12} sm={12} xs={12} key={index}>
            <Card className="border card-height-100">
                <CardHeader>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="text-muted">Enrollment Code :</div>
                            <div className="fw-medium ms-2">{item.enrollmentcode}</div>
                        </div>
                        <div className="d-flex align-items-center">
                            {item.isdefault ? (
                                <span className={'lh-lg badge badge-soft-success me-2'}>Default</span>
                            ) : (
                                <span
                                    className={'lh-lg badge badge-soft-dark-gray cursor-pointer me-2'}
                                    onClick={() => onMakeDefault(item)}
                                >
                                    Make Default
                                </span>
                            )}
                            <i
                                id={`assign-${index}`}
                                className="ri-share-forward-line me-2 fs-18 cursor-pointer link-secondary"
                                onClick={() => onAssignClick(item)}
                            />
                            {Tooltip(`assign-${index}`, 'Assign')}
                            <i
                                id={`edit-${index}`}
                                className="ri-edit-box-line me-2 fs-18 cursor-pointer link-secondary"
                                onClick={() => onConfigEdit(item)}
                            />
                            {Tooltip(`edit-${index}`, 'Edit')}
                            {item.isdefault !== true && (
                                <>
                                    <i
                                        id={`delete-${index}`}
                                        className="ri-delete-bin-line me-2 fs-18 cursor-pointer link-secondary"
                                        onClick={() => onConfigDelete(item)}
                                    />
                                    {Tooltip(`delete-${index}`, 'Delete')}
                                </>
                            )}
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="text-muted">Authentication Type :</div>
                            <div className="fw-medium ms-2">{windowsAuthTypes[item.authtype]}</div>
                        </div>
                        {item.authtype === 'STATIC_PIN' && (
                            <div className="d-flex align-items-center">
                                <div className="text-muted">PIN :</div>
                                <div className="fw-medium ms-2">{item?.authconfig?.pin}</div>
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export const getWindowsConfigInitialValues = () => {
    return windowsEnrollConfigSchema?.reduce((defaultValues, field) => {
        defaultValues[field?.value] = field?.defaultValue;
        return defaultValues;
    }, {});
};

export const windowsEnrollConfigSchema = [
    {
        label: 'Authentication Type',
        inputType: 'radios',
        value: 'authtype',
        helpText: 'Specifies type of Authentication Method used to manage secure enrollment of devices',
        defaultValue: 'NO_AUTH',
        row: true,
        options: [
            {
                label: 'Enrollment without Login',
                value: 'NO_AUTH',
                helpText: 'Upon enabling this option, user can enrol the device without any authentication.'
            },
            {
                label: 'Enrollment with Tectoro Login',
                value: 'TECTORO_LOGIN',
                helpText:
                    'Upon enabling this option, the end user will be able to login with all the possible login options as defined in the authentication mode.'
            },
            {
                label: 'Enrollment with Static PIN',
                value: 'STATIC_PIN',
                helpText:
                    'A static PIN is a fixed numerical code used for secure authentication. During enrolment, the same PIN is used to verify user identity during authentication.'
            }
        ]
    },
    {
        label: '',
        value: 'pin',
        helpText: 'Specifies the minimum number of days a password must be used before it can be changed, ranging from 0 to 998 days',
        inputType: 'input',
        maxLength: 4,
        defaultValue: '',
        enableOn: 'authtype',
        enableOnVal: ['STATIC_PIN'],
        placeholder: 'Enter Static PIN'
    }
];

const RadiosButton = ({ index, field, validation, formType }) => {
    return (
        <Row key={index} className={'m-0 p-0'}>
            <Col xs={12} md={12} sm={12} lg={12} xl={12} className="p-0">
                <Label className="form-check-label d-flex align-items-center mb-2">
                    <span className="fw-semibold fs-14">{field.label}</span>
                    {field?.mandatory && formType !== 'view' && <span className="red-color ps-1">*</span>}
                    <i
                        className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                        id={`radio-${index}-${field.tooltipId ? field.tooltipId : field.value}`}
                    />
                    <UncontrolledTooltip placement="bottom" target={`radio-${index}-${field.tooltipId ? field.tooltipId : field.value}`}>
                        {field.helpText ? field.helpText : 'Helper Text'}
                    </UncontrolledTooltip>
                </Label>
                {formType !== 'view' ? (
                    <div className={'px-0'}>
                        {field?.options?.map((ele, ind) => {
                            return (
                                <div
                                    className={`form-check mb-2 ${field?.row ? '' : 'form-check-inline mb-0'}`}
                                    key={ind}
                                    onClick={() =>
                                        validation.handleChange({
                                            target: { name: field.value, value: ele.value }
                                        })
                                    }
                                >
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id={field.label}
                                        name={field.value}
                                        disabled={formType === 'view'}
                                        value={ele.value}
                                        onBlur={validation.handleBlur}
                                        onChange={() =>
                                            validation.handleChange({
                                                target: { name: field.value, value: ele.value }
                                            })
                                        }
                                        checked={validation.values[field.value] === ele.value}
                                    />
                                    <Label className="mb-0">{ele.label}</Label>
                                    <div>{ele.helpText && <div className="text-muted fs-12 mb-2">{ele.helpText}</div>}</div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <span>{field.options?.find((option) => option.value === validation.values[field.value])?.label || '–'}</span>
                )}
            </Col>
        </Row>
    );
};

const InputComponent = ({ index, field, validation, formType }) => {
    return (
        <Row key={index} className={'m-0 p-0'}>
            <Col xs={12} md={4} sm={4} lg={4} xl={4} className="m-0 p-0 margin-left-8 mb-2">
                <Label className={'mb-0 fw-medium d-flex align-items-center mb-1'}>{field.label}</Label>
                <div className="input-group position-relative">
                    {formType !== 'view' ? (
                        <>
                            <Input
                                name={field.value}
                                id={field.value}
                                className="form-control"
                                placeholder={field.placeholder}
                                type={field.type}
                                onChange={validation.handleChange}
                                min={field.minValue}
                                max={field.maxValue}
                                maxLength={field.maxLength}
                                onBlur={validation?.handleBlur}
                                value={validation?.values[field?.value]}
                                disabled={field.disabled}
                                invalid={validation.touched[field.value] && validation?.errors?.[field?.value] ? true : false}
                            />
                        </>
                    ) : (
                        <span>{validation?.values[field?.value] || '–'}</span>
                    )}
                    {validation.touched[field.value] && validation.errors[field.value] && (
                        <FormFeedback type="invalid">{validation.errors[field.value]}</FormFeedback>
                    )}
                </div>
            </Col>
        </Row>
    );
};

const componentMap = {
    radios: RadiosButton,
    input: InputComponent
};

export const EnrollConfigComponents = (props) => {
    const Component =
        componentMap[props.field.inputType] ||
        (() => <div className="pt-1 fw-semibold fs-13 px-0 padding-bottom-5">{props.field.label}</div>);

    return (
        <React.Fragment key={props.index}>
            <Component {...props} />
        </React.Fragment>
    );
};

export const configEnableOnCondition = (field, validation) => {
    if (!field.enableOn) return true;
    return field.enableOnVal?.includes(validation.values[field.enableOn]) || false;
};

export const ConfigNotEnabledComponent = ({ field, validation }) => {
    if ('enableOn' in field && !configEnableOnCondition(field, validation)) {
        validation.values[field.value] = field.defaultValue;
    }
    return <></>;
};
